import React from 'react'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import Radio from '@material-ui/core/Radio'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const CardHeader = ({
    title,
    disabledForm,
    choosenPaymentOption,
    thisPaymentOption,
    optionNumber,
    classes,
    haveValuesFromAPI,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                transform: 'translateX(-14px)',
                opacity: haveValuesFromAPI ? '1' : '.85',
            }}
        >
            <Radio
                disabled={true}
                checked={
                    choosenPaymentOption === thisPaymentOption && !disabledForm
                }
                name={thisPaymentOption}
                value={`op${optionNumber}`}
                aria-label={`op${optionNumber}`}
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                }}
            />

            <span
                style={{
                    color: GetThemeColorFromStorage()?.titles?.secondary
                        ?.textColor,
                    textAlign: 'left',
                    fontSize: '1rem',
                    margin: '0',
                    padding: '0',
                    fontWeight: 'bold',
                    transform: 'translateY(2px)',
                }}
            >
                {title}
            </span>
        </div>
    )
}

export default CardHeader
