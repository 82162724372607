// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment/min/moment-with-locales'
import Moment from 'react-moment'
import 'moment/locale/pt-br'
import 'moment/locale/es'
import Favicon from 'react-favicon'

import { Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from 'pages/Auth'
import AdminLayout from 'pages/Admin'

import ConfirmRegistration from 'pages/ConfirmRegistration'
import AuthenticateAccess from 'pages/AuthenticateAccess'

import ResetPassword from 'pages/User/ResetPassword/ResetPassword.jsx'
import { PrivateRoute } from 'components/PrivateRoute.jsx'
import { Provider } from 'react-redux'
import { handleAuthCheck, history } from './utils'
import { ConnectedRouter } from 'connected-react-router'
import store from './redux/store'
import axios from 'axios'

import 'assets/scss/material-dashboard-pro-react.scss?v=1.5.0'
import 'utils/i18n'
import 'assets/icons/style.css'

import NetworkService from './utils/network-service'
import SuccessScreen from './pages/SuccessScreen'
import Policy from './pages/Register/Components/Policy'
import ProgramRegulation from './pages/Register/Components/ProgramRegulation'
import CreateUser from './pages/CreateUser'
import GlobalStyle from './components/GlobalStyle'
import { AuthProvider } from './utils/authconfig'

import { GetThemeColorFromStorage } from './redux/api/themeColor.js'
import { GeneralProvider } from './Context'
import { ConsumerProvider } from './Context/consumerContext.js'
import { envs } from './redux/api/windowServerData.js'
import { PolyCyPage } from './pages/PolyCyPage/index.jsx'
import SearchChild from './pages/Search/SearchChild.jsx'
import ScoringRulesChild from './pages/ScoringRules/index.js'

NetworkService.setupInterceptors(store)

axios.defaults.headers.common['Authorization'] =
    'Bearer ' + localStorage.getItem('token')
axios.defaults.headers.common['appId'] = 'CSM1803'
// axios.defaults.headers.common['companyInternalName'] = 'germini'
axios.defaults.baseURL = envs.REACT_APP_GERMINI_API_URL

/******************
 * MOMENT CONFIG
 ******************/
// Sets the moment instance to use.
Moment.globalMoment = moment

// Set the locale for every react-moment instance to French.
const langg = localStorage.getItem('i18nextLng')
Moment.globalLocale = langg !== null ? langg.toLowerCase() : 'pt-BR'
// if (localStorage.getItem('i18nextLng') !==null) {
//     Moment.globalLocale = localStorage.getItem('i18nextLng').toLowerCase()
// } else {
//     Moment.globalLocale = 'pt-BR'
// }

// Set the output format for every react-moment instance.
Moment.globalFormat = 'DD MM YYYY'

// Set the timezone for every instance.
Moment.globalTimezone = 'America/Sao_Paulo'

// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span'

// Upper case all rendered dates.
Moment.globalFilter = (d) => d.toUpperCase()

const App = () => {
    useEffect(() => {
        handleAuthCheck()
    }, [])

    return (
        <Provider store={store}>
            <GlobalStyle />
            <Favicon
                url={`${GetThemeColorFromStorage()?.images?.favicon?.url}`}
            />
            <Suspense fallback={<div />}>
                <ConnectedRouter history={history}>
                    <GeneralProvider>
                        <AuthProvider>
                            <ConsumerProvider>
                                <Switch>
                                    <Route
                                        path="/TermosUsoPoliticaPrivacidade"
                                        component={Policy}
                                    />
                                    <Route
                                        path="/RegulamentoPrograma"
                                        component={ProgramRegulation}
                                    />
                                    <Route
                                        path="/auth"
                                        component={AuthLayout}
                                    />
                                    <Route
                                        path="/register"
                                        component={CreateUser}
                                    />
                                    <Route
                                        path="/resetpassword"
                                        component={ResetPassword}
                                    />
                                    <Route
                                        path="/privacyPolicies"
                                        component={PolyCyPage}
                                    />
                                    <Route
                                        path="/confirmRegistration"
                                        component={ConfirmRegistration}
                                    />
                                    <Route
                                        path="/authenticateAccess"
                                        component={AuthenticateAccess}
                                    />
                                    <Route
                                        path="/success"
                                        component={SuccessScreen}
                                    />
                                    <Route
                                        path="/search/:id"
                                        component={SearchChild}
                                    />{' '}
                                    <PrivateRoute
                                        path="/"
                                        component={AdminLayout}
                                    />
                                    <Redirect from="/" to="home" />
                                </Switch>
                            </ConsumerProvider>
                        </AuthProvider>
                    </GeneralProvider>
                </ConnectedRouter>
            </Suspense>
        </Provider>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))
