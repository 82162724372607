import { Grid, makeStyles } from '@material-ui/core'
import styled from 'styled-components/macro'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'

export const Container = styled(Grid)`
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -14px) scale(0.75);
    }
    .IconQuery {
        color: #5d5d5d;
        text-align: ${({ mdMediaQuery }) => mdMediaQuery && 'center'};
        margin-left: 12px;
    }

    .iconsWaiver {
        color: #3c4858;
        margin-top: 6px;
    }

    .containerWaiver {
        .MuiFormControlLabel-root {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }

        @media (min-width: 62.25em) {
            transform: translateY(-8px);
        }
    }
    .containerWaiverMEI {
        flex-direction: column;
        align-items: flex-start;

        .MuiFormControlLabel-root {
            margin-left: 0px !important;
            margin-right: 0px !important;
        }

        @media (min-width: 62.25em) {
            transform: translateY(-2px);
        }
    }

    .MuiFormHelperText-root.Mui-error {
        color: #f44336;
        position: initial !important;
    }

    .containerWaiverCheckbox {
        .MuiFormControlLabel-root {
            white-space: nowrap;
        }
    }

    .small-input-checkbox {
        @media screen and (max-width: 1403px) {
            .MuiFormControlLabel-root {
                display: block;
                overflow: hidden;
                width: 240px;
                white-space: nowrap;
                text-overflow: ellipsis;

                @media screen and (max-width: 1050px) {
                    width: 180px;
                }

                @media screen and (max-width: 959.95px) {
                    width: 100%;
                }
            }

            .MuiCheckbox-root {
                transform: translateY(-2px);
            }
        }
    }

    #isStateDisabled-div {
        .Mui-disabled {
            color: ${({ disabled }) =>
                disabled ? '#959393' : 'inherit'} !important;
        }
        margin-top: 8px;
        margin-bottom: 4px;
    }
`

export const useStylesTooltip = makeStyles((theme) => ({
    arrow: {
        color: '#333333',
    },
    tooltip: {
        backgroundColor: '#333333',
    },
}))

export const useStylesSwitch = makeStyles((theme) => ({
    root: {
        width: 62,
        height: 30,
        padding: 0,
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(26px) translateY(0.04rem)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: `${
                    GetThemeColorFromStorage()?.navigation?.buttons
                        ?.elementsColor
                }`,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        transform: 'translateX(4px) translateY(1px)',
        width: 25,
        height: 24,
    },
    track: {
        borderRadius: 40 / 2,
    },
    checked: {},
    focusVisible: {},
}))

export const FormLabel = styled.label`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    color: #717171;
    margin-bottom: 0;
    margin-top: 0;
    white-space: nowrap;
`

export const CustomGrid = styled(Grid)`
    display: flex !important;
    gap: 16px !important;
    align-items: flex-start !important;
    margin-top: 10px !important;
`

export const CustomGridState = styled(Grid)`
    @media (min-width: 1280px) {
        max-width: 20% !important;
        flex-basis: 20% !important;
    }
    @media (min-width: 1600px) {
        max-width: 18% !important;
        flex-basis: 18% !important;
    }
    @media (min-width: 1700px) {
        max-width: 17% !important;
        flex-basis: 17% !important;
    }
`
export const CustomTitle = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 5px 0px 6.5px 8px;
    width: 100%;
`
