import React from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import Datetime from 'react-datetime'
import DocumentTitle from 'react-document-title'
import MaskedInput from 'react-text-mask'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { TextField, InputAdornment } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
	CustomButtonsBackground,
	CustomButtons,
} from '../../../components/CustomButtons'
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section/Section'
import { CssTextField } from 'components/CssTextField/CssTextField'
// style
import styles from './styles.module.scss'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

// UTILS
import compose from 'utils/compose'
import { FormatCNPJ } from 'utils/utils'

// REDIX INIT
import { connectRouterState } from 'utils/connectRouterState'
import {
	GetPartnersData,
	GetReceivedGiftCard,
	HideAlert,
	HideLoader,
	ShowLoader,
	SweetAlert,
} from 'redux/actions'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import {
	NewTab,
	NewTabs,
	NewTabList,
	NewTabPanel,
} from 'components/NewTab/styles'
import GiftList from './components/GiftList'
import * as S from './styles'
import Autocomplete from '@mui/material/Autocomplete'
import { getProgramTitle } from '../../../utils/utils'

const receivedStyle = {
	...extendedFormsStyle,
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	inputHeight: {
		height: '9px',
	},
}

function DateMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}


const createOption = (_label, _value) => ({ label: _label, value: _value })

const options = [
	createOption('Últimos 7 dias', 'last7days'),
	createOption('Últimos 30 dias', 'last30days'),
	createOption('Personalizado', 'custom'),
]

const customSelectStyles = {
	control: (base, state) => ({
		...base,
		input: { height: '26px' },
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}
class Received extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchPeriod: '',
			period: '',

			searchDateFrom: '',
			dateFrom: '',

			searchDateTo: '',
			dateTo: '',

			searchDestiny: '',
			destiny: '',

			disabeldData: false,

			results: false,
			invoiceDataFailed: false,
			pageSize: 10,
			recordCount: 0,

			currentPage: 1,
			isHidden: true,

			entryType: 1,

			snackStatus: {
				open: false,
				severity: 'success',
				text: '',
			},

			tabIndex: 0,

			destaqueIds: [],
		}

		if (localStorage.getItem('i18nextLng') !== null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		let dateFrom = _.get(this.props.urlState, 'searchDateFrom', '')
		let dateTo = _.get(this.props.urlState, 'searchDateTo', '')
		let destiny = _.get(this.props.urlState, 'searchDestiny', '')
		let pageSize = _.get(this.props.urlState, 'pageSize', '')
		let searchPeriod = { label: '', value: '' }
		searchPeriod.label = _.get(this.props.urlState, 'searchPeriod', '')

		this.props.setURLState({
			entryType: 1,
		})
		this.setState({ tabIndex: 0 })

		if (searchPeriod.label !== '') {
			this.props
				.GetPartnersData(searchPeriod.label.split('|')[0].trim(), '', 1)
				.then(() => {
					this.props.partnersData.results.map(
						(partner) => (searchPeriod.value = partner.id),
					)
					this.setState(
						{
							searchDateFrom: dateFrom,
							dateFrom:
								moment(dateFrom).isValid() &&
								moment(dateFrom).format('L'),
							searchDateTo: dateTo,
							dateTo:
								moment(dateTo).isValid() &&
								moment(dateTo).format('L'),
							searchPeriod,
							searchDestiny: destiny,
							period: searchPeriod.value,
							currentPage: _.get(
								this.props.urlState,
								'currentPage',
								1,
							),
							pageSize: pageSize,
						},
						() => {
							this.props.GetReceivedGiftCard(
								_.get(
									this.props.urlState,
									'searchDateFrom',
									'',
								),
								_.get(this.props.urlState, 'searchDateTo', ''),

								_.get(this.props.urlState, 'searchDestiny', ''),
								_.get(this.props.urlState, 'entryType', ''),
								1,
								_.get(this.props.urlState, 'pageSize', ''),
							)
						},
					)
				})
		} else {
			this.props.GetReceivedGiftCard(
				'',
				'',
				'',
				this.state.entryType,
				1,
				this.state.pageSize,
			)
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		let prevUrlState = _.get(prevProps, 'urlState')
		let urlState = _.get(this.props, 'urlState')

		if (!_.isEqual(prevUrlState, urlState)) {
			if (!urlState.entryType) {
				this.props.setURLState({
					entryType: prevUrlState.entryType,
				})
			}
			this.props.ShowLoader(<Loader />)
			this.props.GetReceivedGiftCard(
				urlState.searchDateFrom,
				urlState.searchDateTo,
				urlState.searchDestiny,
				urlState.entryType
					? urlState.entryType
					: prevUrlState.entryType,
				urlState.currentPage,
				urlState.pageSize,
			)
		}

		if (prevProps.receivedCardData !== this.props.receivedCardData) {
			const results = this.props.receivedCardData.results.sort((a, b) => {
				let start = new Date(a.expirationDate)
				let end = new Date(b.expirationDate)
				return start - end
			})
			this.setState({ ...this.props.receivedCardData, results })
			this.props.HideLoader()
		}

		if (prevProps.giftCardError !== this.props.giftCardError) {
			this.props.HideLoader()
			this.setState({ invoiceDataFailed: true })
			this.props.SweetAlert(
				<Alert
					error
					style={{ display: 'block' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					{'Erro ao efetuar a operação.'}
				</Alert>,
			)
		}
	}

	componentWillUnmount() {
		this.props.HideAlert()
	}

	handlePartnerChange = (newValue) => {
		this.setState({ searchPeriod: newValue, partnerId: newValue.value })
	}

	handleDateChange = (state, name, event) => {
		var value = event
		if (this.state.disabeldData) {
			this.setState({
				searchDateFrom: null,
				dateFrom: null,
				searchDateTo: null,
				dateTo: null,
			})
			return false
		}
		//
		if (this.state.dateFrom && name === 'dateTo') {
			if (moment(this.state.searchDateFrom).isAfter(moment(value))) {
				value = moment(this.state.searchDateFrom)
			}
		}
		this.setState({
			[name]: value,
			[state]: moment(value).isValid()
				? moment(value).format('YYYY-MM-DD')
				: '',
		})

		if (name === 'dateFrom' && this.state.dateTo) {
			if (moment(this.state.searchDateTo).isBefore(moment(value))) {
				this.handleDateChange('searchDateTo', 'dateTo', event)
			}
		}
	}

	handleChangePeriod = (event, value) => {
		this.setState({ searchPeriod: value, period: value.value })
		//
		let endDate = null
		if (value.value === 'custom') {
			this.setState({ disabeldData: false })
		} else if (value.value === 'last7days') {
			this.setState({ disabeldData: true })
			//
			const subtract = moment().subtract(7, 'days')
			endDate = subtract
			this.setState({
				searchDateFrom: endDate,
				dateFrom: endDate,
				searchDateTo: moment(),
				dateTo: moment(),
			})
			//
		} else if (value.value === 'last30days') {
			this.setState({ disabeldData: true })
			//
			const subtract = moment().subtract(30, 'days')
			endDate = subtract
			this.setState({
				searchDateFrom: endDate,
				dateFrom: endDate,
				searchDateTo: moment(),
				dateTo: moment(),
			})
		}
	}

	handleChange = (event) => {
		const id = event.target.id || event.target.name
		const value = event.target.value
		this.setState({ [id]: value })
	}

	handleSearch = (currentPage) => {
		this.props.setURLState({ currentPage })
	}
	handleSearchPage = (pageSize) => {
		this.props.setURLState({ pageSize })
	}

	validateEmail = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	}

	handleFilter = () => {
		const {
			searchDateFrom,
			searchDateTo,
			// searchPeriod,
			searchDestiny,
			// entryType,
		} = this.state
		if (!_.isEmpty(searchDestiny) && !this.validateEmail(searchDestiny)) {
			this.setState({
				snackStatus: {
					open: true,
					severity: 'warning',
					text: `'${searchDestiny}' ${_.toLower(
						this.props.t('Email'),
					)}`,
				},
			})
			return false
		}
		//
		this.props.setURLState({
			searchDateFrom: !_.isEmpty(searchDateFrom)
				? moment(searchDateFrom).format('YYYY-MM-DD')
				: '',
			searchDateTo: !_.isEmpty(searchDateFrom)
				? moment(searchDateTo).format('YYYY-MM-DD')
				: '',
			searchDestiny,
			currentPage: 1,
		})
	}

	handleClearFilter = () => {
		this.setState(
			{
				searchDateFrom: '',
				dateFrom: '',
				searchDateTo: '',
				dateTo: '',
				searchPeriod: '',
				period: '',
				searchDestiny: '',
			},
			() => this.handleFilter(),
		)
	}

	getPartnerOptions = (value, callback) => {
		const inputValue = value.trim().toLowerCase()
		return this.props.GetPartnersData(inputValue, '', 1).then(() => {
			if (this.props.partnersData) {
				return callback(
					this.props.partnersData.results.map((partner) => {
						if (partner) {
							return {
								value: partner.id,
								label:
									partner.cnpj && partner.cnpj !== null
										? partner.name +
										' | ' +
										FormatCNPJ(partner.cnpj)
										: partner.name,
							}
						}
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	loadPartnerOptions = (inputValue, callback) => {
		this.getPartnerOptions(inputValue, callback)
	}

	toggleShownRowIndex = (index) => {
		this.setState({
			showRowIndex: index,
		})
	}

	handleShowPartialUsedGiftCard = (digitalWalletDestiny) => {
		let destaqueIds = []
		digitalWalletDestiny.forEach((item) => {
			destaqueIds.push(item?.digitalWalletDestiny)
		})

		this.props.setURLState({
			entryType: 2,
		})

		this.setState({ destaqueIds: destaqueIds })

		this.setState({ tabIndex: 1 })
	}

	render() {
		const { t, classes } = this.props
		const {
			disabeldData,
			dateFrom,
			dateTo,
			searchPeriod,
			results,
			pageSize,
			entryType,
			recordCount,
			searchDestiny,
			currentPage,
			tabIndex,
			// destaqueIds,
		} = this.state
		return (
			<S.Container>
				<DocumentTitle title={getProgramTitle('Vales presentes recebidos')} />

				<Section title={'Vales presentes recebidos'} />

				<NewTabs
					selectedIndex={tabIndex}
					onSelect={(index) => {
						this.setState({ tabIndex: index })
					}}
				>
					<NewTabList
						style={{
							width: '100%',
						}}
					>
						<NewTab
							onClick={() => {
								this.props.setURLState({
									entryType: 1,
									currentPage: 1,
									pageSize: 10,
								})
								this.setState({
									destaqueIds: [],
								})
							}}
						>
							Creditados
						</NewTab>
						<NewTab
							style={{ marginLeft: '3px' }}
							onClick={() => {
								this.props.setURLState({
									entryType: 2,
									currentPage: 1,
									pageSize: 10,
								})
								this.setState({
									destaqueIds: [],
								})
							}}
						>
							Utilizados
						</NewTab>
						<NewTab
							style={{ marginLeft: '3px' }}
							onClick={() => {
								this.props.setURLState({
									entryType: 3,
									currentPage: 1,
									pageSize: 10,
								})
								this.setState({
									destaqueIds: [],
								})
							}}
						>
							Inativos
						</NewTab>
					</NewTabList>

					{/* Filtro */}

					<S.FilterBox className={classes.filterForm}>
						<S.InputBox>
							<S.InputPeriod>
								<Autocomplete
									id="combo-box-demo"
									size="small"
									options={options}
									getOptionLabel={(option) =>
										option.label ? t(option.label) : ''
									}
									disableClearable={true}
									onChange={(event, value) =>
										this.handleChangePeriod(event, value)
									}
									value={searchPeriod}
									disablePortal
									renderInput={(params) => (
										<CssTextField
											{...params}
											style={{
												width: '100%',
												backgroundColor: '#fff',
											}}
											label={t('FIELD_PERIOD')}
											variant="outlined"
											size="small"
										/>
									)}
								/>
							</S.InputPeriod>
							<S.dateBox>
								<S.DivDate>
									<Datetime
										className={styles.dateInputBlock}
										id="searchDateFrom"
										timeFormat={false}
										value={dateFrom}
										disabled={disabeldData}
										onChange={this.handleDateChange.bind(
											null,
											'searchDateFrom',
											'dateFrom',
										)}
										renderInput={(props) => {
											return (
												<CssTextField
													label={t('DATETIME_FROM')}
													variant="outlined"
													margin="dense"
													disabled={disabeldData}
													style={{
														backgroundColor: '#fff',
													}}
													{...props}
													value={
														!_.isEmpty(
															this.state
																.searchDateFrom,
														)
															? props.value
															: ''
													}
													InputProps={{
														endAdornment: (
															<InputAdornment>
																<i className="icon-data" />
															</InputAdornment>
														),
														inputComponent:
															DateMaskCustom,
													}}
												/>
											)
										}}
									/>
								</S.DivDate>

								<S.DivDate>
									<Datetime
										className={styles.dateInputBlock}
										id="searchDateTo"
										timeFormat={false}
										value={dateTo}
										disabled={disabeldData}
										onChange={this.handleDateChange.bind(
											null,
											'searchDateTo',
											'dateTo',
										)}
										renderInput={(props) => {
											return (
												<CssTextField
													label={t('DATETIME_TO')}
													variant="outlined"
													margin="dense"
													style={{
														backgroundColor: '#fff',
													}}
													{...props}
													value={
														!_.isEmpty(
															this.state
																.searchDateTo,
														)
															? props.value
															: ''
													}
													InputProps={{
														disabled: disabeldData,
														endAdornment: (
															<InputAdornment>
																<i className="icon-data" />
															</InputAdornment>
														),
														inputComponent:
															DateMaskCustom,
													}}
												/>
											)
										}}
									/>
								</S.DivDate>
							</S.dateBox>
							<S.inputDestiny>
								<CssTextField
									fullWidth
									label={`${t('FIELD_ORIGIN_GIFT')} (e-mail)`}
									id="searchDestiny"
									name="searchDestiny"
									value={searchDestiny}
									onChange={this.handleChange}
									variant="outlined"
									margin="dense"
									style={{
										backgroundColor: '#fff',
									}}
									disabled={false}
									InputProps={{
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline:
												classes.notchedOutline,
										},
									}}
									InputLabelProps={{
										classes: {
											root: classes.label,
											focused: classes.focusedLabel,
											error: classes.erroredLabel,
										},
									}}
								/>
							</S.inputDestiny>
						</S.InputBox>
						<S.BtnFilterContain>
							<CustomButtons onClick={this.handleClearFilter}>
								{t('BUTTON_CLEAN_FILTER')}
							</CustomButtons>
							<CustomButtonsBackground
								onClick={() => this.handleFilter()}
							>
								{t('BUTTON_FIND')}
							</CustomButtonsBackground>
						</S.BtnFilterContain>
					</S.FilterBox>

					<NewTabPanel>
						<GiftList
							results={results}
							currencySymbol={
								this.props.generalRulesData.currencySymbol
							}
							entryType={entryType}
							pageSize={_.get(
								this.props.urlState,
								'pageSize',
								'',
							)}
							recordCount={recordCount}
							currentPage={currentPage}
							handleSearch={this.handleSearch}
							handleSearchPage={this.handleSearchPage}
							handleUsedButton={
								this.handleShowPartialUsedGiftCard
							}
							classes={classes}
							destaqueIds={this.state.destaqueIds}
						/>
					</NewTabPanel>
					<NewTabPanel>
						<GiftList
							results={results}
							currencySymbol={
								this.props.generalRulesData.currencySymbol
							}
							entryType={entryType}
							pageSize={_.get(
								this.props.urlState,
								'pageSize',
								'',
							)}
							recordCount={recordCount}
							currentPage={currentPage}
							handleSearch={this.handleSearch}
							handleSearchPage={this.handleSearchPage}
							handleUsedButton={
								this.handleShowPartialUsedGiftCard
							}
							classes={classes}
							destaqueIds={this.state.destaqueIds}
						/>
					</NewTabPanel>
					<NewTabPanel>
						<GiftList
							results={results}
							currencySymbol={
								this.props.generalRulesData.currencySymbol
							}
							entryType={entryType}
							pageSize={_.get(
								this.props.urlState,
								'pageSize',
								'',
							)}
							recordCount={recordCount}
							currentPage={currentPage}
							handleSearch={this.handleSearch}
							handleSearchPage={this.handleSearchPage}
							handleUsedButton={
								this.handleShowPartialUsedGiftCard
							}
							classes={classes}
							destaqueIds={this.state.destaqueIds}
						/>
					</NewTabPanel>
				</NewTabs>
			</S.Container>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		userData: state.users.userData,
		partnersData: state.partners.partnersData,
		partnersDataFailed: state.partners.partnersDataFailed,
		generalRulesData: state.generalRules.generalRulesData,
		receivedCardData: state.users.receivedCardData,
		giftCardError: state.users.giftCardError,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetPartnersData,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
			GetReceivedGiftCard,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRouterState({
		searchPeriod: '',
		searchDateFrom: '',
		searchDateTo: '',
		searchDestiny: '',
		currentPage: 1,
		pageSize: 10,
	}),
	withTranslation(),
	withStyles(receivedStyle),
)(Received)
