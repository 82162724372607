/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// CONTEXT

// MATERIAL
import { FormControl } from '@material-ui/core'

// Styles
import {
    AddBotIcon,
    Button,
    GridContainer,
    GridItem,
    MuiAutocomplete,
    MuiBox,
    MuiDatePicker,
    MuiInput,
    MuiStack,
    MuiTypography,
    RemoveIcon,
} from '../../../components/storybook'
import { status } from '../utils'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'
import { IconButton } from '@mui/material'

const SearchForm = ({ getNegotiationFilter }) => {
    const { t } = useTranslation()
    const [theme, setTheme] = useState(GetThemeColorFromStorage())
    const [showSecondContainer, setShowSecondContainer] = useState(false)

    const { control, handleSubmit, watch, setValue } = useForm({})

    const toggleSecondContainer = () => {
        setShowSecondContainer(!showSecondContainer)
    }

    const watchAllFields = watch()

    const handleFormSubmit = async (data) => {
        const filter = {
            negotiationCampaign: data?.name_negotiation,
            Status: data?.status?.value,
            startPeriod:
                data.dateFilterTo && data.dateFilterTo
                    ? new Date(data.dateFilterTo).toISOString()
                    : null,
            endPeriod:
                data.dateFilterExpiration && data.dateFilterExpiration
                    ? new Date(data.dateFilterExpiration).toISOString()
                    : null,
            createdAt:
                data.dateCreated && data.dateCreated
                    ? new Date(data.dateCreated).toISOString()
                    : null,
        }

        await getNegotiationFilter(filter)
    }

    const handleClear = () => {
        setValue('name_negotiation', '')
        setValue('dateFilterExpiration', null)
        setValue('dateFilterTo', null)
        setValue('dateCreated', null)
        setValue('status', null)

        getNegotiationFilter({})
    }

    useEffect(() => {
        setTheme(GetThemeColorFromStorage())
    }, [])

    return (
        <MuiBox
            sx={{
                backgroundColor: '#F8F8F8',
                padding: '10px 20px 20px 20px',
                borderRadius: '5px',
                width: '100%',
                '.MuiInputBase-input.Mui-disabled': {
                    opacity: 1,
                    background: '#F2F2F2 !important',
                },
            }}
            mt={3}
        >
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <GridContainer spacing={1}>
                    <GridItem lg={3} xs={12} md={3} mt={1}>
                        <FormControl fullWidth>
                            <Controller
                                name="name_negotiation"
                                control={control}
                                render={({ ...field }) => (
                                    <MuiInput
                                        {...field}
                                        placeholder={t('NEGOTIATION_CAMPAIGN')}
                                        showLabel
                                        textLabel={
                                            watchAllFields.name_negotiation
                                                ? t('NEGOTIATION_CAMPAIGN')
                                                : ' '
                                        }
                                        defaultColor={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                        background="#F6F6F6!important"
                                    />
                                )}
                            />
                        </FormControl>
                    </GridItem>

                    <GridItem lg={3} xs={12} md={3}>
                        <MuiBox
                            sx={{
                                '& .MuiFormControl-root.MuiTextField-root': {
                                    minWidth: 0,
                                },
                            }}
                        >
                            <Controller
                                control={control}
                                name="dateCreated"
                                render={({ ...field }) => (
                                    <MuiDatePicker
                                        {...field}
                                        placeholder="Vigência"
                                        showLabel
                                        textLabel={
                                            watchAllFields.dateCreated
                                                ? 'Criação'
                                                : ' '
                                        }
                                        defaultValue={null}
                                        background="#F6F6F6!important"
                                        defaultColor={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                        width="100%!important"
                                    />
                                )}
                            />
                        </MuiBox>
                    </GridItem>
                    <GridItem lg={3} xs={12} md={3}>
                        <MuiBox
                            sx={{
                                '& .MuiBox-root': { paddingBottom: '-8px' },
                                '& .MuiFormControl-root.MuiTextField-root': {
                                    minWidth: 0,
                                },
                            }}
                        >
                            <Controller
                                control={control}
                                name="dateFilterTo"
                                render={({ ...field }) => (
                                    <MuiDatePicker
                                        {...field}
                                        placeholder="Inicio"
                                        showLabel
                                        textLabel={
                                            watchAllFields.dateFilterTo
                                                ? 'Inicio'
                                                : ' '
                                        }
                                        defaultValue={null}
                                        background="#F6F6F6!important"
                                        defaultColor={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                    />
                                )}
                            />
                        </MuiBox>
                    </GridItem>
                    <GridItem lg={3} xs={12} md={3}>
                        <Controller
                            control={control}
                            name="dateFilterExpiration"
                            render={({ ...field }) => (
                                <MuiBox
                                    sx={{
                                        '& .MuiFormControl-root.MuiTextField-root':
                                            {
                                                minWidth: 0,
                                            },
                                        '& .MuiBox-root': {
                                            paddingBottom: '-8px',
                                            '& .MuiStack-root.MuiFormControl-root.MuiTextField-root':
                                                {
                                                    minWidth:
                                                        'initial!important',
                                                },
                                        },
                                    }}
                                >
                                    <MuiDatePicker
                                        {...field}
                                        placeholder="Expiração"
                                        showLabel
                                        textLabel={
                                            watchAllFields.dateFilterExpiration
                                                ? 'Expiração'
                                                : ' '
                                        }
                                        defaultValue={null}
                                        background="#F6F6F6!important"
                                        defaultColor={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                    />
                                </MuiBox>
                            )}
                        />
                    </GridItem>
                </GridContainer>
                {showSecondContainer && (
                    <GridContainer spacing={1}>
                        <GridItem lg={3} xs={12} md={3}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                            >
                                <Controller
                                    name="status"
                                    defaultValue=""
                                    control={control}
                                    render={({ onChange, value }) => (
                                        <MuiAutocomplete
                                            options={status}
                                            required
                                            textLabel={
                                                watchAllFields.status
                                                    ? 'Status'
                                                    : ' '
                                            }
                                            showLabel
                                            value={value}
                                            onChange={(_e, newValue) => {
                                                onChange(newValue)
                                            }}
                                            placeholder="Status"
                                            defaultColor={
                                                theme?.navigation?.buttons
                                                    ?.backgroundColor
                                            }
                                            sx={{ background: '#F6F6F6' }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                )}
                <MuiStack
                    direction="row"
                    spacing={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <MuiStack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        spacing={1}
                    >
                        <IconButton
                            onClick={toggleSecondContainer}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                                padding: 0,
                            }}
                        >
                            {showSecondContainer ? (
                                <>
                                    <RemoveIcon
                                        size={35}
                                        color={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <AddBotIcon
                                        size={30}
                                        color={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                    />
                                </>
                            )}
                        </IconButton>
                        {showSecondContainer ? (
                            <MuiTypography
                                onClick={toggleSecondContainer}
                                sx={{
                                    color: theme?.navigation?.buttons
                                        ?.backgroundColor,
                                    cursor: 'pointer',
                                }}
                            >
                                Ocultar filtros
                            </MuiTypography>
                        ) : (
                            <MuiTypography
                                onClick={toggleSecondContainer}
                                sx={{
                                    color: theme?.navigation?.buttons
                                        ?.backgroundColor,
                                    cursor: 'pointer',
                                }}
                            >
                                Mais filtros
                            </MuiTypography>
                        )}
                    </MuiStack>
                    <MuiStack
                        direction="row"
                        spacing={2}
                        display="flex"
                        alignItems="center"
                        pr={1}
                    >
                        <Button
                            text={t('BUTTON_FIND')}
                            typeVariation="TextButton"
                            type="submit"
                            sx={{
                                color: '#FFFFFF!important',
                                textTransform: 'unset',
                                width: '100%',
                                height: '40px',
                                padding: '5px 15px',
                                backgroundColor: '#329EF1!important',
                            }}
                        />
                        <Button
                            text="Limpar"
                            typeVariation="CleanButton"
                            onClick={handleClear}
                            sx={{
                                border: '1px solid #C8C8C8',
                                width: '100%',
                                height: '40px',
                                color: '#C81010!important',
                                textTransform: 'unset',
                            }}
                        />
                    </MuiStack>
                </MuiStack>
            </form>
        </MuiBox>
    )
}

export default SearchForm
