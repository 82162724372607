import * as helpers from './transferHandlerHelpers.js'
import { dealWithAPI } from './transferHandler_DealWithAPI'

const pixOption = 1
const tedOption = 2

//BANCO =================================================

export const Banco = async (
    showModal,
    setTransferRequisitionResponse,
    userPasswordFromDataBase,
    setPasswordModal_open,
    userBankData,
    _value,
    _anticipationFee,
    _transferFee,
    _liquidValue,
) => {
    // if(userBankData){
    // if(userBankData){
    return dealWithAPI(
        showModal,
        setTransferRequisitionResponse,
        userPasswordFromDataBase,
        setPasswordModal_open,
        tedOption,
        _value,
        _anticipationFee,
        _transferFee,
        _liquidValue,
    )
    // }
}

//PIX ===================================================

function writeValidationFor(_userPixKey) {
    switch (_userPixKey) {
        case 'CPF':
            return helpers.isValidCPF(_userPixKey) //transferHandler.CPF(setPasswordModal_open, userPixKey, ...parameters); break;
        case 'Celular':
            return helpers.isValidPhone(_userPixKey) //transferHandler.Celular(setPasswordModal_open, userPixKey, ...parameters); break;
        case 'E-mail':
            return helpers.isValidEmail(_userPixKey) //transferHandler.Email(setPasswordModal_open, userPixKey, ...parameters); break;
        case 'Chave aleatória':
            return helpers.isValidAleatoryKey(_userPixKey) //transferHandler.Aleatoria(setPasswordModal_open, userPixKey, ...parameters); break;
        default:
            return false //transferHandler.Default(userPixKey); break;
    }
}

export const Pix = async (
    showModal,
    setTransferRequisitionResponse,
    userPasswordFromDataBase,
    setPasswordModal_open,
    userPixKeyType,
    userPixKey,
    _value,
    _anticipationFee,
    _transferFee,
    _liquidValue,
) => {
    return dealWithAPI(
        showModal,
        setTransferRequisitionResponse,
        userPasswordFromDataBase,
        setPasswordModal_open,
        pixOption,
        _value,
        _anticipationFee,
        _transferFee,
        _liquidValue,
    )
}
