/* eslint-disable import/first */
export var typeBranchMock = {
    "BusinessUnit": {
        type: "BusinessUnit",
        bgColor: "#FB9006",
        symbol: "UN"
    },
    "CostCenter": {
        type: "CostCenter",
        bgColor: "#3EC450",
        symbol: "CC"
    },
    "Department": {
        type: "Department",
        bgColor: "#1BBED3",
        symbol: "DP"
    },
    "Branch": {
        type: "Branch",
        bgColor: "#7E6BB9",
        symbol: "UN"
    },
    "Simple": {
        type: "Simple",
        bgColor: "#B4DFE3",
        symbol: "FL"
    },
    "Franchise": {
        type: "Franchise",
        bgColor: "#DFAE93",
        symbol: "FR"
    },
    "Matrix": {
        type: "Matrix",
        bgColor: "",
        symbol: ""
    },
};
export var ListDivision = [
    {
        "description": 'ACESSÓRIO DE FAXINAL ',
        "value": '00b06bb6-74f2-40d7-a764-b38433ac6b3a',
        "segType": 'Branch',
    },
    {
        "description": 'BELA VISTA ',
        "value": '02f3f09b-02a1-4d76-bbc2-be5c865dd325',
        "segType": 'BusinessUnit'
    },
    {
        "description": 'SÃO LUIZ GONZAGA ',
        "value": '038b1757-f5be-479a-bcfd-26add13bd902',
        "segType": 'Branch'
    },
    {
        "description": 'BOCAVERÁ ',
        "value": '04120ce5-0c66-4260-b195-5d758e7038c7',
        "segType": 'Franchise'
    },
    {
        "description": 'NOVO HORIZONTE ',
        "value": '062402a8-16a6-4b7a-bac5-a6b60afb04cb',
        "segType": 'Branch'
    },
];
export var defaultOptions = [
    {
        "description": "ACESSÓRIO DE FAXINAL ",
        "value": "00b06bb6-74f2-40d7-a764-b38433ac6b3a",
        "segType": "Branch",
        "price": "12324.23"
    },
    {
        "description": "SÃO LUIZ GONZAGA ",
        "value": "038b1757-f5be-479a-bcfd-26add13bd902",
        "segType": "Branch",
        "price": "324.23"
    }
];
