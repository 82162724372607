import * as actionTypes from './actionsTypes';
import * as API from '../api/platformRules';
import * as Loader from './loader';

export const fetcPlatformRules = (data) => {
    return {
        type: actionTypes.FETCH_PLATFORM_RULES_DATA,
        platformRulesData: data
    };
};

export const postConsumerPlatformRulesData = (data) => {
    return {
        type: actionTypes.POST_PLATFORM_RULES_DATA,
        consumerPlatformRulesData: data
    };
};

// FAIL FETCH -------------------------------------
export const fetcPlatformRulesFailed = (error) => {
    return {
        type: actionTypes.FETCH_PLATFORM_RULES_FAILED,
        reglationDataFailed: error
    };
};

export const postConsumerPlatformRulesFailed = (error) => {
    return {
        type: actionTypes.POST_PLATFORM_RULES_FAILED,
        consumerPlatformRulesFailed: error
    };
};

//----------------------------------------------------

export const GetPlatformRulesData = () => {
    return dispatch => {
        API.GetPlatformRules().then(response => {
            dispatch(fetcPlatformRules(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetcPlatformRulesFailed());
            dispatch(Loader.hideLoader());
        })
    };
};

export const PostConsumerPlatformRulesData = (obj) => {
    return dispatch => {
        API.SetConsumerPlatformRules(obj).then(response => {
            dispatch(postConsumerPlatformRulesData(response));
        }).catch(error => {
            console.error(error);
            dispatch(postConsumerPlatformRulesFailed());
            dispatch(Loader.hideLoader());
        })
    };
};