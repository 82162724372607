import axios from "axios";

export const MetricsBannersCount = (id, view, click) => {
  let url = `/Banner/MetricsBannersCount/${id}`;

  if (view) url += `?view=${view}`;
  if (click) url += `?click=${click}`;

  url = url.replace(/['"]+/g, "");

  return axios.put(url).then((response) => response.data);
};
