import React from 'react'
import {
    InvoicesNegotiation,
    MuiBox,
    MuiTooltip,
    MuiTypography,
    TableConsumer,
    formatBRCurrency,
} from '../../../../components/storybook'
import { formatDateModal } from '../../../Negotiation/functions'
import { useMediaQuery } from '@mui/material'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { useParams } from 'react-router-dom'
import { numberFormatText } from 'utils/utils'

export function Table({
    negotiationDetailsTable,
    pointsMask,
    tablePagination,
    getPaginatedNegotiations,
    isLoadingDetailsTable,
    cashbackMask,
}) {
    const { id } = useParams()
    const matches = useMediaQuery('(max-width:600px)')

    const redirectToDetailPage = (id) => {
        window.location.href = `/shopHistory/detail/${id}`
    }

    // const groupByInvoice = (data) => {
    //     if (!data) return {}

    //     const groupedData = {}

    //     data.forEach((item) => {
    //         const invoiceId = item.invoiceId

    //         if (!groupedData[invoiceId]) {
    //             groupedData[invoiceId] = { ...item }
    //         } else {
    //             groupedData[invoiceId].quantity += item.quantity
    //             groupedData[invoiceId].totalInvoice += item.totalInvoice
    //             groupedData[invoiceId].total += item.total
    //         }
    //     })

    //     const dataArray = Object.values(groupedData)

    //     return dataArray
    // }
    // const groupedData = groupByInvoice(negotiationDetailsTable?.results)

    // const groupedDataArray = Object.values(groupedData).flatMap(
    //     (group) => group,
    // )

    // const totalItems = groupedDataArray?.length

    return (
        <MuiBox
            mt={2}
            sx={{
                overflow: 'auto!important',
                '& .table tr:nth-child(even)': {
                    backgroundColor: '#FFFFFF!important',
                },
            }}
        >
            <TableConsumer
                columns={[
                    {
                        id: 'id',
                        label: '',
                        render: (rowData) => (
                            <>
                                <MuiTooltip title={'Visualizar NF'}>
                                    <button
                                        style={{
                                            background: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            redirectToDetailPage(
                                                rowData?.invoiceId,
                                            )
                                        }
                                    >
                                        <InvoicesNegotiation
                                            size={20}
                                            color={
                                                GetThemeColorFromStorage()
                                                    ?.navigation?.buttons
                                                    ?.backgroundColor
                                            }
                                        />
                                    </button>
                                </MuiTooltip>
                            </>
                        ),
                    },
                    {
                        align: 'left',
                        id: 'Centro',
                        label: 'Centro',
                        render: (rowData) => (
                            <MuiTooltip
                                title={`${rowData?.partnerBranchInfo?.key} - ${rowData?.partnerBranchInfo?.value}`}
                                sx={{ cursor: 'pointer' }}
                            >
                                <MuiTypography
                                    variant="body2"
                                    sx={{
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitLineClamp: 1,
                                        color: '#636363',
                                        fontSize: 12,
                                        textAlign: 'left',
                                    }}
                                >
                                    {rowData?.partnerBranchInfo?.key} -{' '}
                                    {rowData?.partnerBranchInfo?.value}
                                </MuiTypography>
                            </MuiTooltip>
                        ),
                    },
                    {
                        align: 'left',
                        id: 'Nº da NF',
                        label: 'Nº da NF',
                        minWidth: '125px',

                        render: (rowData) => (
                            <MuiTypography
                                variant="body2"
                                sx={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    WebkitLineClamp: 1,
                                    color: '#636363',
                                    fontSize: 12,
                                }}
                            >
                                {rowData?.invoiceDocumentID ?? '-'}
                            </MuiTypography>
                        ),
                    },
                    {
                        align: 'left',
                        id: 'Produto/Data da compra',
                        label: 'Produto/Data da compra',
                        minWidth: '225px',

                        render: (rowData) => (
                            <MuiTooltip
                                title={rowData?.product?.value}
                                sx={{ cursor: 'pointer' }}
                            >
                                <MuiBox
                                    sx={{
                                        width: '200px',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <>
                                        <MuiTypography
                                            variant="body2"
                                            sx={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 1,
                                                color: '#636363',
                                                fontSize: 12,
                                                textAlign: 'left',
                                            }}
                                        >
                                            {rowData?.product?.value ?? '-'}
                                        </MuiTypography>
                                        <MuiTypography
                                            variant="body2"
                                            sx={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 1,
                                                color: '#636363',
                                                fontSize: 12,
                                                textAlign: 'left',
                                            }}
                                        >
                                            {formatDateModal(rowData?.date) ??
                                                '-'}
                                        </MuiTypography>
                                    </>
                                </MuiBox>
                            </MuiTooltip>
                        ),
                    },
                    {
                        align: 'left',
                        id: 'Qtd.',
                        label: 'Qtd.',
                        render: (rowData) => (
                            <MuiTypography
                                variant="body2"
                                sx={{
                                    color: '#636363',
                                    fontSize: 12,
                                }}
                            >
                                {rowData?.quantity ?? '-'}
                            </MuiTypography>
                        ),
                    },

                    {
                        align: 'left',
                        id: 'Valor da compra',
                        label: 'Valor da compra',
                        minWidth: '153px',

                        render: (rowData) => (
                            <MuiTypography
                                variant="body2"
                                sx={{
                                    color: '#636363',
                                    fontSize: 12,
                                }}
                            >
                                {formatBRCurrency(rowData?.totalInvoice) ?? '-'}
                            </MuiTypography>
                        ),
                    },
                    {
                        align: 'center',
                        id: 'Benefício',
                        label: 'Benefício',
                        minWidth: '100px',

                        render: (rowData) => (
                            <MuiTypography
                                variant="body2"
                                sx={{
                                    color: '#636363',
                                    fontSize: 12,
                                }}
                            >
                                {rowData?.typeOfBenefit?.value === 'Cashback'
                                    ? formatBRCurrency(rowData?.total)
                                    : numberFormatText(
                                          rowData?.total,
                                          `${pointsMask} `,
                                          0,
                                      ) || '-'}
                            </MuiTypography>
                        ),
                    },
                ]}
                firstItemPadding={
                    negotiationDetailsTable?.results?.length
                        ? '10px 10px!important'
                        : '10px 16px!important'
                }
                padding="0px 16px 0px 16px!important"
                notFoundMessage="Não há registros de produtos"
                onChangeRowsPerPage={() => {}}
                showPagination
                loading={isLoadingDetailsTable}
                data={negotiationDetailsTable?.results}
                totalItems={tablePagination?.totalPages}
                defaultRows={10}
                onChangePage={(page) => {
                    getPaginatedNegotiations(id, 'page', page, tablePagination)
                }}
                changeRowsPerPage={(rowsPerPage) => {
                    getPaginatedNegotiations(
                        id,
                        'pageSize',
                        rowsPerPage,
                        tablePagination,
                    )
                }}
                overflow={(matches && 'auto') || 'hidden'}
                defaultColor={
                    GetThemeColorFromStorage()?.navigation?.buttons
                        ?.backgroundColor
                }
            />
        </MuiBox>
    )
}
