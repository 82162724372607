import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

export const Container = styled(Grid)`
    .MuiIconButton-colorPrimary:hover,
    .MuiIconButton-root:hover {
        background-color: transparent !important;
    }
`

export const GridBox = styled(Grid)`
    b {
        color: #1f1f1f;
    }

    .MuiGrid-spacing-xs-2 {
        margin: 0;
    }

    @media screen and (min-width: 960px) {
        &.MuiGrid-item {
            margin-top: 0.7rem;
        }
    }

    @media screen and (min-width: 1200px) {
        &.MuiGrid-grid-xs-12 {
            max-width: 90%;
            margin: 0 auto;
        }
    }

    @media screen and (min-width: 1460px) {
        &.MuiGrid-grid-xs-12 {
            max-width: 75%;
        }
    }
`

export const ButtonBox = styled(Grid)`
    p {
        margin: 0;
        color: #1f1f1f;
        font-size: 15px;
        text-align: center;
    }

    > .MuiGrid-item {
        margin-top: 1rem;

        button {
            width: 100%;
            height: 36px;
            border-radius: 8px;
        }
    }
`

export const ErrorBox = styled.div`
    .mt-2 {
        margin-top: 20px;
    }
    a,
    span {
        font-size: 14px;
        font-weight: 700;
        color: #21409a;
    }
    .MuiTypography-body1 {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-weight: 300;
    }
    .error {
        .MuiTypography-body1 {
            color: #f44336;
        }
    }
    .MuiFormGroup-root {
        flex-direction: row;
    }
`
