import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import MaskedInput from 'react-text-mask'

// @material-ui/core components
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import { CssTextField } from 'components/CssTextField/CssTextField'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section/Section'
// icons
// style
import styles from '../resetPassword.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
// UTILS
import compose from 'utils/compose'
// MOMENT JS
import axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ValidateDocument } from '../../../../redux/api/user'
import { Box } from '@material-ui/core'

import { AlertStatus } from './Alert'
import Axios from 'axios'
import { replaceCpfCnpj } from '../../../CreateUser/helpers/utils'
import { AlertPhoneNumber } from './AlertPhoneNumber'
import { envs } from '../../../../redux/api/windowServerData'

class RequestPasswordReset extends React.Component {
	constructor(props) {
		super(props)
		this.getAccountStatus = this.getAccountStatus.bind(this)
		this.hideAlert = this.hideAlert.bind(this)
		this.state = {
			corporationPartners: true,
			submitMessage: '',
			alert: null,
			accountData: [],
			smsAlert: true,
		}
	}

	componentDidMount() {
		axios.get(`/ProgramRules/General/BasicData`).then((response) => {
			this.setState({
				corporationPartners: response.data.hasCorporationPartners,
			})
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.requestPasswordReset &&
			prevProps.requestPasswordReset !== this.props.requestPasswordReset
		) {
			if (this.props.requestPasswordReset.success) {
				this.props.handleMsg(
					'A senha será enviada para o e-mail cadastrado ' +
					this.props.requestPasswordReset.data,
					'success',
				)

				// this.setState({ submitMessage: "A senha será enviada para o e-mail cadastrado " + this.props.requestPasswordReset.data })
			} else if (
				this.props.requestPasswordReset?.errors[0].message !==
				'PHONENUMBER_DOES_NOT_VALIDATED' &&
				this.props.requestPasswordReset?.errors[0].message !==
				'EMAIL_DOES_NOT_VALIDATED'
			) {
				this.props.handleMsg(
					this.props.requestPasswordReset?.errors[0].message,
					'success',
				)
				// this.setState({
				//     submitMessage:
				//         this.props.requestPasswordReset?.errors[0].message,
				// })
			}
		}
	}

	CPFMaskCustom = (props) => {
		const { inputRef, ...other } = props
		return (
			<MaskedInput
				{...other}
				ref={(ref) => {
					inputRef(ref ? ref.inputElement : null)
				}}
				guide={false}
				mask={(rawValue) =>
					rawValue.replace(/[^\d]+/g, '').length <= 11 ||
						!this.state.corporationPartners
						? [
							/\d/,
							/\d/,
							/\d/,
							'.',
							/\d/,
							/\d/,
							/\d/,
							'.',
							/\d/,
							/\d/,
							/\d/,
							'-',
							/\d/,
							/\d/,
						]
						: [
							/\d/,
							/\d/,
							'.',
							/\d/,
							/\d/,
							/\d/,
							'.',
							/\d/,
							/\d/,
							/\d/,
							'/',
							/\d/,
							/\d/,
							/\d/,
							/\d/,
							'-',
							/\d/,
							/\d/,
						]
				}
				placeholderChar={'\u2000'}
			/>
		)
	}

	hideAlert = () => this.setState({ alert: false })

	async getAccountStatus() {
		const documentNumber = this.props.values.login
		const isRegister = true

		const response = await ValidateDocument(documentNumber, isRegister)

		if (response?.errors.length > 0 && response?.data) {
			this.setState({ accountData: response?.data })

			const hasPhoneError = response.errors[0].message.includes(
				'PHONENUMBER_DOES_NOT_VALIDATED',
			)

			const hasEmailError = response.errors[0].message.includes(
				'EMAIL_DOES_NOT_VALIDATED',
			)

			if (hasPhoneError) {
				console.log('hasPhoneError', hasPhoneError)
				this.setState({ smsAlert: true })
				this.setState({ alert: true })
			} else if (hasEmailError) {
				this.setState({ smsAlert: false })
				this.setState({ alert: true })
			} else {
				this.props.handleMsg(
					this.props.t(response?.errors[0].message),
					'error',
				)
			}
		} else {
			this.props.handleSubmit()
		}
	}

	sendEmail = async () => {
		const documentNumber = await replaceCpfCnpj(this?.props?.values?.login)

		const response = await Axios.put(
			`/Consumer/SendEmailConfirmationToken?cpfCnpj=${documentNumber}`,
			{
				// baseURL: envs.REACT_APP_GERMINI_API_URL,
				baseURL: envs.REACT_APP_GERMINI_API_URL,
			},
		)

		if (response?.data?.data?.errors?.length > 0) {
			this.setState({ alert: true })
			this.props.history.push('/login')
		} else {
			this.setState({ alert: false })
		}
	}

	sendSMS = async () => {
		const documentNumber = await replaceCpfCnpj(this?.props?.values?.login)

		await Axios.put(
			`/Consumer/SendSmsActivationCode?cpfCnpj=${documentNumber}`,
			{
				// baseURL: envs.REACT_APP_GERMINI_API_URL,
				baseURL: envs.REACT_APP_GERMINI_API_URL,
			},
		)

		this.setState({ alert: false })
		this.props.history.push('/login')
	}

	render() {
		const {
			t,
			classes,
			values,
			errors,
			touched,
			handleSubmit,
			handleCancel,
			handleChange,
			isValid,
			setFieldTouched,
			setFieldValue,
		} = this.props

		const change = (name, e) => {
			e.persist()
			handleChange(e)
			setFieldTouched(name, true, false)
		}

		return (
			<Fragment>
				<Box
					sx={{
						button: {
							border: '1px solid red',
						},
					}}
				>
					{this.state.alert && this?.state?.accountData && (
						<>
							{this?.state?.smsAlert ? (
								<AlertPhoneNumber
									hideAlert={this.hideAlert}
									onConfirm={this.sendSMS}
								/>
							) : (
								<AlertStatus
									hideAlert={this.hideAlert}
									sendEmail={this.sendEmail}
									accountData={this.state.accountData}
								/>
							)}
						</>
					)}
				</Box>

				<Section title={t('MENU_USER_RESET_PASSWORD')} />
				<GridContainer>
					<GridItem xs={6} md={6} style={{ marginTop: '7px' }}>
						<CssTextField
							fullWidth
							label={
								this.state.corporationPartners
									? 'CPF/CNPJ'
									: 'CPF'
							}
							id="login"
							name="login"
							error={touched.login && Boolean(errors.login)}
							value={values.login}
							onChange={change.bind(null, 'login')}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
							InputProps={{
								inputComponent: this.CPFMaskCustom,
							}}
						/>
					</GridItem>
					<GridItem xs={6} md={6} style={{ textAlign: 'right' }}>
						<Button
							color="greenBorderButtonColor"
							style={{
								width: '145px',
								fontSize: 15,
								height: 36,
								padding: '0 15px',
								borderRadius: '6px',
								marginTop: '15px',
							}}
							onClick={handleCancel}
						>
							{t('BUTTON_BACK')}
						</Button>
					</GridItem>
				</GridContainer>
				<Grid xs={12} style={{ marginTop: '7px' }}>
					{this.props.requestPasswordReset && (
						<>
							<div
								style={{
									color: 'rgb(226,60,60)',
									fontWeight: '900',
									fontFamily:
										"'Roboto', 'Helvetica', 'Arial', sans-serif",
								}}
							>
								{t(this.state.submitMessage)}
							</div>
						</>
					)}
				</Grid>
				<GridContainer style={{ paddingTop: '30px' }}>
					<GridItem xs={12}>
						<Button
							size="sm"
							variant="contained"
							color="greenButtonColor"
							className={styles.Button}
							onClick={this.getAccountStatus}
						>
							{t('REQUEST_RESET_PASSWORD')}
						</Button>
					</GridItem>
				</GridContainer>
			</Fragment>
		)
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const mapStateToProps = (state) => {
	return {
		requestPasswordReset: state.users.requestPasswordReset,
		resetPassword: state.users.resetPassword,
		resetPasswordFailed: state.users.resetPasswordFailed,
	}
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(extendedFormsStyle),
)(RequestPasswordReset)
