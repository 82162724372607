import {
    cardTitle,
    blackColor,
    hexToRgb,
    grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import { germiniDefaultColors } from '../../material-dashboard-pro-react'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

const extendedFormsStyle = {
    ...customCheckboxRadioSwitch,
    ...customSelectStyle,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
    },
    label: {
        cursor: 'pointer',
        paddingLeft: '0',
        color: germiniDefaultColors[5],
        fontSize: '14px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        display: 'inline-flex',
    },
    zebra: {
        '&:nth-child(even)': {
            backgroundColor: '#f2f2f2',
        },
    },
    TitleSession: {
        fontWeight: 'bold',
        fontSize: '18px',
        color: germiniDefaultColors[0],
        textTransform: 'uppercase',
        paddingBottom: '10px',
    },
    noPadding: {
        padding: '0 !important',
    },
    mrAuto: {
        marginRight: 'auto',
    },
    mlAuto: {
        marginLeft: 'auto',
    },
    ...buttonStyle,
    filterPlusButton: {
        marginRight: '5px',
        padding: '4px',
        color: GetThemeColorFromStorage()?.navigation?.buttons?.backgroundColor,
        border: '1px solid',
        borderColor:
            GetThemeColorFromStorage()?.navigation?.buttons?.backgroundColor,
        '&:hover': {
            color: 'white',
            background:
                GetThemeColorFromStorage()?.navigation?.buttons
                    ?.backgroundColor,
        },
    },
    filterForm: {
        margin: 'auto',
        background: '#F6F6F6',
        borderRadius: '5px',
        padding: '5px',
        paddingTop: '20px',
        width: '100%',
        marginBottom: '15px',
    },
}

export default extendedFormsStyle
