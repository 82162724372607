import React, { useState, useEffect } from 'react'
import compose from 'utils/compose'
import { withTranslation } from 'react-i18next'

// css
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

import * as S from './style'

const Section = ({ title, children }) => {
    const [themeColor, setThemeColor] = useState({})

    useEffect(() => {
        setThemeColor(
            GetThemeColorFromStorage()?.titles?.main?.textColor ?? '#333333',
        )
    }, [])

    return (
        <S.HeaderBox className="section_title_class">
            <S.sectionTitle borderColor={themeColor}>
                <span>{title}</span>
            </S.sectionTitle>

            {children && <S.BtnHeaderBox>{children}</S.BtnHeaderBox>}
        </S.HeaderBox>
    )
}

export default compose(withTranslation())(Section)
