import React from 'react'
import { GridContainer } from '../../../../components/storybook'
import { CardBankAccount } from '../CardBankAccount'
import { TransferValue } from '../TransferValue'
import { OriginLoot } from '../OriginLoot'
import { AcceptTerms } from '../AcceptTerms'
import { Warning } from '../Warning'
import { CardBalance } from '../CardBalance'
import { WithdrawalFeeWarning } from '../WithdrawalFeeWarning'

export function DigitalWallet({ ...sharedProps }) {
    const {
        handleSubmit,
        onSubmit,
        debouncedValue,
        isSmallScreen,
        currentTab,
        generalRules,
    } = sharedProps

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2} ml={0} pl={isSmallScreen ? 2 : 0}>
                {/************** CARD BANK ACCOUNT  **************/}
                <CardBankAccount {...sharedProps} />

                {/************** ORIGIN LOOT  **************/}
                <OriginLoot {...sharedProps} />

                {/************** NOTICES FEE THAT WILL BE CHARGED  **************/}
                {(currentTab === 'points' &&
                    generalRules?.allowPointsWithdrawFee) ||
                (currentTab !== 'points' && generalRules?.allowTaxTransfer) ? (
                    <WithdrawalFeeWarning {...sharedProps} />
                ) : null}

                {/************** CARD BALANCE  **************/}
                <CardBalance {...sharedProps} />

                {/************** WITHDRAWAL VALUE  **************/}
                <TransferValue {...sharedProps} />

                {debouncedValue && (
                    <>
                        <AcceptTerms {...sharedProps} />
                        <Warning {...sharedProps} />
                    </>
                )}
            </GridContainer>
        </form>
    )
}
