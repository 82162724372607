import React, { Fragment } from 'react'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import ButtonsContainer from './ButtonsContainer'
import Section from '../../components/Section/Section'
import * as S from './style'
import DocumentTitle from 'react-document-title'
import { getProgramTitle } from '../../utils/utils'

export default ({
	firstJSXElement = <Fragment></Fragment>,
	disableEditButton = false,
	disabledSaveButton = false,
	formTitle,
	handleEdit,
	handleSave,
	handleCancel,
	editName = 'Editar',
	cancelName = 'Cancelar',
	saveName = 'Salvar',
	disabledForm,
	showButtons = true,
	documentTitle,
}) => {
	return (
		<S.Container>
			<DocumentTitle title={getProgramTitle(documentTitle)} />
			<Section title={formTitle}>
				{showButtons && (
					<ButtonsContainer
						disabledSaveButton={disabledSaveButton}
						disableEditButton={disableEditButton}
						_onEdit={handleEdit}
						_onSubmit={handleSave}
						_onCancel={handleCancel}
						_booleanOfForm={disabledForm}
						_btnNameEdit={editName}
						_btnNameSubmit={saveName}
						_btnNameCancel={cancelName}
					/>
				)}
			</Section>
			<GridContainer>
				<GridItem xs={6} md={4}>
					{firstJSXElement}
				</GridItem>
			</GridContainer>
		</S.Container>
	)
}
