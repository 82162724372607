import React from 'react'
import {
    AlertModalIcon,
    GridItem,
    MuiBox,
    MuiStack,
    MuiTypography,
} from '../../../../components/storybook'

export function WithdrawalFeeWarning({ currentTab, generalRules }) {
    return (
        <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            mt={3}
            sx={{
                paddingLeft: '0px !important',
            }}
        >
            <MuiBox
                sx={{
                    padding: '15px',
                    backgroundColor: '#F0F0F0',
                    borderRadius: '5px',
                }}
            >
                <MuiStack direction="row" alignItems="center" spacing={2}>
                    <MuiBox>
                        <AlertModalIcon size={35} color="#F8BB86" />
                    </MuiBox>
                    <MuiBox>
                        {currentTab !== 'points' && (
                            <MuiTypography fontSize="14px" color="#333333">
                                Para essa transação será cobrada uma taxa de
                                transferência e outra por *antecipação.
                            </MuiTypography>
                        )}
                        {currentTab === 'points' &&
                            generalRules?.allowPointsWithdrawFee && (
                                <MuiTypography fontSize="14px" color="#333333">
                                    Para essa transação será cobrada uma taxa de
                                    transferência.
                                </MuiTypography>
                            )}
                        {currentTab !== 'points' && (
                            <MuiTypography fontSize="14px" color="#333333">
                                (*essa taxa será apresentada apenas quando o
                                valor transferido foi recebido de uma premiação
                                por antecipação).
                            </MuiTypography>
                        )}
                    </MuiBox>
                </MuiStack>
            </MuiBox>
        </GridItem>
    )
}
