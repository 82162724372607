import React from 'react'
import * as _ from 'lodash'
import Axios from 'axios'

// @material-ui
import withStyles from '@material-ui/core/styles/withStyles'

// @Components
import Loader from 'components/Loader/Loader.jsx'
import Form from './Components/Confirm'

// @Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// @Assets
import registerStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

// @actions
import {
	ValidateEmailToken,
	SendSmsCode,
	ValidateSmsCode,
} from '../../redux/actions'

// @utils
import compose from 'utils/compose'
import { connectRouterState } from 'utils/connectRouterState'

// @styles
import styles from './confirm.module.scss'
import Alert from 'react-bootstrap-sweetalert'

// MOMENT JS
import * as moment from 'moment'
import { withTranslation } from 'react-i18next'
import { envs } from '../../redux/api/windowServerData'

require('moment/locale/pt-br')

class ConfirmRegistration extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			resetForm: false,
			alert: null,
			isSent: false,
			token: null,
			isLoading: false,
		}
		moment.locale('pt-br')
	}

	componentDidMount() {
		this.setState({ ...this.props.urlState }, () => {
			if (this.state.token) {
				this.showLoader()
				this.props.ValidateEmailToken(this.state.token).then((res) => {
					try {
						if (res?.errors?.length > 0) {
							this.hideLoader()
							this.props.SendSmsCode(res?.data?.cpf)
						}
						if (res?.data) {
							try {
								if (!localStorage.getItem('consumerSMS')) {
									localStorage.setItem(
										'consumerSMS',
										JSON.stringify({
											consumer: res.data,
											expireDate:
												new Date().getTime() + 900000,
										}),
									)
								}
							} catch (error) {
								console.log(error)
							}

							this.hideLoader()
						}
					} catch (error) {
						console.log(error)
					}

				})
			} else {
				let data = this.getWithExpiry('consumerSMS')
				if (!data) {
					this.props.history.push('/')
				}
			}
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.emailToken !== this.props.emailToken) {
			this.setState({ isLoading: true })
			if (!this.props.emailToken.success) {
				if (_.isArray(this.props.emailToken.errors)) {
					console.log("emailToken", this.props.emailToken)
					this.props.emailToken.errors.map((error) => {
						switch (error.message) {
							case 'token - INVALID_VALUE':
								this.setState({
									isLoading: false,
									alert: (
										<Alert
											warning
											style={{
												display: 'block',
												marginTop: '-100px',
											}}
											title={null}
											onConfirm={() => {
												this.props.history.push('/')
											}}
											confirmBtnCssClass={
												this.props.classes.button +
												' ' +
												this.props.classes
													.greenButtonColor
											}
											confirmBtnText={'OK'}
										>
											O Token de validação de e-mail está
											inválido ou expirado.
										</Alert>
									),
								})
								break
							case 'consumer - EMAIL_ALREADY_VALIDATED':
								this.setState({
									isLoading: false,
									alert: null,
								})
								break
							case 'consumer - PHONENUMBER_ALREADY_VALIDATED':
								this.setState({
									isLoading: false,
									alert: (
										<Alert
											warning
											style={{
												display: 'block',
												marginTop: '-100px',
											}}
											title={null}
											onConfirm={() => {
												this.handleClose()
											}}
											confirmBtnCssClass={
												this.props.classes.button +
												' ' +
												this.props.classes
													.greenButtonColor
											}
											confirmBtnText={'Realizar login'}
										>
											{this.props.t(error.message)}
										</Alert>
									),
								})
								break
							default:
								this.props.history.push(`/auth/login`, {
									error: error.message,
								})
								this.setState({
									isLoading: false,
									alert: (
										<Alert
											warning
											style={{
												display: 'block',
												marginTop: '-100px',
											}}
											title={null}
											onConfirm={() => {
												this.props.t(error.message)
													.length >= 11
													? this.handleSendEmail(
														this.props.t(
															error.message,
														),
													)
													: this.hideLoader()
											}}
											confirmBtnCssClass={
												this.props.classes.button +
												' ' +
												this.props.classes
													.greenButtonColor
											}
											confirmBtnText={
												this.props.t(error.message)
													.length >= 11
													? 'Reenviar token'
													: 'OK'
											}
										>
											{this.props.t(error.message)
												.length >= 11
												? 'O Token de validação de e-mail está expirado.'
												: this.props.t(error.message)}
										</Alert>
									),
								})
								break
						}
					})
				} else {
					this.setState({
						isLoading: false,
						alert: (
							<Alert
								warning
								style={{
									display: 'block',
									marginTop: '-100px',
								}}
								title={null}
								onConfirm={() => {
									this.hideLoader()
								}}
								confirmBtnCssClass={
									this.props.classes.button +
									' ' +
									this.props.classes.greenButtonColor
								}
								confirmBtnText={'OK'}
							>
								{'Ocorreu um erro ao realizar operação!'}
							</Alert>
						),
					})
				}
			} else {
				localStorage.setItem(
					'consumerSMS',
					JSON.stringify({
						consumer: this.props.emailToken.data,
						expireDate: new Date().getTime() + 900000,
					}),
				)
				this.hideLoader()
			}
		}
		if (prevProps.smsRequest !== this.props.smsRequest) {
			if (!this.props?.smsRequest?.success)
				this.setState({
					isLoading: false,
					alert: (
						<Alert
							warning
							style={{ display: 'block', marginTop: '-100px' }}
							title={null}
							onConfirm={() => {
								if (!_.isArray(this.props.smsRequest?.errors)) {
									window.location.reload()
									return
								}
								this.hideLoader()
								this.props.history.push(`/auth/login`)
							}}
							confirmBtnCssClass={
								this.props.classes.button +
								' ' +
								this.props.classes.greenButtonColor
							}
							confirmBtnText={_.isArray(this.props.smsRequest?.errors) ? 'OK' : 'Reenviar código'}
						>
							{_.isArray(this.props?.smsRequest?.errors)
								? this.props.smsRequest?.errors?.map((error) => [
									<div>{this.props.t(error.message)}</div>,
								])
								: 'Ocorreu um erro enviar o código de autenticação!'}
						</Alert>
					),
				})
			else {
				const consumer = this.getWithExpiry('consumerSMS')
				if (consumer) {
					this.setState({
						isLoading: false,
						alert: (
							<Alert
								success
								style={{
									display: 'block',
									marginTop: '-100px',
								}}
								title={null}
								onConfirm={() => {
									this.hideLoader()
								}}
								confirmBtnCssClass={
									this.props.classes.button +
									' ' +
									this.props.classes.greenButtonColor
								}
								confirmBtnText={'OK'}
							>
								<div>
									{this.state.isSent
										? 'Um novo código foi enviado para o celular cadastrado:'
										: 'O código de autenticação foi enviado para o celular cadastrado:'}
								</div>
								<div className={styles.phoneNumberAlert}>
									{consumer?.phoneNumber2 ?? ''}
								</div>
							</Alert>
						),
						resetForm: !this.state.resetForm,
					})
				}
			}
		}
		if (prevProps.putSms !== this.props.putSms) {
			if (!this.props.putSms.success)
				this.setState({
					isLoading: false,
					alert: (
						<Alert
							warning
							style={{ display: 'block', marginTop: '-100px' }}
							title={null}
							onConfirm={() => {
								this.hideLoader()
							}}
							confirmBtnCssClass={
								this.props.classes.button +
								' ' +
								this.props.classes.greenButtonColor
							}
							confirmBtnText={'OK'}
						>
							{_.isArray(this.props.putSms.errors)
								? this.props.putSms.errors.map((error) => [
									<div>{this.props.t(error.message)}</div>,
								])
								: 'Ocorreu um erro ao realizar operação!'}
						</Alert>
					),
				})
			else {
				this.setState({ alert: null, isLoading: false })
				this.props.history.push(`/success`)
				// localStorage.removeItem('consumerSMS')
			}
		}
		if (
			this.props.emailTokenFailed ||
			this.props.smsRequestFailed ||
			this.props.putSmsFailed
		) {
			this.setState({
				isLoading: false,
				alert: (
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={null}
						onConfirm={() => {
							this.hideLoader()
						}}
						confirmBtnCssClass={
							this.props.classes.button +
							' ' +
							this.props.classes.greenButtonColor
						}
						confirmBtnText={'OK'}
					>
						Ocorreu um erro ao realizar operação! Contate o suporte
						técnico
					</Alert>
				),
			})
		}
	}
	getWithExpiry(key) {
		const itemStr = localStorage.getItem(key)
		// if the item doesn't exist, return null
		if (!itemStr) {
			return null
		}
		const item = JSON.parse(itemStr)
		const now = new Date()
		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expireDate) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem(key)
			this.setState({
				isLoading: false,
				alert: (
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={null}
						onConfirm={() => {
							this.handleClose()
						}}
						confirmBtnCssClass={
							this.props.classes.button +
							' ' +
							this.props.classes.greenButtonColor
						}
						confirmBtnText={'OK'}
					>
						{_.isArray(this.props.smsRequest?.errors)
							? this.props.smsRequest?.errors?.map((error) => [
								<div>{this.props.t(error.message)}</div>,
							])
							: 'Sua Sessão expirou!'}
					</Alert>
				),
			})
			return null
		}
		return item.consumer
	}

	handleSetState = (key, value) => {
		this.setState({ [key]: value })
	}

	showLoader = () => {
		this.setState({ alert: <Loader />, isLoading: true })
	}

	async handleSave(values) {
		await new Promise((resolve) => setTimeout(resolve, 500))
		let consumer = this.getWithExpiry('consumerSMS')
		// if (consumer) {
		this.showLoader()
		this.props.ValidateSmsCode(
			consumer.cpf,
			values.numberToken,
			this.handleSetState,
		)
		// }
	}

	requestSmsCode() {
		this.showLoader()
		try {
			let consumer = this.getWithExpiry('consumerSMS')
			if (consumer) {


				if (consumer?.cpf) {
					this.props.SendSmsCode(consumer?.cpf)
				}
			}
		} catch (error) {
			console.log(error)
		} finally {
			this.hideLoader()
		}


	}

	hideLoader = () => {
		this.setState({ alert: null, isLoading: false })
	}

	handlSent = () => {
		this.setState({ isSent: true })
	}

	handleClose() {
		this.setState({ alert: null })
		this.props.history.push(`/auth/login`)
	}

	handleSendEmail(cpf) {
		Axios.put(`/Consumer/SendEmailConfirmationToken?cpfCnpj=${cpf}`, {
			// baseURL: envs.REACT_APP_GERMINI_API_URL,
			baseURL: envs.REACT_APP_GERMINI_API_URL,
		})
		this.setState({ alert: null })
		localStorage.setItem('cpfUser', cpf)
		this.props.history.push({
			pathname: `/authenticateAccess`,
		})
	}

	GetConsumerSMS = () => {
		try {
			const obj = JSON.parse(localStorage.getItem('consumerSMS'))

			if (obj?.consumer) return obj?.consumer
			else return {}
		} catch (error) {
			return {}
		}
	}

	render() {
		const { alert, isLoading } = this.state
		return (
			<>
				{alert}
				<Form
					key={this.state.resetForm}
					handleClose={this.handleClose.bind(this)}
					requestSmsCode={this.requestSmsCode.bind(this)}
					history={this.props.history}
					onSubmit={this.handleSave.bind(this)}
					props={this.props}
					handlSent={this.handlSent}
					isLoading={isLoading}
					consumer={this.GetConsumerSMS()}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		emailToken: state.users.emailToken,
		emailTokenFailed: state.users.emailTokenFailed,
		smsRequest: state.users.smsRequest,
		smsRequestFailed: state.users.smsRequestFailed,
		putSms: state.users.putSms,
		putSmsFailed: state.users.putSmsFailed,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ ValidateEmailToken, SendSmsCode, ValidateSmsCode },
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(registerStyle),
	withTranslation(),
	connectRouterState({ token: '' }),
)(ConfirmRegistration)
