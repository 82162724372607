import styled from 'styled-components'

import { Box, Typography } from '@mui/material'

export const Container = styled(Box)`
    height: 290px;
    .slick-slide {
        display: flex;
        justify-content: center;
        padding: 20px;
    }
`

export const Title = styled.div`
    text-align: center;
    font: normal normal 300 26px Roboto;
    letter-spacing: 0px;
    color: #ffffff;

    @media (max-width: 400px) {
        padding: 10px;
        text-align: center;
        font-size: 20px;
    }
`

export const TextBold = styled.strong`
    font: normal normal bold 26px/39px Roboto;
    letter-spacing: 0px;
    color: #ffffff;

    @media (max-width: 400px) {
        padding: 10px;
        text-align: center;
        font-size: 20px;
    }
`
