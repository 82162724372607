import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Fade } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button.jsx'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import {
    ContainerTXT,
    EmailAnsCell,
    Line,
    ModalContainer,
    PaperContainer,
    SectionText,
    Support,
    Title,
    TitleTxt,
} from './styles'
import { GetContact } from '../../../../redux/api/footer'

function ModalDateOfBirthError({
    className,
    closeByClick,
    handleClose,
    onConfirm,
    maxWidth,
    minWidth,
    open,
    padding,
    shadow,
    title,
    text,
    type,
    preDisabled,
    t,
    handleResetDate,
}) {
    const [contact, setContat] = useState()

    const GetContactInfo = async () => {
        const res = await GetContact()
        res && setContat(res)
    }

    useEffect(() => {
        GetContactInfo()
    }, [])

    return (
        <ModalContainer
            open={open}
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            maxwidth={maxWidth}
            minwidth={minWidth}
            disableEnforceFocus
        >
            <Fade in={open} unmountOnExit>
                <PaperContainer
                    padding={padding}
                    maxwidth={maxWidth}
                    preDisabled={preDisabled}
                >
                    <GridContainer xs={12} sm={12} style={{ padding: '25px' }}>
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '20px',
                            }}
                        >
                            <i
                                className="icon-alerta_modal"
                                style={{
                                    color: germiniDefaultColors[11],
                                    fontSize: '60px',
                                }}
                            />
                            <Title>{t('Atenção!')}</Title>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                textAlign: 'center',
                                marginTop: '17px',
                            }}
                        >
                            <SectionText>{t(text)}</SectionText>
                        </GridItem>{' '}
                        {!preDisabled && (
                            <GridItem
                                xs={12}
                                sm={12}
                                style={{ textAlign: 'center' }}
                            >
                                <TitleTxt>{t(title)}</TitleTxt>
                                <ContainerTXT>
                                    <Line />
                                    <Support>
                                        {' '}
                                        Caso de dúvidas entre em contato com o
                                        suporte:
                                    </Support>
                                    <EmailAnsCell
                                        onClick={() =>
                                            window.open(
                                                `mailto:${contact?.contactEmail}`,
                                            )
                                        }
                                        className="email-ans-cell"
                                    >
                                        E-mail:
                                        {contact?.contactEmail || ''}
                                    </EmailAnsCell>
                                    <EmailAnsCell className="email-ans-cell">
                                        Telefones:{' '}
                                        {contact?.contactPhoneNumber || ''}{' '}
                                        {contact?.contactPhoneNumber2 || ''}
                                        3689-7000"
                                    </EmailAnsCell>
                                </ContainerTXT>
                            </GridItem>
                        )}
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{ textAlign: 'center', marginTop: '5px' }}
                        >
                            <Button
                                size="sm"
                                variant="contained"
                                color={'greenButtonColor'}
                                onClick={handleResetDate}
                                style={{
                                    width: '100px',
                                }}
                            >
                                {t('Ok')}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </PaperContainer>
            </Fade>
        </ModalContainer>
    )
}

ModalDateOfBirthError.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    closeByClick: PropTypes.func,
    showButtons: PropTypes.bool,
    handleClose: PropTypes.func,
    handleForgetPWD: PropTypes.func,
    handleLogin: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
    text: PropTypes.string,
    preDisabled: PropTypes.bool,
    handleResetDate: PropTypes.func,
}

ModalDateOfBirthError.defaultProps = {
    children: null,
    className: '',
    closeByClick: null,
    handleClose: null,
    handleForgetPWD: null,
    handleLogin: null,
    maxWidth: '',
    minWidth: '',
    open: false,
    showButtons: true,
    padding: '',
    shadow: '',
    text: '',
    preDisabled: false,
}

export default withTranslation()(ModalDateOfBirthError)
