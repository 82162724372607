/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from "@mui/material";
import { MuiTypography } from "../Typography";
export function MuiLegend(_a) {
    var color = _a.color, label = _a.label, size = _a.size, borderRadius = _a.borderRadius, marginRight = _a.marginRight, props = __rest(_a, ["color", "label", "size", "borderRadius", "marginRight"]);
    return (_jsxs(Stack, __assign({ direction: "row", alignItems: "right" }, { children: [_jsx(Box, { "data-testid": "legend-color", style: {
                    width: size,
                    height: size,
                    backgroundColor: color !== null && color !== void 0 ? color : "#3EC450",
                    marginRight: marginRight,
                    borderRadius: borderRadius,
                } }), label && (_jsx(MuiTypography, __assign({ "data-testid": "legend-label", variant: "caption", mr: 2 }, { children: label })))] })));
}
