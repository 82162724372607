import { Box, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import _ from 'lodash'

import { TextBold, Title } from './styles'

import storeImage from 'assets/img/virtualstore/bg_textura_loja.png'
import { getAllCategories } from '../../../../redux/api/store'
import SliderComponent from './ResponsiveSlider'
import * as S from './styles'
import Card from '../Card'
import GridItem from 'components/Grid/GridItem.jsx'
import SectionLine from '../SectionLine'
import { useTranslation } from 'react-i18next'
import Loader from 'components/Loader/Loader.jsx'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

function ProductsCategory() {
    const { t } = useTranslation()
    const matches = useMediaQuery('(max-width:600px)')
    const [categories, setCategories] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const getCategories = async () => {
        try {
            const response = await getAllCategories()
            if (response.status === 200) {
                const { data } = response
                data?.length > 0 && setCategories(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCategories()
    }, [])

    return (
        !_.isEmpty(categories) && (
            <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{ marginTop: '30px', marginBottom: '30px' }}
            >
                {loading && <Loader />}
                <SectionLine title={t('PRODUTOS POR CATEGORIA')} />
                <S.Container component="section" mt={2}>
                    <Box
                        width="100%"
                        minHeight="310px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        gap="20px"
                        component="div"
                        sx={{
                            zIndex: -2,
                            backgroundImage: `url(${storeImage})`,
                            backgroundSize: {
                                xs: '100% 145px',
                                sm: 'contain',
                                md: 'inherit',
                                lg: '100% 100%',
                            },
                            borderRadius: '70px 0 70px 0',
                            backgroundColor:
                                GetThemeColorFromStorage()?.visual?.icons
                                    ?.color,
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                    <Box
                        position="relative"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        top="-247px"
                    >
                        <Title>
                            As <TextBold>MELHORES</TextBold> ofertas estão na
                            nossa <TextBold>LOJA VIRTUAL</TextBold>
                        </Title>
                        <Box display="block" width="100%">
                            {matches ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '0px 20px',
                                        overflowX: 'auto',
                                        scrollbarWidth: 'none',
                                        '-ms-overflow-style': 'none',
                                        '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'nowrap',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '25px',
                                            position: 'relative',
                                        }}
                                    >
                                        {categories.map(
                                            (product, index) =>
                                                product.enable === true && (
                                                    <Card
                                                        key={index}
                                                        name={product.name}
                                                        imageUrl={
                                                            product.imageUrl
                                                        }
                                                        enable={product.enable}
                                                        id={product.id}
                                                        storeId={
                                                            product.storeId
                                                        }
                                                        setLoading={setLoading}
                                                        loading={loading}
                                                    />
                                                ),
                                        )}
                                    </Box>
                                </Box>
                            ) : (
                                <SliderComponent
                                    categories={categories}
                                    setLoading={setLoading}
                                    loading={loading}
                                />
                            )}
                        </Box>
                    </Box>
                </S.Container>
            </GridItem>
        )
    )
}

export default ProductsCategory
