import Axios from 'axios'

export default class NegotiationService {
    async getNegotiations(type = 'points') {
        let typeNegotiaitons = null
        if (type === 'points') {
            typeNegotiaitons = `/Negotiation/GetExtractList?TypeOfBenefit=Points&pageSize=10&page=1`
        } else {
            typeNegotiaitons = `/Negotiation/GetExtractList?TypeOfBenefit=Cashback&pageSize=10&page=1`
        }
        const response = await Axios.get(typeNegotiaitons)
        return response?.data
    }
    async getPaginationNegotiations({ pageSize, page, type = 'points' }) {
        let typeNegotiaitons = null

        if (type === 'points') {
            typeNegotiaitons = `/Negotiation/GetExtractList?TypeOfBenefit=Points&pageSize=${pageSize}&page=${page}`
        } else {
            typeNegotiaitons = `/Negotiation/GetExtractList?TypeOfBenefit=Cashback&pageSize=${pageSize}&page=${page}`
        }
        const response = await Axios.get(typeNegotiaitons)
        return response?.data
    }
    async getFilteredNegotiations({ pageSize, page, ...filter }) {
        const queryParams = {
            page,
            pageSize,
            ...filter,
        }

        const filteredParams = Object.fromEntries(
            Object.entries(queryParams).filter(
                ([_, value]) =>
                    value !== undefined && value !== null && value !== '',
            ),
        )

        const query = Object.entries(filteredParams)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&')

        const response = await Axios.get(`/Negotiation/GetExtractList?${query}`)
        return response?.data
    }
    async getNegotiationNotification(id) {
        const response = await Axios.get(
            `/Negotiation/SendNegotiationNotification/${id}`,
        )
        return response?.data
    }
    async CancelNegotiation(id) {
        const response = await Axios.post(`/Negotiation/Cancel/${id}`)
        return response?.data
    }
}
