import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTheme, useMediaQuery } from '@mui/material'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'
import {
    GetConsumerTransferAccountInfo,
    GetCurrentConsumer,
    GetTransferTax,
    RequestConsumerTransfer,
    ValidateConsumer,
} from '../../../redux/api/user'
import { formatValue, formatValuePoint, maskedPixValue } from '../functions'
import { useTranslation } from 'react-i18next'
import { GetGeneralRules } from '../../../redux/api/generalRules'
import TransferService from '../Services/transferService'

export default function useTransferValue() {
    const service = new TransferService()
    const { t } = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [currentTab, setCurrentTab] = useState('digitalWallet')
    const [transferInfo, setTransferInfo] = useState([])
    const [transferTax, setTransferTax] = useState([])
    const [currentConsumer, setCurrentConsumer] = useState([])
    const [generalRules, setGeneralRules] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
    const [isModalTransferOpen, setIsModalTransferOpen] = useState(false)
    const [isModalInsufficientAccountOpen, setIsModalInsufficientAccountOpen] =
        useState(false)
    const [
        isModalExceededTransferLimitOpen,
        setIsModalExceededTransferLimitOpen,
    ] = useState(false)
    const [isModalConfirmPasswordOpen, setIsModalConfirmPasswordOpen] =
        useState(false)
    const [isModalCodeOpen, setIsModalCodeOpen] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessageValue, setErrorMessageValue] = useState('')
    const [errorMessageNotFound, setErrorMessageNotFound] = useState(false)
    const [validatedPassword, setValidatedPassword] = useState('')
    const [userPix, setUserPix] = useState({})
    const [isDisabledNumberToken, setIsDisabledNumberToken] = useState(true)
    const [errors, setErrors] = useState(true)
    const initialSelectedAccount =
        transferInfo?.accounts?.length > 0 &&
        transferInfo?.accounts[0]?.transferType?.key === '1'
            ? 1
            : 2
    const [selectedAccount, setSelectedAccount] = useState(
        initialSelectedAccount,
    )
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingValue, setIsLoadingValue] = useState(false)
    const [selectedOption, setSelectedOption] = useState('Email')

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const { handleSubmit, watch, setValue, control, reset } = useForm({
        defaultValues: {
            value: '',
            valuePoint: '',
            password: '',
            acceptTerms: false,
        },
    })
    const watchFields = watch()
    const watchValue = watchFields?.value || watchFields?.valuePoint
    const [debouncedValue, setDebouncedValue] = useState(watchValue)

    const handleTabChange = (index) => {
        setCurrentTab(index)
    }

    const handleHomeClick = () => {
        history.push('/home')
    }
    const handleProfileClick = () => {
        history.push('/profile')
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    async function getConsumerTransferAccountInfo() {
        setIsLoading(true)
        const response = await GetConsumerTransferAccountInfo()
        setTransferInfo(response?.data)
        if (response?.errors[0]?.message === 'account - NOT_FOUND') {
            setErrorMessageNotFound(true)
        }

        const updatedSelectedAccount =
            response?.data?.accounts?.length > 0 &&
            response?.data?.accounts[0]?.transferType?.key === '1'
                ? 1
                : 2

        setSelectedAccount(updatedSelectedAccount)
        setValue('accountBank', updatedSelectedAccount)

        setIsLoading(false)
    }

    async function getTransferTax(value, transferOrigin) {
        setIsLoadingValue(true)
        const transferOriginMap = {
            digitalWallet: 2,
            points: 1,
        }

        const transferOriginAPI = transferOriginMap.hasOwnProperty(
            transferOrigin,
        )
            ? transferOriginMap[transferOrigin]
            : 1

        const response = await GetTransferTax(value, transferOriginAPI)
        setTransferTax(response?.data)
        validateValue(response?.errors[0])
        setErrors(response?.errors[0])

        setIsLoadingValue(false)
    }
    const handleTransferValueSubmit = async (data) => {
        setIsLoading(true)

        const transferOriginMap = {
            digitalWallet: 2,
            points: 1,
        }

        const transferOriginAPI = transferOriginMap.hasOwnProperty(currentTab)
            ? transferOriginMap[currentTab]
            : 1
        const formData = {
            password: validatedPassword,
            transferType: data?.accountBank,
            transferOrigin: transferOriginAPI,
            value: data?.value ? formatValue(data?.value) : 0,
            pointsValue: data?.valuePoint
                ? formatValuePoint(data?.valuePoint)
                : 0,
            anticipationFee: transferTax?.anticipationFee,
            transferFee: transferTax?.transferFee,
            liquidValue: transferTax?.liquidValue,
            isAcceptTermsAndConditions: data?.acceptTerms,
            confirmationCode: data?.numberToken,
        }
        const response = await RequestConsumerTransfer(formData)

        if (response?.success) {
            setIsModalTransferOpen(false)
            setMessageSuccess(true)
        } else {
            setErrorMessage('Ocorreu um erro ao realizar a transferência.')
            if (
                response?.errors[0]?.message ===
                'Value - PERIOD_AVAILABLE_FOR_TRANSFERS_EXCEEDED'
            ) {
                setIsModalInsufficientAccountOpen(true)
            }
            if (
                response?.errors[0]?.message ===
                'Value - EXCEEDED_TRANSFER_LIMIT'
            ) {
                setIsModalExceededTransferLimitOpen(true)
            }

            setIsModalConfirmOpen(false)
        }
        setIsLoading(false)
    }

    const handlePasswordSubmit = async (data) => {
        const formData = data?.password

        const response = await ValidateConsumer(formData)
        if (response === true) {
            setValidatedPassword(formData)
            setIsModalConfirmPasswordOpen(true)
            setIsModalConfirmOpen(false)
            return response
        } else {
            setErrorMessage(
                'A senha está incorreta. Por favor, tente novamente.',
            )
            setIsModalConfirmOpen(false)
        }
    }

    const getSendTransferRequest = async () => {
        const response = await service.getSendTransferRequest({
            type: selectedOption,
            password: validatedPassword,
        })

        if (response.success) {
            setIsModalCodeOpen(true)
            return response
        } else if (response.errors[0].field === 'Password') {
            setErrorMessage('Senha incorreta')
        }
    }

    const handleCodeSubmit = async () => {
        setIsModalConfirmPasswordOpen(false)
        await getSendTransferRequest()
    }

    const handleFinalSubmit = async () => {
        setIsModalCodeOpen(false)
        await handleTransferValueSubmit(watchFields)
    }

    const getCurrentConsumer = async () => {
        const response = await GetCurrentConsumer()
        setCurrentConsumer(response)
        setUserPix(
            maskedPixValue(response?.consumerAccount?.keyType?.value),
            response?.consumerAccount?.key,
        )
    }
    const getGeneralRules = async () => {
        const response = await GetGeneralRules()
        setGeneralRules(response)
    }

    function validateValue(errors) {
        const minValue =
            errors?.message === 'value - MINIMUM_VALUE_NOT_ALLOWED' ||
            formatValue(watchFields?.value) < generalRules?.dailyTransferLimit
        const maxValue = errors?.message === 'value - MAXIMUM_VALUE_NOT_ALLOWED'
        const invalidValue = errors?.message === 'value - INVALID_VALUE'
        const availableBalance =
            formatValue(watchFields?.value) > transferInfo?.balanceAvailable
        const notAllowed = errors?.message === 'transfer - NOT_ALLOWED'
        const insufficientBalance =
            errors?.message === 'balance - INSUFFICIENT_ACCOUNT_BALANCE'
        const liquidValue = errors?.message === 'LiquidValue - INVALID_VALUE'

        if (minValue) {
            setErrorMessageValue(
                'Sua solicitação está abaixo do valor permitido',
            )
            return false
        }

        if (availableBalance) {
            setErrorMessageValue(
                'O valor está acima do saldo disponível para saque',
            )
            return false
        }

        if (!availableBalance && maxValue) {
            setErrorMessageValue(
                'Sua solicitação está acima do valor permitido',
            )
            return false
        }
        if (notAllowed) {
            setErrorMessageValue('Transferência não permitida')
            return false
        }
        if (invalidValue) {
            setErrorMessageValue('Valor inválido')
            return false
        }

        if (insufficientBalance) {
            setErrorMessageValue('Saldo disponível para saque insuficiente')
            return false
        }
        if (liquidValue) {
            setErrorMessageValue('Valor líquido da conversão menor que 0')
            return false
        }

        setErrorMessageValue('')
        return true
    }

    useEffect(() => {
        Promise.all([
            getCurrentConsumer(),
            getGeneralRules(),
            getConsumerTransferAccountInfo(),
        ])
    }, [])

    useEffect(() => {
        const { value, acceptTerms, accountBank, valuePoint } = watchFields
        if (currentTab === 'points') {
            setIsButtonDisabled(!(valuePoint && acceptTerms && accountBank))
        } else {
            setIsButtonDisabled(!(value && acceptTerms && accountBank))
        }
    }, [watchFields])

    const handleRadioChange = (event) => {
        setSelectedAccount(Number(event.target.value))
        setValue('accountBank', Number(event.target.value))
    }

    const handleRadioChangeOrigin = (event) => {
        setCurrentTab(event.target.value)
    }

    const onSubmit = () => {
        handleCodeSubmit()
    }

    useEffect(() => {
        const { numberToken } = watchFields
        setIsDisabledNumberToken(numberToken?.length !== 4)
    }, [watchFields])

    return {
        t,
        watchFields,
        setValue,
        handleSubmit,
        control,
        isModalOpen,
        handleCloseModal,
        handleOpenModal,
        reset,
        isModalTransferOpen,
        setIsModalTransferOpen,
        handleHomeClick,
        isSmallScreen,
        currentTab,
        setCurrentTab,
        handleTabChange,
        GetThemeColorFromStorage,
        transferInfo,
        getConsumerTransferAccountInfo,
        transferTax,
        getTransferTax,
        isModalConfirmOpen,
        setIsModalConfirmOpen,
        isModalConfirmPasswordOpen,
        setIsModalConfirmPasswordOpen,
        isModalCodeOpen,
        setIsModalCodeOpen,
        messageSuccess,
        setMessageSuccess,
        handlePasswordSubmit,
        handleCodeSubmit,
        handleFinalSubmit,
        errorMessage,
        setErrorMessage,
        userPix,
        currentConsumer,
        errorMessageNotFound,
        handleProfileClick,
        validateValue,
        errorMessageValue,
        isModalInsufficientAccountOpen,
        setIsModalInsufficientAccountOpen,
        isModalExceededTransferLimitOpen,
        setIsModalExceededTransferLimitOpen,
        generalRules,
        isButtonDisabled,
        errors,
        debouncedValue,
        setDebouncedValue,
        watchValue,
        selectedAccount,
        handleRadioChange,
        handleRadioChangeOrigin,
        isLoading,
        onSubmit,
        selectedOption,
        setSelectedOption,
        isDisabledNumberToken,
        isLoadingValue,
    }
}
