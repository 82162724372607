import { Modal, Paper } from '@material-ui/core'
import styled from 'styled-components'

export const ModalContainer = styled(Modal)`
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .section_title_class {
        padding: 20px 20px 0 20px;
    }

    header {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 10px 15px 10px 20px;
        .line {
            width: 55%;
            margin-left: 10px;
            hr {
                border: 2px solid #21409a;
                margin: 0;
            }
        }
        .section-part {
            .section-header {
                padding: 0;
                font-size: 15px;
                display: flex;
                white-space: nowrap;
                font-weight: 600;
                align-items: center;
                width: 100%;
                &::after {
                    border-top: 3px solid #21409a;
                    content: '';
                    display: none;
                    position: static;
                    width: 100%;
                    left: 0;
                    margin-left: 10px;
                }
            }
        }
    }
`

export const SubHeader = styled.div`
    padding: 15px 15px 10px 20px;

    div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px;
        border-radius: 6px;
        background-color: ${({ warning }) => (warning ? '#ff9800' : '#de3b2e')};
        color: #fff;
        font-weight: 600;
    }
`

export const BodyRender = styled.div`
    padding-left: 20px;
    margin: 15px 0;
    max-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
`

export const PaperContainer = styled(Paper)`
    /* margin-bottom: 40px; */
    /* margin-top: 80px;
  margin-bottom: 80px; */
    position: relative;
    max-width: ${({ maxwidth }) => maxwidth};
    min-width: 60vw;
    padding: ${({ padding }) => padding};
    &.MuiPaper-elevation1 {
        box-shadow: ${({ shadow }) => shadow};
    }
    .sub-info {
        color: #48484c;
        font-size: 0.9rem;
        font-weight: bold;
    }

    .info-geral {
        color: #31394d;
        font-weight: bold;
        font-size: 1.3rem;
        > div {
            margin-right: 5px;
        }
    }

    .blue {
        color: #3f51b5;
    }

    .close-button {
        align-items: center;
        background: transparent;
		white-space: nowrap;
        border: none;
        color: #f44336;
        cursor: pointer;
        display: flex;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 15px;
        justify-content: flex-end;
        padding: 0;

        &:hover {
            opacity: 0.7;
        }
        svg {
            margin-right: 3px;
        }
    }

    .sub-total {
        color: #31394d;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
    }

    .sub-price {
        color: #49d489;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
    .button-box {
        position: absolute;
        margin-top: -28px;
        top: 0;
        margin-right: -28px;
        right: 0;
        .MuiIconButton-label {
            background-color: #3f51b5;
            border-radius: 50%;
            .MuiIcon-colorPrimary {
                color: #fff;
                font-size: 2rem;
            }
        }
    }
`

export const SectionBox = styled.div`
    box-sizing: border-box;
    padding-top: 10px;
    width: 100%;
`
