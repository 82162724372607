/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MuiBox } from "../Box";
import { TabButtons } from "./Components/TabButtons";
export var Tabs = function (_a) {
    var children = _a.children, isEditData = _a.isEditData, selectedTab = _a.selectedTab, setSelectedTab = _a.setSelectedTab, _b = _a.defaultColor, defaultColor = _b === void 0 ? "#F58B3C" : _b, tabLabels = _a.tabLabels, hasBorder = _a.hasBorder, onOpenModal = _a.onOpenModal, onclickEdit = _a.onclickEdit, buttonActions = _a.buttonActions, tabPadding = _a.tabPadding, isFixedTab = _a.isFixedTab;
    var sharedProps = {
        value: selectedTab,
        setSelectedTab: setSelectedTab,
        defaultColor: defaultColor,
        tabLabels: tabLabels !== null && tabLabels !== void 0 ? tabLabels : ["Dados da empresa", "Divisão de negócio", "Documentos"],
        selectedItem: selectedTab,
        selectedTab: selectedTab,
        isEditData: isEditData,
        onOpenModal: onOpenModal,
        onclickEdit: onclickEdit,
        buttonActions: buttonActions,
        tabPadding: tabPadding,
        isFixedTab: isFixedTab,
    };
    return (_jsxs(_Fragment, { children: [_jsx(TabButtons, __assign({}, sharedProps)), _jsx(MuiBox, __assign({ sx: {
                    border: hasBorder && "1px solid #e5e5e5",
                    padding: hasBorder && "15px",
                    borderRadius: hasBorder && "5px",
                    marginTop: hasBorder && "10px",
                } }, { children: children }))] }));
};
