import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Checkbox } from '@material-ui/core'
import { Fade } from '@material-ui/core'
import { ModalContainer, PaperContainer } from './styles'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import PageModal from './PageModal'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { userPreference } from 'redux/api/alerts/alerts'
import { CustomButtonsBackground } from '../CustomButtons'
import Section from 'components/Section/Section'
import { Stack } from '@mui/material'

function NotificationModal({
    buttonTitle,
    children,
    className,
    closeByClick,
    headerIcon = 'icon-ico_regra_plataforma',
    headerTitle = 'TITLE_REGULATION_REGULATION_MODEL',
    height,
    onClose,
    open,
    padding,
    presentPage = 0,
    sendPage,
    shadow,
    showCheckBox,
    t,
    totalPage = 0,
    width,
}) {
    const [showModal, setShowModal] = useState(false)

    function callUserPreference() {
        setShowModal(!showModal)
        userPreference(showModal)
    }

    useEffect(() => {}, [totalPage, presentPage])

    return (
        <>
            {showCheckBox ? (
                <>
                    <ModalContainer
                        aria-describedby="alert-dialog-slide-description"
                        aria-labelledby="alert-dialog-slide-title"
                        className={className}
                        disableEnforceFocus
                        height={height}
                        onClose={onClose}
                        open={open}
                        shadow={shadow}
                        width={width}
                    >
                        <Fade in={open} unmountOnExit>
                            <PaperContainer padding={padding}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ padding: '30px 25px' }}
                                >
                                    <div
                                        className="section-part"
                                        style={{
                                            width: '100%',
                                            transform: 'translateY(5px)',
                                        }}
                                    >
                                        {headerIcon && (
                                            <i
                                                className={headerIcon}
                                                style={{
                                                    color: GetThemeColorFromStorage()
                                                        ?.visual?.icons?.color,
                                                    fontSize: '15px',
                                                    //margin: '0 10px 0px 15px'
                                                }}
                                            />
                                        )}

                                        <Section title={t(headerTitle)} />
                                    </div>
                                    <div className="close-part">
                                        <button
                                            type="button"
                                            className="close-button"
                                            onClick={closeByClick}
                                        >
                                            <i
                                                className="icon-ico_cancelar"
                                                style={{
                                                    color: '#fff',
                                                    fontSize: '16px',
                                                    marginRight: '10px',
                                                }}
                                            />
                                            {t(buttonTitle)}
                                        </button>
                                    </div>

                                    <div className="line">
                                        <hr></hr>
                                    </div>
                                    {presentPage === totalPage && (
                                        <div
                                            className="close-part"
                                            style={{
                                                paddingLeft: '15px',
                                                marginTop: '-12px',
                                            }}
                                        >
                                            <CustomButtonsBackground
                                                type="button"
                                                onClick={closeByClick}
                                                style={{
                                                    backgroundColor: '#f44336',
                                                    borderColor: '#f44336',
                                                }}
                                            >
                                                <i className="icon-ico_cancelar" />
                                                {t(buttonTitle)}
                                            </CustomButtonsBackground>
                                        </div>
                                    )}
                                </Stack>
                                <main>{children}</main>
                                <GridContainer
                                    style={{
                                        width: '100%',
                                        margin: '0',
                                    }}
                                >
                                    <GridItem
                                        style={{
                                            marginLeft: '15px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                            marginBottom: '13px',
                                        }}
                                    >
                                        <Checkbox
                                            style={{ color: 'blue' }}
                                            onClick={() => callUserPreference()}
                                        />
                                        <label
                                            style={{
                                                color: 'black',
                                                fontSize: '12px',
                                            }}
                                        >
                                            Não mostrar mais estas notificações
                                        </label>
                                    </GridItem>
                                    <GridItem
                                        xs={5}
                                        style={{
                                            position: 'relative',
                                            top: '-10px',
                                            right: '-70px',
                                        }}
                                    >
                                        {totalPage > 1 && (
                                            <PageModal
                                                total={totalPage}
                                                sendPage={sendPage}
                                            ></PageModal>
                                        )}
                                    </GridItem>
                                </GridContainer>
                            </PaperContainer>
                        </Fade>
                    </ModalContainer>
                </>
            ) : (
                <>
                    <ModalContainer
                        aria-describedby="alert-dialog-slide-description"
                        aria-labelledby="alert-dialog-slide-title"
                        className={className}
                        disableEnforceFocus
                        height={height}
                        onClose={onClose}
                        open={open}
                        shadow={shadow}
                        width={width}
                    >
                        <Fade in={open} unmountOnExit>
                            <PaperContainer padding={padding}>
                                <header>
                                    <div
                                        className="section-part"
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        {headerIcon && (
                                            <i
                                                className={headerIcon}
                                                style={{
                                                    color: GetThemeColorFromStorage()
                                                        ?.visual?.icons?.color,
                                                    fontSize: '16px',
                                                    marginRight: '10px',
                                                }}
                                            />
                                        )}
                                        <div
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <Section title={t(headerTitle)} />
                                        </div>
                                    </div>
                                    {Number(totalPage, 10) ===
                                        Number(presentPage, 10) && (
                                        <div
                                            className="close-part"
                                            style={{
                                                paddingLeft: '15px',
                                                marginTop: '-12px',
                                            }}
                                        >
                                            <CustomButtonsBackground
                                                type="button"
                                                onClick={closeByClick}
                                                style={{
                                                    backgroundColor: '#f44336',
                                                    borderColor: '#f44336',
                                                }}
                                            >
                                                <i className="icon-ico_cancelar" />
                                                {t(buttonTitle)}
                                            </CustomButtonsBackground>
                                        </div>
                                    )}
                                </header>
                                <main>{children}</main>
                                <GridContainer>
                                    <GridItem
                                        xs={6}
                                        style={{
                                            alignItens: 'left',
                                            marginLeft: '20px',
                                        }}
                                    ></GridItem>
                                    <GridItem
                                        xs={5}
                                        style={{
                                            position: 'relative',
                                            top: '-20px',
                                            right: '-70px',
                                        }}
                                    >
                                        {totalPage > 1 && (
                                            <PageModal
                                                total={totalPage}
                                                sendPage={sendPage}
                                            ></PageModal>
                                        )}
                                    </GridItem>
                                </GridContainer>
                            </PaperContainer>
                        </Fade>
                    </ModalContainer>
                </>
            )}
        </>
    )
}
NotificationModal.propTypes = {
    children: PropTypes.element,
    open: PropTypes.bool,
    closeByClick: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
    headTitle: PropTypes.string,
    readAllImportantAlerts: PropTypes.func,
    showCheckBox: PropTypes.bool,
}

NotificationModal.defaultProps = {
    className: '',
    padding: '',
    shadow: '',
    headTitle: '',
    children: null,
    open: false,
    closeByClick: null,
    readAllImportantAlerts: null,
    showCheckBox: false,
}

export default withTranslation()(NotificationModal)
