/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

// CONTEXT
import { useStatement } from '../Providers/StatementContext'

// COMPONENTS
import {
    CustomButtonsBackground,
    CustomButtons,
} from '../../../components/CustomButtons'
import CustomDate from 'components/CustomDate'

// MATERIAL
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { FormControl } from '@material-ui/core'
import Grid from '@mui/material/Grid'

// REDUX
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { GetPartnersData, GetStatementData } from 'redux/actions'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

// Styles
import * as S from './styles'
import { periodOptions, typeOptions } from './utils'
import { GetPartnerByConsumer } from '../../../redux/api/partner'

const SearchForm = (props) => {
    const { setValueTypeFilter } = useStatement()
    const { t } = useTranslation()

    const { control, handleSubmit, watch, reset, setValue } = useForm({})

    const watchAllFields = watch()

    useEffect(() => {
        const calculateDateRange = (period) => {
            const today = moment()
            let startDate, endDate

            switch (period) {
                case 15:
                    startDate = today.clone().subtract(15, 'days')
                    endDate = today.clone()
                    break
                case 30:
                    startDate = today.clone().subtract(30, 'days')
                    endDate = today.clone()
                    break
                default:
                    startDate = null
                    endDate = null
            }

            return { startDate, endDate }
        }

        const { startDate, endDate } = calculateDateRange(watchAllFields.period)
        setValue(
            'dateFilterFrom',
            startDate ? startDate.format('DD/MM/YYYY') : '',
        )
        setValue('dateFilterTo', endDate ? endDate.format('DD/MM/YYYY') : '')
        setFromEnabled(!startDate)
        setToEnabled(!endDate)
    }, [watchAllFields.period])

    const [fromEnabled, setFromEnabled] = useState(false)
    const [toEnabled, setToEnabled] = useState(false)
    const [partnerOptions, setPartnerOptions] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState('')
    const [handleClearTrue, setHandleClearTrue] = useState(false)
    const [theme, setTheme] = useState(GetThemeColorFromStorage())

    const handleClearFilter = () => {
        setHandleClearTrue(true)
        setSelectedPeriod(null)

        setValueTypeFilter({
            period: null,
            dateFilterFrom: null,
            dateFilterTo: null,
            selectedPartner: null,
        })

        reset({
            period: null,
            type: null,
            dateFilterFrom: null,
            dateFilterTo: null,
            selectedPartner: null,
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleGetPartnerOptionsData = async () => {
        await props.GetPartnersData('', '', 1)
    }

    const handleSelectedPeriod = (value, event) => {
        event(value.value)
        setSelectedPeriod(value)
    }

    const handleSelectedPartner = (value, event) => {
        setHandleClearTrue(false)
        event(value)
    }

    const handleSelectedType = (value, event) => {
        setHandleClearTrue(false)
        event(value)
    }

    const getPartnerByConsumer = async () => {
        const res = await GetPartnerByConsumer()

        if (res?.status !== 200) return
        setPartnerOptions(res.data)
        console.log(res)
    }

    useEffect(() => {
        handleGetPartnerOptionsData()
    }, [])

    useEffect(() => {
        setTheme(GetThemeColorFromStorage())
    }, [])

    useEffect(() => {
        getPartnerByConsumer()
    }, [])

    const onSubmitFilter = async (data) => {
        setValueTypeFilter({
            dateFilterFrom:
                data.dateFilterFrom && data.dateFilterFrom
                    ? moment(data.dateFilterFrom, 'DD/MM/YYYY').format(
                          'YYYY-MM-DD',
                      )
                    : null,
            dateFilterTo:
                data.dateFilterTo && data.dateFilterTo
                    ? moment(data.dateFilterTo, 'DD/MM/YYYY').format(
                          'YYYY-MM-DD',
                      )
                    : null,
            selectedPartner: data.selectedPartner ? data.selectedPartner : null,
            type: data.type ? data.type : '',
        })
    }

    const afterValidation = (current) => {
        const today = moment()

        const beforeDate = moment(watchAllFields.dateFilterFrom).format(
            'DD/MM/YYYY',
        )

        return (
            current.isAfter(moment(beforeDate, 'DD/MM/YYYY')) &&
            current.isBefore(today)
        )
    }

    return (
        <S.Container
            onSubmit={handleSubmit(onSubmitFilter)}
            theme={theme}
            autoComplete="off"
        >
            <Grid container rowSpacing={3} columnSpacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth size="small" variant="outlined">
                        <Controller
                            onChange={([, data]) => data}
                            name="period"
                            defaultValue=""
                            control={control}
                            render={({ onChange }) => (
                                <Autocomplete
                                    styles={S.customSelectStyles}
                                    id="combo-box-demo"
                                    options={periodOptions}
                                    getOptionLabel={(option) =>
                                        option.label ? option.label : ''
                                    }
                                    disableClearable={true}
                                    value={selectedPeriod}
                                    disablePortal
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <TextField
                                                style={{
                                                    backgroundColor: '#ffffff',
                                                    width: '100%',
                                                }}
                                                {...params}
                                                noOptionsMessage={() =>
                                                    t('RESULTS_NOT_FOUND')
                                                }
                                                label={t('FIELD_PERIOD')}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>
                                    )}
                                    onChange={(e, data) =>
                                        handleSelectedPeriod(data, onChange)
                                    }
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Controller
                        as={
                            <CustomDate
                                disableClearable={true}
                                disablePortal
                                inputProps={{ readOnly: true }}
                                enableDates={fromEnabled}
                                fullWidth={true}
                                label={t('DATETIME_FROM')}
                                open={fromEnabled === true ? undefined : false}
                            />
                        }
                        name="dateFilterFrom"
                        control={control}
                        defaultValue={null}
                        className="controller"
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                    <S.CustomDateTime>
                        <Controller
                            as={
                                <CustomDate
                                    isValidDate={afterValidation}
                                    enableDates={toEnabled}
                                    label={t('DATETIME_TO')}
                                    fullWidth={true}
                                    style={{ padding: 10 }}
                                    open={
                                        toEnabled === true ? undefined : false
                                    }
                                />
                            }
                            name="dateFilterTo"
                            control={control}
                            defaultValue={null}
                        />
                    </S.CustomDateTime>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                        <Controller
                            onChange={([, data]) => data}
                            name="selectedPartner"
                            defaultValue=""
                            control={control}
                            render={({ onChange }) => (
                                <Autocomplete
                                    styles={S.customSelectStyles}
                                    id="combo-box-demo"
                                    options={partnerOptions}
                                    getOptionLabel={(option) =>
                                        `${option.name} | ${option.cnpj}`
                                    }
                                    disableClearable={true}
                                    disablePortal
                                    renderInput={(params) => (
                                        (params.inputProps.value =
                                            handleClearTrue === true
                                                ? ''
                                                : params.inputProps.value),
                                        (
                                            <div ref={params.InputProps.ref}>
                                                <TextField
                                                    style={{
                                                        backgroundColor:
                                                            '#ffffff',
                                                        width: '100%',
                                                    }}
                                                    {...params}
                                                    noOptionsMessage={() =>
                                                        t('RESULTS_NOT_FOUND')
                                                    }
                                                    label={t(
                                                        'PLACEHOLDER_FIND_PARTNER',
                                                    )}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </div>
                                        )
                                    )}
                                    onChange={(e, data) =>
                                        handleSelectedPartner(data, onChange)
                                    }
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <S.BtnContainer>
                <CustomButtonsBackground type="submit">
                    {t('BUTTON_FIND')}
                </CustomButtonsBackground>
                <CustomButtons onClick={() => handleClearFilter()}>
                    {t('BUTTON_CLEAN_FILTER')}
                </CustomButtons>
            </S.BtnContainer>
        </S.Container>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: state.users.userData,
        statementData: state.wallet.statementData,
        statementDataFailed: state.wallet.statementDataFailed,
        partnersDataFailed: state.partners.partnersDataFailed,
        generalRulesData: state.generalRules.generalRulesData,
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ GetPartnersData, GetStatementData }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm)
