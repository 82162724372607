import * as actionTypes from './actionsTypes';
import * as API from '../api/tier';

// - - - - - -

export const GetTierData = () => {
    return dispatch => (
        API.GetTierData().then(rest => {
            dispatch(success(rest));
            return rest;
        }).catch(err => {
            console.error(err);
            dispatch(failed(err));
        })
    )

    function success(data) {
        return {
            type: actionTypes.FETCH_TIER_DATA,
            tierData: data
        }
    }

    function failed(error) {
        return {
            type: actionTypes.FETCH_TIER_FAILED,
            tierDataFailed: error
        }
    }

}

export const GetActiveTierData = () => {
    return dispatch => (
        API.GetActiveTierData().then(rest => {
            dispatch(success(rest));
            return rest;
        }).catch(err => {
            console.error(err);
            dispatch(failed(err));
        })
    )

    function success(data) {
        return {
            type: actionTypes.FETCH_ACTIVE_TIER_DATA,
            activeTierData: data
        }
    }

    function failed(error) {
        return {
            type: actionTypes.FETCH_ACTIVE_TIER_FAILED,
            activeTierDataFailed: error
        }
    }

}
