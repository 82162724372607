import React from 'react'
import PasswordStrengthBars from 'react-password-strength-bar'
import { withTranslation } from 'react-i18next'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Section from '../../../../components/Section/Section'
import { CustomButtonsBackground } from '../../../../components/CustomButtons'
import { CssTextField } from 'components/CssTextField/CssTextField'

// style
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
// UTILS
import compose from 'utils/compose'
import { InputAdornment } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { PassRules } from './passRules'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { useState } from 'react'
import * as S from './style'

import { inputPropsStyles, inputLabelPropsStyles, PwdTooltip } from './utils'

const ChangePassword = ({
    t,
    classes,
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    validationSchema,
}) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    const handleToggleOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    }
    const handleToggleNewPassword = (prevState) => {
        setShowNewPassword(!showNewPassword)
    }
    const handleToggleConfirmPassword = (prevState) => {
        setShowPasswordConfirm(!showPasswordConfirm)
    }

    const change = (name, e) => {
        e.persist()
        handleChange(e)
        setFieldTouched(name, true, false)
    }

    return (
        <S.Container>
            <Section title={t('MENU_USER_EDIT_PASSWORD')}>
                <CustomButtonsBackground onClick={handleSubmit}>
                    {t('BUTTON_SAVE')}
                </CustomButtonsBackground>
            </Section>

            <GridContainer>
                <GridItem xs={12} md={6} style={{ marginTop: '-8px' }}>
                    <CssTextField
                        fullWidth
                        label="Senha antiga"
                        id="oldPassword"
                        name="oldPassword"
                        error={
                            touched.oldPassword && Boolean(errors.oldPassword)
                        }
                        value={values.oldPassword}
                        onChange={change.bind(null, 'oldPassword')}
                        variant="outlined"
                        margin="dense"
                        style={{ backgroundColor: '#fff' }}
                        type={showOldPassword ? 'text' : 'password'}
                        InputProps={{
                            classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                            },

                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleToggleOldPassword}
                                    >
                                        {showOldPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                                focused: classes.focusedLabel,
                                error: classes.erroredLabel,
                            },
                        }}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} md={6} style={{ marginTop: '7px' }}>
                    <CssTextField
                        fullWidth
                        label="Nova senha"
                        id="newPassword"
                        name="newPassword"
                        value={values.newPassword}
                        onChange={change.bind(null, 'newPassword')}
                        variant="outlined"
                        margin="dense"
                        style={{ backgroundColor: '#fff' }}
                        error={
                            touched.newPassword && Boolean(errors.newPassword)
                        }
                        helperText={
                            touched.newPassword ? errors.newPassword : ''
                        }
                        type={showNewPassword ? 'text' : 'password'}
                        InputProps={{
                            classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleToggleNewPassword}
                                    >
                                        {showNewPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                                focused: classes.focusedLabel,
                                error: classes.erroredLabel,
                            },
                        }}
                    />
                    <PasswordStrengthBars
                        password={values.newPassword}
                        minLength={2}
                        shortScoreWord={'muito curto'}
                        barColors={[
                            '#dddddd',
                            '#ef4836',
                            '#f6b44d',
                            '#2b90ef',
                            '#25c281',
                        ]}
                        scoreWords={[
                            'muito fraca',
                            'fraca',
                            'regular',
                            'boa',
                            'forte',
                        ]}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} md={6} style={{ marginTop: '7px' }}>
                    <CssTextField
                        fullWidth
                        label="Confirmar senha"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={change.bind(null, 'confirmPassword')}
                        variant="outlined"
                        margin="dense"
                        style={{ backgroundColor: '#fff' }}
                        error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                        }
                        helperText={
                            touched.confirmPassword
                                ? errors.confirmPassword
                                : ''
                        }
                        type={showPasswordConfirm ? 'text' : 'password'}
                        InputProps={{
                            classes: {
                                root: classes.cssOutlinedInput,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleToggleConfirmPassword}
                                    >
                                        {showPasswordConfirm ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                                focused: classes.focusedLabel,
                                error: classes.erroredLabel,
                            },
                        }}
                    />
                    <div style={{ textAlign: 'right' }}>
                        <PwdTooltip
                            open={showTooltip}
                            leaveTouchDelay={3000}
                            enterTouchDelay={50}
                            onOpen={() => setShowTooltip(true)}
                            onClose={() => setShowTooltip(false)}
                            arrow
                            id="tooltip-top"
                            title={
                                <div>
                                    <b>Regras:</b>
                                    <ul
                                        style={{
                                            marginLeft: '-18px',
                                        }}
                                    >
                                        {PassRules.map((rule, index) => (
                                            <li key={index}>{rule}</li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            placement="right-end"
                            classes={{
                                tooltip: classes.tooltip,
                            }}
                        >
                            <small
                                onClick={() => setShowTooltip(!showTooltip)}
                                style={{
                                    cursor: 'pointer',
                                    color: GetThemeColorFromStorage()?.titles?.highlight?.textColor,
                                    textDecoration: 'underline',
                                    textDecorationStyle: 'dotted',
                                }}
                            >
                                Política de senha
                            </small>
                        </PwdTooltip>
                    </div>
                </GridItem>
            </GridContainer>
        </S.Container>
    )
}

export default compose(
    withTranslation(),
    withStyles(extendedFormsStyle, inputPropsStyles, inputLabelPropsStyles),
)(ChangePassword)
