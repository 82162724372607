import { Box } from '@mui/material'
import styled from 'styled-components'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'

export const Container = styled(Box)`
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 35px;
    border-radius: 6px;
    text-align: center;
    gap: 25px;
    display: flex;
    flex-direction: column;
`

export const Button = styled.button`
    height: 40px;
    padding: 0px 15px;
    border-radius: 6px;
    font: normal normal normal 15px Roboto;
    background-color: ${({ bgColor }) => bgColor || '#ffffff'};
    color: ${({ color }) => color || '#000000'};
    border: 1px solid ${({ borderColor }) => borderColor || 'transparent'};
    cursor: pointer;

    :hover {
        background-color: ${({ bgHover }) => bgHover || 'transparent'};
        border-color: ${({ bgHover }) => bgHover || 'transparent'};
        color: #ffffff;
    }

    :disabled {
        background-color: transparent;
        color: #b2b2b2;
        cursor: not-allowed;
    }
`

export const IconAlert = styled.i`
    font-size: 62px;
    color: #f8bb86;
`

export const TextAlert = styled.i`
    text-align: center;
    font: normal normal bold 15px/24px Roboto;
    letter-spacing: 0px;
    color: ${GetThemeColorFromStorage()?.titles?.highlight?.textColor};
    opacity: 1;
`
