import axios from 'axios'

export const GetPrivacyPolicies = () =>
    axios
        .get(
            `/ProgramRules/PrivacyPolicies
	`,
        )
        .then((response) => response)

// POST
export const AcceptancePrivacyPolicies = (obj) =>
    axios
        .post(`/Consumer/SetConsumerPrivacyPoliciesRules`, obj)
        .then((response) => response)
