import { Box } from '@mui/material';
import React from 'react';


function RedirectButton({ push, theme, t }) {
	return (
		<Box
			onClick={() => push('/auth/login')}
			style={{
				borderRadius: '10px',
				height: '66px',
				width: '151px',
				borderColor: 'transparent',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor:
					theme?.navigation?.buttons?.backgroundColor,
				color: theme?.navigation?.buttons?.textColor,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<p>{t('I_M_ALREADY_REGISTERED')}</p>
			<b>{t('ACCESS')}</b>
		</Box>
	);
}

export default RedirectButton;
