import axios from "axios";

// Pesquisa e grid:
// GET api/Consumer/GetIndications
export const GetIndications = async (filter) => {
  const response = await axios.get(`/Consumer/GetIndications?${filter}`);
  return response.data;
};

// Drop de status:
// GET api/Consumer/GetIndicationStatusTypes

export const GetConsumerIndicationStatusTypes = async () => {
  const response = await axios.get(
    `/Consumer/GetConsumerIndicationStatusTypes`
  );
  return response.data;
};

// POST api/Consumer/CreateIndications
export const CreateIndications = (obj) =>
  axios
    .post(`/Consumer/CreateIndications`, obj)
    .then((response) => response.data);

// Indicação de amigos:
// GET api/Consumer/VerifyReferralEmailValid
export const VerifyEmail = async (email) => {
  const response = await axios.get(
    `/Consumer/VerifyReferralEmailValid/${email}`
  );
  return response.data;
};
