import React, { useEffect, useState } from 'react'
import MaskedInput, { conformToMask } from 'react-text-mask'

const CPF_MASK_CUSTOM = [
    /[\d]/,
    /[\d]/,
    /[\d]/,
    '.',
    /[\d]/,
    /[\d]/,
    /[\d]/,
    '.',
    /[\d]/,
    /[\d]/,
    /[\d]/,
    '-',
    /[\d]/,
    /[\d]/,
]

const NOT_ONLY_NUMBER = [
    /[\w ]/,
	/[\w ]/,
	/[\w ]/,
	/[\w ]/,
	/[\w ]/,
	/[\w ]/,
]

const TextMaskCustom = ({ value, onChange, ...props }) => {
	const digits_only = string => [...string].every(c => '0123456789.-'.includes(c));
    const [maskValue, setMaskValue] = useState(
        value && value?.length < 7
            ? conformToMask(value, NOT_ONLY_NUMBER).conformedValue
            : `${conformToMask(value, CPF_MASK_CUSTOM).conformedValue}`,
    )

    const handleMask = (valueWithoutMask) => {
		if (!digits_only(`${valueWithoutMask}`)) {
			return NOT_ONLY_NUMBER
		}

        return valueWithoutMask?.length < 7 ? NOT_ONLY_NUMBER : CPF_MASK_CUSTOM
    }

    useEffect(() => {
        setMaskValue(value)
    }, [value])

    return (
        <MaskedInput
            mask={handleMask}
            value={maskValue}
            onChange={(e) => {
                const val = onChange(e.target.value)

                if (val) {
                    setMaskValue(val)
                } else {
                    setMaskValue(e.target.value)
                }
            }}
            {...props}
            guide={false}
        />
    )
}

export default TextMaskCustom
