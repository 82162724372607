import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

	html, body {
		height: 100%;
	}

	body{
		overflow-y: auto !important;
		padding-right: 0 !important;
		button{
			box-shadow:none !important;
		}

	}

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
`

export default GlobalStyle
