import React from 'react';
import CustomInput from 'components/CustomInput/CustomInput.jsx'

export default ({type, state, labelText, inputProps})=>(
    <CustomInput
        id={type}
        name={type}
        labelText={ labelText }
        success={state ==='success'}
        error={state === 'error'}
        formControlProps={{ fullWidth: true, }}
        inputProps={inputProps}
    />
)