import React from 'react'
import PerfectScrollbar from 'perfect-scrollbar'
import NewMenu from '../../NewMenu'
import '../hideMobileMenu.css'
var ps
class SidebarWrapper extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false,
            })
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf('Win') > -1) ps.destroy()
    }
    render() {
        const { className, user, handleDrawerToggle } = this.props
        return (
            <div
                className={className}
                ref="sidebarWrapper"
            >
                {user}
                <NewMenu handleDrawerToggle={handleDrawerToggle} />
            </div>
        )
    }
}
export default SidebarWrapper
