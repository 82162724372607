import React from 'react'
// import Button from 'components/CustomButtons/Button.jsx'
import {
    CustomButtonsBackground,
    CustomButtons,
} from '../../components/CustomButtons'

export const SingleButton = ({
    _onClickMethod,
    _buttonName,
    _buttonStyles,
    disableButton = false,
    isCancel = false,
}) => {
    return (
        <CustomButtonsBackground //botao em si
            onClick={_onClickMethod}
            disabled={disableButton}
        >
            {_buttonName}
        </CustomButtonsBackground>
    )
}

export const SingleButtonCancel = ({
    _onClickMethod,
    _buttonName,
    _buttonStyles,
    disableButton = false,
    isCancel = false,
}) => {
    return (
        <CustomButtons //botao em si
            onClick={_onClickMethod}
            disabled={disableButton}
        >
            {_buttonName}
        </CustomButtons>
    )
}
