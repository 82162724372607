import React, { useEffect, useState } from 'react'
import {
    Button,
    GridItem,
    InputUSDCurrency,
    MuiBox,
    MuiTypography,
    formatBRCurrency,
} from '../../../../components/storybook'
import { Controller } from 'react-hook-form'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export function ConvertedPoints({
    control,
    watchFields,
    inputKey,
    handleSimulateClick,
    showValueInput,
    dataSimulatePointsConversion,
    errorMessageValue,
    handleClearClick,
    isButtonDisabledSimulate,
}) {
    return (
        <>
            <GridItem xs={12} sm={12} md={4} lg={4}>
                <Controller
                    name="points"
                    control={control}
                    render={({ ...field }) => (
                        <>
                            <MuiBox
                                sx={{
                                    '& .MuiFormHelperText-root': {
                                        marginBottom: '-5px',
                                    },
                                }}
                            >
                                <InputUSDCurrency
                                    {...field}
                                    key={inputKey}
                                    placeholder="Pontos a serem convertidos"
                                    label={
                                        watchFields?.points ? 'Pontos' : null
                                    }
                                    value={watchFields?.points ?? ''}
                                    error={errorMessageValue ?? ''}
                                    helperText={errorMessageValue ?? ''}
                                    maskedValue={
                                        localStorage?.programCurrencySymbol
                                    }
                                    disabled={
                                        dataSimulatePointsConversion?.liquidValue >
                                            0 && showValueInput
                                    }
                                    defaultColor={
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor
                                    }
                                />
                            </MuiBox>
                        </>
                    )}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={2} lg={2}>
                <Button
                    border={`1px solid ${
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }`}
                    defaultColor={
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }
                    icon=""
                    outlined="outlined"
                    text="Simular"
                    textColor="#FFFFFF"
                    typeVariation="CustomTextButton"
                    borderRadius="5px"
                    onClick={handleSimulateClick}
                    disabled={
                        isButtonDisabledSimulate ||
                        (dataSimulatePointsConversion?.liquidValue > 0 &&
                            !errorMessageValue &&
                            showValueInput)
                    }
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={2} lg={2}>
                <Button
                    borderColor={
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }
                    defaultColor="transparent"
                    icon=""
                    outlined="outlined"
                    text="Limpar"
                    textColor={
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }
                    typeVariation="CustomTextButton"
                    borderRadius="5px"
                    onClick={handleClearClick}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
                <></>
            </GridItem>
            {dataSimulatePointsConversion?.liquidValue > 0 &&
                showValueInput && (
                    <GridItem xs={12} sm={12} md={4} lg={4} mt={-4} mb={3}>
                        <MuiTypography
                            fontSize="13px"
                            color="rgba(0, 0, 0, 0.6)"
                        >
                            Valor
                        </MuiTypography>
                        <MuiBox
                            sx={{
                                backgroundColor: '#F0F0F0',
                                border: `2px solid ${
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }`,
                                borderRadius: '5px',
                                padding: '8.5px 14px',
                            }}
                        >
                            <MuiTypography
                                fontSize="16px"
                                color={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                fontWeight={600}
                            >
                                {dataSimulatePointsConversion?.liquidValue
                                    ? formatBRCurrency(
                                          dataSimulatePointsConversion?.liquidValue,
                                      )
                                    : '-'}
                            </MuiTypography>
                        </MuiBox>
                    </GridItem>
                )}
        </>
    )
}
