import {
    drawerWidth,
    drawerMiniWidth,
    transition,
    containerFluid,
} from 'assets/jss/material-dashboard-pro-react.jsx'

const appStyle = (theme) => ({
    wrapper: {
        position: 'relative',
        top: '0',
        height: '100%',
        '&:after': {
            display: 'table',
            clear: 'both',
            content: '" "',
        },
        maxWidth: '1200px',
        margin: '0 auto',
    },
    mainPanel: {
        transitionProperty: 'top, bottom, width',
        transitionDuration: '.2s, .2s, .35s',
        transitionTimingFunction: 'linear, linear, ease',
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        // overflow: "auto",
        position: 'relative',
        float: 'right',
        ...transition,
        maxHeight: '100%',
        width: '100%',
        overflowScrolling: 'touch',
    },
    content: {
        marginTop: '25px',
        // padding: '0 15px',
        marginLeft: '-13px',
        minHeight: 'calc(100vh - 123px)',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 0px 0 15px !important',
        },
    },
    container: { ...containerFluid },
    map: {
        marginTop: '70px',
    },
    mainPanelSidebarMini: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerMiniWidth}px)`,
        },
    },
    mainPanelWithPerfectScrollbar: {
        overflow: 'hidden !important',
    },
})

export default appStyle
