import styled from 'styled-components'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export const CustomCard = styled.div`
	.MuiCard-root {
		width: 161px;
		padding: 15px;
		background-color: #f5f5f5;
		box-shadow: none;
		&:hover {
			cursor: pointer;
			background-color: ${GetThemeColorFromStorage()?.navigation?.buttons
				?.backgroundColor};
			.MuiIcon-root {
				color: #fff;
			}
			h1 {
				color: #fff;
			}
		}
	}
`

export const HeaderIcons = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 5px;
`
export const CustomIcon = styled.div`
	.MuiIcon-root {
		color: ${GetThemeColorFromStorage()?.visual?.icons?.color};
		font-size: 30px;
		transform: rotateY(180deg);
		overflow: inherit;
	}
`
export const CardTitle = styled.h1`
	font: normal normal normal 15px/20px Roboto;
	color: #333333;
	opacity: 1;
`

export const Observation = styled.p`
	margin-top: 60px;
	color: ${GetThemeColorFromStorage()?.navigation?.buttons?.backgroundColor};
	font: normal normal normal 15px/60px Roboto;
`
