import styled from 'styled-components'
import { CssTextField } from 'components/CssTextField/CssTextField'

export const InputText = styled(CssTextField)`
    p {
        text-align: left !important;
    }


    label {
        white-space: nowrap;
    }
`
