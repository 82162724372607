import React, { useCallback, useEffect, useState } from 'react';
import { GetThemeColorFromStorage } from '../../redux/api/themeColor';
import { InputText } from './styles';

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput = ({
    className = '',
    max,
    onValueChange,
    style = {},
    name,
    value,
    setValue,
    helperText,
    error,
    clear,
    label,
    disabled,
}) => {
    const [originalValueArray, setOriginalValueArray] = useState([]);
    const [valueDisplay, setValueDisplay] = useState('');
    const [colors] = useState(GetThemeColorFromStorage());

    useEffect(() => {
        const originalValue =
            originalValueArray.length === 0
                ? 0
                : Number.parseInt(originalValueArray.join(''));

        if (originalValue / 100 <= max) {
            setValue(name, originalValue / 100);
        }
    }, [max, name, originalValueArray, setValue]);

    useEffect(() => {
        setValueDisplay(
            value
                ? value.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                  })
                : `R$ 0,00`,
        );
    }, [value]);

    useEffect(() => {
        if (clear === true) {
            setOriginalValueArray([]);
            setValueDisplay('');
            setValue(name, '');
        }
    }, [clear, name, setValue]);

    const handleInput = useCallback(
        (e) => {
            const { target } = e;
            const inputValue = target.value;

            // Lógica para lidar com a entrada do teclado
            const cleanedValue = inputValue.replace(/[^0-9]+/g, '');
            const nextValue = cleanedValue === '' ? 0 : parseInt(cleanedValue, 10);

            if (nextValue > max) {
                return;
            }

            setOriginalValueArray(cleanedValue.split(''));
            onValueChange(nextValue);
        },
        [max, onValueChange],
    );

    return (
        <InputText
            label={label}
            fullWidth
            borderText={colors?.titles?.secondary?.textColor}
            size="small"
            variant="outlined"
            className={className}
            inputMode="numeric"
            onInput={handleInput}
            style={style}
            value={valueDisplay}
            error={error}
            helperText={error ? helperText : ''}
            disabled={disabled}
        />
    );
};

export default CurrencyInput;
