import React, { useEffect, useState } from 'react'
import Datetime from 'react-datetime'
import MaskedInput from 'react-text-mask'
import { ContextDate } from './styles.js'
import { InputAdornment } from '@material-ui/core'
import { CssTextField } from 'components/CssTextField/CssTextField'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor.js'

const DateMaskCustom = (props) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        />
    )
}

function CustomDate({
    enableDates = false,
    label = '',
    marginLeft = '0px',
    helperText = '',
    error = '',
    fullWidth = false,
    ...values
}) {
    const [theme, setTheme] = useState(GetThemeColorFromStorage())

    useEffect(() => {
        setTheme(GetThemeColorFromStorage())
    }, [])

    return (
        <ContextDate theme={theme} className="customDate">
            <Datetime
                {...values}
                formatDate="dd/MM/yyyy"
                locale="pt-BR"
                timeFormat={false}
                closeOnSelect
                renderInput={(props) => {
                    return (
                        <CssTextField
                            {...props}
                            theme={theme}
                            name={values.name}
                            disabled={!enableDates}
                            helperText={helperText}
                            error={error}
                            label={label}
                            fullWidth={fullWidth}
                            margin="dense"
                            value={values?.value}
                            variant="outlined"
                            style={{
                                backgroundColor:
                                    enableDates === true ? '#fff' : '#f9f9f9',
                                marginLeft: marginLeft,
                            }}
                            InputProps={{
                                ...props,
                                endAdornment: (
                                    <InputAdornment>
                                        {enableDates === true ? (
                                            <div className="calendario">
                                                <i className="icon-data" />
                                            </div>
                                        ) : (
                                            <div className="CalendarioDesabilitado">
                                                <i className="icon-data" />
                                            </div>
                                        )}
                                    </InputAdornment>
                                ),
                                inputComponent: DateMaskCustom,
                            }}
                        />
                    )
                }}
            />
        </ContextDate>
    )
}

export default CustomDate
