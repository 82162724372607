import styled from 'styled-components'

export const HeaderBox = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    span {
        margin-right: 15px;
    }
`
export const sectionTitle = styled.div`
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;

    &:after {
        border-top: 3px solid ${(props) => props.borderColor};
        content: '';
        display: table-cell;
        position: relative;
        width: 100%;
    }
`
export const BtnHeaderBox = styled.div`
    display: flex;
    gap: 15px;
    margin-left: 15px;
`
