import React, { memo } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import * as Style from './styles'
import RegisterInput, {
    CellPhoneMaskCustom,
    ResponsableCPF,
} from 'components/RegisterInput'
import { useTranslation } from 'react-i18next'

function ResponsableData({ errors, control, watch }) {
    const { t } = useTranslation()
    return (
        <Style.Container
            container
            item
            xs={12}
            spacing={2}
            alignItems="flex-start"
        >
            <Grid item xs={12} style={{ marginTop: '25px' }}>
                <b>Dados do responsável</b>
            </Grid>
            <Controller
                as={
                    <RegisterInput
                        label="Nome completo"
                        type="name"
                        xs={12}
                        md={9}
                        error={!!errors?.nameRP}
                        helperText={t(errors?.nameRP?.message)}
                    />
                }
                name="nameRP"
                control={control}
                id="nameRP"
            />
            <Controller
                as={
                    <RegisterInput
                        xs={12}
                        md={3}
                        label="CPF"
                        InputProps={{
                            inputComponent: !watch('cpfRP')?.includes('*')
                                ? ResponsableCPF
                                : undefined,
                        }}
                        error={!!errors?.cpfRP}
                        hasTooltip
                        helperText={t(errors?.cpfRP?.message)}
                    />
                }
                name="cpfRP"
                control={control}
                id="cpfRP"
            />

            <Grid item container xs={12} md={6} alignItems="center">
                <Controller
                    as={
                        <RegisterInput
                            label="E-mail"
                            xs={12}
                            error={!!errors?.emailRP}
                            helperText={t(errors?.emailRP?.message)}
                        />
                    }
                    name="emailRP"
                    control={control}
                    id="emailRP"
                />
                <Controller
                    as={<TextField style={{ display: 'none' }} />}
                    name="emailRpPre"
                    control={control}
                    id="emailRpPre"
                />
            </Grid>

            <Controller
                as={
                    <RegisterInput
                        label="Celular"
                        xs={12}
                        md={2}
                        error={!!errors?.phoneNumberRP}
                        helperText={t(errors?.phoneNumberRP?.message)}
                        InputProps={{
                            inputComponent: CellPhoneMaskCustom,
                        }}
                    />
                }
                name="phoneNumberRP"
                control={control}
                id="phoneNumberRP"
            />
        </Style.Container>
    )
}

export default memo(ResponsableData)
