// import config from 'config';
import axios from 'axios'
import { authHeader } from '../../utils'
import { envs } from '../api/windowServerData'

export const userService = {
    login,
    logout,
    getAll,
    changePassword,
}

function login(username, password) {
    const authData = {
        username,
        password,
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json-patch+json',
            appId: 'CSM1803',
            // companyInternalName: 'germini',
        },
        body: JSON.stringify(authData),
    }

    return fetch(
        // `${envs.REACT_APP_GERMINI_IDENTITY_URL}api/v2/Account/Login`,
        `${envs.REACT_APP_GERMINI_IDENTITY_URL}api/v2/Account/Login`,
        requestOptions,
    )
        .then(handleResponse)
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', user.token)
            localStorage.setItem('user', JSON.stringify(user.user))

            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + user.token
            return user.user
        })
}

function logout() {
    // cleaning cache from platform
    fetch(`${window.location.origin}/clear-site-data`, {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            CSRF: 'storage',
        }),
    })
    // localStorage.clear()
    localStorage.removeItem('token')
    localStorage.removeItem('user')
}

function changePassword(id, obj) {
    try {
        let userId = ''
        userId = JSON.parse(localStorage.getItem('user'))?.id

        return axios
            .post(
                // `${envs.REACT_APP_GERMINI_IDENTITY_URL}api/v2/Account/Password/Change`,
                `${envs.REACT_APP_GERMINI_IDENTITY_URL}api/v2/Account/Password/Change`,
                {
                    id: id ?? userId,
                    oldPassword: obj.oldPassword,
                    newPassword: obj.newPassword,
                },
            )
            .then((response) => {
                if (response?.data?.success) {
                    return response?.data
                }
                return Promise.reject(response?.data?.errors[0])
            })
    } catch (error) {
        console.log(error)
    }
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    }

    return fetch(`http://localhost:5000/users`, requestOptions)
        .then
        //handleResponse,
        ()
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text)
        if (data.code === 429) {
            // ERRO 429
            const error =
                'Limite de acessos excedido. Aguarde uns minutos e tente novamente'
            return Promise.reject(error)
        }
        //
        var myHeaders = response.headers
        myHeaders.forEach(function (val, key) {
            // console.log('=>>> Header = ' + key + ' -> ' + val)
        })
        //
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout()
            }

            const error = data || response.statusText
            return Promise.reject(error)
        }

        const user = {
            user: data.data.user,
            token: data.data.token,
        }

        return user
    })
}
