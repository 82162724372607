import * as API from '../../../redux/api/user'

export default (
    bruteValue,
    setAntecipationFeeTotal,
    setAntecipationFeeTotalPercentage,
    setAntecipationFees,
    setTransferFee,
    setTransferFeePercentage,
    setLiquidValueToTransfer,
    setDisabledSaveButton,
    BrazilCurrency,
    showModal,
    setValuesError,
    minTransferValue,
    maxTransferValue,
    showMinError,
    showMaxError,
    hideAllErrors,
    t,
) => {
    // se nao tem o valor escrito no Input, nao faz a solicitacao ----------------
    if (bruteValue <= 0 || bruteValue === null || bruteValue === undefined)
        return
    // ---------------------------------------------------------------------------
    API.GetTransferTax(bruteValue)
        .then((res) => {
            setValuesError('')
            hideAllErrors()

            const response = JSON.parse(JSON.stringify(res))
            if (response.success === true) {
                const transferData = response.data
                setAntecipationFeeTotal(transferData.anticipationFee) //num
                setAntecipationFeeTotalPercentage(
                    transferData.anticipationFeePercentage,
                ) //num
                setAntecipationFees(transferData.anticipationFees) // obj
                setTransferFee(transferData.transferFee)
                setTransferFeePercentage(transferData.transferFeePercentage)
                setLiquidValueToTransfer(transferData.liquidValue)
                setDisabledSaveButton(false) //habilitar botao de transferir
                hideAllErrors()
            } else {
                setDisabledSaveButton(true) //desabilitar botao de transferir
                response.errors.forEach((_error) => {
                    hideAllErrors()
                    let errorMessage = ''
                    switch (_error.message) {
                        case 'transfer - NOT_ALLOWED':
                            errorMessage += 'Transferência não permitida. '
                            hideAllErrors()
                            break

                        case 'balance - INSUFFICIENT_ACCOUNT_BALANCE':
                            errorMessage += 'Não há saldo o suficiente. '
                            hideAllErrors()
                            break

                        case 'value - INVALID_VALUE':
                            errorMessage += `Valor inválido: ${BrazilCurrency(
                                bruteValue,
                            )} `
                            hideAllErrors()
                            break

                        case 'value - MAXIMUM_VALUE_NOT_ALLOWED':
                            errorMessage += `Valor máximo: ${BrazilCurrency(
                                maxTransferValue,
                            )}`
                            showMaxError()
                            break

                        case 'value - MINIMUM_VALUE_NOT_ALLOWED':
                            errorMessage += `Valor mínimo: ${BrazilCurrency(
                                minTransferValue,
                            )}`
                            showMinError()
                            break

                        default:
                            errorMessage += `${_error.message}`
                            hideAllErrors()
                            break
                    }
                    setValuesError(errorMessage)
                })
            }
        })
        .catch((err) => {
            showModal(
                `#${t(
                    'Erro ao calcular a taxa de transferência, por favor, entre em contato com o suporte.',
                )}#`,
            )
            setValuesError('')
        })
}
