import { Box } from '@mui/material'
import Styled from 'styled-components'

export const CardBox = Styled.form`
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	gap: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 45px;
	justify-content: center;

	width: 630px;

	i {
		font-size: 79px;
		color:   #F8BB86;
	}

`

export const Typography = Styled(Box)`
	text-align: center;
	font: normal normal normal 16px Roboto;
	color: #333333;
	opacity: 1;
`

export const ButtonBox = Styled(Box)`
	display: flex;
	gap: 15px;

	button {
		width: 127px !important;
		text-transform: uppercase !important;
	}
`
