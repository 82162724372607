import React from 'react'

export const executeRequisition = (
    res,
    showModal,
    daysToProcessTransfer,
    generalRules,
) => {
    if (res?.success === true) {
        showModal(
            <p>
                <span style={{ fontSize: '22px' }}>
                    Solicitação realizada com sucesso.
                </span>
                <br />
                {/* Sua transferência será processada em {
                    daysToProcessTransfer
                } */}
                {generalRules.allowDaysToProcessTransfer === true &&
                daysToProcessTransfer > 0
                    ? ` Sua transferência será processada em ${daysToProcessTransfer}  ${
                          daysToProcessTransfer > 1 ? 'dias úteis' : 'dia útil'
                      }`
                    : `Em breve sua transferência será aprovada`}
            </p>,

            true,
        )
    } else {
        let errorMessage = ''

        res.errors.map((_error) => {
            switch (_error.message) {
                case 'Password - PASSWORD_INCORRECT':
                    errorMessage += 'Senha incorreta.#'
                    break
                case 'Password - INVALID_VALUE':
                    errorMessage += 'Senha incorreta.#'
                    break

                case 'ConsumerAccount - REQUIRED_FIELD':
                    errorMessage +=
                        'Não há método de transferência cadastrado.#'
                    break

                case 'TransferType - REQUIRED_FIELD':
                    errorMessage +=
                        'Não há métodos de transferência selecionado.#'
                    break

                case 'Value - INVALID_VALUE':
                    errorMessage += 'Valor da transferência inválido.#'
                    break

                case 'Value - MINIMUM_VALUE_NOT_ALLOWED':
                    errorMessage +=
                        'Este valor é menor do que o valor mínimo de transferência.#'
                    break

                case 'Value - MAXIMUM_VALUE_NOT_ALLOWED':
                    errorMessage +=
                        'Este valor excedeu o valor máximo de transferência.#'
                    break

                case 'Value - INSUFFICIENT_ACCOUNT_BALANCE':
                    errorMessage +=
                        'O valor na conta é insuficiente para realizar esta transferência.#'
                    break

                case 'Value - EXCEEDED_TRANSFER_LIMIT':
                    errorMessage += 'O limite de transferências foi excedido.#'
                    break

                case 'Value - PERIOD_AVAILABLE_FOR_TRANSFERS_EXCEEDED':
                    errorMessage +=
                        'Você atingiu o horário limite para realizar transferências.#'
                    break

                case 'LiquidValue - INVALID_VALUE':
                    errorMessage += 'Valor líquido da transferência inválido.#'
                    break

                default:
                    errorMessage += 'Ocorreu um erro inesperado.#'
                    break
            }
        })
        showModal(errorMessage, false)
    }
}
