import styled from 'styled-components/macro'
import { Modal, Paper, Button, Grid } from '@material-ui/core'
import {
    germiniDefaultColors,
    grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import backgroundImg from '../../assets/img/bg_banner_indique_amigo.jpg'

export const ModalContainer = styled(Modal)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    header {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 10px 15px 0 20px;
        .section-part {
            .section-header {
                padding: 0;
                font-size: 15px;
                display: flex;
                white-space: nowrap;
                font-weight: 600;
                align-items: center;
                text-transform: initial;
                width: 100%;
                &::after {
                    border-top: 3px solid #21409a;
                    content: '';
                    display: none;
                    position: static;
                    width: 100%;
                    left: 0;
                    margin-left: 10px;
                }
            }
        }
    }
`

export const Background = styled(Grid)`
    background-image: url(${backgroundImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

export const SubHeader = styled.div`
    padding: 15px 15px 10px 20px;

    div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        border-radius: 8px;
        background-color: ${({ warning }) => (warning ? '#ff9800' : '#de3b2e')};
        color: #fff;
        font-weight: 600;
    }
`

export const BodyRenderModal = styled.div`
    padding-left: 20px;
    padding-right: 20px;

    margin: 15px 0;
    min-height: ${({ minheight }) => minheight};
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
`

export const PaperContainer = styled(Paper)`
    /* margin-bottom: 40px; */
    /* margin-top: 80px;
  margin-bottom: 80px; */
    position: relative;
    max-width: ${({ maxwidth }) => maxwidth};
    min-width: 880px;

    @media screen and (max-width: 880px) {
        min-width: 60vw;
    }

    padding: ${({ padding }) => padding};
    &.MuiPaper-elevation1 {
        box-shadow: ${({ shadow }) => shadow};
    }
    .sub-info {
        color: #48484c;
        font-size: 0.9rem;
        font-weight: bold;
    }

    .info-geral {
        color: #31394d;
        font-weight: bold;
        font-size: 1.3rem;
        > div {
            margin-right: 5px;
        }
    }

    .blue {
        color: #3f51b5;
    }

    .close-button {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        font-size: 15px;
        color: #fff;
        background-color: #f44336;
        padding: 6px;
        border-radius: 6px;
        &:hover {
            opacity: 0.7;
        }
        svg {
            margin-right: 3px;
        }
    }

    .sub-total {
        color: #31394d;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
    }

    .sub-price {
        color: #49d489;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
    .button-box {
        position: absolute;
        margin-top: -28px;
        top: 0;
        margin-right: -28px;
        right: 0;
        .MuiIconButton-label {
            background-color: #3f51b5;
            border-radius: 50%;
            .MuiIcon-colorPrimary {
                color: #fff;
                font-size: 2rem;
            }
        }
    }
    main {
        margin: 0 0 40px 20px;

        .title-render,
        .body-render {
            margin: 10px 0;
            padding-left: 0px;
        }

        .img-render {
            width: 250px;
            height: 200px;
        }
    }
`
export const ButtonClose = styled(Button)`
    height: 40px;
    font-size: 15px !important;
    min-width: 75px !important;
    padding: 0 15px !important;

    &.MuiButton-root {
        border-radius: 6px;
        text-transform: initial;

        color: #fff;
        background-color: ${germiniDefaultColors[12]};

        &:hover {
            background-color: ${germiniDefaultColors[12]};
            box-shadow: 10px 10px 15px 0px rgba(190, 190, 190, 0.75);
            -webkit-box-shadow: 10px 10px 15px 0px rgba(190, 190, 190, 0.75);
            -moz-box-shadow: 10px 10px 15px 0px rgba(190, 190, 190, 0.75);
        }
    }
`
