import styled from 'styled-components/macro'
import ListItem from '@material-ui/core/ListItem'
import {
    Paper,
    ListItemIcon,
    ListItemText,
    Grid,
    Button,
    TextField,
} from '@material-ui/core'
import {
    germiniDefaultColors,
    grayColor,
} from 'assets/jss/material-dashboard-pro-react'
import backgroundImg from '../../assets/img/bg_indique_amigo.jpg'

export const Background = styled(Grid)`
    background-image: url(${backgroundImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

export const spanLink = styled.span`
    color: white;
    margin-right: 9px;
    font-size: 15px;
    cursor: pointer;
`

export const wButton = styled(Button)`
    &.MuiButton-root {
        color: ${germiniDefaultColors[0]};
        text-transform: none;
        background: white;
        border-radius: 7px;
    }
    &.MuiButton-text {
        padding: 3px 20px;
    }
`

export const spanError = styled.span`
    height: 15px;
    color: white;
    margin-left: 14px;
    font-size: 13px;
    margin-top: -5px;
`

export const bButton = styled(Button)`
    &.MuiButton-root {
        color: ${germiniDefaultColors[0]};
        text-transform: none;
        background: white;
        border-radius: 7px;
    }
    &.MuiButton-text {
        padding: 3px 5px;
    }
`

export const CustomText = styled(TextField)`
    &.MuiFormControl-root {
        border-radius: 5px;
    }
`
