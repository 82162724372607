import React, { useCallback, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { FormControl } from '@material-ui/core'
import {
    Button,
    GridContainer,
    GridItem,
    MuiBox,
    MuiCheckbox,
    MuiDivider,
    MuiInput,
    MuiTypography,
} from '../../../components/storybook'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

const SearchForm = ({
    theme,
    dataSurveyDetails,
    control,
    isValid,
    setValue,
    watchfields,
    isLoading,
    handleLoader,
}) => {
    const handleCheckboxChange = (itemId, alternative) => {
        const currentValues = watchfields[itemId] || []
        const newValues = currentValues.includes(alternative)
            ? currentValues.filter((val) => val !== alternative)
            : [...currentValues, alternative]
        setValue(itemId, newValues)
    }

    const getMinSize = useCallback(
        (itemId) => {
            const length = watchfields[itemId]?.[0]?.length || 0
            return length - 1 <= 3 ? 5 : undefined
        },
        [watchfields],
    )

    const hasResponses = useMemo(() => {
        return dataSurveyDetails?.quiz?.some(
            (item) => item?.question?.responses?.length > 0,
        )
    }, [dataSurveyDetails])

    return (
        <MuiBox mt={3}>
            {dataSurveyDetails?.quiz?.length > 0 &&
                dataSurveyDetails?.quiz.map((item, index) => (
                    <GridContainer spacing={2} key={index}>
                        {item?.questionType?.value === 'OpenQuestion' && (
                            <GridItem lg={12} xs={12} md={12} sm={12} mt={3}>
                                <MuiTypography
                                    fontSize="14px"
                                    color="#717171"
                                    mb={1}
                                >
                                    {`${index + 1} - ${
                                        item?.question?.question
                                    }${item?.required ? ' *' : ''}`}
                                </MuiTypography>
                                {item?.question?.responses ? (
                                    <MuiTypography
                                        color="#717171"
                                        fontSize="14px"
                                        mb={3}
                                    >
                                        {item?.question?.responses}
                                    </MuiTypography>
                                ) : (
                                    <FormControl fullWidth>
                                        <Controller
                                            name={item.id}
                                            control={control}
                                            defaultValue=""
                                            render={({ ...field }) => (
                                                <MuiInput
                                                    {...field}
                                                    placeholder="R:"
                                                    onChange={(e) => {
                                                        setValue(item?.id, [
                                                            e?.target?.value,
                                                        ])
                                                    }}
                                                    defaultColor={
                                                        theme?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor
                                                    }
                                                    minSize={getMinSize(
                                                        item?.id,
                                                    )}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            </GridItem>
                        )}
                        {item?.questionType?.value === 'MultipleChoice' && (
                            <GridItem lg={12} xs={12} md={12} sm={12} mt={3}>
                                <MuiBox
                                    sx={{
                                        '& .MuiFormGroup-root': {
                                            paddingLeft: '11px !important',
                                        },
                                    }}
                                >
                                    <MuiTypography
                                        fontSize="14px"
                                        color="#717171"
                                        mb={2}
                                    >
                                        {item?.required
                                            ? `${index + 1} - ${
                                                  item?.question?.question
                                              } ${
                                                  item?.question?.choices === 1
                                                      ? '(mínimo uma) *'
                                                      : item?.question
                                                            ?.choices === 2
                                                      ? '(mínimo duas) *'
                                                      : '*'
                                              }`
                                            : `${index + 1} - ${
                                                  item?.question?.question
                                              } ${
                                                  item?.question?.choices === 1
                                                      ? '(mínimo uma)'
                                                      : item?.question
                                                            ?.choices === 2
                                                      ? '(mínimo duas)'
                                                      : ''
                                              }`}
                                    </MuiTypography>
                                    <GridContainer>
                                        {item?.question?.alternatives.map(
                                            (alternative, altIndex) => (
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={2}
                                                    lg={3}
                                                    mb={3}
                                                    key={altIndex}
                                                >
                                                    <Controller
                                                        name={item.id}
                                                        control={control}
                                                        render={({
                                                            ...field
                                                        }) => (
                                                            <MuiCheckbox
                                                                {...field}
                                                                labeltxt={
                                                                    alternative
                                                                }
                                                                selected={item?.question?.responses?.includes(
                                                                    alternative,
                                                                )}
                                                                onChange={() =>
                                                                    handleCheckboxChange(
                                                                        item?.id,
                                                                        alternative,
                                                                    )
                                                                }
                                                                defaultColor={
                                                                    theme
                                                                        ?.navigation
                                                                        ?.buttons
                                                                        ?.backgroundColor
                                                                }
                                                                disabled={
                                                                    (item
                                                                        ?.question
                                                                        ?.choices !==
                                                                        0 &&
                                                                        watchfields[
                                                                            item
                                                                                .id
                                                                        ]
                                                                            ?.length >=
                                                                            item
                                                                                ?.question
                                                                                ?.choices &&
                                                                        !watchfields[
                                                                            item
                                                                                .id
                                                                        ]?.includes(
                                                                            alternative,
                                                                        )) ||
                                                                    item
                                                                        ?.question
                                                                        ?.responses
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </GridItem>
                                            ),
                                        )}
                                    </GridContainer>
                                </MuiBox>
                            </GridItem>
                        )}
                        {item?.questionType?.value === 'AnswerSelection' && (
                            <GridItem lg={12} xs={12} md={12} sm={12} mt={3}>
                                <MuiTypography
                                    fontSize="14px"
                                    color="#717171"
                                    mb={1}
                                >
                                    {item?.required
                                        ? `${index + 1} - ${
                                              item?.question?.question
                                          } *`
                                        : `${index + 1} - ${
                                              item?.question?.question
                                          }`}
                                </MuiTypography>

                                <Controller
                                    name={item.id}
                                    control={control}
                                    defaultValue=""
                                    render={({ ...field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            sx={{
                                                '& span.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked.MuiRadio-root':
                                                    {
                                                        color: theme?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor,
                                                    },

                                                '& span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label':
                                                    {
                                                        color: '#4F4F4F',
                                                        fontSize: '14px',
                                                        fontWeight: 300,
                                                    },
                                                '& span.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.Mui-disabled.PrivateSwitchBase-root.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked.Mui-disabled.MuiRadio-root.MuiRadio-colorPrimary':
                                                    {
                                                        color: '#989898',
                                                    },
                                            }}
                                        >
                                            <GridContainer>
                                                {item?.question?.alternatives.map(
                                                    (alternative, altIndex) => (
                                                        <GridItem
                                                            xs={12}
                                                            sm={12}
                                                            md={2}
                                                            lg={3}
                                                            mb={3}
                                                            key={altIndex}
                                                        >
                                                            <FormControlLabel
                                                                value={
                                                                    alternative
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    alternative
                                                                }
                                                                onChange={() => {
                                                                    setValue(
                                                                        item?.id,
                                                                        {
                                                                            type: item
                                                                                .questionType
                                                                                .value,
                                                                            value: [
                                                                                alternative,
                                                                            ],
                                                                        },
                                                                    )
                                                                }}
                                                                checked={item?.question?.responses?.includes(
                                                                    alternative,
                                                                )}
                                                                disabled={
                                                                    item
                                                                        ?.question
                                                                        ?.responses
                                                                }
                                                            />
                                                        </GridItem>
                                                    ),
                                                )}
                                            </GridContainer>
                                        </RadioGroup>
                                    )}
                                />
                            </GridItem>
                        )}
                    </GridContainer>
                ))}
            <GridContainer mt={2}>
                {!hasResponses && (
                    <>
                        <GridItem lg={12} xs={12} md={12} sm={12}>
                            <MuiDivider />
                        </GridItem>
                        <GridItem lg={2} xs={12} md={2} sm={12} mt={3} mb={3}>
                            <Button
                                border={`1px solid ${theme?.navigation?.buttons?.backgroundColor}`}
                                defaultColor={
                                    theme?.navigation?.buttons?.backgroundColor
                                }
                                icon=""
                                outlined="outlined"
                                text="Enviar respostas"
                                textColor="#FFFFFF"
                                typeVariation="CustomTextButton"
                                borderRadius="3px"
                                type="submit"
                                onClick={() => handleLoader()}
                                disabled={!isValid}
                                isLoading={isLoading}
                                loaderColor="#FFFFFF"
                            />
                        </GridItem>
                    </>
                )}
            </GridContainer>
        </MuiBox>
    )
}

export default SearchForm
