import { Tooltip, withStyles } from '@material-ui/core'
import styled from 'styled-components/macro'

export const BoxPolicy = styled.div`
    ul {
        margin: 0;
    }
`

export const PoliticyText = styled.small`
    cursor: pointer;
    color: #21409a;
    text-decoration: underline;
    text-decoration-style: dotted;

    margin-top: 1rem;
`

export const PolicyTooltip = withStyles({
	arrow: {
		color: '#5d5d5d',
	},
	tooltip: {
		backgroundColor: '#5d5d5d',
		fontSize: 10,
		width: '100%',
		borderRadius: 8,
	},
	tooltipPlacementRight: {
		marginLeft: 8,
	},
	tooltipPlacementLeft: {
		marginLeft: 12,
	},
})(Tooltip)
