import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { GetUrlCatalog } from '../../../../redux/api/store'

function SectionCatalog({ title, subtitle, button }) {
    async function getCatalogRedirect() {
        const response = await GetUrlCatalog()
        if (!_.isEmpty(response)) {
            const newTab = window.open(response?.data, '_blank')
            if (newTab) {
                newTab.focus()
            }
        }
    }
    return (
        <Box
            display="flex"
            width="100%"
            sx={{
                textAlign: 'left',
                font: 'normal normal bold 15px / 21px Roboto',
                letterSpacing: '0px',
                color: '#333333',
                opacity: 1,
                whiteSpace: 'nowrap',
            }}
        >
            <Box
                sx={{
                    width: 'fit-content + 10px',
                    borderBottom: `3px solid  ${
                        GetThemeColorFromStorage()?.titles?.main?.textColor ??
                        '#333333'
                    }`,
                }}
                pr={2}
            >
                {title}
            </Box>
            <Box>{subtitle}</Box>

            <Box
                sx={{
                    width: '100%',
                    borderBottom: '1px solid #C2C2C2',
                }}
            />
            {button && (
                <Box
                    width="fit-content"
                    ml={2}
                    sx={{
                        color: GetThemeColorFromStorage()?.titles?.main
                            ?.textColor,
                        width: 'fit-content',
                        marginLeft: '16px',
                        display: 'flex',
                        gap: '5px',
                        textAlign: 'left',
                        font: 'normal normal normal 13px Roboto',
                        letterSpacing: '0px',
                        opacity: '1',
                        transform: 'translateY(5px)',
                        alignItems: 'flex-end',
                        cursor: 'pointer',
                    }}
                    onClick={() => getCatalogRedirect()}
                >
                    {button}
                    <Box
                        component="i"
                        className="icon-i_seta_link"
                        fontSize="18px"
                    />
                </Box>
            )}
        </Box>
    )
}

SectionCatalog.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    button: PropTypes.string,
}

export default SectionCatalog
