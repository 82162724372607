import { authentication } from './authenticate'

import home from './home'

// GERAL
import images from './images'
import sweetAlert from './sweetAlert'
import loader from './loader'
import location from './location'
import generalRules from './generalRules'
import invoice from './invoice'
import tier from './tier'
import wallet from './wallet'
import regulation from './regulation'
import platformRules from './platformRules'
import partners from './partners'
import statement from './statement'
import { users } from './user'
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        users,
        authentication,
        sweetAlert,
        loader,
        location,
        home,
        statement,

        images,
        generalRules,
        regulation,
        platformRules,
        tier,
        partners,
        wallet,
        invoice,
    })

export default createRootReducer
