import styled from 'styled-components/macro'
import { Modal, Paper, Button } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const ModalContainer = styled(Modal)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .MuiPaper-rounded {
        border-radius: 10px;
    }

    header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 25px;
        .section-part {
            transform: translateY(5px);
            margin-right: 18px;
            .section-header {
                color: ${GetThemeColorFromStorage()?.titles?.main?.textColor};
                padding: 0;
                font-size: 15px;
                display: flex;
                white-space: nowrap;
                font-weight: 600;
                align-items: center;
                text-transform: initial;
                width: 100%;
                &::after {
                    border-top: 3px solid
                        ${GetThemeColorFromStorage()?.titles?.main?.textColor};
                    content: '';
                    display: none;
                    position: static;
                    width: 100%;
                    left: 0;
                    margin-left: 10px;
                }
            }
        }
    }
`

export const SubHeader = styled.div`
    padding: 15px 15px 10px 20px;

    div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px;
        border-radius: 6px;
        background-color: ${({ warning }) => (warning ? '#ff9800' : '#de3b2e')};
        color: #fff;
        font-weight: 600;
    }
`

export const BodyRender = styled.div`
    padding-right: 30px;
    min-height: 319px;
    max-height: 0vh;
    max-width: 840px;
    overflow-y: auto;
    overflow-x: auto;
`

export const BodyRenderModal = styled.div`
    padding-right: 30px;
    min-height: 319px;
    max-height: 0vh;
    max-width: 840px;
    overflow-y: auto;
    overflow-x: auto;
`

export const PaperContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 900px;
    height: 600px;
    position: relative;
    main {
        margin: 0px 25px;

        .title-render,
        .body-render {
            margin: 10px 0;
            padding-left: 0px;
        }

        .title-render {
            color: ${GetThemeColorFromStorage()?.titles?.secondary?.textColor};
            height: 21px;
        }

        .img-render {
            width: 250px;
            height: 200px;
        }
    }

    max-width: 60vw;

    @media screen and (max-width: 880px) {
        min-width: 60vw;
    }

    padding: ${({ padding }) => padding};
    &.MuiPaper-elevation1 {
        box-shadow: ${({ shadow }) => shadow};
    }
    .sub-info {
        color: #48484c;
        font-size: 0.9rem;
        font-weight: bold;
    }

    .info-geral {
        color: #31394d;
        font-weight: bold;
        font-size: 1.3rem;
        > div {
            margin-right: 5px;
        }
    }

    .blue {
        color: #3f51b5;
    }

    .close-button {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        font-size: 15px;
        color: #fff;
        background-color: #f44336;
        padding: 6px;
        border-radius: 6px;
        &:hover {
            opacity: 0.7;
        }
        svg {
            margin-right: 3px;
        }
    }

    .sub-total {
        color: #31394d;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
    }

    .sub-price {
        color: #49d489;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
    .button-box {
        position: absolute;
        margin-top: -28px;
        top: 0;
        margin-right: -28px;
        right: 0;
        .MuiIconButton-label {
            background-color: #3f51b5;
            border-radius: 50%;
            .MuiIcon-colorPrimary {
                color: #fff;
                font-size: 2rem;
            }
        }
    }
`

export const ButtonClose = styled(Button)`
    height: 40px;
    font-size: 15px !important;
    min-width: 75px !important;
    padding: 0 15px !important;

    &.MuiButton-root {
        border-radius: 6px;
        text-transform: initial;

        color: #fff;
        background-color: ${germiniDefaultColors[12]};

        &:hover {
            background-color: ${germiniDefaultColors[12]};
            box-shadow: 10px 10px 15px 0px rgba(190, 190, 190, 0.75);
            -webkit-box-shadow: 10px 10px 15px 0px rgba(190, 190, 190, 0.75);
            -moz-box-shadow: 10px 10px 15px 0px rgba(190, 190, 190, 0.75);
        }
    }
`

export const NotificationSkeleton = styled(Skeleton)`
    margin: 10px 0px;
`
