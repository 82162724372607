import axios from 'axios'


// GET api/ProgramRules/General
export const GetGeneralRules = () =>
axios
    .get(`/ProgramRules/General/`)
    .then((response) => response.data)


// GET api/ProgramRules/General
export const GetGeneralRulesData = () =>
    axios
        .get(`/ProgramRules/General/BasicData`)
        .then((response) => response.data)

// GET api/ProgramRules/General/GetCurrencies
export const GetCurrencies = () =>
    axios
        .get(`/ProgramRules/General/GetCurrencies`)
        .then((response) => response.data)

// GET api/ProgramRules/ProgramDescription
export const GetProgramDescription = () =>
    axios
        .get(`/ProgramRules/ProgramDescription/GetBasic`)
        .then((response) => response.data)

export const GetDigitalWalletRules = async () => {
    try {
        const res = await axios.get(`/ProgramRules/General/DigitalWalletRules`)
        return res
    } catch (error) {
        console.log(error)
    }
}
