/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import DocumentTitle from 'react-document-title'
import { useTranslation } from 'react-i18next'

// @Images
import imgSuccess from 'assets/img/NF_sucesso.png'
import imgRequested from 'assets/img/NF_registrada.png'

// REDUX
import {
	GetCurrentConsumer,
	GetInvoicesCreditSource,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
	GetData,
} from 'redux/actions'
import { bindActionCreators } from 'redux'
import { useDispatch, connect } from 'react-redux'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

// @Components
import CustomDate from 'components/CustomDate'
import Section from 'components/Section/Section'
import Loader from 'components/Loader/Loader.jsx'
import CurrencyInput from 'components/NewCustomInput'
import { CssTextField } from 'components/CssTextField/CssTextField'
import { CustomButtonsBackground } from '../../components/CustomButtons'

// @material-ui
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import { RadioGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'

// @Styles
import * as S from './styles'
import styles from './points.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

// UTILS
import compose from 'utils/compose'
import { defaultValues, statemenStyle, validationSchema } from './utils'

// HOOK FORM
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

// MOMENT JS
import * as moment from 'moment'
import { getProgramTitle } from '../../utils/utils'

const PointsRequestNew = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { classes } = props
	const [theme, setTheme] = React.useState(GetThemeColorFromStorage())

	useEffect(() => {
		setTheme(GetThemeColorFromStorage())
	}, [])

	const { handleSubmit, control, watch, errors, setValue, formState } =
		useForm({
			defaultValues: defaultValues,
			mode: 'all',
			resolver: yupResolver(validationSchema),
		})

	const watchAllFields = watch()

	const [results, setResults] = useState(false)

	useEffect(() => {
		if (props.invoiceCreditSource.success) {
			setResults({
				img: () => (
					<img src={imgSuccess} alt="Nota fiscal já cadastrada" />
				),
				title: 'Nota fiscal reconhecida com sucesso.',
				subTitle: `Caso haja recompensa, aguarde de 3 a 5 dias para receber.`,
				subTitle2: `A Nota estará disponível em seu histórico de compras.`,
			})
		}
		if (props.invoiceCreditSource?.success === false) {
			switch (props.invoiceCreditSource?.errors[0]?.message) {
				case 'Invoice - DUPLICATED':
					setResults({
						img: () => (
							<img
								src={imgRequested}
								alt="Nota fiscal já cadastrada"
							/>
						),
						title: 'Essa Nota Fiscal já foi registrada',
					})
					break

				case 'Data - INVALID_VALUE':
					setResults({
						img: () => (
							<img
								src={imgRequested}
								alt="Nota fiscal já cadastrada"
							/>
						),
						title: 'Data incorreta',
					})
					break

				case 'Total - INVALID_VALUE':
					setResults({
						img: () => (
							<img
								src={imgRequested}
								alt="Nota fiscal já cadastrada"
							/>
						),
						title: 'Valor total incorreto ou não preenchido',
					})
					break

				case 'Date - INVOICE_DATE_EXPIRED':
					setResults({
						img: () => (
							<i
								className={'icon-pontos_expirados'}
								style={{ color: '#EEA600', fontSize: '74px' }}
							/>
						),
						title: 'O prazo para solicitação das recompensas para essa nota expirou.',
					})
					break
				case 'Invoice - NOT_FOUND':
				case 'invoiceId - NOT_FOUND':
					setResults({
						img: () => (
							<i
								className={'icon-nf_nao_reconhecida'}
								style={{ color: 'red', fontSize: '74px' }}
							/>
						),
						title: 'A Nota Fiscal não foi reconhecida.',
						subTitle: `Caso tenha realizado a compra a poucos instantes, aguarde até 1 hora e tente novamente.`,
						subTitle2: 'Lembre-se',
						subTitle3: `Só serão reconhecidas Notas Fiscais geradas `,
						subTitle4: `lojas participantes do  ${props.generalRulesData.name}. `,
					})
					break
				case 'consumerId - INVALID_VALUE':
					setResults({
						img: () => (
							<img
								src={imgRequested}
								alt="Essa Nota Fiscal já foi resgatada"
							/>
						),
						title: 'Essa Nota Fiscal já foi resgatada',
						subTitle2: '',
					})
					break
				default:
					break
			}
		}
	}, [
		props.invoiceCreditSource,
		props.generalRulesData.daysForPointRelease,
		props.generalRulesData.daysToRequestPoints,
		props.generalRulesData,
	])

	useEffect(() => {
		setValue('invoiceNumber', '')
		setValue('purchaseValue', '')
		setValue('invoiceKey', '')
		setValue('invoiceData', '')
		setResults(false)
	}, [setValue, watchAllFields.radioValue])

	const onSubmit = async (data) => {
		await new Promise((resolve) => setTimeout(resolve, 500))

		const purchaseDate =
			data.invoiceData !== ''
				? moment(data.invoiceData).format('YYYY-MM-DD')
				: ''

		try {
			dispatch(ShowLoader(<Loader />))
			dispatch(
				GetInvoicesCreditSource(
					props.user?.id,
					data.invoiceKey,
					data.invoiceNumber,
					purchaseDate,
					data.purchaseValue,
				),
			)
		} catch (error) {
			console.error(error)
		} finally {
			dispatch(HideLoader())
		}
	}

	return (
		<S.Container>
			{formState.isSubmitting && <Loader />}
			<form
				style={{ width: '100%', height: '100%' }}
				onSubmit={handleSubmit(onSubmit)}
			>
				<DocumentTitle title={getProgramTitle(t('MENU_REWARD_REQUEST'))} />
				<S.InvoiceGrid container>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						className="REWARD_REQUEST"
					>
						<Section title={t('MENU_REWARD_REQUEST')} />
					</Grid>
					<Grid item xs={12} sm={2} md={2} className="REQUEST_BUTTON">
						<CustomButtonsBackground
							type="submit"
							style={{
								float: 'right',
								margin: 0,
								fontSize: '18px',
							}}
							disabled={Boolean(formState.isSubmitting)}
						>
							{t('FIELD_INVOICE_REQUEST_BUTTON')}
						</CustomButtonsBackground>
					</Grid>
				</S.InvoiceGrid>
				<S.ContainerForm theme={theme}>
					<S.RadiusBox theme={theme}>
						<Controller
							rules={{ required: true }}
							control={control}
							name="radioValue"
							defaultValue={'op1'}
							render={({ onBlur, onChange, value }) => (
								<RadioGroup
									className="RadioGroup"
									value={value}
									onBlur={onBlur}
									onChange={(e) => {
										onChange(e)
									}}
								>
									<S.RadiusOptions>
										<FormControlLabel
											value="op1"
											control={
												<Radio
													value="op1"
													icon={
														<FiberManualRecord
															className={
																classes.radioUnchecked
															}
														/>
													}
													checkedIcon={
														<FiberManualRecord
															className={
																classes.radioChecked
															}
														/>
													}
													classes={{
														checked: classes.radio,
														root: classes.radioRoot,
													}}
												/>
											}
										/>
									</S.RadiusOptions>
									<S.RadiusOptions>
										<FormControlLabel
											value="op2"
											control={
												<Radio
													value="op2"
													aria-label="op2"
													icon={
														<FiberManualRecord
															className={
																classes.radioUnchecked
															}
														/>
													}
													checkedIcon={
														<FiberManualRecord
															className={
																classes.radioChecked
															}
														/>
													}
													classes={{
														checked: classes.radio,
														root: classes.radioRoot,
													}}
												/>
											}
										/>
									</S.RadiusOptions>
								</RadioGroup>
							)}
						/>
					</S.RadiusBox>
					<S.InputsBox>
						<S.InvoiceNumber>
							<div className="number">
								<Controller
									name="invoiceNumber"
									control={control}
									render={({ name, onChange, value }) => (
										<CssTextField
											fullWidth
											value={value.replace(
												/[^0-9\.]+/g,
												'',
											)}
											label={'Número da nota fiscal'}
											id="invoiceNumber"
											name={name}
											onChange={onChange}
											error={errors.invoiceNumber}
											helperText={
												errors.invoiceNumber &&
												errors.invoiceNumber.message
											}
											variant="outlined"
											margin="dense"
											style={{
												backgroundColor: '#fff',
												width: '100%',
											}}
											disabled={
												watchAllFields.radioValue ===
													'op2'
													? true
													: false
											}
											InputProps={{
												classes: {
													root: classes.cssOutlinedInput,
													focused: classes.cssFocused,
													notchedOutline:
														classes.notchedOutline,
												},
											}}
											InputLabelProps={{
												classes: {
													root: classes.label,
													focused:
														classes.focusedLabel,
													error: classes.erroredLabel,
												},
											}}
										/>
									)}
								/>
							</div>
							<div className="date">
								<Controller
									as={
										<CustomDate
											fullWidth={true}
											error={errors.invoiceData}
											helperText={
												errors.invoiceData &&
												errors.invoiceData.message
											}
											enableDates={
												watchAllFields.radioValue ===
												'op1'
											}
											open={
												watchAllFields.radioValue ===
													'op2'
													? false
													: undefined
											}
											label={t('FIELD_INVOICE_DATE')}
										/>
									}
									name="invoiceData"
									control={control}
								/>
							</div>
							<div className="purchaseValue">
								<Controller
									render={({ name, onChange, value }) => (
										<CurrencyInput
											max={1000000000}
											error={errors.purchaseValue}
											helperText={
												errors.purchaseValue &&
												errors.purchaseValue.message
											}
											onValueChange={onChange}
											clear={
												watchAllFields.radioValue ===
												'op2'
											}
											name={name}
											value={value}
											label={'Valor'}
											setValue={setValue}
											disabled={
												watchAllFields.radioValue ===
													'op2'
													? true
													: false
											}
										/>
									)}
									control={control}
									name="purchaseValue"
									defaultValue=""
								/>
							</div>
						</S.InvoiceNumber>
						<S.InvoiceKey>
							<Controller
								name="invoiceKey"
								control={control}
								render={({ name, onChange, value }) => (
									<CssTextField
										fullWidth
										value={value}
										label={t('FIELD_INVOICE_KEY')}
										id="invoiceKey"
										name={name}
										onChange={onChange}
										error={errors.invoiceKey}
										helperText={
											errors.invoiceKey &&
											errors.invoiceKey.message
										}
										variant="outlined"
										margin="dense"
										className={styles.invoice}
										style={{
											backgroundColor: '#fff',
											width: '100%',
										}}
										disabled={
											watchAllFields.radioValue === 'op1'
												? true
												: false
										}
										InputProps={{
											classes: {
												root: classes.cssOutlinedInput,
												focused: classes.cssFocused,
												notchedOutline:
													classes.notchedOutline,
											},
										}}
										InputLabelProps={{
											classes: {
												root: classes.label,
												focused: classes.focusedLabel,
												error: classes.erroredLabel,
											},
										}}
									/>
								)}
							/>
						</S.InvoiceKey>
					</S.InputsBox>
				</S.ContainerForm>
				<Grid container>
					<Grid item xs={12}>
						{results !== false && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									marginTop: '5.125rem',
								}}
							>
								<results.img />
								<div
									style={{
										fontWeight: 'bold',
										marginTop: 5,
									}}
								>
									{results.title}
								</div>
								<div>{results.subTitle}</div>

								{results.subTitle2 !== '' && (
									<div
										style={{
											background: '#F4F4F4',
											padding: 20,
											borderRadius: 10,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											marginTop: 30,
										}}
									>
										{results.subTitle2 && (
											<span
												style={{
													fontWeight: 'bold',
												}}
											>
												{results.subTitle2}
											</span>
										)}

										<p>
											{results.subTitle3}
											{results.subTitle4 && (
												<>
													<br />
													{results.subTitle4}
												</>
											)}
										</p>
									</div>
								)}
							</div>
						)}
					</Grid>
				</Grid>
			</form>
		</S.Container>
	)
}

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,
		userData: state.users.userData,
		generalRulesData: state.generalRules.generalRulesData,
		invoiceCreditSource: state.invoice.invoiceCreditSource,
		invoiceCreditSourceFailed: state.invoice.invoiceCreditSourceFailed,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetCurrentConsumer,
			GetInvoicesCreditSource,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
			GetData,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(extendedFormsStyle),
	withStyles(statemenStyle),
)(PointsRequestNew)
