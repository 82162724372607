import React from 'react'
import { SingleButton, SingleButtonCancel } from './SingleButton'
import styles from './profile.module.scss'
export default ({
    _onEdit,
    _onSubmit,
    _onCancel,
    _booleanOfForm,
    _btnNameEdit,
    _btnNameSubmit,
    _btnNameCancel,
    disabledSaveButton,
    disableEditButton,
}) => {
    const formIsBeingEdited = !_booleanOfForm
    return (
        <div
            style={{
                display: 'flex',
                gap: '15px',
            }}
        >
            {/* editar */}
            {!formIsBeingEdited && (
                <SingleButton
                    _onClickMethod={_onEdit}
                    _buttonName={_btnNameEdit}
                    _buttonStyles={styles.Button}
                    disableButton={disableEditButton}
                />
            )}
            {/* salvar */}
            {formIsBeingEdited && (
                <SingleButton
                    _onClickMethod={_onSubmit}
                    _buttonName={_btnNameSubmit}
                    _buttonStyles={styles.Button}
                    disableButton={disabledSaveButton}
                />
            )}
            {/* cancelar */}
            {formIsBeingEdited && (
                <SingleButtonCancel
                    _onClickMethod={_onCancel}
                    _buttonName={_btnNameCancel}
                    _buttonStyles={styles.CancelButton}
                    isCancel={true}
                />
            )}
        </div>
    )
}
