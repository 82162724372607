/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Card, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { MuiTypography } from "../Typography";
import { MuiBox } from "../Box";
import { MuiStack } from "../Stack";
import { typeBranchMock } from "./Mocks";
import { AddAllIcon, AddIcon, RemoveAllIcon, RemoveIcon } from "../Icons/svgIcons";
export var CheckListDivision = React.memo(function (_a) {
    var options = _a.options, label = _a.label, selectorLabel = _a.selectorLabel, defaultOptions = _a.defaultOptions, getSelectedItems = _a.getSelectedItems, _b = _a.addTextLabel, addTextLabel = _b === void 0 ? "Adicionar Todas" : _b, _c = _a.removeTextLabel, removeTextLabel = _c === void 0 ? "Deletar Todas" : _c, disabled = _a.disabled, align = _a.align, itemForRemoval = _a.itemForRemoval;
    var _d = useState([]), left = _d[0], setLeft = _d[1];
    var _e = useState([]), right = _e[0], setRight = _e[1];
    useEffect(function () {
        var initialRight = options.filter(function (option) {
            return defaultOptions.find(function (defaultOption) { return (defaultOption === null || defaultOption === void 0 ? void 0 : defaultOption.description) === (option === null || option === void 0 ? void 0 : option.description); });
        });
        setRight(initialRight);
        getSelectedItems(defaultOptions);
        setLeft(options.filter(function (option) { return !initialRight.find(function (rightOption) { return (rightOption === null || rightOption === void 0 ? void 0 : rightOption.description) === (option === null || option === void 0 ? void 0 : option.description); }); }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);
    var handleToggle = function (value, isLeft) {
        if (disabled)
            return;
        var updatedLeft = isLeft ? left.filter(function (item) { return item !== value; }) : __spreadArray(__spreadArray([], left, true), [value], false);
        var updatedRight = isLeft ? __spreadArray(__spreadArray([], right, true), [value], false) : right.filter(function (item) { return item !== value; });
        setLeft(updatedLeft);
        setRight(updatedRight);
        getSelectedItems(updatedRight);
    };
    var handleMoveAll = function (isLeft) {
        if (!disabled) {
            if (isLeft) {
                setRight(__spreadArray(__spreadArray([], right, true), left, true));
                setLeft([]);
                getSelectedItems(__spreadArray(__spreadArray([], right, true), left, true));
            }
            else {
                setLeft(__spreadArray(__spreadArray([], left, true), right, true));
                setRight([]);
                getSelectedItems([]);
            }
        }
    };
    useEffect(function () {
        if (itemForRemoval) {
            var division = options.filter(function (option) { return (option === null || option === void 0 ? void 0 : option.description) === (itemForRemoval === null || itemForRemoval === void 0 ? void 0 : itemForRemoval.description); });
            if (division.length > 0) {
                handleToggle(division[0], false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemForRemoval]);
    var renderActionButton = function (isLeft) { return (_jsxs(ListItem, __assign({ button: true, onClick: function () { return handleMoveAll(isLeft); }, sx: {
            background: isLeft ? "#F2F2F2" : "#F2F2F2",
            borderRadius: "5px",
            padding: "10px",
            height: "39px",
        }, "data-testid": isLeft ? "add-all" : "remove-all" }, { children: [isLeft ? _jsx(AddAllIcon, { size: 25, color: "#13A413" }) : _jsx(RemoveAllIcon, { size: 25, color: "#AA382E" }), _jsx(MuiTypography, __assign({ fontSize: 15, fontWeight: 700, ml: 1.5 }, { children: isLeft ? addTextLabel : removeTextLabel }))] }))); };
    var renderList = function (items, isLeft) { return (_jsx(Card, __assign({ sx: {
            border: "1px solid #C8C8C8",
            borderRadius: "4px !important",
            boxShadow: "none",
            opacity: disabled ? 0.8 : 1,
            ".MuiListItemIcon-root": {
                minWidth: "36px",
            },
        } }, { children: _jsxs(List, __assign({ dense: true, component: "div", role: "list", sx: { width: 345, height: 310, overflow: "auto", p: "8px 10px" } }, { children: [renderActionButton(isLeft), items.map(function (value, index) {
                    var _a;
                    return (_jsxs(ListItem, __assign({ onClick: function () { return handleToggle(value, isLeft); }, sx: {
                            background: "transparent",
                            borderRadius: "5px",
                            p: isLeft ? "7px 0px 10px 10px" : "8px 0px 8px 8px",
                            cursor: "pointer",
                        }, "data-testid": isLeft ? "left-item" : "right-item" }, { children: [_jsx(ListItemIcon, { children: isLeft ? _jsx(AddIcon, { size: 25, color: "#13A413" }) : _jsx(RemoveIcon, { size: 30, color: "#EB6C61" }) }), _jsx(ListItemText, { primary: _jsxs(MuiBox, __assign({ display: "flex", alignItems: "center", gap: 1 }, { children: [_jsx("div", { style: {
                                                width: "5px",
                                                height: "24px",
                                                backgroundColor: (value === null || value === void 0 ? void 0 : value.segType) && "".concat((_a = typeBranchMock[value === null || value === void 0 ? void 0 : value.segType]) === null || _a === void 0 ? void 0 : _a.bgColor),
                                                borderRadius: "4px 0px 0px 4px",
                                                opacity: 1,
                                            } }), value === null || value === void 0 ? void 0 : value.description] })) })] }), index));
                })] })) }))); };
    return (_jsx(MuiBox, __assign({ sx: { margin: "auto" } }, { children: _jsx(MuiStack, __assign({ mt: 1, direction: "row", justifyContent: align !== null && align !== void 0 ? align : "flex-start", alignItems: "center" }, { children: _jsxs(MuiBox, __assign({ display: "flex", gap: "25px", justifyContent: align !== null && align !== void 0 ? align : "flex-start", alignItems: "center" }, { children: [_jsxs(MuiBox, { children: [_jsx(MuiTypography, __assign({ variant: "body2", fontWeight: 300, pb: 1, "data-testid": "label" }, { children: label })), renderList(left, true)] }), _jsxs(MuiBox, { children: [_jsx(MuiTypography, __assign({ variant: "body2", fontWeight: 300, pb: 1, "data-testid": "selector-label" }, { children: selectorLabel })), renderList(right, false)] })] })) })) })));
});
