import React from 'react'
import {
    Button,
    GridContainer,
    GridItem,
    MuiBox,
    MuiDefaultModal,
    MuiTypography,
    ResearchIllustration,
} from '../../../../components/storybook'
import { useHistory } from 'react-router-dom'
import { handleDescription } from '../../function'
import { useMediaQuery } from '@mui/material'

export const Modal = ({ closeByClick, themeColor, search, open }) => {
    const history = useHistory()
    const isScreenSmall = useMediaQuery('(max-width:600px)')

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 840,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        p: 4,
    }
    return (
        <>
            {search?.results?.length > 0 &&
                search?.results?.map((item, index) => (
                    <MuiDefaultModal
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        open={
                            item?.showAlert &&
                            item?.status?.value === 'Active' &&
                            open
                        }
                        onClose={closeByClick}
                        key={index}
                        sx={{
                            '& .MuiBackdrop-root.MuiModal-backdrop': {
                                backgroundColor: 'transparent!important',
                            },
                        }}
                    >
                        <MuiBox
                            sx={{
                                ...style,
                                maxHeight: '90vh',
                                overflowY: 'auto',
                            }}
                        >
                            <GridContainer
                                spacing={isScreenSmall ? 0 : 1}
                                display="flex"
                                alignItems="center"
                                justifyContent="end"
                                ml={0}
                            >
                                <GridItem
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                ></GridItem>
                                <GridItem lg={4} md={4} sm={12} xs={12}>
                                    <Button
                                        borderRadius="5px"
                                        defaultColor="transparent"
                                        icon=""
                                        outlined="outlined"
                                        text="Responder mais tarde"
                                        typeVariation="CustomTextButton"
                                        onClick={closeByClick}
                                        sx={{
                                            color: `${themeColor?.navigation?.buttons?.backgroundColor} !important`,
                                            border: `1px solid ${themeColor?.navigation?.buttons?.backgroundColor}!important`,
                                            textTransform: 'inherit!important',
                                            padding: '8px 15px!important',
                                            ':hover': {
                                                border: `1px solid ${themeColor?.navigation?.buttons?.backgroundColor}!important`,
                                                textTransform:
                                                    'inherit!important',
                                                color: `${themeColor?.navigation?.buttons?.backgroundColor} !important`,
                                            },
                                            width: isScreenSmall
                                                ? '100%'
                                                : '80%!important',
                                            marginLeft: '40px!important',
                                        }}
                                    />
                                </GridItem>
                                <GridItem lg={2} md={2} sm={12} xs={12}>
                                    <Button
                                        hoverColor="#EB6C61"
                                        colorIcon="#FFFFFF"
                                        icon
                                        text="Fechar"
                                        typeVariation="CustomTextButton"
                                        borderRadius="5px"
                                        onClick={closeByClick}
                                        sx={{
                                            color: '#fff!important',
                                            textTransform: 'inherit!important',
                                            backgroundColor:
                                                '#EB6C61!important',
                                            border: '1px solid #EB6C61!important',
                                            padding: '8px 15px!important',
                                            ':hover': {
                                                border: `1px solid #EB6C61!important`,
                                                textTransform:
                                                    'inherit!important',
                                                color: '#fff!important',
                                                padding: '8px 15px!important',
                                            },
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <MuiBox
                                sx={{
                                    border: `1px solid ${themeColor?.navigation?.buttons?.backgroundColor}`,
                                    borderRadius: '5px',
                                }}
                                mt={3}
                            >
                                <MuiBox
                                    sx={{
                                        backgroundColor:
                                            themeColor?.navigation?.buttons
                                                ?.backgroundColor,
                                        padding: '30px',
                                        borderRadius: '0px 0px 5px 5px',
                                    }}
                                >
                                    <MuiTypography
                                        color="#FFFFFF"
                                        fontWeight={600}
                                        mb={1}
                                    >
                                        {item?.title ?? ''}
                                    </MuiTypography>
                                    <MuiTypography color="#FFFFFF">
                                        {handleDescription(item)}
                                    </MuiTypography>
                                </MuiBox>
                                <GridContainer
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <MuiBox
                                            sx={{
                                                padding: '20px!important',
                                                marginTop: '40px!important',
                                                marginBottom: '90px!important',
                                            }}
                                        >
                                            <MuiTypography
                                                sx={{
                                                    marginBottom:
                                                        '30px !important',
                                                    color: '#333333!important',
                                                    fontWeight: '600!important',
                                                    fontSize: '20px!important',
                                                }}
                                            >
                                                Responda a pesquisa e ajude a
                                                melhorar nossos serviços.
                                            </MuiTypography>
                                            <Button
                                                borderRadius="5px"
                                                icon=""
                                                outlined="outlined"
                                                text="Responder agora"
                                                typeVariation="CustomTextButton"
                                                onClick={() =>
                                                    history.push({
                                                        pathname: `/search/${item?.id}`,
                                                    })
                                                }
                                                sx={{
                                                    color: '#FFFFFF!important',
                                                    border: `1px solid ${themeColor?.navigation?.buttons?.backgroundColor}!important`,
                                                    textTransform:
                                                        'inherit!important',
                                                    padding:
                                                        '8px 15px!important',
                                                    width: isScreenSmall
                                                        ? '100%'
                                                        : '55%!important',
                                                    backgroundColor: `${themeColor?.navigation?.buttons?.backgroundColor} !important`,
                                                    ':hover': {
                                                        border: `1px solid ${themeColor?.navigation?.buttons?.backgroundColor}!important`,
                                                        textTransform:
                                                            'inherit!important',
                                                        color: '#FFFFFF!important',
                                                        backgroundColor: `${themeColor?.navigation?.buttons?.backgroundColor} !important`,
                                                    },
                                                }}
                                            />
                                        </MuiBox>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                        <ResearchIllustration size="250px" />
                                    </GridItem>
                                </GridContainer>

                                <MuiBox
                                    sx={{
                                        backgroundColor: '#E6E7E8!important',
                                        padding: '45px 0px!important',
                                        marginTop: '-75px!important',
                                        borderRadius:
                                            '0px 0px 5px 5px!important',
                                    }}
                                >
                                    <></>
                                </MuiBox>
                            </MuiBox>
                        </MuiBox>
                    </MuiDefaultModal>
                ))}
        </>
    )
}
