import React, { memo, useEffect, useState } from "react";
import compose from "utils/compose";
import { withTranslation } from "react-i18next";

// css
import styles from "./styles.module.scss";
import {GetThemeColorFromStorage} from '../../redux/api/themeColor'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'

const Section = memo((props) => {

	const [themeColor, setThemeColor] = useState({})

	useEffect(()=>{
		setThemeColor(GetThemeColorFromStorage()?.titles?.main?.textColor ?? germiniDefaultColors[0]);
	},[])

	return(
		<div className={styles.sectionTitlePolicy + " " + props.className}
			style={{
				'--bgcolor': themeColor,
				borderColor: `${themeColor}`,
			}}
		>{props.title}</div>
	)
});

export default compose(withTranslation())(Section);
