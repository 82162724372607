import React from 'react'
import Alert from 'react-bootstrap-sweetalert'
import { Box, Typography } from '@material-ui/core'
import './styles.css'
import Cookies from 'js-cookie'

export function AlertStatus({ hideAlert, sendEmail, accountData }) {
    const theme =
        JSON.parse(Cookies.get('themeColor')) ||
        JSON.parse(localStorage.getItem('themeColor'))

    const themeColor = theme?.navigation?.buttons?.backgroundColor

    return (
        <div className="custom-alert">
            <Alert
                className="custom-alert"
                warning
                style={{
                    display: 'block',
                    marginTop: '-150px',
                    marginRight: '150px',
                    left: '45%',
                }}
                confirmBtnText="Reenviar e-mail"
            >
                <Box>
                    <button
                        style={{
                            backgroundColor: `#f25756`,
                            color: '#fff',
                            fontSize: '15px',
                            padding: '5px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            marginTop: '15px',
                            position: 'absolute',
                            float: 'right',
                            top: '1px',
                            left: '80%',
                        }}
                        onClick={hideAlert}
                    >
                        Fechar
                    </button>
                </Box>
                <Box textAlign="center">
                    <Typography className="title">
                        Identificamos que o seu cadastro já foi iniciado, mas
                        seu e-mail ainda não foi validado.
                    </Typography>
                    <Typography className="title">
                        Reenvie o e-mail para
                        {` ${accountData?.email}`} e realize a validação
                    </Typography>

                    <Typography className="description">
                        Caso não tenha recebido, verifique sua pasta de lixo
                        eletrônico(spam) ou entre em contato com o suporte
                    </Typography>

                    <Typography
                        style={{
                            color: `${themeColor}`,
                            fontSize: '13px',
                            textAlign: 'center',
                        }}
                    >
                        Email: faleconosco@cvaledidade.com.br
                    </Typography>

                    <Typography
                        style={{
                            color: `${themeColor}`,
                            fontSize: '13px',
                        }}
                    >
                        Telefone: (44) 3333-3333
                    </Typography>

                    <button
                        style={{
                            backgroundColor: `${themeColor}`,
                            color: '#fff',
                            fontSize: '15px',
                            padding: '10px 25px',
                            borderRadius: '5px',
                            border: 'none',
                            marginTop: '15px',
                        }}
                        onClick={sendEmail}
                    >
                        Reenviar e-mail
                    </button>
                </Box>
            </Alert>
        </div>
    )
}
