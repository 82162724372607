import React from 'react'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const EmptyField = () => (
    <div
        style={{
            width: ' 60%',
            minWidth: '50px',
            height: '16px',
            background: '#ccc',
            marginTop: '5px',
            transform: 'translateY(-5px)',
        }}
    ></div>
)

export default ({ label, value, width }) => {
    return (
        <div
            style={{
                display: 'flex',
                gap: '0',
                width: `${width}%`,
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyItems: 'center',
            }}
        >
            <span>{label}</span>
            {value != null ? (
                <span
                    style={{
                        color:   GetThemeColorFromStorage()?.titles?.highlight?.textColor,
                        fontWeight: 'bold',
                        transform: 'translateY(-3px)',
                    }}
                >
                    {value}
                </span>
            ) : (
                <EmptyField />
            )}
        </div>
    )
}
