import _ from 'lodash'

export const giftValueFormat = (inputValue, onChange) => {
    try {
        if (_.isString(inputValue) && inputValue.length === 0) {
            return
        }

        const cleanedValue = inputValue.replace('R$', '').replace(/\./g, '')
        const numericValue = parseFloat(cleanedValue.replace(',', '.'))

        if (_.isNaN(numericValue)) {
            return
        }

        onChange(numericValue)
    } catch (error) {
        console.error('Erro em giftValueFormat:', error.message)
    }
}

export const giftValueValidate = (
    type,
    watchGiftValue,
    currentTotalCash,
    minimumGiftCard,
    maximumGiftCard,
) => {
    try {
        const isValid =
            watchGiftValue >= minimumGiftCard &&
            watchGiftValue <= maximumGiftCard

        if (type === 'color') {
            return isValid && watchGiftValue <= currentTotalCash
                ? 'greenButtonColor'
                : ''
        }

        if (type === 'disabled') {
            return !(isValid && watchGiftValue <= currentTotalCash)
        }
    } catch (error) {
        console.error('Erro em giftValueValidate:', error.message)
        return type === 'disabled' // Return disabled state if an error occurs
    }
}
