import axios from 'axios'

export const GetTransferTax = (_value, transferOrigin) => {
    return axios
        .get(
            `/Transfer/GetTransferTax?value=${_value}&transferOrigin=${transferOrigin}`,
        )
        .then((response) => response?.data)
}

export const RequestConsumerTransfer = (obj) => {
    return axios
        .post(`/Transfer/RequestConsumerTransfer`, obj)
        .then((response) => response?.data)
}

export const GetConsumerAccountKeyType = () => {
    return axios
        .get(`/Consumer/GetConsumerAccountKeyType`)
        .then((response) => response?.data)
}

export const GetBanks = (name) => {
    return axios
        .get(`/BankAccount/GetBanks?query=${name}`)
        .then((response) => response?.data)
}

export const UpdateConsumerAccountInfo = (_infoBody) => {
    const config = { headers: { 'Content-Type': 'application/json' } } //sobrescrevendo config pra ver se resolve problema
    return axios
        .post('/Consumer/UpdateConsumerAccountInfo', _infoBody, config)
        .then((response) => response?.data)
}

export const GetConsumerTransferAccountInfo = () =>
    axios
        .get(`/Transfer/GetConsumerTransferAccountInfo`)
        .then((response) => response?.data)

export const GetCurrentConsumer = () =>
    axios.get(`/Consumer/GetCurrentConsumer`).then((response) => response?.data)

export const ValidateDocument = (doc, isRegister) => {
    return axios
        .get(`/Consumer/VerifyDocument?cpfCnpj=${doc}&isRegister=${isRegister}`)
        .then((response) => response?.data)
}

export const GetConsumerByToken = (token) => {
    return axios.get(
        `/Consumer/GetConsumerByToken?token=${encodeURIComponent(token)}`,
    )
}

export const GetPreConsumerByToken = (token) => {
    return axios.get(
        `/Consumer/GetPreConsumerByToken?token=${encodeURIComponent(token)}`,
    )
}

export const verifyIndicationCode = (indicationToken) => {
    return axios
        .get(
            `/Consumer/VerifyIndicationCodeByToken?tokenIndication=${indicationToken}`,
        )
        .then((response) => response?.data)
}

export const verifyIndicationCodeInput = (concatToken) => {
    return axios
        .get(`/Consumer/VerifyIndicationCode?indicationCode=${concatToken}`)
        .then((response) => response?.data)
}

// POST
export const PostConsumerRegulation = (obj) =>
    axios
        .post(`/Consumer/SetConsumerRegulationRules`, obj)
        .then((response) => response?.data)

// UPDATE
export const UpdateUser = (id, obj) =>
    axios.put(`/Consumer/${id}`, obj).then((response) => response?.data)

// POST
export const PostUser = (obj) =>
    axios.post(`/Consumer/Register`, obj).then((response) => response?.data)

// GET
export const SendResetPasswordRequest = (login) =>
    axios
        .get(`/Consumer/SendResetPasswordRequest/${login}`)
        .then((response) => response?.data)

// POST
export const ResetPassword = (obj) =>
    axios.put(`/User/ResetPassword`, obj).then((response) => response?.data)

export const ConfirmEmailRegistration = (token) =>
    axios
        .put(`/Consumer/EmailTokenValidation?token=${token}`)
        .then((response) => response?.data)

export const SendSmsActivationCode = (cpfCnpj) =>
    axios
        .put(`/Consumer/SendSmsActivationCode?cpfCnpj=${cpfCnpj}`)
        .then((response) => response?.data)

export const ConfirmRegistration = (cpfCnpj, activationCode) =>
    axios
        .put(
            `/Consumer/SmsActivationCodeValidation?cpfCnpj=${cpfCnpj}&activationCode=${activationCode}`,
        )
        .then((response) => response?.data)

// Validar email para envio:
// Retorna: Dados do consumidor, usar o id para enviar o vale presente
export const ValidateConsumerByEmail = (email) =>
    axios
        .get(`/Consumer/ValidateConsumerByEmail?email=${email}`)
        .then((response) => response?.data)

// Validar codigo de indicação para envio:
// Retorna: Dados do consumidor, usar o id para enviar o vale presente
export const ValidateConsumerByCode = (indicationcode) =>
    axios
        .get(
            `/Consumer/ValidateConsumerByCode?indicationcode=${indicationcode}`,
        )
        .then((response) => response?.data)

// Validar cpf para envio:
// Retorna: Dados do consumidor, usar o id para enviar o vale presente
export const ValidateConsumerByCpf = (cpf) =>
    axios
        .get(`/Consumer/ValidateConsumerByCpf?cpf=${cpf}`)
        .then((response) => response?.data)

// Validar senha do consumidor:
export const ValidateConsumer = (password) => {
    const obj = { password: password }
    return axios
        .post(`/Consumer/ValidateConsumer`, obj)
        .then((response) => response?.data)
}

export const SendGiftCard = (obj) => {
    return axios
        .post(`/ConsumerDigitalWallet/SendGiftCard`, obj)
        .then((response) => response?.data)
}

export const GetSentGiftCard = (start, end, destiny, page, pageSize) =>
    axios
        .get(
            `/ConsumerDigitalWallet/GetSentGiftCard?startDate=${start}&endDate=${end}&destinyConsumer=${destiny}&page=${page}&pageSize=${pageSize}`,
        )
        .then((response) => response?.data)

export const GetReceivedGiftCard = (
    start,
    end,
    originConsumer,
    entryType,
    page,
    pageSize,
) =>
    axios
        .get(
            `/ConsumerDigitalWallet/GetReceivedGiftCard?startDate=${start}&endDate=${end}&originConsumer=${originConsumer}&entryType=${entryType}&page=${page}&pageSize=${pageSize}`,
        )
        .then((response) => response?.data)
// [TELA001]
export const GetParticipantConsumers = () =>
    axios
        .get(`/ConsumerDigitalWallet/GetParticipantConsumers`)
        .then((response) => response?.data)

// [TELA002]
export const GetBalanceResume = () =>
    axios
        .get(`/ConsumerDigitalWallet/GetBalanceResume`)
        .then((response) => response?.data)

export const GetBalance = () =>
    axios
        .get(`/ConsumerDigitalWallet/GetBalance`)
        .then((response) => response?.data)

export const GetGeneralBasicData = () =>
    axios
        .get(`/ProgramRules/General/BasicData`)
        .then((response) => response?.data)

export const GetExpirationPointsConsumer = () =>
    axios
        .get(`/ConsumerWallet/GetExpirationPointsConsumerApp`)
        .then((response) => response)

export const GetExpirationPoints = () =>
    axios
        .get(`/ConsumerWallet/GetExpirationPoints`)
        .then((response) => response)

export const ValidateDelete = async (id) => {
    return await axios.get(`/Consumer/ValidateDelete/${id}`)
}

export const DeleteConsumer = async (id, data) => {
    return await axios.delete(`/Consumer/${id}`, {
        data: data,
    })
}

export const TurnOffMagentoIntegration = async (cpf) => {
    return await axios.get(`/Consumer/TurnOffMagentoIntegrationMessage/${cpf}`)
}
