import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import Alert from 'react-bootstrap-sweetalert'
import * as _ from 'lodash'
import './moverBotoesModal.css'
// UTILS
import compose from 'utils/compose'
import DocumentTitle from 'react-document-title'

import withStyles from '@material-ui/core/styles/withStyles'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

//COMPONENTS
import Loader from 'components/Loader/Loader.jsx'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import { useHistory } from 'react-router-dom'

import * as API from '../../../redux/api/user'

import {
    GetCountryData,
    GetStateData,
    GetCityData,
    SearchZipcodeData,
    GetCurrentConsumer,
    UpdateConsumer,
    SetFileData,
    ImageUpload,
    SweetAlert,
    HideAlert,
    ShowLoader,
    HideLoader,
} from '../../../redux/actions'

// #region =========== conta bancaria e pix ===================
import {
    GetConsumerAccountKeyType,
    GetBanks,
    UpdateConsumerAccountInfo,
} from '../../../redux/actions/user'
// #endregion =================================================

import { Formik } from 'formik'

//==================== FORMULARIOS =======================================
import ProfileForm from './Component/ProfileForm.jsx'
import BankAndPixForm from './Component/BankAndPixForm.jsx'
// ========= ESQUEMAS DE VALIDACAO DOS FORMULARIOS ============
import {
    validationSchema,
    validationSchema_Account,
} from './ValidationSchemas.jsx'
//========================================================================

// MOMENT JS
import * as moment from 'moment'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import GeneralContext from '../../../Context'
import { Box } from '@mui/material'
import ModalDelete from './Component/ModalDelete'
import Section from '../../../components/Section/Section'
import { getProgramTitle } from '../../../utils/utils.js'

let allBanksFromAPI = []
let allPixKeysType = []

let selectedPixKeyType = '' //cpf, numero...
function setSelectedPixKeyType(_newSelectedPixKeyType) {
    selectedPixKeyType = _newSelectedPixKeyType
}
let selectedPixKey = '' //053.000.000-3, 489 91833330...
function setSelectedPixKey(_newSelectedPixKey) {
    selectedPixKey = _newSelectedPixKey
}
let selectedBankId = '' //key
function setSelectedBankID(_newSelectedBankId) {
    selectedBankId = _newSelectedBankId
}
let selectedBankBranch = '' //agencia
function setSelectedBankBranch(_newSelectedBankBranch) {
    selectedBankBranch = _newSelectedBankBranch
}
let selectedBankNumber = '' //conta com digito
function setSelectedBankNumber(_newSelectedBankNumber) {
    selectedBankNumber = _newSelectedBankNumber
}
let numberDigit = ''
function setNumberDigit(_newNumberDigit) {
    numberDigit = _newNumberDigit
}

const updatedConsumerAccountInfoObject = () => {
    return JSON.stringify({
        keyType: selectedPixKeyType ? parseInt(selectedPixKeyType) : 0,
        key: selectedPixKey ? `${selectedPixKey}` : null,
        bankId: selectedBankId === '0' ? null : `${selectedBankId}`,
        branch: selectedBankBranch ? `${selectedBankBranch}` : null,
        number: selectedBankNumber ? `${selectedBankNumber}` : null,
        // numberDigit: numberDigit ? `${numberDigit}` : null,
    })
}

class Profile extends React.Component {
    static contextType = GeneralContext
    constructor(props) {
        super(props)

        this.formik = React.createRef()

        this.formik_Account = React.createRef()

        this.state = {
            openDelete: false,
            alert: false,
            pictureUrl: '',
            name: '',
            cpf: '',
            gender: '',
            dateOfBirth: '',
            email: '',
            stateRegistration: null,
            stateRegistrationWaiver: false,
            isMEI: false,
            phoneNumber: '',
            phoneNumber2: '',
            cityId: '',
            stateId: '',
            UF: '',
            zipCode: '',
            district: '',
            number: '',
            aditionalInfo: '',
            location: '',
            countryId: '',
            repName: '',
            repCpf: '',
            repPhoneNumber: '',
            repEmail: '',
            indicationCode: '',
            selectListCity: [],
            selectListState: [],
            selectListCountry: [],
            disabledForm: true,
            open: false,
            // ==== pix e banco ===========
            alert_Accont: false,
            disabledForm_Account: true,
            open_Account: false,
            accountNumber: '',
            agency: '',
            pixKeyType: '',
            pixKey: '',
            bankName: '',
            bankAccount: '',

            usetConfirmedDifferentDataOnSave_Account: false,
            // ============================
        }
        const i18 = localStorage.getItem('i18nextLng')
        moment.locale(i18 != null ? i18 : 'pt-BR')
    }

    checkToGoBackToTransferRequestPage() {
        if (
            localStorage.getItem('myVar') !== null &&
            localStorage.getItem('myVar') !== false &&
            localStorage.getItem('myVar') !== undefined
        ) {
            if (localStorage.getItem('myVar') === true) {
                this.props.history.goBack()
                localStorage.setItem('myVar', false)
            }
        }
    }

    getAllBanksOptionsFromAPI(_onFailed) {
        API.GetBanks('')
            .then((response) => {
                const stringedResponse = JSON.stringify(response)
                const data = [
                    { key: '0', value: this.props.t('NOT_INFORM') },
                    ...JSON.parse(stringedResponse),
                ]
                allBanksFromAPI = data
            })
            .catch((error) => {
                _onFailed(error)
            })
    }

    getConsumerAccountKeyType(_onError) {
        API.GetConsumerAccountKeyType()
            .then((res) => {
                allPixKeysType = [
                    { key: '0', value: this.props.t('NOT_INFORM') },
                    ...JSON.parse(JSON.stringify(res)),
                ]
                //allPixKeysType = JSON.parse(JSON.stringify(res))
            })
            .catch((error) => {
                _onError(error)
            })
    }

    UpdateInitialValuesFromBankAndPixForm() {
        API.GetCurrentConsumer('')
            .then((response) => {
                const updatedValuesAccountObj = {
                    pixKey: response?.consumerAccount?.key?.replace(
                        /[^\d]+/g,
                        '',
                    ),
                    pixKeyType: response.consumerAccount.keyType.value,
                    bank: response.consumerAccount.bank.value,
                    agency: response.consumerAccount.branch,
                    account: response.consumerAccount.number,
                    numberDigit: response.consumerAccount.numberDigit,
                }
                this.values_Account = updatedValuesAccountObj
                this.setState({
                    pixKey: response.consumerAccount.key,
                    pixKeyType: response.consumerAccount.keyType.value,
                    bank: response.consumerAccount.bank.value,
                    agency: response.consumerAccount.branch,
                    account: response.consumerAccount.number,
                    numberDigit: response.consumerAccount.numberDigit,
                })
            })
            .catch((err) => {
                console.log(
                    `Erro ao resgatar dados financeiros deste usuário. ${err}`,
                )
            })
    }

    componentDidMount() {
        this.setState({ alert: <Loader /> })
        // this.props.ShowLoader(<Loader />)
        if (_.isEmpty(this.props.userData)) {
            this.props.GetCurrentConsumer()
        } else {
            this.populateData(this.props.userData)
        }
        this.props.GetCountryData()

        //atualizando as opcoes de select do pix
        this.getAllBanksOptionsFromAPI((error) => {
            alert('Não foi possível recuperar a lista de bancos. ' + error)
        })

        //atualizando os valores iniciais dos inputs do banco e pix
        this.UpdateInitialValuesFromBankAndPixForm()

        //atualizadno o select de pix
        this.getConsumerAccountKeyType((error) => {
            alert(
                'Não foi possível recuperar as opções de chave PIX disponíveis. ' +
                    error,
            )
        })
    }

    handleAlertSuccess = () => {
        this.setState({ open: true })
    }
    handleAlertSuccess_Account = () => {
        this.setState({ open_Account: true })
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') return
        this.setState({ open: false })
    }

    componentDidUpdate = (prevProps, prevState) => {
        ///////////// DADOS PESSOAIS =============
        if (prevProps.userData !== this.props.userData) {
            this.populateData(this.props.userData)
        }

        if (prevProps.cityDataFailed !== this.props.cityDataFailed) {
        }

        if (prevProps.countryData !== this.props.countryData) {
            const countryId = this.props.countryData.find(
                (x) => x?.name === 'Brazil',
            )?.id

            this.props.GetStateData(countryId)
            this.setState({ selectListCountry: this.props.countryData })
        }

        if (prevProps.userUpdate !== this.props.userUpdate) {
            // if (true) {
            this.setState({ ...this.props.userUpdate.data })
            if (this.props.userUpdate.success) {
                if (
                    this.props.userUpdate.representative &&
                    this.props.userUpdate.representative !== null
                ) {
                    let _rep = this.props.userUpdate.representative
                    this.setState({
                        repName: _rep.name,
                        repCpf: _rep.cpf,
                        repEmail: _rep.email,
                        repPhoneNumber: _rep.phoneNumber,
                    })
                }

                if (
                    this.props.userUpdate.address &&
                    this.props.userUpdate.address !== null
                ) {
                    this.setState({
                        location: this.props.userUpdate.address.location,
                        stateId:
                            this.props.userUpdate.address.state &&
                            this.props.userUpdate.address.state.id,
                        UF:
                            this.props.userUpdate.address.state &&
                            this.props.userUpdate.address.state.id,
                        countryId:
                            this.props.userUpdate.address.country &&
                            this.props.userUpdate.address.country.id,
                        cityId:
                            this.props.userUpdate.address.city &&
                            this.props.userUpdate.address.city.id,
                        zipCode:
                            this.props.userUpdate.address.zipCode &&
                            this.props.userUpdate.address.zipCode,
                        district:
                            this.props.userUpdate.address.district &&
                            this.props.userUpdate.address.district,
                        number:
                            this.props.userUpdate.address.number &&
                            this.props.userUpdate.address.number,
                        aditionalInfo:
                            this.props.userUpdate.address.aditionalInfo &&
                            this.props.userUpdate.address.aditionalInfo,
                    })
                }
                this.props.GetCurrentConsumer()

                this.props.SweetAlert(this.handleAlertSuccess())
            } else {
                this.props.SweetAlert(
                    <Alert
                        warning
                        style={{ display: 'block', marginTop: '-100px' }}
                        title={null}
                        onConfirm={() => {
                            this.props.HideAlert()
                            this.props.HideLoader()
                        }}
                        confirmBtnCssClass={
                            this.props.classes.button +
                            ' ' +
                            this.props.classes.greenButtonColor
                        }
                        confirmBtnText={'OK'}
                    >
                        {this.props.t(this.props.userUpdate.errors[0].message)}
                    </Alert>,
                )
            }
            this.setState({ alert: null })
        }

        if (prevProps.stateData !== this.props.stateData) {
            this.setState({ selectListState: this.props.stateData })
        }

        if (prevProps.cityData !== this.props.cityData) {
            this.props.HideLoader()
            this.setState({ selectListCity: this.props.cityData })
        }

        if (prevProps.countryData !== this.props.countryData) {
            this.setState({ selectListCountry: this.props.countryData })
        }

        if (prevState.stateId !== this.state.stateId) {
            this.props.GetCityData(this.state.stateId)
        }
    }

    populateData = (_userData) => {
        if (!_userData || _.isEmpty(_userData)) {
            this.props.history.push(`/auth/login`)
        }

        this.setState({ ..._userData }, () => {
            if (!_userData.phoneNumber) {
                this.setState({ phoneNumber: '' })
            }

            if (!_userData.phoneNumber2) {
                this.setState({ phoneNumber2: '' })
            }
        })

        if (_userData.representative && _userData.representative !== null) {
            let _rep = _userData.representative
            this.setState({
                repName: _rep.name,
                repCpf: _rep.cpf,
                repEmail: _rep.email,
                repPhoneNumber: _rep.phoneNumber,
            })
        }

        if (_userData.address && _userData.address !== null) {
            let _address = _userData.address
            this.setState({
                location: _address.location,
                stateId: _address.state && _address.state.id,
                UF: _address.state && _address.state.id,
                countryId: _address.country && _address.country.id,
                cityId: _address.city && _address.city.id,
                zipCode: _address.zipCode && _address.zipCode,
                district: _address.district && _address.district,
                number: _address.number && _address.number,
                aditionalInfo: _address.aditionalInfo && _address.aditionalInfo,
            })
        }
        if (!_.isEmpty(this.state.selectListCity)) {
            this.props.HideLoader()
        }
        this.setState({ alert: null })
    }

    handleStateChange = (state, newValue) => {
        if (state !== 'countryId') {
            this.props.GetCityData(newValue)
        }
    }

    handleSave = (values) => {
        let address = {
            cityId: values.cityId,
            stateId: values.stateId,
            location: values.location,
            countryId: values.countryId,
            zipCode: values.zipCode,
            district: values.district,
            number: values.number,
            aditionalInfo: values.aditionalInfo,
        }
        values['address'] = address
        if (values?.cpf?.replace(/[^\d]+/g, '')?.length > 11) {
            values.representative = {
                name: values.repName,
                cpf: values.repCpf,
                email: values.repEmail,
                phoneNumber: values.repPhoneNumber,
            }
        }

        this.setState({ alert: <Loader /> })
        let img = this.props.imageFile
        if (this.props.imageFile instanceof File) {
            this.props.ImageUpload(img).then((rest) => {
                if (rest) {
                    values.pictureUrl = rest.data
                }
                this.props
                    .UpdateConsumer(this.props.userData.id, values)
                    .then(() => this.setState({ disabledForm: true }))
                this.props.SetFileData(null)
            })
        } else {
            this.props
                .UpdateConsumer(this.props.userData.id, values)
                .then(() => this.setState({ disabledForm: true }))
        }
    }

    warning_disable = () => {
        this.setState({ alert: null })
    }
    warning_enable = (
        _content,
        _onConfirm = () => {
            console.log('confirm')
        },
        _confirmBtnText = 'OK',
        _showCancelOption = false,
        _onCancel = () => {
            console.log('cancel')
        },
        _cancelBtnText = 'NO',
    ) => {
        this.setState({
            alert: (
                <Alert
                    warning
                    style={{
                        display: 'block',
                        marginTop: '-100px',
                    }}
                    title={null}
                    onConfirm={() => {
                        _onConfirm()
                        this.warning_disable()
                    }}
                    confirmBtnCssClass={
                        this.props.classes.button +
                        ' ' +
                        this.props.classes.greenButtonColor
                    }
                    confirmBtnText={_confirmBtnText}
                    showCancel={_showCancelOption}
                    onCancel={() => {
                        _onCancel()
                        this.warning_disable()
                    }}
                    cancelBtnCssClass={
                        this.props.classes.button +
                        ' ' +
                        this.props.classes.greenBorderButtonColor
                    }
                    cancelBtnText={_cancelBtnText}
                >
                    {_content}
                </Alert>
            ),
        })
    }

    executeUpdateInAPI_Account = () => {
        this.setState({ alert: <Loader /> })
        API.UpdateConsumerAccountInfo(updatedConsumerAccountInfoObject())
            .then((res) => {
                if (res.success == true) {
                    this.setState({ disabledForm_Account: true })
                    this.setState({ alert: null })
                    this.props.SweetAlert(this.handleAlertSuccess())
                    this.checkToGoBackToTransferRequestPage()
                }
            })
            .catch((err) => {
                this.setState({ alert: null })
                const error = err?.response?.data
                if (error?.errors) {
                    this.setState({ alert: null })
                    let erM = ''
                    error.errors.forEach((_error) => {
                        switch (_error.message) {
                            case 'Account - AT_LEAST_ONE_ACCOUNT_MUST_BE_CREATED':
                                erM =
                                    'Pelo menos uma opção de pagamento precisa ser configurada.'
                                break

                            case 'Key - REQUIRED_FIELD':
                                erM = 'Nenhuma chave PIX foi selecionada.'
                                break

                            case 'Cellphone - INVALID_VALUE':
                                erM = 'Número de celular inválido.'
                                break

                            case 'AleatoryKey - INVALID_VALUE':
                                erM = 'Chave aleatória inválida.'
                                break

                            case 'Email - INVALID_VALUE':
                                erM = 'E-mail inválido.'
                                break

                            case 'CPF - INVALID_VALUE':
                                erM = 'CPF inválido.'
                                break

                            case 'CPF - CPF_DIFFERENT_FROM_CONSUMER':
                                erM =
                                    'CPF diferente do cadastrado em sua conta.'
                                break

                            case 'Number - REQUIRED_FIELD':
                                erM = `Nenhum número de conta foi selecionado.`
                                break

                            case 'Branch - REQUIRED_FIELD':
                                erM = `Nenhuma agência foi selecionada.`
                                break

                            case 'Number - VALUE_TOO_LONG':
                                erM = `Número da conta excede o máximo de caracteres.`
                                break

                            case 'Branch - VALUE_TOO_LONG':
                                erM = `Número da agência excede o máximo de caracteres.`
                                break

                            case 'NumberDigit - REQUIRED_FIELD':
                                erM = `O dígito da conta não foi informado.`
                                break

                            default:
                                erM =
                                    'Houve um erro ao salvar suas informações de transferência. Por favor, tente novamente.\n\n' +
                                    _error.message
                                break
                        }
                    })
                    this.warning_enable(erM)
                }
            })
    }

    handleSave_Account = (values_Account) => {
        let bankAndPixAccountData = {
            pixKey: values_Account.pixKey,
            pixType: values_Account.pixType,
            bankType: values_Account.bankType,
            agency: values_Account.agency,
            account: values_Account.account,
            numberDigit: values_Account.numberDigit,
        }
        values_Account['bankAndPixAccountData'] = bankAndPixAccountData

        const paramsOption = [
            this.executeUpdateInAPI_Account,
            'Sim',
            true,
            this.warning_disable,
            'Não',
        ]
        const paramsWarning = [this.warning_disable, 'OK', false]

        if (
            localStorage.getItem('diffCell') === 'true' &&
            this.state.phoneNumber2 !== selectedPixKey
        ) {
            this.warning_enable(
                `Este celular é diferente ao celular informado no seu cadastro. Deseja continuar?`,
                ...paramsOption,
            )
            return
        }
        if (
            localStorage.getItem('diffEmail') === 'true' &&
            this.state.email !== selectedPixKey
        ) {
            this.warning_enable(
                `Este e-mail é diferente do e-mail informado no seu cadastro. Deseja continuar?`,
                ...paramsOption,
            )
            return
        }
        if (localStorage.getItem('diffCpf') === 'true') {
            this.warning_enable(
                `O CPF cadastrado precisa ser o mesmo da sua conta.`,
                ...paramsWarning,
            )
            return
        }
        this.executeUpdateInAPI_Account()
    }

    handleEdit() {
        this.setState({ disabledForm: false })
    }

    handleEdit_Account() {
        this.setState({ disabledForm_Account: false })
    }

    handleCancel() {
        // console.log('Clear up')
        if (this.formik.current) {
            this.formik.current.resetForm()
            this.props.GetCityData(this.state.stateId)
            this.setState({ disabledForm: true })
            this.props.SetFileData(null)
        }
    }

    handleCancel_Account() {
        // console.log('Clear down')
        const formikAccount = this.formik_Account.current
        if (!formikAccount) return //se nao tem form, retorna vazio
        formikAccount.resetForm() //mesmo sem parametros -> TA DANDO ERRO AQUI, NAO TA RESETANDO
        this.setState({ disabledForm_Account: true }) //desabilita o form
    }

    render() {
        const { t, generalRulesData } = this.props
        const {
            location,
            cityId,
            countryId,
            stateId,
            UF,
            zipCode,
            district,
            number,
            aditionalInfo,
            name,
            cpf,
            stateRegistration,
            stateRegistrationWaiver,
            dateOfBirth,
            email,
            phoneNumber,
            repName,
            repCpf,
            repPhoneNumber,
            repEmail,
            phoneNumber2,
            gender,
            pictureUrl,
            selectListCity,
            selectListState,
            selectListCountry,
            disabledForm,
            indicationCode,

            //dados bancarios e pix =====
            disabledForm_Account,
            //-- values
            pixKey,
            pixType,
            bankType,
            agency,
            account,
            numberDigit,
            isMEI,
            //==========================
        } = this.state

        const values = {
            location,
            cityId,
            countryId,
            stateId,
            UF,
            zipCode,
            district,
            number,
            aditionalInfo,
            name,
            cpf,
            stateRegistration,
            stateRegistrationWaiver,
            dateOfBirth,
            email,
            phoneNumber,
            repName,
            repCpf,
            repPhoneNumber,
            repEmail,
            gender,
            phoneNumber2,
            pictureUrl,
            indicationCode,
            isMEI,
        }
        let values_Account = {
            pixKey,
            pixType,
            bankType,
            agency,
            account,
            numberDigit,
        }
        return (
            <Fragment>
                {this.state.alert}
                <Snackbar
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    style={{ marginTop: '125px' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.handleClose}
                        severity="success"
                    >
                        {this.props.t('FIELD_ALERT_SUCCESS')}
                    </MuiAlert>
                </Snackbar>

                <DocumentTitle
                    title={getProgramTitle(this.props.t('TITLE_PROFILE'))}
                />

                <Formik
                    //dados pessoais
                    ref={this.formik}
                    render={(props) => (
                        <Fragment>
                            <ProfileForm
                                {...props}
                                indicationCode={values.indicationCode}
                                selectListCity={selectListCity}
                                selectListState={selectListState}
                                selectListCountry={selectListCountry}
                                handleStateChange={this.handleStateChange.bind(
                                    this,
                                )}
                                handleCancel={this.handleCancel.bind(this)}
                                SearchZipcodeData={this.props.SearchZipcodeData}
                                handleEdit={this.handleEdit.bind(this)}
                                disabledForm={disabledForm}
                            />
                        </Fragment>
                    )}
                    validationSchema={validationSchema}
                    initialValues={values}
                    onSubmit={this.handleSave.bind(this)}
                    enableReinitialize={true}
                />

                {/* FORMULARIO DE DADOS BANCARIOS E PIX */}

                <Formik
                    //banco e pix
                    ref={this.formik_Account}
                    render={(props) => (
                        <BankAndPixForm
                            style={{ marginTop: '30px' }}
                            {...props}
                            allBanksFromAPI={allBanksFromAPI}
                            allPixKeysType={allPixKeysType}
                            handleCancel_Account={this.handleCancel_Account.bind(
                                this,
                            )}
                            handleSave_Account={this.handleSave_Account.bind(
                                this,
                            )}
                            handleEdit_Account={this.handleEdit_Account.bind(
                                this,
                            )}
                            disabledForm_Account={disabledForm_Account}
                            setSelectedPixKeyType={setSelectedPixKeyType}
                            setSelectedPixKey={setSelectedPixKey}
                            setSelectedBankNumber={setSelectedBankNumber}
                            setSelectedBankID={setSelectedBankID}
                            setSelectedBankBranch={setSelectedBankBranch}
                            setNumberDigit={setNumberDigit}
                        />
                    )}
                    validationSchema={validationSchema_Account}
                    initialValues={values_Account}
                    onSubmit={this.handleSave_Account.bind(this)}
                    enableReinitialize={true}
                />
                {this.context.GeneralRules?.excludeParticipants && (
                    <Box component="div">
                        <ModalDelete
                            loader={() => this.setState({ alert: <Loader /> })}
                            closeLoader={() => this.setState({ alert: null })}
                            open={this.state.openDelete}
                            handleClose={() =>
                                this.setState({ openDelete: false })
                            }
                            id={this.props.userData?.id}
                        />
                        <Box
                            sx={{
                                mt: 1,
                                position: 'relative',
                                left: '-14px',
                            }}
                        >
                            <Section />
                        </Box>
                        <Box
                            component="div"
                            onClick={() => this.setState({ openDelete: true })}
                            sx={{
                                textAlign: 'left',
                                font: 'normal normal normal 14px/21px Roboto',
                                letterSpacing: '0px',
                                color: '#DE2E2E',
                                opacity: 1,
                                cursor: 'pointer',
                                fontWeight: 500,
                            }}
                        >
                            Excluir conta do Programa de Fidelidade
                        </Box>
                    </Box>
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        generalRulesData: state.generalRules.generalRulesData,
        userData: state.users.userData,
        userUpdate: state.users.userUpdate,
        stateData: state.location.stateData,
        cityData: state.location.cityData,
        userDataFail: state.users.userDataFail,
        countryData: state.location.countryData,
        stateDataFailed: state.location.stateDataFailed,
        cityDataFailed: state.location.cityDataFailed,
        imageUrl: state.images.imageUrl,
        imageFile: state.images.imageFile,
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            GetCountryData,
            GetStateData,
            GetCityData,
            SearchZipcodeData,
            GetCurrentConsumer,
            UpdateConsumer,
            SetFileData,
            ImageUpload,
            SweetAlert,
            HideAlert,
            ShowLoader,
            HideLoader,
            // ====== conta bancaria e pix ===========================================
            GetConsumerAccountKeyType,
            GetBanks,
            UpdateConsumerAccountInfo,
            // =======================================================================
        },
        dispatch,
    )

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(extendedFormsStyle),
    withTranslation(),
)(Profile)
