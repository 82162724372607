import React from 'react'
import {
    MuiBox,
    MuiDefaultModal,
    Button,
    GridItem,
    MuiTypography,
    GridContainer,
    AlertModalIcon,
    s,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { styleModalConfirmedPassword } from '../../utils'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

export function ConfirmPasswordModal({
    isSmallScreen,
    isModalConfirmPasswordOpen,
    setIsModalConfirmPasswordOpen,
    handleSubmit,
    currentConsumer,
    onSubmit,
    selectedOption,
    setSelectedOption,
}) {
    return (
        <MuiDefaultModal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={isModalConfirmPasswordOpen}
            onClose={() => setIsModalConfirmPasswordOpen(false)}
        >
            <MuiBox
                sx={{
                    ...styleModalConfirmedPassword,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={12}>
                            <AlertModalIcon size={70} color="#F8BB86" />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <MuiTypography
                                color={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                fontSize="14px"
                                fontWeight={600}
                            >
                                SENHA CONFIRMADA
                            </MuiTypography>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} mt={1}>
                            <MuiTypography color="#333333" fontSize="14px">
                                Será enviado um código para confirmação.
                                Selecione onde gostaria de receber:
                            </MuiTypography>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} mt={2}>
                            <FormControl>
                                <RadioGroup
                                    name="contactOption"
                                    value={selectedOption}
                                    onChange={(e) =>
                                        setSelectedOption(e.target.value)
                                    }
                                >
                                    <MuiBox
                                        sx={{
                                            border: '1px solid #C2C2C2',
                                            borderRadius: '5px',
                                            paddingLeft: '10px',
                                            textAlign: 'left',
                                            background:
                                                selectedOption === 'Email'
                                                    ? GetThemeColorFromStorage()
                                                          ?.navigation?.buttons
                                                          ?.backgroundColor
                                                    : 'transparent',
                                        }}
                                    >
                                        <FormControlLabel
                                            value="Email"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: '#C2C2C2',
                                                        fontSize: '25px',
                                                        padding: '5px',
                                                        '&.Mui-checked': {
                                                            color: '#61AC5C',
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 25,
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                currentConsumer?.email ?? '-'
                                            }
                                            sx={{
                                                color:
                                                    selectedOption === 'Email'
                                                        ? '#FFFFFF'
                                                        : '#333333',
                                                fontSize: '14px',
                                            }}
                                        />
                                    </MuiBox>
                                    <MuiBox
                                        sx={{
                                            border: '1px solid #C2C2C2',
                                            borderRadius: '5px',
                                            paddingLeft: '10px',
                                            textAlign: 'left',
                                            background:
                                                selectedOption === 'SMS'
                                                    ? GetThemeColorFromStorage()
                                                          ?.navigation?.buttons
                                                          ?.backgroundColor
                                                    : 'transparent',
                                        }}
                                        mt={2}
                                        mb={2}
                                    >
                                        <FormControlLabel
                                            value="SMS"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: '#C2C2C2',
                                                        fontSize: '25px',
                                                        padding: '5px',
                                                        '&.Mui-checked': {
                                                            color: '#61AC5C',
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 25,
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                currentConsumer?.phoneNumber2 ??
                                                '-'
                                            }
                                            sx={{
                                                color:
                                                    selectedOption === 'SMS'
                                                        ? '#FFFFFF'
                                                        : '#333333',
                                                fontSize: '14px',
                                            }}
                                        />
                                    </MuiBox>
                                </RadioGroup>
                            </FormControl>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{
                                paddingLeft: isSmallScreen
                                    ? 0
                                    : '40px!important',
                            }}
                        >
                            <Button
                                defaultColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                icon=""
                                outlined="outlined"
                                text="Enviar"
                                textColor="#FFFFFF"
                                typeVariation="CustomTextButton"
                                borderRadius="5px"
                                type="submit"
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{
                                paddingRight: isSmallScreen
                                    ? 0
                                    : '40px!important',
                            }}
                        >
                            <Button
                                borderColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                defaultColor="transparent"
                                icon=""
                                outlined="outlined"
                                text="Cancelar"
                                textColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                typeVariation="CustomTextButton"
                                borderRadius="5px"
                                onClick={() =>
                                    setIsModalConfirmPasswordOpen(false)
                                }
                            />
                        </GridItem>
                    </GridContainer>
                </form>
            </MuiBox>
        </MuiDefaultModal>
    )
}
