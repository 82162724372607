import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Fade } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import { ModalContainer, PaperContainer } from './styles'

import { ButtonClose } from 'components/NewButton/styles'

function ModalInform({
    children,
    className,
    closeByClick,
    handleForgetPWD,
    handleLogin,
    handleClose,
    maxWidth,
    minWidth,
    open,
    showButtons,
    padding,
    shadow,
    t,
    text,
}) {
    return (
        <ModalContainer
            open={open}
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            maxwidth={maxWidth}
            minwidth={minWidth}
            disableEnforceFocus
        >
            <Fade in={open} unmountOnExit>
                <PaperContainer padding={padding} maxwidth={maxWidth}>
                    <GridContainer
                        xs={12}
                        sm={12}
                        style={{ margin: '10px 20px 30px 2px' }}
                    >
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                cursor: 'pointer',
                            }}
                        >
                            <ButtonClose onClick={handleClose}>
                                <i
                                    className="icon-ico_cancelar"
                                    style={{
                                        fontSize: '20px',
                                        marginRight: '10px',
                                    }}
                                />
                                {t('BUTTON_CLOSE')}
                            </ButtonClose>
                        </GridItem>

                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <i
                                className="icon-alerta_modal"
                                style={{
                                    color: germiniDefaultColors[11],
                                    fontSize: '80px',
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
                        <div
                            style={{
                                hidden: true,
                                fontSize: '13px',
                                fontWeight: 'bold',
                            }}
                        >
                            {text}
                        </div>
                    </GridItem>
                    {showButtons && (
                        <GridContainer
                            style={{
                                padding: '40px 0 0 20px',
                                marginBottom: '30px',
                            }}
                        >
                            <GridItem
                                xs={12}
                                sm={12}
                                style={{ textAlign: 'center' }}
                            >
                                <Button
                                    size="sm"
                                    variant="contained"
                                    color={'greenButtonColor'}
                                    style={{
                                        marginRight: '5px',
                                        padding: '8px 35px 8px 35px',
                                        borderRadius: '5px',
                                        fontSize: '16px',
                                    }}
                                    onClick={handleLogin}
                                >
                                    {t('To do Login')}
                                </Button>{' '}
                                <Button
                                    size="sm"
                                    variant="contained"
                                    color={'greenButtonColor'}
                                    style={{
                                        marginLeft: '5px',
                                        padding: '8px 35px 8px 35px',
                                        borderRadius: '5px',
                                        fontSize: '16px',
                                    }}
                                    onClick={handleForgetPWD}
                                >
                                    {t('Forgot your password?')}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    )}
                </PaperContainer>
            </Fade>
        </ModalContainer>
    )
}

ModalInform.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    closeByClick: PropTypes.func,
    showButtons: PropTypes.bool,
    handleClose: PropTypes.func,
    handleForgetPWD: PropTypes.func,
    handleLogin: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
    text: PropTypes.string,
}

ModalInform.defaultProps = {
    children: null,
    className: '',
    closeByClick: null,
    handleClose: null,
    handleForgetPWD: null,
    handleLogin: null,
    maxWidth: '',
    minWidth: '',
    open: false,
    showButtons: true,
    padding: '',
    shadow: '',
    text: '',
}

export default withTranslation()(ModalInform)
