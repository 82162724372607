import React from 'react'
import {
    Button,
    GridContainer,
    GridItem,
    MuiBox,
    MuiDivider,
    MuiStack,
    MuiTypography,
    SucessIcon,
    formatBRCurrency,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { numberFormatText } from '../../../../utils/utils'

export function MessageSuccess({
    handleHomeClick,
    handleWalletClick,
    isSmallScreen,
    currentConsumer,
}) {
    return (
        <GridContainer
            mt={10}
            pl={isSmallScreen ? 2 : 15}
            pr={isSmallScreen ? 2 : 15}
            spacing={isSmallScreen ? 0 : 2}
        >
            <GridItem xs={12} sx={{ textAlign: 'center!important' }}>
                <SucessIcon size={60} color="#68B105" />
            </GridItem>
            <GridItem xs={12} sx={{ textAlign: 'center!important' }} mb={3}>
                <MuiTypography color="#333333" fontWeight={600}>
                    Sucesso!!
                </MuiTypography>
            </GridItem>
            <GridItem xs={12} sx={{ textAlign: 'center!important' }} mb={3}>
                <MuiTypography color="#333333" fontSize="15px">
                    Seus pontos foram transferidos para a carteira digital
                </MuiTypography>
            </GridItem>
            <GridItem xs={12}>
                <MuiBox
                    sx={{
                        border: '1px solid #E0E0E0',
                        borderRadius: '10px',
                        padding: '20px',
                    }}
                >
                    <MuiStack
                        direction={isSmallScreen ? 'column' : 'row'}
                        alignItems="center"
                        justifyContent="center"
                        spacing={4}
                    >
                        <MuiBox sx={{ textAlign: 'center!important' }}>
                            <MuiTypography
                                fontSize="15px"
                                fontWeight={600}
                                mb={0.5}
                            >
                                Saldo atualizado em{' '}
                                {localStorage?.programCurrencyName}
                            </MuiTypography>
                            <MuiTypography fontSize="15px" fontWeight={600}>
                                {currentConsumer &&
                                    numberFormatText(
                                        currentConsumer?.points,
                                        `${localStorage?.programCurrencySymbol} `,
                                        0,
                                    )}
                            </MuiTypography>
                        </MuiBox>
                        {!isSmallScreen && (
                            <MuiDivider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />
                        )}
                        <MuiBox sx={{ textAlign: 'center!important' }}>
                            <MuiTypography
                                fontSize="15px"
                                fontWeight={600}
                                mb={0.5}
                            >
                                Saldo atualizado na carteira digital
                            </MuiTypography>
                            <MuiTypography fontSize="15px" fontWeight={600}>
                                {formatBRCurrency(
                                    currentConsumer?.digitalWalletBalance,
                                )}
                            </MuiTypography>
                        </MuiBox>
                    </MuiStack>
                </MuiBox>
            </GridItem>
            <GridItem xs={12} sm={12} md={1.5} lg={1.5}>
                <></>
            </GridItem>
            <GridItem
                xs={12}
                sm={12}
                md={3}
                lg={3}
                mt={10}
                sx={{ textAlign: 'center!important' }}
            >
                <Button
                    defaultColor={
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }
                    icon=""
                    outlined="outlined"
                    text="Ir para a home"
                    textColor="#FFFFFF"
                    typeVariation="CustomTextButton"
                    borderRadius="5px"
                    onClick={handleHomeClick}
                />
            </GridItem>
            <GridItem
                xs={12}
                sm={12}
                md={6}
                lg={6}
                mt={isSmallScreen ? 2 : 10}
                sx={{ textAlign: 'end!important' }}
            >
                <Button
                    borderColor={
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }
                    defaultColor="transparent"
                    icon=""
                    outlined="outlined"
                    text="Visualizar extrato da carteira digital"
                    textColor={
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }
                    typeVariation="CustomTextButton"
                    borderRadius="5px"
                    onClick={handleWalletClick}
                />
            </GridItem>
        </GridContainer>
    )
}
