import { formatBRCurrency } from '../../../components/storybook'
import SearchService from '../Services/searchService'

export async function GetAvailableSearch() {
    try {
        const response = await SearchService.getAvailable()
        return response?.data || []
    } catch (error) {
        console.error('Error fetching search results:', error)
        return []
    }
}

export function handleDescription(item) {
    const pointsMask = localStorage?.programCurrencySymbol
    const pointsName = localStorage?.programCurrencyName

    if (item && item?.paidSurvey?.key === '1' && item?.value) {
        return `Você receberá um bônus de ${formatBRCurrency(
            item?.value,
        )} reais`
    }
    if (item && item?.paidSurvey?.key === '2' && item?.value) {
        return `Você receberá um bônus de ${pointsMask} ${item?.value} ${pointsName}`
    }
    if (item && item?.paidSurvey?.key === '0') {
        return item?.description
    }
}
