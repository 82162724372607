import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import DocumentTitle from 'react-document-title'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'

// material-ui icons
import Menu from '@material-ui/icons/Menu'
import MoreVert from '@material-ui/icons/MoreVert'
import ViewList from '@material-ui/icons/ViewList'

// core components
import Button from 'components/CustomButtons/Button.jsx'

import adminNavbarStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx'
import { getProgramTitle } from '../../utils/utils'

function AdminNavbar({ ...props }) {
	const { classes, color, rtlActive } = props
	const appBarClasses = cx({
		[' ' + classes[color]]: color,
	})
	const sidebarMinimize =
		classes.sidebarMinimize +
		' ' +
		cx({
			[classes.sidebarMinimizeRTL]: rtlActive,
		})
	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<DocumentTitle title={getProgramTitle(props.brandText)} />


			<Toolbar className={classes.container}>
				<Hidden smDown implementation="css">
					<div className={sidebarMinimize}>
						<Button
							justIcon
							round
							color="white"
							onClick={props.sidebarMinimize}
						>
							{props.miniActive ? (
								<ViewList className={classes.sidebarMiniIcon} />
							) : (
								<MoreVert className={classes.sidebarMiniIcon} />
							)}
						</Button>
					</div>
				</Hidden>
				<Hidden mdUp implementation="css">
					<Button
						className={classes.appResponsive}
						color="transparent"
						justIcon
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}
					>
						<Menu />
					</Button>
				</Hidden>
			</Toolbar>
		</AppBar>
	)
}

AdminNavbar.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
	rtlActive: PropTypes.bool,
	brandText: PropTypes.string,
}

export default withStyles(adminNavbarStyle)(AdminNavbar)
