import image01 from './00127.png'
import image02 from './00151.png'
import image03 from './00152.png'

export const SeedsItens = [
    {
        title: 'COMPRAR',
        subtitle: 'Você pode comprar até',
        currency: 'SD$',
        value: '20.000',
        button: 'Comprar',
    },
    {
        title: 'SEEDS',
        subtitle: 'Você pode comprar até',
        currency: 'SD$',
        value: '12.500',
        button: 'Utilizar',
    },
]

export const Products = [
    {
        id: '03c5689b-4dfd-4620-a1e4-8184d98f8525',
        storeId: '342',
        name: 'Casa e Decoração',
        imageUrl: '',
        enable: false,
    },
    {
        id: '1346c6bd-8311-462e-8bcb-859c35be190b',
        storeId: '340',
        name: 'Alimentos e Bebidas',
        imageUrl:
            'https://loja-hom.cvale.com.br/media/catalog/category/categoria_alinetos_bebidas.png',
        enable: true,
    },
    {
        id: '1c6ea67d-5fe0-4f3b-b6a6-bcecbb912e15',
        storeId: '337',
        name: 'Pneus',
        imageUrl:
            'https://loja-hom.cvale.com.br/media/catalog/category/categoria_pneus.png',
        enable: true,
    },
    {
        id: '2162bf74-38cb-4beb-accc-a1a0768c31f7',
        storeId: '335',
        name: 'Produtos Agrícolas',
        imageUrl:
            'https://loja-hom.cvale.com.br/media/catalog/category/categoria_produtos_agricolas.png',
        enable: true,
    },
    {
        id: '4eaf6b49-1779-46ab-ad19-b76c46a37ef1',
        storeId: '343',
        name: 'Esporte e Lazer',
        imageUrl:
            'https://loja-hom.cvale.com.br/media/catalog/category/categoria_esportes.png',
        enable: true,
    },
    {
        id: '66f388be-cb13-4655-881e-5c1624998287',
        storeId: '338',
        name: 'Óleos e Lubrificantes',
        imageUrl:
            'https://loja-hom.cvale.com.br/media/catalog/category/categoria_oleos_lubrificantes.png',
        enable: true,
    },
    {
        id: '7833f780-5f5d-466a-9a30-f8eaeff7d2d4',
        storeId: '339',
        name: 'Peças e Ferramentas',
        imageUrl:
            'https://loja-hom.cvale.com.br/media/catalog/category/categoria_pe_as_ferramentas.png',
        enable: true,
    },
    {
        id: '8bff8086-8a5f-459b-a6e8-7a9d7a13a8ce',
        storeId: '344',
        name: 'Jardinagem',
        imageUrl:
            'https://loja-hom.cvale.com.br/media/catalog/category/categoria_jardinagem.png',
        enable: true,
    },
]

export const ExchangeProducts = [
    {
        image: image01,
        title: 'Vinho Italiano Badissa Cabernet Sauvignon Tinto 750ml',
        price: 'SD$ 500',
    },
    {
        image: image02,
        title: 'Óleo Lubrificante Shell Advance AX5 20W-50 4 Tempos 1L',
        price: 'SD$ 250',
    },
    {
        image: image03,
        title: 'Óleo Lubrificante Shell Advance SX 2 Tempos 500ml',
        price: 'SD$ 265',
    },
    {
        image: image01,
        title: 'Óleo Lubrificante Shell Spirax S2 G 80W 1L',
        price: 'SD$ 490',
    },
]
