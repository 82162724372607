import React, { forwardRef, useEffect, useState } from 'react'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import CustomField from 'components/CustomField/CustomField.jsx'
import { Grid, MenuItem } from '@material-ui/core'

import * as Styled from './styles'
import MaskedInput, { conformToMask } from 'react-text-mask'

const PHONENUMBER_MASK_NOT_HIDDEN = [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]

const CELLPHONENUMBER_MASK_NOT_HIDDEN = [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]

const CELLPHONENUMBER_MASK_HIDDEN = [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    '*',
    '*',
    '*',
    '*',
    '*',
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]

const PHONENUMBER_MASK_HIDDEN = [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    '*',
    '*',
    '*',
    '*',
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]

const CPF_MASK = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
]
const CNPJ_MASK = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
]

const IE_MASK = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]

// inscrição estadual '123.456.789'
export const ResponsableIE = ({ value, onChange, ...props }) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            mask={IE_MASK}
            placeholderChar={'\u2000'}
        />
    )
}

export const ResponsableCPFAndCNPJ = ({ value, onChange, ...props }) => {
    const [maskValue, setMaskValue] = useState(
        value && value?.length < 15
            ? conformToMask(value, CPF_MASK).conformedValue
            : `${conformToMask(value, CNPJ_MASK).conformedValue}`,
    )

    const handleMask = (valueWithoutMask) => {
        return valueWithoutMask?.length < 15 ? CPF_MASK : CNPJ_MASK
    }

    useEffect(() => {
        setMaskValue(value)
    }, [value])

    return (
        <MaskedInput
            mask={handleMask}
            value={maskValue}
            onChange={(e) => {
                const val = onChange(e.target.value)

                if (val) {
                    setMaskValue(val)
                } else {
                    setMaskValue(e.target.value)
                }
            }}
            {...props}
            guide={false}
        />
    )
}

export function CEPMaskCustom(props) {
    const { inputRef, ...other } = props

    if (!props?.value?.includes('*')) {
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
            />
        )
    } else {
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                mask={[/\d/, /\d/, '*', '*', '*', '-', /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
            />
        )
    }
}

export function PhoneMaskCustom({ inputRef, value, onChange, ...props }) {
    const adequacyMask = () => {
        // phone
        if (value && value?.length <= 14) {
            if (value && !value?.includes('*')) {
                return conformToMask(value, PHONENUMBER_MASK_NOT_HIDDEN)
                    .conformedValue
            } else {
                return conformToMask(value, PHONENUMBER_MASK_HIDDEN)
                    .conformedValue
            }
        }

        // cellphone
        if (value && value?.length < 14) {
            if (value && !value?.includes('*')) {
                return conformToMask(value, CELLPHONENUMBER_MASK_NOT_HIDDEN)
                    .conformedValue
            } else {
                return conformToMask(value, CELLPHONENUMBER_MASK_HIDDEN)
                    .conformedValue
            }
        }

        // default
        return conformToMask(value, PHONENUMBER_MASK_NOT_HIDDEN).conformedValue
    }

    const [maskValue, setMaskValue] = useState(adequacyMask())

    const handleMask = (value) => {
        // phone
        if (value && value?.length <= 14) {
            if (value && !value?.includes('*')) {
                return PHONENUMBER_MASK_NOT_HIDDEN
            } else {
                return PHONENUMBER_MASK_HIDDEN
            }
        }

        // cellphone
        if (value && value?.length > 14) {
            if (value && !value?.includes('*')) {
                return CELLPHONENUMBER_MASK_NOT_HIDDEN
            } else {
                return CELLPHONENUMBER_MASK_HIDDEN
            }
        }

        // default
        return PHONENUMBER_MASK_NOT_HIDDEN
    }

    useEffect(() => {
        setMaskValue(value)
    }, [value])

    return (
        <MaskedInput
            mask={handleMask}
            value={maskValue}
            onChange={(e) => {
                const val = onChange(e.target.value)

                if (val) {
                    setMaskValue(val)
                } else {
                    setMaskValue(e.target.value)
                }
            }}
            {...props}
            guide={false}
        />
    )
}

export function CellPhoneMaskCustom(props) {
    const { inputRef, ...other } = props

    if (!props?.value?.includes('*')) {
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                guide={false}
                mask={(rawValue) => [
                    '(',
                    /[1-9]/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                ]}
                placeholderChar={'\u2000'}
            />
        )
    } else {
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                guide={false}
                mask={(rawValue) => [
                    '(',
                    /[1-9]/,
                    /\d/,
                    ')',
                    ' ',
                    '*',
                    '*',
                    '*',
                    '*',
                    '*',
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                ]}
                placeholderChar={'\u2000'}
            />
        )
    }
}

export function DateMaskCustom(props) {
    const { inputRef, ...other } = props

    if (!props?.value?.includes('*')) {
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                mask={[
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                ]}
                placeholderChar={'\u2000'}
            />
        )
    } else {
        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null)
                }}
                mask={['*', '*', '/', /\d/, /\d/, '/', '*', '*', '*', '*']}
                placeholderChar={'\u2000'}
            />
        )
    }
}

export function ResponsableCPF(props) {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            guide={false}
            mask={(rawValue) => [
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
            ]}
            placeholderChar={'\u2000'}
        />
    )
}

export const RegisterInput = forwardRef(
    ({ disabled, hasTooltip, xs, list, ...props }, ref) => {
        return (
            <Styled.Container container item xs={xs || 12} {...props}>
                <Grid item xs={12}>
                    <Styled.CssTextField
                        fullWidth
                        autoComplete="off"
                        disabled={disabled}
                        variant="outlined"
                        margin="dense"
                        {...props}
                        label={
                            <CustomRequiredField
                                autoComplete="off"
                                value={props.label}
                                disabled={disabled}
                            />
                        }
                        ref={ref}
                        inputProps={{
                            autoComplete: 'new-password',
                        }}
                    >
                        {props.select
                            ? list?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                  </MenuItem>
                              ))
                            : null}
                    </Styled.CssTextField>
                </Grid>
            </Styled.Container>
        )
    },
)

export const RegisterInputNotRequired = forwardRef(
    ({ disabled, hasTooltip, xs, list, ...props }, ref) => {
        return (
            <Styled.Container container item xs={xs || 12} {...props}>
                <Grid item xs={12}>
                    <Styled.CssTextField
                        fullWidth
                        autoComplete="off"
                        disabled={disabled}
                        variant="outlined"
                        margin="dense"
                        {...props}
                        label={
                            <CustomField
                                autoComplete="off"
                                value={props.label}
                                disabled={disabled}
                            />
                        }
                        ref={ref}
                    >
                        {props.select
                            ? list?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                  </MenuItem>
                              ))
                            : null}
                    </Styled.CssTextField>
                </Grid>
            </Styled.Container>
        )
    },
)

export default RegisterInput
