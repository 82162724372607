import React from 'react'

// @ Components
import TabPanel from './TabPanel'

const CardLog = () => {

	return (
		<TabPanel />
	)
}

export default CardLog
