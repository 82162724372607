import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Datetime from 'react-datetime'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const Container = styled.div`
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormControl-marginDense {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .MuiGrid-item {
        padding-left: 25px !important;
        padding-top: 25px !important;
    }
`

export const ModalButton = styled(Grid)`
    margin: 0px 0px 30px 0px;
    font-weight: 600;
    font-size: 14px;
    span {
        cursor: pointer;
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor};
    }
`

export const GridBox = styled(Grid)`
    width: 100%;
    margin-top: 30px;

    .MuiTableSortLabel-root {
        line-height: 16px;
    }


    .detail-button {
        margin: 1px;
        padding: 0px;
        height: 45px;
        width: 35px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
    }

    tbody {
        border-left: none;
        border-right: none;
    }

    .MuiPaper-elevation2 {
        box-shadow: unset;
    }

    .MuiToolbar-regular {
        min-height: 0px;
    }

    .MuiTableHead-root {
        tr {
            th {
                background-color: #e9e9e9;
                color: ${GetThemeColorFromStorage()?.titles?.secondary
                    ?.textColor};
                font-size: 16px;
                font-weight: bold;
                height: 40px;

                padding: 0px 16px;

                :first-child {
                    border-top-left-radius: 6px;
                    width: 20% !important;
                    justify-content: flex-start;
                    text-align: left !important;
                }

                :last-child {
                    border-top-right-radius: 6px;
                    width: 20% !important;
                    span {
                        svg {
                            display: none;
                        }
                    }
                }
                :nth-child(2) {
                    text-align: left !important;
                    width: 35% !important;
                }
                :nth-child(3) {
                    text-align: left !important;
                    width: 3% !important;
                }
                :nth-child(4) {
                    text-align: left !important;
                    width: 30% !important;
                }
                :nth-child(5) {
                    text-align: left !important;
                    width: 30% !important;
                }
            }
        }
    }

    .MuiTableBody-root {
        .MuiTableCell-alignLeft {
            :last-child {
            }
        }
        tr {
            color: #717171;
            font-size: 14px;
            height: 45px;

            p {
                font-size: 13px;
                font-style: italic;
                margin: 0px;
            }

            td {
                padding: 0px 15px !important;
                :first-child {
                    width: 20% !important;
                }

                :nth-child(2) {
                    text-align: left !important;
                    width: 35% !important;
                }
                :nth-child(3) {
                    text-align: left !important;
                    width: 5% !important;
                }
                :nth-child(4) {
                    text-align: left !important;
                    width: 20% !important;
                }
                :nth-child(5) {
                    text-align: left !important;
                    text-overflow: ellipsis;
                    width: 30% !important;
                }
                :last-child {
                    border-top-right-radius: 6px;
                    width: 20% !important;
                }
            }
        }
    }
`

export const DivDate = styled(Grid)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    .dateInputBlock {
        margin-top: 2px;
        width: 142px;
    }
    .icon-data {
        font-size: 20px;
        margin-right: -8px;
        color: ${GetThemeColorFromStorage()?.visual?.icons?.color};
    }
    .rdtPicker {
        .dow,
        th.rdtSwitch,
        th.rdtNext,
        th.rdtPrev,
        .rdtTimeToggle {
            color: ${GetThemeColorFromStorage()?.titles?.secondary?.textColor};
        }
    }
    .rdtDay {
        &.rdtToday.rdtActive,
        &.rdtActive,
        &.rdtActive:hover {
            background-color: ${GetThemeColorFromStorage()?.titles?.secondary
                ?.textColor} !important;
        }
    }
`

// ShopHistory.jsx

export const FormContainer = styled(GridContainer)`
    display: flex;
    justify-content: space-between;
    padding: 20px 15px !important;

    .rdtPicker {
        .dow,
        th.rdtSwitch,
        th.rdtNext,
        th.rdtPrev,
        .rdtTimeToggle {
            color: ${(props) =>
                props?.theme?.titles?.highlight?.textColor} !important;
        }
    }
    .rdtDay {
        &.rdtToday.rdtActive,
        &.rdtActive,
        &.rdtActive:hover {
            background-color: ${(props) =>
                props?.theme?.titles?.highlight?.textColor} !important;
        }
    }
    .rdtMonths,
    .rdtYears {
        .rdtMonth,
        .rdtYear {
            &.rdtActive {
                background-color: ${(props) =>
                    props?.theme?.titles?.highlight?.textColor} !important;
            }
        }
    }
`

export const DateTimeReact = styled(Datetime)`
    .MuiFormControl-marginDense {
        margin-top: 0px;
        margin-bottom: Opx;
        width: 100%;
    }
`
export const NameCnpjPartner = styled.div`
    .MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 3px;
    }
`
export const InvoiceNumber = styled.div`
    .MuiInputLabel-outlined {
        z-index: 0 !important;
    }
    width: 100%;
`

export const ContaineMoreFilter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
    @media only screen and (min-width: 450px) {
        flex-direction: row;
    }
`
export const MoreFilter = styled.div`
    align-items: center;
    color: ${GetThemeColorFromStorage()?.navigation?.buttons?.backgroundColor};
    display: flex;
    padding: 0;
    line-height: 36px;
    width: 100%;
    i,
    span {
        cursor: pointer;
    }
`

export const BtnSearchFilter = styled.div`
    display: flex;
    margin-top: 10px;
    padding-right: 15px;
    gap: 15px;
    width: 100%;
    @media only screen and (min-width: 450px) {
        justify-content: flex-end;
        margin-top: 0;
        padding-right: 0;
    }
`

export const LabelInput = styled.label`
    font-weight: 400;
    position: absolute;
    top: -7px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    font-size: 1rem;
    z-index: 3;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1;
    letter-spacing: 0.00938em;
    transform: translateX(-10px) scale(0.75);
    pointer-events: none;
    padding: 0px 4px 0px 4px;
    background-color: #fff;
    left: -11px;
`

export const EmptyTable = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    color: #b4b4b4;

    i {
        padding-top: 1em;
        font-size: 3em;
        cursor: pointer;
        margin: auto 18px;
        color: #b4b4b4;
    }

    .icon-informacao:before {
        font-size: 1.5em;
    }

    span {
        font-weight: 900;
        margin: 25px 0 12px;
    }
`
