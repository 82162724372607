import React, { useEffect } from 'react'
import {
    Button,
    MuiBox,
    MuiLoader,
    MuiSnack,
    MuiTypography,
} from '../../components/storybook'
import DocumentTitle from 'react-document-title'
import SearchForm from './SearchForm/index.js'
import Section from '../../components/Section/Section'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { getProgramTitle } from '../../utils/utils.js'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor.js'
import styles from './search.module.scss'
import { SuccessSearch } from './Components/SuccessSearch/index.js'
import useSearch from './Hooks/useSearch.js'
import { handleDescription } from '../Admin/function/index.js'
import { handleBackground } from './functions/index.jsx'
import { useMediaQuery } from '@mui/material'

const SearchChild = () => {
    const { t } = useTranslation()
    const isScreenSmall = useMediaQuery('(max-width:767px)')
    const {
        GetSurveyDetails,
        dataSurveyDetails,
        theme,
        handleSubmit,
        control,
        onSubmit,
        setValue,
        postSuccess,
        errorMessage,
        setErrorMessage,
        isLoadingData,
        isValid,
        watchfields,
        handleLoader,
        isLoading,
    } = useSearch()

    const germiniLogoInterna = theme?.images?.internalPages?.menuLogo?.url

    useEffect(() => {
        GetSurveyDetails()
    }, [])

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <MuiBox pt={0}>
                    <MuiSnack
                        open={errorMessage}
                        message={errorMessage}
                        type="error"
                        autoHideDuration={5000}
                        onClose={() => setErrorMessage('')}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    />
                    <S.HeaderBox
                        className={styles.parent}
                        backgroundImage={
                            GetThemeColorFromStorage()?.images?.internalPages
                                ?.menuBackground?.url
                        }
                    >
                        <div className={styles.headerContainer}>
                            <div className={styles.logoBox}>
                                <img
                                    className={styles.logo}
                                    src={germiniLogoInterna}
                                    alt="Logo"
                                />
                            </div>
                        </div>
                    </S.HeaderBox>
                </MuiBox>
                <MuiBox p={isScreenSmall ? '0px 20px' : '0px 200px'}>
                    {postSuccess ? (
                        <SuccessSearch dataSurveyDetails={dataSurveyDetails} />
                    ) : (
                        <>
                            <DocumentTitle
                                title={getProgramTitle('Pesquisa')}
                            />
                            <MuiBox mt={3}>
                                <Section
                                    title="Pesquisa"
                                    children={
                                        <Button
                                            hoverColor="#EB6C61"
                                            colorIcon="#FFFFFF"
                                            icon
                                            text="Fechar"
                                            typeVariation="CustomTextButton"
                                            onClick={() =>
                                                (window.location.href = '/home')
                                            }
                                            sx={{
                                                color: '#fff!important',
                                                textTransform:
                                                    'inherit!important',
                                                backgroundColor:
                                                    '#EB6C61!important',
                                                border: '1px solid #EB6C61!important',
                                                padding: '5px 10px!important',
                                                borderRadius: '5px',
                                                ':hover': {
                                                    border: `1px solid #EB6C61!important`,
                                                    textTransform:
                                                        'inherit!important',
                                                    color: '#fff!important',
                                                    padding:
                                                        '5px 10px!important',
                                                    borderRadius: '5px',
                                                },
                                            }}
                                        />
                                    }
                                />
                            </MuiBox>

                            <S.ContainerSecundary>
                                <MuiBox
                                    sx={{
                                        backgroundColor:
                                            handleBackground(dataSurveyDetails),
                                        padding: '30px',
                                        borderRadius: '5px',
                                        backgroundImage: `url(${dataSurveyDetails?.backgroundImage})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                    }}
                                    mb={3}
                                >
                                    <MuiTypography
                                        color="#FFFFFF"
                                        fontWeight={600}
                                    >
                                        {dataSurveyDetails?.title}
                                    </MuiTypography>
                                    <MuiTypography color="#FFFFFF" mt={1}>
                                        {handleDescription(dataSurveyDetails)}
                                    </MuiTypography>
                                </MuiBox>
                                {isLoadingData ? (
                                    <MuiLoader
                                        defaultColor={
                                            theme?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                    />
                                ) : (
                                    <SearchForm
                                        isScreenSmall={isScreenSmall}
                                        theme={theme}
                                        dataSurveyDetails={dataSurveyDetails}
                                        control={control}
                                        setValue={setValue}
                                        isLoadingData={isLoadingData}
                                        isValid={isValid}
                                        watchfields={watchfields}
                                        handleLoader={handleLoader}
                                        isLoading={isLoading}
                                    />
                                )}
                            </S.ContainerSecundary>
                        </>
                    )}
                </MuiBox>
            </form>
        </div>
    )
}

export default SearchChild
