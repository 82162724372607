import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
    invoiceData: [],
    invoiceDataFailed: false,

    invoiceCreditSource: [],
    invoiceCreditSourceFailed: false,

    mergedCreditSource: [],
    mergedCreditSourceFailed: false,

    invoiceById: [],
    invoiceByIdFailed: false,
}

// #1
const fetchShopHistoryData = (state, action) => {
    return updateObject(state, {
        invoiceData: action.invoiceData
    });
};

// #1 DATA FAIL
const fetchShopHistoryDataError = (state, action) => {
    return updateObject(state, {
        invoiceDataFailed: action.invoiceDataFailed
    });
};


const fetchShopHistoryDataById = (state, action) => {
    return updateObject(state, {
        invoiceById: action.invoiceById
    });
};

// #1 DATA FAIL
const fetchShopHistoryDataByIdError = (state, action) => {
    return updateObject(state, {
        invoiceByIdFailed: action.invoiceByIdFailed
    });
};
// --------------------------

// #2 GetInvoicesCreditSource
const fetchInvoicesCreditSource = (state, action) => {
    return updateObject(state, {
        invoiceCreditSource: action.invoiceCreditSource
    });
};

// #2 DATA FAIL
const fetchInvoicesCreditSourceError = (state, action) => {
    return updateObject(state, {
        invoiceCreditSourceFailed: action.invoiceCreditSourceFailed
    });
};

// #3 MergeCreditSource
const fetchMergeCreditSource = (state, action) => {
    return updateObject(state, {
        mergedCreditSource: action.mergedCreditSource
    });
};

// #3 DATA FAIL
const fetchMergeCreditSourceError = (state, action) => {
    return updateObject(state, {
        mergedCreditSourceFailed: action.mergedCreditSourceFailed
    });
};


export const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SHOP_HISTORY_DATA:
            return fetchShopHistoryData(state, action);
        case actionTypes.FETCH_SHOP_HISTORY_FAILED:
            return fetchShopHistoryDataError(state, action);

        case actionTypes.FETCH_INVOICE_CREDIT_SOURCE:
            return fetchInvoicesCreditSource(state, action);
        case actionTypes.FETCH_INVOICE_CREDIT_SOURCE_FAILED:
            return fetchInvoicesCreditSourceError(state, action);

        case actionTypes.FETCH_MERGE_CREDIT_SOURCE:
            return fetchMergeCreditSource(state, action);
        case actionTypes.FETCH_MERGE_CREDIT_FAILED:
            return fetchMergeCreditSourceError(state, action);

        case actionTypes.FETCH_INVOICE_BY_ID:
            return fetchShopHistoryDataById(state, action);
        case actionTypes.FETCH_INVOICE_BY_ID_FAILED:
            return fetchShopHistoryDataByIdError(state, action);

        default:
            return state;
    }
}

export default invoiceReducer;