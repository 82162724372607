import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// @material-ui/core components
import { FormControlLabel } from '@material-ui/core'

import { CustomCheckbox } from 'components/CustomCheckbox'
import Loader from 'components/Loader/Loader.jsx'

import RenderRegulation from 'components/RenderRegulation/index.js'
import ViewModal from 'components/ViewModal/index.js'
import { BodyRender } from 'components/ViewModal/styles.js'
import { BoxForm, BoxAccept } from './styles.js'
import Button from 'components/CustomButtons/Button.jsx'

import {
    GetPlatformRules,
    SetConsumerPlatformRules,
} from 'redux/api/platformRules.js'
import {
    AcceptancePrivacyPolicies,
    GetPrivacyPolicies,
} from 'redux/api/policies'

import {
    GetRegulationData as GetRegulationRules,
    PostRegulationData,
} from 'redux/api/regulation.js'
import { useTranslation } from 'react-i18next'
import { PlatformRules } from './utils/index.jsx'
import { useConsumer } from '../../../Context/consumerContext.js'

const PlatformRulesModal = ({ getPlatformRules }) => {
    const history = useHistory()
    const { t } = useTranslation()
    const [loading, setLoading] = React.useState(false)
    const { userData } = useConsumer()

    const [state, setState] = React.useState({
        alertRG: false,
        types: [],
        type: '',
        regulationData: [],
        useTermsData: {},
    })

    const [accepted, setAccepted] = React.useState(false)

    const [terms, setTerms] = React.useState([])

    const { alertRG, type } = state
    const currentTerm = terms?.find((item) => item.id === type) || {}
    const currentPlatformRules =
        PlatformRules?.find((item) => item.id === type) || {}

    const getTermsAndRegulation = async () => {
        const responseRegulation = await GetRegulationRules()
        const responseUseTerms = await GetPlatformRules()
        const policy = await GetPrivacyPolicies()

        const optins = Object.keys(responseRegulation?.consumerAgreements)?.map(
            (key) => {
                if (responseRegulation?.consumerAgreements[key] === false) {
                    return key
                }
                return false
            },
        )

        const types = optins.filter(Boolean)

        setTerms([
            {
                id: 'acceptedCurrentRegulation',
                data: responseRegulation?.regulation || {},
                days: responseRegulation?.consumerAgreements
                    ?.daysRemainingRegulation,
            },
            {
                id: 'acceptedCurrentUseTerms',
                data: responseUseTerms?.useTerms || {},
                days: responseUseTerms?.consumerAgreements
                    ?.daysRemainingUseTerms,
            },
            {
                id: 'acceptedCurrentPrivacyPolicies',
                data: policy?.data?.privacyPolicies || {},
                days: policy?.data?.consumerAgreements
                    ?.daysRemainingPrivacyPolicies,
            },
        ])

        setState({
            regulationData: responseRegulation,
            useTermsData: responseUseTerms,
            policyData: policy?.data,
            types: types,
            type: types?.length > 0 ? types[0] : '',
            alertRG: types?.length > 0 ? true : false,
        })
    }

    const handleClose = (type) => {
        if (terms?.find((item) => item.id === state.type)?.days === 0)
            history.push('/auth/login')
        const types = state.types.filter((item) => item !== type)
        setState((prevState) => ({
            ...prevState,
            types: types,
            alertRG: types?.length > 0 ? true : false,
            type: types?.length > 0 ? types[0] : '',
        }))
    }

    const handleChangeSwitch = () => {
        setAccepted(!accepted)
    }

    const handleAcceptance = async (type) => {
        setLoading(true)
        try {
            if (state.types.length > 0) {
                const { regulationData, useTermsData, policyData } = state
                let endpoint
                let requestData

                switch (type) {
                    case 'acceptedCurrentRegulation':
                        endpoint = PostRegulationData
                        requestData = {
                            consumerId: userData.id,
                            regulationRulesId: regulationData.id,
                        }
                        break
                    case 'acceptedCurrentUseTerms':
                        endpoint = SetConsumerPlatformRules
                        requestData = {
                            consumerId: userData.id,
                            platformRulesId: useTermsData.id,
                        }
                        break
                    case 'acceptedCurrentPrivacyPolicies':
                        endpoint = AcceptancePrivacyPolicies
                        requestData = {
                            consumerId: userData?.id,
                            privacyPoliciesId: policyData.id,
                        }
                        break
                    default:
                        break
                }

                const response = await endpoint(requestData)
                if (response?.success || response?.status === 200) {
                    const types = state.types.filter((item) => item !== type)
                    setAccepted(!accepted)
                    getPlatformRules()
                    setState((prevState) => ({
                        ...prevState,
                        types,
                        alertRG: types.length > 0,
                        type: types.length > 0 ? types[0] : '',
                    }))
                    setAccepted(!accepted)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getTermsAndRegulation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ViewModal
            open={alertRG}
            closeByClick={() => {
                handleClose(type)
            }}
            headerIcon={false}
            headerTitle={currentPlatformRules?.title}
            buttonTitle={
                currentTerm.days === 0 ? 'BUTTON_LOGOUT' : 'BUTTON_DELAY_ACCEPT'
            }
            days={currentTerm.days}
        >
            {loading && <Loader />}
            <BodyRender>
                <RenderRegulation renderComp={currentTerm.data} />
            </BodyRender>
            <BoxForm name="form">
                <BoxAccept>
                    <div>
                        <FormControlLabel
                            control={
                                <CustomCheckbox
                                    key={currentPlatformRules?.id}
                                    style={{ padding: '0px' }}
                                    onClick={handleChangeSwitch}
                                    checked={accepted}
                                />
                            }
                            label={t(currentPlatformRules?.content)}
                        />
                    </div>

                    <Button
                        onClick={() => {
                            handleAcceptance(type)
                        }}
                        disabled={!accepted}
                        size="sm"
                        variant="contained"
                        color="greenButtonColor"
                    >
                        {t('BUTTON_CONFIRM')}
                    </Button>
                </BoxAccept>
            </BoxForm>
        </ViewModal>
    )
}

PlatformRulesModal.propTypes = {
    getPlatformRules: PropTypes.func,
}

export default PlatformRulesModal
