/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CircularProgress } from "@mui/material";
import { MuiBox } from "../Box";
export function MuiLoader(_a) {
    var defaultColor = _a.defaultColor, size = _a.size;
    return (_jsx(MuiBox, __assign({ sx: {
            textAlign: "center",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        } }, { children: _jsx(CircularProgress, { size: size, sx: { color: defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C" } }) })));
}
