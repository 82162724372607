import { Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Container = styled(Grid)`
    background-color: ${({ title }) => title && '#f5f5f5'};
    border-radius: 0.25rem;

    .character {
        color: ${({ themeColor }) =>
            themeColor?.navigation?.buttons?.backgroundColor} !important;
    }

    span {
        font-size: 15px;
        color: #333333;
    }

    .title_kVVVRq {
        text-align: center;
        font: normal normal normal 15px Roboto;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }

    input[type='text'] {
        width: 56px;
        height: 59px;
        border-radius: 0.25rem;
        border: 1px solid #c4c4c4;
        font-size: 20px;
        font-weight: bold;
    }

    .container {
        height: 59px;
        width: 214px;
    }

    .input-box {
        text-align: center;
    }

    ${({ error }) =>
        !!error &&
        css`
            input[type='text'] {
                border-color: #de2e2e;
                color: #de2e2e;
            }
        `}

    @media screen and (min-width: 960px) {
        .input-box {
            padding-left: ${({ title }) => title && '1rem'};
            text-align: ${({ title }) => (title ? 'start' : 'center')};
        }
    }
`
