import React from 'react'
import GridContainer from 'components/Grid/GridContainer.jsx'
import TransferGridItemField from './TransferGridItemField.jsx'
import ReadonlyField from './ReadonlyField.jsx'
import ValueInputField from './ValueInputField.jsx'
import { useBrazilMoneyFormat } from '../User/Profile/moneyFormater'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import GridItem from 'components/Grid/GridItem.jsx'

const TransferRequest_Form = ({
	classes,
	disabledForm,
	userTotalMoney,
	themeColor,
	setBruteValue,
	clear,
	bruteValue,
	transferFee,
	transferFeePercentage,
	antecipationFees,
	liquidValue,
	updateTransferValues,
	minError,
	maxError,
	setError,
	setDisabledSaveButton,
	error,
}) => {
	const BrazilCurrency = (_value) => useBrazilMoneyFormat(_value)

	const transferGridItemFieldContents = [
		<ReadonlyField
			themeColor={themeColor}
			innerContent={BrazilCurrency(userTotalMoney)}
			spanLabel={'Saldo disponível para transferência'}
		/>,
		<ValueInputField
			minError={minError}
			maxError={maxError}
			setError={setError}
			liquidValue={liquidValue}
			setDisabledSaveButton={setDisabledSaveButton}
			error={error}
			themeColor={themeColor}
			setBruteValue={setBruteValue}
			classes={classes}
			bruteValue={bruteValue}
			clear={clear}
			userTotalMoney={userTotalMoney}
			disabledForm={disabledForm}
			id={`transferValueInput`}
			updateTransferValues={updateTransferValues}
		/>,
		...antecipationFees.map((_fee) => (
			<ReadonlyField
				themeColor={themeColor}
				innerContent={BrazilCurrency(_fee.anticipationFee)}
				spanLabel={`Taxa de antecipação (${_fee.anticipationFeePercentage}%)`}
			/>
		)),

		<ReadonlyField
			themeColor={themeColor}
			innerContent={BrazilCurrency(transferFee)}
			spanLabel={`Taxa de transferência (${transferFeePercentage}%)`}
		/>,
		<ReadonlyField
			fontWeight={'bold'}
			themeColor={themeColor}
			innerContent={BrazilCurrency((liquidValue > userTotalMoney || liquidValue <= 0 || minError || maxError || bruteValue > userTotalMoney) ? 0 : liquidValue)}
			spanLabel={'Valor líquido'}
		/>,
	]

	return (
		<>
			<GridContainer style={{ marginTop: `50px` }}>
				{transferGridItemFieldContents.map((_content, _index) => {
					return (
						_index === 0 && <TransferGridItemField
							key={_index}
							gridSize={12}
							InnerComponent={_content}
							// isVisible={_index === 0}
							gridItemSize={4}
						/>
					)
				})}
			</GridContainer>

			<GridContainer style={{ marginTop: `8px` }}>
				{transferGridItemFieldContents.map((_content, _index) => {
					return (
						_index !== 0 && <TransferGridItemField
							key={_index}
							gridSize={12}
							InnerComponent={_content}
							// isVisible={_index !== 0}
							gridItemSize={4}
							error={error}
						/>
					)
				})}
			</GridContainer>
		</>

	)
}

export default TransferRequest_Form
