import React from 'react'
const ReadonlyField = ({
    innerContent,
    spanLabel,
    borderColor = 'transparent',
    themeColor,
    fontWeight = 'normal',
}) =>{
    const readOnluFieldStyle={
        width: '100%',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
        padding: '0 12px',
        border: `2px solid ${borderColor}`,
        borderRadius: '5px',
        transform: "translateY(8px)",
        position: "relative",
        paddingTop: '2px',
        backgroundColor:'rgb(238, 238, 238)',
        color: themeColor,
        fontWeight: fontWeight,
        marginBottom: '16px', 
    }
    const spanLabelStyle={
        position: "absolute",
        bottom: "43px",
        left: "0px",
        color: "#838383",
        display: "inline",
        width: "100%",
        fontSize: ".7rem",
        lineHeight: "11px",
        fontWeight: '400'
    }
    return(
        <div style={readOnluFieldStyle}>
            {innerContent}
            <span style={spanLabelStyle}>{spanLabel}</span>
        </div>
    )
}
export default ReadonlyField;