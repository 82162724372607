import React from 'react'
import styles from './voucherCard.module.scss'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const VoucherCard = (props) => {
    const {
        receivedData,
        dueDate,
        value,
        debit,
        origin,
        parcialUtilized,
        anonymous,
        entryType,
        handleUsedButton,
        classes,
        usedConsumerDigitals,
        isInactive,
    } = props

    const { t } = useTranslation()

    const isValue = props?.value?.props?.value ?? ''
    const isDebit = props?.debit?.props?.value ?? ''
    const valueUsed = isValue - isDebit ?? ''

    const currencySymbol = localStorage.getItem('currencySymbol')

    return (
        <div className={styles.card}>
            {isInactive ? (
                <div className={styles.imageUtilized} />
            ) : entryType ===1 ? (
                <div className={styles.image} />
            ) : (
                <div className={styles.imageUtilized} />
            )}
            <div className={styles.content}>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div>
                        <div className={styles.title}>
                            {t('FIELD_DATE_RECEIVED_GIFT')}
                        </div>
                        <div className={styles.description}>{receivedData}</div>
                        {!debit ? (
                            <>
                                <div className={styles.title}>
                                    {t('FIELD_VALUE_GIFT')}
                                </div>
                                <div className={styles.description}>
                                    {value}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.title}>
                                    {t('FIELD_DEBIT_GIFT')}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div className={styles.description}>
                                        {debit?.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }) || '-'}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className={styles.title}>
                            {t('FIELD_FROM_GIFT')}
                        </div>
                    </div>
                    <div>
                        <div className={styles.title}>
                            {t('FIELD_DUE_DATE_GIFT')}
                        </div>
                        <div className={styles.description}>
                            {dueDate.toString() === '31/12/0000'
                                ? '-'
                                : dueDate}
                        </div>

                        {entryType ===3 ? (
                            <></>
                        ) : (
                            <>
                                {' '}
                                {debit ? (
                                    <>
                                        <div className={styles.title}>
                                            {t('FIELD_VALUE_GIFT')}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div className={styles.description}>
                                                {valueUsed < 0
                                                    ? `${currencySymbol} 0`
                                                    : `${currencySymbol} ${Math.round(
                                                          valueUsed,
                                                      )}`}
                                            </div>
                                            {parcialUtilized === true &&
                                                entryType ===2 && (
                                                    <S.MyTooltip
                                                        arrow
                                                        id="tooltip-top"
                                                        title={t(
                                                            'PARTIAL_GIFT_CONSUMED',
                                                        )}
                                                        placement="top-start"
                                                        classes={{
                                                            tooltip:
                                                                classes.tooltip,
                                                        }}
                                                    >
                                                        <S.MyIcon className="icon-parcialmente_comsumido" />
                                                    </S.MyTooltip>
                                                )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {debit ? (
                                            <>
                                                <div className={styles.title}>
                                                    {t('FIELD_DEBIT_GIFT')}
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            styles.description
                                                        }
                                                    >
                                                        {debit}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.descriptionWrapper}>
                    <div className={styles.description}>
                        {anonymous === true ? t('ANONYMOUS_GIFT') : origin}
                    </div>

                    {parcialUtilized === true && entryType ===1 && (
                        <S.MyTooltip
                            arrow
                            id="tooltip-top"
                            title={t('PARTIAL_GIFT')}
                            placement="top-start"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <S.MyIcon
                                onClick={() => {
                                    handleUsedButton(usedConsumerDigitals)
                                }}
                                className={`icon-parcialmente_comsumido icon`}
                            />
                        </S.MyTooltip>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VoucherCard
