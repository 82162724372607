import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'
import { withStyles } from '@material-ui/core'
import TextField from 'components/CustomInput/CustomTextField'

export const typeGroup = {
    Category: 'Categoria',
    Brand: 'Marca',
    Family: 'Família',
    Manufacturer: 'Fabricante',
    Product: 'Produto',
}

export const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: grayColor[2],
        },
        '& label': {
            fontSize: '14px',
            lineHeight: 1.2,
            minWidth: 300,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: grayColor[2],
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: grayColor[2],
            },
            '&.Mui-focused fieldset': {
                borderColor: grayColor[2],
            },
        },
        '&.blue-border .MuiOutlinedInput-root': {
            border: `1px solid ${
                GetThemeColorFromStorage()?.navigation?.buttons?.backgroundColor
            }`,
        },
    },
})(TextField)

export const config = {
    Fixed: 'Fixo',
    Manual: 'Porcentagem',
    Percentage: 'Porcentagem',
}
