import React, { useState, use, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import * as Styled from '../../../DigitalWallet/styles'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { numberFormatText } from 'utils/utils'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'

import {
    MuiStack,
    MuiTypography,
    ViewCartIcon,
} from '../../../../components/storybook'

export const FIELDSOURCE = (data) => {
    const { t } = useTranslation()

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-parcerias" />
            <div className="partner_wrapper">
                <div className="partner__label">
                    {data?.data?.entryType?.value === 'CREDIT'
                        ? t('FIELD_ORIGIN')
                        : t('FIELD_DESTINATION')}
                </div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {data.data.entryType?.key === '2' &&
                        data.data.annonymousSent && (
                            <Styled.MyTooltip
                                arrow
                                id="tooltip-top"
                                title={t('ANONYMOUS_GIFT_SENT')}
                                placement="top-start"
                            >
                                <i
                                    style={{
                                        fontSize: 20,
                                        position: 'relative',
                                        left: '14px',
                                        color: germiniDefaultColors[0],

                                        marginRight: '20px',
                                    }}
                                    className="icon-anonimo"
                                />
                            </Styled.MyTooltip>
                        )}
                    {!isEmpty(data) &&
                    data.data.partnerName !== null &&
                    data.data.partnerName !== ''
                        ? data.data.partnerName
                        : data.data.annonymousSent
                        ? t('ANONYMOUS_SENT')
                        : '-- --'}
                    {data.data.originPlatform?.key === 1 && (
                        <a className="originPlatform">- PDV Virtual</a>
                    )}
                </div>
            </div>
        </Grid>
    )
}

export const OPERATIONEVENT = (data) => {
    const { t } = useTranslation()
    return (
        <Grid
            item
            xs={12}
            className="partner__container"
            style={{ marginLeft: '-3px' }}
        >
            <i className="icon-carteira_tipo_operacao" />
            <div className="partner_wrapper">
                <div className="partner__label" style={{ marginLeft: '-3px' }}>
                    {t('OPERATION_EVENT')}
                </div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {t(data.data.sourceType?.value)}
                </div>
            </div>
        </Grid>
    )
}

export const OPERATIONTYPE = (data) => {
    const { t } = useTranslation()
    return (
        <Grid
            item
            xs={12}
            className="partner__container"
            style={{ marginLeft: '-3px' }}
        >
            <i className="icon-carteira_tipo_operacao" />
            <div className="partner_wrapper">
                <div className="partner__label" style={{ marginLeft: '-3px' }}>
                    {t('OPERATION_TYPE')}
                </div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {!isEmpty(data) &&
                    data.data.transactionType !== null &&
                    data.data.transactionType !== ''
                        ? data.data.entryType?.key === '2' &&
                          data.data.sourceType?.key === '6'
                            ? t(data.data?.sourceType?.value)
                            : t(data.data.transactionType.value)
                        : '-- --'}
                </div>
            </div>
        </Grid>
    )
}

// MUDAR ICONES
export const TRANSFERVALUE = (data) => {
    const { t } = useTranslation()

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-cart_transferencia" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('TRANSFER_VALUE')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {data.data.liquidValue
                        ? data.data.liquidValue?.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                          }) || '-'
                        : ' - '}
                </div>
            </div>
        </Grid>
    )
}

export const ANTICIPATIONRATE = (props) => {
    const { t } = useTranslation()
    const { data, programRules } = props

    return (
        <>
            {data?.anticipationFees?.map((item, key) => {
                return (
                    item?.anticipationFeePercentage !== 0 &&
                    item?.anticipationFeePercentage !== null &&
                    item?.anticipationFeePercentage > 0 && (
                        <Grid
                            item
                            xs={12}
                            className="partner__container"
                            key={key}
                        >
                            <i className="icon-cart_taxa_antecipacao" />
                            <div className="partner_wrapper">
                                <div className="partner__label">
                                    {t('ANTICIPATION_RATE')}{' '}
                                    {`(${item?.anticipationFeePercentage}%)`}
                                </div>
                                <div
                                    className="line"
                                    style={{ margin: '0px 20px' }}
                                >
                                    <hr />
                                </div>
                                {/* anticipationFee */}
                                <div className="partner__name">
                                    {!isEmpty(data)
                                        ? numberFormatText(
                                              item?.anticipationFee,
                                              programRules.currencySymbol
                                                  ? programRules.currencySymbol +
                                                        ' '
                                                  : ' ',
                                              2,
                                          )
                                        : ' - '}
                                </div>
                            </div>
                        </Grid>
                    )
                )
            })}
        </>
    )
}

export const TRANSFERRATE = (data, programRules) => {
    const { t } = useTranslation()

    const { transferFeeType, transferFeePercentage, transferFee } = data?.data

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-cart_taxa_transferencia" />
            <div className="partner_wrapper">
                <div className="partner__label">
                    {transferFeeType?.key === '2'
                        ? `${t('TRANSFER_RATE')} (${transferFeePercentage}%)`
                        : `${t('TRANSFER_RATE')} (${transferFee?.toLocaleString(
                              'pt-BR',
                              {
                                  style: 'currency',
                                  currency: 'BRL',
                              },
                          )})`}
                </div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {transferFee?.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </div>
            </div>
        </Grid>
    )
}

export const EVENTDATE = (data) => {
    const { t } = useTranslation()

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-calendario" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('EVENT_DATE')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr style={{ heigt: '0 !important' }} />
                </div>
                <div className="partner__name">
                    {!isEmpty(data) &&
                    data.data.processingDate !== null &&
                    data.data.processingDate !== ''
                        ? moment(data?.data?.processingDate).format('L') +
                          ' - ' +
                          moment(data?.data?.processingDate).format('HH:mm')
                        : '-- --'}
                </div>
            </div>
        </Grid>
    )
}

export const GRACEDATE = (data) => {
    const { t } = useTranslation()

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-calendario" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('GRACE_DATE')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {!isEmpty(data) &&
                    data.data.graceDate !== null &&
                    data.data.graceDate !== ''
                        ? moment(data.data.graceDate).format('L') +
                          ' - ' +
                          moment(data.data.graceDate).format('HH:mm')
                        : '-- --'}
                </div>
            </div>
        </Grid>
    )
}

export const OPERATION = (data) => {
    const { t } = useTranslation()
    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-carteira_operacoes" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('OPERATION')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {!isEmpty(data) &&
                    data.data.sourceType !== null &&
                    data.data.sourceType !== '' ? (
                        <Styled.ExpiredBox>
                            {data.data?.award
                                ? `${data.data.award.value}`
                                : t(data.data.sourceType.value)}
                        </Styled.ExpiredBox>
                    ) : (
                        '-- --'
                    )}
                </div>
            </div>
        </Grid>
    )
}

export const EXPIRED = (data) => {
    const { t } = useTranslation()
    const { status, sourceType, transactionType } = data?.data

    return (
        transactionType?.key !== '6' && (
            <Grid item xs={12} className="partner__container">
                <i className="icon-carteira_status_operacao" />
                <div className="partner_wrapper">
                    <div className="partner__label">{t('FIELD_STATUS')}</div>
                    <div className="line" style={{ margin: '0px 20px' }}>
                        <hr />
                    </div>
                    <div className="partner__name">
                        {!isEmpty(data) &&
                        status !== null &&
                        sourceType !== '' ? (
                            <Styled.ExpiredBox>
                                {t(status?.value)}
                            </Styled.ExpiredBox>
                        ) : (
                            '-- --'
                        )}
                    </div>
                </div>
            </Grid>
        )
    )
}

export const VALUEGIFTCARD = (data) => {
    const { t } = useTranslation()
    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-carteira_status_operacao" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('Valor total do vale')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {
                        // !isEmpty(data) &&
                        data?.data.value !== null && data?.data.value !== '' ? (
                            <Styled.ExpiredBox>
                                {data.data.value.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </Styled.ExpiredBox>
                        ) : (
                            '-- --'
                        )
                    }
                </div>
            </div>
        </Grid>
    )
}

export const PURCHASEVALUE = (data) => {
    const { t } = useTranslation()
    const [programRules, setProgramRules] = useState([])

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-carteira_valor_compra" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('PURCHASE_VALUE')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {!isEmpty(data) && data !== null && data !== ''
                        ? data.data?.value.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                          })
                        : '-- --'}
                </div>
            </div>
        </Grid>
    )
}

export const DATEOFOPERATION = (data) => {
    const { t } = useTranslation()
    const { transactionDate } = data.data

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-calendario" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('DATE_OF_OPERATION')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {moment(transactionDate).format('L') +
                        ' - ' +
                        moment(transactionDate).format('HH:mm')}
                </div>
            </div>
        </Grid>
    )
}

export const EXPIREDATE = (data) => {
    const { t } = useTranslation()

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-calendario" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('EXPIRE_DATE')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div
                    className="partner__name"
                    style={{
                        color: data.data.status.key === '2' ? '#F44336' : '',
                    }}
                >
                    {!isEmpty(data) &&
                    data.data.expirationDate !== null &&
                    data.data.expirationDate !== ''
                        ? moment(data.data.expirationDate).format('L') +
                          ' - ' +
                          moment(data.data.expirationDate).format('HH:mm')
                        : '-- --'}
                </div>
            </div>
        </Grid>
    )
}

export const PURCHASEDATE = (data) => {
    const { t } = useTranslation()

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-calendario" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('PURCHASE_DATE')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {!isEmpty(data) &&
                    data.data.transactionDate !== null &&
                    data.data.transactionDate !== ''
                        ? moment(data.data.transactionDate).format('L') +
                          ' - ' +
                          moment(data.data.transactionDate).format('HH:mm')
                        : '-- --'}
                </div>
            </div>
        </Grid>
    )
}
export const RefundDate = (data) => {
    const { t } = useTranslation()

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-calendario" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('REFUND_DATE')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {!isEmpty(data) &&
                    data.data.refundDate !== null &&
                    data.data.refundDate !== ''
                        ? moment(data.data.refundDate).format('L')
                        : '-- --'}
                </div>
            </div>
        </Grid>
    )
}

export const DATEOFUSE = (props) => {
    const { t } = useTranslation()
    const { data } = props

    return (
        <Grid item xs={12} className="partner__container">
            <i className="icon-calendario" />
            <div className="partner_wrapper">
                <div className="partner__label">{t('DATE_OF_USE')}</div>
                <div className="line" style={{ margin: '0px 20px' }}>
                    <hr />
                </div>
                <div className="partner__name">
                    {!isEmpty(data) &&
                    data.useDate !== null &&
                    data.useDate !== ''
                        ? moment(data.useDate).format('DD/MM/yyyy') +
                          ' - ' +
                          moment(data.useDate).format('HH:mm')
                        : '-- --'}
                </div>
            </div>
        </Grid>
    )
}

export const ViewPurchase = (data) => {
    const { t } = useTranslation()
    const history = useHistory()

    const handleButtonClick = () => {
        if (data?.data?.negotiation) {
            history.push(`/negotiationDetails/${data?.data?.negotiation?.id}`)
        } else {
            history.push(`/shopHistory/detail/${data?.data?.invoiceId}`)
        }
    }

    return (
        <button
            className="partner__button"
            onClick={handleButtonClick}
            style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
            }}
        >
            <MuiStack direction="row" alignItems="center" spacing={1}>
                <ViewCartIcon size={35} color="#21409A" />
                <MuiTypography
                    sx={{ cursor: 'pointer' }}
                    color={GetThemeColorFromStorage()?.footer?.backgroundColor}
                    fontSize="14px"
                >
                    Detalhar movimentação
                </MuiTypography>
            </MuiStack>
        </button>
    )
}
