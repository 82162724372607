import React from 'react'
import { GridItem, MuiTypography } from '../../../../components/storybook'
import { formatTime } from '../../functions'

export function Warning({ generalRules }) {
    return (
        <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            mt={3}
            sx={{
                paddingLeft: '0px !important',
            }}
        >
            <MuiTypography color="#AEAEAE" fontSize="14px">
                As solicitações de transferência estão permitidas entre:{' '}
                {formatTime(generalRules?.startTransferPeriod)}h e{' '}
                {formatTime(generalRules?.endTransferPeriod)}h e serão
                processados em{' '}
                <strong>
                    {generalRules?.daysToProcessTransfer} dias úteis
                </strong>
                .
            </MuiTypography>
            <MuiTypography color="#AEAEAE" fontSize="14px">
                Seu limite de transferência hoje é de{' '}
                {generalRules?.dailyTransferLimit}.
            </MuiTypography>
        </GridItem>
    )
}
