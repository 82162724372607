/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// @ Components and Material UI
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import { Tooltip } from '@mui/material'
import SearchForm from './SearchForm'

// @ Utils and Styles
import * as S from './styles'
import { formatMessage, getColorStatus } from './SearchForm/utils'
import { getLogs } from './utils'

// @ Requests
import { GetAllTransactionLogs } from '../../../../../../redux/api/paymentGateway'



const TabPanel = ({ tab }) => {
	const tableRef = useRef()

	const [logs, setLogs] = useState('')
	const [page, setPage] = useState(10)

	const spliceMessage = (message, createdAt, name) => {
		return (
			<S.Message>
				<div
					dangerouslySetInnerHTML={{
						__html: formatMessage(message, name, createdAt),
					}}
				/>
			</S.Message>
		)
	}

	return (
		<>
			<SearchForm tableRef={tableRef} tab={tab} getLogs={getLogs} />
			<S.Table>
				<MaterialTable
					tableRef={tableRef}
					title={false}
					options={{
						sorting: true,
						emptyRowsWhenPaging: false,
						toolbar: false,
						actionsColumnIndex: -1,
						search: false,
						draggable: false,
						pageSize: page,
						pageSizeOptions: [10, 20, 30, 40, 50, 100],
					}}
					localization={{
						body: {
							emptyDataSourceMessage: (
								<S.EmptyTable>
									<i
										className="icon-ico_information"
										style={{ color: '#B4B4B4' }}
									/>
									<span>Nenhum registro para exibir</span>
								</S.EmptyTable>
							),
						},

						pagination: {
							labelRowsSelect: 'linhas',
							labelDisplayedRows: '{from}-{to} de {count}',
							firstTooltip: 'Primeira página',
							previousTooltip: 'Página anterior',
							nextTooltip: 'Próxima página',
							lastTooltip: 'Última página',
						},
					}}
					columns={[
						{
							title: 'Log',
							cellStyle: {
								minWidth: '100px',
								maxWidth: '100px',
							},
							headerStyle: {
								minWidth: '100px',
								maxWidth: '100px',
							},
							// eslint-disable-next-line react/display-name
							render: rowData => (
								<S.Description>
									<S.AlignIconStatus>
										<Tooltip
											title={
												rowData.success
													? 'Sucesso'
													: 'Negada'
											}
											placement="top"
										>
											<S.IconStatus
												style={{
													backgroundColor: getColorStatus(rowData.status),
												}}
											/>
										</Tooltip>
									</S.AlignIconStatus>
									<div>
										{spliceMessage(
											rowData.description,
											rowData?.realizedAt,
											rowData.userName,
										)}
									</div>
								</S.Description>
							),
						},
					]}
					data={query =>
						new Promise((resolve, reject) => {
							const gtlogs = getLogs(query, logs)
							GetAllTransactionLogs(gtlogs).then(res => {
								setLogs(res?.data?.results)
								res && res?.data?.results
									? resolve({
										data: res?.data?.results,
										page:
											res?.data?.currentPage -
											1,
										totalCount:
											res?.data?.recordCount,
									})
									: resolve({
										data: [],
										page: 0,
										totalCount: 0,
									})
							})

						})
					}
				/>
			</S.Table>
		</>
	)
}

TabPanel.propTypes = {
	tab: PropTypes.number,
}

export default TabPanel
