import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const Container = styled.div`
    .section-part {
        transform: translateY(3px) !important;
    }
`
export const NotificationContainer = styled(Grid)`
    .main-card,
    .button-box {
        /* max-width: 1300px; */
    }
    .button-box {
        padding: 0 20px;
    }
    .main-card {
        margin: 10px 0;
    }

    .card-container {
        cursor: pointer;
    }
    .card-container + .card-container {
        padding: 15;
    }

    .pagination-container {
        border: 1px solid #ccc;
        padding: 10px 0;
        border-right: none;
        border-left: none;
        margin-bottom: 20px;
    }
`

export const CardContainer = styled(Grid)`
    .header-img {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 1px solid #b4b4b4;
        border-bottom: none;
        background-image: url(${({ img }) => img});
        width: 100%;
        height: 206px;
        background-repeat: no-repeat;
        background-position: center center;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
        background-size: cover;
    }

    .read-column {
        font-size: 12px;
        font-weight: 400;
        color: ${GetThemeColorFromStorage()?.navigation?.buttons?.textColor};
        border: 1px solid #b4b4b4;
        border-bottom: none;
        border-top: none;
        padding: 3px 15px;
        justify-content: space-between;
        background-color: ${({ isread }) =>
            isread
                ? '#b2b2b2'
                : GetThemeColorFromStorage()?.navigation?.buttons
                      ?.elementsColor};
        svg {
            margin-right: 10px;
        }
    }

    .read-line {
        font-size: 14px;
        color: ${({ isread }) =>
            isread
                ? '#9d9d9d'
                : GetThemeColorFromStorage()?.titles?.highlight?.textColor};
        height: 84px;
        max-height: 65%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: ${({ isread }) => (isread ? 400 : 700)};
    }

    .text-content {
        background-color: #ffffff;
        height: 110px;
        padding: 10px 15px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: 1px solid #b4b4b4;
        border-top: none;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    }

    .date-small {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
    }

    .link {
        margin-top: 5px;
        font-size: 12px;
        color: #29479d;
        cursor: pointer;
        &:hover {
            font-weight: bold;
        }
    }
`
