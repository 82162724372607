import axios from 'axios'

// GET
export const GetPartners = (name, cnpj, page) =>
    axios
        .get(`/Partner?name=${name}&cpnj=${cnpj}&page=${page}`)
        .then((response) => response.data)

// GET
export const GetPartnerByConsumer = () =>    axios
        .get(`/ConsumerWallet/GetPartnerByConsumer`)
        .then((response) => response)
		.catch((error) => error?.response)

export const PartnerValidatePartner = ({ password }) => {
    const obj = {
        password,
    }
    return axios
        .post(`/Partner/ValidatePartner`, obj)
        .then((response) => response.data)
}
