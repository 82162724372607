import React, { useEffect, useState } from 'react'
import { SearchService } from '../Services'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'
import { useForm } from 'react-hook-form'

export default function useSearch() {
    const { id } = useParams()
    const { t } = useTranslation()
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { isValid: formIsValid },
    } = useForm()

    const watchfields = watch()

    const [theme, setTheme] = useState(GetThemeColorFromStorage())

    const [isLoadingData, setIsLoadingData] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [postSuccess, setPostSuccess] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [dataSurveyDetails, setDataSurveyDetails] = useState([])
    const [errorMessage, setErrorMessage] = useState('')

    async function GetSurveyDetails() {
        setIsLoadingData(true)
        const response = await SearchService.getSurveyDetails(id)
        if (response.errors.length > 0) {
            setErrorMessage(t(response?.errors[0]?.message))
        } else {
            setDataSurveyDetails(response?.data)
        }
        setIsLoadingData(false)
    }
    async function PostSurveyDetails(formData) {
        const response = await SearchService.postSurveyDetails(formData)
        const error = response?.errors[0]?.message.split(' - ')

        if (response.errors.length > 0) {
            setPostSuccess(false)
            setErrorMessage(t(error[1]) || 'Error ao enviar a mensagem!')
        } else {
            setPostSuccess(true)
        }

        return response?.data
    }

    function handleLoader() {
        setIsLoading(!isLoading)
    }

    const onSubmit = (data) => {
        const quizResponses = []
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                quizResponses.push({
                    questionId: key,
                    responses: value,
                })
            } else {
                quizResponses.push({
                    questionId: key,
                    responses: [value],
                })
            }
        })

        const formData = {
            surveyId: id,
            quizResponses: quizResponses,
        }
        // handleLoader()

        return PostSurveyDetails(formData)
    }

    useEffect(() => {
        const checkRequiredFields = () => {
            return dataSurveyDetails?.quiz?.every((item) => {
                if (item.required) {
                    const value = watchfields[item.id]
                    return value && value?.length > 0
                }
                return true
            })
        }

        setIsValid(formIsValid && checkRequiredFields())
    }, [watchfields, formIsValid, dataSurveyDetails])

    return {
        isLoadingData,
        t,
        dataSurveyDetails,
        GetSurveyDetails,
        theme,
        setTheme,
        handleSubmit,
        control,
        handleSubmit,
        watchfields,
        setValue,
        PostSurveyDetails,
        onSubmit,
        postSuccess,
        errorMessage,
        setErrorMessage,
        isValid,
        handleLoader,
        isLoading,
    }
}
