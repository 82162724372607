import styled from 'styled-components/macro'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

export const container = styled.div`
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.elementsColor};
        opacity: 1;
    }
    .MuiSwitch-thumb {
        background-color: #eeeeee;
    }
`

export const bodyStyle = styled.div`
    margin-top: -4px;
`

export const showMessage = styled.div`
    margin-bottom: 0px;
    color: #878787;
    font-size: 15px;
`
