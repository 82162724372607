export const getErrors = (___this) => ({
    user_is_admin: {
        onConfirm:() => { ___this.setState({ alert: false }) },
        confirmBtnText: `Ok`,
        content: `De acordo com os Termos de Uso, este CPF não pode ser um beneficiário do Programa de Fidelidade`,
    },
    email_does_not_validated: {
        onConfirm:() => {
            localStorage.setItem( 'cpfUser',
                ___this.state.login.replace(/[^\d]+/g, '',)
            );
            ___this.sendEmailConfirmationToken().then(
                () => ___this.props.history.push('/AuthenticateAccess'),
            )
        },
        confirmBtnText: `Enviar e-mail`,
        content: ___this.props.t('emailNotValidatedMessage'),
    },
    phone_number_does_not_validated: {
        onConfirm:() => { ___this.sendSMSActivationCode().then(() => ___this.props.history.push('/ConfirmRegistration'))},
        confirmBtnText: `Continuar`,
        content: ___this.props.t('SmsNotValidatedMessage'),
    },
})