import React, { Fragment } from 'react'
import { ContainerFragment } from './styles';

const TransferRequest_TaxesWarnings = ({
	choosenPaymentOption,
	antecipationFees
}) => {

	const antecipationFeesNumber = antecipationFees.length;
	const haveAntecipationFee = _ => antecipationFeesNumber > 0;
	const haveOnlyOneAntecipationFee = _ => antecipationFeesNumber === 1;

	const rightDescription = () => {
		if (haveAntecipationFee) {
			if (haveOnlyOneAntecipationFee) {
				return `Para essa transação será cobrada uma taxa de transferência e outra por antecipação.`;
			} else {
				return `Para essa transação será cobrada uma taxa de transferência e outras ${antecipationFeesNumber} taxas por antecipação.`;
			}
		}
		return `Para essa transação será cobrada uma taxa de transferência.`
	}

	const textDescription = () => {
		if (haveAntecipationFee) {
			if (haveOnlyOneAntecipationFee) {
				return `(*Essa taxa será aplicada apenas quando o valor tranferido for recebido de uma premiação por antecipação).`;
			}
		}
	}

	return (
		<Fragment>
			{
				<ContainerFragment>
					<div>{rightDescription()}</div>
					<div className="info">{textDescription()}</div>
				</ContainerFragment>
			}
		</Fragment>
	)
}

export default TransferRequest_TaxesWarnings;
