import React from 'react'
import useTransferValue from './Hooks/TransferValue'
import TemplatePage from './TemplatePage'

export default function TransferChild() {
    const hookParams = useTransferValue()

    const sharedProps = {
        ...hookParams,
    }

    return (
        <>
            <TemplatePage {...sharedProps} />
        </>
    )
}
