import React from 'react'
import Slider from 'react-slick'
import { Container, PrevArrow, NextArrow } from './styles'
import style from '../../home.module.scss'
import { MetricsBannersCount } from 'redux/api/banners'

export default function SimpleSlider({ bannerInfo, bannersDisplayTime }) {

    function handleLink(link, id) {
        if (link) {
            MetricsBannersCount(id, false, true)
            if (link.indexOf('http://') ===0 || link.indexOf('https://') ===0) {
                window.open(link, '_blank')
                return;
            }
            window.open(`https://${link}`, '_blank')
        }
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: bannersDisplayTime * 1000,
        fade: true,
        pauseOnHover: false,
        cssEase: 'linear',
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    }

    return (
        <Container>
            <Slider {...settings}>
                {bannerInfo.map((item, index) => (
                    <div key={index}>
                        <div onClick={() => handleLink(item[1], item[2])}>
                            <img
                                src={item[0]}
                                alt="img"
                                className={style.maxImage}
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </Container>
    )
}
