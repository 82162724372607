import * as actionTypes from './actionsTypes'
import * as API from '../api/invoice'

export const GetShopHistoryData = (dateFrom, dateTo, documentId, partnerId, searchType, pageIndex, pageSize) => {
    return dispatch => (
        API.GetShopHistoryData(dateFrom, dateTo, documentId, partnerId, searchType, pageIndex, pageSize).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_SHOP_HISTORY_DATA,
            invoiceData: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.FETCH_SHOP_HISTORY_FAILED,
            invoiceDataFailed: error
        };
    };
};

// #2
export const GetInvoicesCreditSource = (consumerId, invoiceKey, invoiceNumber, invoiceData, invoiceValue) => {
    return dispatch => (
        API.GetInvoicesCreditSource(consumerId, invoiceKey, invoiceNumber, invoiceData, invoiceValue).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_INVOICE_CREDIT_SOURCE,
            invoiceCreditSource: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.FETCH_INVOICE_CREDIT_SOURCE_FAILED,
            invoiceCreditSourceFailed: error
        };
    };
};

//#3
export const MergeCreditSource = (invoiceId, consumerId) => {
    return dispatch => (
        API.MergeCreditSource(invoiceId, consumerId).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(failed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_MERGE_CREDIT_SOURCE,
            mergedCreditSource: data
        };
    };

    function failed(error) {
        return {
            type: actionTypes.FETCH_MERGE_CREDIT_FAILED,
            mergedCreditSourceFailed: error
        };
    };
};


export const GetInvoiceById = (id) => {
    return dispatch => (
        API.GetInvoiceById(id).then(response => {
            dispatch(fetchInvoicById(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchInvoiceByIdFailed(error));
        })
    );

    function fetchInvoicById(data) {
        return {
            type: actionTypes.FETCH_INVOICE_BY_ID,
            invoiceById: data
        };
    };

    function fetchInvoiceByIdFailed(error) {
        return {
            type: actionTypes.FETCH_INVOICE_BY_ID_FAILED,
            invoiceByIdFailed: error
        }
    }
}
