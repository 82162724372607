import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Grid, Fade } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import { ModalContainer, PaperContainer } from './styles'

function ConfirmModal({
    children,
    className,
    closeByClick,
    maxWidth,
    minWidth,
    onCancel,
    onConfirm,
    open,
    padding,
    shadow,
    standardFocus,
    t,
    text,
}) {
    return (
        <ModalContainer
            open={open}
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            maxwidth={maxWidth}
            minwidth={minWidth}
            disableEnforceFocus
        >
            <Fade in={open} unmountOnExit>
                <PaperContainer padding={padding} maxwidth={maxWidth}>
                    <GridContainer
                        xs={12}
                        sm={12}
                        style={{ margin: '30px 20px 30px 2px' }}
                    >
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <i
                                className="icon-alerta_modal"
                                style={{
                                    color: germiniDefaultColors[11],
                                    fontSize: '80px',
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
                        <div
                            style={{
                                hidden: true,
                                fontSize: '13px',
                                fontWeight: 'bold',
                            }}
                        >
                            {text}
                        </div>
                    </GridItem>
                    <GridContainer
                        style={{
                            padding: '10px 0 0 20px',
                            marginBottom: '30px',
                        }}
                    >
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{ textAlign: 'center' }}
                        >
                            <Button
                                size="sm"
                                variant="contained"
                                color={
                                    standardFocus.toString() ==='no'
                                        ? 'greenButtonColor'
                                        : 'greenBorderButtonColor'
                                }
                                style={{
                                    marginRight: '5px',
                                    padding: '8px 35px 8px 35px',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                }}
                                onClick={onCancel}
                            >
                                {t('BUTTON_NO')}
                            </Button>{' '}
                            <Button
                                size="sm"
                                variant="contained"
                                color={
                                    standardFocus.toString() === 'yes'
                                        ? 'greenButtonColor'
                                        : 'greenBorderButtonColor'
                                }
                                style={{
                                    marginLeft: '5px',
                                    padding: '8px 35px 8px 35px',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                }}
                                onClick={onConfirm}
                            >
                                {t('BUTTON_YES')}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </PaperContainer>
            </Fade>
        </ModalContainer>
    )
}

ConfirmModal.propTypes = {
    children: PropTypes.element,
    open: PropTypes.bool,
    closeByClick: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
    standardFocus: PropTypes.string,
    text: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

ConfirmModal.defaultProps = {
    className: '',
    padding: '',
    shadow: '',
    standardFocus: 'no',
    children: null,
    open: false,
    closeByClick: null,
    maxWidth: '',
    minWidth: '',
    text: '',
    onConfirm: null,
    onCancel: null,
}

export default withTranslation()(ConfirmModal)
