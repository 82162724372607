import styled from 'styled-components'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

export const Container = styled.div`
    .GridContainer-grid-170 {
        margin: 0 !important;
        width: 100% !important;
    }
    .GridItem-grid-399,
    .ecHOuH {
        padding: 0 !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }
`
export const EmptyTable = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    color: #b4b4b4;

    i {
        padding-top: 1em;
        font-size: 3em;
        cursor: pointer;
        margin: auto 18px;
        color: #b4b4b4;
    }

    .icon-informacao:before {
        font-size: 1.5em;
    }

    span {
        font-weight: 900;
        margin: 25px 0 12px;
    }
`

export const DivDate = styled.div`
    width: 50%;
    .icon-data {
        font-size: 20px;
        margin-right: -8px;
        color: ${GetThemeColorFromStorage()?.visual?.icons?.color};
    }
    .rdtPicker {
        .dow,
        th.rdtSwitch,
        th.rdtNext,
        th.rdtPrev,
        .rdtTimeToggle {
            color: ${GetThemeColorFromStorage()?.titles?.secondary?.textColor};
        }
    }
    .rdtDay {
        &.rdtToday.rdtActive,
        &.rdtActive,
        &.rdtActive:hover {
            background-color: ${GetThemeColorFromStorage()?.titles?.secondary
                ?.textColor} !important;
        }
    }
    .MuiFormControl-marginDense {
        margin: 0 !important;
        width: 100%;
    }

	@media only screen and (max-width: 400px) {
		width: 100%;
	}
`
export const HeaderContain = styled.div`
    align-items: center;
    display: flex;
    margin-top: 12px;
    padding-right: 15px;
`

export const SectionBox = styled.div`
    width: 100%;
`

export const BntGiftFriendContain = styled.div`
    margin-top: -12px;
    padding-left: 15px;
`
export const FilterBox = styled.div`
    padding: 20px !important;
    width: 100%;
`
export const InputBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media only screen and (min-width: 800px) {
        flex-wrap: nowrap;
    }
`

export const InputPeriod = styled.div`
    width: 100%;
    @media only screen and (min-width: 600px) {
        margin-bottom: 15px;
    }
    @media only screen and (min-width: 800px) {
        margin-bottom: 0;
        padding-right: 25px;
        width: 25%;
    }

    .MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 3px;
    }

    .MuiAutocomplete-popperDisablePortal {
		li:hover {
			color: #ffffff !important;
			background-color: ${GetThemeColorFromStorage()?.navigation?.buttons
                ?.backgroundColor} 
		}
	}
`
export const dateBox = styled.div`
    .rdt {
        position: relative;
        margin-right: 0;
    }

    display: flex;
    gap: 25px;
    padding: 0px;
    margin: 15px 0;
    width: 100%;
    @media only screen and (min-width: 600px) {
        margin: 0;
        width: 50%;
    }
    @media only screen and (min-width: 800px) {
        width: 35%;
    }

	@media only screen and (max-width: 400px) {
		flex-direction: column;
	}
`
export const inputDestiny = styled.div`
    width: 100%;
    .MuiFormControl-marginDense {
        margin: 0 !important;
    }
    @media only screen and (min-width: 600px) {
        padding-left: 25px;
        width: 50%;
    }
    @media only screen and (min-width: 800px) {
        width: 40%;
    }
`

export const BtnFilterContain = styled.div`
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 20px;
    width: 100%;
`

export const ContainCards = styled.div`
    display: flex;
    gap: 20.5px;
    flex-wrap: wrap;
    justify-content: space-between;
    .voucherCard_card__1RLg8 {
        min-width: auto;
    }
`

export const CardContain = styled.div`
    width: 100%;
    @media only screen and (min-width: 500px) {
        width: 47.79%;
    }

    @media only screen and (min-width: 750px) {
        padding: 0;
        width: 31.4%;
    }
    @media only screen and (min-width: 960px) {
        padding: 0;
        width: 31%;
    }
`

export const LabelInput = styled.label`
    font-weight: 400;
    position: absolute;
    top: -7px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    font-size: 1rem;
    z-index: 3;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1;
    letter-spacing: 0.00938em;
    transform: translateX(-10px) scale(0.75);
    pointer-events: none;
    padding: 0px 4px 0px 4px;
    background-color: #fff;
    left: -3px;
`
