import styled from 'styled-components'

export const Footer = styled.footer`
    background-color: ${({ themeColor }) =>
        themeColor?.footer?.backgroundColor};
    color: ${({ themeColor }) => themeColor?.footer?.textColor};
    z-index: auto;

    a:link {
        color: ${({ themeColor }) => themeColor?.footer?.textColor};
    }
`
export const BoxFooter = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
export const Box = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1150px;
    z-index: 5;
    margin-top: -35px;
    font-size: 0.8rem;
    opacity: 0.7;
`
