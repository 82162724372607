import { slice, isEmpty } from 'lodash'
import React from 'react'
import { RegistrationValid, replaceCpfCnpj } from '../../../helpers/utils'

export const messageError =
    'It is mandatory that the state registration and zip code are from the same state.'

export const errorTreatment = ({ t, errors, setState, value }) => {
    if (
        errors.find(
            (error) =>
                // error.message.includes('ZipCode') ||
                // error.message.includes('DateOfBirth') ||
                error.message.includes('2FA - NOT_FOUND') ||
                error.message.includes('2FA - INVALID_VALUE'),
        )
    )
        return setState((prevState) => ({
            ...prevState,
            modalError: {
                open: true,
                content: (
                    <>
                        <div>
                            <p>{t('WE_FOUND_IN_YOUR_PRE_REGISTRATION')}</p>
                        </div>
                        <ul>
                            {errors
                                .filter(
                                    (x) =>
                                        // x.message.includes('ZipCode') ||
                                        // x.message.includes('DateOfBirth') ||
                                        x.message.includes('2FA - NOT_FOUND') ||
                                        x.message.includes(
                                            '2FA - INVALID_VALUE',
                                        ),
                                )
                                .map((error, index) => [
                                    <li>
                                        <p
                                            key={index}
                                            style={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            {error?.message ===
                                                'DateOfBirth - NOT_FOUND' &&
                                            replaceCpfCnpj(value)?.length > 11
                                                ? t('Foundation_NOT_FOUND')
                                                : t(error.message)}
                                        </p>
                                    </li>,
                                ])}
                        </ul>
                        <div>
                            <p>{t('FOR_UPDATE_YOUR_REGISTRATION_DATA')}</p>
                        </div>
                    </>
                ),
            },
        }))
}

const MASKS = {
    AC: [/(\d{2})(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3/$4-$5'],
    AL: [/(\d{9})/, '$1'],
    AP: [/(\d{9})/, '$1'],
    AM: [/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'],
    BA: [/(\d{6})(\d{2})/, '$1-$2'],
    CE: [/(\d{6})(\d{2})/, '$1-$2'],
    DF: [/(\d{6})(\d{2})/, '$1-$2'],
    ES: [/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1.$2.$3/$4'],
    GO: [/(\d{1})(\d{4})(\d{3})(\d{3})/, '$1.$2.$3.$4'],
    MA: [/(\d{9})/, '$1'],
    MT: [/(\d{6})(\d{3})/, '$1-$2'],
    MS: [/(\d{9})/, '$1'],
    MG: [/(\d{3})(\d{3})(\d{3})(\d{4})/, '$1.$2.$3/$4'],
    PA: [/(\d{2})(\d{6})(\d{1})/, '$1-$2-$3'],
    PB: [/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'],
    PR: [/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'],
    PE: [/(\d{2})(\d{1})(\d{8})/, '$1.$2.$3'],
    PI: [/(\d{9})/, '$1'],
    RJ: [/(\d{2})(\d{3})(\d{2})(\d{1})/, '$1.$2.$3-$4'],
    RN: [/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'],
    RS: [/(\d{3})(\d{7})/, '$1/$2'],
    RO: [/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'],
    RR: [/(\d{9})/, '$1'],
    SC: [/(\d{3})(\d{3})(\d{3})/, '$1.$2.$3'],
    SP: [/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1.$2.$3.$4'],
    SE: [/(\d{3})(\d{5})(\d{1})/, '$1.$2-$3'],
    TO: [/(\d{9})/, '$1'],
}

export const MASKS_IE = (state, value) => {
    const regex = MASKS?.[state]?.[0]
    const mask = MASKS?.[state]?.[1]

    if (value && regex && mask && slice) {
        return value.replace(regex, mask)
    }

    return value
}

export const MASKSCNPJ = (value) => {
    const regex = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
    const mask = '$1.$2.$3/$4-$5'

    if (value && regex && mask && slice) {
        return value.replace(regex, mask)
    }

    return value
}

export const getLabel = (t, value) => {
    if (replaceCpfCnpj(value)?.length <= 11) {
        return t('TITLE_REGISTER_BIRTHDAY')
    }

    return t('DT_BASE')
}

export const onBlurStateRegistration = async (
    watch,
    setError,
    clearErrors,
    t,
    setTimerStarted,
) => {
    try {
        const stateRegistration = await RegistrationValid(watch())
        if (stateRegistration?.status === 200) {
            const { data } = stateRegistration
            if (data?.data) {
                clearErrors('stateRegistration')
            } else {
                const message = stateRegistration?.data?.errors[0]?.message
                setError('stateRegistration', {
                    type: 'manual',
                    message:
                        message === messageError
                            ? t('RegistrationZipState')
                            : t(message),
                })
            }
        }
    } catch (error) {
        console.log('error', error)
    } finally {
        setTimerStarted(false)
    }
}

export const EmailIsValid = (mensagem, setError) => {
    console.log('mensagem', mensagem)
}

export const CellPhoneIsValid = (mensagem, setError) => {}

export const ErrorsValid = [
    {
        field: 'Email',
        message: '',
    },
    {
        field: 'PhoneNumber2',
        message: '',
    },
]
