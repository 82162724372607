import moment from 'moment'
import * as Yup from 'yup'

export const defaultValues = {
    invoiceData: '',
    invoiceKey: '',
    invoiceNumber: '',
    purchaseValue: '',
    radioValue: 'op1',
}

export const validationSchema = Yup.object({
    purchaseValue: Yup.string().when('radioValue', {
        is: 'op1',
        then: Yup.string().required('Obrigatório'),
        otherwise: Yup.string(),
    }),
    invoiceData: Yup.string()
        .when('radioValue', {
            is: 'op1',
            then: Yup.string().required('Obrigatório'),
            otherwise: Yup.string(),
        })
        .test('invoiceData', 'Data inválida', (value) => {
            if (value !== '') {
                return moment(value).isValid()
            }
            return true
        }),
    invoiceNumber: Yup.string().when('radioValue', {
        is: 'op1',
        then: Yup.string().required('Obrigatório'),
        otherwise: Yup.string(),
    }),
    invoiceKey: Yup.string().when('radioValue', {
        is: 'op2',
        then: Yup.string().required('Obrigatório'),
        otherwise: Yup.string(),
    }),
})

export const  statemenStyle = {
	inputDate: {
		height: '3px',
		fontSize: '16px',
		width: '100%',
	},
	labelTexF: {
		lineHeight: '0 !important',
		fontSize: '15px',
	},
}
