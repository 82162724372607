import React, { Fragment } from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import MaskedInput from 'react-text-mask'
import { withTranslation } from 'react-i18next'

import {
    CircularProgress,
    FormControlLabel,
    Icon,
    InputAdornment,
    MenuItem,
    Radio,
    Tooltip,
} from '@material-ui/core'

import withStyles from '@material-ui/core/styles/withStyles'
import { FiberManualRecord, CheckCircle } from '@material-ui/icons'

import AvatarUpload from 'components/CustomUpload/AvatarUpload.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import { CssTextField } from 'components/CssTextField/CssTextField'

import defaultAvatar from 'assets/img/fazendeiro.png'

// style
import styles from '../profile.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx'

import PageTopPart from '../../../PageTopPart/PageTopPart.jsx'

// UTILS
import compose from 'utils/compose'

import * as S from './styles'
import { CustomCheckbox } from '../../../../components/CustomCheckbox'
import {
    CPFOrCNPJ,
    PhoneMaskCustom,
    CEPMaskCustom,
    CPFMaskCustom,
    CPFCNPJMaskCustom,
    DateMaskCustom,
} from './utils'

class ProfileForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            disabledAddress: false,
            timerStarted: false,
        }
        this.typingTimer = null

        if (localStorage.getItem('i18nextLng') != null) {
            moment.locale(localStorage.getItem('i18nextLng'))
        } else {
            moment.locale('pt-BR')
        }
    }

    render() {
        const {
            t,
            classes,
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleEdit,
            handleStateChange,
            handleCancel,
            disabledForm,
            SearchZipcodeData,
            selectListState,
            selectListCity,
            selectListCountry,
            indicationCode,
            // isValid,
            setFieldTouched,
            setFieldValue,
        } = this.props

        const isCPFBiggerThan = (_num = 11) =>
            values &&
            values.cpf !== null &&
            values &&
            values.cpf !== undefined &&
            values &&
            values.cpf.length === _num

        const { disabledAddress } = this.state

        const change = (name, e) => {
            switch (name) {
                case 'dateOfBirth':
                    if (moment(e).isValid() && (!e.length || e.length === 10)) {
                        setFieldValue(name, e, true)
                    } else {
                        setFieldValue(name, '', true)
                    }
                    return
                case 'stateId':
                    e.persist()
                    handleStateChange(name, e.target.value)
                    setFieldValue('cityId', '')
                    break
                case 'UF':
                    e.persist()
                    handleStateChange(name, e.target.value)
                    break
                case 'countryId':
                    e.persist()
                    handleStateChange(name, e.target.value)
                    break
                case 'pictureUrl':
                    setFieldValue(name, '')
                    return
                case 'zipCode':
                    e.persist()
                    if (e.target.value.replace('-', '').trim().length === 8)
                        searchZipcode(e.target.value)
                    break
                default:
                    e.persist()
            }

            if (e && e.target && e.target.value)
                if (e.target.value.length > 250) {
                    e.target.value = e.target.value.substring(0, 250)
                }

            handleChange(e)
            setFieldTouched(name, true, false)
        }

        const handleZipcodeClick = (e) => {
            if (values && values.zipCode) {
                var str = values && values.zipCode.replace(/\s/g, '').trim()
                var index = str.length < 6 ? str.length - 1 : str.length
                e.target.selctionStart = index
                e.target.selectionEnd = index
            } else {
                e.target.selctionStart = 0
                e.target.selectionEnd = 0
            }
        }

        const searchZipcode = (zipCode) => {
            const { setFieldValue, setFieldTouched, handleStateChange } =
                this.props
            this.setState({ disabledAddress: true })
            SearchZipcodeData(zipCode).then((response) => {
                if (response) {
                    handleStateChange('stateId', response.stateId)
                    setFieldValue(
                        'location',
                        response.location || values?.location || '',
                        true,
                    )
                    setFieldValue('stateId', response.stateId, true)
                    setFieldValue('cityId', response.cityId, true)
                    setFieldValue('zipCode', response.zipCode, true)
                    setFieldValue(
                        'aditionalInfo',
                        response.aditionalInfo || values?.aditionalInfo || '',
                        true,
                    )
                    setFieldValue(
                        'district',
                        response.district || values?.district || '',
                        true,
                    )

                    setFieldTouched('location', true, false)
                    setFieldTouched('stateId', true, false)
                    setFieldTouched('cityId', true, false)
                    setFieldTouched('zipCode', true, false)
                    setFieldTouched('aditionalInfo', true, false)
                    setFieldTouched('district', true, false)
                }
                this.setState({ disabledAddress: false })
            })
        }

        return (
            <S.ContainerProfileForm
                disabledForm={disabledForm}
                disabled={true}
                isCnpj={!isCPFBiggerThan()}
                RegistrationWaiver={values?.registrationWaiver}
            >
                <PageTopPart
                    formTitle={t('TITLE_PROFILE')}
                    handleEdit={handleEdit}
                    handleSave={handleSubmit}
                    handleCancel={handleCancel}
                    editName={t('BUTTON_EDIT')}
                    cancelName={t('BUTTON_CANCEL')}
                    saveName={t('BUTTON_SAVE')}
                    disabledForm={disabledForm}
                    firstElementMarginTop={20}
                    firstJSXElement={
                        <Fragment>
                            {disabledForm && (
                                <div
                                    className="picture-container-disabled"
                                    style={{ marginBottom: '37px' }}
                                >
                                    <div
                                        className="picture"
                                        style={{ margin: '5px 0 0 0' }}
                                    >
                                        <img
                                            style={{
                                                objectFit: 'cover',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                            className="picture-src"
                                            src={
                                                values &&
                                                values.pictureUrl &&
                                                values &&
                                                values.pictureUrl !== ''
                                                    ? values &&
                                                      values.pictureUrl
                                                    : defaultAvatar
                                            }
                                            alt="..."
                                        />
                                    </div>
                                </div>
                            )}
                            {!disabledForm && (
                                <AvatarUpload
                                    avatar
                                    addButtonProps={{
                                        color: 'success',
                                        round: true,
                                    }}
                                    changeButtonProps={{
                                        color: 'success',
                                        round: true,
                                    }}
                                    removeButtonProps={{
                                        color: 'danger',
                                        round: true,
                                        disabled: disabledForm,
                                    }}
                                    onChange={change.bind(null, 'pictureUrl')}
                                    value={values && values.pictureUrl}
                                />
                            )}
                        </Fragment>
                    }
                />

                {/* #1 - Dados pessoais */}
                <GridContainer>
                    <GridItem
                        xs={12}
                        md={12}
                        style={{ margin: '7px 0', marginBottom: '20px' }}
                    >
                        <b style={{ fontWeight: 500 }}>
                            {CPFOrCNPJ(values.cpf) === 'CNPJ'
                                ? t('FIELD_COMPANY_DATA')
                                : t('FIELD_PERSONAL_DATA')}
                        </b>
                    </GridItem>

                    <GridItem
                        xs={12}
                        md={4}
                        id="margin-bottom-input-item"
                        className="custom-form-control-cnpj"
                    >
                        <CssTextField
                            label={
                                CPFOrCNPJ(values.cpf) === 'CNPJ'
                                    ? 'CNPJ'
                                    : 'CPF'
                            }
                            id="cpf"
                            name="cpf"
                            error={
                                touched && touched.cpf && Boolean(errors.cpf)
                            }
                            value={values && values.cpf}
                            variant="outlined"
                            margin="dense"
                            style={{ width: '90%' }}
                            disabled={true}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                                inputComponent: CPFCNPJMaskCustom,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                        <div className={styles.infoIcon}>
                            <Tooltip
                                arrow
                                id="tooltip-top"
                                title="Esse dado será usado no login do sistema."
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <Icon>info</Icon>
                            </Tooltip>
                        </div>
                    </GridItem>

                    <GridItem
                        xs={12}
                        md={CPFOrCNPJ(values.cpf) === 'CNPJ' ? 6 : 8}
                        style={{ marginBottom: '10px' }}
                    >
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            label={
                                CPFOrCNPJ(values.cpf) === 'CNPJ'
                                    ? t('Nome/Razão Social')
                                    : t('FIELD_REGISTER_NAME')
                            }
                            id="name"
                            name="name"
                            error={
                                touched && touched.name && Boolean(errors.name)
                            }
                            value={values && values.name}
                            variant="outlined"
                            margin="dense"
                            disabled={true}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ marginBottom: '10px' }}
                        className="small-input-date"
                    >
                        <CssTextField
                            className={styles.TextField}
                            label={
                                !isCPFBiggerThan()
                                    ? t('DT_BASE')
                                    : t('TITLE_REGISTER_BIRTHDAY')
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={
                                moment(values && values.dateOfBirth).isValid()
                                    ? moment(
                                          values && values.dateOfBirth,
                                      ).format('L')
                                    : values && values.dateOfBirth
                            }
                            disabled={true}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                                endAdornment: (
                                    <InputAdornment>
                                        <i
                                            className="icon-data"
                                            style={{ fontSize: '1.3rem' }}
                                        />
                                    </InputAdornment>
                                ),
                                inputComponent: DateMaskCustom,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>
                    {values?.cpf?.length > 11 && (
                        <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="small-input-checkbox"
                            id="MuiCheckbox"
                        >
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={Boolean(
                                            values?.stateRegistrationWaiver,
                                        )}
                                        disabled={true}
                                        name="stateRegistrationWaiver"
                                        value={values.stateRegistrationWaiver}
                                    />
                                }
                                label={t('IE_EXEMPT')}
                            />
                        </GridItem>
                    )}
                    {!isCPFBiggerThan() &&
                        values?.stateRegistrationWaiver === false && (
                            <GridItem
                                xs={12}
                                md={3}
                                className="input-rg-ie"
                                id="MuiMargin"
                            >
                                <CssTextField
                                    fullWidth
                                    select
                                    label={
                                        <CustomRequiredField
                                            value={t('UF_STATE_REGISTRATION')}
                                            disabled={true}
                                        />
                                    }
                                    id="UF"
                                    name="UF"
                                    value={values && values.UF}
                                    //onChange={change.bind(null, 'UF')}
                                    variant="outlined"
                                    margin="dense"
                                    disabled={true}
                                    SelectProps={{
                                        IconComponent: (props) => {
                                            return true ? (
                                                ''
                                            ) : (
                                                <span {...props}>
                                                    <svg
                                                        className={
                                                            styles.SelectSvgIcon
                                                        }
                                                        focusable="false"
                                                        viewBox="0 0 24 24"
                                                        aria-hidden="true"
                                                        role="presentation"
                                                    >
                                                        <path d="M7 10l5 5 5-5z"></path>
                                                    </svg>
                                                </span>
                                            )
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.cssOutlinedInput,
                                            focused: classes.cssFocused,
                                            notchedOutline:
                                                classes.notchedOutline,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label,
                                            focused: classes.focusedLabel,
                                            error: classes.erroredLabel,
                                        },
                                    }}
                                >
                                    {selectListState &&
                                        selectListState.map((item) => (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected:
                                                        classes.selectMenuItemSelected,
                                                }}
                                            >
                                                <span
                                                    className={styles.TextField}
                                                    style={{
                                                        color: disabledForm
                                                            ? '#838383'
                                                            : 'inherit',
                                                    }}
                                                >
                                                    {item.name}
                                                </span>
                                            </MenuItem>
                                        ))}
                                </CssTextField>
                            </GridItem>
                        )}

                    {!isCPFBiggerThan() &&
                        values?.stateRegistrationWaiver === false && (
                            <GridItem
                                xs={12}
                                md={isCPFBiggerThan() ? 6 : 3}
                                style={{ marginBottom: '10px' }}
                                className="input-rg-ie"
                                id="MuiMargin"
                            >
                                <CssTextField
                                    className={styles.TextField}
                                    fullWidth
                                    label={
                                        <CustomRequiredField
                                            value={t('STATE_REGISTRATION')}
                                            disabled={true}
                                        />
                                    }
                                    id="stateRegistration"
                                    //onChange={change.bind(null, 'STATE_REGISTRATION')}
                                    name="stateRegistration"
                                    //onKeyUp={this.handleKeyUp}
                                    error={Boolean(errors.stateRegistration)}
                                    helperText={t(errors.stateRegistration)}
                                    value={values && values.stateRegistration}
                                    variant="outlined"
                                    margin="dense"
                                    disabled={true}
                                    InputProps={{
                                        classes: {
                                            root: classes.cssOutlinedInput,
                                            focused: classes.cssFocused,
                                            notchedOutline:
                                                classes.notchedOutline,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label,
                                            focused: classes.focusedLabel,
                                            error: classes.erroredLabel,
                                        },
                                    }}
                                />
                            </GridItem>
                        )}
                    {values?.cpf?.length > 11 && (
                        <GridItem
                            xs={12}
                            sm={12}
                            md={!values?.stateRegistrationWaiver ? 4 : 5}
                            className="small-input-checkbox"
                            id="MuiCheckbox"
                        >
                            <Tooltip
                                title={t('YOUR_COMPANY_FITS_MEI')}
                                placement="top"
                                arrow
                            >
                                <FormControlLabel
                                    control={
                                        <CustomCheckbox
                                            checked={Boolean(values.isMEI)}
                                            disabled={true}
                                            //onChange={change.bind(null, 'isMEI')}
                                            name="isMEI"
                                            value={values.isMEI}
                                        />
                                    }
                                    label={t('YOUR_COMPANY_FITS_MEI')}
                                />
                            </Tooltip>
                        </GridItem>
                    )}

                    {isCPFBiggerThan() && (
                        <GridItem xs={12} md={12} style={{ marginTop: '7px' }}>
                            <b style={{ fontWeight: 500 }}>Sexo</b>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={
                                                values && values.gender === 'M'
                                            }
                                            disabled={disabledForm}
                                            onChange={change.bind(
                                                null,
                                                'gender',
                                            )}
                                            value="M"
                                            name="gender"
                                            id="gender"
                                            error={
                                                touched &&
                                                touched.gender &&
                                                Boolean(errors.gender)
                                            }
                                            aria-label="M"
                                            icon={
                                                <FiberManualRecord
                                                    className={
                                                        classes.radioUnchecked
                                                    }
                                                />
                                            }
                                            checkedIcon={
                                                <CheckCircle
                                                    className={
                                                        classes.radioChecked
                                                    }
                                                    style={{
                                                        border: 'none',
                                                        color: disabledForm
                                                            ? 'gray'
                                                            : 'inherit',
                                                    }}
                                                />
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot,
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                    label="Masculino"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={
                                                values && values.gender === 'F'
                                            }
                                            disabled={disabledForm}
                                            onChange={change.bind(
                                                null,
                                                'gender',
                                            )}
                                            value="F"
                                            name="gender"
                                            id="gender"
                                            aria-label="F"
                                            icon={
                                                <FiberManualRecord
                                                    className={
                                                        classes.radioUnchecked
                                                    }
                                                />
                                            }
                                            checkedIcon={
                                                <CheckCircle
                                                    className={
                                                        classes.radioChecked
                                                    }
                                                    style={{
                                                        border: 'none',
                                                        color: disabledForm
                                                            ? 'gray'
                                                            : 'inherit',
                                                    }}
                                                />
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot,
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                    label="Feminino"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={
                                                values &&
                                                values.gender === 'NDA'
                                            }
                                            disabled={disabledForm}
                                            onChange={change.bind(
                                                null,
                                                'gender',
                                            )}
                                            value="NDA"
                                            name="gender"
                                            id="gender"
                                            aria-label="NDA"
                                            icon={
                                                <FiberManualRecord
                                                    className={
                                                        classes.radioUnchecked
                                                    }
                                                />
                                            }
                                            checkedIcon={
                                                <CheckCircle
                                                    className={
                                                        classes.radioChecked
                                                    }
                                                    style={{
                                                        border: 'none',
                                                        color: disabledForm
                                                            ? 'gray'
                                                            : 'inherit',
                                                    }}
                                                />
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot,
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                    label="Não informar"
                                />
                            </div>
                        </GridItem>
                    )}
                </GridContainer>

                {/* #2 CONTATO */}
                <GridContainer>
                    <GridItem
                        xs={12}
                        md={12}
                        style={{ margin: '7px 0', marginBottom: '20px' }}
                    >
                        <b style={{ fontWeight: 500 }}>
                            {t('TITLE_REGISTER_CONTACT')}
                        </b>
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={12}
                        id="margin-bottom-contact"
                        md={
                            (values &&
                                values.cpf &&
                                values &&
                                values.cpf.replace(/[^\d]+/g, '').length) > 11
                                ? 9
                                : 6
                        }
                    >
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            label={
                                <CustomRequiredField
                                    value={'E-mail'}
                                    disabled={disabledForm}
                                />
                            }
                            id="email"
                            name="email"
                            error={
                                touched &&
                                touched.email &&
                                Boolean(errors.email)
                            }
                            helperText={errors.email}
                            value={values && values.email}
                            onChange={change.bind(null, 'email')}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>
                    {/* {values &&
                        values.cpf &&
                        values &&
                        values.cpf.replace(/[^\d]+/g, '').length <= 11 && ( */}
                    <GridItem xs={12} sm={12} md={3} id="margin-bottom-contact">
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            label={
                                <CustomRequiredField
                                    value={t('FIELD_REGISTER_MOBILE_PHONE')}
                                    disabled={disabledForm}
                                />
                            }
                            id="phoneNumber2"
                            name="phoneNumber2"
                            error={
                                touched &&
                                touched.phoneNumber2 &&
                                Boolean(errors.phoneNumber2)
                            }
                            value={values && values.phoneNumber2}
                            helperText={errors.phoneNumber2}
                            onChange={change.bind(null, 'phoneNumber2')}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                                inputComponent: PhoneMaskCustom,
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>
                    {/* )} */}
                </GridContainer>

                {/* #3 ENDEREÇO */}
                <GridContainer>
                    <GridItem
                        xs={12}
                        md={12}
                        style={{ margin: '7px 0', marginBottom: '20px' }}
                    >
                        <b style={{ fontWeight: 500 }}>
                            {t('FIELD_REGISTER_ADDRESS')}
                        </b>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} id="margin-bottom-address">
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            label={
                                <CustomRequiredField
                                    value={'CEP'}
                                    disabled={disabledForm}
                                />
                            }
                            id="zipCode"
                            name="zipCode"
                            error={
                                touched &&
                                touched.zipCode &&
                                Boolean(errors.zipCode)
                            }
                            value={values && values.zipCode}
                            onChange={change.bind(null, 'zipCode')}
                            onClick={handleZipcodeClick}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm || disabledAddress}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                                inputComponent: CEPMaskCustom,
                                endAdornment: disabledAddress && (
                                    <CircularProgress
                                        color="primary"
                                        size={24}
                                        className={styles.buttonProgress}
                                    />
                                ),
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={5} style={{ marginBottom: '10px' }}>
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            label={
                                <CustomRequiredField
                                    value={t('FIELD_REGISTER_ADDRESS')}
                                    disabled={disabledForm}
                                />
                            }
                            id="location"
                            name="location"
                            error={
                                touched &&
                                touched.location &&
                                Boolean(errors.location)
                            }
                            value={values && values.location}
                            onChange={change.bind(null, 'location')}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm || disabledAddress}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={2}
                        style={{ marginBottom: '10px' }}
                    >
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            label={
                                <CustomRequiredField
                                    value={'Nº'}
                                    disabled={disabledForm}
                                />
                            }
                            id="number"
                            name="number"
                            error={
                                touched &&
                                touched.number &&
                                Boolean(errors.number)
                            }
                            value={values && values.number}
                            onChange={change.bind(null, 'number')}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm || disabledAddress}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>

                    <GridItem xs={12} md={3} style={{ marginBottom: '5px' }}>
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            label={t('FIELD_REGISTER_EXTRA_INFO')}
                            id="aditionalInfo"
                            name="aditionalInfo"
                            // error={touched && touched.aditionalInfo && Boolean(errors.aditionalInfo)}
                            value={values && values.aditionalInfo}
                            onChange={change.bind(null, 'aditionalInfo')}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm || disabledAddress}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ margin: '7px 0', marginBottom: '5px' }}
                    >
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            label={
                                <CustomRequiredField
                                    value={t('FIELD_REGISTER_NEIGHBORHOOD')}
                                    disabled={disabledForm}
                                />
                            }
                            id="district"
                            name="district"
                            error={
                                touched &&
                                touched.district &&
                                Boolean(errors.district)
                            }
                            value={values && values.district}
                            onChange={change.bind(null, 'district')}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm || disabledAddress}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        />
                    </GridItem>

                    <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ margin: '7px 0', marginBottom: '5px' }}
                    >
                        <CssTextField
                            className={styles.TextField}
                            fullWidth
                            select
                            label={
                                <CustomRequiredField
                                    value={t('FIELD_REGISTER_CITY')}
                                    disabled={disabledForm}
                                />
                            }
                            id="cityId"
                            name="cityId"
                            error={
                                touched &&
                                touched.cityId &&
                                Boolean(errors.cityId)
                            }
                            value={values && values.cityId}
                            onChange={change.bind(null, 'cityId')}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm || disabledAddress}
                            SelectProps={{
                                IconComponent: (props) => {
                                    return disabledForm ? (
                                        ''
                                    ) : (
                                        <span {...props}>
                                            <svg
                                                className={styles.SelectSvgIcon}
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                aria-hidden="true"
                                                role="presentation"
                                            >
                                                <path d="M7 10l5 5 5-5z"></path>
                                            </svg>
                                        </span>
                                    )
                                },
                            }}
                            InputProps={{
                              classes: {
                                  root: classes.cssOutlinedInput,
                                  focused: classes.cssFocused,
                                  notchedOutline: classes.notchedOutline,
                              },
                          }}
                          InputLabelProps={{
                              classes: {
                                  root: classes.label,
                                  focused: classes.focusedLabel,
                                  error: classes.erroredLabel,
                              },
                          }}
                        >
                            {selectListCity &&
                                selectListCity.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                                classes.selectMenuItemSelected,
                                        }}
                                    >
                                        <span
                                            className={styles.TextField}
                                        >
                                            {item.name}
                                        </span>
                                    </MenuItem>
                                ))}
                        </CssTextField>
                    </GridItem>

                    <GridItem
                        xs={12}
                        md={3}
                        style={{ margin: '7px 0', marginBottom: '5px' }}
                    >
                        <CssTextField
                            fullWidth
                            select
                            label={
                                <CustomRequiredField
                                    value={t('FIELD_STATE')}
                                    disabled={disabledForm}
                                />
                            }
                            id="stateId"
                            name="stateId"
                            error={
                                touched &&
                                touched.stateId &&
                                Boolean(errors.stateId)
                            }
                            value={values && values.stateId}
                            onChange={change.bind(null, 'stateId')}
                            variant="outlined"
                            margin="dense"
                            disabled={disabledForm || disabledAddress}
                            SelectProps={{
                                IconComponent: (props) => {
                                    return disabledForm ? (
                                        ''
                                    ) : (
                                        <span {...props}>
                                            <svg
                                                className={styles.SelectSvgIcon}
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                aria-hidden="true"
                                                role="presentation"
                                            >
                                                <path d="M7 10l5 5 5-5z"></path>
                                            </svg>
                                        </span>
                                    )
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        >
                            {selectListState &&
                                selectListState.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                                classes.selectMenuItemSelected,
                                        }}
                                    >
                                        <span
                                            className={styles.TextField}
                                        >
                                            {item.name}
                                        </span>
                                    </MenuItem>
                                ))}
                        </CssTextField>
                    </GridItem>

                    <GridItem
                        xs={12}
                        md={3}
                        style={{ margin: '7px 0', marginBottom: '5px' }}
                    >
                        <CssTextField
                            fullWidth
                            select
                            label={
                                <CustomRequiredField
                                    value={t('COUNTRY')}
                                    disabled={disabledForm}
                                />
                            }
                            id="countryId"
                            name="countryId"
                            error={
                                touched &&
                                touched.countryId &&
                                Boolean(errors.countryId)
                            }
                            value={values && values.countryId}
                            onChange={change.bind(null, 'countryId')}
                            variant="outlined"
                            margin="dense"
                            disabled={true}
                            SelectProps={{
                                IconComponent: (props) => {
                                    return disabledForm ? (
                                        ''
                                    ) : (
                                        <span {...props}>
                                            <svg
                                                className={styles.SelectSvgIcon}
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                aria-hidden="true"
                                                role="presentation"
                                            >
                                                <path d="M7 10l5 5 5-5z"></path>
                                            </svg>
                                        </span>
                                    )
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                    focused: classes.focusedLabel,
                                    error: classes.erroredLabel,
                                },
                            }}
                        >
                            {selectListCountry &&
                                selectListCountry.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                                classes.selectMenuItemSelected,
                                        }}
                                    >
                                        <span
                                            className={styles.TextField}
                                        >
                                            {item.name}
                                        </span>
                                    </MenuItem>
                                ))}
                        </CssTextField>
                    </GridItem>
                    <S.CodeBox>
                        <S.LabelCodeText>
                            <p className="Textlabel">{t('FIELD_CODE')}</p>
                        </S.LabelCodeText>
                        <S.CodeText>
                            <p className="TextCode">{indicationCode}</p>
                            <label className="LabelStyle">
                                {t('SHARE_CODE_PARTICIPANT')}
                            </label>
                        </S.CodeText>
                    </S.CodeBox>
                </GridContainer>

                {values &&
                    values.cpf &&
                    values &&
                    values.cpf.replace(/[^\d]+/g, '').length > 11 && (
                        <GridContainer style={{ marginBottom: '10px' }}>
                            <GridItem
                                xs={12}
                                md={12}
                                style={{ margin: '15px 0 10px 0' }}
                            >
                                <b style={{ fontWeight: 500 }}>
                                    {t('TITLE_REGISTER_REPRESENTATIVE')}
                                </b>
                            </GridItem>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={9}
                                id="margin-bottom-contact"
                            >
                                <CssTextField
                                    error={Boolean(errors.repName)}
                                    autoComplete="norepName"
                                    label={
                                        <CustomRequiredField
                                            value={t(
                                                'FIELD_REGISTER_FULL_NAME',
                                            )}
                                            disabled={disabledForm}
                                        />
                                    }
                                    disabled={disabledForm}
                                    id="repName"
                                    name="repName"
                                    helperText={
                                        Boolean(errors.repName) &&
                                        errors.repName
                                    }
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    value={values && values.repName}
                                    onChange={change.bind(null, 'repName')}
                                    style={{ marginBottom: '10px' }}
                                    InputProps={{
                                        classes: {
                                            root: classes.cssOutlinedInput,
                                            focused: classes.cssFocused,
                                            notchedOutline:
                                                classes.notchedOutline,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label,
                                            focused: classes.focusedLabel,
                                            error: classes.erroredLabel,
                                        },
                                    }}
                                />
                            </GridItem>

                            <GridItem
                                xs={12}
                                sm={12}
                                md={3}
                                id="margin-bottom-contact"
                            >
                                <CssTextField
                                    error={Boolean(errors.repCpf)}
                                    autoComplete="noperepCpf"
                                    label={
                                        <CustomRequiredField
                                            value={t('FIELD_REGISTER_DOCUMENT')}
                                            disabled={disabledForm}
                                        />
                                    }
                                    helperText={
                                        Boolean(errors.repCpf) && errors.repCpf
                                    }
                                    disabled={disabledForm}
                                    id="repCpf"
                                    name="repCpf"
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    value={values && values.repCpf}
                                    style={{ marginBottom: '10px' }}
                                    onChange={change.bind(null, 'repCpf')}
                                    InputProps={{
                                        classes: {
                                            root: classes.cssOutlinedInput,
                                            focused: classes.cssFocused,
                                            notchedOutline:
                                                classes.notchedOutline,
                                        },
                                        inputComponent: CPFMaskCustom,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label,
                                            focused: classes.focusedLabel,
                                            error: classes.erroredLabel,
                                        },
                                    }}
                                />
                            </GridItem>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={9}
                                id="margin-bottom-contact"
                            >
                                <CssTextField
                                    error={Boolean(errors.repEmail)}
                                    autoComplete="noperepEmail"
                                    label={
                                        <CustomRequiredField
                                            value={t('FIELD_REGISTER_EMAIL')}
                                            disabled={disabledForm}
                                        />
                                    }
                                    id="repEmail"
                                    name="repEmail"
                                    helperText={
                                        Boolean(errors.repEmail) &&
                                        errors.repEmail
                                    }
                                    value={values && values.repEmail}
                                    onChange={change.bind(null, 'repEmail')}
                                    disabled={disabledForm}
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                />
                            </GridItem>
                            <GridItem
                                xs={11}
                                sm={11}
                                md={3}
                                id="margin-bottom-contact"
                            >
                                <CssTextField
                                    error={Boolean(errors.repPhoneNumber)}
                                    helperText={
                                        Boolean(errors.repPhoneNumber) &&
                                        errors.repPhoneNumber
                                    }
                                    autoComplete="nope"
                                    label={
                                        <CustomRequiredField
                                            value={t(
                                                'FIELD_REGISTER_MOBILE_PHONE',
                                            )}
                                            disabled={disabledForm}
                                        />
                                    }
                                    id="repPhoneNumber"
                                    name="repPhoneNumber"
                                    fullWidth
                                    disabled={disabledForm}
                                    variant="outlined"
                                    margin="dense"
                                    value={values && values.repPhoneNumber}
                                    onChange={change.bind(
                                        null,
                                        'repPhoneNumber',
                                    )}
                                    InputProps={{
                                        classes: {
                                            root: classes.cssOutlinedInput,
                                            focused: classes.cssFocused,
                                            notchedOutline:
                                                classes.notchedOutline,
                                        },
                                        inputComponent: PhoneMaskCustom,
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.label,
                                            focused: classes.focusedLabel,
                                            error: classes.erroredLabel,
                                        },
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    )}
            </S.ContainerProfileForm>
        )
    }
}

export default compose(
    withTranslation(),
    withStyles(extendedFormsStyle),
    withStyles(userProfileStyles),
)(ProfileForm)
