import React, { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    withStyles,
} from '@material-ui/core'
import registerStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'
import { ButtonClose } from 'components/NewButton/styles'
import { useTranslation } from 'react-i18next'
import CardBody from 'components/Card/CardBody.jsx'
import { forwardRef } from 'react'
import { GetThemeColorFromStorage } from '../../../../../../redux/api/themeColor'

function Transition(props) {
    return <Slide direction="down" {...props} />
}

const RegulationModal = forwardRef(({ classes, open, handleClose }, ref) => {
    const { t } = useTranslation()
    const [themeColor, setThemeColor] = useState(GetThemeColorFromStorage())
    return (
        <Dialog
            classes={{
                root: classes.center + ' ' + classes.modalRoot,
                paper: classes.modal,
            }}
            fullWidth={true}
            maxWidth={'md'}
            open={open.regulation}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <DialogTitle
                id="modal_platformrules"
                disableTypography
                className={classes.modalHeader}
            >
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={12} md={10}>
                        <h4
                            style={{
                                fontWeight: 'bold',
                                fontSize: '18px',
                                color: themeColor?.titles?.main?.textColor,
                                textTransform: 'uppercase',
                                paddingBottom: '10px',
                            }}
                        >
                            <div>{t('FIELD_REGULATION_PROGRAM')}</div>
                        </h4>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonClose onClick={handleClose}>
                            <i
                                className="icon-ico_cancelar"
                                style={{
                                    fontSize: '20px',
                                    marginRight: '10px',
                                }}
                            />
                            {t('BUTTON_CLOSE')}
                        </ButtonClose>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
            >
                <CardBody>
                    <Grid container>
                        <div ref={ref}></div>
                    </Grid>
                </CardBody>
            </DialogContent>
            <DialogActions className={classes.modalFooter}></DialogActions>
        </Dialog>
    )
})

export default withStyles(registerStyle)(RegulationModal)
