import styled from 'styled-components'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'
import { Box } from '@mui/material'

export const container = styled.div``

export const LeftButton = styled(Box)`
    display: block;
    height: 20px;
    position: relative;
    top: 80px;
    right: 4.4%;
    z-index: 5;
    opacity: 0.2;
    font-size: 80px;
    cursor: pointer;
`

export const RightButton = styled(Box)`
    color: #000;
    background-color: #fff;
    display: block;
    height: 20px;
    position: relative;
    top: -140px;
    left: 99%;
    z-index: 5;
    opacity: 0.2;
    font-size: 80px;
    cursor: pointer;
`

export const inputPropsStyles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${germiniDefaultColors[0]} !important`,
        },
    },
    cssFocused: {},
    notchedOutline: {
        color: germiniDefaultColors[0],
    },
})

export const inputLabelPropsStyles = () => ({
    label: {
        '&$focusedLabel': {
            color: germiniDefaultColors[0],
        },
    },
    focusedLabel: {},
})
