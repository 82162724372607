import styled from 'styled-components'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const ContextInput = styled.div`
    .cssOutlinedInput {
        &.cssFocused .notchedOutline {
            border-color: #333333 !important;
        }
    }
    .cssFocused {
    }
    .notchedOutline {
        color: #333333 !important;
    }
    label {
        .focusedLabel {
            color: #333333 !important;
        }
    }
`
export const ContextSpan = styled.span`
    transition: all 0.2s ease 0s;
    top: 44px;
    color: rgb(255 0 0);
    position: absolute;
    left: 0px;
    display: inline;
    width: 100%;
    font-size: 0.7rem;
    line-height: 11px;
    font-weight: 500;
    opacity: ${(props) => (props.error ? '1' : '0')};
`

export const ContextLabel = styled.span`
    position: absolute;
    bottom: 44px;
    left: 0px;
    color: #838383;
    display: inline;
    width: 100%;
    font-size: 0.7rem;
    line-height: 11px;
    font-weight: 400;
`

export const Container = styled.div`
    opacity: ${({ disabledForm }) => (disabledForm ? '0.85' : '1.0')};
`

export const ContainerVoid = styled.span`
    position: absolute;
    color: red;
    font-weight: 500;
    width: 100%;
    text-align: left;
    top: 60px;
    left: 215px;
`

export const ContainerFragment = styled.div`
    background-color: #eee;
    padding: 5px 8px;
    font-weight: 500;
    margin-top: 30px;
    border-radius: 5px;
    width: 100%;

    .info {
        font-weight: 500;
        font-size: 10px;
        font-style: italic;
    }
`

export const ContainerValueInputField = styled.div`
    transform: translateY(8px);
    position: relative;
    margin-bottom: 16px;

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }
`
