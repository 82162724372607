import axios from 'axios'
import { envs } from '../windowServerData'

export const getAllPurchasers = async () => {
    try {
        const response = await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllPurchasers`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllPurchasers`,
        )
        return response
    } catch (error) {
        console.log(error)
    }
}

export const getPurchaser = async (id) => {
    try {
        const response = await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/api/PartnerSettings/GetPurchaser/${id}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/api/PartnerSettings/GetPurchaser/${id}`,
        )

        return response
    } catch (error) {
        console.log(error)
    }
}

export const getPurchaserByStatus = async (status) => {
    try {
        const response = await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPurchaserByStatus/${status}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPurchaserByStatus/${status}`,
        )

        return response
    } catch (error) {
        console.log(error)
    }
}

export const getStatusByPurchaser = async (purchaser) => {
    try {
        const response = await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetTransactionStatusByPurchaser/${purchaser}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetTransactionStatusByPurchaser/${purchaser}`,
        )

        return response
    } catch (error) {
        console.log(error)
    }
}

export const createSelectedPurchaser = async (obj) => {
    try {
        const response = await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/CreateSelectedPurchaser`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/CreateSelectedPurchaser`,
            {
                method: 'POST',
                data: {
                    partnerId: obj?.partnerId,
                    purchaser: {
                        id: obj?.id,
                        name: obj?.name,
                        type: obj?.type,
                        connection: obj?.connection,
                        paymentMethods: obj?.paymentMethods,
                    },
                    priority: obj?.priority,
                },
            },
        )

        return response
    } catch (error) {
        console.log(error)
    }
}
export const createPartnerSettings = async (obj) => {
    try {
        const response = await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/CreatePartnerSettings`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/CreatePartnerSettings`,
            {
                method: 'POST',
                data: obj,
            },
        )

        return response
    } catch (error) {
        console.log(error)
    }
}

export const GetPurchaser = async (id) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPurchaser/${id}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPurchaser/${id}`,
        )
    } catch (error) {
        console.log(error)
    }
}

export const GetPartnerSettings = async (id) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPartnerSettings/${id}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPartnerSettings/${id}`,
        )
    } catch (error) {
        console.log(error)
    }
}

export const GetAllPartnerLogs = async (obj) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllPartnerLogs`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllPartnerLogs`,
            {
                method: 'POST',
                data: obj,
            },
        )
    } catch (error) {
        console.log(error)
    }
}

export const ExportAllPartnerLogs = async (obj) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ExportAllPartnerLogs`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ExportAllPartnerLogs`,
            {
                method: 'POST',
                data: obj,
                responseType: 'blob',
            },
        )
    } catch (error) {
        console.log(error)
    }
}

export const ExportAllTransactionLogs = async (obj) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ExportAllTransactionLogs`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ExportAllTransactionLogs`,
            {
                method: 'POST',
                data: obj,
                responseType: 'blob',
            },
        )
    } catch (error) {
        console.log(error)
    }
}

export const GetAllTransactionLogs = async (obj) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllTransactionLogs`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllTransactionLogs`,
            {
                method: 'POST',
                data: obj,
            },
        )
    } catch (error) {
        console.log(error)
    }
}

export const GetAllTransactionStatus = async (status) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllTransactionStatus`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllTransactionStatus`,
            {
                method: 'GET',
                data: status,
            },
        )
    } catch (error) {
        console.log(error)
    }
}

export const ValidatePurchaserCredentials = async (id, obj) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ValidatePurchaserCredentials/${id}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ValidatePurchaserCredentials/${id}`,
            {
                method: 'POST',
                data: obj,
            },
        )
    } catch (error) {
        console.log(error)
    }
}

export const DisablePartnerSettings = async (id, obj) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/DisablePartnerSettings/${id}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/DisablePartnerSettings/${id}`,
            {
                method: 'PUT',
                data: obj,
            },
        )
    } catch (error) {
        console.log(error)
    }
}

export const UpdatePaymentSettings = async (id, obj) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/UpdatePaymentSettings/${id}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/UpdatePaymentSettings/${id}`,
            {
                method: 'PUT',
                data: obj,
            },
        )
    } catch (error) {
        console.log(error)
    }
}

export const ChangeSelectedPurchaser = async (id, obj) => {
    try {
        return await axios(
            // `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ChangeSelectedPurchaser/${id}`,
            `${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ChangeSelectedPurchaser/${id}`,
            {
                method: 'PUT',
                data: obj,
            },
        )
    } catch (error) {
        console.log(error)
    }
}
