import styled from 'styled-components'

import { Box, Grid } from '@mui/material'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export const CurrencyTableWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .MuiPaper-elevation2 {
        box-shadow: unset;
    }

    .MuiTablePagination-root:last-child {
        padding: 0;
        display: none !important;
    }

    .MuiToolbar-regular {
        min-height: 0px;
    }

    tbody {
        border: none !important;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: #918d8d #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 16px;
    }

    *::-webkit-scrollbar-track {
        background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #918d8d;
        border-radius: 10px;
        border: 3px solid #ffffff;
    }
    .table-wrapper {
        height: 181px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .MuiTableHead-root {
        tr {
            th {
                background-color: #e9e9e9;
                color: ${GetThemeColorFromStorage()?.titles?.secondary
                    ?.textColor};
                font-size: 16px;
                font-weight: bold;
                height: 40px;

                padding: 0px 16px;

                :first-child {
                    border-top-left-radius: 6px;
                    width: 22% !important;
                }

                :last-child {
                    border-top-right-radius: 6px;
                    width: 15% !important;
                    span {
                        svg {
                            display: none;
                        }
                    }
                }
                :nth-child(2) {
                    text-align: left !important;
                }
            }
        }
    }

    .MuiTableBody-root {
        tr {
            color: #717171;
            letter-spacing: 0px;
            opacity: 1;
            font-size: 15px;
            height: 45px;

            p {
                font-size: 13px;
                font-style: italic;
                margin: 0px;
            }

            td {
                padding: 0px 15px !important;

                :last-child {
                    > div {
                        /* justify-content: flex-end; */
                        /* text-align: right !important; */
                    }
                }
                :nth-child(2) {
                    text-align: left !important;
                }
            }
        }
    }
`

export const CustomGrid = styled(Grid)`
    background-color: #e9e9e9 !important;
    color: #1a9cac !important;
    font-size: 16px !important;
    font-weight: bold !important;
    height: 40px !important;
    padding: 0px 16px !important;
`
