import Axios from 'axios'

class SearchService {
    async getAvailable() {
        const response = await Axios.get(`/Survey/GetActiveSurvey`)
        return response?.data
    }
}

export default new SearchService()
