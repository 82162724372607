import styled from 'styled-components/macro'
import { Icon, Tooltip } from '@material-ui/core'
import {
    germiniDefaultColors,
    grayColor,
} from 'assets/jss/material-dashboard-pro-react'

export const MyIcon = styled(Icon)`
    :hover {
        color: #3dbac7;
    }
    width: '1.5em';
    color: ${germiniDefaultColors[0]};
    cursor: pointer;
`

export const MyTooltip = styled(Tooltip)``

export const ContainCards = styled.div`
    display: flex;
    gap: 20.5px;
    flex-wrap: wrap;
    .voucherCard_card__1RLg8 {
        min-width: auto;
    }
`

export const EmptyTable = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding-top: 1em;
	color: #b4b4b4;

	i {
		padding-top: 1em;
		font-size: 3em;
		cursor: pointer;
		margin: auto 18px;
		color: #b4b4b4;
	}

	.icon-informacao:before {
		font-size: 1.5em;
	}

	span {
		font-weight: 900;
		margin: 25px 0 12px;
	}
`

export const CardContain = styled.div`
    width: 100% !important;
    @media only screen and (min-width: 500px) {
        width: 47.79% !important;
    }

    @media only screen and (min-width: 750px) {
        padding: 0;
        width: 31.4% !important;
    }
    @media only screen and (min-width: 960px) {
        padding: 0;
        width: 31% !important;
    }
`
