/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Snackbar } from "@mui/material";
import { MuiStack } from "../Stack";
import { AiOutlineClose, BsExclamationCircleFill, CancelIcon, FaCheckCircle, } from "../Icons";
export function MuiSnack(_a) {
    var open = _a.open, onClose = _a.onClose, message = _a.message, type = _a.type, props = __rest(_a, ["open", "onClose", "message", "type"]);
    var typeIcons = {
        success: _jsx(FaCheckCircle, { size: "25" }),
        error: _jsx(CancelIcon, { size: "25", color: "#FFFFFF" }),
        attention: _jsx(BsExclamationCircleFill, { size: "25" }),
    };
    var colors = {
        success: "#5cb860",
        error: "#F55A4E",
        attention: "#FFA500",
    };
    var icon = typeIcons[type];
    var color = colors[type];
    return (_jsx(_Fragment, { children: _jsx(Snackbar, __assign({}, props, { open: open, onClose: onClose, sx: {
                borderRadius: "5px",
                "& .MuiPaper-elevation": {
                    backgroundColor: color,
                },
            }, message: _jsxs(MuiStack, __assign({ direction: "row", alignItems: "center", justifyContent: "space-between", spacing: 2, onClick: onClose }, { children: [icon, _jsx("span", { children: message }), _jsx(AiOutlineClose, { size: 15 })] })) })) }));
}
