import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MuiBox, TabItem, CustomTabs } from '../../components/storybook'
import DocumentTitle from 'react-document-title'
import SearchForm from './SearchForm/index.js'
import Section from '../../components/Section/Section'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import useNegotiation from './Hooks/useNegotiation.js'
import { getProgramTitle } from '../../utils/utils.js'
import { Table } from './components/Table/index.jsx'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor.js'

const NegotiationChild = () => {
    const { t } = useTranslation()
    const [currentTab, setCurrentTab] = useState(0)

    const {
        getNegotiationFilter,
        getNegotiationData,
        getPaginatedNegotiations,
        negotiationData,
        tablePagination,
        isLoadingData,
    } = useNegotiation()

    const handleTabChange = (index) => {
        setCurrentTab(index)
    }

    useMemo(() => {
        if (currentTab === 1) {
            getNegotiationData('cashback')
        } else {
            getNegotiationData()
        }
    }, [currentTab])

    useEffect(() => {
        getNegotiationData()
    }, [])
    return (
        <div>
            <DocumentTitle title={getProgramTitle('Negociações')} />
            <MuiBox mt={3}>
                <Section title="Detalhamento das negociações" />
            </MuiBox>

            <S.ContainerSecundary>
                <SearchForm
                    getNegotiationFilter={getNegotiationFilter}
                    negotiationData={negotiationData}
                />
            </S.ContainerSecundary>
            <MuiBox
                sx={{
                    width: '100%',
                    '& ."MuiBox-root': { background: '#000!important' },
                }}
                mt={3}
            >
                <CustomTabs
                    onOpenModal={function noRefCheck() {}}
                    onclickEdit={function noRefCheck() {}}
                    selectedTab={currentTab}
                    setSelectedTab={handleTabChange}
                    tabLabels={['GERAÇÃO DE PONTOS', 'CASHBACK']}
                    tabFontSize="13px"
                    defaultColor={
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }
                >
                    <TabItem index={0} selectedValue={currentTab}>
                        <Table
                            isLoadingData={isLoadingData}
                            negotiationData={negotiationData}
                            tablePagination={tablePagination}
                            getPaginatedNegotiations={getPaginatedNegotiations}
                        />
                    </TabItem>
                    <TabItem index={1} selectedValue={currentTab}>
                        <Table
                            isLoadingData={isLoadingData}
                            negotiationData={negotiationData}
                            tablePagination={tablePagination}
                            getPaginatedNegotiations={getPaginatedNegotiations}
                        />
                    </TabItem>
                </CustomTabs>
            </MuiBox>
        </div>
    )
}

export default NegotiationChild
