/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { MuiTypography } from "../Typography";
var CustomWidthTooltip = styled(function (_a) {
    var className = _a.className, children = _a.children, props = __rest(_a, ["className", "children"]);
    return (_jsx(Tooltip, __assign({}, props, { classes: { popper: className } }, { children: children })));
})((_a = {},
    _a["& .".concat(tooltipClasses.tooltip)] = {
        maxWidth: 1000,
        width: "100%",
        minWidth: 200,
        backgroundColor: "#E8E8E8",
        padding: 20,
    },
    _a["& .".concat(tooltipClasses.arrow)] = {
        color: "#E8E8E8",
    },
    _a));
export function MuiCustomTooltip(_a) {
    var title = _a.title, children = _a.children, description = _a.description, props = __rest(_a, ["title", "children", "description"]);
    return (_jsx(CustomWidthTooltip, __assign({}, props, { title: _jsxs(_Fragment, { children: [_jsx(MuiTypography, __assign({ variant: "body2", fontWeight: 600, color: "#333333", mb: 2 }, { children: title })), _jsx(MuiTypography, __assign({ variant: "body2", color: "#333333" }, { children: description }))] }), arrow: true, placement: "right-start" }, { children: _jsx("button", __assign({ style: { backgroundColor: "transparent", border: "none" } }, { children: children })) })));
}
