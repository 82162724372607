import styled from 'styled-components/macro'
import { Modal, Paper } from '@material-ui/core'
import {
    germiniDefaultColors,
    successColor,
    dangerColor,
    grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export const ModalContainer = styled(Modal)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .MuiPaper-rounded {
        border-radius: 10px;
        padding-bottom: 10px;
        display: flex;
        max-height: 62rem;
        overflow-y: auto;
        flex-direction: column;
    }

    header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 25px 0 25px;
        .line {
            width: 70%;
            hr {
                height: 2px;
                background-color: ${GetThemeColorFromStorage()?.titles?.main
                    ?.textColor};
                margin: 0;
            }
        }

        .section-part {
            .section-header {
                padding: 0;
                font-size: 1em;
                display: flex;
                white-space: nowrap;
                font-weight: 600;
                align-items: center;
                text-transform: initial;
                width: 100%;
                &::after {
                    border-top: 3px solid
                        ${GetThemeColorFromStorage()?.titles?.main?.textColor};
                    content: '';
                    display: none;
                    position: static;
                    width: 100%;
                    left: 0;
                    margin-left: 10px;
                }
            }
        }
    }
`

export const ExpiredBox = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
`

export const PaperContainer = styled(Paper)`
    max-width: 600px;
    position: relative;
    width: ${({ width }) => width};
    height: ${({ height }) => height};

    main {
        padding: 0 30px 30px;
    }

    .card__content {
        padding: 0px 25px;
        > div {
            margin: 2px 0;
            /* teste */
        }
    }

    .closeByClick {
        background-color: #f05b4f;
        border-color: #f05b4f;
        width: 106px;
    }
    #iconclose {
        font-size: 15px;
        margin: 0 0px 0px 0px;
        color: white;
    }

    .value__container {
        display: flex;
        align-items: center;
        margin: 25px 0 !important;
        font-weight: 400;

        .icon-tempo_expirado {
            color: #de2e2e;
            opacity: 1;
        }

        i {
            color: ${({ isGiftCard }) =>
                isGiftCard ? successColor[7] : dangerColor[7]};
            font-size: 2.8125em;
            margin-right: 10px;
        }

        .value__text {
            color: #333333;
            text-align: left;
            font: normal normal bold 15px/21px Roboto;
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;

            margin-right: 10px;
            text-transform: lowercase;
            ::first-letter {
                text-transform: capitalize;
            }
        }

        .value__price {
            color: ${({ isGiftCard }) =>
                isGiftCard ? successColor[7] : dangerColor[7]};
            font-weight: 700;
            font-size: 1.25em;
        }
    }
    .partner__button {
        border: none;
        border-top: 1px solid #c7c7c7;
        width: 100%;
        background-color: #fff;
        padding-top: 15px;
        margin-top: 10px;
    }

    .partner__container {
        display: flex;
        font-weight: 400;
        align-items: center;

        i {
            color: ${grayColor[19]};
            font-size: 2.1875em;
            margin-right: 10px;
            padding: 0 5px;
        }

        .line {
            display: flex;
            align-items: center;
            flex: 1;

            hr {
                border: 1px dashed ${grayColor[19]};
                opacity: 1;
                width: 100%;
            }
        }

        .partner_wrapper {
            align-items: center;
            display: flex;
            flex-direction: row;
            line-height: 1.2;
            width: 100%;

            //justify-content: space-between;

            .partner__name {
                color: ${GetThemeColorFromStorage()?.titles?.secondary
                    ?.textColor};
                font-weight: 700;
                font-size: 1em;
                min-width: 5%;
            }

            .originPlatform {
                color: ${GetThemeColorFromStorage()?.titles?.secondary
                    ?.textColor};
                text-align: left;
                font: italic normal normal 15px/18px Roboto;
                letter-spacing: 0px;
                margin-left: 10px;
            }
        }

        .view_purchase {
            align-items: center;
            display: flex;
            flex-direction: row;
            line-height: 1.2;
            width: 100%;
            //justify-content: space-between;

            .detal__name {
                color: ${GetThemeColorFromStorage()?.titles?.secondary
                    ?.textColor};
                font-weight: 700;
                font-size: 1em;
                min-width: 5%;
            }
        }
    }

    .dates__container {
        display: flex;
        align-items: center;
        font-weight: 400;

        .date__container {
            display: flex;

            i {
                color: ${grayColor[19]};
                font-size: 2.1875em;
                margin-right: 10px;
                padding: 0 5px;
            }

            .date_wrapper {
                display: flex;
                flex-direction: column;
                line-height: 1.2;

                .date__date {
                    color: ${GetThemeColorFromStorage()?.titles?.secondary
                        ?.textColor};
                    font-weight: 700;
                    font-size: 1em;
                }
            }
        }
    }

    @media screen and (max-width: 880px) {
        min-width: 60vw;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    padding: ${({ padding }) => padding};
    &.MuiPaper-elevation1 {
        box-shadow: ${({ shadow }) => shadow};
    }

    .close-button {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        font-size: 1em;
        color: #fff;
        background-color: #f05b4f;
        padding: 8px 10px;
        border-radius: 6px;
        &:hover {
            opacity: 0.7;
        }
        svg {
            margin-right: 3px;
        }
    }
`
export const rejected = styled.div`
    background-color: #f0f0f0;
    border-radius: 8px;
    color: #de2e2e;
    padding: 10px;
    width: 100%;
    div {
        display: flex;
        font-size: 15px;
        width: 100%;
        i {
        }
        p {
            font-weight: bold;
            margin-left: 10px;
            font-size: 15px;
        }
    }
    p {
        font-size: 13px;
    }
`
