import { TextField } from '@material-ui/core'
import styled from 'styled-components'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

export const MyTextField = styled(TextField)`
    background-color: white;
    .MuiOutlinedInput-input {
        padding: 11px;
    }
`

export const CustomDateTime = styled.div`
    .rdtPicker {
        display: block;
        top: 70px;
        right: 1px;
    }

    .rdtPicker:after {
        right: 10px;
        left: auto;
     }

     .rdtPicker:before {
        top: 0px;
        left: 0px;
      }
`

export const Container = styled.form`
    background-color: #f6f6f6;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 20px 15px 20px 15px;
    @media screen and (min-width: 960px) {
        margin-right: 15px;
    }

    .MuiGrid-item {
        padding-left: 25px !important;
        padding-top: 25px !important;
    }
    
    .react-datetime-picker {
        position: relative;
    }

    .react-datetime-picker .react-datetime-picker__wrapper {
        position: absolute;
        left: 0;
    }

    .labelIpt {
        left: -23px;
    }

    .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border: 1px solid red;
        }
    }
    .select-custom {
        //onFocus
        &:focus {
            border: 1px solid red !important;
        }
    }

    .control {
        &:focus {
            border: 1px solid red !important;
        }
        &:hover {
            border: 1px solid red !important;
        }
    }
    .react-select__control {
        border: 1px solid red !important;
        &:focus {
        }
        &:hover {
            border: 1px solid red !important;
        }
    }
    .MuiFormControl-root {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.6);
    }

    .MuiFormControl-marginDense {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .rdtMonths,
    .rdtYears {
        .rdtMonth,
        .rdtYear {
            &.rdtActive {
                background-color: ${(props) =>
                    props?.theme?.titles?.highlight?.textColor} !important;
            }
        }
    }

    .rdtPicker .dow,
    .rdtPicker th.rdtSwitch,
    .rdtPicker th.rdtNext,
    .rdtPicker th.rdtPrev,
    .rdtPicker .rdtTimeToggl {
        color: ${(props) =>
            props?.theme?.titles?.highlight?.textColor} !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${(props) =>
            props?.theme?.navigation?.buttons?.backgroundColor} !important;
        border-width: 2px;
        box-shadow: none;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-color: ${(props) =>
            props?.theme?.navigation?.buttons?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${(props) =>
            props?.theme?.navigation?.buttons?.backgroundColor} !important;
    }

    .MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 3px;
    }

    .MuiAutocomplete-popperDisablePortal {
        margin: 38px auto;
    }

    .MuiAutocomplete-popperDisablePortal {
        li:hover {
            color: #ffffff !important;
            background-color: ${(props) =>
                props?.theme?.navigation?.buttons?.backgroundColor} !important;
        }
    }

    .MuiOutlinedInput-root:hover fieldset {
        border-color: ${(props) =>
            props?.theme?.navigation?.buttons?.backgroundColor};
    }
`
export const DateBox = styled.div`
    display: flex;
`
export const BtnContainer = styled.div`
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 20px;
    width: 100%;
`

export const LabelInput = styled.label`
    font-weight: 400;
    position: absolute;
    top: -7px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    z-index: 1;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1;
    letter-spacing: 0.00938em;
    transform: translateX(-10px) scale(0.72);
    pointer-events: none;
    padding: 0px 9px 0px 9px;
    background-color: #fff;
    white-space: nowrap;
`

export const customSelectStyles = {
    control: (base, state) => ({
        ...base,
        input: { height: '25px' },
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? grayColor[2] : base.borderColor,
        '&:hover': {
            borderColor: state.isFocused ? grayColor[2] : base.borderColor,
        },
    }),
}
