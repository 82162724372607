import Axios from 'axios'

export default class NegotiationDetailsService {
    async getDetailsById(id) {
        try {
            const response = await Axios.get(
                `/Negotiation/GetDetailsById/${id}`,
            )
            return response.data
        } catch (error) {
            console.error('Erro ao obter detalhes da negociação por ID', error)
            throw error
        }
    }
    async getDetailsTableById(id) {
        try {
            const response = await Axios.get(
                `/Negotiation/GetNegotiationItens/${id}`,
            )
            return response.data
        } catch (error) {
            console.error('Erro ao obter detalhes da negociação por ID', error)
            throw error
        }
    }
    async getPaginationNegotiations(id, { pageSize, page }) {
        const response = await Axios.get(
            `/Negotiation/GetNegotiationItens/${id}?pageSize=${pageSize}&page=${page}`,
        )
        return response?.data
    }
}
