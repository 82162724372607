import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {  Fade, Grid } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import { userPreference } from 'redux/api/alerts/alerts'
import { ModalContainer, PaperContainer } from './styles'
import CloseIcon from '@material-ui/icons/HighlightOff'

import * as Styled from './styles'

function CustomModal({
    children,
    open,
    closeByClick,
    maxWidth,
    className,
    showCheckBox,
    padding,
    shadow,
    hasPointsIndicator,
    pointsIndicator,
    programCurrency,
    t,
    headerIcon = 'icon-ico_aviso',
    headerTitle = 'MENU_NOTIFICATION',
    buttonTitle,
    days,
    onClose,
    totalPage = 0,
    sendPage,
    presentPage = 0,
    readAllImportantAlerts,
}) {
    const [showModal, setShowModal] = useState(true)

    function callUserPreference() {
        setShowModal(!showModal)
        userPreference(showModal)
    }

    return (
        <Fragment>
            <ModalContainer
                open={open}
                className={className}
                onClose={onClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                shadow={shadow}
                maxwidth={maxWidth}
                disableEnforceFocus
            >
                <Fade in={open} unmountOnExit>
                    <PaperContainer padding={padding} maxwidth={maxWidth}>
                        <Grid style={{ padding: '6px 0px' }}>
                            <Styled.Background
                                style={{ width: '880px', height: '330px' }}
                            >
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{ textAlign: 'right' }}
                                >
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => closeByClick()}
                                    >
                                        <CloseIcon
                                            style={{
                                                color: 'white',
                                                fontSize: '24px',
                                                marginTop: '10px',
                                            }}
                                        ></CloseIcon>
                                    </div>
                                </GridItem>
                                {!hasPointsIndicator ===true ? (
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{
                                            marginTop: '75px',
                                            marginLeft: '415px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: '17px',
                                                fontWeight: 'bold',
                                                color: 'white',
                                            }}
                                        >
                                            <span>
                                                Convide seus amigos para
                                                participar do
                                            </span>
                                            <br />
                                            <br />
                                            <span>
                                                {localStorage.getItem(
                                                    'programName',
                                                )
                                                    ? localStorage.getItem(
                                                          'programName',
                                                      )
                                                    : 'Fidelidade'
                                                    ? localStorage.getItem(
                                                          'programName',
                                                      )
                                                        ? localStorage.getItem(
                                                              'programName',
                                                          )
                                                        : 'Fidelidade'
                                                    : 'Fidelidade'}
                                            </span>
                                            <br />
                                            <br />
                                            <span>
                                                aumente os benefícios do
                                                programa.
                                            </span>
                                        </div>
                                    </GridItem>
                                ) : (
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{ marginLeft: '415px' }}
                                    >
                                        <div
                                            style={{
                                                fontSize: '17px',
                                                fontWeight: 'bold',
                                                color: 'white',
                                            }}
                                        >
                                            <span>
                                                Convide seus amigos para
                                                participar do
                                            </span>
                                            <br />
                                            <br />
                                            <span>
                                                {localStorage.getItem(
                                                    'programName',
                                                )
                                                    ? localStorage.getItem(
                                                          'programName',
                                                      )
                                                    : 'Fidelidade'}{' '}
                                                e
                                            </span>
                                            <br />
                                            <br />
                                            <span>
                                                aumente seus benefícios no
                                                programa!
                                            </span>
                                            <br />
                                            <br />
                                            <br />
                                            <span>
                                                Além de trazer seus amigos para
                                                o mundo
                                            </span>
                                            <br />
                                            <br />
                                            <span>
                                                de benefícios, você ainda recebe{' '}
                                                {programCurrency}{' '}
                                                {pointsIndicator}
                                            </span>
                                            <br />
                                            <br />
                                            <span>
                                                {pointsIndicator > 1
                                                    ? 'pontos'
                                                    : 'ponto'}{' '}
                                                por indicação.
                                            </span>
                                        </div>
                                    </GridItem>
                                )}
                            </Styled.Background>
                        </Grid>
                    </PaperContainer>
                </Fade>
            </ModalContainer>
        </Fragment>
    )
}
CustomModal.propTypes = {
    children: PropTypes.element,
    open: PropTypes.bool,
    closeByClick: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
    headTitle: PropTypes.string,
    readAllImportantAlerts: PropTypes.func,
    showCheckBox: PropTypes.bool,
    hasPointsIndicator: PropTypes.bool,
    pointsIndicator: PropTypes.string,
    programCurrency: PropTypes.string,
}

CustomModal.defaultProps = {
    className: '',
    padding: '',
    shadow: '',
    headTitle: '',
    children: null,
    open: false,
    closeByClick: null,
    maxWidth: '630px',
    readAllImportantAlerts: null,
    showCheckBox: false,
    hasPointsIndicator: false,
    pointsIndicator: '',
    programCurrency: '',
}

export default withTranslation()(CustomModal)
