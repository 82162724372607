// LOGIN USER
export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE'
export const LOGOUT = 'USERS_LOGOUT'
export const GETALL_REQUEST = 'USERS_GETALL_REQUEST'
export const GETALL_SUCCESS = 'USERS_GETALL_SUCCESS'
export const GETALL_FAILURE = 'USERS_GETALL_FAILURE'
export const CHANGE_PASSWORD = 'USERS_CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAILURE = 'USERS_CHANGE_PASSWORD_FAILURE'
export const REGISTER_USER = 'USERS_REGISTER'
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
export const FETCH_GIFT_CARD = 'FETCH_GIFT_CARD'
export const FETCH_RECEIVED_CARD = 'FETCH_RECEIVED_CARD'
export const GIFT_CARD_FAILURE = 'LOGIN_GIFT_CARD_FAILURE'
// HOME

// IMAGES
export const POST_IMAGE_DATA = 'POST_IMAGE_DATA'
export const SET_FILE_DATA = 'SET_FILE_DATA'
export const FETCH_THUMBNAILS = 'FETCH_THUMBNAILS'
export const FETCH_IMAGE_FAILED = 'FETCH_IMAGE_FAILED'

// SWEET-ALERT
export const SWEET_ALERT = 'SWEET_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

// LOADER
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'

// LOCATION
export const FETCH_CITY_DATA = 'FETCH_CITY_DATA'
export const FETCH_STATE_DATA = 'FETCH_STATE_DATA'
export const FETCH_COUNTRIES_DATA = 'FETCH_COUNTRIES_DATA'
export const FETCH_ZIPCODE_DATA = 'FETCH_ZIPCODE_DATA'
export const FETCH_CITY_FAILED = 'FETCH_CITY_DATA_FAILED'
export const FETCH_STATE_FAILED = 'FETCH_STATE_FAILED'
export const FETCH_COUNTRIES_FAILED = 'FETCH_COUNTRIES_FAILED'
export const FETCH_ZIPCODE_FAILED = 'FETCH_ZIPCODE_DATA'

// MANAGEMENT - GENERAL RULES
export const FETCH_GENERAL_RULES_DATA = 'FETCH_GENERAL_RULES_DATA'
export const FETCH_GENERAL_RULES_FAILED = 'FETCH_GENERAL_RULES_FAILED'
export const FETCH_PROGRAM_DESCRIPTION_DATA = 'FETCH_PROGRAM_DESCRIPTION_DATA'
export const FETCH_PROGRAM_DESCRIPTION_FAILED =
    'FETCH_PROGRAM_DESCRIPTION_FAILED'

// CONSUMER - DIGITAL WALLET RULES DATA
export const FETCH_DIGITAL_WALLET_RULES_DATA = 'FETCH_DIGITAL_WALLET_RULES_DATA'
export const FETCH_DIGITAL_WALLET_RULES_FAILED =
    'FETCH_DIGITAL_WALLET_RULES_FAILED'

export const FETCH_REGULATION_DATA = 'FETCH_REGULATION_DATA'
export const FETCH_REGULATION_FAILED = 'FETCH_REGULATION_FAILED'

// PLATFORM RULES
export const FETCH_PLATFORM_RULES_DATA = 'FETCH_PLATFORM_RULES_DATA'
export const FETCH_PLATFORM_RULES_FAILED = 'FETCH_PLATFORM_RULES_FAILED'
export const POST_PLATFORM_RULES_DATA = 'POST_PLATFORM_RULES_DATA'
export const POST_PLATFORM_RULES_FAILED = 'POST_PLATFORM_RULES_FAILED'

// USER - BY CONSUMER ID

export const FETCH_CONSUMER_DATA = 'FETCH_CONSUMER_DATA'
export const POST_CONSUMER_DATA = 'POST_CONSUMER_DATA'
export const PUT_CONSUMER_DATA = 'PUT_CONSUMER_DATA'
export const FETCH_CONSUMER_FAILED = 'FETCH_CONSUMER_FAILED'
export const POST_CONSUMER_REGULATION_DATA = 'POST_CONSUMER_REGULATION_DATA'
export const VERIFY_DOCUMENT = 'CONSUMER_VERIFY_DOCUMENT'
export const GET_CONSUMER_BY_TOKEN = 'GET_CONSUMER_BY_TOKEN'
export const GET_CONSUMER_BY_TOKEN_FAILED = 'GET_CONSUMER_BY_TOKEN_FAILED'
export const PUT_EMAIL_TOKEN_VALIDATION = 'PUT_EMAIL_TOKEN_VALIDATION'
export const EMAIL_TOKEN_VALIDATION_FAILED = 'EMAIL_TOKEN_VALIDATION_FAILED'
export const FETCH_SMS_ACTIVATION_CODE = 'FETCH_SMS_ACTIVATION_CODE'
export const FETCH_SMS_ACTIVATION_CODE_FAILED =
    'FETCH_SMS_ACTIVATION_CODE_FAILED'
export const PUT_SMS_ACTIVATION_CODE = 'PUT_SMS_ACTIVATION_CODE'
export const SMS_ACTIVATION_CODE_FAILED = 'SMS_ACTIVATION_CODE_FAILED'

// PARTNERS
export const FETCH_PARTNERS_DATA = 'FETCH_PARTNERS_DATA'
export const FETCH_PARTNERS_FAILED = 'FETCH_PARTNERS_FAILED'

// STATEMENT
export const FETCH_STATEMENT_DATA = 'FETCH_STATEMENT_DATA'
export const FETCH_STATEMENT_DETAILS_DATA = 'FETCH_STATEMENT_DETAILS_DATA'
export const FETCH_STATEMENT_FAILED = 'FETCH_STATEMENT_FAILED'

// TIER
export const FETCH_TIER_DATA = 'FETCH_TIER_DATA'
export const FETCH_TIER_FAILED = 'FETCH_TIER_FAILED'
export const FETCH_ACTIVE_TIER_DATA = 'FETCH_ACTIVE_TIER_DATA'
export const FETCH_ACTIVE_TIER_FAILED = 'FETCH_ACTIVE_TIER_FAILED'

// CONSUMER WALLET
export const FETCH_CONSUMER_WALLET_DATA = 'FETCH_CONSUMER_WALLET_DATA'
export const FETCH_CONSUMER_WALLET_FAILED = 'FETCH_CONSUMER_WALLET_FAILED'
// SHOP HISTORY
export const FETCH_SHOP_HISTORY_DATA = 'FETCH_SHOP_HISTORY_DATA'
export const FETCH_SHOP_HISTORY_FAILED = 'FETCH_SHOP_HISTORY_FAILED'

export const FETCH_UNAUTH_USER = 'FETCH_UNAUTH_USER'

// INVOICE
export const FETCH_INVOICE_CREDIT_SOURCE = 'FETCH_INVOICE_CREDIT_SOURCE'
export const FETCH_INVOICE_CREDIT_SOURCE_FAILED =
    'FETCH_INVOICE_CREDIT_SOURCE_FAILED'
export const FETCH_MERGE_CREDIT_SOURCE = 'FETCH_MERGE_CREDIT_SOURCE'
export const FETCH_MERGE_CREDIT_FAILED = 'FETCH_MERGE_CREDIT_FAILED'
export const FETCH_INVOICE_BY_ID = 'FETCH_INVOICE_BY_ID'
export const FETCH_INVOICE_BY_ID_FAILED = 'FETCH_INVOICE_BY_ID_FAILED'
