import React, { useEffect } from 'react'
import { createContext, useState } from 'react'
import { GetGeneralRules } from '../redux/api/generalRules'

const GeneralContext = createContext({})

export const GeneralProvider = ({ children }) => {
    const [listState, setListState] = useState([])

    const [GeneralRules, setGeneralRules] = useState([])

    const getGeneralRules = () => {
        GetGeneralRules().then((res) => {
            setGeneralRules(res)
        })
    }

    useEffect(() => {
        getGeneralRules()
    }, [])

    return (
        <GeneralContext.Provider
            value={{
                listState,
                setListState,
                GeneralRules,
                getGeneralRules,
            }}
        >
            {children}
        </GeneralContext.Provider>
    )
}

export default GeneralContext
