/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Card, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { AddIcon, RemoveAllIcon, RemoveIcon } from "../Icons";
import { MuiTypography } from "../Typography";
import { MuiBox } from "../Box";
import { MuiStack } from "../Stack";
import { GridContainer, GridItem } from "../Grid";
import { useDebounce } from "use-debounce";
export var CheckList = React.memo(function (_a) {
    var options = _a.options, label = _a.label, selectorLabel = _a.selectorLabel, defaultOptions = _a.defaultOptions, getSelectedItems = _a.getSelectedItems, _b = _a.addTextLabel, addTextLabel = _b === void 0 ? "Adicionar Todas" : _b, _c = _a.removeTextLabel, removeTextLabel = _c === void 0 ? "Deletar Todas" : _c, disabled = _a.disabled, align = _a.align, showError = _a.showError, errorText = _a.errorText, maxSize = _a.maxSize, props = __rest(_a, ["options", "label", "selectorLabel", "defaultOptions", "getSelectedItems", "addTextLabel", "removeTextLabel", "disabled", "align", "showError", "errorText", "maxSize"]);
    var _d = useState([]), left = _d[0], setLeft = _d[1];
    var _e = useState([]), right = _e[0], setRight = _e[1];
    var timer = useDebounce(right, 2000)[0];
    useEffect(function () {
        var initialRight = options.filter(function (option) { return defaultOptions.includes(option); });
        setRight(initialRight);
        setLeft(options.filter(function (option) { return !defaultOptions.includes(option); }));
    }, []);
    var handleToggle = function (value, isLeft) {
        if (!disabled) {
            if (isLeft) {
                setRight(__spreadArray(__spreadArray([], right, true), [value], false));
                setLeft(left.filter(function (item) { return item !== value; }));
            }
            else {
                setRight(right.filter(function (item) { return item !== value; }));
                setLeft(__spreadArray(__spreadArray([], left, true), [value], false));
            }
        }
    };
    var handleMoveAllToRight = function () {
        if (!disabled) {
            setRight(__spreadArray(__spreadArray([], right, true), left, true));
            setLeft([]);
        }
    };
    var handleMoveAllToLeft = function () {
        if (!disabled) {
            setLeft(__spreadArray(__spreadArray([], left, true), right, true));
            setRight([]);
        }
    };
    useEffect(function () {
        if (timer) {
            if (getSelectedItems) {
                getSelectedItems(right);
            }
        }
    }, [timer]);
    var customList = function (title, items, isLeft) { return (_jsx(Card, __assign({ style: {
            border: "1px solid #c4c4c4",
            borderRadius: "5px!important",
            boxShadow: "none",
            padding: "0px 10px",
            opacity: disabled ? 0.8 : 1,
        } }, { children: _jsxs(List, __assign({ dense: true, component: "div", role: "list", sx: { width: maxSize !== null && maxSize !== void 0 ? maxSize : "100%", minWidth: "100%", height: 230, overflow: "auto" } }, { children: [isLeft && (_jsxs(ListItem, __assign({ "data-testid": "left-items-action", button: true, onClick: handleMoveAllToRight, sx: {
                        background: "#F2F2F2",
                        borderRadius: "5px",
                        padding: "10px",
                    } }, { children: [_jsx(AddIcon, { size: 25, color: "#348A34" }), _jsx(MuiTypography, __assign({ "data-testid": "add-all", fontSize: 15, fontWeight: 500, ml: 1.5 }, { children: addTextLabel !== null && addTextLabel !== void 0 ? addTextLabel : "Adicionar Todas" }))] }))), !isLeft && (_jsxs(ListItem, __assign({ "data-testid": "right-items-action", button: true, onClick: handleMoveAllToLeft, sx: {
                        background: "#F2F2F2",
                        borderRadius: "5px",
                        padding: "10px",
                    } }, { children: [_jsx(RemoveAllIcon, { size: 25, color: "#AA382E" }), _jsx(MuiTypography, __assign({ "data-testid": "remove-all", fontSize: 15, fontWeight: 500, ml: 1.5 }, { children: removeTextLabel !== null && removeTextLabel !== void 0 ? removeTextLabel : "Deletar Todas" }))] }))), items.map(function (value, index) { return (_jsxs(ListItem, __assign({ "data-testid": value, onClick: function () { return handleToggle(value, isLeft); }, sx: {
                        background: "transparent",
                        borderRadius: "5px",
                        padding: "10px",
                    } }, { children: [_jsx(ListItemIcon, { children: isLeft ? _jsx(AddIcon, { size: 26, color: "#5FB55F" }) : _jsx(RemoveIcon, { size: 30, color: "#EB6C61" }) }), _jsx(ListItemText, { primary: value })] }), index)); })] })) }))); };
    return (_jsxs(MuiBox, __assign({ sx: { margin: "auto" } }, props, { children: [_jsx(MuiStack, __assign({ mt: 1, direction: "row", justifyContent: align !== null && align !== void 0 ? align : "flex-start", alignItems: "center", spacing: 2 }, { children: _jsxs(GridContainer, __assign({ spacing: 2, justifyContent: align !== null && align !== void 0 ? align : "flex-start", alignItems: "center", style: { padding: 0 } }, { children: [_jsxs(GridItem, __assign({ lg: 6, sm: 12, xs: 12, md: 6, "data-testid": "left-items" }, { children: [_jsx(MuiTypography, __assign({ variant: "body2", fontWeight: 300, pb: 1 }, { children: label })), customList(addTextLabel !== null && addTextLabel !== void 0 ? addTextLabel : "Adicionar Todas", left, true)] })), _jsxs(GridItem, __assign({ lg: 6, sm: 12, xs: 12, md: 6, "data-testid": "right-items" }, { children: [_jsx(MuiTypography, __assign({ variant: "body2", fontWeight: 300, pb: 1 }, { children: selectorLabel })), customList(removeTextLabel !== null && removeTextLabel !== void 0 ? removeTextLabel : "Deletar Todas", right, false)] }))] })) })), showError && (_jsx(MuiBox, __assign({ height: "25px" }, { children: _jsx(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: "#BE2C2C" }, { children: errorText !== null && errorText !== void 0 ? errorText : " " })) })))] })));
});
