import { Dialog, Paper } from "@material-ui/core";
import styled from "styled-components";

export const ModalContainer = styled(Dialog)`
  .MuiDialog-scrollPaper {
    margin-top: 130px;
    align-items: flex-start;
  }
  .MuiDialog-paperWidthSm {
    width: 100%;
    max-width: ${({ maxwidth }) => maxwidth};
  }
`;

export const PaperContainer = styled(Paper)`
  padding: 20px;
  .header-1 {
    .line {
      height: 3px;
      background-color: #21409a;
	  margin-left: 15px;
      width: 100%;
      @media (max-width: 768px) {
        margin-left: 20px;
		margin-right: -10px;
      }
    }
    span,
    .close {
      font-size: 14px;
      font-weight: 700;
      color: #f44336;
    }
    .close {
      span {
        font-weight: initial;
      }
      &:hover {
        span {
          opacity: 0.5;
        }
      }
    }
  }

  .header-2 {
    .line {
      margin-left: -20px;
      height: 3px;
      background-color: #21409a;
      width: 100%;
    }
  }

  padding: ${({ padding }) => padding};
  box-shadow: ${({ shadow }) => shadow};

  .mb-2 {
    margin-bottom: 10px;
  }
`;
