import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const TabEmpty = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 100px;
    gap: 20px;

    i {
        width: 50px;
        height: 50px;
        font-size: 50px;
        color: #b4b4b4;
        opacity: 1;
    }

    p {
        font: normal normal normal 15px/21px Roboto;
        letter-spacing: 0px;
        color: #b4b4b4;
        opacity: 1;
    }
`

export const TabSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
    align-items: baseline;
    margin-bottom: ${({ open }) => (open ? '14px' : '0px')};
`
