import {
    Grid,
    Hidden,
    InputAdornment,
    TextField,
    Tooltip,
    withStyles,
} from '@material-ui/core'
import styled from 'styled-components'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

export const Container = styled(Grid)`
    display: flex;
    align-items: center;
    position: relative;
    
    .MuiFormHelperText-root {
        position: absolute;
        top: 40px;
    }
`

export const CustomHidden = styled(Hidden)`
    display: flex;
`

export const CssTextField = withStyles({
    root: {
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -14px) scale(0.75)',
        },
        '& label.Mui-focused': {
            color: grayColor[2],
        },
        '& label': {
            fontSize: '14px',
            lineHeight: 1.2,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: grayColor[2],
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: grayColor[2],
            },
            '&.Mui-focused fieldset': {
                borderColor: grayColor[2],
            },
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '0px',
        },

        '& .input:-internal-autofill-selected, .input:-webkit-autofill, .input:-webkit-autofill:hover, .input:-webkit-autofill:focus, .input:-webkit-autofill:active':
            {
                '-webkit-box-shadow': '0 0 0 30px white inset !important',
            },
    },
})(TextField)

export const SmToolTip = withStyles({
    arrow: {
        color: '#5d5d5d',
        '@media (min-width: 768px)': {
            color: '#333333',
            marginLeft: '-278px !important',
        },
    },
    tooltip: {
        backgroundColor: '#5d5d5d',
        fontSize: 10,
        width: '100%',
        maxWidth: '120px',
        borderRadius: 8,
        '@media (min-width: 768px)': {
            borderRadius: 4,
            maxWidth: '270px',
            backgroundColor: '#333333',
        },
    },
    tooltipPlacementRight: {
        marginLeft: 8,
        '@media (min-width: 768px)': {
            marginLeft: 0,
        },
    },
    tooltipPlacementTop: {
        marginLeft: 142,
    },
})(Tooltip)

export const CustomEndAdornment = styled(InputAdornment)`
    cursor: pointer;
    padding-right: 0.5rem;

    i {
        color: #21409a;
        font-size: 1rem;
        right: 10px;
    }
`
