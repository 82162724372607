/* eslint-disable */
import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { Grid, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import asterisk from 'assets/img/asterisk.svg'

import * as Style from './styles'
import RegisterInput, { CellPhoneMaskCustom } from 'components/RegisterInput'
import { useTranslation } from 'react-i18next'
import { VerifyPhone } from '../../../../redux/api/location'
import { ValidateEmail } from '../../helpers/validation'
import {
    CellPhoneIsValid,
    EmailIsValid,
} from '../PersonalData/helpers/errorsTreatment'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { Asterisk } from '../../../../assets/icons_svg'

const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

function ContactData({
    errors,
    control,
    watch,
    setError,
    preDisabled,
    setValue,
    clearErrors,
    disableItems,
}) {
    const { t } = useTranslation()
    const [timerStarted, setTimerStarted] = useState(false)
    const [themeColor, setThemeColor] = useState(GetThemeColorFromStorage())

    useEffect(() => {
        if (watch('phoneNumber2')?.replace(/[^\d]+/g, '')?.length > 10) {
            const x = watch('phoneNumber2')?.replace(/[^\d]+/g, '')
            VerifyPhone(x).then((res) => {
                if (res.success === false && res?.errors?.length > 0) {
                    const data = res?.errors[0]
                    setError('phoneNumber2', {
                        type: 'manual',
                        message: t(data?.message),
                    })
                }
            })
        }
    }, [watch('phoneNumber2')])

    useEffect(() => {
        if (!_.isEmpty(watch('email'))) {
            if (regex.test(watch('email'))) EmailValidate(watch('email'))
        }
    }, [watch('email')])

    const EmailValidate = async (email) => {
        try {
            const res = await ValidateEmail(email)

            if (res === false) {
                setError('email', {
                    type: 'manual',
                    message: t('email - DUPLICATED'),
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const DisableEmailOrPhoneNumber2 = (type) => {
        if (preDisabled) {
            if (type === 1) {
                if (
                    watch('phoneNumber2Pre') !== watch('phoneNumber2') &&
                    _.isEmpty(watch('emailPre')) === false
                ) {
                    return true
                }
            }
            if (type === 2) {
                if (
                    watch('emailPre') !== watch('email') &&
                    _.isEmpty(watch('phoneNumber2Pre')) === false
                ) {
                    return true
                }
            }
        }

        return false
    }

    const handleKeyUp = () => {
        if (!timerStarted) {
            setTimerStarted(true)
        }
    }

    useEffect(() => {
        let typingTimer

        if (preDisabled) {
            if (!timerStarted) {
                setTimerStarted(true)
            }

            clearTimeout(typingTimer)
            typingTimer = setTimeout(() => {
                let email = watch('email') || ''

                if (!_.isEmpty(watch('emailPre')) && _.isEmpty(email)) {
                    setValue('email', watch('emailPre'))
                    clearErrors('email')
                }
                if (
                    !_.isEmpty(watch('phoneNumber2Pre')) &&
                    _.isEmpty(watch('phoneNumber2'))
                ) {
                    setValue('phoneNumber2', watch('phoneNumber2Pre'))
                    clearErrors('phoneNumber2')
                }
            }, 3000)
        }

        return () => clearTimeout(typingTimer)
    }, [watch('email'), watch('phoneNumber2')])

    return (
        <Style.Container
            container
            item
            xs={12}
            spacing={2}
            alignItems="flex-start"
        >
            <Style.CustomTitle>
                <b>{t('TITLE_REGISTER_CONTACT')}</b>
            </Style.CustomTitle>

            <Grid
                item
                container
                xs={12}
                md={6}
                alignItems="center"
                style={{ marginTop: '-2.5px' }}
            >
                <Controller
                    as={
                        <RegisterInput
                            label="E-mail"
                            disabled={
                                DisableEmailOrPhoneNumber2(1) ||
                                disableItems?.phone
                            }
                            xs={12}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                            onKeyUp={handleKeyUp}
                            error={!!errors?.email}
                            helperText={t(errors?.email?.message)}
                        />
                    }
                    name="email"
                    control={control}
                    id="email"
                />

                <Style.CustomAlert>
                    <Asterisk
                        style={{
                            color: themeColor?.visual?.icons?.color,
                        }}
                    />
                    <Grid
                        item
                        xs={11}
                        style={{
                            fontSize: '0.8rem',
                            paddingLeft: '0.5rem',
                        }}
                    >
                        {t('IT_IS_IMPORTANT_TO_FILL_IN_EMAIL')}
                    </Grid>
                </Style.CustomAlert>
            </Grid>
            <Controller
                as={
                    <RegisterInput
                        label={t('FIELD_REGISTER_MOBILE_PHONE')}
                        xs={12}
                        md={2}
                        disabled={
                            DisableEmailOrPhoneNumber2(2) || disableItems?.email
                        }
                        onKeyUp={handleKeyUp}
                        error={!!errors.phoneNumber2}
                        helperText={t(errors?.phoneNumber2?.message)}
                        InputProps={{
                            inputComponent: CellPhoneMaskCustom,
                        }}
                    />
                }
                name="phoneNumber2"
                control={control}
                id="phoneNumber2"
            />
            <Controller
                as={<TextField style={{ display: 'none' }} />}
                name="emailPre"
                control={control}
                id="emailPre"
            />
            <Controller
                as={<TextField style={{ display: 'none' }} />}
                name="phoneNumber2Pre"
                control={control}
                id="phoneNumber2Pre"
            />
        </Style.Container>
    )
}

export default memo(ContactData)
