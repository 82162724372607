/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@mui/material";
export function DefaultButton(_a) {
    var text = _a.text, defaultColor = _a.defaultColor, customSize = _a.customSize, icon = _a.icon, outlined = _a.outlined, border = _a.border, colorText = _a.colorText, props = __rest(_a, ["text", "defaultColor", "customSize", "icon", "outlined", "border", "colorText"]);
    return (_jsxs(Button, __assign({ variant: outlined ? "outlined" : "contained", sx: {
            backgroundColor: defaultColor && "#329EF1",
            height: customSize,
            width: customSize,
            border: border,
            color: colorText,
        } }, props, { children: [text !== null && text !== void 0 ? text : "", icon !== null && icon !== void 0 ? icon : ""] })));
}
