import { Grid, Tooltip, withStyles } from '@material-ui/core'
import styled from 'styled-components/macro'

export const Container = styled(Grid)`
    .MuiFormHelperText-root {
        position: inherit !important;
        top: 0 !important;
    }
`

export const BoxPolicy = styled.div`
    ul {
        margin: 0;
    }
`

export const PoliticyText = styled.small`
    cursor: pointer;
    color: ${({ themeColor }) => themeColor?.titles?.highlight?.textColor};
    text-decoration: underline;
    text-decoration-style: dotted;
`

export const PolicyTooltip = withStyles({
    arrow: {
        color: '#5d5d5d',
    },
    tooltip: {
        backgroundColor: '#5d5d5d',
        fontSize: 10,
        width: '100%',
        borderRadius: 8,
    },
    tooltipPlacementRight: {
        marginLeft: 8,
    },
    tooltipPlacementLeft: {
        marginLeft: 12,
    },
    '@media (max-width:1000px) and (min-width: 960px)': {
        // eslint-disable-line no-useless-computed-key
        tooltip: {
            marginTop: '800px',
        },
    },
    '@media (max-width:959px) and (min-width: 751px)': {
        // eslint-disable-line no-useless-computed-key
        tooltip: {
            marginTop: '1380px',
        },
    },
    '@media (max-width:750px) and (min-width: 597px)': {
        // eslint-disable-line no-useless-computed-key
        tooltip: {
            marginTop: '1300px',
        },
    },
    '@media (max-width:596px) and (min-width: 427px)': {
        // eslint-disable-line no-useless-computed-key
        tooltip: {
            marginTop: '1650px',
        },
    },
    '@media (max-width:426px) and (min-width: 377px)': {
        // eslint-disable-line no-useless-computed-key
        tooltip: {
            marginTop: '1650px',
        },
    },
    '@media (max-width:376px) and (min-width: 322px)': {
        // eslint-disable-line no-useless-computed-key
        tooltip: {
            marginTop: '1670px',
        },
    },
    '@media (max-width:321px)': {
        // eslint-disable-line no-useless-computed-key
        tooltip: {
            marginTop: '1700px',
        },
    },
})(Tooltip)

export const PolicyTooltipResponsive = styled.div`
    display: none;

    @media (max-width: 599px) {
        display: block;
        width: 100%;
        background-color: #5d5d5d;
        color: white;
        padding: 15px 0px;
        border-radius: 15px;
    }
`

export const ArrowPolicyTooltipResponsive = styled.div`
    display: none;

    @media (max-width: 599px) {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 100px 100px 100px;
        border-color: transparent transparent #5d5d5d transparent;
    }
`

export const CustomTitle = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 10px 0px 6.5px 8px;
    width: 100%;
`
