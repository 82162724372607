import React from 'react'
import NumberFormat from 'react-number-format'
import * as _ from 'lodash'
import { format } from 'date-fns'

import { createMuiTheme } from '@material-ui/core/styles'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

export const moneyFormatValue = (value) => {
    if (value.length < 20) {
        var v = parseFloat(value)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        return v
    }
}
export function formatCurrency(value, redeemedValueGreaterThanZero) {
    const currencyMask = new Intl.NumberFormat(
        redeemedValueGreaterThanZero ? 'en-US' : 'pt-br',
        {
            style: 'currency',
            currency: redeemedValueGreaterThanZero ? 'USD' : 'BRL',
        },
    )
    let result = null

    if (redeemedValueGreaterThanZero) {
        result = `SD$ ${currencyMask.format(value).replace('$', '')}` ?? '-'
    } else {
        result = `R$ ${currencyMask.format(value).replace('R$', '')}` ?? '-'
    }
    return (value && result) || '-'
}

export const numberFormatText = (_data, _prefix, _toFix, _isSeed) => {
    let _toFixed = _toFix === undefined ? 0 : _toFix
    let tSeparator = '.'
    let dSeparator = ','
    //
    if (localStorage.getItem('i18nextLng') !== null) {
        if (
            localStorage.getItem('i18nextLng') === 'pt-BR' ||
            localStorage.getItem('i18nextLng') === 'es'
        ) {
            tSeparator = '.'
            dSeparator = ','
        } else {
            tSeparator = ','
            dSeparator = '.'
        }
    }
    //
    if (_isSeed) {
        tSeparator = '.'
        dSeparator = ','
    }
    //
    let v =
        _toFixed === 0 ? _.toInteger(_data) : Number(_data).toFixed(_toFixed)
    // console.log('T: ', tSeparator)
    // console.log('D: ', dSeparator);
    // console.log('V: ', v);
    // console.log('-----------------------')
    //
    return (
        <NumberFormat
            value={v}
            displayType={'text'}
            thousandSeparator={tSeparator}
            decimalSeparator={dSeparator}
            decimalScale={2}
            prefix={_prefix}
            isNumericString={true}
        />
    )
}

export const themeUtil = () => {
    return createMuiTheme({
        overrides: {
            MuiButtonBase: {
                // Name of the component ⚛️ / style sheet
                root: {
                    // Name of the rule
                    border: '1px solid green !important',
                    borderRadius: '3px',
                    padding: '0 12px 0 12px !important',
                    maxWidth: '32px !important',
                    height: '33px',
                    margin: '3px',
                    '&:hover': {
                        background: 'green !important',
                        color: 'white',
                    },
                },
            },
        },
        palette: {
            primary: {
                main: germiniDefaultColors[0],
            },
        },
        typography: {
            useNextVariants: true,
        },
    })
}

export const VerifyCPF = (cpf) => {
    let valido = true
    cpf = cpf.replace(/[^\d]+/g, '')
    if (cpf === '') valido = false
    // Elimina CPFs invalidos conhecidos
    if (
        cpf.length !== 11 ||
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
    ) {
        valido = false
    }
    // Valida 1o digito
    let add = 0
    for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i)
    }
    let rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) {
        rev = 0
    }
    if (rev !== parseInt(cpf.charAt(9))) {
        valido = false
    }
    // Valida 2o digito
    add = 0
    for (let i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i)
    }
    rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) {
        rev = 0
    }
    if (rev !== parseInt(cpf.charAt(10))) {
        valido = false
    }
    //
    if (valido) {
        // console.log('O CPF INFORMADO É VÁLIDO.');
    } else {
        // console.log("o CPF é inválido");
    }
    return valido
}

export const VerifyCNPJ = (cnpj) => {
    let valido = true
    cnpj = cnpj.replace(/[^\d]+/g, '')
    if (cnpj === '') return false
    if (cnpj.length !== 14) {
        valido = false
    }

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        valido = false
    }

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho)
    let digitos = cnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) {
            pos = 9
        }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== digitos.charAt(0)) {
        valido = false
    }

    tamanho = tamanho + 1
    numeros = cnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) {
            pos = 9
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado !== digitos.charAt(1)) {
        valido = false
    }
    //
    if (valido) {
        // console.log('O CNPJ INFORMADO É VÁLIDO.');
    } else {
        // console.log("o CNPJ é inválido");
    }
    return valido
}

export const VerifyCPFCNPJ = (document) =>
    VerifyCPF(document) || VerifyCNPJ(document)

export const FormatCPF = (cpf) => {
    return (
        cpf.substr(0, 3) +
        '.' +
        cpf.substr(3, 3) +
        '.' +
        cpf.substr(6, 3) +
        '-' +
        cpf.substr(9, 2)
    )
}

export const FormatCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '')
    //cnpj.substr(0, 2) + "." + cnpj.substr(2, 3) + "." + cnpj.substr(5, 3) + "/" + cnpj.substr(9, 4) + "-" + cnpj.substr(12, 2);
    return (
        cnpj.substr(0, 2) +
        '.' +
        cnpj.substr(2, 3) +
        '.' +
        cnpj.substr(5, 3) +
        '/' +
        cnpj.substr(8, 4) +
        '-' +
        cnpj.substr(12, 2)
    )
}

export const formatDate = (date) => {
    if (!date) return ''
    const formattedDate = format(new Date(date), 'MM-dd-yyyy')

    return formattedDate
}

export const formatCellAndTell = (tel) => {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/
    var str = tel?.replace(/[^0-9]/g, '').slice(0, 11)

    const result = str?.replace(regex, '($1) $2-$3')

    return result ?? ''
}

export const formatCellHidden = (tel) => {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/

    const result = tel?.replace(regex, '$1-$2-$3')

    const resultNew = result?.split('-')
    return `(${resultNew[0]}) **** - ${resultNew[2] ?? ''}`
}

export const getProgramTitle = (subTitle) => {
    try {
        const programName = localStorage.getItem('programName')

        return programName && subTitle
            ? `${programName} - ${subTitle}`
            : programName || subTitle || ''
    } catch (error) {
        console.log('Error: ', error)
        return subTitle
    }
}
