import axios from 'axios';

export const ImageUpload = (obj) => {
    const formData = new FormData();
    formData.append("File", obj);
    return axios.post(`/Images/Upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => response.data);
}

export const GetThumbnails = () =>
    axios.get(`/Images/thumbnails`)
        .then(response => response.data);
