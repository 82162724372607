/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar, Button, styled } from "@mui/material";
import { MdPhotoCamera } from "react-icons/md";
var VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
var CameraIconContainer = styled("div")({
    position: "absolute",
    right: 0,
    borderRadius: "50%",
    background: "#1B9CAC",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    color: "#FFFFFF",
    marginRight: "-13px",
});
export function MuiAvatar(_a) {
    var avatarUrl = _a.avatarUrl, avatarText = _a.avatarText, sx = _a.sx, width = _a.width, height = _a.height, onChange = _a.onChange, isUploadFile = _a.isUploadFile, border = _a.border, iconCamera = _a.iconCamera, props = __rest(_a, ["avatarUrl", "avatarText", "sx", "width", "height", "onChange", "isUploadFile", "border", "iconCamera"]);
    return (_jsx(_Fragment, { children: _jsxs(Button, __assign({ component: "label", variant: "contained", sx: {
                background: "transparent",
                border: "none",
                borderRadius: "50%",
                boxShadow: "none",
                padding: 0,
                "&:hover": {
                    background: "transparent",
                    boxShadow: "none",
                },
            } }, { children: [_jsx(Avatar, __assign({ alt: avatarText, src: avatarUrl }, props, { sx: { width: width, height: height, border: border }, type: "file" })), isUploadFile && (_jsx(VisuallyHiddenInput, __assign({ type: "file" }, props, { onChange: onChange }))), iconCamera && (_jsx(CameraIconContainer, { children: _jsx(MdPhotoCamera, { style: { width: "20px", height: "20px" } }) }))] })) }));
}
