import React from "react";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import axios from "axios";

import {
	withStyles,
} from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SectionPolicy from "components/Section/SectionPolicy.jsx";
import RenderRegulation from "components/RenderRegulation/index.js";

import { ContainerRegister } from "./styles";
import style from "../register.module.scss";
import registerStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import logo from "assets/img/logo_cvale_fidelidade_header.png";

import compose from "utils/compose";
import * as moment from "moment";
import { getProgramTitle } from "../../../utils/utils";
require("moment/locale/pt-br");

class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			textUT: "",
		};
		if (localStorage.getItem("i18nextLng") !== null) {
			moment.locale(localStorage.getItem("i18nextLng"));
		} else {
			moment.locale("pt-BR");
		}
	}

	componentDidMount() {
		axios.get(`/PlatformRules`).then((response) => {
			this.setState({ textUT: response.data.useTerms });
		});
	}

	render() {
		const { t } = this.props;

		const _title = localStorage.getItem("programName") ? `${localStorage.getItem("programName")}` : `Fidelidade - ${t("TITLE_PLATFORM_RULES_USE_TERMS_AND_POLICY")}`
		return (
			<ContainerRegister>
				<DocumentTitle title={getProgramTitle(_title)} />
				<GridContainer justify="center" className={style.Container}>
					<div className={style.parent}>
						<div className={style.headerContainer}>
							<GridItem xs={12} sm={12} md={9} lg={9} className={style.logoBox}>
								<img className={style.logo} src={logo} alt="Logo" />
							</GridItem>
						</div>
					</div>
				</GridContainer>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<GridItem xs={12} sm={12} md={12} lg={12} style={{ margin: "10px 10% 32px" }}>
						<SectionPolicy title={t("TITLE_PLATFORM_RULES_USE_TERMS_AND_POLICY")} />
					</GridItem>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={12}>
					<section>
						<article>
							<GridItem
								xs={12}
								sm={12}
								md={12}
								lg={12}
								style={{ margin: "0px 10% 5% 10%" }}
							>
								<RenderRegulation renderComp={this.state.textUT} />
							</GridItem>
						</article>
					</section>
				</GridItem>
				<GridContainer>
					<div className={style.footer}></div>
				</GridContainer>
			</ContainerRegister>
		);
	}
}

export default compose(
	withStyles(registerStyle),
	withTranslation()
)(Register);
