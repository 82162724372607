import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
   alert: null
};

const sweetAlert = (state, action) => {
    return updateObject(state, {
        alert: action.alert
    });
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SWEET_ALERT:
        case actionTypes.HIDE_ALERT:
            return sweetAlert(state, action); 
        default:
            return state;
    }
};

export default dashboardReducer;