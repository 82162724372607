/*  eslint-disable */
import React, {
    memo,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import { Grid } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import {
    FindZipCode,
    GetCity,
    GetCountries,
    GetState,
} from '../../../../redux/api/location'

import * as Style from './styles'
import {
    CEPMaskCustom,
    RegisterInputNotRequired,
    RegisterInput,
} from 'components/RegisterInput'
import { useTranslation } from 'react-i18next'
import GeneralContext from '../../../../Context'

function AddressData({
    errors,
    control,
    setValue,
    watch,
    userCpf,
    token,
    setError,
    preDisabled,
    setUfs,
}) {
    const { t } = useTranslation()
    const { setListState } = useContext(GeneralContext)
    const [blockedState, setBlockedState] = useState({
        zipCode: false,
        location: false,
        number: false,
        district: false,
        cityId: false,
        stateId: false,
        countryId: true,
    })

    const [list, setList] = useState({
        countries: [],
        states: [],
        cities: [],
    })

    const values = watch()

    const getList = useCallback(async () => {
        try {
            const countries = await GetCountries()

            if (!countries || countries.length === 0) {
                console.log('No countries found.')
            }

            const country = countries.find(
                (country) =>
                    country.name === 'Brazil' || country.name === 'Brasil',
            )

            if (!country) {
                console.log('Brazil not found in countries list.')
            }

            const states = await GetState(country.id)

            if (!states || states.length === 0) {
                console.log(`No states found for country ${country.name}.`)
            }

            setList({ states, countries })
            setListState(states)
        } catch (error) {
            console.log('Failed to load countries or states:', error)
        }
    }, [])

    useEffect(() => {
        if (values?.address?.zipCode?.replace('-', '')?.trim()?.length === 8) {
            FindZipCode(values?.address?.zipCode).then((res) => {
                if (!res.zipCode) {
                    setError('address.zipCode', {
                        type: 'manual',
                        message: t('ZipCode - NOT_FOUND'),
                    })
                }
            })
        }
    }, [values?.address?.zipCode])

    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        if (values?.address?.zipCode === '') {
            setValue(
                'address',
                {
                    zipCode: '',
                    location: '',
                    number: '',
                    complement: '',
                    district: '',
                    cityId: '',
                    stateId: '',
                    countryId: '',
                },
                { shouldValidate: false },
            )
        }
    }, [values?.address?.zipCode])

    const searchZipcode = useCallback(
        async (zipCode) => {
            setBlockedState((prevState) => ({ ...prevState, zipCode: true }))

            const hasZipCode = await FindZipCode(zipCode)

            setUfs(hasZipCode.state)

            if (hasZipCode.state && hasZipCode.city) {
                setValue('address', { ...hasZipCode }, { shouldValidate: true })

                return setBlockedState((prevState) => ({
                    ...prevState,
                    zipCode: false,
                    location: hasZipCode.location ? true : false,
                    district: hasZipCode.district ? true : false,
                    cityId: true,
                    stateId: true,
                }))
            } else {
                setValue(
                    'address',
                    {
                        zipCode: '',
                        location: '',
                        number: '',
                        complement: '',
                        district: '',
                        cityId: '',
                        stateId: '',
                        countryId: '',
                    },
                    { shouldValidate: false },
                )
            }

            setValue('addres', {}, { shouldValidate: true })

            return setBlockedState((prevState) => ({
                ...prevState,
                zipCode: false,
                location: false,
                district: false,
                cityId: false,
                stateId: false,
            }))
        },
        [setUfs, setValue],
    )

    useEffect(() => {
        if (values?.address?.zipCode?.replace('-', '')?.trim()?.length === 8) {
            searchZipcode(values?.address?.zipCode)
        }
    }, [values?.address?.zipCode])

    useEffect(() => {
        if (values?.address?.stateId) {
            GetCity(values.address.stateId)
                .then((cities) => {
                    setList((prevState) => ({ ...prevState, cities }))
                })
                .then((_) => {
                    if (userCpf.data) {
                        setValue('address.cityId', userCpf.data.address.city.id)
                    }
                })
        }
    }, [userCpf?.data, values?.address?.stateId])

    // console.log('AddressData', list)
    return (
        <Style.Container container item xs={12} alignItems="flex-start">
            <Style.CustomTitle>
                <b>{t('TITLE_REGISTER_ADDRESS')}</b>
            </Style.CustomTitle>
            <Grid
                container
                item
                xs={12}
                // style={{ margin: '8px 0px 0px 8px' }}
                spacing={2}
            >
                <Controller
                    as={
                        <RegisterInput
                            label="CEP"
                            xs={12}
                            sm={4}
                            md={2}
                            style={{ alignItems: 'flex-start' }}
                            InputProps={{
                                inputComponent: CEPMaskCustom,
                            }}
                            disabled={
                                userCpf.blockCpf || blockedState.zipCode
                                // || (preDisabled && !token)
                            }
                            error={!!errors?.address?.zipCode}
                            helperText={t(errors?.address?.zipCode?.message)}
                        />
                    }
                    name="address.zipCode"
                    control={control}
                    id="cep"
                />
                <Controller
                    as={
                        <RegisterInput
                            xs={12}
                            sm={8}
                            md={4}
                            style={{ alignItems: 'flex-start' }}
                            label={t('TITLE_REGISTER_ADDRESS')}
                            disabled={
                                userCpf.blockCpf || blockedState.location
                                // ||  (preDisabled && !token)
                            }
                            error={!!errors?.address?.location}
                            helperText={t(errors?.address?.location?.message)}
                        />
                    }
                    name="address.location"
                    control={control}
                    id="location"
                />
                <Controller
                    as={
                        <RegisterInput
                            label="Nº"
                            xs={12}
                            style={{ alignItems: 'flex-start' }}
                            className="address_number"
                            sm={6}
                            md={1}
                            lg={1}
                            disabled={
                                userCpf.blockCpf || blockedState.number
                                // ||  (preDisabled && !token)
                            }
                            error={!!errors?.address?.number}
                            helperText={t(errors?.address?.number?.message)}
                        />
                    }
                    name="address.number"
                    control={control}
                    id="number"
                />
                <Controller
                    as={
                        <RegisterInputNotRequired
                            label={t('FIELD_REGISTER_EXTRA_INFO')}
                            xs={12}
                            sm={6}
                            md={2}
                            style={{ alignItems: 'flex-start' }}
                            disabled={
                                userCpf.blockCpf || blockedState.aditionalInfo
                                // || (preDisabled && !token)
                            }
                            error={!!errors?.address?.aditionalInfo}
                            helperText={t(
                                errors?.address?.aditionalInfo?.message,
                            )}
                        />
                    }
                    name="address.aditionalInfo"
                    control={control}
                    id="aditionalInfo"
                />
                <Controller
                    as={
                        <RegisterInput
                            label={t('FIELD_REGISTER_NEIGHBORHOOD')}
                            xs={12}
                            sm={6}
                            md={3}
                            style={{ alignItems: 'flex-start' }}
                            disabled={
                                userCpf.blockCpf || blockedState.district
                                //  ||  (preDisabled && !token)
                            }
                            error={!!errors?.address?.district}
                            helperText={t(errors?.address?.district?.message)}
                        />
                    }
                    name="address.district"
                    control={control}
                    id="district"
                />
                <Controller
                    render={(props) => (
                        <RegisterInput
                            label={t('FIELD_REGISTER_CITY')}
                            select
                            xs={12}
                            sm={6}
                            md={2}
                            style={{ alignItems: 'flex-start' }}
                            disabled={
                                userCpf.blockCpf || blockedState.cityId
                                // ||  (preDisabled && !token)
                            }
                            list={list.cities}
                            error={!!errors?.address?.cityId}
                            helperText={t(errors?.address?.cityId?.message)}
                            {...props}
                        />
                    )}
                    name="address.cityId"
                    control={control}
                    id="cityId"
                />

                <Controller
                    render={(props) => (
                        <RegisterInput
                            label="UF"
                            select
                            xs={12}
                            sm={6}
                            md={2}
                            style={{ alignItems: 'flex-start' }}
                            disabled={
                                userCpf.blockCpf || blockedState.stateId
                                // || (preDisabled && !token)
                            }
                            list={list.states}
                            error={!!errors?.address?.stateId}
                            helperText={t(errors?.address?.stateId?.message)}
                            {...props}
                        />
                    )}
                    name="address.stateId"
                    control={control}
                    id="stateId"
                />

                <Controller
                    render={(props) => (
                        <RegisterInput
                            label={t('COUNTRY')}
                            value="Brasil"
                            select
                            xs={12}
                            sm={6}
                            md={2}
                            defaultValue="Brasil"
                            style={{ alignItems: 'flex-start' }}
                            disabled={
                                userCpf.blockCpf || blockedState.countryId
                                // || (preDisabled && !token)
                            }
                            list={list.countries}
                            error={!!errors?.address?.countryId}
                            helperText={t(errors?.address?.countryId?.message)}
                            {...props}
                        />
                    )}
                    name="address.countryId"
                    control={control}
                    id="countryId"
                />
            </Grid>
        </Style.Container>
    )
}

export default memo(AddressData)
