import React from 'react'
import { withTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'
import * as _ from 'lodash'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import Section from 'components/Section/Section'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

// Utils
import compose from 'utils/compose'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ValidateDocument } from '../../redux/actions'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

//import logo from 'assets/img/logo_cvale_fidelidade_header.png'
import style from './authenticate.module.scss'
// @Assets and Styles
import {
	Box,
	BoxFooter,
	BoxImage,
	Container,
	ContainerButton,
	GridContainerFooter,
	GridItemText,
	LinkEmail,
	LinkText,
	Snack,
} from './styles'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import registerStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import getFromStorage from './helpers/getFromStorage'

import Axios from 'axios'
import SnackDefault from 'components/SnackDefault'
import { envs } from '../../redux/api/windowServerData'
import { getProgramTitle } from '../../utils/utils'
import { GetContact } from '../../redux/api/footer'
import { GetTenantVersion } from '../../redux/api/About'

class AuthenticateAccess extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			alert: '',
			tenant: '',
			alertError: '',
			themeColor: GetThemeColorFromStorage(),
			snackStatus: Snack,
			consumerData: {},
		}
	}

	getTenantVersion = async () => {
		try {
			const response = await GetTenantVersion()

			if (response?.status === 200 && response?.data) {
				this.setState({
					tenant: response.data,
				})
			}
		} catch (error) {
			console.log('error', error)
		}
	}

	sendEmailConfirmationToken = async (e) => {
		const { t } = this.props
		const cpfUser = getFromStorage('cpfUser')
		const consumerData = getFromStorage('consumerSMS')
		const cpf = cpfUser ?? consumerData?.consumer?.cpf

		const response = await Axios(
			// `${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendEmailConfirmationToken?cpfCnpj=${cpf}`,
			`${envs.REACT_APP_GERMINI_API_URL}/Consumer/SendEmailConfirmationToken?cpfCnpj=${cpf}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json-patch+json',
					appId: 'CSM1803',
					// companyInternalName: 'germini',
				},
				Accept: 'application/json',
				body: `{}`,
			},
		)

		const { status, data } = response

		if (status === 200) {
			const { errors } = data

			if (errors?.length > 0) {
				this.setState({
					snackStatus: {
						open: true,
						text: t(errors[0].message),
						severity: 'error',
					},
				})
				return
			}

			this.setState({
				snackStatus: {
					open: true,
					text: 'E-mail enviado com sucesso!',
					severity: 'success',
				},
			})
		}
	}

	GetContactInfo = async () => {
		const contact = await GetContact()
		contact && this.setState({ consumerData: contact })
	}

	componentDidMount() {
		this.GetContactInfo()
		if (this.state.tenant === '') {
			this.getTenantVersion()
		}
	}

	render() {
		const { t } = this.props

		const handleLogin = () => {
			this.props.history.push(`/auth/login`)
		}
		const germiniLogoInterna =
			this.state?.themeColor?.images?.internalPages?.menuLogo?.url
		const menuBackground =
			this.state.themeColor?.images?.internalPages?.menuBackground?.url

		const _title =
			localStorage.getItem('programName') ??
			`Fidelidade - ${t('TITLE_REGISTER_CONFIRMATION')}`

		return (
			<Container>
				{this.state.alert}
				{this.state.alertError}
				<DocumentTitle title={getProgramTitle(_title)} />

				<GridContainer
					justify="center"
					className={style.Container}
					style={{ paddingBottom: '12rem' }}
				>
					<SnackDefault
						snackStatus={this.state.snackStatus}
						handleCloseSnack={() =>
							this.setState({ snackStatus: Snack })
						}
					/>
					<BoxImage
						className={style.parent}
						themeColor={menuBackground}
					>
						<div className={style.headerContainer}>
							<div
								className={style.logoBox}
								onClick={() => {
									this.props.history.goBack()
								}}
							>
								<img
									className={style.logo}
									src={germiniLogoInterna}
									alt="Logo"
								/>
							</div>
						</div>
					</BoxImage>

					<GridItem xs={11} md={8} className={style.Content}>
						<div className={style.boxInfo}>
							<div className={style.boxInfoText}>
								<b>CADASTRE-SE</b> e tenha muitas{' '}
								<b>VANTAGENS</b>
								<br />
								no <b>MUNDO</b> do agronegócio.
							</div>
							<ContainerButton
								themeColor={this.state.themeColor}
								onClick={handleLogin}
							>
								Já sou cadastrado
								<br />
								<b>ACESSAR</b>
							</ContainerButton>
						</div>
						{/* #1 DADOS PESSOAIS */}
						<GridContainer>
							<GridItem>
								<Section
									title={t('TITLE_ACCESS_AUTHENTICATION')}
								/>
							</GridItem>

							<GridItem
								xs={12}
								md={12}
								style={{
									margin: '7px 0',
									lineHeight: '2em',
								}}
								className="text-justify"
							>
								Foi enviado um link para o seu e-mail,{' '}
								<b>acesse</b> e <b>confirme</b> o seu cadastro.
								<br />
								Se o e-mail não estiver na caixa de entrada,
								verifique suas pastas. Ele pode ter sido movido
								por um filtro de spam ou regra de e-mail, nesse
								caso ele poderá estar na pasta Spam,
								Indesejadas, Lixo, Itens Apagados ou Arquivo.
								<br />
								É importante que seu número de telefone esteja
								correto, pois você receberá um sms com o código
								de ativação após a confirmação do seu e-mail.
								<br />
								{/* <SectionText> */}
								Caso você não receba o e-mail, clique{' '}
								<LinkText
									onClick={this.sendEmailConfirmationToken}
								>
									aqui
								</LinkText>{' '}
								para o e-mail ser reenviado.
								{/* </SectionText> */}
							</GridItem>
						</GridContainer>

						<GridContainer
							style={{
								paddingTop: '30px',
								lineHeight: '2em',
							}}
						>
							<GridItemText
								xs={12}
								md={12}
								grayColor={grayColor[2]}
							>
								Se tiver problemas com o código token entre em
								contato com:{' '}
								<LinkEmail
									themeColor={this.state.themeColor}
									onClick={() => {
										window.open(
											`mailto:${this.state.consumerData
												?.contactEmail || ''
											}`,
											'_blank',
										)
									}}
								>
									{this.state.consumerData?.contactEmail ||
										''}
								</LinkEmail>
							</GridItemText>
						</GridContainer>
					</GridItem>
				</GridContainer>
				<GridContainerFooter themeColor={this.state.themeColor}>
					<BoxFooter>
						<Box>
							{this.state.tenant !== 'pocketexpress' && (
								<span>{`Release: ${envs.REACT_APP_GERMINI_BUILD_VERSION ?? ''
									}`}</span>)}
						</Box>
					</BoxFooter>
				</GridContainerFooter>
			</Container>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		userPost: state.users.userPost,
		userCpf: state.users.userCpf,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ ValidateDocument }, dispatch)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(registerStyle),
	withTranslation(),
)(AuthenticateAccess)
