import axios from 'axios'

export const GetShopHistoryData = (
    dateFrom,
    dateTo,
    documentId,
    partnerId,
    searchType,
    pageIndex,
    pageSize,
) => {
    let args = ''
    if (dateFrom && dateFrom !== '') args = args + `StartDate=${dateFrom}`
    if (dateTo && dateTo !== '') args = args + `&EndDate=${dateTo}`
    if (documentId && documentId !== '')
        args = args + `&DocumentId=${documentId}`
    if (searchType && searchType !== '') args = args + `&listType=${searchType}`
    if (partnerId && partnerId !== '') args = args + `&PartnerId=${partnerId}`
    if (pageSize && pageSize !== '') args = args + `&pageSize=${pageSize}`
    return axios
        .get(`/Invoice/GetShopHistory?${args}&page=${pageIndex}`)
        .then((response) => response.data)
}
//
export const GetInvoicesCreditSource = (
    consumerId,
    invoiceKey,
    invoiceNumber,
    invoiceData,
    invoiceValue,
) => {
    let args = `ConsumerId=${consumerId}`
    if (invoiceKey && invoiceKey !== '')
        args = args + `&DocumentKey=${invoiceKey}`
    if (invoiceNumber && invoiceNumber !== '')
        args = args + `&DocumentId=${invoiceNumber}`
    if (invoiceValue && invoiceValue !== '')
        args = args + `&Total=${invoiceValue}`
    if (invoiceData && invoiceData !== '') args = args + `&Data=${invoiceData}`

    // console.log("ARGS: ", args);

    return axios
        .get(`/Invoice/GetInvoicesCreditSource?${args}`)
        .then((response) => response.data)
}
//
export const MergeCreditSource = (invoiceId, consumerId) =>
    axios
        .get(
            `/Invoice/mergeCreditSource?InvoiceId=${invoiceId}&ConsumerId=${consumerId}`,
        )
        .then((response) => response.data)

/*
 * api/Invoice/{id}
 * GetInvoiceById
 */
export const GetInvoiceById = (id) => {
    return axios
        .get(`/Invoice/GetInvoiceDetails/${id}`)
        .then((response) => response.data)
}

export const GetTransactionDetails = (id) => {
    return axios
        .get(`/Transaction/GetTransactionDetails/${id}`)
        .then((response) => response.data)
}

export const GetOperationDetails = (id) => {
    return axios
        .get(`/Transaction/GetOperationDetails/${id}`)
        .then((response) => response.data)
}
