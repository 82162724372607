import React from 'react'
import * as _ from 'lodash'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Avatar from '@material-ui/core/Avatar'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Button from 'components/CustomButtons/Button.jsx'

// css
import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx'
import styles from '../styles.module.scss'
import withStyles from '@material-ui/core/styles/withStyles'
import compose from 'utils/compose'
import defaultAvatar from 'assets/img/icone_foto_perfil_02.png'
import { history } from 'utils'
import { numberFormatText } from 'utils/utils'

import * as S from '../styles'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'

class UserBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
            setAnchorEl: null,
            selectedIndex: 1,
            setSelectedIndex: 1,
            MenuOptions: [
                this.props.t('MENU_USER_MY_PROFILE'),
                this.props.t('MENU_USER_ACCOUNT_PREFERENCES'),
                this.props.t('MENU_USER_EDIT_PASSWORD'),
                this.props.t('BUTTON_LOGOUT'),
            ],
            open: false,
        }
    }

    handleClick = () => {
        this.setState({ open: !this.state.open })
    }
    handleClose = () => {
        this.setState({ open: false })
        localStorage.removeItem('showNegotiations')
    }

    handleProfile = (i) => {
        history.push(`/${i}`)
        this.handleClose()
    }

    render() {
        const { classes, userData, generalRulesData } = this.props
        const { open } = this.state
        const dropdownItem = classNames(
            classes.dropdownItem,
            classes.primaryHover,
        )

        return (
            <S.UserBox>
                <Button
                    color="transparent"
                    aria-label="Notifications"
                    aria-owns={open ? 'menu-list' : null}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    style={{
                        padding: '0',
                        width: 250,
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: GetThemeColorFromStorage()?.titles?.main
                            ?.textColor,
                    }}
                    buttonRef={(node) => {
                        this.anchorEl = node
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '5px',
                            color: GetThemeColorFromStorage()?.titles?.main
                                ?.textColor,
                        }}
                    >
                        <Avatar
                            alt="Remy Sharp"
                            src={
                                userData.pictureUrl !== '' &&
                                userData.pictureUrl !== null
                                    ? userData.pictureUrl
                                    : defaultAvatar
                            }
                            className={styles.avatar}
                        />
                        <div style={{ margin: '0 10px', textAlign: 'left' }}>
                            <div className={styles.userName}>
                                {_.truncate(userData.name, {
                                    length: 18,
                                    separator: ' ',
                                })}
                            </div>
                            <div className={styles.userSeeds}>
                                {numberFormatText(userData.points, '', 0)}
                                {generalRulesData.programCurrencyName
                                    ? ` ${generalRulesData.programCurrencyName} (${generalRulesData.programCurrencySymbol})`
                                    : 'Seeds (SD$)'}
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingRight: '10px' }}>
                        <span>
                            <ArrowDropDown fontSize="large" />
                        </span>
                    </div>
                </Button>

                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                    placement="bottom"
                    className={classNames({
                        [classes.popperClose]: !open,
                        [classes.pooperResponsive]: true,
                        [classes.pooperNav]: true,
                    })}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list"
                            style={{
                                transformOrigin: '0 0 0',
                            }}
                        >
                            <Paper
                                className={classes.dropdown}
                                style={{ width: 220 }}
                            >
                                <ClickAwayListener
                                    onClickAway={this.handleClose}
                                >
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={() =>
                                                this.handleProfile('profile')
                                            }
                                            className={dropdownItem}
                                        >
                                            {this.props.t(
                                                'MENU_USER_MY_PROFILE',
                                            )}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.handleProfile(
                                                    'accountPreferences',
                                                )
                                            }
                                            className={dropdownItem}
                                        >
                                            {this.props.t(
                                                'MENU_USER_ACCOUNT_PREFERENCES',
                                            )}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.handleProfile(
                                                    'changepassword',
                                                )
                                            }
                                            className={dropdownItem}
                                        >
                                            {this.props.t(
                                                'MENU_USER_EDIT_PASSWORD',
                                            )}
                                        </MenuItem>
                                        <NavLink to="/auth/login">
                                            <MenuItem
                                                style={{ width: '100%' }}
                                                onClick={this.handleClose}
                                                className={dropdownItem}
                                            >
                                                {this.props.t('BUTTON_LOGOUT')}
                                            </MenuItem>
                                        </NavLink>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </S.UserBox>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        generalRulesData: state.generalRules.generalRulesData,
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    withStyles(adminNavbarLinksStyle),
)(UserBox)
