/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import MaskedInput from 'react-text-mask'
import { CssTextField } from 'components/CssTextField/CssTextField'
import { useTranslation } from 'react-i18next'

export const maskCell = (props) => {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	);
}

export const keyRadom = (props) => {
	const { inputRef, ...other } = props
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			// ex: dbbf965d-677c-49ff-b9da-5131da1505f3
			mask={[
				/[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/,
				'-', /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/,
				'-', /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/,
				'-', /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/,
				'-', /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/, /[a-z0-9]/

			]}
			placeholderChar={'\u2000'}
			showMask={false}
		/>
	);
}

export const noMask = (props) => {
	const { inputRef, ...other } = props
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			placeholderChar={'\u2000'}
		/>
	)
}


const pixInputAreaSize = 5;
const inputsStyle = { marginBottom: '10px' }
const BankAndPixForm_Pix = ({
	disabledForm_Account,
	touched,
	classes,
	errors,
	styles,
	selectList,
	setFieldTouched,
	setFieldValue,
	handleChange,
	setSelectedPixKeyType,

	mask_cpf,
	mask_cell,
	mask_aleatory,
	clearMask,

	empty,
	setEmpty,

	selectedOption_CPF,
	selectedOption_Email,
	selectedOption_Celular,
	selectedOption_Aleatoria,

	setSelectedOption_CPF,
	setSelectedOption_Celular,
	setSelectedOption_Email,
	setSelectedOption_Aleatoria,

	selectedKey,

	CPFFromAPI,
	emailFromAPI,
	cellFromAPI,

	setSelectedType,
	selectedTypeIndex,
	setSelectedTypeIndex,
	SelectKeyValue,
	maskedPixType,
}) => {
	const { t } = useTranslation();
	const [disableTxt, setDisableTxt] = useState()

	useEffect(() => {

		switch (true) {
			case selectedOption_CPF: SelectKeyValue(CPFFromAPI, mask_cpf); break;
			case selectedOption_Email: SelectKeyValue(emailFromAPI); break;
			case selectedOption_Celular: SelectKeyValue(cellFromAPI, mask_cell); break;
			case selectedOption_Aleatoria: SelectKeyValue('', mask_aleatory); break;
			default: SelectKeyValue('', mask_aleatory); break;
		}
	}, [selectedOption_CPF, selectedOption_Email, selectedOption_Celular, selectedOption_Aleatoria, empty])

	useEffect(() => {

		const allFalseBut = (_desired = `#$%¨%$#$%¨&`, _condition = false) => {
			['diffCpf', 'diffEmail', 'diffCell'].forEach(key => {
				localStorage.setItem(key, (key === _desired && _condition))
			})
		}

		switch (true) {
			case selectedOption_CPF:
				allFalseBut('diffCpf', (CPFFromAPI?.replace(/[^0-9]/g, '')) !== (clearMask(selectedKey)))
				break;

			case selectedOption_Email:
				allFalseBut('diffEmail', emailFromAPI !== selectedKey)
				break;

			case selectedOption_Celular:
				allFalseBut('diffCell', mask_cell(cellFromAPI) !== selectedKey)
				break;

			case selectedOption_Aleatoria:
				allFalseBut()
				break;

			default:
				allFalseBut()
				break;
		}
	}, [selectedKey])

	useEffect(() => {
		if (selectedTypeIndex === '0') {
			setDisableTxt(true)
		}
	}, [selectedTypeIndex])



	const type_NumberToString = _number => {
		switch (`${_number}`) {
			case `0`: return 'empty'
			case `1`: return 'CPF'
			case `2`: return 'Celular'
			case `3`: return 'Email'
			case `4`: return 'ChaveAleatoria'
			default: return _number
		}
	}


	const getCustomMask = () => {
		if (selectedOption_Celular) return maskCell;
		if (selectedOption_Aleatoria) return keyRadom;
	}

	const change = (_name, e) => {

		switch (_name) {
			case 'pixType':

				e && e.persist()

				const clickedInputValue = e.target.value; // numero -> 1 à 4
				// console.log('clickedInputValue: ', clickedInputValue)
				setDisableTxt(false)
				setFieldValue(_name, '');

				///=================== SETAR STATE PARA VALORES FIXOS =============

				//modal de confirmação se user quer cadastrar dados iguais ou difrentes
				const valueIs = (value) => type_NumberToString(clickedInputValue) === value;
				setEmpty(valueIs('empty')); //empty
				setSelectedOption_CPF(valueIs('CPF'));        //CPF
				setSelectedOption_Celular(valueIs('Celular'));   //Celular
				setSelectedOption_Email(valueIs('Email'));      //Email
				setSelectedOption_Aleatoria(valueIs('ChaveAleatoria'));  //Mascara


				//pra enviar pra api requiscao
				setSelectedPixKeyType(clickedInputValue); //enviando pro componente pai o index do tipo de chave selecionado, pra depois ele fazer a requisicao com esse valor

				//pro input value --> problema ta aqui, parte visual
				setSelectedType(type_NumberToString(clickedInputValue)); // -> retorna uma string dependendo do numero clickedInputValue ('CPF', 'Email', 'Celular' ou "chave aleatoria");
				setSelectedTypeIndex(clickedInputValue);

				break;

			case 'pixKey':
				const _clickedInputValue = e.target.value; // string que o user digitar -> "48 9 4565 5454", "email@gmail.com" ...
				e && e.persist()
				setFieldValue(_name, '')
				SelectKeyValue(_clickedInputValue); // -> pro input value e pra api requiscao
				break;

			default:
				e && e.persist();
				break;
		}


		if (e && e.target && e.target.value) {
			if (e.target.value.length > 250) {
				e.target.value = e.target.value.substring(0, 250)
			}
		}

		handleChange(e);
		setFieldTouched(_name, true, false)

	}

	return (
		<Fragment>
			<GridItem xs={12} md={12} style={{ margin: '7px 0 20px', }}>
				<b style={{ fontWeight: 500 }}>{t("PIX_KEY_TARGET_ACCOUNT")}</b>
			</GridItem>

			{/* CHAVE PIX SELECTOR  */}
			<GridItem xs={12} sm={12} md={3} style={inputsStyle} >
				<CssTextField
					fullWidth
					select
					label={
						<CustomRequiredField
							value={t("KEY_TYPE")}
							disabled={true}
						/>
					}
					id="pixType"
					name="pixType"
					error={touched && touched.pixType && Boolean(errors.pixType)}
					value={selectedTypeIndex ?? ''} //number --: 1 - 4
					onChange={change.bind(null, 'pixType')}
					variant="outlined"
					margin="dense"
					disabled={disabledForm_Account}
					SelectProps={{
						IconComponent: (props) => {
							return disabledForm_Account ? (
								''
							) : (
								<span {...props}>
									<svg
										className={styles.SelectSvgIcon}
										focusable="false"
										viewBox="0 0 24 24"
										aria-hidden="true"
										role="presentation"
									>
										<path d="M7 10l5 5 5-5z"></path>
									</svg>
								</span>
							)
						},
					}}
					InputProps={{
						classes: {
							root: classes.cssOutlinedInput,
							focused: classes.cssFocused,
							notchedOutline: classes.notchedOutline,
						},
					}}
					InputLabelProps={{
						classes: {
							root: classes.label,
							focused: classes.focusedLabel,
							error: classes.erroredLabel,
						},
					}}
				>
					{selectList && selectList.map((key) => (
						<MenuItem
							key={key.key}
							value={key.key} //1, 2, ,3, 4, //keyTypeIndex
							classes={{
								root: classes.selectMenuItem,
								selected:
									classes.selectMenuItemSelected,
							}}
						>
							<span
								className={styles.TextField}
							>
								{maskedPixType(key.value)}
							</span>
						</MenuItem>
					))}
				</CssTextField>

			</GridItem>



			{/* CHAVE PIX EM SI  */}
			<GridItem xs={12} sm={12} md={pixInputAreaSize} style={inputsStyle} >
				{!empty &&
					(<CssTextField
						fullWidth
						label={
							<CustomRequiredField
								value={t("KEY_PIX")}
								disabled={true}
							/>
						}
						id="pixKey"
						name="pixKey"
						error={touched && touched.pixKey && Boolean(errors.pixKey)}
						value={selectedKey ?? ''} //string
						onChange={change.bind(null, 'pixKey')}
						variant="outlined"
						margin="dense"
						disabled={disabledForm_Account || selectedOption_CPF || disableTxt}
						InputProps={{
							classes: {
								root: classes.cssOutlinedInput,
								focused: classes.cssFocused,
								notchedOutline: classes.notchedOutline,
							},
							inputComponent: getCustomMask(),
						}}
						InputLabelProps={{
							classes: {
								root: classes.label,
								focused: classes.focusedLabel,
								error: classes.erroredLabel,
							},
						}}
					/>)}
			</GridItem>
		</Fragment>
	)
}

export default BankAndPixForm_Pix;
