import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const theme = () => GetThemeColorFromStorage()?.navigation?.buttons

export const NewTab = styled(Tab)`
    text-transform: uppercase;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    list-style: none;
    padding: 10px 30px;
    cursor: pointer;

    color: ${({ selected }) => (selected ? theme()?.textColor : '#636363')};
    // background: ${({ selected }) => (selected ? '#21409a' : '#eee')};
    background-color: ${({ selected }) =>
        selected ? theme()?.backgroundColor : '#eee'};
`

export const NewTabs = styled(Tabs)`
    -webkit-tap-highlight-color: transparent;
    /* .fpUeLn {
        border-color: red;
    } */
`

export const NewTabList = styled(TabList)`
    border-bottom: 4px solid ${theme()?.backgroundColor};
    margin: 0 0 10px;
    padding: 0;

    @media (min-width: 600px) {
        margin-right: 15px;
    }
`

export const NewTabPanel = styled(TabPanel)`
    display: ${({ selected }) => (selected ? 'block' : 'none')};
`
