import React from 'react'
import GridItem from 'components/Grid/GridItem.jsx'
import CardHeader from './CardHeader.jsx'
import CardRow from './CardRow.jsx'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import { Grid } from '@material-ui/core'

const Card = ({
    title,
    disabledForm,
    choosenPaymentOption,
    thisPaymentOption,
    setChoosenPaymentOption,
    optionNumber,
    classes,
    themeColor,
    isSelected,
    label1 = '',
    value1 = '',
    label2 = '',
    value2 = '',
    label3 = '',
    value3 = '',
    label4 = '',
    value4 = '',
    setRequestedWithoutSelectAnOption,
}) => {
    const selectedCardBoarder =
    GetThemeColorFromStorage()?.titles?.highlight?.textColor
    const normalCardBoarder = `#eee`
    const currentColor = isSelected ? selectedCardBoarder : normalCardBoarder
    const allValues = [value1, value2, value3, value4]
    const haveValuesFromAPI = !allValues.includes(null)
    const cardCanBeSelected = !disabledForm && haveValuesFromAPI

    const ClickOnCardHandler = () => {
        if (cardCanBeSelected) {
            if (haveValuesFromAPI) {
                setChoosenPaymentOption(thisPaymentOption)
                setRequestedWithoutSelectAnOption(false)
                return undefined
            }
            alert(
                `Ops... Parece que esta opção de pagamento não tem dados suficientes. Configure esta opção com os dados necessários nas configurações do seu perfil.`,
            )
        }
    }

    const cardCursorStyle = () => {
        if (disabledForm) return 'default'
        if (cardCanBeSelected) return 'pointer'
        return 'not-allowed'
    }

    return (
        <Grid item
            xs={6}
            md={6}
            style={{
                display: haveValuesFromAPI ? 'block' : 'none',
                transition: '.2s ease-in-out',
				paddingLeft: '15px',
				opacity: disabledForm ? '0.5' : '1',
            }}
        >
            <div
                onClick={ClickOnCardHandler}
                style={{
                    cursor: cardCursorStyle(),
                    border: `2px solid ${currentColor}`,
                    transition: '.2s',
                    borderRadius: '6px',
                    width: '100%',
                    height: '100%',
					fontSize: `0.7rem`,
                }}
            >
                <div
                    style={{
						padding: '0px 15px',
						height: '100%',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						gap: '0px',
						alignItems: 'flex-start'
                    }}
                >
                    <CardHeader
                        haveValuesFromAPI={haveValuesFromAPI}
                        title={title}
                        disabledForm={disabledForm}
                        choosenPaymentOption={choosenPaymentOption}
                        thisPaymentOption={thisPaymentOption}
                        optionNumber={optionNumber}
                        classes={classes}
                    />
                    <CardRow
                        themeColor={themeColor}
                        label1={label1}
                        value1={value1}
                        label2={label2}
                        value2={value2}
                    />
                    <CardRow
                        themeColor={themeColor}
                        label1={label3}
                        value1={value3}
                        label2={label4}
                        value2={value4}
                    />
                </div>
            </div>
        </Grid>
    )
}

export default Card
