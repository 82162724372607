import React, { useEffect, useState } from 'react'
import * as API from '../../redux/api/user'
import * as _ from 'lodash'
import { GetGeneralRules } from '../../redux/api/generalRules'
import * as moment from 'moment'
import DocumentTitle from 'react-document-title'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'
import { format, addDays, isValid, subDays, differenceInDays } from 'date-fns'
import PageTopPart from '../PageTopPart/PageTopPart.jsx'
import { useHistory } from 'react-router-dom'

import {
    FormControl,
    IconButton,
    TablePagination,
    withStyles,
} from '@material-ui/core'
import {
    CustomButtonsBackground,
    CustomButtons,
} from '../../components/CustomButtons'

import CircleIcon from '@material-ui/icons/FiberManualRecord'
import SearchIcon from '@material-ui/icons/Search'
import { Add, Remove } from '@material-ui/icons'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'

import Button from 'components/CustomButtons/Button.jsx'
import SnackDefault from 'components/SnackDefault'
import CustomDate from 'components/CustomDate'
import { CssTextField } from 'components/CssTextField/CssTextField'
import * as S from './styles'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import {
    GetConsumerDigitalWallet,
    GetTransactionType,
    GetSourceType,
} from 'redux/api/wallet'
// getTansectionType
import { initialPagination, periodTime, validationSchema } from './utils'
import { numberFormatText } from 'utils/utils'
import { formatDate } from 'utils/utils'

import { HideLoader } from 'redux/actions'
import { useSelector } from 'react-redux'
import { useConsumer } from '../../Context/consumerContext.js'
import { getProgramTitle } from '../../utils/utils.js'
import { ModalDetails } from './components/ModalDetails/index.jsx'

import {
    MoreInformationIcon,
    MuiTooltip,
    MuiStack,
    MuiBox,
} from 'components/storybook'

function Wallet({ classes }) {
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [enableDates, setEnableDates] = useState(false)
    const [entryType, setEntryType] = useState([])
    const [gridList, setGridList] = useState([])
    const [moreFilters, setMoreFilters] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [pagination, setPagination] = useState(initialPagination)
    const [programRules, setProgramRules] = useState([])
    const [sourceType, setSourceType] = useState([])
    const [transactionDetail, setTransactionDetail] = useState()
    const [userHasMoney, setUserHasMoney] = useState(false)
    const { GetCurrentConsumer } = useConsumer()

    const generalRulesData = useSelector(
        (state) => state.generalRules.generalRulesData,
    )

    const [snackStatus, setSnackStatus] = useState({
        open: false,
        severity: 'success',
        text: t('FIELD_ALERT_SUCCESS'),
    })

    useEffect(() => {
        if (!generalRulesData?.allowDigitalWallet) {
            history.replace(`/home`)
        }
    }, [generalRulesData.allowDigitalWallet, history])

    const { handleSubmit, control, register, reset, watch, setValue } = useForm(
        {
            defaultValues: {
                currentPage: 0,
                pageCount: 1,
                pageSize: 10,
                recordCount: 1,
                filter: {
                    endDate: null,
                    entryType: '',
                    origin: '',
                    partnerName: '',
                    source: '',
                    startDate: null,
                    transactionType: '',
                },
            },
            resolver: yupResolver(validationSchema),
            mode: 'onChange',
        },
    )

    const startDateWatch = watch('filter.startDate')
    const endDateWatch = watch('filter.endDate')

    const GetRules = async () => {
        const res = await GetGeneralRules()
        if (res) {
            getAPIData(res?.allowTransfer ?? false)
        }
    }

    const getAPIData = async (allowTransfer) => {
        API.GetConsumerTransferAccountInfo()
            .then((res) => {
                if (res?.data?.balanceAvailable > 0 && allowTransfer) {
                    setUserHasMoney(true)
                } else {
                    setUserHasMoney(false)
                }
            })
            .catch((err) => {
                alert(
                    `Não foi possível resgatar os dados de sua carteira digital.#${err}#`,
                )
            })
    }

    useEffect(() => {
        pageLoad()
        GetCurrentConsumer()
        GetRules()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        requestApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.currentPage, pagination.pageSize, pagination.filter])

    async function requestApi() {
        try {
            setLoading(true)
            const filter = {
                ...pagination.filter,
                startDate: formatDate(pagination.filter.startDate) ?? '',
                endDate: formatDate(pagination.filter.endDate) ?? '',
                transactionType: pagination?.filter?.transactionType?.key ?? '',
                source: pagination?.filter?.source?.key ?? '',
            }

            let startDate = ''
            let endDate = ''

            if (filter.startDate) {
                startDate = moment(filter.startDate).format('YYYY-MM-DD')
            }

            if (filter.endDate) {
                endDate = moment(filter.endDate).format('YYYY-MM-DD')
            }

            if (filter.transactionType === 0) {
                filter.transactionType = ''
            }

            const { results, ...rest } = await GetConsumerDigitalWallet(
                `page=${pagination.currentPage + 1}&pageSize=${
                    pagination.pageSize
                }&${new URLSearchParams(filter).toString()}`,
            )

            setPagination((prevState) => ({
                ...prevState,
                recordCount: rest?.recordCount,
                pageSize: rest?.pageSize,
            }))

            setGridList(results)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            dispatch(HideLoader())
        }
    }

    async function pageLoad() {
        try {
            setLoading(true)
            const response = await axios.get(`/ProgramRules/General`)
            setProgramRules(response.data)

            const responseSourceType = await GetSourceType()
            if (responseSourceType) {
                let newSourceType = responseSourceType.map((item) => {
                    return {
                        ...item,
                        value: t(item.value),
                    }
                })
                setSourceType(newSourceType)
            }

            const responseEntryType = await GetTransactionType()
            setEntryType(responseEntryType)
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(HideLoader())
        }
    }

    function handleFilter(value) {
        setPagination((prevState) => ({
            ...prevState,
            filter: {
                ...value.filter,
            },
        }))
    }
    //
    function handleCleanFilter() {
        setTimeout(() => {
            setMoreFilters(false)
            reset({
                defaultValue: {
                    currentPage: 0,
                    pageCount: 1,
                    pageSize: 10,
                    recordCount: 1,
                    filter: {
                        endDate: null,
                        entryType: '',
                        origin: '',
                        partnerName: '',
                        source: '',
                        startDate: null,
                        transactionType: '',
                    },
                },
            })
            setValue('filter.startDate', null)
            setValue('filter.endDate', null)
            setValue('filter.transactionType', '')
            setValue('filter.entryType', '')
            setValue('filter.source', '')
            setValue('filter.origin', '')
            setMoreFilters(true)
            setPagination((prevState) => ({
                ...prevState,
                ...initialPagination,
            }))
        }, 500)
    }

    useEffect(() => {
        var period = watch('periodTime')
        switch (period) {
            case 0:
                var today = new Date()
                var dateFrom = moment(today).subtract(7, 'days')
                setValue('filter.startDate', dateFrom)
                setValue('filter.endDate', today)
                break
            case 1:
                var today = new Date()
                var dateFrom = moment(today).subtract(14, 'days')
                setValue('filter.startDate', dateFrom)
                setValue('filter.endDate', today)
                break
            case 2:
                setValue('filter.startDate', '')
                setValue('filter.endDate', '')
                break
            default:
                break
        }
        //
        if (period === 2) {
            setEnableDates(true)
        } else {
            setEnableDates(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue, watch('periodTime')])

    useEffect(() => {
        if (startDateWatch && endDateWatch) {
            if (isValid(startDateWatch._d) && isValid(endDateWatch._d)) {
                if (differenceInDays(endDateWatch._d, startDateWatch._d) < 0) {
                    setValue('filter.endDate', addDays(startDateWatch._d, 1))
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateWatch, endDateWatch])

    function handleDetail(value) {
        setTransactionDetail(value)
        setOpenModal(true)
    }

    function formadDateVXP(date) {
        return format(new Date(date), 'dd/MM/yyyy')
    }
    return (
        <S.Container>
            <DocumentTitle title={getProgramTitle(t('MENU_WALLET'))} />

            <PageTopPart
                formTitle={t('DIGITAL_WALLET')}
                handleEdit={() => {
                    history.push('/transferRequest/detail/:id')
                }}
                transferRequest
                disableEditButton={userHasMoney ? false : true}
                editName={'Solicitar transferência'}
                disabledForm={true}
                marginTop={-3}
                top={'7px'}
            />
            <SnackDefault
                snackStatus={snackStatus}
                handleCloseSnack={() =>
                    setSnackStatus((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
            />
            <S.FilterBox
                onSubmit={handleSubmit(handleFilter)}
                autoComplete="off"
            >
                <ModalDetails
                    data={transactionDetail}
                    generalRules={programRules}
                    open={openModal}
                    closeByClick={() => setOpenModal(false)}
                />
                <Grid container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormControl fullWidth>
                            {watch('filter.source') && (
                                <S.LabelInput htmlFor={'react-select'}>
                                    {t('OPERATION')}
                                </S.LabelInput>
                            )}
                            <Controller
                                defaultValue=""
                                name="filter.source"
                                control={control}
                                render={({ onChange, value }) => (
                                    <Autocomplete
                                        id="combo-box-demo"
                                        size="small"
                                        options={sourceType}
                                        getOptionLabel={(option) =>
                                            option.value ? option.value : ''
                                        }
                                        onChange={(event, item) => {
                                            onChange(item)
                                        }}
                                        value={value}
                                        disablePortal
                                        noOptionsText={t('NO_EVENT_OPTIONS')}
                                        renderInput={(params) => (
                                            <div ref={params.InputProps.ref}>
                                                <CssTextField
                                                    {...params}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    label={t('OPERATION')}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </div>
                                        )}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Controller
                            as={
                                <CssTextField
                                    fullWidth
                                    style={{
                                        backgroundColor: '#fff',
                                        width: '100%',
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                    inputProps={{ maxLength: 255 }}
                                    label="Destinatário/Origem"
                                />
                            }
                            defaultValue=""
                            name="filter.origin"
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Controller
                            render={({ onChange, value }) => (
                                <Autocomplete
                                    id="combo-box-demo"
                                    size="small"
                                    options={entryType}
                                    getOptionLabel={(option) =>
                                        t(option.value) ? t(option.value) : ''
                                    }
                                    onChange={(event, item) => {
                                        onChange(item)
                                    }}
                                    value={value}
                                    disablePortal
                                    noOptionsText={t('NO_OPERATION_OPTION')}
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <CssTextField
                                                {...params}
                                                style={{
                                                    width: '100%',
                                                }}
                                                label={t('OPERATION_TYPE')}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>
                                    )}
                                />
                            )}
                            defaultValue=""
                            name="filter.transactionType"
                            control={control}
                        />
                    </Grid>

                    {moreFilters && (
                        <>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Controller
                                    render={({ onChange, value }) => (
                                        <Autocomplete
                                            id="combo-box-demo"
                                            size="small"
                                            options={periodTime}
                                            getOptionLabel={(option) =>
                                                option.value ? option.value : ''
                                            }
                                            onChange={(event, item) => {
                                                onChange(item ? item.key : '')
                                            }}
                                            value={value}
                                            disablePortal
                                            renderInput={(params) => (
                                                <div
                                                    ref={params.InputProps.ref}
                                                >
                                                    <CssTextField
                                                        {...params}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        label={t(
                                                            'FIELD_PERIOD',
                                                        )}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </div>
                                            )}
                                        />
                                    )}
                                    defaultValue=""
                                    name="periodTime"
                                    control={control}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                <Controller
                                    as={
                                        <CustomDate
                                            enableDates={enableDates}
                                            label={t('DATETIME_FROM')}
                                            maxDate={
                                                isValid(watch('filter.endDate'))
                                                    ? subDays(
                                                          watch(
                                                              'filter.endDate',
                                                          ),
                                                          {
                                                              days: 1,
                                                          },
                                                      )
                                                    : null
                                            }
                                        />
                                    }
                                    name={'filter.startDate'}
                                    control={control}
                                    defaultValue={null}
                                    onChange={(event) => {
                                        return event[0]
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                <Controller
                                    as={
                                        <CustomDate
                                            enableDates={enableDates}
                                            label={t('DATETIME_TO')}
                                            marginLeft={'30px'}
                                            minDate={
                                                isValid(
                                                    watch('filter.startDate'),
                                                )
                                                    ? addDays(
                                                          watch(
                                                              'filter.startDate',
                                                          ),
                                                          {
                                                              days: 1,
                                                          },
                                                      )
                                                    : false
                                            }
                                        />
                                    }
                                    name={'filter.endDate'}
                                    control={control}
                                    defaultValue={null}
                                    onChange={(event) => {
                                        return event[0]
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                <S.BtnBox>
                    <S.BtnMoreFilter
                        colorIdentidadeVisual={
                            GetThemeColorFromStorage()?.navigation?.buttons
                                ?.backgroundColor
                        }
                        onClick={() => setMoreFilters(!moreFilters)}
                        title="Pesquisa avançada"
                    >
                        <IconButton
                            style={{
                                marginRight: '5px',
                                padding: '4px',
                                color: GetThemeColorFromStorage()?.navigation
                                    ?.buttons?.backgroundColor,
                                border: '1px solid',
                                borderColor:
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor,
                                '&:hover': {
                                    color: 'white',
                                    background:
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor,
                                },
                            }}
                            title="Pesquisa avançada"
                        >
                            {!moreFilters ? <Add /> : <Remove />}
                        </IconButton>
                        <span>
                            {!moreFilters
                                ? t('SHOW_MORE_FILTERS')
                                : t('HIDE_MORE_FILTERS')}
                        </span>
                    </S.BtnMoreFilter>

                    <S.BtnSearch>
                        <CustomButtonsBackground type="submit">
                            {t('BUTTON_FIND')}
                        </CustomButtonsBackground>
                        <CustomButtons onClick={handleCleanFilter}>
                            {t('BUTTON_CLEAN_FILTER')}
                        </CustomButtons>
                    </S.BtnSearch>
                </S.BtnBox>
            </S.FilterBox>

            <S.GridBox container>
                <MaterialTable
                    style={{
                        width: '51.8125em',
                        maxWidth: '100%',
                        minWidth: '100%',
                    }}
                    title={false}
                    search={false}
                    noToolbar={true}
                    components={{
                        Pagination: (props) => {
                            return (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[10, 20, 30, 50, 100]}
                                    count={pagination.recordCount}
                                    page={pagination.currentPage}
                                    rowsPerPage={pagination.pageSize}
                                    onChangePage={(e, page) =>
                                        setPagination((prevState) => ({
                                            ...prevState,
                                            currentPage: page,
                                        }))
                                    }
                                />
                            )
                        },
                    }}
                    options={{
                        emptyRowsWhenPaging: false,
                        search: false,
                        toolBar: false,
                        pageSize: pagination.pageSize,
                        loadingType: 'overlay',
                        rowStyle: { backgroundColor: '#fff' },
                        headerStyle: { fontSize: '15px', lineHeight: '1.3' },
                    }}
                    onChangeRowsPerPage={(e) =>
                        e !== pagination.pageSize
                            ? setPagination((prevState) => ({
                                  ...prevState,
                                  currentPage: 0,
                                  pageSize: e,
                              }))
                            : ''
                    }
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        pagination: {
                            labelRowsSelect: 'linhas',
                            firstTooltip: '',
                            lastTooltip: '',
                            nextTooltip: '',
                            previousTooltip: '',
                        },
                        body: {
                            emptyDataSourceMessage: (
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    Nenhum registro encontrado.
                                </div>
                            ),
                        },
                    }}
                    data={gridList}
                    isLoading={loading}
                    columns={[
                        {
                            title: '',
                            field: 'value',
                            sorting: false,
                            cellStyle: {
                                width: '5px',
                                maxWidth: '5px',
                            },
                            headerStyle: {
                                width: '5px',
                                maxWidth: '5px',
                                zIndex: '0',
                            },
                            render: (props) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        lineHeight: 1.1,
                                        fontSize: '18px',
                                    }}
                                >
                                    <S.MyTooltip
                                        arrow
                                        id="tooltip-top"
                                        title={
                                            props.sourceType.key === '7'
                                                ? props.transactionType.key ===
                                                  '7'
                                                    ? t('EXIT')
                                                    : props.transactionType
                                                          .key === '5'
                                                    ? t('RESERVATION')
                                                    : props.transactionType
                                                          .key === '3'
                                                    ? t('EXPIRED')
                                                    : t('REJECTED')
                                                : props.transactionType.key ===
                                                      '2' ||
                                                  props.status.key === '2'
                                                ? t('EXIT')
                                                : t('DEPOSIT')
                                        }
                                        placement="top-start"
                                        classes={{
                                            tooltip: classes.tooltip,
                                        }}
                                    >
                                        <CircleIcon
                                            style={{
                                                fontSize: '18px',
                                                fill:
                                                    props?.sourceType?.key ===
                                                    '7'
                                                        ? props?.transactionType
                                                              ?.key === '7'
                                                            ? '#F44336'
                                                            : props
                                                                  ?.transactionType
                                                                  ?.key === '5'
                                                            ? '#FF9800'
                                                            : props
                                                                  ?.transactionType
                                                                  ?.key === '3'
                                                            ? '#9C571A'
                                                            : '#7F22DB'
                                                        : props?.transactionType
                                                              ?.key === '2' ||
                                                          props?.status?.key ===
                                                              '2'
                                                        ? '#F44336'
                                                        : '#5CB860',
                                            }}
                                        />
                                    </S.MyTooltip>
                                </div>
                            ),
                        },
                        {
                            title: 'Evento/data',
                            field: 'transactionDate',
                            headerStyle: {
                                zIndex: '0',
                            },
                            render: (props) =>
                                props.status.key === '2' ? (
                                    <S.BoxContainer>
                                        <S.ExpiredBox>
                                            {t(props.sourceType.value)}
                                        </S.ExpiredBox>
                                        <S.ExpiredSpan>
                                            {/* {format(
                                                props.transactionDate,
                                            ).format('L') +
                                                ' - ' +
                                                moment(
                                                    props.transactionDate,
                                                ).format('HH:mm')} */}
                                            {formadDateVXP(
                                                props?.transactionDate,
                                            )}
                                        </S.ExpiredSpan>
                                    </S.BoxContainer>
                                ) : props.sourceType?.key === '6' ? (
                                    <S.BoxContainer>
                                        <S.ExpiredBox>
                                            {t(props.sourceType.value)}
                                        </S.ExpiredBox>
                                        <S.ExpiredSpan>
                                            {/* {moment(
                                                props.transactionDate,
                                            ).format('L') +
                                                ' - ' +
                                                moment(
                                                    props.transactionDate,
                                                ).format('HH:mm')} */}
                                            {formadDateVXP(
                                                props?.transactionDate,
                                            )}
                                        </S.ExpiredSpan>
                                    </S.BoxContainer>
                                ) : //_.isEmpty
                                !_.isEmpty(props.award) ? (
                                    <div>
                                        {t(props.award.value)}
                                        <br></br>
                                        <span
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontStyle: 'italic',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {/* {moment(
                                                props.transactionDate,
                                            ).format('L') +
                                                ' - ' +
                                                moment(
                                                    props.transactionDate,
                                                ).format('HH:mm')} */}
                                            {formadDateVXP(
                                                props?.transactionDate,
                                            )}
                                        </span>
                                    </div>
                                ) : (
                                    <MuiStack
                                        minWidth="200px"
                                        spacing={2}
                                        direction="row"
                                    >
                                        <MuiStack>
                                            {t(props.sourceType.value)}
                                            <span
                                                style={{
                                                    fontFamily: 'Roboto',
                                                    fontStyle: 'italic',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {formadDateVXP(
                                                    props?.transactionDate,
                                                )}
                                            </span>
                                        </MuiStack>
                                        {props?.sourceType?.value ===
                                            'CASHBACK' &&
                                        props?.negotiation?.allowTransfer ? (
                                            <MuiTooltip
                                                title={`Cashback decorrente de negociação. \n
												Liberado para transferência à partir de ${formadDateVXP(
                                                    props?.negotiation
                                                        ?.transferValidAfter,
                                                )}`}
                                            >
                                                <MoreInformationIcon
                                                    color="#5D5D5D"
                                                    size={20}
                                                />
                                            </MuiTooltip>
                                        ) : (
                                            props?.refundDate &&
                                            props?.sourceType?.value ===
                                                'CASHBACK' && (
                                                <MuiTooltip
                                                    title={`Cashback decorrente da compra. \n
										Liberado para transferência à partir de ${formadDateVXP(props?.refundDate)}`}
                                                >
                                                    <MoreInformationIcon
                                                        color="#5D5D5D"
                                                        size={20}
                                                    />
                                                </MuiTooltip>
                                            )
                                        )}
                                    </MuiStack>
                                ),
                        },
                        {
                            title: 'Destinatário/Origem',
                            field: 'sourceType',
                            headerStyle: {
                                zIndex: '0',
                            },
                            render: (props) => (
                                <div>
                                    {props.partnerName
                                        ? props.partnerName
                                        : props.entryType?.key === '1' &&
                                          props.annonymousSent === true
                                        ? t('ANONYMOUS_SENT')
                                        : '-- --'}
                                    {props.entryType?.key === '2' &&
                                        props.annonymousSent && (
                                            <S.MyTooltip
                                                arrow
                                                id="tooltip-top"
                                                title={t('ANONYMOUS_GIFT_SENT')}
                                                placement="top-start"
                                                classes={{
                                                    tooltip: classes.tooltip,
                                                }}
                                            >
                                                <i
                                                    style={{
                                                        fontSize: 20,
                                                        position: 'relative',
                                                        left: '14px',
                                                        // top: '14px',
                                                        color: GetThemeColorFromStorage()
                                                            ?.visual?.icons
                                                            ?.color,
                                                        float: 'right',
                                                        marginRight: '20px',
                                                    }}
                                                    className="icon-anonimo"
                                                />
                                            </S.MyTooltip>
                                        )}
                                </div>
                            ),
                        },
                        {
                            title: 'Operação',
                            field: 'value',
                            render: (props) => (
                                <div>{t(props.transactionType.value)}</div>
                            ),
                        },
                        {
                            title: 'Valor',
                            field: 'value',
                            render: (props) => (
                                <div>
                                    <span
                                        style={{
                                            color:
                                                props.sourceType.key === '7'
                                                    ? props.transactionType
                                                          .key === '7'
                                                        ? '#F44336'
                                                        : props.transactionType
                                                              .key === '5'
                                                        ? '#FF9800'
                                                        : props.transactionType
                                                              .key === '3'
                                                        ? '#9C571A'
                                                        : '#7F22DB'
                                                    : props.transactionType
                                                          .key === '2' ||
                                                      props.status.key ===
                                                          '2' ||
                                                      props.status.key === '4'
                                                    ? '#F44336'
                                                    : '#5CB860',
                                        }}
                                    >
                                        {numberFormatText(
                                            props.value,
                                            programRules.currencySymbol
                                                ? programRules.currencySymbol +
                                                      ' '
                                                : ' ',
                                            2,
                                        )}
                                    </span>
                                </div>
                            ),
                        },
                        {
                            title: 'Ação',
                            field: 'value',
                            sorting: false,
                            cellStyle: {
                                width: 'inherit',
                                height: 'inherit',
                                position: 'relative',
                            },
                            headerStyle: {
                                width: '50px !important',
                            },
                            render: (props) => (
                                <Button
                                    justIcon
                                    size="sm"
                                    color="greenButtonColor"
                                    title={t('BUTTON_VIEW')}
                                    className="detail-button"
                                    onClick={() => handleDetail(props)}
                                    style={{
                                        height: '98%',
                                        width: '35px',
                                        margin: 'auto',
                                    }}
                                >
                                    <SearchIcon
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                        }}
                                    />
                                </Button>
                            ),
                        },
                    ]}
                />
                {/* </S.GridContent> */}
            </S.GridBox>
        </S.Container>
    )
}

export default withStyles(sweetAlertStyle)(Wallet)
