import React from 'react'
import * as S from './styles'
import {
    GetThemeColorFromStorage,
    colorLuminance,
} from '../../redux/api/themeColor'

const textColorThemeColor = () => {
    if (GetThemeColorFromStorage()) {
        if (GetThemeColorFromStorage()?.navigation) {
            if (GetThemeColorFromStorage()?.navigation?.buttons) {
                if (GetThemeColorFromStorage()?.navigation.buttons?.textColor) {
                    return GetThemeColorFromStorage()?.navigation?.buttons
                        ?.textColor
                }
            }
        }
    }

    return '#0f0'
}

const backgroundColorThemeColor = () => {
    if (GetThemeColorFromStorage()) {
        if (GetThemeColorFromStorage()?.navigation) {
            if (GetThemeColorFromStorage()?.navigation?.buttons) {
                if (
                    GetThemeColorFromStorage()?.navigation?.buttons
                        ?.backgroundColor
                ) {
                    return GetThemeColorFromStorage()?.navigation?.buttons
                        ?.backgroundColor
                }
            }
        }
    }

    return '#fff'
}

export const CustomButtonsBackground = ({ children, ...rest }) => {
    return (
        <S.ButtonBackground
            {...rest}
            textColorThemeColor={textColorThemeColor()}
            backgroundColorThemeColor={backgroundColorThemeColor()}
            hoverThemeColor={colorLuminance(backgroundColorThemeColor(), -0.2)}
        >
            {children}
        </S.ButtonBackground>
    )
}

export const CustomButtons = ({ children, ...rest }) => {
    return (
        <S.ButtonBackgroundLess
            {...rest}
            textColorThemeColor={textColorThemeColor()}
            backgroundColorThemeColor={backgroundColorThemeColor()}
            hoverThemeColor={colorLuminance(backgroundColorThemeColor(), -0.2)}
        >
            {children}
        </S.ButtonBackgroundLess>
    )
}
