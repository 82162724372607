import axios from 'axios'

const Get = (_url) =>
    axios.get(`/Participants/Tier/${_url}`).then((response) => response.data)

//Get Tier Data from API
export const GetTierData = () => Get()
//    axios.get(`/Participants/Tier/`).then(response => response.data);

export const GetActiveTierData = () => Get('GetCurrentTierGroup')
//    axios.get(`/Participants/Tier/GetCurrentTierGroup/`).then(response => response.data);

export const GetCurrentTierByConsumer = async () =>
    await axios.get(`/Participants/Tier/GetCurrentTierByConsumer`)
