import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import * as S from './styles'
// MATERIAL
import Card from '@mui/material/Card'
import { CardContent, Icon } from '@mui/material'

export const CardServicesLogs = ({ childToParent }) => {
	const { t } = useTranslation()

	const clickMe = boolean => {
		childToParent(boolean)
	}

	return (
		<S.CustomCard>
			<Card
				onClick={() => {
					clickMe(true)
				}}
			>
				<S.HeaderIcons>
					<S.CustomIcon>
						<Icon>
							<i className="icon-servicos_contratados_pagamentos" />
						</Icon>
					</S.CustomIcon>
				</S.HeaderIcons>
				<CardContent style={{ padding: '0px', marginTop: '50px' }}>
					<S.CardTitle>{t('Log de Pagamentos')}</S.CardTitle>
				</CardContent>
			</Card>
		</S.CustomCard>
	)
}

CardServicesLogs.propTypes = {
	childToParent: PropTypes.func.isRequired,
}
