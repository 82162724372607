import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import GridItem from 'components/Grid/GridItem.jsx'
import Section from 'components/Section/Section'
import Button from 'components/CustomButtons/Button.jsx';
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor';
import { SeedsItens } from '../utils';
import { useTranslation } from 'react-i18next';

function ProgramCurrency() {
	const { t } = useTranslation();
	const themeColor = GetThemeColorFromStorage()?.titles?.main?.textColor;

	return (
		<GridItem xs={12} sm={12} md={6}>
			<Section title={t('Seeds')} />
			<Box
				display="flex"
				gap="20px"
				flexWrap={{
					xs: "wrap",
					sm: "nowrap",
					md: "nowrap",
				}}
			>
				{SeedsItens.map((item, index) => (
					<Stack
						key={index}
						minHeight="191px"
						maxHeight="100%"
						border="1px solid #707070"
						gap="10px"
						textAlign="center"
						p="5px"
						width={{
							xs: "100%",
							sm: "100%",
							md: "50%",
							lg: "50%",
						}}
					>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							gap="10px"
							textAlign="center"
						>
							<Box
								width="100%"

								bgcolor="#E9E9E9"
								opacity={0.65}
								color={themeColor}
								display="flex"
								justifyContent="center"
							>
								<Typography
									variant="body1"
									height="33px"
									textAlign="center"
									sx={{
										display: 'flex',
										alignItems: 'center',
										font: "normal normal bold 15px Roboto",
									}}
								>
									{item.title}
								</Typography>
							</Box>
							<Box
								width="100%"
								bgcolor="#E9E9E9"
								opacity={0.65}
								p="3.5px 0px"
								fontSize="14px"
								color="#333333"
							>

								{item.subtitle}
							</Box>
						</Box>
						<Stack
							display="flex"
							justifyContent="center"
							textAlign="center"
						>
							<Typography
								variant="subtitle1"
								component="div"
								fontSize="13px"
								color="#818181"
							>
								{item.currency}
							</Typography>
							<Typography
								variant="subtitle2"
								component="div"
								fontSize="18px"
								color="#333333"
							>
								{item.value}
							</Typography>
						</Stack>
						<Box>
							<Button
								color="greenButtonColor"
								size="sm"
								style={{
									width: "140px",
									height: "29px",
									font: "normal normal bold 13px/18px Roboto",
								}}
							>
								{item.button}
							</Button>
						</Box>
					</Stack>
				))}
			</Box>
		</GridItem>
	);
}

export default ProgramCurrency;
