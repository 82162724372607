import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
    userData: [],
    userPost: {},
    consumerRegulationPost: {},
    userDataFail: false,
    consumerRegulationRules: false,
    userUpdate: false,
    userCpf: {},
    requestPasswordReset: false,
    resetPassword: false,
    resetPasswordFailed: false,
    consumerByToken: [],
    consumerByTokenFailed: false,
    emailToken: {},
    emailTokenFailed: false,
    smsRequest: {},
    smsRequestFailed: false,
    putSms: {},
    putSmsFailed: false,
    giftCardData: {},
    receivedCardData: {},
    giftCardError: false,
}

const fetchConsumerUserData = (state, action) => {
    return updateObject(state, {
        userData: action.userData,
    })
}

const validateConsumerCpf = (state, action) => {
    return updateObject(state, {
        userCpf: action.validDocument,
    })
}

const putConsumerUserData = (state, action) => {
    return updateObject(state, {
        userUpdate: action.userUpdate,
    })
}

const postConsumerData = (state, action) => {
    return updateObject(state, {
        userPost: action.userPost,
    })
}

const fetchConsumerUserFail = (state, action) => {
    return updateObject(state, {
        userDataFail: action.userDataFail,
    })
}

const postConsumerRegulationData = (state, action) => {
    return updateObject(state, {
        consumerRegulationPost: action.consumerRegulationPost,
    })
}

const RequestPasswordReset = (state, action) => {
    return updateObject(state, {
        requestPasswordReset: action.requestPasswordReset,
    })
}

const ResetPassword = (state, action) => {
    return updateObject(state, {
        resetPassword: action.resetPassword,
    })
}

const ResetPasswordFailed = (state, action) => {
    return updateObject(state, {
        resetPasswordFailed: action.resetPasswordFailed,
    })
}

const GetConsumerByToken = (state, action) => {
    return updateObject(state, {
        consumerByToken: action.consumerByToken,
    })
}

const PutEmailTokenValidation = (state, action) => {
    return updateObject(state, {
        emailToken: action.emailToken,
    })
}

const PutEmailTokenValidationFail = (state, action) => {
    return updateObject(state, {
        emailTokenFailed: action.emailTokenFailed,
    })
}

const FetchSMSActivationCode = (state, action) => {
    return updateObject(state, {
        smsRequest: action.smsRequest,
    })
}

const FetchSMSActivationCodeFail = (state, action) => {
    return updateObject(state, {
        smsRequestFailed: action.smsRequestFailed,
    })
}

const PutSmsActivationCode = (state, action) => {
    return updateObject(state, {
        putSms: action.putSms,
    })
}

const PutSmsActivationCodeFail = (state, action) => {
    return updateObject(state, {
        putSmsFailed: action.putSmsFailed,
    })
}

const GetConsumerByTokenFailed = (state, action) => {
    return updateObject(state, {
        consumerByTokenFailed: action.consumerByTokenFailed,
    })
}

const GetSentGiftCard = (state, action) => {
    return updateObject(state, {
        giftCardData: action.giftCardData,
    })
}

const GetReceivedGiftCard = (state, action) => {
    return updateObject(state, {
        receivedCardData: action.receivedCardData,
    })
}

const GetSentGiftCardFailed = (state, action) => {
    return updateObject(state, {
        giftCardError: action.giftCardError,
    })
}

export function users(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GETALL_REQUEST:
            return {
                loading: true,
            }
        case actionTypes.GETALL_SUCCESS:
            return {
                items: action.users,
            }
        case actionTypes.GETALL_FAILURE:
            return {
                error: action.error,
            }
        case actionTypes.POST_CONSUMER_REGULATION_DATA:
            return postConsumerRegulationData(state, action)
        case actionTypes.FETCH_CONSUMER_DATA:
            return fetchConsumerUserData(state, action)
        case actionTypes.POST_CONSUMER_DATA:
            return postConsumerData(state, action)
        case actionTypes.VERIFY_DOCUMENT:
            return validateConsumerCpf(state, action)
        case actionTypes.PUT_CONSUMER_DATA:
            return putConsumerUserData(state, action)
        case actionTypes.FETCH_CONSUMER_FAILED:
            return fetchConsumerUserFail(state, action)
        case actionTypes.REQUEST_PASSWORD_RESET:
            return RequestPasswordReset(state, action)
        case actionTypes.RESET_PASSWORD:
            return ResetPassword(state, action)
        case actionTypes.RESET_PASSWORD_FAILED:
            return ResetPasswordFailed(state, action)

        case actionTypes.GET_CONSUMER_BY_TOKEN:
            return GetConsumerByToken(state, action)
        case actionTypes.GET_CONSUMER_BY_TOKEN_FAILED:
            return GetConsumerByTokenFailed(state, action)

        case actionTypes.PUT_EMAIL_TOKEN_VALIDATION:
            return PutEmailTokenValidation(state, action)
        case actionTypes.EMAIL_TOKEN_VALIDATION_FAILED:
            return PutEmailTokenValidationFail(state, action)

        case actionTypes.FETCH_SMS_ACTIVATION_CODE:
            return FetchSMSActivationCode(state, action)
        case actionTypes.FETCH_SMS_ACTIVATION_CODE_FAILED:
            return FetchSMSActivationCodeFail(state, action)

        case actionTypes.PUT_SMS_ACTIVATION_CODE:
            return PutSmsActivationCode(state, action)
        case actionTypes.SMS_ACTIVATION_CODE_FAILED:
            return PutSmsActivationCodeFail(state, action)

        case actionTypes.FETCH_GIFT_CARD:
            return GetSentGiftCard(state, action)
        case actionTypes.GIFT_CARD_FAILURE:
            return GetSentGiftCardFailed(state, action)

        case actionTypes.FETCH_RECEIVED_CARD:
            return GetReceivedGiftCard(state, action)

        default:
            return state
    }
}
