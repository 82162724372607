/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button } from "@mui/material";
import { ContractedServicesPaymentsIcon } from "../../Icons";
import { MuiTypography } from "../../Typography";
import { MuiStack } from "../../Stack";
export function ButtonNotification(_a) {
    var defaultColor = _a.defaultColor, maxWidth = _a.maxWidth, minHeight = _a.minHeight, padding = _a.padding, color = _a.color, text = _a.text, props = __rest(_a, ["defaultColor", "maxWidth", "minHeight", "padding", "color", "text"]);
    var _b = useState(false), isHovered = _b[0], setIsHovered = _b[1];
    var handleMouseEnter = function () {
        setIsHovered(true);
    };
    var handleMouseLeave = function () {
        setIsHovered(false);
    };
    return (_jsx(Button, __assign({}, props, { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, sx: {
            padding: padding !== null && padding !== void 0 ? padding : "15px",
            maxWidth: maxWidth !== null && maxWidth !== void 0 ? maxWidth : "200px",
            minHeight: minHeight !== null && minHeight !== void 0 ? minHeight : "200px",
            background: "#F5F5F5",
            textAlign: "left",
            color: color !== null && color !== void 0 ? color : "#333333",
            textTransform: "none",
            justifyContent: "space-between",
            alignItems: "unset",
            "&:hover": {
                background: defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#1A9CAC",
                color: "#FFFFFF",
            },
        } }, { children: _jsxs(MuiStack, __assign({ direction: "column", justifyContent: "space-between", alignItems: "flex-start", spacing: 2 }, { children: [_jsx(ContractedServicesPaymentsIcon, { size: 50, color: isHovered ? "#FFFFFF" : defaultColor || "#1A9CAC", hoverColor: "#FFFFFF" }), _jsx(MuiTypography, __assign({ mt: 20 }, { children: text !== null && text !== void 0 ? text : "Serviços de Pagamento" }))] })) })));
}
