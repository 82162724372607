import React from 'react'
import {
    MuiBox,
    MuiDefaultModal,
    Button,
} from '../../../../components/storybook'
import UseTerms from '../../../UseTerms/UseTerms'
import { styleModalTerms } from '../../utils'
export function Modal({ isModalOpen, handleCloseModal }) {
    return (
        <MuiDefaultModal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={isModalOpen}
            onClose={handleCloseModal}
        >
            <MuiBox
                sx={{
                    ...styleModalTerms,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                <UseTerms
                    customButton={
                        <Button
                            hoverColor="#F05B4F"
                            hoverTextColor="#F05B4F"
                            text="Fechar"
                            textColor="#FFFFFF"
                            typeVariation="CustomTextButton"
                            onClick={handleCloseModal}
                            icon
                            colorIcon="#FFFFFF"
                            sx={{
                                borderRadius: '5px',
                                backgroundColor: '#F05B4F',
                                height: '35px',
                                fontWeight: 400,
                                border: 'none',
                                textTransform: 'inherit',
                                color: '#FFFFFF',
                                ':hover': {
                                    backgroundColor: '#F05B4F',
                                    color: '#FFFFFF',
                                    border: 'none',
                                },
                            }}
                        />
                    }
                />
            </MuiBox>
        </MuiDefaultModal>
    )
}
