import React from 'react'
import {
    MuiBox,
    MuiDefaultModal,
    Button,
    GridItem,
    MuiTypography,
    GridContainer,
    AlertModalIcon,
} from '../../../../components/storybook'
import { styleInsufficientAccountModal } from '../../utils'
export function InsufficientAccountModal({
    isModalInsufficientAccountOpen,
    setIsModalInsufficientAccountOpen,
    isModalExceededTransferLimitOpen,
    setIsModalExceededTransferLimitOpen,
}) {
    return (
        <MuiDefaultModal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={
                isModalInsufficientAccountOpen ||
                isModalExceededTransferLimitOpen
            }
            onClose={() =>
                setIsModalInsufficientAccountOpen(false) ||
                setIsModalExceededTransferLimitOpen(false)
            }
        >
            <MuiBox
                sx={{
                    ...styleInsufficientAccountModal,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} sx={{ textAlign: 'end' }}>
                        <Button
                            customSize="15%"
                            hoverColor="#EB6C61"
                            colorIcon="#FFFFFF"
                            icon
                            defaultColor="#EB6C61"
                            text="Fechar"
                            typeVariation="CustomTextButton"
                            borderRadius="5px"
                            onClick={() =>
                                setIsModalInsufficientAccountOpen(false) ||
                                setIsModalExceededTransferLimitOpen(false)
                            }
                            textColor="#FFFFFF"
                        />
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ textAlign: 'center!important' }}
                    >
                        <AlertModalIcon size={70} color="#F8BB86" />
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ textAlign: 'center!important' }}
                        mt={2}
                        mb={5}
                    >
                        <MuiTypography color="#333333" fontSize="15px">
                            {isModalExceededTransferLimitOpen
                                ? 'Você atingiu o limite diário para realizar transferências.'
                                : 'Você atingiu o horário limite para realizar transferências.'}
                        </MuiTypography>
                    </GridItem>
                </GridContainer>
            </MuiBox>
        </MuiDefaultModal>
    )
}
