import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
    loading: null,
}

const loader = (state, action) => {
    return updateObject(state, {
        loading: action.loading,
    })
}

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_LOADER:
            return loader(state, action)
        case actionTypes.HIDE_LOADER:
            return loader(state, action)
        default:
            return state
    }
}

export default dashboardReducer
