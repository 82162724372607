import React from "react";
import PropTypes from "prop-types";

const RenderRegulation = ({ renderComp }) => {
  return (
    <div>
      <div
        style={{ width: "100%" }}
        contentEditable={false}
        dangerouslySetInnerHTML={{ __html: renderComp }}
      />
    </div>
  );
};

RenderRegulation.propTypes = {
  renderComp: PropTypes.string,
};

RenderRegulation.defaultProps = {
  renderComp: "",
};

export default RenderRegulation;
