import { Grid } from '@material-ui/core'
import React from 'react'
import * as S from './styles'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'

const CustomSelect = ({
    children,
    menuWidth = 200,
    inputSelect,
    disabled,
    label,
    error,
    errorMsg,
    ...props
}) => {
    return (
        <S.Container container item xs={12} {...props}>
            <Grid item xs={12}>
                <S.Wrapper
                    error={error}
                    helperText={errorMsg}
                    select
					disabled={disabled}
                    label={
                        <CustomRequiredField
                            autoComplete="off"
                            value={label}
                            disabled={disabled}
                        />
                    }
                    inputSelect={inputSelect}
                    variant="outlined"
                    {...props}
                >
                    {children}
                </S.Wrapper>
            </Grid>
        </S.Container>
    )
}

export default CustomSelect
