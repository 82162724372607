import React from 'react'
import { Warning } from '@material-ui/icons'

export default({__this})=>(
    !__this.state.verifyState && <div style={{
        border: '1px solid #FB9006',
        color: '#FB9006',
        display: 'flex',
        padding: '10px',
        borderRadius: '6px',
        fontSize: '13px',
        marginBottom: '25px',
        maxWidth: '300px',
        marginTop: '10px'
    }}>
        <span style={{ marginRight: '10px' }}> <Warning fontSize="large" /> </span>
        <span> Confirmação de segurança: <br /> Clique na opção "Não sou um robô" </span>
    </div>
)