import axios from 'axios'

export const GetProducts = async () => {
    try {
        const response = await axios.get('/Product/GetMagentoProducts')

        return response
    } catch (error) {
        console.error('An error occurred:', error)
        throw error // You might want to rethrow the error or handle it in a different way
    }
}

export const GetMagentoReedemProduct = async () => {
    try {
        const response = await axios.get('/StoreApp/GetMagentoReedemProduct')

        return response?.data
    } catch (error) {
        console.error('An error occurred:', error)
        throw error // You might want to rethrow the error or handle it in a different way
    }
}
