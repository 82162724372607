import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Pagination from '@material-ui/lab/Pagination'
import Skeleton from '@material-ui/lab/Skeleton'
import { Drafts, Mail } from '@material-ui/icons'
import { Grid } from '@material-ui/core'

import NotificationModal from 'components/NotificationModal'
import RenderDescription from 'components/NotificationModal/RenderDescription'
import Section from '../../components/Section/Section'
import {
    BodyRender,
    NotificationSkeleton,
} from 'components/NotificationModal/styles'
import NoNotifications from 'components/NoNotifications/NoNotifications'

import defaultNotification from 'assets/img/defaultNotification.jpg'

import * as Styled from './styles'

import { HideLoader, ShowLoader } from 'redux/actions'
import { GetConsumerAlerts, ReadAlert } from 'redux/api/alerts/alerts'
import { getProgramTitle, numberFormatText } from '../../utils/utils'
import {
    MuiBox,
    MuiTypography,
    ResearchIllustration,
} from '../../components/storybook'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

function Notification() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { id } = useParams()
    const pointsName = localStorage?.programCurrencyName

    const [dataList, setDataList] = useState([])
    const [alerts, setAlerts] = useState([])
    const [loading, setLoading] = useState(false)
    const [showNotification, setShowNotification] = useState({
        loading: false,
        open: false,
    })
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageCount: 1,
        pageSize: 10,
        recordCount: 1,
    })

    // const array = [1, 2, 3, 4, 5, 6]

    async function getAlerts(pagination) {
        const response = await GetConsumerAlerts(pagination)
        setDataList(response?.results)

        try {
            dispatch(ShowLoader())
            setLoading(true)
            const teste = GetConsumerAlerts(pagination)
            const { results, ...rest } = await GetConsumerAlerts(pagination)

            setPagination({ ...rest })
            setAlerts(
                results.slice(0, 6).map((item) => ({
                    ...item,
                    publishFormat: moment(item.publishDate)
                        .locale('pt-br')
                        .format('DD[|]MM[|]YYYY'),
                })),
            )
        } catch (error) {
        } finally {
            dispatch(HideLoader())
            setLoading(false)
        }
    }
    useEffect(() => {
        getAlerts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function readNotification(id) {
        try {
            setShowNotification((prevState) => ({
                ...prevState,
                loading: true,
                open: true,
            }))
            const response = await ReadAlert(id)
            setAlerts((prevState) =>
                prevState.map((item) =>
                    item.id === id ? { ...item, isRead: true } : item,
                ),
            )
            setShowNotification((prevState) => ({
                ...prevState,
                loading: false,
                ...response,
                publishFormat: moment(response.publishDate)
                    .locale('pt-br')
                    .format('DD[ | ]MMM[ | ]YYYY'),
            }))
        } catch (error) {
            console.log('getNotification -> error', error)
        } finally {
            dispatch(HideLoader())
            setLoading(false)
        }
    }

    return (
        <Styled.Container>
            <DocumentTitle title={getProgramTitle(t('MENU_NOTIFICATION'))} />
            <Section title={t('MENU_NOTIFICATION')} />
            <Styled.NotificationContainer container spacing={4}>
                {loading &&
                    dataList.map((item) => (
                        <Styled.CardContainer
                            key={item}
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                            container
                            isread={false}
                            img={defaultNotification}
                        >
                            <Skeleton
                                width="100%"
                                variant="rect"
                                height={200}
                            />
                            <Skeleton width="100%" height={100} />
                            <Skeleton
                                width="100%"
                                variant="rect"
                                height={100}
                            />
                        </Styled.CardContainer>
                    ))}
                {!loading && dataList?.length > 0 && (
                    <>
                        {!loading &&
                            dataList.map((item) => (
                                <Styled.CardContainer
                                    key={item.id}
                                    item
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    container
                                    isread={
                                        item?.content.includes('EXPIRED') ||
                                        item?.isRead
                                    }
                                    img={
                                        item.imageUrl === 'string' ||
                                        !item.imageUrl
                                            ? defaultNotification
                                            : item.imageUrl
                                    }
                                    onClick={() => {
                                        if (
                                            !item?.content.includes('EXPIRED')
                                        ) {
                                            if (
                                                item?.alertType?.value ===
                                                'SURVEY'
                                            ) {
                                                history.push({
                                                    pathname: `/search/${
                                                        item?.contentId ||
                                                        item?.content
                                                    }`,
                                                })
                                            } else {
                                                readNotification(item.id)
                                            }
                                        }
                                    }}
                                    className="card-container"
                                >
                                    {item?.alertType?.value !== 'SURVEY' && (
                                        <Grid
                                            item
                                            xs={12}
                                            className="header-img"
                                        />
                                    )}
                                    {item?.alertType?.value === 'SURVEY' && (
                                        <Grid item xs={12} sx={{ padding: 20 }}>
                                            <MuiBox
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    border: '1px solid #B4B4B4',
                                                    minHeight: '206px',
                                                    borderRadius:
                                                        '5px 5px 0px 0px',
                                                }}
                                            >
                                                <ResearchIllustration size="150px" />
                                            </MuiBox>
                                            <MuiBox
                                                sx={{
                                                    backgroundColor: '#E6E7E8',
                                                    border: '1px solid #B4B4B4',
                                                    minHeight: '51px',
                                                    marginTop: '-53px',
                                                    borderBlock: 'none',
                                                }}
                                            >
                                                <></>
                                            </MuiBox>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        className="read-column"
                                        container
                                        alignItems="center"
                                    >
                                        {item.isRead ? <Drafts /> : <Mail />}
                                        <div>+ Leia mais</div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-content"
                                        container
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            alignItems="center"
                                        >
                                            <small className="date-small">
                                                {moment(item.publishDate)
                                                    .locale('pt-br')
                                                    .format(
                                                        'DD[ | ]MMM[ | ]YYYY',
                                                    )}
                                            </small>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            alignItems="center"
                                            className="read-line"
                                            isread={item.isRead}
                                        >
                                            {item.title}
                                            {/* {item?.value !== 0 &&
                                                !item?.content.includes(
                                                    'EXPIRED',
                                                ) && (
                                                    <MuiTypography fontSize="14px">
                                                        Você receberá{' '}
                                                        {item.paymentType ===
                                                        'Cashback'
                                                            ? numberFormatText(
                                                                  item?.value,
                                                                  `${item?.currency} `,
                                                                  2,
                                                              )
                                                            : numberFormatText(
                                                                  item?.value,
                                                                  `${item?.currency} `,
                                                                  0,
                                                              )}{' '}
                                                        {item.paymentType ===
                                                        'Cashback'
                                                            ? 'reais'
                                                            : pointsName}
                                                    </MuiTypography>
                                                )} */}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            alignItems="center"
                                            className="read-line"
                                            isread={item.isRead}
                                        ></Grid>
                                    </Grid>
                                </Styled.CardContainer>
                            ))}
                        <Grid
                            item
                            xs={12}
                            container
                            className="pagination-container"
                            justify="flex-end"
                        >
                            <Pagination
                                count={pagination.pageCount}
                                page={pagination.currentPage}
                                onChange={(_, page) => {
                                    getAlerts(page)
                                }}
                                showFirstButton
                                showLastButton
                            />
                        </Grid>
                    </>
                )}
            </Styled.NotificationContainer>

            {!loading && !dataList.length && <NoNotifications />}

            <NotificationModal
                headerTitle="MENU_NOTIFICATION"
                width="900px"
                height="500px"
                headerIcon={false}
                closeByClick={() =>
                    setShowNotification((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
                onClose={() =>
                    setShowNotification((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
                open={showNotification.open}
                buttonTitle="BUTTON_CLOSE"
            >
                {showNotification.loading ? (
                    <div>
                        <NotificationSkeleton
                            variant="rect"
                            width="840px"
                            height="40px"
                        ></NotificationSkeleton>
                        <NotificationSkeleton
                            variant="rect"
                            width="840px"
                            height="40px"
                        ></NotificationSkeleton>
                        <NotificationSkeleton
                            variant="rect"
                            width="840px"
                            height="305px"
                        ></NotificationSkeleton>
                    </div>
                ) : (
                    <Grid container>
                        <Grid item xs={12} className="date-render">
                            <small>{showNotification.publishFormat}</small>
                        </Grid>
                        <Grid item xs={12} className="title-render">
                            <b>{showNotification.title}</b>
                        </Grid>
                        <BodyRender
                            item
                            xs={12}
                            className="body-render"
                            minheight="30vh"
                        >
                            <RenderDescription
                                renderComp={showNotification.content}
                            ></RenderDescription>
                        </BodyRender>
                    </Grid>
                )}
            </NotificationModal>
        </Styled.Container>
    )
}

export default Notification
