import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'

export const DigitalWallet = styled(Grid)`
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: #4d4e51;
    font-size: 15px;
    margin-bottom: 7px;
    a {
        color: #4d4e51;
    }

    .wallet {
        background-color: ${({ newTheme }) =>
            newTheme?.navigation?.menu?.backgroundColor};
        margin-bottom: 2px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 15px;
    }

    .user-name {
        color: ${({ newTheme }) => newTheme?.titles?.main?.textColor};
        font-weight: 500;
        font-size: 15px;
        text-transform: uppercase;
        padding: 2px 0px;
    }

    .status {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: bold;
        // color: #333333;
        color: ${({ newTheme }) => newTheme?.titles?.main?.textColor};
        text-transform: uppercase;
        padding: 2px 0px;
    }

    .wallet-buttom {
        &:hover {
            color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.textColor || '#fff'};
            i {
                color: ${'#fff'};
            }
            span {
                color: ${'#fff'};
            }
        }
        max-height: 50px;
        background-color: ${({ newTheme }) =>
            newTheme?.navigation?.menu?.backgroundColor || '#F58B3C'};
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 2px;
        padding: 15px;
        display: flex;
        cursor: pointer;
        transition: width 2s;
        .icon-saldo_pontuacao {
            color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.textColor || '#fff'};
            z-index: 2;
            font-size: 19px;
            margin-left: -5px;
        }
        .icon-destaque_saldo {
            color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.highlightColor || '#F58B3C'};
            font-size: 50px;
            margin-top: -15px;
            margin-left: -16px;
            position: absolute;
            width: 100%;
        }
        .icon-carteira_digital {
            color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.textColor || '#fff'};
            z-index: 2;
            font-size: 19px;
            margin-left: -5px;
        }
        &.MuiGrid-root:hover {
            background-image: none;
            background-color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.highlightColor || '#F58B3C'};
            color: ${'#fff'};
            .icon-saldo_pontuacao {
                color: ${({ newTheme }) =>
                    newTheme?.navigation?.menu?.textColor || '#fff'};
            }
            .icon-carteira_digital {
                color: ${({ newTheme }) =>
                    newTheme?.navigation?.menu?.textColor || '#fff'};
            }
            .icon-destaque_saldo {
                color: ${({ newTheme }) =>
                    newTheme?.navigation?.menu?.highlightColor || '#F58B3C'};
            }
        }
    }

    .wallet-buttom-focused {
        color: ${({ newTheme }) =>
            newTheme?.navigation?.menu?.textColor || '#fff'};
        i {
            color: ${'#fff'};
        }
        span {
            color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.textColor || '#fff'};
        }
        .icon-saldo_pontuacao {
            color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.textColor || '#fff'};
            z-index: 2;
            font-size: 19px;
            margin-left: -5px;
        }
        .icon-destaque_saldo {
            color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.highlightColor || '#F58B3C'};
            font-size: 50px;
            margin-top: -15px;
            margin-left: -16px;
            position: absolute;
            width: 100%;
        }
        .icon-carteira_digital {
            color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.textColor || '#fff'};
            z-index: 2;
            font-size: 19px;
            margin-left: -5px;
        }

        max-height: 50px;
        background-color: ${({ newTheme }) =>
            newTheme?.navigation?.menu?.activeColor || '#21409a'};
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 2px;
        padding: 15px;
        display: flex;
        cursor: pointer;
        transition: width 2s;

        &.MuiGrid-root:hover {
            background-image: none;
            background-color: ${({ newTheme }) =>
                newTheme?.navigation?.menu?.highlightColor || '#21409a'};
            color: #fff;
            .wallet-icon {
                color: 'white';
            }
        }
    }

    .wallet-icon {
        width: 200px;
        height: 100px;
        border-radius: 100px / 50px;
    }

    .buttom-text {
        font-size: 13px;
        padding-left: 5px;
        /* position: relative; */
        bottom: 6px;
    }

    .buttom-value {
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        color: #3c4858;
    }
`
