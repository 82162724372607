import React, { useEffect } from 'react'
import { Divider } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import * as S from './styles.js'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import * as moment from 'moment'
import { numberFormatText } from 'utils/utils'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ececec',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        margin: '3px 0',
        '&:nth-child(even)': {
            backgroundColor: '#F4F4F4',
        },
    },
    arrowOpen: {
        backgroundColor: '#ececec',
        borderBottomRightRadius: '4px',
        margin: '3px 0',
        '&:nth-child(even)': {
            backgroundColor: '#F4F4F4',
        },
    },
    arrowClose: {
        backgroundColor: '#ececec',
        margin: '3px 0',
        '&:nth-child(even)': {
            backgroundColor: '#F4F4F4',
        },
    },
}))

const tipeOfMov = [
    { name: 'Valor', symbol: '' },
    {
        name: localStorage.getItem('programCurrencyName'),
        symbol: localStorage.getItem('programCurrencySymbol'),
    },
    { name: 'CASHBACK', symbol: localStorage.getItem('currencySymbol') },
]

function GridTable({ data, history }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [expand, setExpand] = useState('')
    const [theme, setTheme] = useState(GetThemeColorFromStorage())

    useEffect(() => {
        setExpand('none')
    }, [data])

    useEffect(() => {
        setTheme(GetThemeColorFromStorage())
    }, [])

    function toggleDetail(index) {
        setExpand(expand === index ? '' : index)
    }

    return (
        <S.Container>
            {data &&
                data.map((item, index) => {
                    return (
                        <S.RowContainer>
                            <S.TableGrid
                                className={classes.root}
                                colorValue={index}
                            >
                                <S.FirstContain>
                                    <S.ContentLabel>
                                        <div className="label">
                                            {t('PURCHASE_DATE')}
                                        </div>
                                        <div className="textValue">
                                            {moment
                                                .utc(item?.date)
                                                .format('L') +
                                                ' - ' +
                                                moment(item?.date).format(
                                                    'HH:mm',
                                                )}
                                        </div>
                                    </S.ContentLabel>

                                    <S.ContentLabel>
                                        <div className="label">
                                            {t('FIELD_PARTNER')}
                                        </div>
                                        <div
                                            className="textValue"
                                            style={{
                                                maxWidth: '20ch',
                                                overflow: ' hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                            title={
                                                item?.partnerName
                                                    ? item.partnerName
                                                    : ' - '
                                            }
                                        >
                                            {item?.partnerName
                                                ? item.partnerName
                                                : ' - '}
                                        </div>
                                    </S.ContentLabel>
                                    <S.ContentLabel>
                                        <div className="label">
                                            {t('FIELD_INVOICE_NUMBER')}
                                        </div>
                                        <div className="textValue">
                                            {item?.documentID
                                                ? item.documentID
                                                : ' - '}
                                        </div>
                                    </S.ContentLabel>
                                    <S.ContentLabel>
                                        <div className="label">
                                            {t('PURCHASE_VALUE')}
                                        </div>
                                        <div className="textValue">
                                            {item?.totalCurrency?.toLocaleString(
                                                'pt-BR',
                                                {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                },
                                            ) || ' - '}
                                        </div>
                                    </S.ContentLabel>
                                </S.FirstContain>

                                {expand === index && (
                                    <>
                                        {item &&
                                            item.generated?.map(
                                                (item, index) => {
                                                    return (
                                                        <S.FirstContain>
                                                            <Divider
                                                                style={{
                                                                    width: '100%',
                                                                    margin: '6px 0px',
                                                                }}
                                                            />
                                                            <S.ContentLabel>
                                                                <div className="label">
                                                                    {'ID ' +
                                                                        t(
                                                                            item
                                                                                .type
                                                                                ?.value,
                                                                        )}
                                                                </div>
                                                                <div className="textValue">
                                                                    {item.code}
                                                                </div>
                                                            </S.ContentLabel>
                                                            <S.ContentLabel>
                                                                <div className="label">
                                                                    {t(
                                                                        tipeOfMov[
                                                                            item
                                                                                .type
                                                                                ?.key
                                                                        ].name,
                                                                    ) +
                                                                        ' ' +
                                                                        t(
                                                                            'ACCUMULATED_PREFIX',
                                                                        )}
                                                                </div>
                                                                <div className="textValue">
                                                                    {item.value
                                                                        ? numberFormatText(
                                                                              item.value,
                                                                              item
                                                                                  .type
                                                                                  ?.key ===
                                                                                  '1'
                                                                                  ? localStorage.getItem(
                                                                                        'programCurrencySymbol',
                                                                                    ) +
                                                                                        ' '
                                                                                  : localStorage.getItem(
                                                                                        'currencySymbol',
                                                                                    ) +
                                                                                        ' ',
                                                                              item
                                                                                  .type
                                                                                  ?.key ===
                                                                                  '1'
                                                                                  ? 0
                                                                                  : 2,
                                                                          )
                                                                        : '-'}
                                                                </div>
                                                            </S.ContentLabel>
                                                            {item.redeemedValue !==
                                                                0 && (
                                                                <S.ContentLabel>
                                                                    <div className="label">
                                                                        {item
                                                                            .type
                                                                            ?.key ===
                                                                        '1'
                                                                            ? t(
                                                                                  tipeOfMov[
                                                                                      item
                                                                                          .type
                                                                                          ?.key
                                                                                  ]
                                                                                      .name,
                                                                              ) +
                                                                              ' ' +
                                                                              t(
                                                                                  'REDEEMED_PREFIX',
                                                                              )
                                                                            : t(
                                                                                  'WALLET_REDEEM',
                                                                              )}
                                                                    </div>
                                                                    <div className="textValue">
                                                                        {item.redeemedValue
                                                                            ? numberFormatText(
                                                                                  item.redeemedValue,
                                                                                  item
                                                                                      .type
                                                                                      ?.key ===
                                                                                      '1'
                                                                                      ? localStorage.getItem(
                                                                                            'programCurrencySymbol',
                                                                                        ) +
                                                                                            ' '
                                                                                      : localStorage.getItem(
                                                                                            'currencySymbol',
                                                                                        ) +
                                                                                            ' ',
                                                                                  item
                                                                                      .type
                                                                                      ?.key ===
                                                                                      '1'
                                                                                      ? 0
                                                                                      : 2,
                                                                              )
                                                                            : '-'}
                                                                    </div>
                                                                </S.ContentLabel>
                                                            )}
                                                        </S.FirstContain>
                                                    )
                                                },
                                            )}
                                    </>
                                )}
                            </S.TableGrid>
                            <S.BtnContainer container md={1}>
                                {expand === index ? (
                                    <S.BtnArrow
                                        colorValue={index}
                                        className={classes.arrowOpen}
                                        style={{
                                            cursor:
                                                item.generated?.find(
                                                    (item) =>
                                                        item.value ||
                                                        item.redeemedValue !==
                                                            0,
                                                ) !== undefined
                                                    ? 'pointer'
                                                    : 'unset',
                                        }}
                                        onClick={() =>
                                            item.generated?.find(
                                                (item) =>
                                                    item.value ||
                                                    item.redeemedValue !== 0,
                                            ) !== undefined
                                                ? toggleDetail(index)
                                                : ''
                                        }
                                    >
                                        <S.PositionArrow>
                                            <i
                                                style={{
                                                    fontSize: 14,
                                                    color:
                                                        item.generated?.find(
                                                            (item) =>
                                                                item.value ||
                                                                item.redeemedValue !==
                                                                    0,
                                                        ) !== undefined
                                                            ? GetThemeColorFromStorage()
                                                                  ?.visual
                                                                  ?.icons?.color
                                                            : 'grey',
                                                }}
                                                className={
                                                    'icon-seta_acordium_aberto'
                                                }
                                            />
                                        </S.PositionArrow>
                                    </S.BtnArrow>
                                ) : (
                                    <S.BtnArrow
                                        colorValue={index}
                                        className={classes.arrowClose}
                                        style={{
                                            cursor:
                                                item.generated?.find(
                                                    (item) =>
                                                        item.value ||
                                                        item.redeemedValue !==
                                                            0,
                                                ) !== undefined
                                                    ? 'pointer'
                                                    : 'unset',
                                        }}
                                        onClick={() =>
                                            item.generated?.find(
                                                (item) =>
                                                    item.value ||
                                                    item.redeemedValue !== 0,
                                            ) !== undefined
                                                ? toggleDetail(index)
                                                : ''
                                        }
                                    >
                                        <S.PositionArrow>
                                            <i
                                                style={{
                                                    fontSize: 28,
                                                    position: 'relative',
                                                    color:
                                                        item.generated?.find(
                                                            (item) =>
                                                                item.value ||
                                                                item.redeemedValue !==
                                                                    0,
                                                        ) !== undefined
                                                            ? GetThemeColorFromStorage()
                                                                  ?.visual
                                                                  ?.icons?.color
                                                            : 'grey',
                                                }}
                                                className={
                                                    'icon-seta_acordium_fechado'
                                                }
                                            />
                                        </S.PositionArrow>
                                    </S.BtnArrow>
                                )}

                                <S.BtnDetail
                                    onClick={() =>
                                        history.push(
                                            `/shopHistory/detail/${item.id}`,
                                        )
                                    }
                                >
                                    <Search
                                        style={{
                                            margin: 'auto',
                                            color: theme?.navigation?.buttons
                                                ?.textColor,
                                        }}
                                    />
                                </S.BtnDetail>
                            </S.BtnContainer>
                        </S.RowContainer>
                    )
                })}
        </S.Container>
    )
}

GridTable.propTypes = {
    labels: PropTypes.string,
    values: PropTypes.string,
    history: PropTypes.string,
    id: PropTypes.string,
    data: PropTypes.array,
}

GridTable.defaultProps = {
    labels: '',
    values: '',
    history: '',
    id: '',
    data: [],
}

export default withTranslation()(GridTable)
