/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import MuiDrawer from "@mui/material/Drawer";
import { styled as muiStyled } from "@mui/material/styles";
var drawerWidth = "300px";
var openedMixin = function (theme) { return ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
}); };
var closedMixin = function (theme) {
    var _a;
    return (_a = {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            width: "calc(".concat(theme.spacing(7), " + 1px)")
        },
        _a[theme.breakpoints.up("sm")] = {
            width: "calc(".concat(theme.spacing(8), " + 1px)"),
        },
        _a);
};
export var DrawerHeader = muiStyled("div")(function (_a) {
    var theme = _a.theme;
    return (__assign({ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: theme.spacing(0, 2) }, theme.mixins.toolbar));
});
export var Drawer = muiStyled(MuiDrawer, {
    shouldForwardProp: function (prop) { return prop !== "open"; },
})(function (_a) {
    var theme = _a.theme, open = _a.open;
    return (__assign(__assign({ width: "100%", flexShrink: 0, whiteSpace: "nowrap", boxSizing: "border-box" }, (open && __assign(__assign({}, openedMixin(theme)), { "& .MuiDrawer-paper": openedMixin(theme) }))), (!open && __assign(__assign({}, closedMixin(theme)), { "& .MuiDrawer-paper": closedMixin(theme) }))));
});
