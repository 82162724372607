import * as actionTypes from './actionsTypes';
import * as API from '../api/location';

export const fetchCityData = (data) => {
    return {
        type: actionTypes.FETCH_CITY_DATA,
        cityData: data
    };
};
export const fetchStateData = (data) => {
    return {
        type: actionTypes.FETCH_STATE_DATA,
        stateData: data
    };
};
export const fetchCountriesData = (data) => {
    return {
        type: actionTypes.FETCH_COUNTRIES_DATA,
        countryData: data
    };
};

export const fetchZipcodeData = (data) => {
    return {
        type: actionTypes.FETCH_ZIPCODE_DATA,
        zipcodeData: data
    };
};
//
// FAIL FETCH -------------------------------------
export const fetchCityDataError = (error) => {
    return {
        type: actionTypes.FETCH_CITY_FAILED,
        cityDataFailed: error
    };
};
export const fetchStateDataError = (error) => {
    return {
        type: actionTypes.FETCH_STATE_FAILED,
        stateDataFailed: error
    };
};
export const fetchCountriesDataError = (error) => {
    return {
        type: actionTypes.FETCH_COUNTRIES_FAILED,
        countryDataFailed: error
    };
};
export const fetchZipcodeDataError = (error) => {
    return {
        type: actionTypes.FETCH_ZIPCODE_FAILED,
        zipcodeFailed: error
    };
};


//
//----------------------------------------------------
export const GetCityData = (stateId) => {
    return dispatch => (
        API.GetCity(stateId).then(response => {
            dispatch(fetchCityData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchCityDataError(error));
        })
    );
};

export const GetStateData = (countryId) => {
    return dispatch => (
        API.GetState(countryId).then(response => {
            dispatch(fetchStateData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchStateDataError(error));
        })
    );
};

export const GetCountryData = () => {
    return dispatch => (
        API.GetCountries().then(response => {
            dispatch(fetchCountriesData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchCountriesDataError(error));
        })
    );
};
export const SearchZipcodeData = (zipcode) => {
    return dispatch => (
        API.FindZipCode(zipcode).then(response => {
            dispatch(fetchZipcodeData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchZipcodeDataError(error));
        })
    );
};
