import styled from 'styled-components/macro'

export const ContainerRegister = styled.div`
    .MuiGrid-item {
        margin: 7px 0px;
    }
    .tooltip-box {
        padding: 0px !important;
        .MuiGrid-item {
            margin: 0;
            padding: 0px !important;
            @media screen and (max-width: 640px) {
                padding: 0px !important;
            }
        }
    }
    .access-box {
        margin-left: 0px;
        gap: 40px;

        @media screen and (max-width: 1279px) {
            gap: 0px;
        }
    }


    .remove-padding {
        padding-right: 0px !important;
    }

    @media screen and (max-width: 1279px) {
        .access-box {
            gap: 0px;
        }

        .indication-code {
            padding-right: 0px !important;
        }
    }

    @media screen and (max-width: 900px) {
        .person-box {
            margin-top: 100px;
        }
        .box-right {
            > div {
                margin: 0px;
            }
        }
        .MuiFormControlLabel-root {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .form-box {
            .MuiIconButton-root {
                padding: 0 9px;
            }
        }
    }
`

export const BoxPolicy = styled.div`
    ul {
        margin: 0;
    }
`

export const ErrorBox = styled.div`
    .mt-2 {
        margin-top: 20px;
    }
    a,
    span {
        font-size: 14px;
        font-weight: 700;
        color: #21409a;
    }
    .MuiTypography-body1 {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-weight: 300;
    }
    .error {
        .MuiTypography-body1 {
            color: #f44336;
        }
    }
    .MuiFormGroup-root {
        flex-direction: row;
    }
`
