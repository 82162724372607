import React from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'
import SidebarWrapper from './SidebarWrapper'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Collapse from '@material-ui/core/Collapse'
import { Button } from '../../components/storybook'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor.js'

// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.jsx'
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx'
import BorderImg from 'assets/img/u183.png'
import { withTranslation } from 'react-i18next'
import defaultAvatar from 'assets/img/fazendeiro.png'
import logoF from 'assets/img/logo_Germini.png'
// import Button from 'components/CustomButtons/Button.jsx'
import { createMuiTheme, MuiThemeProvider, Icon } from '@material-ui/core'
import DigitalWallet from '../DigitalWallet'
import compose from 'utils/compose'
import * as S from './styles'
import { GetNegotiation } from '../../redux/actions/negotiation.js'

const theme = createMuiTheme({
    overrides: {
        MuiListItem: {
            divider: {
                borderBottom: '1px solid',
                backgroundClip: 'padding-box',
                borderImageSource: `url(${BorderImg})`,
                borderImageSlice: '3',
            },
        },
    },
})

class Sidebar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openAvatar: false,
            miniActive: true,
            themeColor: {},
            showNegotiation: false,
            ...this.getCollapseStates(props.routes),
        }
    }

    componentDidMount() {
        this.setState({
            themeColor: GetThemeColorFromStorage(),
        })
    }

    getCollapseStates = (routes) => {
        let initialState = {}
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState,
                }
            }
            return null
        })
        return initialState
    }
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (
                routes[i].collapse &&
                this.getCollapseInitialState(routes[i].views)
            ) {
                return true
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true
            }
        }
        return false
    }
    activeRoute = (routeName) => {
        return this.props.location.pathname.indexOf(routeName) > -1
            ? 'active'
            : ''
    }
    openCollapse(collapse) {
        var st = {}
        st[collapse] = !this.state[collapse]
        this.setState(st)
    }
    createLinks = (routes) => {
        const { t, classes, color, rtlActive } = this.props
        return routes
            .filter((n) => n.sidebar)
            .map((prop, key) => {
                if (prop.redirect) {
                    return null
                }
                if (prop.collapse) {
                    var st = {}
                    st[prop['state']] = !this.state[prop.state]
                    const navLinkClasses =
                        classes.itemLink +
                        ' ' +
                        cx({
                            [' ' + classes.collapseActive]:
                                this.getCollapseInitialState(prop.views),
                        })
                    const itemText =
                        classes.itemText +
                        ' ' +
                        cx({
                            [classes.itemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                            [classes.itemTextMiniRTL]:
                                rtlActive &&
                                this.props.miniActive &&
                                this.state.miniActive,
                            [classes.itemTextRTL]: rtlActive,
                        })
                    const collapseItemText =
                        classes.collapseItemText +
                        ' ' +
                        cx({
                            [classes.collapseItemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                            [classes.collapseItemTextMiniRTL]:
                                rtlActive &&
                                this.props.miniActive &&
                                this.state.miniActive,
                            [classes.collapseItemTextRTL]: rtlActive,
                        })
                    const caret =
                        classes.caret +
                        ' ' +
                        cx({
                            [classes.caretRTL]: rtlActive,
                        })
                    return (
                        <ListItem
                            divider
                            key={key}
                            className={cx({
                                [classes.item]: prop.icon !== undefined,
                            })}
                        >
                            <NavLink
                                to={'#'}
                                className={navLinkClasses}
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.setState(st)
                                }}
                            >
                                <ListItemText
                                    primary={
                                        rtlActive
                                            ? t(prop.rtlName)
                                            : t(prop.name)
                                    }
                                    secondary={
                                        <b
                                            className={
                                                caret +
                                                ' ' +
                                                (this.state[prop.state]
                                                    ? classes.caretActive
                                                    : '')
                                            }
                                        />
                                    }
                                    disableTypography={true}
                                    className={cx(
                                        { [itemText]: prop.icon !== undefined },
                                        {
                                            [collapseItemText]:
                                                prop.icon === undefined,
                                        },
                                    )}
                                />
                            </NavLink>
                            <Collapse in={this.state[prop.state]} unmountOnExit>
                                <List
                                    className={
                                        classes.list +
                                        ' ' +
                                        classes.collapseList
                                    }
                                >
                                    {this.createLinks(prop.views)}
                                </List>
                            </Collapse>
                        </ListItem>
                    )
                }
                const innerNavLinkClasses =
                    classes.collapseItemLink +
                    ' ' +
                    cx({
                        [' ' + classes[color]]: this.activeRoute(prop.path),
                    })
                const navLinkClasses =
                    classes.itemLink +
                    ' ' +
                    cx({
                        [' ' + classes[color]]: this.activeRoute(prop.path),
                    })
                const itemText =
                    classes.itemText +
                    ' ' +
                    cx({
                        [classes.itemTextMini]:
                            this.props.miniActive && this.state.miniActive,
                        [classes.itemTextMiniRTL]:
                            rtlActive &&
                            this.props.miniActive &&
                            this.state.miniActive,
                        [classes.itemTextRTL]: rtlActive,
                    })
                const collapseItemText =
                    classes.collapseItemText +
                    ' ' +
                    cx({
                        [classes.collapseItemTextMini]:
                            this.props.miniActive && this.state.miniActive,
                        [classes.collapseItemTextMiniRTL]:
                            rtlActive &&
                            this.props.miniActive &&
                            this.state.miniActive,
                        [classes.collapseItemTextRTL]: rtlActive,
                    })
                const itemIcon =
                    classes.itemIcon +
                    ' ' +
                    cx({
                        [classes.itemIconRTL]: rtlActive,
                    })
                return (
                    <MuiThemeProvider theme={theme} key={Math.random()}>
                        <ListItem
                            divider
                            key={key}
                            className={cx(
                                { [classes.item]: prop.icon !== undefined },
                                {
                                    [classes.collapseItem]:
                                        prop.icon === undefined,
                                },
                            )}
                        >
                            <NavLink
                                to={prop.path}
                                className={cx(
                                    {
                                        [navLinkClasses]:
                                            prop.icon !== undefined,
                                    },
                                    {
                                        [innerNavLinkClasses]:
                                            prop.icon === undefined,
                                    },
                                )}
                            >
                                {this.props.regulationWarning &&
                                    prop.name === 'MENU_REGULATION' && (
                                        <Icon
                                            className={itemIcon}
                                            style={{
                                                color: '#ff9800',
                                                position: 'relative',
                                                top: '2px',
                                                right: '75px',
                                            }}
                                        >
                                            {prop.icon}
                                        </Icon>
                                    )}
                                {this.props.useTermsWarning &&
                                    prop.name === 'MENU_USE_TERMS' && (
                                        <Icon
                                            className={itemIcon}
                                            style={{
                                                color: '#ff9800',
                                                position: 'relative',
                                                top: '2px',
                                                right: '75px',
                                            }}
                                        >
                                            {prop.icon}
                                        </Icon>
                                    )}
                                <ListItemText
                                    primary={t(prop.name)}
                                    disableTypography={true}
                                    className={cx(
                                        { [itemText]: prop.icon !== undefined },
                                        {
                                            [collapseItemText]:
                                                prop.icon === undefined,
                                        },
                                    )}
                                />
                            </NavLink>
                        </ListItem>
                    </MuiThemeProvider>
                )
            })
    }
    render() {
        const {
            t,
            classes,
            logo,
            image,
            routes,
            bgColor,
            rtlActive,
            userData,
        } = this.props

        const itemText =
            classes.itemText +
            ' ' +
            cx({
                [classes.itemTextMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                    rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive,
            })
        const userWrapperClass =
            classes.user +
            ' ' +
            cx({
                [classes.whiteAfter]: bgColor === 'white',
            })
        const photo = classes.photo
        const mobileButtons = classes.mobileButtons

        let User = (
            <div className={userWrapperClass}>
                <div className={photo}>
                    <img
                        src={
                            userData && userData.pictureUrl
                                ? userData.pictureUrl
                                : defaultAvatar
                        }
                        className={classes.avatarImg}
                        alt="User"
                    />
                </div>
                <List onClick={this.props.handleDrawerToggle}>
                    <DigitalWallet userData={userData} />
                    <div className={mobileButtons}>
                        <NavLink to="/profile">
                            <Button
                                customSize="100%"
                                outlined="outlined"
                                text={t('MENU_PROFILE')}
                                typeVariation="DefaultButton"
                                sx={{
                                    textTransform: 'inherit',
                                    border: '1px solid #f2f2f2',
                                    width: '100%',
                                    color:
                                        (this?.props?.location?.pathname ===
                                            '/profile' &&
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.textColor) ||
                                        '#878787',
                                    marginBottom: 0.5,
                                    justifyContent: 'left',
                                    padding: '10px 15px',
                                    fontSize: '15px',
                                    backgroundColor:
                                        (this?.props?.location?.pathname ===
                                            '/profile' &&
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.backgroundColor) ||
                                        'transparent',
                                    ':hover': {
                                        backgroundColor:
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.backgroundColor,
                                        color: '#FFFFFF',
                                        border: 'none',
                                    },
                                }}
                            />
                        </NavLink>

                        <NavLink to="/accountPreferences">
                            <Button
                                customSize="100%"
                                outlined="outlined"
                                text={t('MENU_USER_ACCOUNT_PREFERENCES')}
                                typeVariation="DefaultButton"
                                sx={{
                                    textTransform: 'inherit',
                                    border: '1px solid #f2f2f2',
                                    width: '100%',
                                    color:
                                        (this?.props?.location?.pathname ===
                                            '/accountPreferences' &&
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.textColor) ||
                                        '#878787',
                                    marginBottom: 0.5,
                                    justifyContent: 'left',
                                    padding: '10px 15px',
                                    fontSize: '15px',
                                    backgroundColor:
                                        (this?.props?.location?.pathname ===
                                            '/accountPreferences' &&
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.backgroundColor) ||
                                        'transparent',
                                    ':hover': {
                                        backgroundColor:
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.backgroundColor,
                                        color: '#FFFFFF',
                                        border: 'none',
                                    },
                                }}
                            />
                        </NavLink>

                        <NavLink to="/changepassword">
                            <Button
                                customSize="100%"
                                outlined="outlined"
                                text={t('MENU_USER_EDIT_PASSWORD')}
                                typeVariation="DefaultButton"
                                sx={{
                                    textTransform: 'inherit',
                                    border: '1px solid #f2f2f2',
                                    width: '100%',
                                    color:
                                        (this?.props?.location?.pathname ===
                                            '/changepassword' &&
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.textColor) ||
                                        '#878787',
                                    marginBottom: 0.5,
                                    justifyContent: 'left',
                                    padding: '10px 15px',
                                    fontSize: '15px',
                                    backgroundColor:
                                        (this?.props?.location?.pathname ===
                                            '/changepassword' &&
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.backgroundColor) ||
                                        'transparent',
                                    ':hover': {
                                        backgroundColor:
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.backgroundColor,
                                        color: '#FFFFFF',
                                        border: 'none',
                                    },
                                }}
                            />
                        </NavLink>

                        <NavLink to="/auth/login">
                            <Button
                                customSize="100%"
                                outlined="outlined"
                                text="Sair"
                                typeVariation="DefaultButton"
                                sx={{
                                    textTransform: 'inherit',
                                    border: '1px solid #f2f2f2',
                                    width: '100%',
                                    color:
                                        (this?.props?.location?.pathname ===
                                            '/auth/login' &&
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.textColor) ||
                                        '#878787',
                                    justifyContent: 'left',
                                    padding: '10px 15px',
                                    fontSize: '15px',
                                    backgroundColor:
                                        (this?.props?.location?.pathname ===
                                            '/auth/login' &&
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.backgroundColor) ||
                                        'transparent',
                                    ':hover': {
                                        backgroundColor:
                                            this?.state?.themeColor?.navigation
                                                ?.buttons?.backgroundColor,
                                        color: '#FFFFFF',
                                        border: 'none',
                                    },
                                }}
                            />
                        </NavLink>
                    </div>
                </List>
            </div>
        )
        var links = (
            <List className={classes.list}>{this.createLinks(routes)}</List>
        )

        const logoClasses =
            classes.logo +
            ' ' +
            cx({
                [classes.whiteAfter]: bgColor === 'white',
            })
        var brand = (
            <div className={logoClasses}>
                <img
                    src={this.state.miniActive ? logo : logoF}
                    alt="logo"
                    className={classes.img}
                />
            </div>
        )
        const drawerPaper =
            classes.drawerPaper +
            ' ' +
            cx({
                [classes.drawerPaperMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.drawerPaperRTL]: rtlActive,
            })
        const sidebarWrapper =
            classes.sidebarWrapper +
            ' ' +
            cx({
                [classes.drawerPaperMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.sidebarWrapperWithPerfectScrollbar]:
                    navigator.platform.indexOf('Win') > -1,
            })
        return (
            <div ref="mainPanel">
                {/* mobile */}
                <Hidden mdUp implementation="css">
                    <S.SidebarWrapperBox
                        variant="temporary"
                        anchor={'right'}
                        open={this.props.open}
                        classes={{
                            paper:
                                drawerPaper +
                                ' ' +
                                classes[bgColor + 'Background'],
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <SidebarWrapper
                            handleDrawerToggle={this.props.handleDrawerToggle}
                            className={sidebarWrapper}
                            user={this.props.userData !== undefined ? User : ''}
                            headerLinks={
                                <AdminNavbarLinks rtlActive={rtlActive} />
                            }
                            links={links}
                        />

                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{
                                    backgroundImage: 'url(' + image + ')',
                                }}
                            />
                        ) : null}
                    </S.SidebarWrapperBox>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        onMouseEnter={() =>
                            this.setState({ miniActive: false })
                        }
                        onMouseLeave={() => this.setState({ miniActive: true })}
                        variant="permanent"
                        elevation={0}
                        open
                        classes={{
                            paper:
                                drawerPaper +
                                ' ' +
                                classes[bgColor + 'Background'],
                        }}
                    >
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={User}
                            links={links}
                        />
                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{
                                    backgroundImage: 'url(' + image + ')',
                                }}
                            />
                        ) : null}
                    </Drawer>
                </Hidden>
            </div>
        )
    }
}

Sidebar.defaultProps = {
    bgColor: 'blue',
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf([
        'white',
        'red',
        'orange',
        'green',
        'blue',
        'purple',
        'rose',
    ]),
    logo: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
}

export default compose(
    withTranslation(),
    withStyles(sidebarStyle),
    withRouter,
)(Sidebar)
