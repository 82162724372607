import React from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import DocumentTitle from 'react-document-title'
import axios from 'axios'
import { withTranslation } from 'react-i18next'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import RenderRegulation from 'components/RenderRegulation/index.js'
import SectionPolicy from 'components/Section/SectionPolicy.jsx'

import { ContainerRegister } from './styles'
import style from '../register.module.scss'
import registerStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'
import compose from 'utils/compose'
import { getProgramTitle } from '../../../utils/utils'
require('moment/locale/pt-br')

const germiniLogoInterna =
	GetThemeColorFromStorage()?.images?.internalPages?.menuLogo?.url
class Register extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			textRG: '',
		}
		if (localStorage.getItem('i18nextLng') !== null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	componentDidMount() {
		axios.get(`/ProgramRules/Regulation`).then((response) => {
			this.setState({ textRG: response.data.regulation })
		})
	}

	render() {
		const { t } = this.props
		return (
			<>
				<ContainerRegister>
					<DocumentTitle title={getProgramTitle(t('PROGRAM_REGULATION'))} />
					<GridContainer justify="center" className={style.Container}>
						<div
							className={style.parent}
							style={{
								backgroundImage: `${GetThemeColorFromStorage()?.images
										?.internalPages?.menuBackground?.url
									}`,
							}}
						>
							<div className={style.headerContainer}>
								<GridItem
									xs={12}
									sm={12}
									md={9}
									lg={9}
									className={style.logoBox}
								>
									<img
										className={style.logo}
										src={germiniLogoInterna}
										alt="Logo"
									/>
								</GridItem>
							</div>
						</div>
					</GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							lg={12}
							style={{ margin: '10px 10% 32px' }}
						>
							<SectionPolicy title={t('PROGRAM_REGULATION')} />
						</GridItem>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<section>
							<article>
								<GridItem
									xs={12}
									sm={12}
									md={12}
									lg={12}
									style={{ margin: '0px 10% 5% 10%' }}
								>
									<RenderRegulation
										renderComp={this.state.textRG}
									/>
								</GridItem>
							</article>
						</section>
					</GridItem>
					<GridContainer>
						<div className={style.footer}></div>
					</GridContainer>
				</ContainerRegister>
			</>
		)
	}
}

export default compose(withStyles(registerStyle), withTranslation())(Register)
