/* eslint-disable */
import React, { memo, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'
import { Grid } from '@mui/material'
import { Box } from '@material-ui/core'

function Header() {
	const { push } = useHistory()

	const [theme, setTheme] = useState({})

	useEffect(() => {
		setTheme(GetThemeColorFromStorage)
	}, [])

	const images = theme?.images?.internalPages

	return (
		<Box
			sx={{
				backgroundImage: `url(${images?.menuBackground?.url})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				height: '130px',
				justifyContent: 'center',
				width: '100%',
			}}
		>
			<Box sx={{ maxWidth: '1138px', width: '100%', margin: 'auto', pt: '10px' }}>
				<Box
					component="img"
					src={images?.menuLogo?.url}
					alt={`${images?.menuLogo?.filename}`}
					sx={{
						width: '180px',
						cursor: 'pointer',
					}}
					onClick={() => push('/')}
				/>
			</Box>
		</Box>
	)
}

export default memo(Header)
