import moment from 'moment'
import {
    getAllPurchasers,
    GetAllTransactionStatus,
    getPurchaserByStatus,
    getStatusByPurchaser,
} from '../../../../../../../redux/api/paymentGateway'

export const getPurchaserObjects = async (setPurchaserObjects) => {
    const response = await getAllPurchasers()

    if (response) {
        const { data } = response
        const res = []
        for (const purchasers of data) {
            res.push({ label: purchasers.name })
        }
        setPurchaserObjects(res)
    }
}

export const getAllTransactionsStatus = async (setOptionsStatus) => {
    const getAllTransactionsStatus = await GetAllTransactionStatus()
    const res = []
    for (const purchasers in getAllTransactionsStatus.data) {
        getAllTransactionsStatus.data[purchasers].map((p) => {
            res.push({ label: p })
        })
    }
    setOptionsStatus(res)
}

export const getPurchaserStatus = async (status, setPurchaserObjects) => {
    const response = await getPurchaserByStatus(status.label)
    if (response) {
        const { data } = response
        setPurchaserObjects([{ label: data[0] }])
    }
}

export const getStatusbyPurchaser = async (purchaser, setOptionsStatus) => {
    const response = await getStatusByPurchaser(purchaser.label)
    const res = []
    for (const status of response.data) {
        res.push({ label: status })
    }
    setOptionsStatus(res)
}

export const periodOptions = [
    { value: 1, label: 'Últimos 7 dias' },
    { value: 2, label: 'Últimos 14 dias' },
    { value: 3, label: 'Personalizado' },
]

export const statusOptions = [
    { value: 1, label: 'Sucesso' },
    { value: 2, label: 'Falha' },
]

export const ordinationOptions = [
    { value: 1, label: 'Mais recente' },
    { value: 2, label: 'Mais antiga' },
]

export const handleChangePeriod = (event, setValue) => {
    let end = new Date()
    let start = moment()

    switch (event?.value) {
        case 1:
            start = moment(start).subtract(7, 'days')
            end = moment(end)

            break
        case 2:
            start = moment(start).subtract(14, 'days')
            end = moment(end)
            break
        case 3:
            start = ''
            end = ''
            break
        default:
            start = ''
            end = ''
            break
    }

    setValue('dateFrom', start)
    setValue('dateTo', end)
}

export const defaultValues = {
    period: [],
    dateFrom: '',
    dateTo: '',
    history: '',
    status: { label: '' },
    autorizadora: { label: '' },
}

export const Open = (e) => {
    const period = e?.value
    return period !== 3 ? false : undefined
}

export const IsDisabled = (e) => {
    const period = e?.value
    return !Boolean(period !== 3)
}

export function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

export const formatMessage = (message, name, createdAt) => {
    const formattedName = `<strong>${name}</strong>`
    const formattedSuccess = `<span style='color: #8FC992; font-weight: bold;'>sucesso</span>`
    const formattedDenied = `<span style='color: #F05B4F; font-weight: bold;'>negado</span>`
    const formattedPending = `<span style='color: #E6CC75; font-weight: bold;'>pendente</span>`
    const formattedDate = moment(createdAt)
        .locale('pt-BR')
        .format('DD [de] MMMM [de] YYYY')

    console.log(formattedDate)
    const regexName = new RegExp(name, 'g')
    const regexSuccess = /sucesso/g
    const regexDenied = /negado/g
    const regexPending = /pendente/g
    const regexDate = new RegExp(formattedDate, 'g')

    return message
        .replace(regexName, formattedName)
        .replace(regexSuccess, formattedSuccess)
        .replace(regexDenied, formattedDenied)
        .replace(regexPending, formattedPending)
        .replace(regexDate, `<strong>${formattedDate}</strong>`)
}

export const getColorStatus = (status) => {
	const statusLowerCase = status.toLowerCase()
	switch (statusLowerCase) {
		case 'sucesso':
			return '#8FC992'
		case 'negado':
			return '#F05B4F'
		case 'pendente':
			return '#E6CC75'
		default:
			return '#8FC992'
	}
}
