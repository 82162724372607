import React from 'react'

import {
    MuiStack,
    MuiTypography,
    formatBRCurrency,
    formatUSDCurrency,
} from '../../../../components/storybook'
import { numberFormatText } from 'utils/utils'

export function Values({ negotiationDetails }) {
    return (
        <MuiStack
            display="flex"
            AlignItems="center"
            justifyContent="end"
            width="100%"
            spacing={2}
            direction="row"
        >
            <MuiTypography
                fontSize="14px"
                fontWeight={600}
                display="flex"
                AlignItems="center"
                justifyContent="left"
                color="#717171"
            >
                Qtde de compras:{' '}
                {negotiationDetails?.itensDetails?.qtdInvoices ?? 0}
            </MuiTypography>

            <MuiTypography
                fontSize="14px"
                fontWeight={600}
                display="flex"
                AlignItems="center"
                justifyContent="left"
                color="#717171"
            >
                |
            </MuiTypography>
            <MuiTypography
                fontSize="14px"
                fontWeight={600}
                display="flex"
                AlignItems="center"
                justifyContent="left"
                color="#717171"
            >
                Qtde de itens: {negotiationDetails?.itensDetails?.qtdItens ?? 0}
            </MuiTypography>
            <MuiTypography
                fontSize="14px"
                fontWeight={600}
                display="flex"
                AlignItems="center"
                justifyContent="left"
                color="#717171"
            >
                |
            </MuiTypography>

            <MuiTypography
                fontSize="14px"
                fontWeight={600}
                display="flex"
                AlignItems="center"
                justifyContent="left"
                color="#717171"
            >
                Total das compras:{' '}
                {formatBRCurrency(
                    negotiationDetails?.itensDetails?.totalInvoice ?? 0,
                )}
            </MuiTypography>
            <MuiTypography
                fontSize="14px"
                fontWeight={600}
                display="flex"
                AlignItems="center"
                justifyContent="left"
                color="#717171"
            >
                |
            </MuiTypography>

            <MuiTypography
                fontSize="14px"
                fontWeight={600}
                display="flex"
                AlignItems="center"
                justifyContent="left"
                color="#717171"
            >
                Benefício gerado: <span style={{ marginRight: '5px' }}> </span>
                {negotiationDetails?.typeOfBenefit?.value === 'Cashback'
                    ? formatBRCurrency(negotiationDetails?.itensDetails?.total)
                    : numberFormatText(
                          negotiationDetails?.itensDetails?.total,
                          `${negotiationDetails?.simpleRules?.programCurrencySymbol} `,
                          0,
                      ) || 0}
            </MuiTypography>
        </MuiStack>
    )
}
