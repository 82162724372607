import { useEffect, useState } from 'react'
import { ScoringRulesService } from '../Services'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTheme, useMediaQuery } from '@mui/material'
import { formatValue, formatValuePoint } from '../functions'
import { GetCurrentConsumer, ValidateConsumer } from '../../../redux/api/user'
import eventEmitter from '../../../components/DigitalWallet/eventEmitter'

export default function useScoringRules() {
    const scoringRulesService = new ScoringRulesService()
    const { t } = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const { handleSubmit, watch, setValue, control, reset } = useForm({
        defaultValues: {
            points: '',
        },
    })
    const watchFields = watch()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalTransferOpen, setIsModalTransferOpen] = useState(false)
    const [dataSimulatePointsConversion, setDataSimulatePointsConversion] =
        useState([])
    const [validatedPassword, setValidatedPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [errorMessageValue, setErrorMessageValue] = useState('')
    const [showValueInput, setShowValueInput] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [isButtonDisabledSimulate, setIsButtonDisabledSimulate] =
        useState(true)
    const [currentConsumer, setCurrentConsumer] = useState([])
    const [inputKey, setInputKey] = useState(Date.now())
    const [isLoading, setIsLoading] = useState(false)

    const handleHomeClick = () => {
        history.push('/home')
    }
    const handleWalletClick = () => {
        history.push('/wallet')
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    function handleLoader() {
        setIsLoading(!isLoading)
    }

    async function getSimulatePointsConversion() {
        const formData = formatValue(watchFields?.points)
        const response = await scoringRulesService.getSimulatePointsConversion(
            formData,
        )

        if (response.success) {
            setErrorMessageValue('')
            setDataSimulatePointsConversion(response?.data)
        } else {
            if (response?.errors?.length > 0) {
                const error = response?.errors[0]?.message.split(' - ')
                setErrorMessageValue(
                    t(error[1]) || 'Erro ao enviar a mensagem!',
                )
            }
            if (formatValuePoint(watchFields.points) > currentConsumer.points) {
                setErrorMessageValue('Sua solicitação excede o saldo permitido')
            }
        }
    }

    const getCurrentConsumer = async () => {
        try {
            const response = await GetCurrentConsumer()
            setCurrentConsumer(response)
            return response
        } catch (error) {
            console.error('Erro ao obter consumidor atual:', error)
        }
    }

    async function createRequestPointsConversion(data) {
        const formData = {
            password: data,
            isAcceptTermsAndConditions: watchFields?.acceptTerms,
            pointsValue: dataSimulatePointsConversion?.pointsValue,
            pointCurrency: dataSimulatePointsConversion?.pointCurrency,
            liquidValue: dataSimulatePointsConversion?.liquidValue,
        }

        const response =
            await scoringRulesService.updateRequestPointsConversion(formData)
        if (response.success) {
            setDataSimulatePointsConversion(response?.data)
            const updatedData = await getCurrentConsumer()
            setMessageSuccess(true)
            eventEmitter.emit('updatePoints', updatedData?.points)
            eventEmitter.emit(
                'updateWalletValue',
                updatedData?.digitalWalletBalance,
            )
        } else {
            if (response.errors[0].message === 'PointsValue - INVALID_VALUE') {
                setErrorMessage('Valor inválido')
            }
        }
    }

    const handlePasswordSubmit = async (data) => {
        try {
            handleLoader()
            const formData = data?.password
            const response = await ValidateConsumer(formData)
            if (response === true) {
                setValidatedPassword(formData)
                await createRequestPointsConversion(formData)
            } else {
                setErrorMessage(
                    'A senha está incorreta. Por favor, tente novamente.',
                )
            }
        } catch (error) {
            setErrorMessage('Erro de validação ou conexão')
            console.error('Erro ao validar consumidor:', error)
        }
    }

    const handleSimulateClick = () => {
        setShowValueInput(true)
        getSimulatePointsConversion()
    }

    const handleClearClick = () => {
        setValue('points', null)
        setShowValueInput(false)
        setInputKey(Date.now())
        setErrorMessageValue('')
        setDataSimulatePointsConversion([])
    }

    useEffect(() => {
        Promise.all([getCurrentConsumer()])
    }, [])

    useEffect(() => {
        const { points, acceptTerms } = watchFields
        setIsButtonDisabled(!(points && acceptTerms))
    }, [watchFields, errorMessage])

    useEffect(() => {
        const { points } = watchFields
        setIsButtonDisabledSimulate(!points)
    }, [watchFields])

    return {
        t,
        watchFields,
        setValue,
        handleSubmit,
        control,
        isModalOpen,
        handleCloseModal,
        handleOpenModal,
        reset,
        isModalTransferOpen,
        setIsModalTransferOpen,
        handleHomeClick,
        handleWalletClick,
        isSmallScreen,
        dataSimulatePointsConversion,
        getSimulatePointsConversion,
        handlePasswordSubmit,
        errorMessage,
        setErrorMessage,
        validatedPassword,
        messageSuccess,
        currentConsumer,
        errorMessageValue,
        handleClearClick,
        handleSimulateClick,
        showValueInput,
        inputKey,
        isButtonDisabled,
        isButtonDisabledSimulate,
        setErrorMessageValue,
        isLoading,
        handleLoader,
    }
}
