import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Box } from '@mui/material'
import sem_imagem from 'assets/img/sem_imagem_consumidor.png'
import { GetUrlCategory } from '../../../../redux/api/store'

function Card({ id, storeId, name, imageUrl, setLoading }) {
    const getStoreId = async () => {
        setLoading(true)
        try {
            const response = await GetUrlCategory(storeId)
            const { data } = response
            window.open(data, '_blank')
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const checkImage = (url) => {
        const padrao = /\/media\/(.+)/
        const resultado = url.match(padrao)

        if (resultado) {
            return true
        } else {
            return false
        }
    }

    return (
        <Box onClick={getStoreId}>
            <Box
                width="100px"
                height="118px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    background:
                        'transparent linear-gradient(321deg, #f4f4f4f4 0%, #F4F4F4 100%) 0% 0% no-repeat padding-box',
                    boxShadow: '0px 3px 6px #00000029',
                    border: '2px solid #E7E7E7',
                    borderRadius: '20px 0',
                    opacity: 1,
                    '&:hover': {
                        transform: 'scale(1.2)',
                        transition: 'all 0.5s ease-in-out',
                        border: 'none',
                    },
                    padding: '10px',
                    gap: '10px',
                    flexDirection: 'column',
                }}
            >
                <Box
                    component={'img'}
                    src={checkImage(imageUrl) ? imageUrl : sem_imagem}
                    width="60px"
                    height="60px"
                    sx={{
                        objectFit: checkImage(imageUrl) ? 'contain' : 'cover',
                        borderRadius: checkImage(imageUrl) ? 0 : '50%',
                        cursor: 'pointer',
                    }}
                />
                <Box
                    sx={{
                        textAlign: 'center',
                        font: 'normal normal normal 12px/13px Roboto',
                        letterSpacing: '0px',
                        color: '#333333',
                        opacity: 1,
                    }}
                >
                    {name}
                </Box>
            </Box>
        </Box>
    )
}

Card.propTypes = {
    id: PropTypes.string,
    storeId: PropTypes.number,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    enable: PropTypes.bool,
    setLoading: PropTypes.func,
    loading: PropTypes.bool,
}

export default Card
