import { push } from 'connected-react-router'

import * as actionTypes from './actionsTypes'
import { userService } from '../services'
import * as API from '../api/user'
import { useConsumer } from '../../Context/consumerContext'
// import { error } from 'util';

// import { alertActions } from './';

// export const userActions = {
//     login,
//     logout
// };

// #region ==== DADOS BANCARIOS E PIX =====================================

//GET

export const GetConsumerAccountKeyType = () => {
    return (dispatch) =>
        API.GetConsumerAccountKeyType()
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchConsumerDataError(error))
            })

    function success(data) {
        return {
            type: actionTypes.FETCH_CONSUMER_DATA,
            userData: data,
        }
    }
}

//GET
export const GetBanks = (_bankQuery = '') => {
    return (dispatch) =>
        API.GetBanks(_bankQuery)
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchConsumerDataError(error))
            })

    function success(data) {
        return {
            type: actionTypes.FETCH_CONSUMER_DATA,
            userData: data,
        }
    }
}

//PUT
export const UpdateConsumerAccountInfo = () => {
    return (dispatch) =>
        API.UpdateConsumerAccountInfo()
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchConsumerDataError(error))
            })

    function success(data) {
        return {
            type: actionTypes.PUT_CONSUMER_DATA,
            userUpdate: data,
        }
    }
}

// #endregion ==== DADOS BANCARIOS E PIX =====================================
export const login = (username, password) => {
    return (dispatch) => {
        dispatch(
            request({
                username,
            }),
        )
        userService.login(username, password).then(
            (user) => {
                dispatch(success(user))
                dispatch(GetCurrentConsumer())
                dispatch(push('/home'))
            },
            (error) => {
                dispatch(failure(error))
                // dispatch(alertActions.error(error));
            },
        )
    }

    function request(user) {
        return {
            type: actionTypes.LOGIN_REQUEST,
            user,
        }
    }

    function success(user) {
        return {
            type: actionTypes.LOGIN_SUCCESS,
            user,
        }
    }

    function failure(error) {
        return {
            type: actionTypes.LOGIN_FAILURE,
            error,
        }
    }
}

export const logout = () => {
    userService.logout()
    return (dispatch) => {
        dispatch(logout())
        dispatch(reset())
    }
    //
    function logout() {
        localStorage.clear()
        return {
            type: actionTypes.LOGOUT,
        }
    }

    function reset() {
        return {
            type: actionTypes.FETCH_CONSUMER_DATA,
            userData: [],
        }
    }
}

export const changePassword = (id, obj) => {
    return (dispatch) => {
        userService.changePassword(id, obj).then(
            (result) => {
                dispatch(success(result))
            },
            (error) => {
                dispatch(failure(error))
            },
        )
    }

    function success(data) {
        return {
            type: actionTypes.CHANGE_PASSWORD,
            changePassword: data,
        }
    }

    function failure(error) {
        return {
            type: actionTypes.CHANGE_PASSWORD_FAILURE,
            error,
        }
    }
}

export const fetchConsumerDataError = (error) => {
    return {
        type: actionTypes.FETCH_CONSUMER_FAILED,
        userDataFailed: error,
    }
}

export const resetPassword = (data) => {
    return {
        type: actionTypes.RESET_PASSWORD,
        resetPassword: data,
    }
}

export const resetPasswordFailed = (error) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAILED,
        resetPasswordFailed: error,
    }
}

// GET
export const GetCurrentConsumer = () => {
    return (dispatch) =>
        API.GetCurrentConsumer()
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                // dispatch(fetchConsumerDataError(error))
            })

    function success(data) {
        return {
            type: actionTypes.FETCH_CONSUMER_DATA,
            userData: data,
        }
    }
}

export const GetConsumerByToken = (token) => {
    return (dispatch) =>
        API.GetConsumerByToken(token)
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(failed(error))
            })

    function success(data) {
        return {
            type: actionTypes.GET_CONSUMER_BY_TOKEN,
            consumerByToken: data,
        }
    }

    function failed(error) {
        return {
            type: actionTypes.GET_CONSUMER_BY_TOKEN_FAILED,
            consumerByTokenFailed: error,
        }
    }
}

export const ValidateDocument = (doc, maskData) => {
    return (dispatch) =>
        API.ValidateDocument(doc, maskData)
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(failed(error))
            })

    function success(data) {
        return {
            type: actionTypes.VERIFY_DOCUMENT,
            validDocument: data,
        }
    }

    function failed(error) {
        return {
            type: actionTypes.VERIFY_DOCUMENT,
            validDocument: false,
        }
    }
}

// PUT
export const UpdateConsumer = (id, obj) => {
    return (dispatch) =>
        API.UpdateUser(id, obj)
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchConsumerDataError(error))
            })

    function success(data) {
        return {
            type: actionTypes.PUT_CONSUMER_DATA,
            userUpdate: data,
        }
    }
}

//POST
export const PostConsumerRegulation = (data) => {
    return (dispatch) =>
        API.PostConsumerRegulation(data)
            .then((response) => {
                dispatch(postConsumerRegulationData(response))
                return response
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchConsumerDataError(error))
            })

    function postConsumerRegulationData(data) {
        return {
            type: actionTypes.POST_CONSUMER_REGULATION_DATA,
            consumerRegulationPost: data,
        }
    }
}

export const RegisterConsumer = (data) => {
    return (dispatch) =>
        API.PostUser(data)
            .then((response) => {
                dispatch(success(response))
                dispatch(fetchConsumerDataError(false))
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchConsumerDataError(error))
            })

    function success(data) {
        return {
            type: actionTypes.POST_CONSUMER_DATA,
            userPost: data,
        }
    }
}

export const GetResetPasswordToken = (login) => {
    return (dispatch) =>
        API.SendResetPasswordRequest(login)
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(resetPasswordFailed(error))
            })

    function success(data) {
        return {
            type: actionTypes.REQUEST_PASSWORD_RESET,
            requestPasswordReset: data,
        }
    }
}

export const PostResetPassword = (obj) => {
    return (dispatch) =>
        API.ResetPassword(obj)
            .then((response) => {
                dispatch(resetPassword(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(resetPasswordFailed(error))
            })
}

export const ValidateEmailToken = (token) => {
    return (dispatch) =>
        API.ConfirmEmailRegistration(token)
            .then((response) => {
                dispatch(success(response))
                return response
            })
            .catch((error) => {
                console.error(error)
                dispatch(failed(error))
            })

    function success(data) {
        return {
            type: actionTypes.PUT_EMAIL_TOKEN_VALIDATION,
            emailToken: data,
        }
    }

    function failed(error) {
        return {
            type: actionTypes.EMAIL_TOKEN_VALIDATION_FAILED,
            emailTokenFailed: error,
        }
    }
}

export const SendSmsCode = (cpfcnpj) => {
    return (dispatch) =>
        API.SendSmsActivationCode(cpfcnpj)
            .then((response) => {
                dispatch(success(response))
                return response
            })
            .catch((error) => {
                console.error(error)
                dispatch(failed(error))
            })

    function success(data) {
        return {
            type: actionTypes.FETCH_SMS_ACTIVATION_CODE,
            smsRequest: data,
        }
    }

    function failed(error) {
        return {
            type: actionTypes.FETCH_SMS_ACTIVATION_CODE_FAILED,
            smsRequestFailed: error,
        }
    }
}

export const ValidateSmsCode = (cpfcnpj, code, handleSetState) => {
    handleSetState('isLoading', true)
    console.log('ValidateSmsCode')

    return (dispatch) =>
        API.ConfirmRegistration(cpfcnpj, code)

            .then((response) => {
                dispatch(success(response))
                console.log(response)
                return response
            })
            .catch((error) => {
                console.error(error)
                dispatch(failed(error))
            })
            .finally(() => {
                handleSetState('isLoading', false)
            })

    function success(data) {
        return {
            type: actionTypes.PUT_SMS_ACTIVATION_CODE,
            putSms: data,
        }
    }

    function failed(error) {
        return {
            type: actionTypes.SMS_ACTIVATION_CODE_FAILED,
            putSmsFailed: error,
        }
    }
}

export const ClearSmsReducer = () => {
    return (dispatch) => dispatch(success(''))

    function success(data) {
        return {
            type: actionTypes.PUT_SMS_ACTIVATION_CODE,
            putSms: data,
        }
    }
}

// GET
export const GetSentGiftCard = (start, end, destiny, page, pageSize) => {
    return (dispatch) =>
        API.GetSentGiftCard(start, end, destiny, page, pageSize)
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(failure(error))
            })

    function success(data) {
        return {
            type: actionTypes.FETCH_GIFT_CARD,
            giftCardData: data,
        }
    }

    function failure(error) {
        return {
            type: actionTypes.GIFT_CARD_FAILURE,
            giftCardError: error,
        }
    }
}

// GET
export const GetReceivedGiftCard = (
    start,
    end,
    originConsumer,
    entryType,
    page,
    pageSize,
) => {
    return (dispatch) =>
        API.GetReceivedGiftCard(
            start,
            end,
            originConsumer,
            entryType,
            page,
            pageSize,
        )
            .then((response) => {
                dispatch(success(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(failure(error))
            })

    function success(data) {
        return {
            type: actionTypes.FETCH_RECEIVED_CARD,
            receivedCardData: data,
        }
    }

    function failure(error) {
        return {
            type: actionTypes.GIFT_CARD_FAILURE,
            giftCardError: error,
        }
    }
}
