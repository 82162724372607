import React from "react";
import { withTranslation } from 'react-i18next';
import propTypes from "prop-types";
import compose from "utils/compose";

import img_informacao from 'assets/img/ico_informacao.svg'

class NoResults extends React.Component {
    render() {
        const {
            t,
        } = this.props;

        return (
            <div style={{ textAlign: "center", marginTop: '60px' }}>
                <img src={img_informacao} alt="No information" width="50px" style={{ margin: '15px' }} />
                <div style={{ fontSize: '15px', color: '#B4B4B4', fontWeight: '400', marginBottom: '30px' }}>
                    {t("NO_REGISTERED_NOTIFICATIONS")}
                </div>
            </div >
        );
    }
}

NoResults.propTypes = {
    value: propTypes.string.isRequired,
}

export default compose(
    withTranslation())(NoResults);