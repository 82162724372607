import axios from 'axios'
import _ from 'lodash'
import { envs } from './windowServerData'
import Cookies from 'js-cookie'

export const colorLuminance = (hex, lum) => {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '')

    if (hex.length < 6)
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    lum = lum || 0
    // convert to decimal and change luminosity
    let rgb = '#',
        c,
        i
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16)
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
        rgb += ('00' + c).substr(c.length)
    }
    return rgb
}

const themeColor = {
    set(_val) {
        const theme = JSON.stringify(_val)
        Cookies.set('themeColor', theme)
        localStorage.setItem('themeColor', theme)
    },
    get() {
        try {
            const cookieTheme = Cookies.get('themeColor')
            const storageTheme = localStorage.getItem('themeColor')

            const re = JSON.parse(cookieTheme) || JSON.parse(storageTheme)
            return re
        } catch (err) {
            return [{}]
        }
    },
}

export const GetThemeFromAPI = async () => {
    try {
        const cookieTheme = Cookies.get('themeColor')

        if (!cookieTheme) {
            const response = await axios.get(
                `${envs.REACT_APP_GERMINI_API_URL}/Themes/Consumer`,
            )

            Cookies.set('themeColor', JSON.stringify(response?.data?.data))
            return response
        }

        return {
            data: JSON.parse(cookieTheme),
        }
    } catch (err) {
        return err
    }
}

export const GetThemeFromAPILogin = async () => {
    const apiUrl = `${envs.REACT_APP_GERMINI_API_URL}/Themes/Consumer`

    const res = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    })

    const cookieTheme = await Cookies.get('themeColor')
    const storageTheme = await localStorage.getItem('themeColor')

    if (
        Object.is(cookieTheme, storageTheme) &&
        Object.is(JSON.parse(cookieTheme), JSON.parse(storageTheme))
    ) {
        return JSON.parse(cookieTheme) || JSON.parse(storageTheme) || null
    } else {
        const themeJson =
            envs.REACT_APP_CVALE_IDENTITY === 'true' ? CVALE : await res.json()
        if (_.isEmpty(themeJson)) return {}
        SetThemeIntoStorage(themeJson)
        return themeJson
    }
}

export const SetThemeIntoStorage = (_theme) => {
    !_.isEmpty(_theme) && themeColor.set(_theme)
}

export const CVALE = {
    images: {
        loginPage: {
            logo: {
                filename: 'logo.png',
                url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/f9485d54-7717-4f2c-bfbd-d94dfb0bf212.png',
            },
            background: {
                filename: 'background.png',
                url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/baa91f86-7056-4030-8881-770acf1002f3.jpg',
            },
        },
        internalPages: {
            menuLogo: {
                filename: 'logo_header.png',
                url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/60e7df84-61d1-44bf-8d60-48e4a33d1aea.png',
            },
            menuBackground: {
                filename: 'background_responsivo.png',
                url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/98692fea-5abf-4a3c-b62f-5b9d7897fffe.jpg',
            },
        },
        favicon: {
            filename: 'favicon.png',
            url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/945bc099-46be-44c8-85a3-81e435838447.png',
        },
    },
    navigation: {
        buttons: {
            textColor: '#ffffff',
            backgroundColor: '#21409A',
            elementsColor: '#21409A',
        },
        menu: {
            backgroundColor: '#DCE2F5',
            highlightColor: '#21409A',
            activeColor: '#21409A',
        },
    },
    visual: { icons: { color: '#21409A' } },
    titles: {
        main: { textColor: '#21409A' },
        secondary: { textColor: '#21409A' },
        highlight: { textColor: '#21409A' },
    },
    footer: {
        backgroundColor: '#21409A',
        textColor: '#B1BCDB',
    },
}

export const GetThemeColorFromStorage = () =>
    `${envs.REACT_APP_CVALE_IDENTITY}` === 'true' ? CVALE : themeColor.get()
