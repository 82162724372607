import * as actionTypes from './actionsTypes'
import * as API from '../api/generalRules'

export const fetchData = (data) => {
    return {
        type: actionTypes.FETCH_GENERAL_RULES_DATA,
        generalRulesData: data,
    }
}

export const fetchProgramDescriptionData = (data) => {
    return {
        type: actionTypes.FETCH_PROGRAM_DESCRIPTION_DATA,
        programDescriptionData: data,
    }
}

export const fetchDigitalWalletRulesData = (data) => {
    return {
        type: actionTypes.FETCH_DIGITAL_WALLET_RULES_DATA,
        digitalWalletRulesData: data,
    }
}

// FAIL FETCH -------------------------------------
export const fetchDataError = (error) => {
    return {
        type: actionTypes.FETCH_GENERAL_RULES_FAILED,
        generalRulesDataFailed: error,
    }
}

export const fetchProgramDescriptionError = (error) => {
    return {
        type: actionTypes.FETCH_PROGRAM_DESCRIPTION_FAILED,
        programDescriptionDataFailed: error,
    }
}

export const fetchDigitalWalletRulesError = (error) => {
    return {
        type: actionTypes.FETCH_DIGITAL_WALLET_RULES_FAILED,
        digitalWalletRulesDataFailed: error,
    }
}

//----------------------------------------------------

export const GetData = () => {
    return (dispatch) =>
        API.GetGeneralRulesData()
            .then((response) => {
                dispatch(fetchData(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchDataError(error))
            })
}

export const GetCurrencies = () => {
    return (dispatch) =>
        API.GetCurrencies()
            .then((response) => {
                dispatch(fetchData(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchDataError(error))
            })
}

export const GetProgramDescription = () => {
    return (dispatch) =>
        API.GetProgramDescription()
            .then((response) => {
                dispatch(fetchProgramDescriptionData(response))
            })
            .catch((error) => {
                console.error(error)
                dispatch(fetchProgramDescriptionError(error))
            })
}

export const GetDigitalWalletRulesData = () => {
    return async (dispatch) => {
        try {
            const res = await API.GetDigitalWalletRules()
            dispatch(fetchDigitalWalletRulesData(res))
        } catch (error) {
            console.error(error)
            dispatch(fetchDigitalWalletRulesError(error))
        }
    }
}
