import styled from 'styled-components/macro'
import { TextField, Grid, Tooltip, InputLabel } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const theme = GetThemeColorFromStorage()

export const Container = styled.div`
    .fqQohv .MuiTableHead-root tr th:nth-child(2) {
        width: 25% !important;
    }
    .fqQohv .MuiTableBody-root tr td:nth-child(2) {
        width: 25% !important;
    }

    .fqQohv .MuiTableHead-root tr th:nth-child(3) {
        width: 5% !important;
    }
    .fqQohv .MuiTableBody-root tr td:nth-child(3) {
        width: 5% !important;
    }

    .fqQohv .MuiTableHead-root tr th:nth-child(4) {
        width: 1% !important;
    }
    .fqQohv .MuiTableBody-root tr td:nth-child(4) {
        width: 1% !important;
    }

    .fqQohv .MuiTableHead-root tr th:nth-child(5) {
        width: 1% !important;
    }
    .fqQohv .MuiTableBody-root tr td:nth-child(5) {
        width: 1% !important;
    }

    .fqQohv .MuiTableHead-root tr th:nth-child(6) {
        width: 1% !important;
    }
    .fqQohv .MuiTableBody-root tr td:nth-child(6) {
        width: 1% !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 3px;
    }

    .MuiAutocomplete-popperDisablePortal {
        li:hover {
            color: #ffffff !important;
            background-color: ${theme &&
            theme.navigation &&
            theme.navigation.buttons.elementsColor};
        }
    }
`

export const BoxContainer = styled.div`
    align-items: self-start;
    display: flex;
    flex-direction: column;
`

export const ExpiredBox = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
`

export const ExpiredSpan = styled.div`
    font-style: italic;
    font-size: 12px;
    text-align: left;
    width: 100%;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`

export const ContaineMoreFilter = styled.div`
    width: 100%;
    margin: 20px 0px;
    justify-content: center;
    .jAGcJu.button-grid {
        padding-top: 15px !important;
    }
    @media only screen and (min-width: 375px) {
        display: flex;
        padding-top: 0 !important;
        .jAGcJu.button-grid {
            padding-top: 0 !important;
        }
    }
`
export const BtnContain = styled.div`
    display: flex;
    /* padding-left: 15px; */
    @media only screen and (min-width: 375px) {
        justify-content: flex-end;
        padding-left: 0;
    }
`

export const GridBox = styled(Grid)`
    width: 100%;
    margin-top: 30px;

    .detail-button {
        margin: 1px;
        padding: 0px;
        height: 45px;
        width: 35px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
    }

    tbody {
        border-left: none;
        border-right: none;
    }

    .MuiPaper-elevation2 {
        box-shadow: unset;
    }

    .MuiToolbar-regular {
        min-height: 0px;
    }

    .MuiTableHead-root {
        tr {
            th {
                background-color: #e9e9e9;
                color: ${GetThemeColorFromStorage()?.titles?.secondary
                    ?.textColor};
                font-size: 16px;
                font-weight: bold;
                height: 40px;

                padding: 0px 16px;

                :first-child {
                    border-top-left-radius: 6px;
                    width: 1% !important;
                    justify-content: flex-start;
                    text-align: left !important;
                }

                :last-child {
                    border-top-right-radius: 6px;
                    width: 2% !important;
                    justify-content: flex-end;
                    text-align: right !important;
                    span {
                        svg {
                            display: none;
                        }
                    }
                }
                :nth-child(2) {
                    text-align: left !important;
                    width: 10% !important;
                }
                :nth-child(3) {
                    text-align: left !important;
                    width: 45% !important;
                }
                :nth-child(4) {
                    text-align: left !important;
                    width: 15% !important;
                }
                :nth-child(5) {
                    text-align: left !important;
                    width: 20% !important;
                }
            }
        }
    }

    .MuiTableBody-root {
        .MuiTableCell-alignLeft {
            text-align: left;
            :last-child {
                text-align: right;
            }
        }
        tr {
            color: #717171;
            font-size: 15px;
            height: 45px;

            p {
                font-size: 13px;
                font-style: italic;
                margin: 0px;
            }

            td {
                padding: 0px 15px !important;
                :first-child {
                    width: 1% !important;
                    justify-content: flex-start;
                    text-align: left !important;
                }
                :last-child {
                    padding: 0px !important;
                    justify-content: flex-end;
                    text-align: right !important;
                }
                :nth-child(2) {
                    text-align: left !important;
                    width: 10% !important;
                }
                :nth-child(3) {
                    text-align: left !important;
                    width: 45% !important;
                }
                :nth-child(4) {
                    text-align: left !important;
                    width: 15% !important;
                }
                :nth-child(5) {
                    text-align: left !important;
                    width: 20% !important;
                }
                :last-child {
                    border-top-right-radius: 6px;
                    width: 2% !important;
                    justify-content: flex-end;
                    text-align: right !important;
                }
            }
        }
    }
    .GridItem-grid-351 {
        padding: 0 !important;
    }
`

export const GridContent = styled(GridItem)`
    padding: 0px !important;
    .MuiPaper-elevation2 {
        z-index: 0;
    }
`

export const IndicationBox = styled(GridContainer)`
    .button-box {
        display: flex;
        align-items: center;
        padding-left: 0px;
        padding-right: 15px;
    }

    @media screen and (max-width: 720px) {
        position: relative;
        left: -15px;
    }
`

export const IndicationItem = styled(Grid)`
    padding: 0px 15px 0px;

    &.send-button {
        justify-content: flex-end;
        display: flex;
        padding-right: 0px;
        padding-bottom: 30px;
    }

    @media screen and (max-width: 720px) {
        .send-button {
            position: relative;
            right: -15px;
        }
    }
`

export const MyTooltip = styled(Tooltip)``

export const FilterBox = styled.form`
    width: 100%;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 20px 15px;
    .MuiFormControl-marginDense {
        margin: 0 !important;
        width: 100%;
    }
    .css-155tste-control {
        margin: 0 !important;
    }
    .css-1lwizo5-control {
        margin: 0 !important;
    }
`

export const EventInput = styled.div`
    margin-bottom: 15px;
    width: 100%;

    @media only screen and (min-width: 550px) {
        padding-right: 12.5px;
        width: 50%;
    }
    @media only screen and (min-width: 700px) {
        padding-right: 12.5px;
        width: 33.33%;
    }
`
export const OriginInput = styled.div`
    margin-bottom: 15px;
    width: 100%;

    @media only screen and (min-width: 550px) {
        padding-left: 12.5px;
        width: 50%;
    }
    @media only screen and (min-width: 700px) {
        padding: 0 12.5px;
        width: 33.33%;
    }
`

export const OperationInput = styled.div`
    margin-bottom: 15px;
    width: 100%;
    @media only screen and (min-width: 550px) {
        margin-bottom: 15px;
        padding-right: 12.5px;
        width: 50%;
    }
    @media only screen and (min-width: 700px) {
        padding-left: 12.5px;
        padding-right: 0;
        width: 33.33%;
    }
`

export const PeriodTimeInput = styled.div`
    margin-bottom: 15px;
    width: 100%;
    @media only screen and (min-width: 550px) {
        margin-bottom: 15px;
        //padding-left: 12.5px;
        width: 50%;
    }
    @media only screen and (min-width: 700px) {
        padding-right: 12.5px;
        width: 33.33%;
    }
`
export const DateBox = styled.div`
    display: flex;
    pointer-events: ${(props) => (props.enableDates === true ? 'all' : 'none')};
    width: 100%;
`

export const BtnBox = styled.div`
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    @media only screen and (min-width: 500px) {
        flex-direction: row;
    }
`
export const BtnMoreFilter = styled.div`
    align-items: center;
    color: ${(props) => props.colorIdentidadeVisual};
    display: flex;
    cursor: pointer;

    @media only screen and (min-width: 500px) {
        width: 48%;
    }
`

export const BtnSearch = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 5px;

    @media only screen and (min-width: 500px) {
        /* justify-content: flex-end; */
        margin-top: 0;
    }
`

export const LabelInput = styled.label`
    font-weight: 400;
    position: absolute;
    top: -7px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    font-size: 1rem;
    z-index: 3;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1;
    letter-spacing: 0.00938em;
    transform: translateX(-10px) scale(0.75);
    pointer-events: none;
    padding: 0px 9px 0px 9px;
    background-color: #fff;
`
