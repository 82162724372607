import styled from 'styled-components'

export const CardProductContainer = styled.div`
    width: 194px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 22px;

    .btn-round {
        width: 140px;
        height: 29px;
        font: normal normal bold 13px/18px Roboto;
    }
`

export const CardProductTitle = styled.div`
    text-align: center;
    font: normal normal normal 12px/18px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    min-height: 36px;
`

export const CardProductIcon = styled.div`
    font-size: 20px;
    color: #fbbf46;
    opacity: 1;
`
