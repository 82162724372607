import styled from 'styled-components'

import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export const Container = styled.div`
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }
`
