import React, { useEffect, useState } from 'react'

import successIcon from 'assets/img/success_icon.png'
// imgs
import { withTranslation } from 'react-i18next'
import style from './confirm.module.scss'
import { Grid } from '@material-ui/core'
import {
    SuccessBox,
    BoxSuccess,
    BoxImage,
    Page,
    BoxInfoFlex,
    getLocalStorage,
} from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { GetCurrencies } from '../../redux/actions'
import { history } from '../../utils'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import Button from 'components/CustomButtons/Button.jsx'
import Footer from '../../components/Footer/Footer'
import { envs } from '../../redux/api/windowServerData'
const germiniLogoInterna =
    GetThemeColorFromStorage()?.images?.internalPages?.menuLogo?.url

function SuccessScreen({ t }) {
    const [themeColor, setThemeColor] = useState()
    const auth = useSelector((state) => state.authentication)
    const [programName, setProgramName] = useState('')
    const generalRulesData = useSelector(
        (state) => state.generalRules.generalRulesData,
    )

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        dispatch(GetCurrencies())
        if (auth.loggedIn) history.goBack()
        setLoading(false)
    }, [auth.loggedIn, dispatch])

    useEffect(() => {
        setThemeColor(GetThemeColorFromStorage())
        getLocalStorage(setProgramName)
    }, [])

    return (
        !loading && (
            <Page>
                <BoxSuccess justify="center">
                    <BoxImage
                        className={style.parent}
                        themeColor={
                            themeColor?.images?.internalPages?.menuBackground
                                ?.url
                        }
                    >
                        <div className={style.headerContainer}>
                            <div
                                className={style.logoBox}
                                onClick={() => history.goBack()}
                            >
                                <img
                                    className={style.logo}
                                    src={germiniLogoInterna}
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        <BoxInfoFlex>
                            <div className={style.boxInfo}>
                                <div className={style.boxInfoText}>
                                    <b>CADASTRE-SE</b> e tenha muitas{' '}
                                    <b>VANTAGENS</b>
                                    <br />
                                    no <b>MUNDO</b> do agronegócio.
                                </div>
                            </div>
                        </BoxInfoFlex>
                    </BoxImage>
                </BoxSuccess>
                <SuccessBox container justify="center">
                    <Grid item xs={12} container justify="center">
                        <img src={successIcon} alt="" />
                    </Grid>
                    <Grid item xs={12} container justify="center">
                        <b>{t('activationCode - SUCCESS')}</b>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        xl={4}
                        container
                        justify="center"
                    >
                        <p style={{ fontWeight: '700' }}>
                            {`
                            Com a mesma senha e login, você acessa a sua conta e também a Loja Virtual ${programName}
                        `}
                        </p>
                        <p>
                            {`Comece a acumular ${
                                generalRulesData.programCurrencyName
                            } (${
                                generalRulesData?.programCurrencySymbol || ''
                            }), a sua nova moeda virtual no mundo de vantagens do Programa!`}
                        </p>
                        <p>
                            {`Suas compras poderão acumular pontos "${
                                generalRulesData?.programCurrencyName || ''
                            }", e com eles trocarem por diversos produtos.`}
                        </p>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                        <Button
                            color="greenButtonColor"
                            onClick={() => history.push('/auth/login')}
                            size="sm"
                            className="button-margin"
                        >
                            <i
                                style={{
                                    fontSize: 16,
                                    padding: '2px 0',
                                    marginRight: 10,
                                }}
                                className="icon-programa"
                            />
                            {t('BUTTON_ACCESS_ACCOUNT')}
                        </Button>
                        <Button
                            color="greenButtonColor"
                            size="sm"
                            onClick={() =>
                                window.open(
                                    // envs.REACT_APP_MAGENTO_URL
                                    envs.REACT_APP_MAGENTO_URL
                                        ? // ? envs.REACT_APP_MAGENTO_URL
                                          envs.REACT_APP_MAGENTO_URL
                                        : '',
                                    '_blank',
                                )
                            }
                            className="button-margin"
                        >
                            <i
                                style={{
                                    fontSize: 16,
                                    padding: '2px 0',
                                    marginRight: 10,
                                }}
                                className="icon-acesso_loja"
                            />
                            {t('BUTTON_ACCESS_STORE')}
                        </Button>
                    </Grid>
                </SuccessBox>
                <Footer />
            </Page>
        )
    )
}

export default withTranslation()(SuccessScreen)
