import React, { memo, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import styles from './styles.module.scss'
import { GetContact } from '../../redux/api/footer'
import { useTranslation } from 'react-i18next'
import { WhatsApp } from '@material-ui/icons'
import { formatCellAndTell } from '../../utils/utils'
import * as S from './styles'
import { envs } from '../../redux/api/windowServerData'
import { GetTenantVersion } from '../../redux/api/About'

const Footer = memo(() => {
	const { t } = useTranslation()
	const [contact, setContat] = useState()
	const [themeColor, setThemeColor] = useState({})
	const [tenant, setTenant] = useState('')


	const getTenantVersion = async () => {
		try {
			const response = await GetTenantVersion()

			if (response?.status === 200 && response?.data) {
				setTenant(response?.data)
			}
		} catch (error) {
			console.log('error', error)
		}
	}

	const GetContactInfo = async () => {
		const res = await GetContact()
		res && setContat(res)
	}

	useEffect(() => {
		setThemeColor(GetThemeColorFromStorage())
		GetContactInfo()
		getTenantVersion()
	}, [])



	return (
		<S.Footer className={styles.container} themeColor={themeColor}>
			<S.BoxFooter>
				<S.Box>
					{tenant !== 'pocketexpress' && (<span>{`Release: ${envs.REACT_APP_GERMINI_BUILD_VERSION}`}</span>)}
				</S.Box>
			</S.BoxFooter>
			{contact && (
				<Grid className={styles.textBox}>
					<div className={styles.iconText}>Contato</div>
					{contact?.contactEmail && (
						<div className={styles.text}>
							E-mail: {contact.contactEmail}
						</div>
					)}
					<div className={styles.text}>
						{contact?.contactPhoneNumber &&
							`${t('FIELD_REGISTER_PHONE')} `}
						{formatCellAndTell(contact.contactPhoneNumber)}
						{contact?.contactPhoneNumber && ' | '}
						{contact?.isContactPhoneNumber2Whatsapp &&
							contact?.contactPhoneNumber2 ? (
							<a
								href={`https://api.whatsapp.com/send?phone=${contact?.contactPhoneNumber2?.replace(
									/\D/g,
									'',
								)}`}
								// eslint-disable-next-line react/jsx-no-target-blank
								target="_blank"
								style={{
									textDecoration: 'none',

								}}
								className={styles.whatsappLink}
							>
								<WhatsApp
									style={{
										fontSize: '20px',
										margin: '0px 5px 3px',
									}}
								/>
								{`${t('FIELD_REGISTER_MOBILE_PHONE')} `}
								{formatCellAndTell(
									contact?.contactPhoneNumber2,
								)}
							</a>
						) : (
							contact?.contactPhoneNumber2 &&
							`${t(
								'FIELD_REGISTER_MOBILE_PHONE',
							)} ${formatCellAndTell(
								contact?.contactPhoneNumber2,
							)}`
						)}
					</div>
					{contact?.startAttendancePeriod && (
						<div className={styles.text}>
							Atendimento: De{' '}
							{t(contact?.startAttendanceDay?.value)} a{' '}
							{t(contact?.endAttendanceDay?.value)} das{' '}
							{contact?.startAttendancePeriod?.slice(0, 5)} às{' '}
							{contact?.endAttendancePeriod?.slice(0, 5)} (exceto{' '}
							{t(contact?.attendancExceptions?.value)})
						</div>
					)}
				</Grid>
			)}
		</S.Footer>
	)
})

export default Footer
