import * as Yup from 'yup'

export const validateIndication = Yup.object().shape({
    cpf: Yup.string().required('Campo de preenchimento obrigatório'),
    giftValue: Yup.string().required('Campo de preenchimento obrigatório'),
    balance: Yup.string(),
    balanceUsed: Yup.string(),
})
export const defaultValues = {
	cpf: '',
	giftValue: '',
	balance: '',
	balanceUsed: '',
}

export const currencyFormatter = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL',
})
