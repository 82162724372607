import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import {
    germiniDefaultColors,
    grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/CustomButtons/Button.jsx'

export const Container = styled.div`
    overflow-x: hidden;
    width: 100%;
`

export const EmptyTable = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding-top: 1em;
	color: #b4b4b4;

	i {
		padding-top: 1em;
		font-size: 3em;
		cursor: pointer;
		margin: auto 18px;
		color: #b4b4b4;
	}

	.icon-informacao:before {
		font-size: 1.5em;
	}

	span {
		font-weight: 900;
		margin: 25px 0 12px;
	}
`

export const Status = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 1rem;
    background-color: ${(props) => (props.active ? '#8BC53F' : '#E81F26')};
`

export const ButtonItem = styled(Grid)`
    text-align: right;
    margin-top: 0px;
    margin-bottom: 15px;
    padding: 0px !important;
`

export const SearchBox = styled.div`
    width: 100% !important;
    background: #f6f6f6;
    border-radius: 8px;
`

export const InnerContainer = styled(Grid)`
    .gQwXps .MuiTableHead-root tr th:last-child > div {
        display: flex;
        justify-content: flex-end;
        padding-right: 43px;
    }
    .header {
        display: flex;
        width: 100%;
        background-color: #fafafa;
        padding: 20px;
        flex-wrap: wrap;
        align-items: center;
        > div {
            margin-right: 20px;
        }
    }

    .filter-box {
        display: flex;
        max-height: 310px;

        .box-height {
            min-height: 180px;
        }

        background-color: #fafafa;
        margin: 0;
        padding: 16px;
        padding-left: 24px;
        button {
            max-height: 38px;
        }
        .search-button {
            margin-right: 20px;
            color: #fff;
            &:hover {
                background-color: ${germiniDefaultColors[0]};
                opacity: 0.8;
            }
        }
    }

    @media screen and (max-width: 960px) {
        max-height: initial;
        .box-height {
            min-height: initial;
        }
        .grid-input {
            margin-top: 16px;
        }
    }

    @media screen and (max-width: 1280px) {
        .cards-box {
            justify-content: center !important;
        }
    }
`

export const CustomFormLabel = styled.div`
    cursor: default !important;
    display: inline-flex !important;
    font-size: 15px !important;
    line-height: 1.428571429 !important;
    font-weight: 600 !important;
    padding-top: 20px !important;
    color: #333333 !important;
    margin-right: 0 !important;
    margin-bottom: 15px;
`

export const GridContent = styled(Grid)`
    padding: 0px !important;
`

export const GridButton = styled(Button)`
    height: 45px !important;
    width: 45px !important;
    border-radius: 0px !important;
    margin: 0px 0px 0px 1px !important;
`

export const ListGrid = styled(Grid)`
    background-color: #fff;
    height: 100%;
    //padding: 2px 2px 0;
    margin: 30px 0;
    width: 100%;

    .section {
        font-weight: 300;
        color: #5d5d5d;
        margin-top: 10px;
    }
    > div {
        &:first-child {
            padding: 0 !important;
        }
    }

    .MuiToolbar-regular {
        min-height: 0px;
    }

    .grid-container {
        margin: 0 30px;
        padding: 0 0 15px 0;
    }

    .button-container {
        max-height: 36px;
    }

    .filter-box {
        margin: 30px 0;
        background-color: #fafafa;
        padding: 16px;
        padding-left: 24px;
        .search-button {
            margin-right: 20px;
            color: #fff;
            &:hover {
                background-color: ${germiniDefaultColors[0]};
                opacity: 0.8;
            }
        }
    }

    .MuiPaper-elevation2 {
        box-shadow: unset;
    }

    .MuiTableCell-root {
        > div {
            .MuiIconButton-root {
                background: transparent !important;
                box-shadow: none !important;
            }
            button {
                .MuiTouchRipple-root {
                    display: none !important;
                }
                cursor: pointer;
                box-shadow: none !important;
            }
        }
    }

    .MuiTableHead-root {
        tr {
            th {
                background-color: #e9e9e9;
                color: ${germiniDefaultColors[0]};
                font-size: 16px;
                font-weight: bold;

                padding: 0px 16px;
                height: 40px;

                :first-child {
                    border-top-left-radius: 6px;
                    width: 20% !important;
                }

                :nth-child(2) {
                    /* width: 50% !important; */
                    width: 20% !important;

                    justify-content: flex-start;
                    text-align: left !important;
                }

                :nth-child(3) {
                    /* width: 50% !important; */
                    width: 40% !important;
                    justify-content: flex-start;
                    text-align: left !important;
                }

                :last-child {
                    border-top-right-radius: 6px;
                    padding: 0 !important;
                    width: 20% !important;
                    justify-content: center !important;
                    text-align: center !important;
                    > div {
                        margin-right: 0px;
                    }
                    span {
                        svg {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .MuiTableBody-root {
        tr {
            color: #717171;
            background-color: #fff;
            td {
                font-size: 15px !important;
                /* background-color: black; */
                height: 45px !important;
                padding: 0px 15px !important;

                :first-child {
                    width: 20% !important;

                    width: min-content !important;
                }
                > div {
                }

                > div p {
                    margin: 0px;
                    font-size: 13px;
                    font-style: italic;
                }

                :nth-child(2) {
                    /* width: 50% !important; */
                    width: 20% !important;

                    justify-content: flex-start;
                    text-align: left !important;
                }

                :nth-child(3) {
                    /* width: 50% !important; */
                    width: 40% !important;
                    justify-content: flex-start;
                    text-align: left !important;
                }

                :last-child {
                    padding: 0 !important;
                    width: 20% !important;
                    justify-content: flex-end;
                    text-align: right !important;
                    > div {
                    }
                }
            }
        }
    }

    .MuiIconButton-root {
        padding: 0px !important;
    }

    .submit-container {
        margin: 20px 0;
        padding-right: 16px;
        > :first-child {
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 1200px) {
        .create-title {
            width: 100%;
            margin-top: 30px;
        }
    }
`

export const RegisterGrid = styled(Grid)`
    background-color: #fff;
    height: 100%;
    //padding: 2px 2px 0;
    margin: 30px 0;
    width: 100%;

    .section {
        font-weight: 300;
        color: #5d5d5d;
        margin-top: 10px;
    }
    > div {
        &:first-child {
            padding: 0 !important;
        }
    }

    .MuiToolbar-regular {
        min-height: 0px;
    }

    .grid-container {
        margin: 0 30px;
        padding: 0 0 15px 0;
    }

    .button-container {
        max-height: 36px;
    }

    .filter-box {
        margin: 30px 0;
        background-color: #fafafa;
        padding: 16px;
        padding-left: 24px;
        .search-button {
            margin-right: 20px;
            color: #fff;
            &:hover {
                background-color: ${germiniDefaultColors[0]};
                opacity: 0.8;
            }
        }
    }

    .MuiPaper-elevation2 {
        box-shadow: unset;
    }

    .MuiTableCell-root {
        > div {
            .MuiIconButton-root {
                background: transparent !important;
                box-shadow: none !important;
            }
            button {
                .MuiTouchRipple-root {
                    display: none !important;
                }
                cursor: pointer;
                box-shadow: none !important;
            }
        }
    }

    .MuiTableHead-root {
        tr {
            th {
                background-color: #e9e9e9;
                color: ${germiniDefaultColors[0]};
                font-size: 16px;
                font-weight: bold;

                padding: 0px 16px;
                height: 40px;

                :first-child {
                    border-top-left-radius: 6px;
                    width: 20% !important;
                }

                :nth-child(2) {
                    width: 50% !important;
                    justify-content: flex-start;
                    text-align: left !important;
                }

                :last-child {
                    border-top-right-radius: 6px;
                    padding: 0 !important;
                    width: 20% !important;
                    justify-content: center !important;
                    text-align: center !important;
                    > div {
                        margin-right: 0px;
                    }
                    span {
                        svg {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .MuiTableBody-root {
        tr {
            color: #717171;
            background-color: #fff;
            td {
                font-size: 15px !important;
                /* background-color: black; */
                height: 45px !important;
                padding: 0px 15px !important;
                :first-child {
                    width: 20% !important;
                }
                > div {
                }

                > div p {
                    margin: 0px;
                    font-size: 13px;
                    font-style: italic;
                }

                :nth-child(2) {
                    width: 50% !important;
                    justify-content: flex-start;
                    text-align: left !important;
                }

                :last-child {
                    padding: 0 !important;
                    width: 20% !important;
                    justify-content: flex-end;
                    text-align: right !important;
                    > div {
                    }
                }
            }
        }
    }

    .MuiIconButton-root {
        padding: 0px !important;
    }

    .submit-container {
        margin: 20px 0;
        padding-right: 16px;
        > :first-child {
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 1200px) {
        .create-title {
            width: 100%;
            margin-top: 30px;
        }
    }
`
export const ContainerSecundary = styled.div`
    margin-top: 20px;
    width: 100%;
`

export const GridBox = styled.div`
    margin-top: 25px;
    width: 100%;
`

export const CustomPaginationContain = styled.div`
    text-align: center;
    margin-top: 20px;
    width: 100%;
`
