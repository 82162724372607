import styled from 'styled-components'

export const TotalSpan = styled.div`
    color: ${(props) => props?.themeColor?.titles};
`

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`

export const DivRecordCount = styled.div`
    margin-top: 15px;
    width: 100%;
    @media only screen and (min-width: 550px) {
        margin-top: 0;
        position: absolute;
    }
`
export const DivPagination = styled.div`
    display: flex;
    justify-content: center;
`
