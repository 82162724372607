import styled from 'styled-components/macro'

import { Grid } from '@material-ui/core'

export const RowContainer = styled.div`
    background-color: ${(props) =>
        props.valueColor % 2 === 0 ? '#ececec' : '#f4f4f4'};
    border-radius: 8px;
    display: flex;
    margin-bottom: 10px;
`

export const TableGrid = styled.div`
    border-radius: 5px 0 0 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    width: 100%;
    .buttonCampaign {
        height: 100%;
        // background-color: #21409a;
        text-align: center;
        display: flex;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        float: right;
        cursor: pointer;
        width: 100%;
        &:hover {
            background-color: #0f2872;
        }
    }
    .label {
        //padding: 0px 18px 0 18px;
        font-weight: 600;
    }
    .textValue {
        font-size: 0.8rem !important;
    }
    .subTextValue {
        font-size: 10px;
        font-style: italic;
        margin-top: -5px;
        margin-left: -2px;
    }
`

export const MainGrid = styled(Grid)`
    .buttonGrid {
        background-color: #ececec;
    }
    .buttonCampaign {
        height: 100%;
        background-color: #21409a;
        text-align: center;
        display: flex;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        float: right;
        cursor: pointer;
        width: 65%;
        &:hover {
            background-color: #0f2872;
        }
    }
`

export const ContainerAll = styled.div`
    flex-direction: column;
    display: flex;
    width: 100%;
`
export const ContainerPrimary = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`
export const ContainerSecundary = styled.div`
    width: 100%;
`

export const BtnContainer = styled.div`
    display: flex;
    box-sizing: border-box !important;
    width: 50px !important;
`

export const BtnArrow = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    height: 75px;
    justify-content: center;
    padding-top: 10px;
    width: 100%;
`
export const ContentLabel = styled.div`
    width: 100%;
    @media only screen and (min-width: 500px) {
        width: 49%;
    }
    @media only screen and (min-width: 1000px) {
        width: 25%;
    }
`
