import React, { Component } from 'react'
import Pagination from 'material-ui-flat-pagination'
import styled from 'styled-components/macro'

import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
    Toolbar,
} from '@material-ui/core'

import customPaginationStyle from 'assets/jss/material-dashboard-pro-react/views/customPaginationStyle'

import compose from 'utils/compose'

import { primaryColor } from 'assets/jss/material-dashboard-pro-react.jsx'
import {
    GetThemeColorFromStorage,
    colorLuminance,
} from '../../redux/api/themeColor'
import { useState } from 'react'

import * as S from './styles'

const pagTheme = createMuiTheme({
    overrides: {
        MuiFlatPagination: {
            root: {
                background: 'white',
            },
            colorInheritOther: {
                color: '#333333 !important',
                fontWeight: '400',
                backgroundColor: 'white',
                '&:hover': {
                    color: primaryColor[0],
                    fontWeight: '800',
                },
            },
        },
        MuiFlatPageButton: {
            root: {
                border:
                    '1px solid ' +
                    GetThemeColorFromStorage()?.navigation?.buttons
                        ?.backgroundColor +
                    ' !important',
                borderRadius: '3px',
                padding: '0 12px 0 12px !important',
                maxWidth: '42px !important',
                height: '33px',
                margin: '3px',
                backgroundColor:
                    GetThemeColorFromStorage()?.navigation?.buttons
                        ?.backgroundColor,
                color: '#fff !important',
                lineHeight: '1',
                '&:hover': {
                    border:
                        '1px solid ' +
                        colorLuminance(
                            GetThemeColorFromStorage()?.navigation?.buttons
                                ?.backgroundColor,
                            -0.2,
                        ) +
                        ' !important',
                    backgroundColor:
                        colorLuminance(
                            GetThemeColorFromStorage()?.navigation?.buttons
                                ?.backgroundColor,
                            -0.2,
                        ) + ' !important',
                    color:
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.textColor + '!important',
                },
            },
            text: {
                color:
                    GetThemeColorFromStorage	()?.navigation?.buttons?.textColor +
                    '!important',
            },
        },
    },
    palette: {
        primary: {
            main: '#000',
        },
    },
    typography: {
        useNextVariants: true,
    },
})

class CustomPagination extends Component {
    render() {
        const { pageSize, recordCount, classes, offset, clickEvent } =
            this.props
        return (
            <Toolbar className={classes.toolbar}>
                <MuiThemeProvider theme={pagTheme}>
                    <S.Container>
                        <S.DivPagination>
                            <Pagination
                                otherPageColor="inherit"
                                currentPageColor="default"
                                limit={pageSize}
                                offset={offset}
                                total={recordCount}
                                onClick={clickEvent}
                                size="small"
                                nextPageLabel={<ChevronRight />}
                                previousPageLabel={<ChevronLeft />}
                            />
                        </S.DivPagination>
                        <S.DivRecordCount>
                            <S.TotalSpan themeColor={GetThemeColorFromStorage()} className={classes.totalCount}>
                                Total: <b>{recordCount}</b>
                            </S.TotalSpan>
                        </S.DivRecordCount>
                    </S.Container>
                </MuiThemeProvider>
            </Toolbar>
        )
    }
}
export default compose(withStyles(customPaginationStyle))(CustomPagination)
