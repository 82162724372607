import React from 'react'
import {
    Button,
    GridContainer,
    GridItem,
    MuiBox,
    MuiTypography,
    SucessIcon,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export function MessageSuccess({
    handleHomeClick,
    isSmallScreen,
    setMessageSuccess,
}) {
    return (
        <GridContainer
            mt={10}
            pl={isSmallScreen ? 2 : 15}
            pr={isSmallScreen ? 2 : 15}
            spacing={isSmallScreen ? 0 : 2}
        >
            <GridItem xs={12} sx={{ textAlign: 'center!important' }}>
                <SucessIcon size={60} color="#68B105" />
            </GridItem>
            <GridItem xs={12} sx={{ textAlign: 'center!important' }} mb={3}>
                <MuiTypography color="#333333" fontWeight={600}>
                    Transferência realizada com sucesso
                </MuiTypography>
            </GridItem>

            <GridItem
                xs={12}
                sm={12}
                md={3}
                lg={3}
                mt={10}
                sx={{
                    textAlign: 'center!important',
                    marginLeft: '145px',
                }}
            >
                <MuiBox
                    sx={{
                        '& .MuiButton-root': { padding: '5px 15px!important' },
                    }}
                >
                    <Button
                        defaultColor={
                            GetThemeColorFromStorage()?.navigation?.buttons
                                ?.backgroundColor
                        }
                        icon=""
                        outlined="outlined"
                        text="Ir para a home"
                        textColor="#FFFFFF"
                        typeVariation="CustomTextButton"
                        borderRadius="5px"
                        onClick={handleHomeClick}
                        padding="5px 15px!important"
                    />
                </MuiBox>
            </GridItem>
            <GridItem
                xs={12}
                sm={12}
                md={6}
                lg={6}
                mt={isSmallScreen ? 2 : 10}
                sx={{ textAlign: 'left!important' }}
            >
                <MuiBox
                    sx={{
                        '& .MuiButton-root': { padding: '5px 15px!important' },
                    }}
                >
                    <Button
                        customSize="72%"
                        borderColor={
                            GetThemeColorFromStorage()?.navigation?.buttons
                                ?.backgroundColor
                        }
                        defaultColor="transparent"
                        icon=""
                        outlined="outlined"
                        text="Realizar nova transferência"
                        textColor={
                            GetThemeColorFromStorage()?.navigation?.buttons
                                ?.backgroundColor
                        }
                        typeVariation="CustomTextButton"
                        borderRadius="5px"
                        onClick={() => setMessageSuccess(false)}
                        padding="5px 15px!important"
                    />
                </MuiBox>
            </GridItem>
        </GridContainer>
    )
}
