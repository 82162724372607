import * as React from 'react'

// @ Validators
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// @ Material-ui
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material'

// @ Components
import Button from 'components/CustomButtons/Button.jsx'

// @ Styles
import { ButtonBox, CardBox, Icon, Typography } from './styles'

// @ Redux

const ModalDelete = ({ open, handleClose }) => {
    const { t } = useTranslation()
    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <CardBox>
                <i className="icon-alerta_modal" />
                <Box
                    sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#21409A',
                    }}
                >
                    {t('CONTA ENCERRADA')}
                </Box>

                <Typography>
                    {t('Ficamos tristes com a exclusão da sua conta.')}
                </Typography>
                <Typography>
                    {t(
                        'Seus dados foram excluídos completamente da nossa base.',
                    )}
                </Typography>
                <Typography sx={{ lineHeight: '1.5' }}>
                    Mas lembre-se, você pode voltar ao Programa a hora que
                    quiser,
                    <br />
                    basta se cadastrar novamente.
                </Typography>
                <Typography>{t('Até logo.')}</Typography>
                <ButtonBox>
                    <Button
                        size="sm"
                        color="danger"
                        onClick={handleClose}
                        style={{ height: '40px' }}
                        startIcon={<Icon className="icon-ico_cancelar" />}
                    >
                        {t('Fechar')}
                    </Button>
                </ButtonBox>
            </CardBox>
        </Modal>
    )
}

ModalDelete.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
}

export default ModalDelete
