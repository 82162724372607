import React from 'react'
import {
    MuiBox,
    MuiDefaultModal,
    Button,
    GridItem,
    MuiTypography,
    MuiInput,
    GridContainer,
    AlertModalIcon,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { Controller } from 'react-hook-form'
import RegisterIndicationInput from '../../../../components/RegisterIndicationInput'
import { styleModalCode } from '../../utils'
export function CodeModal({ ...sharedProps }) {
    const {
        isSmallScreen,
        control,
        handleFinalSubmit,
        handleSubmit,
        isModalCodeOpen,
        setIsModalCodeOpen,
        onSubmit,
        isDisabledNumberToken,
    } = sharedProps

    return (
        <MuiDefaultModal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={isModalCodeOpen}
            onClose={() => setIsModalCodeOpen(false)}
        >
            <MuiBox
                sx={{ ...styleModalCode, maxHeight: '90vh', overflowY: 'auto' }}
            >
                <form onSubmit={handleSubmit(handleFinalSubmit)}>
                    <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={12}>
                            <AlertModalIcon size={70} color="#F8BB86" />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <MuiTypography
                                color={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                fontSize="14px"
                                fontWeight={600}
                            >
                                UTILIZE O CÓDIGO ENVIADO
                            </MuiTypography>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} mt={2}>
                            <Controller
                                as={
                                    <RegisterIndicationInput
                                        title={''}
                                        hasBackground={false}
                                        validChars="0-9"
                                        amount={4}
                                        error
                                    />
                                }
                                name="numberToken"
                                control={control}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} mb={3} mt={2}>
                            <MuiTypography color="#717171" fontSize="14px">
                                Caso não tenha recebido, aguarde o tempo
                                informado e solicite um novo.
                            </MuiTypography>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{
                                paddingLeft: isSmallScreen
                                    ? 0
                                    : '80px!important',
                            }}
                        >
                            <Button
                                defaultColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                icon=""
                                outlined="outlined"
                                text="Confirmar saque"
                                textColor="#FFFFFF"
                                typeVariation="CustomTextButton"
                                borderRadius="5px"
                                type="submit"
                                disabled={isDisabledNumberToken}
                            />
                        </GridItem>

                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{
                                paddingRight: isSmallScreen
                                    ? 0
                                    : '55px!important',
                            }}
                        >
                            <Button
                                borderColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                defaultColor="transparent"
                                icon=""
                                outlined="outlined"
                                text="Solicitar novo código"
                                textColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                typeVariation="CustomTextButton"
                                borderRadius="5px"
                                onClick={onSubmit}
                            />
                        </GridItem>
                    </GridContainer>
                </form>
            </MuiBox>
        </MuiDefaultModal>
    )
}
