import { Grid, TextField, withStyles } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

export const Container = styled(Grid)`
    display: flex;
    align-items: center;
    position: relative;

    .MuiFormHelperText-root {
        position: absolute;
        top: 40px;
    }
`

export const Wrapper = withStyles({
    root: {
        width: '100%',

        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 12px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -14px) scale(0.75)',
        },

        '& label.Mui-focused': {
            color: grayColor[2],
        },
        '& label': {
            fontSize: '14px',
            lineHeight: 1.2,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: grayColor[2],
        },
        '& .MuiOutlinedInput-root': {
            height: '40px',
            // marginTop: '8px',
            '&:hover fieldset': {
                borderColor: grayColor[2],
            },
            '&.Mui-focused fieldset': {
                borderColor: grayColor[2],
            },
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '0px',
        },

        '& .input:-internal-autofill-selected, .input:-webkit-autofill, .input:-webkit-autofill:hover, .input:-webkit-autofill:focus, .input:-webkit-autofill:active':
            {
                '-webkit-box-shadow': '0 0 0 30px white inset !important',
            },
    },
})(TextField)