import React from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'
import Loader from 'components/Loader/Loader.jsx'
import MaskedInput from 'react-text-mask'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// @material-ui/core components
import {
	FormControl,
	IconButton,
	InputAdornment,
	MenuItem,
	TableBody,
	TableCell,
	TableRow,
	TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import GridContainer from 'components/Grid/GridContainer.jsx'
import {
	CustomButtonsBackground,
	CustomButtons,
} from '../../components/CustomButtons'
import Section from '../../components/Section/Section'
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx'

// style
import styles from './shopHistory.module.scss'
import * as S from './styles'

import IconeCalendario from 'assets/img/ico_calendario.svg'

// UTILS
import compose from 'utils/compose'
import { FormatCNPJ } from 'utils/utils'

// REDIX INIT
import { connectRouterState } from 'utils/connectRouterState'

import {
	GetPartnersData,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
	GetShopHistoryData,
} from 'redux/actions'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import GridTable from './components/NewTable/GridTable'
import { Add, Remove } from '@material-ui/icons'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import MaterialTable from 'material-table'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import { getProgramTitle } from '../../utils/utils'

const shopHistoryStyle = {
	...extendedFormsStyle,
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	inputHeight: {
		height: '9px',
	},
}

const customSelectStyles = {
	control: (base, state) => ({
		...base,
		input: { height: '26px' },
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}

const CustomPopper = function (props) {
	const classesPoper = useStyles()
	return (
		<Popper {...props} className={classesPoper.root} placement="bottom" />
	)
}

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				'& :hover': {
					color: '#ffffff',
					backgroundColor:
						GetThemeColorFromStorage() &&
							GetThemeColorFromStorage()?.navigation &&
							GetThemeColorFromStorage()?.navigation?.buttons
							? GetThemeColorFromStorage()?.navigation?.buttons
								?.backgroundColor + ' !important'
							: `#fff`,
				},
				'& li': {
					backgroundColor: '#ffffff',
				},
			},
		},
	}),
)

function DateMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor:
					GetThemeColorFromStorage()?.navigation?.buttons
						?.backgroundColor,
			},
			'&.Mui-focused fieldset': {
				borderColor:
					GetThemeColorFromStorage()?.navigation?.buttons
						?.backgroundColor,
			},
		},
	},
})(TextField)

const movType = [
	{ key: 1, value: 'TRANSACTION' },
	{ key: 2, value: 'OPERATION' },
]

class shopHistory extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchDateFrom: '',
			dateFrom: '',
			searchDateTo: '',
			dateTo: '',
			searchInvoiceCode: '',
			documentId: '',
			searchType: '',
			searchPartner: '',
			partners: [],
			partnerId: '',
			results: false,
			open: false,
			invoiceDataFailed: false,
			pageSize: 10,
			recordCount: 0,
			currentPage: 1,
			isHidden: true,
			theme: GetThemeColorFromStorage(),
		}

		if (localStorage.getItem('i18nextLng') != null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		let dateFrom = _.get(this.props.urlState, 'searchDateFrom', '')
		let dateTo = _.get(this.props.urlState, 'searchDateTo', '')
		let documentId = _.get(this.props.urlState, 'searchInvoiceCode', '')
		let searchPartner = { label: '', value: '' }
		this.setState({ theme: GetThemeColorFromStorage() })
		searchPartner.label = _.get(this.props.urlState, 'searchPartner', '')
		searchPartner.label !== ''
			? this.props
				.GetPartnersData(
					searchPartner.label.split('|')[0].trim(),
					'',
					1,
				)
				.then(() => {
					this.props.partnersData.results.map(
						(partner) => (searchPartner.value = partner.id),
					)
					this.setState(
						{
							searchDateFrom: dateFrom,
							dateFrom:
								moment(dateFrom).isValid() &&
								moment(dateFrom).format('L'),
							searchDateTo: dateTo,
							dateTo:
								moment(dateTo).isValid() &&
								moment(dateTo).format('L'),
							searchPartner,
							searchInvoiceCode: documentId,
							partnerId: searchPartner.value,
							currentPage: _.get(
								this.props.urlState,
								'currentPage',
								1,
							),
							pageSize: _.get(
								this.props.urlState,
								'pageSize',
								10,
							),
						},
						() => {
							this.props.GetShopHistoryData(
								_.get(
									this.props.urlState,
									'searchDateFrom',
									'',
								),
								_.get(
									this.props.urlState,
									'searchDateTo',
									'',
								),
								_.get(
									this.props.urlState,
									'searchInvoiceCode',
									'',
								),
								searchPartner.value,
								_.get(this.props.urlState, 'searchType', ''),
								this.state.currentPage,
								_.get(this.props.urlState, 'pageSize', ''),
							)
						},
					)
				})
			: this.props.GetShopHistoryData(
				'',
				'',
				'',
				'',
				'',
				_.get(this.props.urlState, 'currentPage', 1),
				_.get(this.props.urlState, 'pageSize', 10),
			)
	}

	componentDidUpdate = (prevProps, prevState) => {
		let prevUrlState = _.get(prevProps, 'urlState')
		let urlState = _.get(this.props, 'urlState')
		if (!_.isEqual(prevUrlState, urlState)) {
			this.props.ShowLoader(<Loader />)
			let searchPartner = { label: '', value: '' }
			let searchType = ''
			searchPartner.label = _.get(
				this.props.urlState,
				'searchPartner',
				'',
			)
			searchType = _.get(this.props.urlState, 'searchType', '')
			searchPartner.label !== '' &&
				this.props
					.GetPartnersData(
						searchPartner.label.split('|')[0].trim(),
						'',
						1,
					)
					.then(() => {
						this.props.partnersData.results.map(
							(partner) => (searchPartner.value = partner.id),
						)
					})
			this.setState({ ...urlState, searchPartner, searchType })
			this.props.GetShopHistoryData(
				urlState.searchDateFrom,
				urlState.searchDateTo,
				this.state.searchInvoiceCode,
				this.state.partnerId,
				urlState.searchType,
				urlState.currentPage,
				urlState.pageSize,
			)
		}

		if (this.props.partnersData.length === 0) {
			this.getPartnerOptions()
		}

		if (prevProps.invoiceData !== this.props.invoiceData) {
			this.setState({ ...this.props.invoiceData })
			this.props.HideLoader()
		}

		if (prevProps.invoiceDataFailed !== this.props.invoiceDataFailed) {
			this.props.HideLoader()
			this.setState({ invoiceDataFailed: true })
			this.props.SweetAlert(
				<Alert
					error
					style={{ display: 'block' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					{'Erro ao efetuar a operação.'}
				</Alert>,
			)
		}
	}

	componentWillUnmount() {
		this.props.HideAlert()
	}

	handlePartnerChange = (event, value) => {
		this.setState({ searchPartner: value, partnerId: value.value })
	}

	handleDateChange = (state, name, event) => {
		var value = event
		if (this.state.dateFrom && name === 'dateTo') {
			if (moment(this.state.searchDateFrom).isAfter(moment(value))) {
				value = moment(this.state.searchDateFrom)
			}
		}
		this.setState({
			[name]: value,
			[state]: moment(value).isValid()
				? moment(value).format('YYYY-MM-DD')
				: '',
		})

		if (name === 'dateFrom' && this.state.dateTo) {
			if (moment(this.state.searchDateTo).isBefore(moment(value))) {
				this.handleDateChange('searchDateTo', 'dateTo', event)
			}
		}
	}

	handleChange = (event) => {
		const id = event.target.id || event.target.name
		const value = event.target.value
		this.setState({ [id]: value })
	}

	handleToggle = () => {
		this.setState({ open: !this.state.open })
	}

	handleSearch = (currentPage) => {
		this.props.setURLState({ currentPage })
	}

	handleType = (newValue) => {
		this.setState({ searchType: newValue.target.value })
	}

	handleFilter = () => {
		const {
			searchDateFrom,
			searchDateTo,
			searchPartner,
			searchInvoiceCode,
			searchType,
		} = this.state
		this.props.setURLState({
			searchDateFrom,
			searchDateTo,
			searchPartner: searchPartner.label
				? searchPartner.label
				: this.props.t('PLACEHOLDER_FIND_PARTNER'),
			searchInvoiceCode: searchInvoiceCode,
			searchType,
			currentPage: 1,
			pageSize: 10,
		})
	}

	handleClearFilter = () => {
		this.setState(
			{
				searchDateFrom: '',
				dateFrom: '',
				searchDateTo: '',
				dateTo: '',
				searchPartner: '',
				partnerId: '',
				searchInvoiceCode: '',
				searchType: '',
			},
			() => this.handleFilter(),
		)
	}

	getPartnerOptions = () => {
		let partners = []

		this.props.GetPartnersData(' ', ' ', 1).then(() => {
			if (this.props.partnersData) {
				this.props.partnersData.results.map((partner) => {
					if (partner) {
						partners.push({
							value: partner.id,
							label:
								partner.cnpj && partner.cnpj !== null
									? partner.name +
									' | ' +
									FormatCNPJ(partner.cnpj)
									: partner.name,
						})
					}
				})
			}
			return this.setState({ partners: partners })
		})
	}

	loadPartnerOptions = () => {
		this.getPartnerOptions()
	}

	toggleShownRowIndex = (index) => {
		this.setState({
			showRowIndex: index,
		})
	}

	render() {
		const { t, classes } = this.props
		const {
			invoiceDataFailed,
			dateFrom,
			dateTo,
			searchPartner,
			partners,
			searchType,
			results,
			pageSize,
			recordCount,
			open,
			searchInvoiceCode,
			currentPage,
		} = this.state

		return (
			<S.Container>
				<DocumentTitle title={getProgramTitle(t('NAVPILLS_SHOP_HISTORY'))} />

				<Section title={t('MENU_HISTORY')} />
				<S.FormContainer
					className={classes.filterForm}
					theme={this.state.theme}
				>
					<Grid container rowSpacing={3} columnSpacing={3}>
						<Grid item xs={6} sm={6} md={2} lg={2}>
							<S.DateTimeReact
								className={styles.dateInputBlock}
								id="searchDateFrom"
								timeFormat={false}
								value={dateFrom}
								onChange={this.handleDateChange.bind(
									null,
									'searchDateFrom',
									'dateFrom',
								)}
								renderInput={(props) => {
									return (
										<CssTextField
											label={t('DATETIME_FROM')}
											variant="outlined"
											margin="dense"
											style={{ backgroundColor: '#fff' }}
											{...props}
											value={
												!_.isEmpty(
													this.state.searchDateFrom,
												)
													? props.value
													: ''
											}
											InputProps={{
												endAdornment: (
													<InputAdornment>
														<i
															className="icon-data"
															style={{
																marginRight:
																	'-8px',
																fontSize:
																	'20px',
																color: this
																	.state.theme
																	?.visual
																	?.icons
																	?.color,
															}}
														/>
													</InputAdornment>
												),
												inputComponent: DateMaskCustom,
											}}
										/>
									)
								}}
							/>
						</Grid>
						<Grid item xs={6} sm={6} md={2} lg={2}>
							<S.DateTimeReact
								id="searchDateTo"
								timeFormat={false}
								value={dateTo}
								onChange={this.handleDateChange.bind(
									null,
									'searchDateTo',
									'dateTo',
								)}
								className={styles.dateInputBlock}
								renderInput={(props) => {
									return (
										<CssTextField
											label={t('DATETIME_TO')}
											variant="outlined"
											margin="dense"
											style={{ backgroundColor: '#fff' }}
											{...props}
											value={
												!_.isEmpty(
													this.state.searchDateTo,
												)
													? props.value
													: ''
											}
											InputProps={{
												endAdornment: (
													<InputAdornment>
														<i
															className="icon-data"
															style={{
																marginRight:
																	'-8px',
																fontSize:
																	'20px',
																color: this
																	.state.theme
																	?.visual
																	?.icons
																	?.color,
															}}
														/>
													</InputAdornment>
												),
												inputComponent: DateMaskCustom,
											}}
										/>
									)
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<S.NameCnpjPartner>
								<Autocomplete
									styles={customSelectStyles}
									id="combo-box-demo"
									size="small"
									options={partners}
									getOptionLabel={(option) =>
										option.label ? option.label : ''
									}
									disableClearable={true}
									onChange={this.handlePartnerChange}
									value={searchPartner}
									PopperComponent={CustomPopper}
									renderInput={(params) => (
										<div ref={params.InputProps.ref}>
											<CssTextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() =>
													t('RESULTS_NOT_FOUND')
												}
												label={t(
													'PLACEHOLDER_FIND_PARTNER',
												)}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
								/>
							</S.NameCnpjPartner>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={4}>
							<S.InvoiceNumber>
								<CssTextField
									fullWidth
									label={t('FIELD_INVOICE_CODE')}
									id="searchInvoiceCode"
									name="searchInvoiceCode"
									value={searchInvoiceCode}
									onChange={this.handleChange}
									variant="outlined"
									margin="dense"
									style={{ backgroundColor: '#fff' }}
									disabled={false}
									InputProps={{
										classes: {
											root: classes.cssOutlinedInput,
											focused: classes.cssFocused,
											notchedOutline:
												classes.notchedOutline,
										},
									}}
									InputLabelProps={{
										classes: {
											root: classes.label,
											focused: classes.focusedLabel,
											error: classes.erroredLabel,
										},
									}}
								/>
							</S.InvoiceNumber>
						</Grid>

						{open && (
							<Grid item xs={12} sm={6} md={4} lg={4}>
								<CssTextField
									select
									fullWidth
									variant="outlined"
									margin="dense"
									style={{ backgroundColor: '#fff' }}
									label={t('MOVIMENTATION_TYPE')}
									onChange={this.handleType}
									value={searchType}
								>
									{movType?.map((item) => (
										<MenuItem
											value={item.key}
											key={item.key}
										>
											{t(item.value)}
										</MenuItem>
									))}
								</CssTextField>
							</Grid>
						)}
					</Grid>

					<S.ContaineMoreFilter>
						<S.MoreFilter title="Pesquisa avançada">
							<IconButton
								classes={{ root: classes.filterPlusButton }}
								title="Pesquisa avançada"
								onClick={this.handleToggle}
							>
								{!open ? <Add /> : <Remove />}
							</IconButton>
							<span onClick={this.handleToggle}>
								{!open
									? t('SHOW_MORE_FILTERS')
									: t('HIDE_MORE_FILTERS')}
							</span>
						</S.MoreFilter>

						<S.BtnSearchFilter>
							<CustomButtonsBackground
								onClick={() => this.handleFilter()}
							>
								{t('BUTTON_FIND')}
							</CustomButtonsBackground>
							<CustomButtons onClick={this.handleClearFilter}>
								{t('BUTTON_CLEAN_FILTER')}
							</CustomButtons>
						</S.BtnSearchFilter>
					</S.ContaineMoreFilter>
				</S.FormContainer>

				<GridContainer justify="center" style={{ paddingTop: '10px' }}>
					<Grid xs={12} style={{ padding: '0px 0px 0px 15px' }}>
						<GridTable
							data={
								results && results.length !== 0 ? results : []
							}
							history={this.props.history}
						/>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								borderBottom:
									'1px solid rgba(224, 224, 224, 1)',
							}}
						>
							{(results && results.length === 0) ||
								invoiceDataFailed ? (
								<TableBody>
									<TableRow>
										<TableCell
											colSpan={10}
											align="center"
											style={{ borderBottom: 'none' }}
										>
											{t('RESULTS_NOT_FOUND')}.
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								''
							)}
						</div>

						{results && results.length > 0 && (
							<div
								style={{
									textAlign: 'center',
									marginTop: '5px',
									width: '100%',
								}}
							>
								<MaterialTable
									onChangePage={(e) =>
										this.handleSearch(e + 1)
									}
									onChangeRowsPerPage={(e) => {
										this.props.setURLState({ pageSize: e })
									}}
									title=""
									data={results}
									totalCount={recordCount}
									page={currentPage - 1}
									options={{
										sorting: false,
										emptyRowsWhenPaging: false,
										toolbar: false,
										actionsColumnIndex: -1,
										search: false,
										draggable: false,
										pageSize:
											_.get(
												this.props.urlState,
												'pageSize',
												10,
											) ?? pageSize,
										pageSizeOptions: [
											10, 20, 30, 40, 50, 100,
										],
									}}
									localization={{
										body: {
											emptyDataSourceMessage: (
												<S.EmptyTable>
													<i className="icon-alerta_modal" />
													<span>
														{t(
															'No records to display',
														)}
													</span>
												</S.EmptyTable>
											),
										},
										pagination: {
											labelRowsSelect: `${t('lines')}`,
											//labelDisplayedRows: '{from} de {to}-{count}',
											firstTooltip: ` ${t('First page')}`,
											previousTooltip: `${t(
												'Previous page',
											)}`,
											nextTooltip: `${t('Next page')}`,
											lastTooltip: `${t('Last page')}`,
										},
									}}
								/>
							</div>
						)}
					</Grid>
				</GridContainer>
			</S.Container>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		userData: state.users.userData,
		statementData: state.wallet.statementData,
		statementDataFailed: state.wallet.statementDataFailed,
		partnersData: state.partners.partnersData,
		invoiceData: state.invoice.invoiceData,
		invoiceDataFailed: state.invoice.invoiceDataFailed,
		partnersDataFailed: state.partners.partnersDataFailed,
		generalRulesData: state.generalRules.generalRulesData,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetPartnersData,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
			GetShopHistoryData,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRouterState({
		searchType: '',
		searchDateFrom: '',
		searchDateTo: '',
		searchPartner: '',
		currentPage: 1,
		pageSize: 10,
	}),
	withTranslation(),
	withStyles(shopHistoryStyle),
	// withStyles(extendedFormsStyle),
)(shopHistory)
