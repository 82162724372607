import axios from 'axios'

export const GetCity = (stateId) =>
    axios.get(`/City?stateId=${stateId}`).then((response) => response.data)

export const GetState = (countryId) =>
    axios.get(`/State?countryId=${countryId}`).then((response) => response.data)

export const GetCountries = () =>
    axios.get(`/Country`).then((response) => response.data)

export const FindCNPJ = () =>
    axios.get(`/Country`).then((response) => response.data)

export const FindZipCode = (zipcode) =>
    axios
        .get(`/Address/SearchZipcode/${zipcode}`)
        .then((response) => response.data)

export const VerifyPhone = (number, id) => {
    const url = `/Consumer/VerifyPhoneNumber2?phoneNumber2=${number}`
    return axios
        .get(id ? url + `&consumerId=${id}` : url)
        .then((response) => response.data)
}
