import axios from 'axios'

export const allowIndications = async () => {
    const data = await axios
        .get(`/ProgramRules/General/BasicData`)
        .then((response) => response.data.allowIndications)

    return data
}

export const allowActive = async () => {
    const data = await axios
        .get(
            `/Negotiation/GetExtractList?TypeOfBenefit=Points&pageSize=10&page=1`,
        )
        .then(
            (response) =>
                response?.data?.results?.length && response?.data?.results,
        )

    console.log({ data })

    return data
}
