import styled from 'styled-components/macro'
import Button from '@material-ui/core/Button'
import { colorLuminance } from '../../redux/api/themeColor'

export const ButtonStyle = styled.button`
    font-size: 13px;
    color: ${(props) => props.color};
`

export const ButtonBackground = styled.button`
    align-items: center;
    border-radius: 6px;
    color: ${(props) => props.textColorThemeColor};
    background-color: ${(props) => props.backgroundColorThemeColor};
    border: 0px solid ${(props) => props.backgroundColorThemeColor};
    box-shadow: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 8px 15px;
    gap: 10px;
    white-space: nowrap;
    font-size: 15px;
    height: 40px;
    border-radius: 6px;
    padding: 0px 15px;
    &:disabled {
        background: #c2c2c2;
        border-color: #c2c2c2;
        cursor: not-allowed;
        &:hover {
            background-color: #c2c2c2;
            border-color: #c2c2c2;
            color: #fff;
        }
    }
    &:hover {
        background-color: ${(props) => props.hoverThemeColor};
        border-color: ${(props) => props.hoverThemeColor};
    }
`
export const ButtonBackgroundLess = styled.button`
    align-items: center;
    border-radius: 6px;
    color: ${(props) => props.backgroundColorThemeColor};
    background-color: transparent;
    border: 1px solid ${(props) => props.backgroundColorThemeColor};
    box-shadow: none;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: center;
    padding: 8px 15px;
    gap: 10px;
    white-space: nowrap;
    &:disabled {
        background: #c2c2c2;
        border-color: #c2c2c2;
    }
    &:hover {
        background-color: ${(props) => props.backgroundColorThemeColor};
        border-color: ${(props) => props.backgroundColorThemeColor};
        color: ${(props) => props.textColorThemeColor};
    }
`
// ====================================
export const ButtoCustom = styled(Button)`
    font-size: 13px;
    color: ${(props) => props.color};

    &.MuiButton-root {
        background-color: ${({ greenButtonColor, themeColor }) =>
            greenButtonColor === 'greenButtonColor' &&
            themeColor?.navigation?.buttons?.backgroundColor} !important;
        color: ${({ greenButtonColor, themeColor }) =>
            greenButtonColor === 'greenButtonColor' &&
            themeColor?.navigation?.buttons?.textColor} !important;
        border: ${({ greenButtonColor, themeColor }) =>
            greenButtonColor === 'greenBorderButtonColor' &&
            `1px solid${themeColor?.navigation?.buttons?.backgroundColor}`} !important;
        box-shadow: none;

        :hover {
            background-color: ${({ greenButtonColor, themeColor }) =>
                greenButtonColor === 'greenButtonColor' &&
                colorLuminance(
                    themeColor?.navigation?.buttons?.backgroundColor,
                    -0.5,
                )} !important;
        }
    }
`
