import React from 'react';
import Alert from 'react-bootstrap-sweetalert'


export default({currErr, ___this })=>(
    <Alert
        warning
        style={{display: 'block', marginTop: '-100px' }}
        title={null}
        onConfirm={currErr.onConfirm}
        confirmBtnCssClass={`${___this.props.classes.button} ${___this.props.classes.greenButtonColor}`}
        confirmBtnText={currErr.confirmBtnText}
    >
        <div style={{fontWeight: 500,fontFamily: 'helvetica',}}> {currErr.content}</div>
    </Alert>
)