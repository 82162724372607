import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Fade } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import { ModalContainer, PaperContainer, SectionInfo, SectionText, TitleTxt } from './styles'

import { ButtonClose } from 'components/NewButton/styles'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

import Typography from 'components/Typography/Info'
function Modal({
    className,
    closeByClick,
    handleClose,
    onConfirm,
    maxWidth,
    minWidth,
    open,
    padding,
    shadow,
    title,
    text,
    type,
    t,
}) {
    return (
        <ModalContainer
            open={open}
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            maxwidth={maxWidth}
            minwidth={minWidth}
            disableEnforceFocus
        >
            <Fade in={open} unmountOnExit>
                <PaperContainer padding={padding} maxwidth={maxWidth}>
                    <GridContainer
                        xs={12}
                        sm={12}
                        style={{ margin: '15px 20px 30px 2px' }}
                    >
                        {type === 'stateRegister' && (
                            <GridItem
                                xs={12}
                                sm={12}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    cursor: 'pointer',
                                }}
                            >
                                <ButtonClose
                                    size="sm"
                                    variant="contained"
                                    onClick={handleClose}
                                >
                                    <i
                                        className="icon-ico_cancelar"
                                        style={{
                                            fontSize: '18px',
                                            marginRight: '10px',
                                        }}
                                    />
                                    {t('BUTTON_CLOSE')}
                                </ButtonClose>
                            </GridItem>
                        )}

                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <i
                                className="icon-alerta_modal"
                                style={{
                                    color: germiniDefaultColors[11],
                                    fontSize: '80px',
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
                        {type === 'stateRegister' && (
                            <SectionText>{t(text)}</SectionText>
                        )}
                    </GridItem>{' '}
                    {type === 'tokenRegister' && (
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{ textAlign: 'center' }}
                        >
                            <TitleTxt>
                                <Typography>{t(title)}</Typography>
                            </TitleTxt>
                            <SectionInfo>{t(text)}</SectionInfo>
                            <Button
                                size="sm"
                                variant="contained"
                                color={'greenButtonColor'}
                                onClick={onConfirm}
                                style={{
                                    margin: '25px 0px',
                                }}
                            >
                                {t("Resend email")}
                            </Button>
                        </GridItem>
                    )}
                </PaperContainer>
            </Fade>
        </ModalContainer>
    )
}

Modal.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    closeByClick: PropTypes.func,
    showButtons: PropTypes.bool,
    handleClose: PropTypes.func,
    handleForgetPWD: PropTypes.func,
    handleLogin: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
    text: PropTypes.string,
}

Modal.defaultProps = {
    children: null,
    className: '',
    closeByClick: null,
    handleClose: null,
    handleForgetPWD: null,
    handleLogin: null,
    maxWidth: '',
    minWidth: '',
    open: false,
    showButtons: true,
    padding: '',
    shadow: '',
    text: '',
}

export default withTranslation()(Modal)
