import styled from 'styled-components'

import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
export const Container = styled.div`
  cursor: pointer;

  > div {
    > div:not(:nth-child(2)) {
      z-index: -2;
    }

    :hover div:not(:nth-child(2)) {
      z-index: 2;
    }
  }

  .slick-slide {
    visibility: hidden;

	[class^="home_categoryTitle"]{
		color ${GetThemeColorFromStorage()?.titles?.secondary?.textColor} !important
	}
  }

  .slick-slide.slick-active {
    visibility: visible;
  }

`

export const PrevArrow = styled.div`
    left: 5px !important;
    z-index: 1;

    :hover {
        ::before {
            color: #333333 !important;
        }
    }
`

export const NextArrow = styled.div`
    right: 5px !important;

    :hover {
        ::before {
            color: #333333 !important;
        }
    }
`
