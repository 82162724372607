import React from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import Datetime from 'react-datetime'
import DocumentTitle from 'react-document-title'
import MaskedInput from 'react-text-mask'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'
// @material-ui/core components
import { InputAdornment, FormControl } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Loader from 'components/Loader/Loader.jsx'
import {
	CustomButtonsBackground,
	CustomButtons,
} from '../../../components/CustomButtons'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Section from 'components/Section/Section'
import VoucherCard from './components/VoucherCard.js'
import SnackDefault from 'components/SnackDefault'
// style
import styles from './styles.module.scss'
import * as S from './styles'
// UTILS
import compose from 'utils/compose'
import { numberFormatText } from 'utils/utils'

// REDIX INIT
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { connectRouterState } from 'utils/connectRouterState'

import {
	GetPartnersData,
	HideAlert,
	HideLoader,
	ShowLoader,
	SweetAlert,
	GetSentGiftCard,
} from 'redux/actions'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import { CssTextField } from 'components/CssTextField/CssTextField'
import Autocomplete from '@mui/material/Autocomplete'

import { GetGeneralRulesData } from 'redux/api/generalRules.js'
import MaterialTable from 'material-table'
import { getProgramTitle } from '../../../utils/utils.js'

const sentStyle = {
	...extendedFormsStyle,
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	inputHeight: {
		height: '9px',
	},
}

function DateMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}


const createOption = (_label, _value) => ({ label: _label, value: _value })
const options = [
	createOption(`Últimos 7 dias`, `last7days`),
	createOption(`Últimos 30 dias`, `last30days`),
	createOption(`Personalizado`, `custom`),
]

const customSelectStyles = {
	control: (base, state) => ({
		...base,
		input: { height: '26px' },
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}

class Sent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			searchPeriod: '',
			period: '',

			searchDateFrom: '',
			dateFrom: '',

			searchDateTo: '',
			dateTo: '',

			searchDestiny: '',
			destiny: '',

			disabeldData: false,

			results: false,
			pageSize: 10,
			recordCount: 0,
			currentPage: 1,
			isHidden: true,

			snackStatus: {
				open: false,
				severity: 'success',
				text: '',
			},

			generalRulesData: [],
		}

		if (localStorage.getItem('i18nextLng') !== null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		this.getGeneralRulesData()
		//
		let dateFrom = _.get(this.props.urlState, 'searchDateFrom', '')
		let dateTo = _.get(this.props.urlState, 'searchDateTo', '')
		let destiny = _.get(this.props.urlState, 'searchDestiny', '')
		let pageSize = _.get(this.props.urlState, 'pageSize', '')
		let searchPeriod = { label: '', value: '' }
		searchPeriod.label = _.get(this.props.urlState, 'searchPeriod', '')

		if (!this.props.generalRulesData.allowGiftCard) {
			this.props.history.push(`/home`)
		}

		//
		if (searchPeriod.label !== '') {
			this.props
				.GetPartnersData(searchPeriod.label.split('|')[0].trim(), '', 1)
				.then(() => {
					this.props.partnersData.results.map(
						(partner) => (searchPeriod.value = partner.id),
					)
					this.setState(
						{
							searchDateFrom: dateFrom,
							dateFrom:
								moment(dateFrom).isValid() &&
								moment(dateFrom).format('L'),
							searchDateTo: dateTo,
							dateTo:
								moment(dateTo).isValid() &&
								moment(dateTo).format('L'),

							searchPeriod,
							searchDestiny: destiny,
							period: searchPeriod.value,
							currentPage: _.get(
								this.props.urlState,
								'currentPage',
								1,
							),
							pageSize: _.get(this.props.urlState, 'pageSize', ''),
						},
						() => {
							this.props.GetSentGiftCard(
								_.get(
									this.props.urlState,
									'searchDateFrom',
									'',
								),
								_.get(this.props.urlState, 'searchDateTo', ''),
								_.get(this.props.urlState, 'searchDestiny', ''),
								1,
								_.get(this.props.urlState, 'pageSize', ''),
							)
						},
					)
				})
		} else {
			this.props.GetSentGiftCard('', '', '', 1, pageSize ?? this.state.pageSize)
		}
	}

	componentDidUpdate = (prevProps) => {
		let prevUrlState = _.get(prevProps, 'urlState')
		let urlState = _.get(this.props, 'urlState')
		if (!_.isEqual(prevUrlState, urlState)) {
			this.props.ShowLoader(<Loader />)
			this.props.GetSentGiftCard(
				urlState.searchDateFrom,
				urlState.searchDateTo,
				urlState.searchDestiny,
				urlState.currentPage,
				urlState.pageSize,
			)
		}

		if (prevProps.giftCardData !== this.props.giftCardData) {
			this.setState({ ...this.props.giftCardData })
			this.props.HideLoader()
		}

		if (prevProps.giftCardError !== this.props.giftCardError) {
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					error
					style={{ display: 'block' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					{'Erro ao efetuar a operação.'}
				</Alert>,
			)
		}
	}

	componentWillUnmount() {
		this.props.HideAlert()
	}
	//
	getGeneralRulesData = async () => {
		const response = await GetGeneralRulesData()
		this.setState({ generalRulesData: response })
	}

	handleDateChange = (state, name, event) => {
		var value = event
		if (this.state.disabeldData) {
			this.setState({
				searchDateFrom: null,
				dateFrom: null,
				searchDateTo: null,
				dateTo: null,
			})
			return false
		}

		if (this.state.dateFrom && name === 'dateTo') {
			if (moment(this.state.searchDateFrom).isAfter(moment(value))) {
				value = moment(this.state.searchDateFrom)
			}
		}
		this.setState({
			[name]: value,
			[state]: moment(value).isValid()
				? moment(value).format('YYYY-MM-DD')
				: '',
		})

		if (name === 'dateFrom' && this.state.dateTo) {
			if (moment(this.state.searchDateTo).isBefore(moment(value))) {
				this.handleDateChange('searchDateTo', 'dateTo', event)
			}
		}
	}

	handleChangePeriod = (newValue, value) => {
		this.setState({ searchPeriod: value, period: value.value })
		//
		let endDate = null
		if (value.value === 'custom') {
			this.setState({ disabeldData: false })
		} else if (value.value === 'last7days') {
			this.setState({ disabeldData: true })
			//
			const subtract = moment().subtract(7, 'days')
			endDate = subtract
			this.setState({
				searchDateFrom: endDate,
				dateFrom: endDate,
				searchDateTo: moment(),
				dateTo: moment(),
			})
			//
		} else if (value.value === 'last30days') {
			this.setState({ disabeldData: true })
			//
			const subtract = moment().subtract(30, 'days')
			endDate = subtract
			this.setState({
				searchDateFrom: endDate,
				dateFrom: endDate,
				searchDateTo: moment(),
				dateTo: moment(),
			})
		}
	}

	handleChange = (event) => {
		const id = event.target.id || event.target.name
		const value = event.target.value
		this.setState({ [id]: value })
	}

	handleSearch = (currentPage) => {
		this.props.setURLState({ currentPage })
	}

	handleSearchPage = (pageSize) => {
		this.props.setURLState({ pageSize })
	}

	validateEmail = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	}

	handleFilter = () => {
		const { searchDateFrom, searchDateTo, searchDestiny } =
			this.state
		if (!_.isEmpty(searchDestiny) && !this.validateEmail(searchDestiny)) {
			this.setState({
				snackStatus: {
					open: true,
					severity: 'warning',
					text: `'${searchDestiny}' ${_.toLower(
						this.props.t('Email'),
					)}`,
				},
			})
			return false
		}
		//
		this.props.setURLState({
			searchDateFrom: !_.isEmpty(searchDateFrom)
				? moment(searchDateFrom).format('YYYY-MM-DD')
				: '',
			searchDateTo: !_.isEmpty(searchDateFrom)
				? moment(searchDateTo).format('YYYY-MM-DD')
				: '',
			searchDestiny,
			currentPage: 1,
			pageSize: 10,
		})
	}

	handleClearFilter = () => {
		this.setState(
			{
				searchDateFrom: '',
				dateFrom: '',
				searchDateTo: '',
				dateTo: '',
				searchPeriod: '',
				period: '',
				searchDestiny: '',
				pageSize: 10
			},
			() => this.handleFilter(),
		)
	}

	handleGiftFriend = () => {
		if (this.state.generalRulesData.allowGiftCard) {
			this.props.history.push('/giftVoucher/giftFriend')
		} else {
			this.setState({
				snackStatus: {
					open: true,
					severity: 'warning',
					text: 'O vale presente não esta habilitado',
				},
			})
		}
	}

	render() {
		const { t, classes } = this.props
		const {
			disabeldData,
			dateFrom,
			dateTo,
			searchPeriod,
			results,
			recordCount,
			searchDestiny,
			currentPage,
		} = this.state

		return (
			<S.Container>
				<DocumentTitle title={getProgramTitle('Vales presentes enviados')} />
				<div style={{ marginBottom: '40px' }}>
					<Section title={'Vales presentes enviados'}>
						<CustomButtonsBackground
							style={{ width: '190px' }}
							disabled={
								!this.state.generalRulesData.allowGiftCard
							}
							onClick={() =>
								this.props.history.push(
									'/giftVoucher/giftFriend',
								)
							}
						>
							<i className="icon-presentear_amigo" />
							{t('BUTTON_GIFT_FRIEND')}
						</CustomButtonsBackground>
					</Section>

					<SnackDefault
						snackStatus={this.state.snackStatus}
						handleCloseSnack={() =>
							this.setState({
								snackStatus: (prevState) => ({
									...prevState,
									open: false,
								}),
							})
						}
					></SnackDefault>
				</div>

				<S.FilterBox className={classes.filterForm}>
					<S.InputBox>
						<S.InputPeriod>
							<Autocomplete
								id="combo-box-demo"
								size="small"
								options={options}
								getOptionLabel={(option) =>
									option.label ? t(option.label) : ''
								}
								disableClearable={true}
								onChange={(event, value) => this.handleChangePeriod(event, value)}
								value={searchPeriod}
								disablePortal
								renderInput={(params) => (
									<CssTextField
										{...params}
										style={{
											width: '100%',
										}}
										label={t('FIELD_PERIOD')}
										variant="outlined"
										size="small"
									/>
								)}
							/>
						</S.InputPeriod>

						<S.dateBox>
							<S.DivDate>
								<Datetime
									className={styles.dateInputBlock}
									id="searchDateFrom"
									timeFormat={false}
									value={dateFrom}
									disabled={disabeldData}
									onChange={this.handleDateChange.bind(
										null,
										'searchDateFrom',
										'dateFrom',
									)}
									renderInput={(props) => {
										return (
											<CssTextField
												label={t('DATETIME_FROM')}
												variant="outlined"
												margin="dense"
												disabled={disabeldData}
												style={{
													backgroundColor: '#fff',
												}}
												{...props}
												value={
													!_.isEmpty(
														this.state
															.searchDateFrom,
													)
														? props.value
														: ''
												}
												InputProps={{
													endAdornment: (
														<InputAdornment>
															<i className="icon-data" />
														</InputAdornment>
													),
													inputComponent:
														DateMaskCustom,
												}}
											/>
										)
									}}
								/>
							</S.DivDate>

							<S.DivDate>
								<Datetime
									id="searchDateTo"
									timeFormat={false}
									value={dateTo}
									disabled={disabeldData}
									onChange={this.handleDateChange.bind(
										null,
										'searchDateTo',
										'dateTo',
									)}
									renderInput={(props) => {
										return (
											<CssTextField
												label={t('DATETIME_TO')}
												variant="outlined"
												margin="dense"
												style={{
													backgroundColor: '#fff',
												}}
												{...props}
												value={
													!_.isEmpty(
														this.state.searchDateTo,
													)
														? props.value
														: ''
												}
												InputProps={{
													disabled: disabeldData,
													endAdornment: (
														<InputAdornment>
															<i className="icon-data" />
														</InputAdornment>
													),
													inputComponent:
														DateMaskCustom,
												}}
											/>
										)
									}}
								/>
							</S.DivDate>
						</S.dateBox>

						<S.inputDestiny>
							<CssTextField
								fullWidth
								label={`${t('FIELD_DESTINY')} (e-mail)`}
								id="searchDestiny"
								name="searchDestiny"
								value={searchDestiny}
								onChange={this.handleChange}
								variant="outlined"
								margin="dense"
								style={{ backgroundColor: '#fff' }}
								disabled={false}
								InputProps={{
									classes: {
										root: classes.cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.notchedOutline,
									},
								}}
								InputLabelProps={{
									classes: {
										root: classes.label,
										focused: classes.focusedLabel,
										error: classes.erroredLabel,
									},
								}}
							/>
						</S.inputDestiny>
					</S.InputBox>
					<S.BtnFilterContain>
						<CustomButtonsBackground
							onClick={() => this.handleFilter()}
						>
							{t('BUTTON_FIND')}
						</CustomButtonsBackground>
						<CustomButtons onClick={this.handleClearFilter}>
							{t('BUTTON_CLEAN_FILTER')}
						</CustomButtons>
					</S.BtnFilterContain>
				</S.FilterBox>

				{!_.isEmpty(results) ? (
					<S.ContainCards>
						{results.map((item, index) => {
							return (
								<S.CardContain key={index} countPadding={index}>
									<VoucherCard
										anonymo={item?.annonymousSent}
										data={moment(item?.releaseDate).format(
											'DD/MM/YYYY',
										)}
										destiny={item?.consumer.email}
										value={
											item?.value >= 0
												? numberFormatText(
													item?.value,
													this.props
														.generalRulesData
														.currencySymbol
														? this.props
															.generalRulesData
															.currencySymbol +
														' '
														: ' ',
													2,
												)
												: ''
										}
										classes={classes}
									/>
								</S.CardContain>
							)
						})}
					</S.ContainCards>
				) : (
					<GridContainer
						justify="center"
						style={{ padding: '30px 0' }}
					>
						<GridItem xs={12} md={4}>
							{t('RESULTS_NOT_FOUND')}
						</GridItem>
					</GridContainer>
				)}
				<div style={{ marginTop: '20px' }}>
					{!_.isEmpty(results) && (
						<div
							style={{
								textAlign: 'right',
								marginTop: '10px',
								width: '100%',
							}}
						>
							<MaterialTable
								onChangePage={(e) => this.handleSearch(e + 1)}
								onChangeRowsPerPage={(e) => this.handleSearchPage(e)}
								title=""
								data={results}
								totalCount={recordCount}
								page={currentPage - 1}
								options={{
									sorting: false,
									emptyRowsWhenPaging: false,
									toolbar: false,
									actionsColumnIndex: -1,
									search: false,
									draggable: false,
									pageSize: _.get(this.props.urlState, 'pageSize', '') || 10,
									pageSizeOptions: [10, 20, 30, 40, 50, 100],
								}}
								localization={{
									body: {
										emptyDataSourceMessage: (
											<S.EmptyTable>
												<i className="icon-alerta_modal" />
												<span>
													{t('No records to display')}
												</span>
											</S.EmptyTable>
										),
									},
									pagination: {
										labelRowsSelect: `${t('lines')}`,
										//labelDisplayedRows: '{count} de {from}-{to}',
										firstTooltip: ` ${t('First page')}`,
										previousTooltip: `${t('Previous page')}`,
										nextTooltip: `${t('Next page')}`,
										lastTooltip: `${t('Last page')}`,
									},
								}}
							/>
						</div>
					)}
				</div>
			</S.Container>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		userData: state.users.userData,
		partnersData: state.partners.partnersData,
		partnersDataFailed: state.partners.partnersDataFailed,
		generalRulesData: state.generalRules.generalRulesData,

		giftCardData: state.users.giftCardData,
		giftCardError: state.users.giftCardError,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetPartnersData,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
			GetSentGiftCard,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	connectRouterState({
		searchPeriod: '',
		searchDateFrom: '',
		searchDateTo: '',
		searchDestiny: '',
		currentPage: 1,
		pageSize: 10,
	}),
	withTranslation(),
	withStyles(sentStyle),
)(Sent)
