import React from 'react'
import Home from 'pages/Home'
import Category from 'pages/Category'
import Statement from 'pages/Statement'
import Negotiation from 'pages/Negotiation'
import Friends from 'pages/Friends'
import Program from 'pages/Program'
import TransferRequest from 'pages/TransferRequest'

import VoucherSent from 'pages/GiftVoucher/Sent'
import VoucherReceived from 'pages/GiftVoucher/Received'
import VoucherGiftFriend from 'pages/GiftVoucher/GiftFriend'

import Regulation from 'pages/Regulation'
import Notification from 'pages/Notification'
import UseTerms from 'pages/UseTerms'
import Profile from 'pages/User/Profile'
import accountPreferences from 'pages/User/AccountPreferences'
import ChangePassword from 'pages/User/ChangePassword'
import { ShopHistory, ShopHistoryDetail } from 'pages/ShopHistory'
import PointsRequest from 'pages/PointsRequest'
import Wallet from 'pages/DigitalWallet'
import { allowIndications } from 'utils/permissions'
import NotificationCenter from '../NotificationCenter'
import PrivacyPolicy from '../PrivacyPolicy'
import { NegotiationDetails } from '../NegotiationDetails'
import { MenuConvertIcon, MenuNegotiation } from '../../components/storybook'
import TransferChild from '../Transfer'
import ScoringRulesChild from '../ScoringRules'

const IsAllowIndications = allowIndications()
const IsAllowStatus = localStorage.getItem('showNegotiations') ?? false
// const IsAllowTransfer = localStorage?.allowPointsConversion === 'true'

export const dashRoutes = [
    {
        id: 'home',
        path: '/home',
        name: 'MENU_HOME',
        icon: 'icon-home',
        component: Home,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: 'notification',
        path: '/notification',
        name: 'MENU_NOTIFICATION',
        icon: 'icon-ico_aviso',
        component: Notification,
        layout: '/admin',
        sidebar: false,
    },
    {
        id: 'statment',
        path: '/statement',
        name: 'MENU_STATEMENT',
        icon: 'icon-extrato',
        component: Statement,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: 'negotiation',
        path: '/negotiation',
        name: 'Negociações',
        svgIcon: (color) => (
            <MenuNegotiation size={20} color={color ?? '#fff'} />
        ),

        component: Negotiation,
        layout: '/admin',
        sidebar: IsAllowStatus,
    },
    {
        id: 'negotiationDetails',
        path: '/negotiationDetails/:id',
        name: 'Negociações',
        icon: 'icon-extrato',
        component: NegotiationDetails,
        layout: '/admin',
        sidebar: false,
    },

    {
        id: 'transferRequest',
        focus: '/transferRequest',
        path: '/transferRequest/detail/:id',
        name: 'Solicitar transferência',
        icon: 'icon-config_saque',
        component: TransferChild,
        layout: '/admin',
        sidebar: true,
    },

    {
        id: '/shophistory',
        focus: '/shopHistory',
        path: '/shopHistory/detail/:id',
        name: 'MENU_HISTORY',
        icon: '',
        component: ShopHistoryDetail,
        layout: '/admin',
        sidebar: false,
    },

    {
        id: 'shophistory',
        path: '/shopHistory',
        name: 'MENU_HISTORY',
        icon: 'icon-historico_compras',
        component: ShopHistory,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: '/scoringRules',
        path: '/scoringRules',
        name: 'Converter meus pontos',
        component: ScoringRulesChild,
        layout: '/admin',
        sidebar: true,
        svgIcon: (color) => (
            <MenuConvertIcon size={20} color={color ?? '#fff'} />
        ),
    },

    {
        id: 'pointsrequest',
        path: '/pointsRequest',
        name: 'MENU_REWARD_REQUEST',
        icon: 'icon-solicitar_pontos',
        component: PointsRequest,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: 'friends',
        path: '/friends',
        name: 'MENU_FRIENDS',
        icon: 'icon-indicar_amigo',
        component: Friends,
        layout: '/admin',
        sidebar: IsAllowIndications,
    },
    // {
    //     id: 'research',
    //     path: '/research',
    //     name: 'MENU_RESEARCH',
    //     icon: 'icon-pesquisa',
    //     component: Construction,
    //     layout: '/admin',
    //     sidebar: true,
    // },
    {
        id: 'tiers',
        path: '/Tiers',
        name: 'SECTION_TIERS',
        icon: 'icon-niveis',
        component: Category,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: 'notification_center',
        path: '/notificationcenter',
        name: 'Central de notificações',
        icon: 'icon-ico_notificacoes',
        component: NotificationCenter,
        layout: '/admin',
        sidebar: true,
    },
    {
        collapse: true,
        id: 'giftVouchers',
        name: 'MENU_GIFT_VOUCHERS',
        sidebar: true,
        icon: 'icon-vale_presente',
        state: 'pageCollapseMngt',

        views: [
            {
                id: 'giftVouchers',
                path: '/giftVoucher/sent',
                requiresDigitalWallet: true,
                name: 'MENU_GIFT_VOUCHERS_SENT',
                icon: 'icon-presente_enviado',
                iconSmall: true,
                component: VoucherSent,
                layout: '/admin',
                sidebar: true,
            },
            {
                id: 'giftVouchers',
                path: '/giftVoucher/received',
                requiresDigitalWallet: true,
                name: 'MENU_GIFT_VOUCHERS_RECEIVED',
                icon: 'icon-presente_recebido',
                iconSmall: true,
                component: VoucherReceived,
                layout: '/admin',
                sidebar: true,
            },
            {
                id: 'giftVouchers',
                path: '/giftVoucher/giftFriend',
                requiresDigitalWallet: true,
                name: 'MENU_GIFT_FRIEND',
                sidebar: false,
                icon: 'icon-presente_recebido',
                iconSmall: true,
                layout: '/admin',
                component: VoucherGiftFriend,
            },
        ],
    },

    {
        id: 'program',
        path: '/program',
        name: 'MENU_PROGRAM',
        icon: 'icon-programa',
        component: Program,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: 'regulation',
        path: '/regulation',
        name: 'MENU_REGULATION',
        icon: 'icon-regulamento',
        component: Regulation,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: 'useterms',
        path: '/useterms',
        name: 'TITLE_PLATFORM_RULES_USE_TERMS_AND_POLICY',
        icon: 'icon-termos',
        component: UseTerms,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: 'privacypolicy',
        path: '/privacypolicy',
        name: 'TITLE_PRIVACY_POLICY',
        icon: 'icon-privacidade',
        component: PrivacyPolicy,
        layout: '/admin',
        sidebar: true,
    },
    {
        id: '/profile',
        path: '/profile',
        name: 'MENU_PROFILE',
        icon: '',
        component: Profile,
        layout: '/admin',
        sidebar: false,
    },
    {
        id: '/accountPreferences',
        path: '/accountPreferences',
        name: 'MENU_USER_ACCOUNT_PREFERENCES',
        icon: '',
        component: accountPreferences,
        layout: '/admin',
        sidebar: false,
    },
    {
        id: '/changepassword',
        path: '/changepassword',
        name: 'MENU_USER_EDIT_PASSWORD',
        icon: '',
        component: ChangePassword,
        layout: '/admin',
        sidebar: false,
    },
    {
        id: '/wallet',
        path: '/wallet',
        name: 'MENU_USER_EDIT_PASSWORD',
        icon: '',
        component: Wallet,
        layout: '/admin',
        sidebar: false,
    },
]
