import styled from 'styled-components'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const Container = styled.div`
    align-items: center;
    height: 100%;
    max-width: 1230px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 38px 0 10px !important;
`
export const Logo = styled.div`
    img {
        width: 200px;
    }
`

export const LogoBox = styled.div`
    cursor: pointer;
    flex-grow: 3;
`
export const UserBox = styled.div`
    display: none;
    border-radius: 4px;
    flex-grow: 0;
    height: 55px;
    cursor: pointer;
    background-color: #ffffff;
    color: ${GetThemeColorFromStorage()?.titles?.main?.textColor};
    width: 250px;
    @media only screen and (min-width: 960px) {
        display: block;
    }
`

export const Parent = styled.div`
    background-image: url(${({ themeColor }) =>
        themeColor?.images?.internalPages?.menuBackground?.url});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 170px;
    color: #000;
    padding: 20px;
    margin: auto;
    width: 100%;
    top: 0px;
    .userBox {
        flex-grow: 0;
        display: flex;
        height: 55px;
        cursor: pointer;
        align-items: center;
        background-color: #ffff;
        float: right;
        color: ${GetThemeColorFromStorage()?.titles?.main?.textColor};
        max-width: 250px;
        font-size: 12px;
    }

    .userBox {
        font-size: 16px;
        vertical-align: middle;
        font-weight: bold;
    }
`

export const NotificationBox = styled.div`
    display: flex;
    .MuiBadge-anchorOriginTopRightRectangle {
        font-family: sans-serif;
        font-weight: bold;
        color: #333333;
        background-color: #fbbf46;
    }
`
export const NotificationContain = styled.div`
    border-radius: 4px;
    display: flex;
`
export const RightBox = styled.div`
    display: flex;
    gap: 15px;
`
