
export const PlatformRules = [
	{
		id: "acceptedCurrentRegulation",
		title: 'Regulamento',
		content: 'REGULATION_TERMS_ACCEPT_LABEL_MESSAGE'
	},
	{
		id: "acceptedCurrentUseTerms",
		title: 'Termos de uso',
		content: 'USE_TERMS_ACCEPT_LABEL_MESSAGE'
	},
	{
		id: "acceptedCurrentPrivacyPolicies",
		title: 'TITLE_PRIVACY_POLICY',
		content: 'Declaro que li e estou ciente das condições da política de privacidade'
	},
]

