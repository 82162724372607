import React, { useEffect } from 'react'
import {
    AlertSuccessIcon,
    GridContainer,
    GridItem,
    MuiBox,
    MuiTypography,
} from '../../../../components/storybook'
import { handleDescriptionSuccess } from '../../functions'

export const SuccessSearch = ({ dataSurveyDetails }) => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = '/home'
        }, 3000)
    }, [])

    return (
        <GridContainer display={'flex'} justifyContent={'center'} mt={3}>
            <GridItem lg={12} xs={12} md={12} sm={12}>
                <MuiBox display={'flex'} justifyContent={'center'}>
                    <AlertSuccessIcon size={60} color="#00A859" />
                </MuiBox>
                <MuiTypography mt={3} textAlign="center" fontWeight={600}>
                    PESQUISA ENVIADA COM SUCESSO!
                </MuiTypography>
                <MuiTypography
                    mt={1}
                    textAlign="center"
                    fontWeight={600}
                    fontSize="14px"
                >
                    {handleDescriptionSuccess(dataSurveyDetails)}
                </MuiTypography>
            </GridItem>
        </GridContainer>
    )
}
