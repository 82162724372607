import axios from 'axios'
import { FETCH_UNAUTH_USER } from '../redux/actions/actionsTypes'
import { userService } from '../redux/services'
import { history } from './index'

export default {
    setupInterceptors: (store) => {
        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                return response
            },
            function (error) {
                console.error('axios.interceptors: ', error)
                //catches if the session ended!

                //pra conseguir testar sem dar erro de undefined
                // if (history.location.pathname !== '/register' && error && error.response && error.response && error.response.status === 401) {
                if (
                    error &&
                    error?.response &&
                    error?.response?.status === 401
                ) {
                    console.error(
                        'Network-service: ',
                        error?.response?.statusText,
                    )
                    userService.logout()
                    // window.location.reload();
                    store.dispatch({ type: FETCH_UNAUTH_USER })
                }
                // else if (error.response.data.token.KEY ==='ERR_EXPIRED_TOKEN') {
                //     console.log("EXPIRED TOKEN!");
                //     localStorage.clear();
                //     store.dispatch({ type: FETCH_UNAUTH_USER });
                // };
                if (error?.response) {
                    // Request made and server responded
                    console.log('--- RESPONSE ---')
                    const { data } = error?.response
                    console.log(`DATA: ${data}`)
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('--- REQUENT ---')
                    console.log(error?.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('--- MESSAGE ---')
                    console.log('Error', error?.message)
                }
                // return Promise.reject(error);
            },
        )
    },
}
