import {
    dangerColor,
    roseColor,
    grayColor,
    blackColor,
    whiteColor,
    hexToRgb,
    germiniDefaultColors,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

const customCheckboxRadioSwitch = {
    checkRoot: {
        padding: '14px',
    },
    radioRoot: {
        padding: '16px',
    },
    checkboxAndRadio: {
        position: 'relative',
        display: 'block',
        marginTop: '10px',
        marginBottom: '10px',
    },
    checkboxAndRadioHorizontal: {
        position: 'relative',
        display: 'block',
        '&:first-child': {
            marginTop: '10px',
        },
        '&:not(:first-child)': {
            marginTop: '-14px',
        },
        marginTop: '0',
        marginBottom: '0',
    },
    checked: {
        color:
            GetThemeColorFromStorage()?.navigation?.buttons?.elementsColor +
            '!important',
    },
    checkedIcon: {
        width: '20px',
        height: '20px',
        border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
        borderRadius: '3px',
    },
    uncheckedIcon: {
        width: '0px',
        height: '0px',
        padding: '9px',
        border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
        borderRadius: '3px',
    },
    disabledCheckboxAndRadio: {
        '& $checkedIcon,& $uncheckedIcon,& $radioChecked,& $radioUnchecked': {
            borderColor: blackColor,
            opacity: '0.26',
            color: blackColor,
        },
    },
    label: {
        cursor: 'pointer',
        paddingLeft: '0',
        color: germiniDefaultColors[5],
        fontSize: '15px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        display: 'inline-flex',
        transition: '0.3s ease all',
    },
    labelHorizontal: {
        color: germiniDefaultColors[5],
        cursor: 'pointer',
        display: 'inline-flex',
        fontSize: '15px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        paddingTop: '39px',
        marginRight: '0',
        '@media (min-width: 992px)': {
            float: 'left', //#FormLabel
        },
    },
    labelHorizontalRadioCheckbox: {
        paddingTop: '22px',
    },
    labelLeftHorizontal: {
        color: 'rgba(' + hexToRgb(blackColor) + ', 0.8)',
        cursor: 'pointer',
        display: 'inline-flex',
        fontSize: '14px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        paddingTop: '22px',
        marginRight: '0',
    },
    labelError: {
        color: dangerColor[0],
    },
    radio: {
        color:
            GetThemeColorFromStorage()?.navigation?.buttons?.elementsColor +
            '!important',
    },
    radioChecked: {
        width: '16px',
        height: '16px',
        border:
            '1px solid ' +
            GetThemeColorFromStorage()?.navigation?.buttons?.elementsColor,
        borderRadius: '50%',
    },
    radioUnchecked: {
        width: '0px',
        height: '0px',
        padding: '7px',
        border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
        borderRadius: '50%',
    },
    inlineChecks: {
        marginTop: '8px',
    },
    iconCheckbox: {
        height: '116px',
        width: '116px',
        color: grayColor[0],
        padding: '0',
        margin: '0 auto 20px',
        '& > span:first-child': {
            borderWidth: '4px',
            borderStyle: 'solid',
            borderColor: grayColor[9],
            textAlign: 'center',
            verticalAlign: 'middle',
            borderRadius: '50%',
            color: 'inherit',
            transition: 'all 0.2s',
        },
        '&:hover': {
            color: '#ff0000',
            '& > span:first-child': {
                borderColor: roseColor[0],
            },
        },
    },
    iconCheckboxChecked: {
        color: roseColor[0],
        '& > span:first-child': {
            borderColor: roseColor[0],
        },
    },
    iconCheckboxIcon: {
        fontSize: '40px',
        lineHeight: '111px',
    },
    switchBase: {
        color:
            GetThemeColorFromStorage()?.navigation?.buttons?.elementsColor +
            '!important',
        opacity: '1 !important',
    },
    switchIcon: {
        color: whiteColor + ' !important',
        border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
        transform: 'translateX(-4px)!important',
    },
    switchIconChecked: {
        borderColor:
            GetThemeColorFromStorage()?.navigation?.buttons?.elementsColor,
        transform: 'translateX(0px)!important',
    },
    switchBar: {
        width: '30px',
        height: '15px',
        backgroundColor: 'rgb(' + hexToRgb(grayColor[18]) + ')',
        borderRadius: '15px',
        opacity: '0.7!important',
    },
    switchChecked: {
        '& + $switchBar': {
            backgroundColor:
                'rgba(' +
                GetThemeColorFromStorage()?.navigation?.buttons?.elementsColor +
                ', 1) !important',
        },
        '&$disabled': {
            opacity: '1',
            '& + $switchBar': {
                backgroundColor:
                    'rgba(' + hexToRgb(grayColor[18]) + ', 1) !important',
            },
            '& > span > span': {
                borderColor: 'rgba(' + hexToRgb(grayColor[18]) + ', 1)',
            },
        },
    },
    disabled: {},
}

export default customCheckboxRadioSwitch
