import React from 'react'
import DocumentTitle from 'react-document-title'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section/Section'
import compose from 'utils/compose'
import {
	GetProgramDescription,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from 'redux/actions'

import styled from 'styled-components/macro'
import { getProgramTitle } from '../../utils/utils'

const ContentProgram = styled.div`
    max-width: 100%;
    img {
        max-width: 100%;
    }
`
class Program extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			description: '',
			user: {},
			createdAt: '',
			updatedAt: '',
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		if (
			this.props.programDescriptionData &&
			this.props.programDescriptionData.id
		) {
			this.setState({ ...this.props.programDescriptionData }, () =>
				this.props.HideLoader(),
			)
		} else {
			this.props.GetProgramDescription()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.programDescriptionData !==
			this.props.programDescriptionData
		) {
			this.setState({ ...this.props.programDescriptionData }, () =>
				this.props.HideLoader(),
			)
		}
	}

	render() {
		const { t } = this.props

		return (
			<Grid>
				<DocumentTitle title={getProgramTitle(t('NAVPILLS_PROGRAM'))} />
				<Section title={t('MENU_PROGRAM')} />
				<ContentProgram
					dangerouslySetInnerHTML={{ __html: this.state.description }}
				/>
			</Grid>
		)
	}
}

const mapStateToProps = (state) => ({
	programDescriptionData: state.generalRules.programDescriptionData,
	programDescriptionDataFailed:
		state.generalRules.programDescriptionDataFailed,
})

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetProgramDescription,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation(),
)(Program)
