import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import { useTranslation } from 'react-i18next'

import CustomPagination from 'components/CustomPagination/CustomPagination.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

import { numberFormatText } from 'utils/utils'
import VoucherCard from './VoucherCard'
import * as S from './styles.js'
import MaterialTable from 'material-table'

const GiftList = ({
    classes,
    currencySymbol,
    currentPage,
    destaqueIds,
    // entryType,
    handleSearch,
    handleSearchPage,
    handleUsedButton,
    pageSize,
    recordCount,
    results,
}) => {
    const { t } = useTranslation()
    const [myResults, setMyResults] = useState([])

    useEffect(() => {
        let newResults = []
        if (destaqueIds !== undefined) {
            if (destaqueIds.length > 0 && results.length > 0) {
                results.forEach((item) => {
                    if (destaqueIds.includes(item?.id)) {
                        newResults.push(item)
                    }
                })

                results.forEach((item) => {
                    if (!destaqueIds.includes(item?.id)) {
                        newResults.push(item)
                    }
                })
            } else {
                newResults = results
            }
        }
        setMyResults(newResults)
    }, [destaqueIds, results])

    return (
        <div>
            {!_.isEmpty(myResults) ? (
                <S.ContainCards>
                    {myResults.map((item) => {
                        return (
                            <S.CardContain key={item.id}>
                                <VoucherCard
                                    receivedData={moment(
                                        item?.processingDate,
                                    ).format('DD/MM/YYYY')}
                                    dueDate={
                                        item?.expirationDate
                                            ? moment(
                                                  item?.expirationDate,
                                              ).format('DD/MM/YYYY')
                                            : '-'
                                    }
                                    value={
                                        item?.value?.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                        }) || ''
                                    }
                                    debit={item?.valueRedeemed}
                                    origin={item?.originConsumer?.name}
                                    parcialUtilized={item?.parcialUtilized}
                                    anonymous={item?.annonymousSent}
                                    entryType={item?.entryType?.key}
                                    isInactive={item?.isInactive}
                                    handleUsedButton={handleUsedButton}
                                    classes={classes}
                                    usedConsumerDigitals={
                                        item?.usedConsumerDigitals
                                    }
                                />
                            </S.CardContain>
                        )
                    })}
                </S.ContainCards>
            ) : (
                <GridContainer justify="center" style={{ padding: '30px 0' }}>
                    <GridItem xs={12} md={4}>
                        {t('RESULTS_NOT_FOUND')}
                    </GridItem>
                </GridContainer>
            )}
            {/* Pagination */}
            <div style={{ marginTop: '20px' }}>
                {!_.isEmpty(results) && (
                    <div
                        style={{
                            textAlign: 'right',
                            marginTop: '10px',
                            width: '100%',
                        }}
                    >
                        <MaterialTable
                            onChangePage={(e) => handleSearch(e + 1)}
                            onChangeRowsPerPage={(e) => handleSearchPage(e)}
                            title=""
                            data={myResults}
                            totalCount={recordCount}
                            page={currentPage - 1}
                            options={{
                                sorting: false,
                                emptyRowsWhenPaging: false,
                                toolbar: false,
                                actionsColumnIndex: -1,
                                search: false,
                                draggable: false,
                                pageSize: pageSize || 10,
                                pageSizeOptions: [10, 20, 30, 40, 50, 100],
                            }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: (
                                        <S.EmptyTable>
                                            <i className="icon-alerta_modal" />
                                            <span>
                                                {t('No records to display')}
                                            </span>
                                        </S.EmptyTable>
                                    ),
                                },
                                pagination: {
                                    labelRowsSelect: `${t('lines')}`,
                                    //labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: ` ${t('First page')}`,
                                    previousTooltip: `${t('Previous page')}`,
                                    nextTooltip: `${t('Next page')}`,
                                    lastTooltip: `${t('Last page')}`,
                                },
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default GiftList
