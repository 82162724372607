import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { envs } from '../../redux/api/windowServerData'
import { MuiBox } from 'components/storybook'
import Footer from 'components/Footer'
import Header from './Header'
export function PolyCyPage() {
	const [policyData, setPolicyData] = useState()

	async function getPolicyData() {
		const BASE_URL = envs.REACT_APP_GERMINI_API_URL
		const response = await axios.get(
			`${BASE_URL}/ProgramRules/PrivacyPolicies`,
		)

		setPolicyData(response.data)
	}

	useEffect(() => {
		getPolicyData()
	}, [])

	return (
		<MuiBox
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: '40px',
				minHeight: '100vh',
			}}
		>
			<Header />
			<MuiBox
				sx={{
					flex: 1,
					maxWidth: '1138px',
					width: '100%',
				}}
			>
				<span
					dangerouslySetInnerHTML={{
						__html: policyData?.privacyPolicies,
					}}
				/>
			</MuiBox>
			<Footer />
		</MuiBox>

	)
}
