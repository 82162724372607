import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import Login from './components/Login/index.jsx'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import authStyle from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx'
// Utils
import compose from 'utils/compose'
import {
    GetThemeFromAPILogin,
    SetThemeIntoStorage,
} from './../../redux/api/themeColor'
class Auth extends React.Component {
    state = {
        forceUpdateEffect: false,
        themeColor: {},
    }

    getTheme() {
        const theme = GetThemeFromAPILogin()

        this.setState({
            forceUpdateEffect: true,
            themeColor: theme,
        })
        SetThemeIntoStorage(theme)
    }
    componentDidMount() {
        this.getTheme()
    }

    render() {
        const { classes } = this.props
        return (
            <div
                className={classes.wrapper}
                ref="wrapper"
                style={{
                    border: `${
                        this.forceUpdateEffect ? '2px' : '1px'
                    } solid transparent`,
                }}
            >
                <Route path="/auth/login" component={Login} />
            </div>
        )
    }
}

Auth.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default compose(withStyles(authStyle))(Auth)
