import React from 'react'
import { withTranslation } from 'react-i18next'
import Alert from 'react-bootstrap-sweetalert'
//import propTypes from "prop-types";
import * as _ from 'lodash'

// UTILS
import compose from 'utils/compose'
import Loader from 'components/Loader/Loader.jsx'
import withStyles from '@material-ui/core/styles/withStyles'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	GetCurrentConsumer,
	changePassword,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from '../../../redux/actions'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Form from './Component/ChangePassword'

// MOMENT JS
import moment from 'moment';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { formatCellAndTell, FormatCPF } from '../../../utils/utils'
import { Container } from '@material-ui/core'

class ChangePassword extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			id: '',
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
			cpf: '',
			dateOfBirth: '',
			phoneNumber: '',
			phoneNumber2: '',
			validationSchema: '',
			themeColor: GetThemeColorFromStorage(),
		}
		if (localStorage.getItem('i18nextLng') !== null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	componentDidMount() {
		if (_.isEmpty(this.props.userData)) {
			this.props.GetCurrentConsumer()
		} else {
			this.setState({ id: this.props.user.id })
			this.setState(
				{
					cpf: this.props.userData.cpf,
					dateOfBirth: this.props.userData.dateOfBirth,
					phoneNumber: this.props.userData.phoneNumber,
					phoneNumber2: this.props.userData.phoneNumber2,
				},
				() => {
					let cpf = this.props.userData.cpf
					let dateOfBirth = this.props.userData.dateOfBirth
					let phoneNumber = this.props.userData.phoneNumber
					let phoneNumber2 = this.props.userData.phoneNumber2

					let message1 =
						'Senha não pode estar contida em CPF/CNPJ, DATA DE NASCIMENTO, CEL OU TEL'
					let message2 = 'Não é permitido ter 3 números repetidos'
					let message3 = 'Não deve conter mais de 3 números sequenciais'
					let message4 =
						'A senha deve conter letras, números e caracteres especiais'

					this.setState({
						validationSchema: Yup.object({
							oldPassword: Yup.string().required(
								'Senha antiga é obrigatório',
							),
							newPassword: Yup.string()
								.min(8, 'mínimo de 8 caracteres')
								.max(20, 'máximo de 20 caracteres')
								.test(
									'password policy test',
									message1,
									function (item) {
										if (item) {
											if (
												(dateOfBirth &&
													item.includes(moment(dateOfBirth)
														.format('DD/MM/YYYY'))) ||
												(dateOfBirth &&
													item.includes(moment(dateOfBirth)
														.format('DD/MM/YY'))) ||
												(dateOfBirth &&
													item.includes(moment(dateOfBirth)
														.format('DD.MM.YYYY'))) ||
												(dateOfBirth &&
													item.includes(moment(dateOfBirth)
														.format('DD.MM.YY'))) ||
												(dateOfBirth &&
													item.includes(moment(dateOfBirth)
														.format('MM/DD/YYYY'))) ||
												(dateOfBirth &&
													item.includes(moment(dateOfBirth)
														.format('MM/DD/YY'))) ||
												(dateOfBirth &&
													item.includes(moment(dateOfBirth)
														.format('YYYY/MM/DD'))) ||
												(cpf &&
													item
														?.includes(FormatCPF(cpf))) ||
												(phoneNumber2 &&
													item
														?.replace(' ', '')
														.includes(formatCellAndTell(phoneNumber2)?.replace(' ', '')))
											) {
												return false
											}
										}
										return true
									},
								)
								.test(
									'repetition test',
									message2,
									function (item) {
										var re = /(\d)\1\1/g
										if (re.test(item)) {
											return false
										}
										return true
									},
								)
								.test(
									'sequantial test',
									message3,
									function (item) {
										var re =
											/(?:(?=0[1-2-3]|1[2-3-4]|2[3-4-5]|3[4-5-6]|4[5-6-7]|5[6-7-8]|6[7-8-9])\d){3}/
										if (re.test(item) || item.includes('6789')) {
											return false
										}
										return true
									}
								)
								.test(
									'special test',
									message4,
									function (item) {
										var re =
											/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])/g
										if (!re.test(item)) {
											return false
										}
										return true
									},
								),

							confirmPassword: Yup.string()
								.oneOf(
									[Yup.ref('newPassword'), null],
									'Campos senha nova não batem.',
								)
								.required('Confirme a nova senha'),
						}),
					})
				},
			)
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.userData !== this.props.userData) {
			this.setState({ id: this.props.user.sub })
		}

		if (
			this.props.changePasswordData &&
			prevProps.changePasswordData !== this.props.changePasswordData
		) {
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title={null}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
						this.props.history.push(`/auth/login`)
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_SUCCESS')}
				</Alert>,
			)
			this.formik.current && this.formik.current.resetForm()
		}

		if (
			this.props.changePasswordFailed &&
			prevProps.changePasswordFailed !== this.props.changePasswordFailed
		) {
			this.props.SweetAlert(
				<Container themeColor={this.state.themeColor}>
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={'Atenção!'}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.HideLoader()
						}}
						confirmBtnCssClass={
							this.props.classes.button +
							' ' +
							this.props.classes.greenButtonColor
						}
						confirmBtnText={'OK'}
					>
						{_.isArray(this.props.changePasswordFailed) ? (
							this.props.changePasswordFailed.map((error) => [
								<div>{this.props.t(error.message)}</div>,
							])
						) : (
							<div>
								{this.props.t(this.props.changePasswordFailed.message?.replace('.', '')
								) || this.props.t("ERROR_OPERATION")}
							</div>
						)}
					</Alert>
				</Container>

				,
			)
		}
	}

	handleSave = (values) => {
		this.props.ShowLoader(<Loader />)
		this.props.changePassword(this.state.id, values)
	}

	render() {
		const { oldPassword, newPassword, confirmPassword } = this.state;
		const values = { oldPassword, newPassword, confirmPassword };

		return (
			<Formik
				ref={this.formik}
				render={(props) => <Form {...props} />}
				validationSchema={this.state.validationSchema}
				initialValues={values}
				onSubmit={this.handleSave.bind(this)}
			/>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,
		userData: state.users.userData,
		changePasswordData: state.authentication.changePassword,
		changePasswordFailed: state.authentication.changePasswordFailed,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetCurrentConsumer,
			changePassword,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(extendedFormsStyle),
	withTranslation(),
)(ChangePassword)
