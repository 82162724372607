import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'

// @ Components
import SnackDefault from 'components/SnackDefault'
import Loader from 'components/Loader/Loader.jsx'

//components
import Button from 'components/CustomButtons/Button.jsx'
import { CustomCheckbox } from 'components/CustomCheckbox'
import DocumentTitle from 'react-document-title'
import Section from 'components/Section/Section'

// @ Styles
import { Container } from './styles'

// @ Mui
import { Box } from '@mui/material'
import {
    GetPlatformRules,
    SetConsumerPlatformRules,
} from '../../redux/api/platformRules'
import { GetCurrentConsumer } from '../../redux/api/user'
import moment from 'moment'
import { getProgramTitle } from '../../utils/utils'

function UseTerms({ customButton }) {
    const { t } = useTranslation()
    const [terms, setTerms] = useState({})
    const [consumerAgreements, setConsumerAgreements] = useState({})
    const [consumer, setConsumer] = useState({})
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(true)

    const [snackStatus, setSnackStatus] = useState({
        open: false,
        text: '',
        severity: 'success',
    })

    const getPlatformRules = () => {
        setLoading(true)
        GetPlatformRules()
            .then((response) => {
                if (response) {
                    setTerms(response)
                    setConsumerAgreements(response?.consumerAgreements)
                }
            })
            .finally(() => setLoading(false))
    }

    const GetConsumer = () => {
        setLoading(true)
        GetCurrentConsumer()
            .then((response) => {
                if (response) setConsumer(response)
            })
            .finally(() => setLoading(false))
    }

    const AcceptancePlatformRules = async () => {
        setLoading(true)
        try {
            const response = await SetConsumerPlatformRules({
                consumerId: consumer?.id || '',
                platformRulesId: terms.id,
            })

            if (response) {
                const { errors } = response
                if (errors?.length > 0) {
                    setSnackStatus({
                        open: true,
                        text: t(errors[0].message),
                        severity: 'error',
                    })
                    return
                }

                setSnackStatus({
                    open: true,
                    text: 'Termos de uso aceitos com sucesso',
                    severity: 'success',
                })
                getPlatformRules()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const getDateFormat = (user) => {
        if (user?.platformRules?.length > 0) {
            const { platformRules } = user
            const useTerms = platformRules?.filter(
                (item) => item.platformRulesId === terms?.id,
            )

            return useTerms?.length > 0
                ? moment(useTerms[0].createdAt).format('DD/MM/YYYY HH:mm')
                : '-- --'
        }
        return '-- --'
    }

    useEffect(() => {
        getPlatformRules()
        GetConsumer()
    }, [])

    return (
        <Container>
            <DocumentTitle
                title={getProgramTitle(
                    t('TITLE_PLATFORM_RULES_USE_TERMS_AND_POLICY'),
                )}
            />
            {loading ? (
                <Loader />
            ) : (
                <>
                    <SnackDefault
                        snackStatus={snackStatus}
                        handleCloseSnack={() =>
                            setSnackStatus((prevState) => ({
                                ...prevState,
                                open: false,
                            }))
                        }
                    />

                    <Section
                        title={t('TITLE_PLATFORM_RULES_USE_TERMS_AND_POLICY')}
                        children={customButton}
                    />
                    <Typography
                        component="div"
                        dangerouslySetInnerHTML={{ __html: terms?.useTerms }}
                    />
                    <Box
                        bgcolor="#F4F4F4"
                        borderRadius="6px"
                        padding="10px 20px"
                        mt="20px"
                    >
                        {consumerAgreements?.acceptedCurrentUseTerms ? (
                            <Box
                                textAlign="left"
                                font="normal normal normal 14px/21px Roboto"
                                letterSpacing="0px"
                                color="#333333"
                            >
                                Termos e condições do programa aceito -{' '}
                                {getDateFormat(consumer)}
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    gap="15px"
                                >
                                    <CustomCheckbox
                                        style={{ padding: '0px' }}
                                        checked={checked}
                                        onChange={() => setChecked(!checked)}
                                    />
                                    <Typography component="div">
                                        Declaro que li e estou ciente das
                                        condições dos termos e condições do
                                        programa
                                    </Typography>
                                </Box>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="greenButtonColor"
                                    size="sm"
                                    style={{
                                        boxShadow: 'none',
                                        margin: '0px',
                                    }}
                                    onClick={() => AcceptancePlatformRules()}
                                    disabled={!checked}
                                >
                                    {t('BUTTON_CONFIRM')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </Container>
    )
}

export default UseTerms
