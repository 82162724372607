import { useCallback, useState } from 'react'
import NegotiationDetailsService from '../Services/NegotiationDetailsService'

export default function useNegotiationDetails() {
    const [isLoadingDetails, setIsLoadingDetails] = useState(false)
    const [isLoadingDetailsTable, setIsLoadingDetailsTable] = useState(false)
    const [negotiationDetails, setNegotiationDetails] = useState([])
    const [negotiationDetailsTable, setNegotiationDetailsTable] = useState([])
    const [tablePagination, setTablePagination] = useState({
        page: 1,
        pageSize: 5,
        totalPages: 1,
    })

    const service = new NegotiationDetailsService()

    async function getNegotiationDetailsById(id) {
        setIsLoadingDetails(true)
        try {
            const response = await service.getDetailsById(id)
            setNegotiationDetails(response?.data)
        } catch (error) {
            console.error('Erro ao obter detalhes da negociação', error)
        } finally {
            setIsLoadingDetails(false)
        }
    }

    async function getNegotiationTableById(id) {
        setIsLoadingDetailsTable(true)
        try {
            const response = await service.getDetailsTableById(id)
            if (response) {
                setNegotiationDetailsTable(response)

                setTablePagination({
                    page: response?.currentPage,
                    pageSize: response?.pageSize,
                    totalPages: response?.pageCount,
                    totalItems: negotiationDetailsTable?.totalItems || 0,
                })
            }
        } catch (error) {
            console.error('Erro ao obter detalhes da negociação', error)
        } finally {
            setIsLoadingDetailsTable(false)
        }
    }

    const getPaginatedNegotiations = useCallback(
        async (id, key, value, tablePagination) => {
            setIsLoadingDetailsTable(true)

            let filter = {
                ...tablePagination,
            }
            if (key === 'page') {
                filter.page = value
            } else {
                filter.pageSize = value
                filter.page = 1
            }

            const response = await service.getPaginationNegotiations(id, {
                page: filter.page,
                pageSize: filter.pageSize,
            })

            if (response) {
                setNegotiationDetailsTable(response)
                setTablePagination({
                    page: response?.currentPage,
                    pageSize: response?.pageSize,
                    totalPages: response?.pageCount,
                })
            }
            setIsLoadingDetailsTable(false)
        },
        [],
    )

    return {
        getNegotiationDetailsById,
        isLoadingDetails,
        negotiationDetails,
        setNegotiationDetails,
        getNegotiationTableById,
        negotiationDetailsTable,
        isLoadingDetailsTable,
        tablePagination,
        getPaginatedNegotiations,
    }
}
