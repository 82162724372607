import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

export const Container = styled(Grid)`
    &.MuiGrid-item {
        margin-top: 1rem;
    }

    .MuiGrid-item {
        padding-top: 2px !important;
    }

    .MuiFormHelperText-root {
        position: inherit !important;
        top: 0 !important;
    }
`
export const CustomTitle = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 10px 0px 6.5px 8px;
    width: 100%;
`

export const CustomAlert = styled.div`
    display: flex;
    margin: 5px 0px;
    width: 100%;
`
