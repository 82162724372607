import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

export const Container = styled(Grid)`
    &.MuiGrid-item {
        margin-top: 1rem;
    }

	.MuiFormHelperText-root {
        position: inherit !important;
        top: 0 !important;
    }
`
