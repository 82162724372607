import React, { useEffect } from 'react'
import CurrencyInput from 'components/NewCustomInput'
// import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import { Controller, useForm } from 'react-hook-form'
import * as S from './styles'

export default ({
    disabledForm,
    id,
    liquidValue,
    themeColor,
    userTotalMoney,
    setBruteValue,
    bruteValue,
    maxError,
    clear,
    setError,
    setDisabledSaveButton,
    error,
}) => {
    const { control, setValue, watch } = useForm({
        defaultValues: {
            id: '',
        },
    })

    const watchAllFields = watch()

    //eslint-disable-next-line
    const changeValueBasedOnWachedValue = (_) => {
        const watchValue = watchAllFields.transferValueInput
        if (watchValue !== null) {
            setBruteValue(watchValue)
            if (watchAllFields?.transferValueInput > userTotalMoney) {
                setError(true)
                setDisabledSaveButton(true)
            }
        }
    }

    useEffect(() => {
        changeValueBasedOnWachedValue()
    }, [changeValueBasedOnWachedValue, watchAllFields])

    return (
        <S.ContainerValueInputField>
            <>
                <S.ContextLabel>Valor da transferência</S.ContextLabel>
                <S.ContextInput>
                    <Controller
                        render={({ value }) => (
                            <CurrencyInput
                                required
                                clear={clear === true}
                                value={value}
                                max={10000000000}
                                onValueChange={changeValueBasedOnWachedValue}
                                name={id}
                                id={id}
                                setValue={setValue}
                                error={
                                    watchAllFields?.transferValueInput >
                                    userTotalMoney
                                }
                                disabled={disabledForm}
                            />
                        )}
                        control={control}
                        name={id}
                    />
                </S.ContextInput>
            </>
            {watchAllFields?.transferValueInput <= userTotalMoney ? (
                <S.ContextSpan error={error}>
                    {`O valor informando é ${
                        maxError ? 'maior' : 'menor'
                    } que o limite permitido pelas regras gerais do programa. Informe um novo valor`}
                </S.ContextSpan>
            ) : (
                <S.ContextSpan error={error}>
                    {`O valor informando é maior que o saldo disponível para transferência.`}
                </S.ContextSpan>
            )}
            {liquidValue <= 0 && !error && bruteValue >= 0 && (
                <S.ContextSpan error={liquidValue <= 0 && !error}>
                    {`O valor liquidado não pode ser igual a zero.`}
                </S.ContextSpan>
            )}
        </S.ContainerValueInputField>
    )
}
