import React from 'react'
import propTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import compose from 'utils/compose'

const CustomRequiredField = ({ t, disabled, message, value, autoComplete, autocomplete }) => (
    <>
        {t(value)}&nbsp;
        {!disabled && (
            <label
                autoComplete={autoComplete || autocomplete}
                style={{
                    color: '#f44336',
                }}
            >
                {message ? message : '*'}
            </label>
        )}
    </>
)

CustomRequiredField.propTypes = {
    value: propTypes.string.isRequired,
}

export default compose(withTranslation())(CustomRequiredField)
