export const status = [
    { id: 1, label: 'Todos', value: '' },
    { id: 2, label: 'Ativos', value: 'SaleMade' },
    { id: 3, label: 'Expirados', value: 'Expired' },
    { id: 3, label: 'Cancelados', value: 'Canceled' },
]

export const statusTooltip = {
    SaleMade: 'Venda realizada',
    Active: 'Ativo',
    Expired: 'Expirados',
    Canceled: 'Cancelados',
    ActivePartial: 'Ativo Parcial',
}

export const statusColors = {
    Expired: '#EB964F',
    SaleMade: '#5FB55F',
    Active: '#5FB55F',
    ActivePartial: '#5FB55F',
    Canceled: '#EB6C61',
}
