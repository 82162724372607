import styled, { css } from 'styled-components'
import Button from 'components/CustomButtons/Button.jsx'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

export const BoxForm = styled.form`
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
`

export const BoxAccept = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    border-radius: 6px;
    background-color: #f4f4f4;
    margin-bottom: 10px;

    .divInternal {
        border: 2px solid red !important;
    }

    .MuiFormControlLabel-root {
        margin: 0;
		display: flex !important;
		gap: 10px;
    }

    .MuiCheckbox-root {
        padding-left: 0;
    }
    .MuiTypography-body1 {
        color: rgba(0, 0, 0, 0.87);
    }
    .check-icon,
    .uncheck-icon {
        border: ${() => `1px solid ${germiniDefaultColors[0]}`};
    }

    .check-icon {
        background-color: ${() => `${germiniDefaultColors[0]}`};
        color: #fff;
    }

    @media (max-width: 960px) {
        .MuiIconButton-colorSecondary {
            padding-left: 0 !important;
        }
    }
`

export const NewButton = styled(Button)`
    ${({ disabled }) =>
        disabled &&
        css`
            background: #b4b4b4;
            border-color: #b4b4b4;
        `}
`
