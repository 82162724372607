import styled from 'styled-components/macro'
import { Modal, Paper } from '@material-ui/core'
import {
    germiniDefaultColors,
    successColor,
    dangerColor,
    grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export const ModalContainer = styled(Modal)`
    .text__value {
        margin-top: 3px;
        font-size: 14px;
        font-weight: 600;
        color: ${GetThemeColorFromStorage()?.titles?.highlight?.textColor};
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .MuiPaper-rounded {
        border-radius: 10px;
    }

    header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 25px 0 25px;
        .line {
            width: 70%;
            hr {
                border: 2px solid
                    ${GetThemeColorFromStorage()?.titles?.secondary?.textColor};
                margin: 0;
            }
        }
        .section-part {
            .section-header {
                padding: 0;
                font-size: 15px;
                display: flex;
                white-space: nowrap;
                font-weight: 600;
                align-items: center;
                text-transform: initial;
                width: 100%;
                &::after {
                    border-top: 3px solid ${germiniDefaultColors[0]};
                    content: '';
                    display: none;
                    position: static;
                    width: 100%;
                    left: 0;
                    margin-left: 10px;
                }
            }
        }
    }
`

export const PaperContainer = styled(Paper)`
    position: relative;
    width: ${({ width }) => width};
    height: ${({ height }) => height};

    main {
        padding: 0 30px 30px;
    }

    .card__content {
        margin: 25px;
        padding-right: 50px;
        > div {
            margin: 20px 0;
            width: 100%;
        }
    }

    .value__container {
        display: flex;
        align-items: center;
        font-weight: 400;

        i {
            color: ${({ isGiftCard }) =>
                isGiftCard ? successColor[7] : dangerColor[7]};
            font-size: 45px;
            margin-right: 10px;
        }

        .text__value {
            margin-top: 10px;
            font-size: 19px;
            color: ${germiniDefaultColors[0]};
        }

        .value__price {
            color: ${({ isGiftCard }) =>
                isGiftCard ? successColor[7] : dangerColor[7]};
            font-weight: 700;
            font-size: 20px;
        }
    }

    .partner__container {
        display: flex;
        font-weight: 400;

        i {
            color: ${grayColor[19]};
            font-size: 35px;
            margin-right: 10px;
            padding: 0 5px;
        }

        .partner_wrapper {
            display: flex;
            flex-direction: column;
            line-height: 1.2;

            .partner__name {
                color: ${germiniDefaultColors[0]};
                font-weight: 700;
                font-size: 16px;
            }
        }
    }

    .dates__container {
        display: flex;
        align-items: center;
        font-weight: 400;

        .date__container {
            display: flex;

            i {
                color: ${grayColor[19]};
                font-size: 35px;
                margin-right: 10px;
                padding: 0 5px;
            }

            .date_wrapper {
                display: flex;
                flex-direction: column;
                line-height: 1.2;

                .date__date {
                    color: ${germiniDefaultColors[0]};
                    font-weight: 700;
                    font-size: 16px;
                }
            }
        }
    }

    @media screen and (max-width: 580px) {
        min-width: 40vw;
    }

    padding: ${({ padding }) => padding};
    &.MuiPaper-elevation1 {
        box-shadow: ${({ shadow }) => shadow};
    }

    .close-button {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        font-size: 15px;
        color: #fff;
        background-color: #f05b4f;
        padding: 8px 10px;
        border-radius: 6px;
        &:hover {
            opacity: 0.7;
        }
        svg {
            margin-right: 3px;
        }
    }
`

export const Dotted = styled.div`
    border: none;
    margin: 10px 5px 9px 5px;
    border-top: 2px dotted #eaeaea;
    color: #fff;
    background-color: #fff;
    height: 1px;
    max-width: 287px !important;
    width: 287px !important;
    flex-grow: 1;
`
