import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
    const [showDigitalCard, setShowDigitalCard] = useState(false)

    const { generalRulesData } = useSelector((state) => state.generalRules)

    useEffect(() => {
        setShowDigitalCard(generalRulesData.allowDigitalWallet)
    }, [generalRulesData])

    return (
        <AuthContext.Provider value={showDigitalCard}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
