import axios from 'axios';

// GET api/ProgramRules/Platform
export const GetPlatformRules = () =>
    axios.get(`/PlatformRules`)
        .then(response => response.data);

// POST
export const SetConsumerPlatformRules = (obj) =>
    axios.post(`/Consumer/SetConsumerPlatformRules`, obj)
        .then(response => response.data);
