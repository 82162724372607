import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'
import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'
import { GetThemeColorFromStorage, colorLuminance } from '../../redux/api/themeColor'

export const ButtonConfirm = styled(Button)`
    height: 40px;
    font-size: 15px !important;
    min-width: 75px !important;

    &.MuiButton-root {
        border-radius: 6px;
        text-transform: initial;

        background-color: ${germiniDefaultColors[0]};
    }

    &.MuiButton-textPrimary {
        color: #fff;

        &:hover {
            background-color: ${germiniDefaultColors[0]};
            box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
        }
    }

    &.MuiButton-text {
        padding: 0 15px;
        font-weight: initial;
    }

    &.search-button {
        border-radius: 6px;

        &:hover {
            opacity: 1 !important;
            box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
        }
    }
`

export const ButtonBack = styled(Button)`
    height: 40px;
    font-size: 15px !important;
    min-width: 75px !important;

    &.MuiButton-root {
        border-radius: 6px;
        text-transform: initial;

        background-color: transparent;
        border: 1px solid ${germiniDefaultColors[0]};
    }

    &.MuiButton-text {
        color: ${germiniDefaultColors[0]};

        &:hover {
            background-color: ${germiniDefaultColors[0]};
            color: #fff;
            box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
        }
    }

    &.MuiButton-text {
        padding: 0px 15px;
        font-weight: initial;
    }
`

export const ButtonClose = styled(Button)`
    height: 30px;
    font-size: 15px !important;
    font-weight: normal !important;
    min-width: 75px !important;
    padding: 0 10px !important;

    &.MuiButton-root {
        border-radius: 6px;
        text-transform: initial;
        height: 40px;
        color: #fff;
        background-color: ${germiniDefaultColors[12]};

        &:hover {
            background-color: ${germiniDefaultColors[12]};
            box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
        }
    }
`

export const ActionButton = styled(Button)`
    &.MuiButton-root {
        border-radius: 6px;
        background-color: ${({ disabled }) =>
            disabled
                ? '#c8c8c8'
                : `${
                      GetThemeColorFromStorage()?.navigation?.buttons
                          ?.backgroundColor
                  }`};
        min-width: 30px !important;
        height: 30px;
    }

    &.MuiButton-textPrimary {
        color: #fff;

        &:hover {
            background-color: ${colorLuminance(
                GetThemeColorFromStorage()?.navigation?.buttons
                    ?.backgroundColor,
                -0.2,
            )};
        }
    }

    &.MuiButton-text {
        padding: 0px;
        /* height: 30px; */
        font-size: 22px;
        font-weight: bold;

        :first-child {
            margin-right: 15px;
        }
    }

    &.search-button {
        border-radius: 6px;

        &:hover {
            opacity: 1 !important;
            box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
            -moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
        }
    }
`
