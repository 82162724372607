import styled from 'styled-components'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card.jsx'
import FormControl from '@material-ui/core/FormControl'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export const GeneralRulesBox = styled(GridContainer)`
    .switch-label {
        transform: translate(0, 1.5px) scale(0.75);
        transform-origin: top left;
    }

    .bottom-box {
        padding-left: 15px;
        position: relative !important;
        top: -35px;
        /* margin-top: -30px; */
    }

    @media (max-width: 1279px) {
        .recorrencionRules {
            padding-right: 0px !important;
        }
    }

    @media (max-width: 959.95px) {
        .bottom-box {
            padding-left: 0;
        }
    }
`

export const CardBox = styled(Card)`
    margin-bottom: 50px !important;
`

export const CodeBox = styled.div`
    margin-top: 7px;
    padding-left: 15px;
    margin-bottom: 20px;
`

export const LabelCodeText = styled.div`
    .Textlabel {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
    }
`

export const CodeText = styled.div`
    display: flex;

    .TextCode {
        font: Roboto;
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor};
        letter-spacing: 10px;
        font-size: 20px;
        background-color: #f4f4f4;
        font-weight: 500;
        padding: 5px 5px 5px 5px;
        border-radius: 2px;
    }

    .LabelStyle {
        font: Roboto regular;
        font-size: 15px;
        color: #333333;
        margin-left: 15px;
        padding-top: 5px;
    }
`

export const GridItemRules = styled(GridItem)`
    padding-left: 0 !important;
`

export const InputWrapper = styled.div`
    /* background-color: lightgray; */
    display: inline-flex;
    align-items: baseline;

    > .MuiFormControl-root {
        padding: 0;
        top: -3px;
    }

    &.expirte-date {
        padding: 0 !important;
    }
`

export const FormSelect = styled(FormControl)`
    .MuiInput-formControl {
        margin-top: 21px !important;
    }
`

export const ContainerProfileForm = styled.div`
    .picture-container {
        position: relative;
        cursor: pointer;
        text-align: center;
        .picture {
            width: 106px;
            height: 106px;
            background-color: #ffffff;
            border: 4px solid
                ${GetThemeColorFromStorage()?.navigation?.buttons
                    ?.backgroundColor} !important;
            color: #ffffff;
            border-radius: 50%;
            margin: 5px auto;
            overflow: hidden;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;

            &:hover {
                border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
                    ?.backgroundColor} !important;
            }

            input[type='file'] {
                cursor: pointer;
                display: block;
                height: 100%;
                left: 0;
                opacity: 0 !important;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
        .picture-src {
            display: inline-block;
            width: 100px;
            height: 103px;
            object-fit: cover;
        }
    }
    .picture-container-disabled {
        position: relative;
        cursor: default;
        text-align: center;
        .picture {
            width: 106px;
            height: 106px;
            background-color: #ffffff;
            border: 4px solid #cccccc;
            color: #ffffff;
            border-radius: 50%;
            margin: 5px auto;
            overflow: hidden;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;

            input[type='file'] {
                cursor: pointer;
                display: block;
                height: 100%;
                left: 0;
                opacity: 0 !important;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
        .picture-src {
            display: inline-block;
            width: 100px;
            height: 103px;
            object-fit: cover;
        }
    }

    #margin-bottom-input-item {
        margin-bottom: -17px !important;
    }

    #margin-switch {
        @media screen and (max-width: 959.95px) {
            margin: 20px 0px !important;
        }
    }
    #margin-switch-mei {
        @media screen and (max-width: 959.95px) {
            margin: 20px 0px !important;
        }
    }

    .small-input-checkbox {
        .MuiFormControlLabel-root {
            display: block;
            overflow: hidden;
            width: ${({ RegistrationWaiver }) =>
                RegistrationWaiver ? '240px' : '100%'};
            white-space: nowrap;
            text-overflow: ellipsis;

            @media screen and (max-width: 1050px) {
                width: 180px;
            }

            @media screen and (max-width: 959.95px) {
                width: 100%;
            }
        }

        .MuiCheckbox-root {
            transform: translateY(-1px);
        }
    }
    .input-rg-ie {
        .MuiInputLabel-outlined.MuiInputLabel-shrink {
            display: block;
            overflow: hidden;
            width: 206px;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media screen and (max-width: 1100px) {
                width: 149px;
            }

            @media screen and (max-width: 959.95px) {
                width: 100%;
            }
        }
    }

    #MuiCheckbox {
        .MuiFormControlLabel-root {
            transform: translateY(8px) !important;
        }

        @media screen and (max-width: 959.99px) {
            margin-bottom: 20px !important;
        }

        .Mui-disabled {
            color: ${({ disabled }) =>
                disabled ? '#959393' : 'inherit'} !important;
        }
    }
    #MuiMargin {
        @media screen and (max-width: 959.99px) {
            margin-bottom: 20px !important;
        }
    }

    #margin-bottom-contact {
        margin-bottom: 12px !important;
    }

    ${(props) =>
        props.isCnpj &&
        `
    @media (min-width: 960px) {
      .custom-form-control-cnpj {
        max-width: 26.333333% !important;
        flex-basis: 36.333333% !important;
      }

      .small-input-name {
        max-width: 73.666667% !important;
        flex-basis: 73.666667% !important;
      }

      .small-input-date {
        max-width: 23% !important;
        flex-basis: 23% !important;
      }
    }
  `}

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }
`

export const ContainerBankAndPixForm = styled.div`
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }
`
