/* eslint-disable */
import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
// REDUX
import { useBrazilMoneyFormat } from '../User/Profile/moneyFormater'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import Alert from 'react-bootstrap-sweetalert'
import ConfirmRefundModal from './ConfirmTransfer'
import NoPaymentOptionModal from './NoPaymentOptionModal'
//------
import {
	GetCurrentConsumer,
	GetInvoicesCreditSource,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
	GetData,
	GetConsumerAccountKeyType,
} from 'redux/actions'

import * as API from '../../redux/api/user'
import { withStyles } from '@material-ui/core/styles'
// UTILS
import compose from 'utils/compose'
import Loader from 'components/Loader/Loader.jsx'
import PageTopPart from '../PageTopPart/PageTopPart.jsx'
import TransferRequest_Form from './TransferRequest_Form.jsx'
import TransferRequest_Cards from './TransferRequest_Cards.jsx'
import TransferRequest_LimitsWarnings from './TransferRequest_LimitsWarnings.jsx'
import TransferRequest_TaxesWarnings from './TransferRequest_TaxesWarnings.jsx'
import { executeRequisition } from './transferHandlerResponse'
import * as transferHandler from './transferHandler'
import updateTransferRules from './RequestsToAPI/updateTransferRules.js'
import updateTransferValues from './RequestsToAPI/updateTransferValues'
import updateUserTransferData from './RequestsToAPI/updateUserTransferData'
import updateWalletBalanceData from './RequestsToAPI/updateWalletBalanceData'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
import { GetDigitalWalletRulesData } from '../../redux/actions/generalRules'
import { Container, ContainerVoid } from './styles'

const TransferRequest = (props) => {
	const BrazilCurrency = (_number) => useBrazilMoneyFormat(_number)

	const { t } = useTranslation()
	const { classes } = props
	const dispatch = useDispatch()

	const [disabledForm, setDisabledForm] = useState(true)

	const bankPaymentOption = 'bank'
	const pixPaymentOption = 'pix'

	const [choosenPaymentOption, setChoosenPaymentOption] = useState(null) //'pix' or 'bank'

	const themeColor = GetThemeColorFromStorage()?.titles?.highlight?.textColor

	//dados da api
	const [userBank, setUserBank] = useState({})
	const [userPix, setUserPix] = useState({})
	const [userTotalMoney, setUserTotalMoney] = useState(0)

	const [minTransferValue, setMinTransferValue] = useState(0)
	const [maxTransferValue, setMaxTransferValue] = useState(0)
	const [daysToProcessTransfer, setDaysToProcessTransfer] = useState(0)
	const [userTransferLimit, setUserTransferLimit] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [allowUserTransfer, setAllowUserTransfer] = useState(null)
	const [bruteValue, setBruteValue] = useState(0)
	const [liquidValueToTransfer, setLiquidValueToTransfer] = useState('')

	const [transferFee, setTransferFee] = useState(0)
	const [transferFeePercentage, setTransferFeePercentage] = useState(0)
	const [antecipationFeeTotal, setAntecipationFeeTotal] = useState(0)
	const [antecipationFeeTotalPercentage, setAntecipationFeeTotalPercentage] =
		useState(0)
	const [antecipationFees, setAntecipationFees] = useState([])

	const [generalRules, setGeneralRules] = useState({})

	//string
	const [userPasswordFromDataBase, setUserPasswordFromDataBase] =
		useState(null)
	//string
	const [userPasswordFromModal, setUserPasswordFromModal] = useState('')
	//object
	const [transferRequisitionResponse, setTransferRequisitionResponse] =
		useState({})

	// boolean
	const [passwordModal_open, setPasswordModal_open] = useState(false)
	const [userHasAnyPaymentOption, setUserHasAnyPaymentOption] = useState(false)
	const [incorrectPassword_show, setIncorrectPassword_show] = useState(false)
	const [requestedWithoutSelectAnOption, setRequestedWithoutSelectAnOption] =
		useState(false)
	const [errorModal_show, setErrorModal_show] = useState(false)
	const [errorModal_isSucess, setErrorModal_isSucess] = useState(false)
	const [errorModal_text, setErrorModal_text] = useState('')
	const [disabledSaveButton, setDisabledSaveButton] = useState(true)
	const [valuesError, setValuesError] = useState('')
	const [clear, setClear] = useState(false)

	//erro de valor máximo ou mínimo
	const [minError, setMinError] = useState(false)
	const [maxError, setMaxError] = useState(false)
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)

	const [numberOfTransfers, set_numberOfTransfers] = useState(0)

	const showMinError = () => {
		setMaxError(false)
		setMinError(true)
		setError(true)
	}
	const showMaxError = () => {
		setMaxError(true)
		setMinError(false)
		setError(true)
	}
	const hideAllErrors = () => {
		setMaxError(false)
		setMinError(false)
		setError(false)
	}

	const setMyVar = (_value) => {
		localStorage.setItem('myVar', _value)
	}


	const [userHasBank, set_userHasBank] = useState(true)

	const [isTimeOut, setIsTimeOut] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setIsTimeOut(!isTimeOut)
			updateWalletBalanceData(
				setUserTotalMoney,
				showModal,
				setUserTransferLimit,
			)
		}, 60000)
	}, [dispatch, isTimeOut])


	useEffect(() => {
		setChoosenPaymentOption(
			userHasBank ? bankPaymentOption : (bankPaymentOption ? pixPaymentOption : null),
		)
	}, [userHasBank])

	useEffect(() => {
		setChoosenPaymentOption(
			userHasBank ? bankPaymentOption : (bankPaymentOption ? pixPaymentOption : null),
		)
	}, [])


	useEffect(() => {

		setMyVar(false)
		updateUserTransferData(
			setUserPix,
			setUserBank,
			userBank,
			userPix,
			setUserHasAnyPaymentOption,
			showModal,
			set_userHasBank,
			setLoading,
		)
		updateWalletBalanceData(
			setUserTotalMoney,
			showModal,
			setUserTransferLimit,
		)
		updateTransferRules(
			setMinTransferValue,
			setMaxTransferValue,
			setDaysToProcessTransfer,
			setAllowUserTransfer,
			setGeneralRules,
			showModal,
			history,
		)

	}, [])

	useEffect(() => {
		updateTransferValues(
			bruteValue,
			setAntecipationFeeTotal,
			setAntecipationFeeTotalPercentage,
			setAntecipationFees,
			setTransferFee,
			setTransferFeePercentage,
			setLiquidValueToTransfer,
			setDisabledSaveButton,
			BrazilCurrency,
			showModal,
			setValuesError,
			minTransferValue,
			maxTransferValue,
			showMinError,
			showMaxError,
			hideAllErrors,
			t,
		)
	}, [bruteValue])

	function handleSave(userPasswordFromModal) {
		const _valuesFromFormToTransfer = [
			bruteValue,
			antecipationFeeTotal,
			transferFee,
			liquidValueToTransfer,
		]
		switch (choosenPaymentOption) {
			case null:
				setRequestedWithoutSelectAnOption(true)
				break

			case bankPaymentOption:
				return transferHandler.Banco(
					showModal,
					setTransferRequisitionResponse,
					userPasswordFromModal,
					setPasswordModal_open,
					userBank,
					..._valuesFromFormToTransfer,
				)

			case pixPaymentOption:
				return transferHandler.Pix(
					showModal,
					setTransferRequisitionResponse,
					userPasswordFromModal,
					setPasswordModal_open,
					userPix.pixKeyType,
					userPix.pixKey,
					..._valuesFromFormToTransfer,
				)
		}
	}

	const closeForm = () => {
		setPasswordModal_open(false)
		setDisabledForm(true)
		setChoosenPaymentOption(null)
	}

	const onModalConfirm = async () => {
		setLoading(true)
		setClear(false)
		try {
			const isValide = await API.ValidateConsumer(userPasswordFromModal)
			if (isValide) {
				setIncorrectPassword_show(false)
				const res = await handleSave(userPasswordFromModal)
				if (res) {
					executeRequisition(res, showModal, daysToProcessTransfer, generalRules)
					closeForm()
					setUserPasswordFromModal('')
					setBruteValue(0)
					setClear(true)
					setTransferFee(0)
					setLiquidValueToTransfer('')
					setAntecipationFees([])
					updateWalletBalanceData(
						setUserTotalMoney,
						showModal,
						setUserTransferLimit,
					)


					setChoosenPaymentOption(
						userHasBank ? bankPaymentOption : (bankPaymentOption ? pixPaymentOption : null),
					)

					setUserPasswordFromDataBase(userPasswordFromModal)
					dispatch(GetDigitalWalletRulesData())
					dispatch(GetCurrentConsumer())
					set_numberOfTransfers(numberOfTransfers + 1)
				}
			} else {
				setIncorrectPassword_show(true)
				setUserPasswordFromDataBase(null)
			}
		} catch (error) {
			showModal(
				`Não foi possível checar o banco de dados para validar sua senha.#${error}.#`,
				false,
			)
		} finally {
			setLoading(false)
		}
	}

	function opemModal() {
		if (disabledSaveButton) return
		if (choosenPaymentOption === null) {
			setRequestedWithoutSelectAnOption(true)
			return
		}

		if (allowUserTransfer === true) {
			setPasswordModal_open(true)
		} else {
			showModal(
				`Seu perfil não tem permissão para realizar transferências no momento.#`,
				false,
			)
		}
	}

	const history = useHistory()

	const goToProfile = () => {
		setMyVar(true)
		history.push('/profile')
	}

	const showModal = (_content, _sucess) => {
		setErrorModal_text(_content)
		setErrorModal_show(!errorModal_show)
		setErrorModal_isSucess(_sucess)
	}
	const hideModal = () => {
		setErrorModal_text('')
		setErrorModal_show(false)
		setErrorModal_isSucess(false)
	}

	// useEffect(() => {
	// 	console.log(`userHasAnyPaymentOption foi mudado para ${userHasAnyPaymentOption}`)
	// }, [userHasAnyPaymentOption])

	return (
		<div style={{ position: 'relative' }}>

			{loading && <Loader />}

			{errorModal_show && (
				<Alert
					success={errorModal_isSucess ? true : false}
					warning={errorModal_isSucess ? false : true}
					style={{
						display: 'block',
						marginTop: '-100px',
					}}
					title={null}
					onConfirm={hideModal}
					confirmBtnCssClass={
						classes.button + ' ' + classes.greenButtonColor
					}
					confirmBtnText={`OK`}
					showCancel={false}
				>
					{typeof errorModal_text === 'string'
						? errorModal_text.split('#').map((_content, _index) => {
							if (
								_index <
								errorModal_text.split('#').length - 1
							) {
								//retorna tudo menos o ultimo que eh vazio
								return (
									<p>
										{_content
											.split('[object Object]')
											.join('')}
									</p>
								)
							}
						})
						: errorModal_text
					}
				</Alert>
			)}

			<ConfirmRefundModal
				incorrectPassword_show={incorrectPassword_show}
				maxWidth={800}
				minWidth={500}
				closeByClick={() => {
					setUserPasswordFromModal('')
					setPasswordModal_open(false)
				}}
				onCancel={() => {
					setUserPasswordFromModal('')
					setPasswordModal_open(false)
				}}
				setUserPasswordFromModal={setUserPasswordFromModal}
				onDelete={(e) => {
					setUserPasswordFromModal(e.target?.value)
				}}
				onConfirm={onModalConfirm}
				value={userPasswordFromModal}
				open={passwordModal_open}
				password={userPasswordFromDataBase}
				t={t}
				loading={loading}
			/>

			<PageTopPart
				formTitle={t('TITLE_REQUIRE_TRANSFER')}
				handleEdit={opemModal}
				disabledForm={true}
				editName={t('BUTTON_REQUIRE_TRANSFER')}
				showButtons={userHasAnyPaymentOption}
				disableEditButton={userTransferLimit < 1 || disabledSaveButton || bruteValue === 0 || liquidValueToTransfer <= 0}
			/>

			{requestedWithoutSelectAnOption && (
				<ContainerVoid>
					(nenhuma opção selecionada)
				</ContainerVoid>
			)}

			{!loading && (userHasAnyPaymentOption ? (
				<Container disabledForm={disabledForm}>
					<TransferRequest_Cards
						choosenPaymentOption={choosenPaymentOption}
						themeColor={themeColor}
						disabledForm={false}
						setChoosenPaymentOption={setChoosenPaymentOption}
						classes={classes}
						bankPaymentOption={bankPaymentOption}
						pixPaymentOption={pixPaymentOption}
						userBank={userBank}
						userPix={userPix}
						setRequestedWithoutSelectAnOption={
							setRequestedWithoutSelectAnOption
						}
					/>
					<TransferRequest_Form
						minError={minError}
						maxError={maxError}
						error={error}
						transferFee={transferFee}
						transferFeePercentage={transferFeePercentage}
						antecipationFees={antecipationFees}
						bruteValue={bruteValue}
						liquidValue={liquidValueToTransfer}
						themeColor={themeColor}
						setBruteValue={setBruteValue}
						userTotalMoney={userTotalMoney}
						classes={classes}
						disabledForm={false}
						setError={setError}
						setDisabledSaveButton={setDisabledSaveButton}
						clear={clear}
					/>
					<TransferRequest_LimitsWarnings
						daysToProcessTransfer={daysToProcessTransfer}
						userTransferLimit={userTransferLimit}
						generalRules={generalRules}
					/>
					<TransferRequest_TaxesWarnings
						antecipationFees={antecipationFees}
						choosenPaymentOption={choosenPaymentOption}
					/>
				</Container>
			) : (
				<NoPaymentOptionModal goToProfile={goToProfile} />
			))}

		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		user: state.authentication.user,
		userData: state.users.userData,
		generalRulesData: state.generalRules.generalRulesData,
		invoiceCreditSource: state.invoice.invoiceCreditSource,
		invoiceCreditSourceFailed: state.invoice.invoiceCreditSourceFailed,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetCurrentConsumer,
			GetInvoicesCreditSource,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
			GetData,
			GetConsumerAccountKeyType,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(extendedFormsStyle),
)(TransferRequest)
