/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from 'react'
import Section from '../../components/Section/Section'
import DocumentTitle from 'react-document-title'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Loader from 'components/Loader/Loader.jsx'

// REDUX
import { bindActionCreators } from 'redux'
import {
	GetPartnersData,
	GetStatementData,
	HideLoader,
	ShowLoader,
} from 'redux/actions'
import GridTableNew from './components/GridTable/index.jsx'

// CONTEXT
import { useStatement } from './Providers/StatementContext/index.js'
import SearchForm from './SearchForm/index.js'
import * as S from './styles'
import MaterialTable from 'material-table'
import { getProgramTitle } from '../../utils/utils.js'

const StatementChild = (props) => {
	const {
		currentPage,
		setCurrentPage,
		setRecordCount,
		valueTypeFilter,
	} = useStatement()
	const { t } = useTranslation()
	const history = useHistory()

	const [pageSize, setPageSize] = useState(10)

	useEffect(() => {
		if (currentPage) {
			try {
				props.ShowLoader(<Loader />)
				props.GetStatementData(
					valueTypeFilter?.selectedPartner?.id,
					valueTypeFilter?.type?.value,
					valueTypeFilter?.dateFilterFrom,
					valueTypeFilter?.dateFilterTo,
					currentPage,
					pageSize,
				)
			} catch (error) {
				console.error(error)
			} finally {
				props.HideLoader()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, valueTypeFilter, pageSize])

	useEffect(() => {
		setRecordCount(props.statementData.recordCount)
		setPageSize(props.statementData.pageSize)
		setCurrentPage(props.statementData.currentPage)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.statementData])

	const handleSearch = (currentPage) => {
		setCurrentPage(currentPage)
	}

	return (
		<S.Container>
			<DocumentTitle title={getProgramTitle(t('NAVPILLS_STATEMENT'))} />
			<Section title={t('NAVPILLS_STATEMENT')} />
			<S.ContainerSecundary>
				<SearchForm />
				<S.GridBox>
					<GridTableNew
						data={
							props.statementData.results &&
								props.statementData.results.length !== 0
								? props.statementData.results
								: []
						}
						history={history}
					/>

					<MaterialTable
						onChangePage={(e) => handleSearch(e + 1)}
						onChangeRowsPerPage={(e) => {
							setRecordCount(e)
							setPageSize(e)
						}}
						title=""
						data={props.statementData.results}
						totalCount={props.statementData.recordCount}
						page={props.statementData.currentPage - 1}

						options={{
							sorting: false,
							emptyRowsWhenPaging: false,
							toolbar: false,
							actionsColumnIndex: -1,
							search: false,
							draggable: false,
							pageSize: pageSize || 10,
							pageSizeOptions: [10, 20, 30, 40, 50, 100],
						}}
						localization={{
							body: {
								emptyDataSourceMessage: (
									<S.EmptyTable>
										<i className="icon-alerta_modal" />
										<span>{t('No records to display')}</span>
									</S.EmptyTable>
								),
							},
							pagination: {
								labelRowsSelect: `${t('lines')}`,
								//labelDisplayedRows: '{count} de {from}-{to}',
								firstTooltip: ` ${t('First page')}`,
								previousTooltip: `${t('Previous page')}`,
								nextTooltip: `${t('Next page')}`,
								lastTooltip: `${t('Last page')}`,
							},
						}}
					/>
				</S.GridBox>
			</S.ContainerSecundary>
		</S.Container>
	)
}

const mapStateToProps = (state) => {
	return {
		userData: state.users.userData,
		statementData: state.wallet.statementData,
		statementDataFailed: state.wallet.statementDataFailed,
		partnersData: state.partners.partnersData,
		partnersDataFailed: state.partners.partnersDataFailed,
		generalRulesData: state.generalRules.generalRulesData,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ GetPartnersData, GetStatementData, ShowLoader, HideLoader },
		dispatch,
	)

export default connect(mapStateToProps, mapDispatchToProps)(StatementChild)
