import {
    primaryBoxShadow,
    whiteColor,
    blackColor,
    grayColor,
    hexToRgb,
    germiniDefaultColors,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

const customSelectStyle = {
    select: {
        padding: '12px 0 7px',
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '1.42857',
        textDecoration: 'none',
        color: germiniDefaultColors[5],
        letterSpacing: '0',
        '&:focus': {
            backgroundColor: 'transparent',
        },
        '&[aria-owns] + input + svg': {
            transform: 'rotate(180deg)',
        },
        '& + input + svg': {
            transition: 'all 300ms linear',
        },
    },
    selectFormControl: {
        margin: '5px 0 17px 0 !important',
        opacity: '1 !important',
        '&$disabled': {
            '& > div': {
                '&:before': {
                    borderBottom: 'none',
                },
                '& > div > div': {
                    '& + input + svg': {
                        display: 'none !important',
                    },
                },
            },
        },
        '& > div': {
            '&:before': {
                borderBottomWidth: '1px !important',
                borderBottomColor: germiniDefaultColors[3] + '!important',
            },
            '&:after': {
                borderBottomColor:
                    GetThemeColorFromStorage()?.navigation?.menu?.activeColor +
                    '!important',
            },
        },
    },
    selectLabel: {
        fontSize: '15px',
        color: germiniDefaultColors[5] + ' !important',
        top: '8px',
    },
    selectMenu: {
        '& > div > ul': {
            border: '0',
            padding: '5px 0',
            margin: '0',
            boxShadow: 'none',
            minWidth: '100%',
            borderRadius: '4px',
            boxSizing: 'border-box',
            display: 'block',
            fontSize: '15px',
            textAlign: 'left',
            listStyle: 'none',
            backgroundColor: whiteColor,
            backgroundClip: 'padding-box',
        },
        '& $selectPaper $selectMenuItemSelectedMultiple': {
            backgroundColor:
                GetThemeColorFromStorage()?.navigation?.menu?.activeColor +
                '!important',
        },
        '& > div + div': {
            maxHeight: '266px !important',
        },
    },
    selectMenuItem: {
        fontSize: '15px',
        padding: '10px 20px',
        margin: '0 5px',
        borderRadius: '2px',
        transition: 'all 150ms linear',
        display: 'block',
        clear: 'both',
        fontWeight: '400',
        lineHeight: '2',
        whiteSpace: 'nowrap',
        color: 'germiniDefaultColors[5]',
        paddingRight: '30px',
        '&:hover': {
            backgroundColor:
                GetThemeColorFromStorage()?.navigation?.menu?.activeColor +
                '!important',
            color: whiteColor,
        },
    },
    selectMenuItemSelected: {
        backgroundColor:
            GetThemeColorFromStorage()?.navigation?.menu?.activeColor +
            '!important',
        color: whiteColor,
    },
    selectMenuItemSelectedMultiple: {
        backgroundColor: 'transparent !important',
        '&:hover': {
            backgroundColor:
                GetThemeColorFromStorage()?.navigation?.menu?.activeColor +
                '!important',
            color: whiteColor,
            '&:after': {
                color: whiteColor,
            },
        },
        '&:after': {
            top: '16px',
            right: '12px',
            width: '12px',
            height: '5px',
            borderLeft: '2px solid currentColor',
            transform: 'rotate(-45deg)',
            opacity: '1',
            color: grayColor[2],
            position: 'absolute',
            content: "''",
            borderBottom: '2px solid currentColor',
            transition: 'opacity 90ms cubic-bezier(0,0,.2,.1)',
        },
    },
    selectPaper: {
        boxSizing: 'borderBox',
        borderRadius: '4px',
        padding: '0',
        minWidth: '100%',
        display: 'block',
        border: '0',
        boxShadow: '0 2px 5px 0 rgba(' + hexToRgb(blackColor) + ', 0.26)',
        backgroundClip: 'padding-box',
        margin: '2px 0 0',
        fontSize: '15px',
        textAlign: 'left',
        listStyle: 'none',
        backgroundColor: 'transparent',
        maxHeight: '266px',
    },
    disabled: {},
}

export default customSelectStyle
