import Axios from 'axios'

export default class TransferService {
    async getSendTransferRequest({ type, password }) {
        const paramsString = `option=${type}&Password=${password}`
        const queryParams = new URLSearchParams(paramsString).toString()
        const response = await Axios.post(
            `/Transfer/SendTransferRequestToConsumer${'?' + queryParams}`,
            null,
        )
        return response?.data
    }
}
