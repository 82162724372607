export function formatTime(time) {
    return time?.substring(0, 5)
}

export function formatAccountNumber(accountNumber) {
    if (!accountNumber) return ''
    return accountNumber.split('-')[0]
}

export function formatValue(value) {
    const cleanedValue = value?.replace(/[^\d,.-]/g, '')
    const noThousandSeparators = cleanedValue?.replace(/\.(?=\d{3},)/g, '')
    const formattedValue = noThousandSeparators?.replace(',', '.')
    return parseFloat(formattedValue)
}

export function formatValuePoint(value) {
    const cleanedValue = value?.replace(/[^\d]/g, '')
    return parseFloat(cleanedValue)
}
export function maskedPixValue(_value) {
    switch (_value) {
        case '0':
            return 'Chave PIX' //0
        case 'AleatoryKey':
            return 'Chave aleatória'
        // case 'CPF': return "CPF"
        case 'Cellphone':
            return 'Celular'
        case 'Email':
            return 'E-mail'
        default:
            return _value
    }
}

const transferOriginMap = {
    0: 2,
    1: 1,
}
const transferOriginPoints = {
    0: 1,
    1: 2,
}

export function TransferOriginAPI(index) {
    return transferOriginMap.hasOwnProperty(index)
        ? transferOriginMap[index]
        : 1
}
export function transferOriginAPIPoints(index) {
    return transferOriginPoints.hasOwnProperty(index)
        ? transferOriginPoints[index]
        : 1
}

export function formatPercentage(value) {
    return value.toString().replace('.', ',') + '%'
}

export function borderPoints(item, index) {
    return item?.transferType?.key === '1' && transferOriginAPIPoints(index)
}
