import * as API from '../api/regulation';
import * as actionTypes from './actionsTypes';

export const fetchRegulationData = (data) => {
    return {
        type: actionTypes.FETCH_REGULATION_DATA,
        regulationData: data
    }
}

export const fetchRegulationDataFailed = (error) => {
    return {
        type: actionTypes.FETCH_REGULATION_FAILED,
        regulationDataFailed: error
    }
}

export const GetRegulationData = () => {
    return dispatch => {
        API.GetRegulationData().then(rest => {
            dispatch(fetchRegulationData(rest))
            //console.log(rest)
            return rest;
        }).catch(err => {
            console.error(err)
            dispatch(fetchRegulationDataFailed(err))
        })
    }
}