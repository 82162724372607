import React, { Fragment } from 'react';
import * as _ from "lodash";
import PasswordStrengthBars from 'react-password-strength-bar';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import { Grid, IconButton, InputAdornment } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Section from 'components/Section/Section';
import { Visibility, VisibilityOff } from '@material-ui/icons'

// style
import styles from '../resetPassword.module.scss';
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import * as Style from './styles'

// UTILS
import compose from 'utils/compose';

const PwdTooltip = withStyles({
	tooltip: {
		textAlign: 'left!important',
		maxWidth: '500px!important',
		padding: '8px 15px',
		borderRadius: '10px!important',
		'@media (max-width: 780px)': {
			maxWidth: '300px!important',
			marginRight: '10px',
		},
	},
})(Tooltip)

const policyArray = [
	'Deve ter no mínimo 8 caracteres;',
	'Deve ter no máximo 20 caracteres;',
	'Deve conter letras, números e caracteres especiais',
	'Não deve conter mais de 3 números sequenciais;',
	'Não deve conter números repetidos em ordem;',
	'Não pode ser igual ao CPF, CNPJ, Data de Nascimento, Telefone ou celular.',
]

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showPassword: false,
			showPasswordConfirm: false,
			showTooltip: false
		}
	}
	render() {
		const {
			t,
			classes,
			values,
			errors,
			touched,
			handleSubmit,
			handleChange,
			isValid,
			setFieldTouched,
			setFieldValue,
		} = this.props;

		const change = (name, e) => {
			e.persist();
			handleChange(e);
			setFieldTouched(name, true, false);
		};

		const handleTogglePassword = () => {
			this.setState((prevState) => ({
				showPassword: !prevState.showPassword,
			}))
		}

		const handleTogglePasswordConfirm = () => {
			this.setState((prevState) => ({
				showPasswordConfirm: !prevState.showPasswordConfirm,
			}))
		}

		const handleShowTooltip = (value) => {
			this.setState({
				showTooltip: value
			})
		}

		return (
			<Fragment>
					<Section title={t("MENU_USER_RESET_PASSWORD")} />
				<GridContainer>
					<GridItem xs={12} md={6} style={{ marginTop: "7px" }}>
						<TextField
							fullWidth
							label="Nova senha"
							id="newPassword"
							name="newPassword"
							// error={touched.newPassword && Boolean(errors.newPassword)}
							value={values.newPassword}
							onChange={change.bind(null, "newPassword")}
							variant="outlined"
							margin="dense"
							type={this.state.showPassword ? "text" : "password"}
							style={{ backgroundColor: '#fff' }}
							error={
								touched.newPassword &&
								Boolean(errors.newPassword)
							}
							helperText={
								touched.newPassword
									? errors.newPassword
									: ''
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											disableRipple
											aria-label="toggle password visibility"
											onClick={() => handleTogglePassword()}
										>
											{this.state.showPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<PasswordStrengthBars
							password={values.newPassword}
							minLength={2}
							shortScoreWord={"muito curto"}
							// onChangeScore={handleChangeScore}
							barColors={['#dddddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
							scoreWords={['muito fraca', 'fraca', 'regular', 'boa', 'forte']} />
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} md={6} style={{ marginTop: "7px" }}>
						<TextField
							fullWidth
							label="Confirmar senha"
							id="confirmPassword"
							name="confirmPassword"
							value={values.confirmPassword}
							onChange={change.bind(null, "confirmPassword")}
							variant="outlined"
							margin="dense"
							type={this.state.showPasswordConfirm ? "text" : "password"}
							style={{ backgroundColor: '#fff' }}
							error={
								touched.confirmPassword &&
								Boolean(errors.confirmPassword)
							}
							helperText={
								touched.confirmPassword
									? errors.confirmPassword
									: ''
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											disableRipple
											aria-label="toggle password visibility"
											onClick={() => handleTogglePasswordConfirm()}
										>
											{this.state.showPasswordConfirm ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<Grid item container xs={12} md={6} justify="flex-end" style={{ paddingRight: '15px' }}>
						<Style.PolicyTooltip
							open={this.state.showTooltip}
							leaveTouchDelay={3000}
							enterTouchDelay={50}
							onOpen={() => handleShowTooltip(true)}
							onClose={() => handleShowTooltip(false)}
							onClick={() => handleShowTooltip(!this.state.showTooltip)}
							arrow
							style={{
								marginTop: '1rem',
							}}
							title={
								<Style.BoxPolicy>
									<b>Regras:</b>
									<ul
										style={{
											marginLeft: '-18px',
										}}
									>
										{policyArray.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</Style.BoxPolicy>
							}
							placement="bottom-end"
						>
							<Style.PoliticyText
								onClick={() => handleShowTooltip(!this.state.showTooltip)}
							>
								Política de senha
							</Style.PoliticyText>
						</Style.PolicyTooltip>
					</Grid>
					<GridItem xs={12}>
						<Tooltip
							id="tooltip-top"
							title="View"
							placement="bottom"
							classes={{ tooltip: classes.tooltip }}
						>
							<Button
								size="sm"
								variant="contained"
								color="primary"
								className={styles.Button}
								onClick={handleSubmit}
							>{t("BUTTON_SAVE")}</Button>
						</Tooltip>

					</GridItem>
				</GridContainer>
			</Fragment>
		)
	}
}

export default compose(withTranslation(), withStyles(extendedFormsStyle))(ResetPassword);
