import * as Yup from 'yup'
import * as _ from 'lodash'
import React from 'react'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const theme = GetThemeColorFromStorage()

export const initialPagination = {
    currentPage: 0,
    pageCount: 1,
    pageSize: 10,
    recordCount: 1,
    filter: {
        startDate: '',
        endDate: '',
        source: '',
        entryType: '',
        transactionType: '',
        partnerName: '',
    },
}

export const transactionTypes = [
    { key: 0, value: 'Tudo' },
    { key: 1, value: 'Entradas' },
    { key: 2, value: 'Saidas' },
]

export const periodTime = [
    { key: 0, value: 'Últimos 7 dias' },
    { key: 1, value: 'Últimos 14 dias' },
    { key: 2, value: 'Personalizado' },
]

export const validationSchema = Yup.object({
    startDate: Yup.string().nullable(),
    endDate: Yup.string().nullable(),
    source: Yup.string().nullable(),
    entryType: Yup.string().nullable(),
    transactionType: Yup.number().nullable(),
    partnerName: Yup.string().nullable(),
})

export const customSelectStyles = {
    control: (base, state) => ({
        ...base,
        input: { height: '26px' },
        backgroundColor: '#fff',
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ?  theme &&
        theme.navigation &&
        theme.navigation.buttons.elementsColor : base.borderColor,
        '&:hover': {
            borderColor: state.isFocused ? theme &&
            theme.navigation &&
            theme.navigation.buttons.elementsColor
            : base.borderColor,
        },
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        cursor: 'pointer',
        zIndex: '2',
    }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = theme?.navigation?.buttons?.elementsColor
		return {
			...styles,
			backgroundColor: isDisabled
				? undefined
				: isSelected
				? color
				: isFocused
				? color
				: undefined,
			color: isDisabled
				? undefined
				: isSelected
				? '#FFFFFF'
				: isFocused
				? '#FFFFFF'
				: undefined,
		}
	},
}
export const customIcon = () => {
    return (
        <svg
            class="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
        >
            <path d="M7 10l5 5 5-5z"></path>
        </svg>
    )
}
