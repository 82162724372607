import React from 'react'

import MaterialTable, {
    MTableCell,
    MTableToolbar,
    MTableHeader,
} from 'material-table'

import { Paper } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { primaryColor } from 'assets/jss/material-dashboard-pro-react.jsx'

const useStyles = makeStyles((theme) => ({
    tableTitle: {
        padding: 0,
        '& h6': {
			border:'2px solid blue',
            color: '#3C4858',
            // fontSize: '18px !important',
            fontWeight: 800 + ' !important',
        },
        '& span': {
            color: primaryColor[0],
        },
        minHeight: '0px',
    },
    headerStyle: {
		//border: '2px solid green',
        color: primaryColor[0],
        fontWeight: '700',
        fontSize: '15px !important',
        background: '#f2f2f2',
        padding: '10px 16px',
        '&:first-child': {
            borderRadius: '10px 0 0 0',
        },
        '&:last-child': {
            borderRadius: '0 10px 0 0',
        },
    },
    tableCell: {
        padding: '10px 16px !important',
    },
    tableBody: {
        '& tbody': {
            border: 'none',
        },
        '& tfoot': {
            '& tr': {
                '& td': {
                    borderBottom: 'none',
                },
            },
        },
    },
    acttions: {
        color: 'blue',
    },
}))

const CustomMaterialTable = ({
    data,
    search,
    columns,
    title,
    noPaging,
    ...props
}) => {
    const classes = useStyles()

    return (
        <MaterialTable
            components={{
                Container: (props) => (
                    <Paper
                        {...props}
                        elevation={0}
                        classes={{ root: classes.tableBody }}
                    />
                ),
                Toolbar: (props) => (
                    <MTableToolbar
                        {...props}
                        classes={{ root: classes.tableTitle }}
                    />
                ),
                Cell: (props) => (
                    <MTableCell
                        {...props}
                        classes={{ root: classes.tableCell }}
                    />
                ),
                Header: (props) => (
                    <MTableHeader
                        {...props}
                        classes={{ header: classes.headerStyle }}
                    />
                ),
            }}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Buscar',
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'linhas',
                },
                body: {
                    emptyDataSourceMessage: 'Não foram encontrados resultados',
                },
            }}
            title={title ? title : null}
            columns={columns}
            data={data}
            options={{
                search: search ? search : false,
                paging: noPaging ? false : true,
            }}
            {...props}
        />
    )
}

export default CustomMaterialTable
