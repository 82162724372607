import React from 'react'
import { GridItem, MuiTypography } from '../../../../components/storybook'

export function Warning() {
    return (
        <GridItem xs={12} sm={12} md={12} lg={12} mb={3}>
            <MuiTypography
                color="#333333"
                fontSize="14px"
                sx={{ lineHeight: 1.8 }}
            >
                Ao converter seus {localStorage?.programCurrencyName} para
                carteira digital, você poder utilizar o valor como um método de
                pagamento para suas compras ou realizar solicitações de
                transferências.
            </MuiTypography>{' '}
            <MuiTypography
                color="#333333"
                fontSize="14px"
                sx={{ lineHeight: 1.8 }}
            >
                Valor mínimo para conversão é de R$ 1,00
            </MuiTypography>
        </GridItem>
    )
}
