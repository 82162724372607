/* eslint-disable no-unused-expressions */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import NotificationBox from './comp/NotificationBox'
import UserBox from './comp/UserBox'
import compose from 'utils/compose'
import { GetConnectionId } from 'redux/api/signalr'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
// css
import styles from './styles.module.scss'
import * as S from './styles.js'

class Banner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            alerts: '',
            themeColor: GetThemeColorFromStorage(),
        }
    }

    async componentDidMount() {
        const connectId = await GetConnectionId()
        if (connectId) {
            connectId?.forEach((item) => {
                if (item?.type?.value === 'ALERT') {
                    this.setState({ alerts: item })
                }
            })
        }
    }

    render() {
        return (
            <S.Parent themeColor={this.state?.themeColor}>
                <S.Container>
                    <NavLink to="/home">
                        <S.Logo>
                            <img
                                src={
                                    this.state?.themeColor?.images
                                        ?.internalPages?.menuLogo?.url
                                }
                                alt="Logo"
                            />
                        </S.Logo>
                    </NavLink>
                    <S.RightBox>
                        <UserBox
                            userData={this.props.userData}
                            themeColor={this.state?.themeColor}
                        />

                        <NotificationBox
                            userData={this.props.userData}
                            alerts={this.state.alerts}
                        />
                    </S.RightBox>
                </S.Container>
            </S.Parent>
        )
    }
}

export default compose(withRouter)(Banner)
