import React, { Fragment, useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from './Card.jsx'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const TransferRequest_Cards = ({
	choosenPaymentOption,
	themeColor,
	disabledForm,
	setChoosenPaymentOption,
	classes,
	bankPaymentOption,
	pixPaymentOption,
	userBank,
	userPix,
	setRequestedWithoutSelectAnOption,
}) => {

	const mask_cpf = (value) => {
		if (value?.length <= 11) {
			return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
		}
		return value?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
	}

	const mask_cell = (value) =>
		value?.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
	const mask_aleatory = (value) =>
		value?.replace(/(\d{8})(\d{4})(\d{4})(\d{4})(\d{11})/, '$1-$2-$3-$4-$5')

	const [maskedPixValue, setMaskedPixValue] = useState(userPix.pixKey)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const maskInput = () => {
		let desiredValue = ''
		switch (userPix.pixKeyType) {
			case `CPF`:
				desiredValue = mask_cpf(userPix.pixKey)
				break

			case `Celular`:
				desiredValue = mask_cell(userPix.pixKey)
				break
			case `Chave aleatória`:
				desiredValue = mask_aleatory(userPix.pixKey)
				break
			default:
				desiredValue = userPix.pixKey
				break
		}
		setMaskedPixValue(desiredValue)
	}

	useEffect(() => {
		maskInput()
	}, [maskInput, userPix])
	return (
		<Fragment>
			<b
				style={{
					color: GetThemeColorFromStorage()?.titles?.secondary
						?.textColor,
				}}
			>
				{'Selecione uma conta de destino para transferência'}
			</b>

			<GridContainer
				style={{ marginTop: '10px', height: '145px', paddingRight: '0px !important' }}
			>
				<Card
					isSelected={choosenPaymentOption === pixPaymentOption}
					title={'Pix'}
					themeColor={themeColor}
					disabledForm={disabledForm}
					choosenPaymentOption={choosenPaymentOption}
					thisPaymentOption={pixPaymentOption}
					optionNumber={1}
					classes={classes}
					setRequestedWithoutSelectAnOption={
						setRequestedWithoutSelectAnOption
					}
					setChoosenPaymentOption={setChoosenPaymentOption}
					// labels
					label1={userPix?.pixKeyType === 'CPF'
								&& userPix.pixKey?.length > 11 ? 'CNPJ' : userPix?.pixKeyType
					}
					value1={maskedPixValue}
				/>

				<Card
					isSelected={choosenPaymentOption === bankPaymentOption}
					title={'Conta bancária'}
					themeColor={themeColor}
					disabledForm={disabledForm || userPix?.value || userPix?.pixKey}
					choosenPaymentOption={choosenPaymentOption}
					thisPaymentOption={bankPaymentOption}
					optionNumber={2}
					classes={classes}
					setRequestedWithoutSelectAnOption={
						setRequestedWithoutSelectAnOption
					}
					setChoosenPaymentOption={setChoosenPaymentOption}
					// labels
					label1={'Banco'}
					value1={userBank.name}
					label2={'Agência'}
					value2={userBank.agency}
					label3={'Conta'}
					value3={userBank.account?.replace(/\D/g, '')}
					label4={'Dígito'}
					value4={userBank.digit}
				/>
			</GridContainer>
		</Fragment>
	)
}

export default TransferRequest_Cards
