import React from 'react'
import styled from 'styled-components/macro'
import { Checkbox, FormControl, withStyles } from '@material-ui/core'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const CustomFormGroup = styled(FormControl)`
    p,
    b {
        margin: 0;
        color: #333333;
        font-size: 14px !important;

        a {
            color: #21409a;
        }
    }
`

export const CValeCheckbox = withStyles(() => {
    const themeColor = GetThemeColorFromStorage()

    return {
        root: {
            color: themeColor?.navigation?.buttons?.backgroundColor,
            '&$checked': {
                color: themeColor?.navigation?.buttons?.backgroundColor,
                fontSize: '14px !important',
            },
        },
        checked: {},
    }
})((props) => (
    <Checkbox
        color="default"
        {...props}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        viewBox={'0 0 14px 14px'}
    />
))

export const CustomDiv = styled.div`
    @media screen and (max-width: 425px) {
        height: 180px;
    }
`
