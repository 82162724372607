/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { MuiStack } from "../Stack";
import { Pagination as MuiPagination } from "@mui/material";
import { MenuItem, Select, Divider } from "@mui/material";
import { FaAngleDown } from "react-icons/fa6";
import { MuiBox } from "../Box";
import { MuiTypography } from "../Typography";
export function Pagination(_a) {
    var onChangePage = _a.onChangePage, changeRowsPerPage = _a.changeRowsPerPage, defaultRows = _a.defaultRows, totalItems = _a.totalItems, defaultColor = _a.defaultColor;
    var _b = useState(1), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = useState(defaultRows !== null && defaultRows !== void 0 ? defaultRows : "5"), rowsPerPage = _c[0], setRowsPerPage = _c[1];
    var _d = useState(false), selectOpen = _d[0], setSelectOpen = _d[1];
    var toggleSelect = function () {
        setSelectOpen(!selectOpen);
    };
    useMemo(function () {
        if (totalItems) {
            setCurrentPage(1);
        }
    }, [totalItems]);
    return (_jsxs(MuiStack, __assign({ "data-testid": "table-pagination", direction: "row", justifyContent: "flex-end", alignItems: "center", spacing: 1.5, sx: {
            ".MuiPaginationItem-previousNext, .MuiPaginationItem-firstLast ": {
                backgroundColor: "#F0F0F0",
                padding: "5px",
                color: "#636363",
            },
            ".MuiPaginationItem-sizeMedium": {
                minHeight: "33px",
            },
            ".Mui-selected": {
                background: "".concat(defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C", " !important"),
                color: "#fff",
                border: "3px solid #F0F0F0",
                padding: "5px",
            },
            "& li": {
                "&:not(:first-child):not(:nth-child(2)):not(:last-child):not(:nth-last-child(2))": {
                    backgroundColor: "#F0F0F0",
                },
                "&:last-child": {
                    borderRadius: totalItems > 1 ? "0px 20px 20px 0px" : 0,
                },
                "&:nth-child(3)": {
                    borderRadius: totalItems > 1 ? "20px 0px 0px 20px" : "20px",
                    "& button": {
                        margin: "0px",
                    },
                },
                "&:nth-last-child(3)": {
                    borderRadius: totalItems > 1 ? "0px 20px 20px 0px" : "20px",
                    "& button": {
                        margin: "0px",
                    },
                },
            },
        } }, { children: [_jsxs(MuiStack, __assign({ direction: "row", spacing: 2, justifyContent: "center", alignItems: "center" }, { children: [_jsxs(MuiTypography, { children: [rowsPerPage, " linhas"] }), _jsxs(MuiBox, { children: [_jsxs(Select, __assign({ "data-testid": "pagination-rows-selected", value: String(rowsPerPage), onChange: function (e) {
                                    setRowsPerPage(e.target.value);
                                    changeRowsPerPage(Number(e.target.value));
                                    // onChangePage(1);
                                    setCurrentPage(1);
                                }, open: selectOpen, onClose: function () { return setSelectOpen(false); }, onOpen: function () { return setSelectOpen(true); }, displayEmpty: true, sx: {
                                    opacity: 0,
                                    height: "1px",
                                    width: "1px",
                                } }, { children: [_jsx(MenuItem, __assign({ value: 5 }, { children: "5" })), _jsx(MenuItem, __assign({ value: 10 }, { children: "10" })), _jsx(MenuItem, __assign({ value: 20 }, { children: "20" })), _jsx(MenuItem, __assign({ value: 30 }, { children: "30" })), _jsx(MenuItem, __assign({ value: 50 }, { children: "50" })), _jsx(MenuItem, __assign({ value: 100 }, { children: "100" }))] })), _jsx(MuiBox, __assign({ "data-testid": "pagination-rows-action", onClick: toggleSelect, sx: {
                                    padding: 0,
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#F0F0F0",
                                    zIndex: 9,
                                    position: "relative",
                                    marginTop: "-15px",
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                } }, { children: _jsx(FaAngleDown, { size: 14 }) }))] }), _jsx(MuiBox, { children: _jsx(Divider, { orientation: "vertical", flexItem: true, sx: { height: "30px" } }) })] })), _jsx(MuiPagination, { count: totalItems, page: currentPage, showFirstButton: true, showLastButton: true, onChange: function (e, value) {
                    onChangePage(value);
                    setCurrentPage(value);
                } })] })));
}
