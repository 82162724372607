import styled from 'styled-components/macro'

import { Grid } from '@material-ui/core'
import {
    GetThemeColorFromStorage,
    colorLuminance,
} from '../../../../redux/api/themeColor'

export const Container = styled.div``
export const RowContainer = styled.div`
    display: flex;
`

export const BtnContainer = styled.div`
    display: flex;
    width: 100px !important;
    box-sizing: border-box !important;
`

export const BtnArrow = styled.button`
    border: none;
    background-color: ${(props) =>
        props.colorValue % 2 === 0 ? '#ececec' : '#f4f4f4'} !important;
    display: flex;
    width: 60px;
`
export const BtnDetail = styled.button`
    border: none;
    background-color: ${GetThemeColorFromStorage()?.navigation?.buttons
        ?.backgroundColor};
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    display: flex;
    margin: 3px 0;
    height: 190px;

    &:hover {
        background-color: ${colorLuminance(
            GetThemeColorFromStorage()?.navigation?.buttons?.backgroundColor,
            -0.2,
        )};
    }

    @media only screen and (min-width: 500px) {
        height: 104px;
    }
    @media only screen and (min-width: 1000px) {
        height: 62px;
    }
`
export const PositionArrow = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    height: 185px;
    width: 100%;
    @media only screen and (min-width: 500px) {
        height: 99px;
    }
    @media only screen and (min-width: 1000px) {
        height: 60px;
    }
`

export const FirstContain = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const ContentLabel = styled.div`
    width: 100%;
    @media only screen and (min-width: 500px) {
        width: 49%;
    }
    @media only screen and (min-width: 1000px) {
        width: 25%;
    }
`
export const SecContain = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const TableGrid = styled.div`
    border-radius: 5px 0 0 5px;
    background-color: ${(props) =>
        props.colorValue % 2 === 0 ? '#ececec' : '#f4f4f4'} !important;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    width: 100%;

    .buttonCampaign {
        height: 100%;
        text-align: center;
        display: flex;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        float: right;
        cursor: pointer;
        width: 100%;
        &:hover {
            background-color: ${GetThemeColorFromStorage()?.navigation?.buttons
                ?.backgroundColor};
        }
    }
    .label {
        font-weight: 600;
    }
    .textValue {
        font-size: 0.8rem !important;
    }
`

export const MainGrid = styled(Grid)`
    .buttonGrid {
        background-color: #ececec;
    }
    .buttonCampaign {
        height: 100%;
        background-color: #21409a;
        text-align: center;
        display: flex;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        float: right;
        cursor: pointer;
        width: 65%;
        &:hover {
            background-color: #0f2872;
        }
    }
`
