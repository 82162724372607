export const styleModalTerms = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 1000,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
    minHeight: '90vh',
}

export const styleModalCode = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 635,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: '45px 70px',
    textAlign: 'center',
}

export const styleModalConfirmedPassword = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 605,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: '45px 145px',
    textAlign: 'center',
}

export const styleModalConfirmPassword = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: '45px 70px',
    textAlign: 'center',
}

export const styleInsufficientAccountModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 640,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: '25px',
    textAlign: 'center',
}
