/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MuiStack } from "../Stack";
import { MuiBox } from "../Box";
import { MuiTypography } from "../Typography";
export function LabelText(_a) {
    var label = _a.label, value = _a.value, required = _a.required, isEditData = _a.isEditData;
    return (_jsxs(MuiStack, __assign({ "data-testid": "label-text" }, { children: [_jsx(MuiTypography, __assign({ "data-testid": "label-text-label", style: { color: isEditData ? "#333333" : "#8D8D8D", fontSize: "13px" } }, { children: "".concat(label).concat(required ? "*" : "") })), _jsx(MuiBox, __assign({ sx: {
                    width: "100%",
                } }, { children: value && (_jsx(MuiTypography, __assign({ "data-testid": "label-text-value", style: { color: "#333333", marginTop: 10, fontSize: "15px" } }, { children: value }))) }))] })));
}
