/* eslint-disable import/first */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Box } from "@mui/material";
import styled from "styled-components";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  * {\n    scrollbar-width: auto;\n    scrollbar-color: #dfdfdf #ffffff;\n  }\n\n  *::-webkit-scrollbar {\n    width: 16px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: #ffffff;\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background-color: #dfdfdf;\n    border-radius: 10px;\n    border: 3px solid #ffffff;\n  }\n\n  .select-container {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  * {\n    scrollbar-width: auto;\n    scrollbar-color: #dfdfdf #ffffff;\n  }\n\n  *::-webkit-scrollbar {\n    width: 16px;\n  }\n\n  *::-webkit-scrollbar-track {\n    background: #ffffff;\n  }\n\n  *::-webkit-scrollbar-thumb {\n    background-color: #dfdfdf;\n    border-radius: 10px;\n    border: 3px solid #ffffff;\n  }\n\n  .select-container {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n  }\n"])));
export var SelectContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  padding: 4px 12px;\n  align-items: center;\n  border-radius: 5px;\n  border: 1px solid #c8c8c8;\n  min-width: 290px;\n  max-width: 290px;\n  padding-right: 15px;\n  width: ", ";\n  justify-content: space-between;\n  background: #ffffff 0% 0% no-repeat padding-box;\n\n  .selected-name {\n    text-align: left;\n    font: normal normal normal 14px Roboto;\n    letter-spacing: 0px;\n    color: #333333;\n    opacity: 1;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    font-weight: 500;\n  }\n"], ["\n  display: flex;\n  padding: 4px 12px;\n  align-items: center;\n  border-radius: 5px;\n  border: 1px solid #c8c8c8;\n  min-width: 290px;\n  max-width: 290px;\n  padding-right: 15px;\n  width: ", ";\n  justify-content: space-between;\n  background: #ffffff 0% 0% no-repeat padding-box;\n\n  .selected-name {\n    text-align: left;\n    font: normal normal normal 14px Roboto;\n    letter-spacing: 0px;\n    color: #333333;\n    opacity: 1;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    font-weight: 500;\n  }\n"])), function (_a) {
    var fullWidth = _a.fullWidth, width = _a.width;
    return (fullWidth ? "100%" : width ? width : "auto");
});
export var OptionsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n\n  min-width: 290px;\n  max-width: 290px;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  border: 1px solid #c8c8c8;\n  border-radius: 4px;\n  opacity: 1;\n  padding: 11px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  max-height: 280px;\n\n  z-index: 1200;\n\n  .divisions-list-item {\n    display: flex;\n    align-items: center;\n    height: 40px;\n    gap: 7px;\n    min-width: 290px;\n    max-width: 290px;\n  }\n\n  .divisions-name {\n    white-space: nowrap;\n    text-align: left;\n    font: normal normal normal 15px Roboto;\n    letter-spacing: 0px;\n    color: #333333;\n    opacity: 1;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n\n  min-width: 290px;\n  max-width: 290px;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  border: 1px solid #c8c8c8;\n  border-radius: 4px;\n  opacity: 1;\n  padding: 11px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  max-height: 280px;\n\n  z-index: 1200;\n\n  .divisions-list-item {\n    display: flex;\n    align-items: center;\n    height: 40px;\n    gap: 7px;\n    min-width: 290px;\n    max-width: 290px;\n  }\n\n  .divisions-name {\n    white-space: nowrap;\n    text-align: left;\n    font: normal normal normal 15px Roboto;\n    letter-spacing: 0px;\n    color: #333333;\n    opacity: 1;\n  }\n"])));
export var DivisionItem = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 4px 0px 0px 4px;\n  opacity: 1;\n  width: 5px;\n  height: 20px;\n"], ["\n  border-radius: 4px 0px 0px 4px;\n  opacity: 1;\n  width: 5px;\n  height: 20px;\n"])));
export var Label = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: left;\n  font: normal normal normal 13px Roboto;\n  letter-spacing: 0px;\n  color: #989898;\n  opacity: 1;\n  padding-left: 11px;\n"], ["\n  text-align: left;\n  font: normal normal normal 13px Roboto;\n  letter-spacing: 0px;\n  color: #989898;\n  opacity: 1;\n  padding-left: 11px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
