import React from 'react';
import Skeleton from 'react-loading-skeleton'
import styles from '../../styles.module.scss'

export function CustomSkeleton() {
	return (
		<div>
			<div className={styles.boxCategory}>
				<div
					style={{ marginRight: '20px', display: 'inline-grid' }}
				>
					<span>
						<Skeleton height={30} width={200} />{' '}
					</span>
					<span>
						<Skeleton height={30} width={200} />{' '}
					</span>
				</div>
				<Skeleton height={70} width={50} />
			</div>
			<div style={{ marginTop: '25px', marginBottom: '20px' }}>
				<p>
					<Skeleton height={25} />
				</p>
			</div>
			<div
				style={{
					marginTop: '25px',
					marginBottom: '20px',
					display: 'flex',
				}}
			>
				<div style={{ width: '15%' }}>
					<Skeleton height={98} width={135} />
				</div>
				<div style={{ marginLeft: '20px', width: '85%' }}>
					<Skeleton height={22} width={100} />
					<Skeleton height={22} count={2} />
					<Skeleton height={22} width={340} />
				</div>
			</div>
			<div
				style={{
					marginTop: '20px',
					marginBottom: '20px',
					display: 'flex',
				}}
			>
				<div style={{ width: '15%' }}>
					<Skeleton height={98} width={135} />
				</div>
				<div style={{ marginLeft: '20px', width: '85%' }}>
					<Skeleton height={22} width={100} />
					<Skeleton height={22} count={2} />
					<Skeleton height={22} width={340} />
				</div>
			</div>
			<div
				style={{
					marginTop: '20px',
					marginBottom: '20px',
					display: 'flex',
				}}
			>
				<div style={{ width: '15%' }}>
					<Skeleton height={98} width={135} />
				</div>
				<div style={{ marginLeft: '20px', width: '85%' }}>
					<Skeleton height={22} width={100} />
					<Skeleton height={22} count={2} />
					<Skeleton height={22} width={340} />
				</div>
			</div>
		</div>
	)
}
