import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
    generalRulesData: {},
    generalRulesDataFailed: false,
    programDescriptionData: {},
    programDescriptionDataFailed: false,
    digitalWalletRulesData: {},
    digitalWalletRulesDataFailed: false,
}

const fetchDigitalWalletRulesData = (state, action) => {
    return updateObject(state, {
        digitalWalletRulesData: action.digitalWalletRulesData,
    })
}

const fetchGeneralRulesData = (state, action) => {
    return updateObject(state, {
        generalRulesData: action.generalRulesData,
    })
}

const fetchProgramDescriptionData = (state, action) => {
    return updateObject(state, {
        programDescriptionData: action.programDescriptionData,
    })
}

// DATA FAIL
const fetchGeneralRulesFail = (state, action) => {
    return updateObject(state, {
        generalRulesdataFailed: action.generalRulesdataFailed,
    })
}

const fetchProgramDescriptionFail = (state, action) => {
    return updateObject(state, {
        programDescriptionDataFailed: action.programDescriptionDataFailed,
    })
}

const fetchDigitalWalletRulesError = (state, action) => {
    return updateObject(state, {
        digitalWalletRulesDataFailed: action.digitalWalletRulesDataFailed,
    })
}

//
const generalRulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_GENERAL_RULES_DATA:
            return fetchGeneralRulesData(state, action)
        case actionTypes.FETCH_GENERAL_RULES_FAILED:
            return fetchGeneralRulesFail(state, action)
        case actionTypes.FETCH_PROGRAM_DESCRIPTION_DATA:
            return fetchProgramDescriptionData(state, action)
        case actionTypes.FETCH_PROGRAM_DESCRIPTION_FAILED:
            return fetchProgramDescriptionFail(state, action)
        case actionTypes.FETCH_DIGITAL_WALLET_RULES_DATA:
            return fetchDigitalWalletRulesData(state, action)
        case actionTypes.FETCH_DIGITAL_WALLET_RULES_FAILED:
            return fetchDigitalWalletRulesError(state, action)
        default:
            return state
    }
}

export default generalRulesReducer
