import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
    platformRulesData: null,
    platformRulesDataFailed: false,
    consumerPlatformRulesData: null,
    consumerPlatformRulesFailed: false,
}

const fetchPlatformRulesData = (state, action) => {
    return updateObject(state, {
        platformRulesData: action.platformRulesData,
    })
}

const postConsumerPlatformRulesData = (state, action) => {
    return updateObject(state, {
        consumerPlatformRulesData: action.consumerPlatformRulesData,
    })
}

//
// DATA FAIL
const fetchPlatformRulesFail = (state, action) => {
    return updateObject(state, {
        platformRulesDataFailed: action.platformRulesDataFailed,
    })
}

const postConsumerPlatformRulesFail = (state, action) => {
    return updateObject(state, {
        consumerPlatformRulesFailed: action.consumerPlatformRulesFailed,
    })
}

const platformRules = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PLATFORM_RULES_DATA:
            return fetchPlatformRulesData(state, action)
        case actionTypes.FETCH_PLATFORM_RULES_FAILED:
            return fetchPlatformRulesFail(state, action)
        case actionTypes.POST_PLATFORM_RULES_DATA:
            return postConsumerPlatformRulesData(state, action)
        case actionTypes.POST_PLATFORM_RULES_FAILED:
            return postConsumerPlatformRulesFail(state, action)

        default:
            return state
    }
}

export default platformRules
