import Axios from 'axios'

export const GetNegotiation = async () => {
    const response = await Axios.get(`/Negotiation/GetExtractList`)
    if (response.data.results.length > 0) {
        return localStorage.setItem('showNegotiations', true)
    } else {
        return localStorage.removeItem('showNegotiations')
    }
}
