import { Tooltip } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

export const inputPropsStyles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#333333!important',
        },
    },
    cssFocused: {},
    notchedOutline: {
        color: '#333333!important',
    },
})

export const inputLabelPropsStyles = () => ({
    label: {
        '&$focusedLabel': {
            color: '#333333!important',
        },
    },
    focusedLabel: {},
})

export const PwdTooltip = withStyles({
    tooltip: {
        textAlign: 'left!important',
        maxWidth: '500px!important',
        padding: '8px 15px',
        borderRadius: '10px!important',
        '@media (max-width: 780px)': {
            maxWidth: '300px!important',
            marginRight: '10px',
        },
    },
})(Tooltip)