import { GetGeneralRules } from '../../../redux/api/generalRules'

export default (
    setMinTransferValue,
    setMaxTransferValue,
    setDaysToProcessTransfer,
    setAllowUserTransfer,
    setGeneralRules,
    showModal,
    history,
) => {
    GetGeneralRules()
        .then((res) => {
            setMinTransferValue(res?.minimumLimitTransfer)
            setMaxTransferValue(res?.maximumLimitTransfer)
            setDaysToProcessTransfer(res?.daysToProcessTransfer)
            setAllowUserTransfer(res?.allowTransfer)
            setGeneralRules(res)

            if (!res?.allowTransfer) {
                history.push(`/home`)
            }
        })
        .catch((err) => {})
}
