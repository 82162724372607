import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { grayColor } from 'assets/jss/material-dashboard-pro-react.jsx'

export default withStyles({
    root: {
        '& label.Mui-focused': {
            color: grayColor[2],
        },
        '& label': {
            fontSize: '14px',
            lineHeight: 1.2,
            zIndex: 0,
            color: '#717171',
            fontWeight: 300
        },
        '& fieldset': {
            borderColor: 'hsl(0,0%,85%)'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -8px) scale(0.75)'
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            transform: 'translate(14px, -14px) scale(0.75)',
            color: grayColor[2],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: grayColor[2],
        },
        '& .MuiOutlinedInput-root': {
            color: "#838383",
            fontFamily: "roboto",
            fontWeight: 400,
            '&:not(.Mui-disabled)': {
                '&:hover fieldset': {
                    borderColor: grayColor[2],
                },
                '&.Mui-focused fieldset': {
                    borderColor: grayColor[2],
                },
            },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: 'hsl(0,0%,95%)',
            zIndex: -1,
            '& fieldset': {
                borderColor: 'hsl(0,0%,95%)'
            }
        }
    },
})(TextField);


