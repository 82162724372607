import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Style from './styles'
import RegisterCheckbox from 'components/RegisterCheckbox'
import UseTermsModal from './components/UseTermsModal'
import RegulationModal from './components/RegulationModal'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

function SelectAll({ errors, control, watch, setValue, generalRules, value }) {
    const [themeColor, setThemeColor] = useState(GetThemeColorFromStorage())
    const { t } = useTranslation()
    const [open, setOpen] = useState({
        useTerms: false,
        regulation: false,
    })

    const termRef = useRef(false)
    const regulationRef = useRef(false)

    const handleClick = (name) => {
        if (name === 'useTerms') {
            if (termRef.current) {
                termRef.current.innerHTML = generalRules.useTerms
            }
        }

        if (name === 'regulation') {
            if (regulationRef.current) {
                regulationRef.current.innerHTML = generalRules.regulation
            }
        }
        setOpen({ ...open, [name]: !open[name] })
    }

    const optionsArray = [
        {
            name: 'selectAll',
            label: <b>{t('Select_All')}</b>,
        },
        {
            name: 'useTerms',
            label: (
                <p style={{ display: 'flex' }}>
                    {t('FIELD_REGISTER_ACCEPT')}
                    <Link
                        style={{
                            marginLeft: '4px',
                            color: themeColor?.titles?.highlight?.textColor,
                        }}
                        to="#"
                        onClick={() => handleClick('useTerms')}
                    >
                        {t('TITLE_PLATFORM_RULES_USE_TERMS')}
                    </Link>
                    .
                </p>
            ),
        },

        {
            name: 'useRegulation',
            label: (
                <p style={{ display: 'flex', height: '20px' }}>
                    {t('FIELD_REGULATION_ACCEPT')}{' '}
                    <Link
                        style={{
                            marginLeft: '4px',
                            display: 'contents',
                            color: themeColor?.titles?.highlight?.textColor,
                        }}
                        to="#"
                        onClick={() => handleClick('regulation')}
                    >
                        {t('FIELD_REGULATION_PROGRAM')}
                    </Link>
                    .
                </p>
            ),
        },
        {
            name: 'usePrivacyPolicy',
            label: (
                <p style={{ display: 'flex' }}>
                    {t('ACCEPTED')}
                    <Link
                        style={{
                            marginLeft: '4px',
                            color: themeColor?.titles?.highlight?.textColor,
                        }}
                        to="/privacyPolicies"
                    >
                        {t('TITLE_PLATFORM_RULES_PRIVACY_POLICY')}
                    </Link>
                    .
                </p>
            ),
        },
        {
            name: 'newsletter',
            label: (
                <p style={{ display: 'flex', height: '20px' }}>
                    {t('FIELD_REGISTER_NOTIFICATION')}.
                </p>
            ),
        },
    ]

    const handleClose = () => {
        setOpen({
            useTerm: false,
            regulation: false,
        })
    }

    return (
        <Style.Container container item xs={12}>
            <RegisterCheckbox
                xs={6}
                options={optionsArray}
                {...{ errors, control, setValue, watch }}
            />
            <UseTermsModal {...{ open, ref: termRef, handleClose }} />
            <RegulationModal {...{ open, ref: regulationRef, handleClose }} />
        </Style.Container>
    )
}

export default memo(SelectAll)
