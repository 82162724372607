import axios from 'axios'

//GET api/ConsumerWallet/GetExpirationPoints
export const GetConsumerWalletExpirationPoints = () =>
    axios
        .get(`/ConsumerWallet/GetExpirationPoints/`)
        .then((response) => response.data)

// GET api/extract
export const GetStatementData = (
    partnerId,
    searchType,
    dateFrom,
    dateTo,
    pageIndex,
    pageSize,
) => {
    let args = ''
    if (partnerId && partnerId !== '') args = args + `&partnerId=${partnerId}`
    if (searchType && searchType !== '')
        args = args + `&entryType=${searchType}`
    if (dateFrom && dateFrom !== '') args = args + `&startDate=${dateFrom}`
    if (dateTo && dateTo !== '') args = args + `&endData=${dateTo}`
    return axios
        .get(
            `/ConsumerWallet/GetStatementByConsumer?page=${pageIndex}&pageSize=${pageSize}${args}`,
        )
        .then((response) => response.data)
}

export const GetConsumerDigitalWallet = async (filter) => {
    const response = await axios.get(
        `/ConsumerDigitalWallet/ExtractDigitalWalletDetails?${filter}`,
    )
    return response.data
}

export const GetSourceType = async () => {
    const response = await axios.get(
        `/ConsumerDigitalWallet/GetSourceTypeByConsumer`,
    )
    return response.data
}

export const GetEntryType = async () => {
    const response = await axios.get(`/ConsumerDigitalWallet/GetEntryType`)
    return response.data
}

export const GetTransactionType = async () => {
    const response = await axios.get(
        `/ConsumerDigitalWallet/GetTransactionTypeByConsumer`,
    )
    return response.data
}

export const GetWalletDetailsById = async (id) => {
    const response = await axios.get(`/Negotiation/GetExtractList`)
    return response.data
}

// // GET api/extract
// export const GetStatementDetails = (id) =>
//     axios.get(`/AccountStatement/${id}`)
//         .then(response => response.data)
