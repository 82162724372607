import React from 'react'
import {
    AlertModalIcon,
    Button,
    GridContainer,
    GridItem,
    MuiTypography,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

export function NoPaymentOptionModal({ handleProfileClick }) {
    return (
        <GridContainer>
            <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: 'center!important' }}
                mt={10}
            >
                <AlertModalIcon size={70} color="#F8BB86" />
            </GridItem>
            <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: 'center!important' }}
                mt={1}
            >
                <MuiTypography color="#333333" fontWeight={600} fontSize="14px">
                    Para realizar transferência é necessário configurar a conta
                    de destino.
                </MuiTypography>
            </GridItem>
            <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: 'center!important' }}
                mt={5}
            >
                <Button
                    customSize="15%"
                    defaultColor={
                        GetThemeColorFromStorage()?.navigation?.buttons
                            ?.backgroundColor
                    }
                    icon=""
                    outlined="outlined"
                    text="Configurar"
                    textColor="#FFFFFF"
                    typeVariation="CustomTextButton"
                    borderRadius="5px"
                    onClick={handleProfileClick}
                />
            </GridItem>
        </GridContainer>
    )
}
