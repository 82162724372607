import React from 'react'
import { Controller } from 'react-hook-form'
import { GridItem, MuiBox, MuiCheckbox } from '../../../../components/storybook'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'

export function AcceptTerms({ control, handleOpenModal, setValue }) {
    return (
        <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            mt={3}
            sx={{
                paddingLeft: '0px !important',
            }}
        >
            <MuiBox ml={2}>
                <Controller
                    name="acceptTerms"
                    control={control}
                    defaultValue=""
                    render={({ ...field }) => (
                        <MuiCheckbox
                            {...field}
                            labeltxt={
                                <span>
                                    Estou de acordo com o que está definido nos{' '}
                                    <span
                                        style={{
                                            color: GetThemeColorFromStorage()
                                                ?.navigation?.buttons
                                                ?.backgroundColor,
                                            cursor: 'pointer',
                                            fontWeight: 500,
                                        }}
                                        onClick={handleOpenModal}
                                    >
                                        termos e condições
                                    </span>{' '}
                                    sobre as transferências e conversão de
                                    valores.
                                </span>
                            }
                            onChange={(value) => setValue('acceptTerms', value)}
                        />
                    )}
                />
            </MuiBox>
        </GridItem>
    )
}
