/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
// REDUX
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import { NavLink, useHistory, useLocation } from 'react-router-dom'
import * as moment from 'moment'
import { withTranslation } from 'react-i18next'

import { Grid, Icon } from '@material-ui/core'

import { numberFormatText } from 'utils/utils'
import * as Styled from './styles'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'
// REDUX
import { useSelector } from 'react-redux'
import { GetDigitalWalletRulesData } from '../../redux/actions/generalRules'

//------
import { GetCurrentConsumer } from 'redux/actions'
import Loader from 'components/Loader/Loader.jsx'

import { withStyles } from '@material-ui/core/styles'
// UTILS
import compose from 'utils/compose'
import { Box } from '@mui/material'

import banner_consumidor from 'assets/img/virtualstore/banner_consumidor_loja.png'
import { GetUrlCategory, GetUrlNegotiation } from '../../redux/api/store'
import eventEmitter from './eventEmitter'

const DigitalWallet = ({ t, userData }) => {
    const myRef = useRef(null)
    const [programRules, setProgramRules] = useState([])
    const [negotiation, setNegotiation] = useState([])
    const [themeColor, setThemeColor] = useState({})
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const { generalRulesData } = useSelector((state) => state.generalRules)
    const { userData: reduxUserData } = useSelector((state) => state.users)
    const [pointsValue, setPointsValue] = useState(null)
    const [walletValue, setWalletValue] = useState(null)
    useEffect(() => {
        setProgramRules(generalRulesData)
    }, [reduxUserData, generalRulesData])

    const location = useLocation()

    const history = useHistory()

    useEffect(() => {
        const theme = GetThemeColorFromStorage()
        setThemeColor(theme)
        requestApi()
    }, []) //eslint-disable-line

    async function requestApi() {
        dispatch(GetDigitalWalletRulesData())
    }

    const [isTimeOut, setIsTimeOut] = useState(false)

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsTimeOut(!isTimeOut)
    //         try {
    //             dispatch(GetCurrentConsumer())
    //         } catch (error) {
    //             alert(error)
    //         }
    //     }, 60000)
    // }, [dispatch, isTimeOut])

    const getStoreId = async () => {
        setLoading(true)
        try {
            const response = await GetUrlCategory()

            const { data } = response
            window.open(data, '_blank')
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    const getNegotiation = async () => {
        try {
            const response = await GetUrlNegotiation()

            setNegotiation(response?.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getNegotiation()
    }, [])

    useEffect(() => {
        const handleUpdatPoints = (newContent) => {
            if (myRef.current) {
                setPointsValue(newContent)
            }
        }
        const handleUpdateWalletValue = (newContent) => {
            if (myRef.current) {
                setWalletValue(newContent)
            }
        }

        eventEmitter.on('updatePoints', handleUpdatPoints)
        eventEmitter.on('updateWalletValue', handleUpdateWalletValue)

        return () => {
            eventEmitter.off('updatePoints', handleUpdatPoints)
            eventEmitter.off('updateWalletValue', handleUpdateWalletValue)
        }
    }, [])

    return (
        <Styled.DigitalWallet newTheme={themeColor}>
            {loading && <Loader />}
            <Grid container className="wallet">
                <Grid item xs={12} className="user-name">
                    {userData ? userData.name : t('FIELD_USER')}
                </Grid>
                <Grid item xs={12}>
                    {t('FIELD_STATUS') + ':'}{' '}
                    <span className="status">
                        {userData && userData.status
                            ? t(userData.status.value)
                            : t('NOT_AVAIBLE')}
                    </span>
                </Grid>
                <Grid item xs={12}>
                    {userData
                        ? t('REGISTER_DATE') +
                          ' ' +
                          moment(userData?.fidelityDate).format('L')
                        : t('REGISTER_DATE') + t('NOT_AVAIBLE')}
                </Grid>
            </Grid>
            <NavLink to={'/statement'}>
                <Grid
                    item
                    xs={12}
                    className={
                        location.pathname === '/statement'
                            ? 'wallet-buttom-focused'
                            : 'wallet-buttom'
                    }
                >
                    <div style={{ display: 'flex', marginRight: '10px' }}>
                        <Icon className="icon-destaque_saldo" />
                        <Icon className="icon-saldo_pontuacao" />
                    </div>
                    <Grid item container style={{ alignContent: 'center' }}>
                        <Grid
                            xs={12}
                            item
                            className="buttom-text"
                            style={{ marginLeft: '10px' }}
                        >
                            <span>{t('WALLET_BALANCE')}</span>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    lineHeight: '0.6',
                                }}
                            >
                                {` ${t('WALLET_POINTS')}`}
                                <br />
                                <span
                                    className="buttom-value"
                                    ref={myRef}
                                    key={pointsValue}
                                >
                                    {pointsValue
                                        ? numberFormatText(
                                              pointsValue,
                                              `${programRules.programCurrencySymbol} `,
                                              0,
                                          )
                                        : numberFormatText(
                                              userData?.points,
                                              `${programRules.programCurrencySymbol} `,
                                              0,
                                          )}
                                </span>
                            </span>
                        </Grid>
                    </Grid>
                    <i
                        className="icon-modal_seta_proximo"
                        style={{
                            fontSize: '13px',
                            fontWeight: 'bold',
                            position: 'relative',
                            top: '1px',
                            left: '4px',
                        }}
                    ></i>
                </Grid>
            </NavLink>

            {programRules?.allowDigitalWallet && (
                <NavLink to={'/wallet'}>
                    <Grid
                        xs={12}
                        item
                        className={
                            location.pathname === '/wallet'
                                ? 'wallet-buttom-focused'
                                : 'wallet-buttom'
                        }
                    >
                        <div style={{ display: 'flex', marginRight: '10px' }}>
                            <Icon className="icon-destaque_saldo" />
                            <Icon className="icon-carteira_digital" />
                        </div>
                        <Grid
                            item
                            container
                            style={{
                                alignContent: 'center',
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                className="buttom-text"
                                style={{ marginLeft: '10px' }}
                            >
                                <span>{t('WALLET_BALANCE')}</span>
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        lineHeight: '0.6',
                                    }}
                                >
                                    {` ${t('DIGITAL_WALLET')}`}
                                    <br />
                                    <span
                                        className="buttom-value"
                                        key={walletValue}
                                        ref={myRef}
                                    >
                                        {walletValue
                                            ? numberFormatText(
                                                  walletValue,
                                                  `${programRules.currencySymbol} `,
                                                  2,
                                              )
                                            : numberFormatText(
                                                  userData?.digitalWalletBalance,
                                                  `${programRules.currencySymbol} `,
                                                  2,
                                              )}
                                    </span>
                                </span>
                            </Grid>
                        </Grid>
                        <i
                            className="icon-modal_seta_proximo"
                            style={{
                                fontSize: '13px',
                                fontWeight: 'bold',
                                position: 'relative',
                                top: '1px',
                                left: '4px',
                            }}
                        ></i>
                    </Grid>
                </NavLink>
            )}
            {negotiation?.hasActiveNegotiation && (
                <NavLink to={'/negotiation'}>
                    <Grid
                        xs={12}
                        item
                        className={
                            location.pathname === '/negotiation'
                                ? 'wallet-buttom-focused'
                                : 'wallet-buttom'
                        }
                    >
                        <div style={{ display: 'flex', marginRight: '10px' }}>
                            <Icon className="icon-destaque_saldo" />
                            <Icon className="icon-carteira_digital" />
                        </div>
                        <Grid
                            item
                            container
                            style={{
                                alignContent: 'center',
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                className="buttom-text"
                                style={{ marginLeft: '10px' }}
                            >
                                <span>Saldos bloqueados</span>
                                <br />
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        lineHeight: '0.6',
                                    }}
                                >
                                    {numberFormatText(
                                        negotiation?.pointsBalanceBlocked,
                                        `${negotiation?.simpleRules?.programCurrencySymbol} `,
                                        0,
                                    )}{' '}
                                    |{' '}
                                    {numberFormatText(
                                        negotiation?.digitalWalletBalanceBlocked,
                                        `${negotiation?.simpleRules?.currencySymbol} `,
                                        2,
                                    )}
                                </span>
                            </Grid>
                        </Grid>
                        <i
                            className="icon-modal_seta_proximo"
                            style={{
                                fontSize: '13px',
                                fontWeight: 'bold',
                                position: 'relative',
                                top: '1px',
                                left: '4px',
                            }}
                        ></i>
                    </Grid>
                </NavLink>
            )}
            {programRules?.allowEcommerce && (
                <Box
                    sx={{
                        background:
                            themeColor?.navigation?.buttons?.backgroundColor ||
                            '#f58b3c',
                    }}
                >
                    <Box
                        component="img"
                        src={banner_consumidor}
                        alt="banner consumidor"
                        width="100%"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => getStoreId()}
                    />
                </Box>
            )}
        </Styled.DigitalWallet>
    )
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            // GetCurrentConsumer,
        },
        dispatch,
    )

export default compose(
    connect(mapDispatchToProps),
    withStyles(extendedFormsStyle),
    withTranslation(),
)(DigitalWallet)
