import React from 'react'

import {
    LupaIcon,
    MuiBox,
    MuiIconButton,
    MuiLegend,
    MuiTooltip,
    MuiTypography,
    TableConsumer,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { useMediaQuery } from '@mui/material'
import { formatDate, formatDateModal } from '../../functions'
import { useTranslation } from 'react-i18next'
import { statusColors, statusTooltip } from '../../utils'
import useNegotiation from '../../Hooks/useNegotiation'
import { useHistory } from 'react-router-dom'

export function Table({
    isLoadingData,
    negotiationData,
    tablePagination,
    getPaginatedNegotiations,
}) {
    const { t } = useTranslation()
    const history = useHistory()

    const matches = useMediaQuery('(max-width:600px)')

    return (
        <MuiBox
            mt={2}
            sx={{
                overflow: 'auto!important',
                '& .table tr:nth-child(even)': {
                    backgroundColor: '#FFFFFF!important',
                },
            }}
        >
            <TableConsumer
                columns={[
                    {
                        id: 'id',
                        label: '',

                        render: (rowData) => (
                            <>
                                <MuiTooltip
                                    title={
                                        statusTooltip[rowData?.status?.value]
                                    }
                                >
                                    <button
                                        style={{
                                            background: 'transparent',
                                            border: 'none',
                                        }}
                                    >
                                        <MuiLegend
                                            color={
                                                statusColors[
                                                    rowData?.status?.value
                                                ] ?? 'grey'
                                            }
                                            size="15px"
                                            borderRadius="20px"
                                        />
                                    </button>
                                </MuiTooltip>
                            </>
                        ),
                    },
                    {
                        align: 'left',
                        id: t('NEGOTIATION_CAMPAIGN'),
                        label: t('NEGOTIATION_CAMPAIGN'),
                        render: (rowData) => (
                            <MuiBox
                                sx={{
                                    width: '200px',
                                    overflow: 'hidden',
                                }}
                            >
                                <>
                                    <MuiTypography
                                        variant="body2"
                                        sx={{
                                            display: 'flex',
                                            color: '#636363',
                                        }}
                                    >
                                        {rowData?.negotiationCampaign?.value ??
                                            '-'}
                                    </MuiTypography>
                                    <MuiTypography
                                        variant="body2"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitLineClamp: 1,
                                            color: '#636363',
                                            fontSize: 12,
                                        }}
                                    >
                                        {formatDateModal(rowData?.createdAt) ??
                                            '-'}
                                    </MuiTypography>
                                </>
                            </MuiBox>
                        ),
                    },

                    {
                        align: 'left',
                        id: 'Vigência',
                        label: 'Vigência',
                        render: (rowData) =>
                            `${formatDate(rowData?.validFrom)} até ${formatDate(
                                rowData?.validUntil,
                            )}` ?? '-',
                    },
                    {
                        align: 'right',
                        id: 'Ações',
                        label: 'Ações',
                        padding: '0px!important',
                        render: (rowData) => (
                            <MuiIconButton
                                onClick={() => {
                                    history.push(
                                        `/negotiationDetails/${rowData.id}`,
                                    )
                                }}
                                sx={{
                                    backgroundColor:
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor,
                                    borderRadius: '0px 5px 5px 0px',
                                    padding: '20px 12px',
                                    ':hover': {
                                        backgroundColor:
                                            GetThemeColorFromStorage()
                                                ?.navigation?.buttons
                                                ?.backgroundColor,
                                    },
                                }}
                            >
                                <LupaIcon size={15} color="#FFFFFF" />
                            </MuiIconButton>
                        ),
                    },
                ]}
                firstItemPadding={
                    negotiationData?.length > 0
                        ? '10px 0px!important'
                        : '10px 16px!important'
                }
                padding="10px 16px"
                notFoundMessage={t('NEGOTIATION_NO_TRADES_FOUND')}
                onChangeRowsPerPage={() => {}}
                showPagination
                loading={isLoadingData}
                data={negotiationData}
                totalItems={tablePagination?.totalPages}
                defaultRows={10}
                onChangePage={(page) => {
                    getPaginatedNegotiations('page', page, tablePagination)
                }}
                changeRowsPerPage={(rowsPerPage) => {
                    getPaginatedNegotiations(
                        'pageSize',
                        rowsPerPage,
                        tablePagination,
                    )
                }}
                overflow={(matches && 'auto') || 'hidden'}
                defaultColor={
                    GetThemeColorFromStorage()?.navigation?.buttons
                        ?.backgroundColor
                }
            />
        </MuiBox>
    )
}
