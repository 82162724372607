import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { envs } from '../../redux/api/windowServerData'

export const BoxSuccess = styled(Grid)`
    height: 250px;

    @media only screen and (max-width: 464px) {
        height: 300px;
    }
`

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

export const SuccessBox = styled(Grid)`
    flex: 1;
    padding: 0 10px;

    .button-margin {
        margin-right: 15px;
        height: 38px;

        @media only screen and (max-width: 449px) {
            margin-right: 0px;
        }
    }

    div {
        img {
            width: 72px;
            height: 62px;
        }
        margin-bottom: 15px;
        p {
            margin: 0;
            text-align: center;
        }
        b {
            text-transform: uppercase;
        }
        button {
            margin-top: 30px;
            border-radius: 8px;
        }
    }
`
export const BoxInfoFlex = styled.div`
    height: 100px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 464px) {
        height: 120px;
    }
`

export const BoxImage = styled.div`
    background-image: ${({ themeColor }) =>
        themeColor ? ` url(${themeColor})` : ''};
`

export const GridContainerFooter = styled(GridContainer)`
    position: absolute;
    bottom: 0;
    background-color: ${({ themeColor }) =>
        themeColor?.footer?.backgroundColor};
    height: 60px;
`

export const BoxFooter = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
export const Box = styled.div`
    color: #666;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1150px;
    z-index: 5;
    margin-top: -20px;
    font-size: 0.8rem;
    opacity: 0.7;
`

export const getLocalStorage = (setProgramName) => {
    // if (`${envs.REACT_APP_CVALE_IDENTITY}` === 'true') {
    if (`${envs.REACT_APP_CVALE_IDENTITY}` === 'true') {
        setProgramName('C.Vale.')
        return
    }
    try {
        const serializedState = localStorage.getItem('GeneralRulesBasic')
        if (serializedState === null) {
            setProgramName('Fidelidade')
        }
        const rules = JSON.parse(serializedState)
        console.log('rules', rules)
        const programName = `do ${rules?.name || 'Fidelidade'}`
        serializedState && setProgramName(programName)
    } catch (err) {
        setProgramName('Fidelidade')
    }
}
