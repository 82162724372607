import * as React from 'react'
import * as Yup from 'yup'
import { history } from 'utils'

// @ Validators
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

// @ Material-ui
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Box, IconButton, InputAdornment, Skeleton } from '@mui/material'

// @ Components
import Button from 'components/CustomButtons/Button.jsx'
import SnackDefault from 'components/SnackDefault'
import { CssTextField } from 'components/CssTextField/CssTextField'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'

// @ Styles
import { ButtonBox, CardBox, Typography } from './styles'

// @ Redux
import {
    DeleteConsumer,
    ValidateConsumer,
    ValidateDelete,
} from '../../../../../redux/api/user'
import { FaWhatsapp, MuiStack } from '../../../../../components/storybook'
import { GetContact } from '../../../../../redux/api/footer'
import { formatCellAndTell } from '../../../../../utils/utils'

const ModalDelete = ({ open, handleClose, id, loader, closeLoader }) => {
    const { t } = useTranslation()
    const [isSkeleton, setIsSkeleton] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false)
    const [contact, setContat] = React.useState()

    const [snackStatus, setSnackStatus] = React.useState({
        open: false,
        severity: 'success',
        text: '',
    })
    const [account, setAccount] = React.useState({
        allowDelete: false,
        reason: '',
    })

    const { control, handleSubmit, errors, setError } = useForm({
        defaultValues: { password: '' },
        resolver: yupResolver(
            Yup.object().shape({
                password: Yup.string().required(t('REQUIRED_FIELD')),
            }),
        ),
    })

    const handleConfirmDelete = async (data) => {
        loader()
        try {
            const isValida = await ValidateConsumer(data.password) // Validate password
            if (isValida) {
                const response = await DeleteConsumer(id, data)

                if (response.status === 200 && response?.data?.success) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    await new Promise((resolve) => setTimeout(resolve, 1000))

                    history.push({
                        pathname: '/auth/login',
                        state: { deleteAccount: true },
                    })
                }

                if (
                    !response?.data?.success &&
                    response?.data?.errors?.length > 0
                ) {
                    const { message } = response?.data?.errors[0]
                    setSnackStatus({
                        open: true,
                        severity: 'error',
                        text: t(message || 'ERROR_DELETING_ACCOUNT'),
                    })
                }
            } else {
                setError('password', {
                    type: 'manual',
                    message: t('INVALID_PASSWORD'),
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            closeLoader()
        }
    }

    const DeleteValidate = async () => {
        setIsSkeleton(true)
        try {
            const response = await ValidateDelete(id)

            if (response.status === 200 && response?.data?.data) {
                const { allowDelete, reason } = response?.data?.data
                setAccount({
                    allowDelete: allowDelete,
                    reason: reason,
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsSkeleton(false)
        }
    }

    React.useEffect(() => {
        open && DeleteValidate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    React.useEffect(() => {
        GetContactInfo()
    }, [])

    const GetContactInfo = async () => {
        const res = await GetContact()
        res && setContat(res)
    }

    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Fade in={open}>
                {!isSkeleton ? (
                    <CardBox onSubmit={handleSubmit(handleConfirmDelete)}>
                        <SnackDefault
                            snackStatus={snackStatus}
                            handleCloseSnack={() =>
                                setSnackStatus((prevState) => ({
                                    ...prevState,
                                    open: false,
                                }))
                            }
                        />
                        <i className="icon-alerta_modal" />
                        <Box
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: '#21409A',
                            }}
                        >
                            {t('DELETE_ACCOUNT')}
                        </Box>
                        {!account.allowDelete && (
                            <Typography>{t('HAS_BALANCE')}</Typography>
                        )}
                        <Typography sx={{ lineHeight: '1.5' }}>
                            {t('DELETE_WARNING')}
                            <Box sx={{ color: '#DE2E2E' }}>
                                {t('IRREVERSIBLE_ACTION')}
                            </Box>
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <Typography sx={{ marginBottom: 1.5 }}>
                                Em caso de dúvidas, entre em contato:
                            </Typography>
                            {contact?.contactEmail && (
                                <Typography sx={{ marginBottom: 0.8 }}>
                                    E-mail: {contact?.contactEmail}
                                </Typography>
                            )}
                            {contact?.contactPhoneNumber2 && (
                                <MuiStack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={1}
                                    mb={0.8}
                                >
                                    {contact?.isContactPhoneNumber2Whatsapp &&
                                    contact?.contactPhoneNumber2 ? (
                                        <>
                                            <Typography>Celular:</Typography>
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px',
                                                }}
                                            >
                                                <FaWhatsapp
                                                    color="#01AF6E"
                                                    size={22}
                                                />{' '}
                                                {formatCellAndTell(
                                                    contact?.contactPhoneNumber2,
                                                )}
                                            </Typography>
                                        </>
                                    ) : (
                                        contact?.contactPhoneNumber2 && (
                                            <>
                                                <Typography>
                                                    Celular:
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                    }}
                                                >
                                                    {formatCellAndTell(
                                                        contact?.contactPhoneNumber2,
                                                    )}
                                                </Typography>
                                            </>
                                        )
                                    )}
                                </MuiStack>
                            )}
                            {contact?.contactPhoneNumber && (
                                <Typography>
                                    Telefone:{' '}
                                    {formatCellAndTell(
                                        contact.contactPhoneNumber,
                                    )}
                                </Typography>
                            )}
                        </Box>
                        <Typography>{t('CONFIRM_DELETION')}</Typography>
                        <Box component="div" width="100%" maxWidth="300px">
                            <Controller
                                as={
                                    <CssTextField
                                        label={
                                            <CustomRequiredField
                                                value={t('LOGIN_PASSWORD')}
                                            />
                                        }
                                        variant="standard"
                                        size="small"
                                        fullWidth
                                        error={errors.password}
                                        helperText={
                                            errors.password &&
                                            errors.password?.message
                                        }
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disableRipple
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword,
                                                            )
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                                name="password"
                                control={control}
                            />
                        </Box>
                        <ButtonBox>
                            <Button
                                size="sm"
                                color="greenBorderButtonColor"
                                onClick={handleClose}
                            >
                                {t('BUTTON_CANCEL')}
                            </Button>
                            <Button
                                size="sm"
                                color="greenButtonColor"
                                type="submit"
                            >
                                {t('BUTTON_CONFIRM')}
                            </Button>
                        </ButtonBox>
                    </CardBox>
                ) : (
                    <CardBox>
                        <Skeleton variant="circular" width={80} height={80} />
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={70}
                        />
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={70}
                        />
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={40}
                        />
                        <Box display="flex" gap={2}>
                            <Skeleton
                                variant="rectangular"
                                width={100}
                                height={40}
                                sx={{ borderRadius: 2 }}
                            />
                            <Skeleton
                                variant="rectangular"
                                width={100}
                                height={40}
                                sx={{ borderRadius: 2 }}
                            />
                        </Box>
                    </CardBox>
                )}
            </Fade>
        </Modal>
    )
}

ModalDelete.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    walletBalance: PropTypes.object,
    id: PropTypes.number,
    loader: PropTypes.func,
    closeLoader: PropTypes.func,
}

ModalDelete.defaultProps = {
    walletBalance: {
        digitalWalletBalance: 0,
        points: 0,
    },
}

export default ModalDelete
