import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import { useTranslation, withTranslation, Trans } from 'react-i18next'

import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx'
class HeaderLinks extends React.Component {
    state = {
        open: false,
        openLang: false,
    }
    handleClick = () => {
        this.setState({ open: !this.state.open })
    }
    handleClickLang = () => {
        this.setState({ openLang: !this.state.openLang })
    }
    handleClose = () => {
        this.setState({ open: false })
    }
    handleCloseLang = () => {
        this.setState({ openLang: false })
    }
    handleChangeLanguage = (lng) => {
        this.props.i18n.changeLanguage(lng)
        this.setState({ openLang: false })
    }
    render() {
        const { classes, rtlActive } = this.props
        const { open, openLang } = this.state
        const searchButton =
            classes.top +
            ' ' +
            classes.searchButton +
            ' ' +
            classNames({
                [classes.searchRTL]: rtlActive,
            })
        const dropdownItem = classNames(
            classes.dropdownItem,
            classes.primaryHover,
            { [classes.dropdownItemRTL]: rtlActive },
        )
        const wrapper = classNames({
            [classes.wrapperRTL]: rtlActive,
        })
        const managerClasses = classNames({
            [classes.managerClasses]: true,
        })
        return <div className={wrapper}></div>
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired,
    rtlActive: PropTypes.bool,
}

export default withStyles(adminNavbarLinksStyle)(withTranslation()(HeaderLinks))
