import React, { Fragment, useEffect } from 'react'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import { MenuItem } from '@material-ui/core'
import { CssTextField } from 'components/CssTextField/CssTextField'
import { useTranslation } from 'react-i18next'

function Account(value) {
    const conta = value?.replace(/\D/g, '').slice(0, 9)
    return conta.length >= 3
        ? conta.slice(0, conta.length - 1) + '-' + conta.slice(conta.length - 1)
        : conta
}
function Agency(value) {
    return value?.replace(/\D/g, '').slice(0, 5)
}
const inputsStyle = {
    marginBottom: '10px',
}

const BankAndPixForm_Bank = ({
    disabledForm_Account,
    touched,
    classes,
    errors,
    styles,
    selectList,
    setFieldTouched,
    setFieldValue,
    handleChange,
    setSelectedBankNumber,
    setNumberDigit,
    set_NumberDigit,
    setSelectedBankID,
    setSelectedBankBranch,
    setBankKey,
    setBankName,
    setAgency,
    setAccount,
    bankKey,
    agency,
    account,
    numberDigit,
}) => {
    const { t } = useTranslation()

    const clearValues = (bankKey) => {
        if (bankKey === '0' || bankKey === '') {
            setSelectedBankNumber('')
            setNumberDigit('')
            set_NumberDigit('')
            setSelectedBankBranch('')
            setAgency('')
            setAccount('')
        }
    }

    const change = (_name, e) => {
        switch (_name) {
            case 'bankType':
                e && e.persist()
                const bankSelectedValue = e.target.value

                setFieldValue(_name, '')
                let _bankName = ''
                selectList.forEach((item) => {
                    if (item.key === e.target.value) {
                        _bankName = item.value
                    }
                })
                setSelectedBankID(bankSelectedValue) // --> //pra API
                setBankKey(bankSelectedValue) //--> pro input seletc
                setBankName(_bankName) //
                break

            case 'agency':
                e && e.persist()
                const newAgencyValue = e.target.value
                setFieldValue(_name, '')
                setSelectedBankBranch(Agency(newAgencyValue)) //--> pra API
                setAgency(Agency(newAgencyValue)) //--> pro input value
                break

            case 'account':
                e && e.persist()
                const newAccountValue = e.target.value
                setFieldValue(_name, '')
                setSelectedBankNumber(Account(newAccountValue)) // ---> pra API
                setAccount(Account(newAccountValue)) // ---> pro input value
                break

            default:
                e && e.persist()
        }

        if (e && e.target && e.target.value) {
            if (e.target.value.length > 250) {
                e.target.value = e.target.value.substring(0, 250)
            }
        }

        handleChange(e)

        setFieldTouched(_name, true, false)
    }

    useEffect(() => {
        bankKey && clearValues(bankKey)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankKey])

    return (
        <Fragment>
            {/* DADOS BANCARIOS */}
            <GridItem xs={12} md={12} style={{ margin: '7px 0 20px' }}>
                <b style={{ fontWeight: 500 }}>{t('BANK_ACCOUNT_SENDING')}</b>
            </GridItem>

            {/* =============== BANCO seletor ============= */}

            <GridItem xs={12} sm={12} md={5} style={inputsStyle}>
                <CssTextField
                    fullWidth
                    select
                    label={
                        <CustomRequiredField
                            value={t('BANK')}
                            disabled={true}
                        />
                    }
                    id="bankType"
                    name="bankType"
                    error={
                        touched && touched.bankType && Boolean(errors.bankType)
                    }
                    value={bankKey}
                    onChange={change.bind(null, 'bankType')}
                    variant="outlined"
                    margin="dense"
                    disabled={disabledForm_Account}
                    SelectProps={{
                        IconComponent: (props) => {
                            return disabledForm_Account ? (
                                ''
                            ) : (
                                <span {...props}>
                                    <svg
                                        className={styles.SelectSvgIcon}
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        role="presentation"
                                    >
                                        <path d="M7 10l5 5 5-5z"></path>
                                    </svg>
                                </span>
                            )
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.label,
                            focused: classes.focusedLabel,
                            error: classes.erroredLabel,
                        },
                    }}
                >
                    {selectList &&
                        selectList.map((item) => (
                            <MenuItem
                                key={item.key}
                                value={item.key}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                }}
                            >
                                <span className={styles.TextField}>
                                    {item.value}
                                </span>
                            </MenuItem>
                        ))}
                </CssTextField>
            </GridItem>

            {/* ============ AGENCIA ============  */}
            <GridItem xs={12} sm={12} md={2} style={inputsStyle}>
                <CssTextField
                    className={styles.TextField}
                    fullWidth
                    label={
                        <CustomRequiredField
                            value={t('Agency')}
                            disabled={true}
                        />
                    }
                    autoComplete="off"
                    id="agency"
                    name="agency"
                    error={touched && touched.agency && Boolean(errors.agency)}
                    value={agency}
                    onChange={change.bind(null, 'agency')}
                    variant="outlined"
                    margin="dense"
                    disabled={disabledForm_Account || bankKey === '0'}
                    helperText={
                        touched && touched.agency && errors.agency
                            ? errors.agency
                            : ''
                    }
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.label,
                            focused: classes.focusedLabel,
                            error: classes.erroredLabel,
                        },
                    }}
                />
            </GridItem>

            <GridItem xs={12} sm={12} md={3} style={inputsStyle}>
                <CssTextField
                    className={styles.TextField}
                    fullWidth
                    label={
                        <CustomRequiredField
                            value={t('Account')}
                            disabled={true}
                        />
                    }
                    autoComplete="off"
                    id="account"
                    name="account"
                    error={
                        touched && touched.account && Boolean(errors.account)
                    }
                    value={account}
                    onChange={change.bind(null, 'account')}
                    variant="outlined"
                    margin="dense"
                    disabled={disabledForm_Account || bankKey === '0'}
                    helperText={
                        touched && touched.account && errors.account
                            ? errors.account
                            : ''
                    }
                    InputProps={{
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.label,
                            focused: classes.focusedLabel,
                            error: classes.erroredLabel,
                        },
                    }}
                />
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={2} style={inputsStyle} >

				<TextField
					className={styles.TextField}
					fullWidth
					label={
						<CustomRequiredField
							value={'Dígito'}
							disabled={true}
						/>
					}
					autoComplete="off"
					id="numberDigit"
					name="numberDigit"
					error={touched && touched.numberDigit && Boolean(errors.numberDigit)}
					value={numberDigit}
					onChange={change.bind(null, 'numberDigit')}
					variant="outlined"
					margin="dense"
					disabled={disabledForm_Account || bankKey === '0'}
					helperText={
						touched && touched.numberDigit && errors.numberDigit ? errors.numberDigit : ''
					}

					InputProps={{
						classes: {
							root: classes.cssOutlinedInput,
							focused: classes.cssFocused,
							notchedOutline: classes.notchedOutline,
						},
					}}
					InputLabelProps={{
						classes: {
							root: classes.label,
							focused: classes.focusedLabel,
							error: classes.erroredLabel,
						},
					}}
				/>

			</GridItem> */}
        </Fragment>
    )
}

export default BankAndPixForm_Bank
