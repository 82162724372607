import { TextField, withStyles } from "@material-ui/core";
import { grayColor } from 'assets/jss/material-dashboard-pro-react.jsx'
import { withTheme } from "styled-components";
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const theme = GetThemeColorFromStorage()


export const CssTextField = withStyles({
    root: {
        backgroundColor: '#fff',
        '& label.Mui-focused': {
            color: theme &&
            theme.navigation &&
            theme.navigation.buttons.elementsColor,
        },
        '& label': {
            fontSize: '14px',
            lineHeight: 1.2
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: grayColor[2],
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: theme &&
                    theme.navigation &&
                    theme.navigation.buttons.elementsColor,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme &&
                    theme.navigation &&
                    theme.navigation.buttons.elementsColor,
            },
        },
    },
})(TextField);