import React from 'react'
import CardRowLateral from './CardRowLateral.jsx'
export default ({ themeColor, label1, value1, label2, value2 }) => {
    return (
        <div
            style={{
                display: 'flex',
                gap: '0',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
            }}
        >
            <CardRowLateral
                themeColor={themeColor}
                width={70}
                value={value1}
                label={label1}
            />
            <CardRowLateral
                themeColor={themeColor}
                width={30}
                value={value2}
                label={label2}
            />
        </div>
    )
}
