import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'
import banner_img from 'assets/img/virtualstore/banner_indique_amigo.png'

import * as Styled from './styles'

import IndicationModal from '../IndicationModal'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Grid } from '@material-ui/core'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const validateIndication = Yup.object().shape({
    name: Yup.string().required('Nome obrigatório.'),
    email: Yup.string().email().required('Email obrigatório.'),
})

function SideMenu({ t }) {
    const history = useHistory()
    const [sentScreen, setSentScreen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [generalRules, setGeneralRules] = useState('')

    useEffect(() => {
        axios.get(`/ProgramRules/General`).then((response) => {
            setGeneralRules(response.data)
        })
    }, [])

    const {
        handleSubmit,
        control,
        errors,
        setValue,
        clearError,
        formState,
        triggerValidation,
    } = useForm({
        resolver: yupResolver(validateIndication),
        defaultValues: {
            name: '',
            email: '',
        },
        mode: 'onChange',
    })

    const sendForm = async (formData) => {
        let obj = []
        obj = [formData]
        await axios
            .post(`/Consumer/CreateIndications`, obj)
            .then((response) => {
                response.data[0].errors.length === 0 && setSentScreen(true)
                if (response.success) {
                    setEmailError('')
                } else {
                    if (response.data[0].errors[0].messageCode === 1) {
                        return setEmailError('Email Inválido!')
                    } else if (response.data[0].errors[0].messageCode === 3) {
                        return setEmailError('Email já indicado!')
                    }
                    setEmailError(response.errors[0].message)
                }
            })
            .catch((error) => error && console.log(error))
    }

    const theme = GetThemeColorFromStorage()

    return (
        <Grid>
            {!sentScreen ? (
                <Grid item xs={12} sm={12} md={12} style={{ paddin: '0px' }}>
                    <form
                        onSubmit={handleSubmit(sendForm)}
                        style={{ width: '100%' }}
                    >
                        <IndicationModal
                            open={modalOpen}
                            closeByClick={() => setModalOpen(false)}
                            hasPointsIndicator={
                                generalRules?.hasPointsIndicator
                            }
                            pointsIndicator={`${generalRules?.pointsIndicator}`}
                            programCurrency={
                                generalRules
                                    ? generalRules.programCurrencySymbol
                                    : ''
                            }
                        ></IndicationModal>
                        <Styled.Background
                            style={{
                                padding: '15px',
                                width: '100%',

                                backgroundImage: `url('${banner_img}')`,
                                backgroundColor:
                                    theme?.navigation?.buttons
                                        ?.backgroundColor || '#f58b3c',
                            }}
                        >
                            <div
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    marginTop: '5px',
                                    marginBottom: '3px',
                                }}
                            >
                                {'Indique um AMIGO'}
                            </div>
                            <Grid
                                item
                                xs={9}
                                sm={9}
                                md={9}
                                style={{
                                    marginBottom: '0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Controller
                                    as={
                                        <Styled.CustomText
                                            placeholder="Qual o nome do seu amigo?"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            style={{ backgroundColor: '#fff' }}
                                            error={errors.name}
                                        />
                                    }
                                    defaultValue=""
                                    name="name"
                                    control={control}
                                ></Controller>
                                <Styled.spanError>
                                    {errors.name && 'Nome obrigatório.'}
                                </Styled.spanError>
                            </Grid>

                            <Grid
                                item
                                xs={2}
                                sm={2}
                                md={2}
                                style={{
                                    position: 'relative',
                                    width: '0px',
                                    float: 'right',
                                    bottom: '35px',
                                    right: '75px',
                                }}
                            >
                                <i
                                    className="icon-indicacao_amigos"
                                    style={{
                                        color: 'white',
                                        fontSize: '80px',
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={9}
                                sm={9}
                                md={9}
                                item
                                style={{
                                    marginBottom: '0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Controller
                                    as={
                                        <Styled.CustomText
                                            placeholder="Coloque o e-mail da pessoa indicada."
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            autoComplete="off"
                                            style={{ backgroundColor: '#fff' }}
                                            error={errors.email}
                                        />
                                    }
                                    defaultValue=""
                                    name="email"
                                    control={control}
                                ></Controller>

                                <Styled.spanError>
                                    {errors.email
                                        ? 'Email obrigatório.'
                                        : emailError && emailError}
                                </Styled.spanError>
                            </Grid>
                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                item
                                style={{
                                    textAlign: ' right ',
                                    marginTop: '8px',
                                }}
                            >
                                <Styled.spanLink
                                    onClick={() => setModalOpen(true)}
                                >
                                    + Saiba mais
                                </Styled.spanLink>
                                <Styled.wButton
                                    // onClick={handleSubmit(sendForm)}
                                    type="submit"
                                    style={{
                                        margin: '0 5px',
                                        fontSize: '15px',
                                        height: '40px',
                                        borderRadius: '6px',
                                        padding: '0px 15px',
                                        marginRight: '15px',
                                        color:
                                            theme?.navigation?.buttons
                                                ?.backgroundColor || '#f58b3c',
                                    }}
                                >
                                    Enviar
                                </Styled.wButton>
                            </Grid>
                        </Styled.Background>
                    </form>
                </Grid>
            ) : (
                <Grid item xs={12} sm={12} md={12} style={{ padding: '0px' }}>
                    <Styled.Background
                        style={{ padding: '15px', width: '100%' }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ textAlign: 'center', margin: '20px 0px' }}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '43px', color: 'white' }}
                            ></CheckCircleIcon>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            item
                            style={{ textAlign: 'center' }}
                        >
                            <span style={{ color: 'white', fontSize: '18px' }}>
                                Indicação enviada com sucesso
                            </span>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            item
                            style={{ textAlign: ' right ', marginTop: '38px' }}
                        >
                            <Styled.spanLink
                                onClick={() => history.push('/friends')}
                            >
                                Visualizar Indicações
                            </Styled.spanLink>
                            <Styled.bButton
                                onClick={() => setSentScreen(false)}
                                style={{ margin: '0 5px' }}
                            >
                                Nova Indicação
                            </Styled.bButton>
                        </Grid>
                    </Styled.Background>
                </Grid>
            )}
        </Grid>
    )
}

export default withTranslation()(SideMenu)
