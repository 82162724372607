import styled from 'styled-components'

export const Container = styled.div`
    .MuiInput-underline:after {
        border-bottom: ${({ borderColor }) => `2px solid ${borderColor}`};
        pointer-events: none;
    }
    .MuiInputLabel-root {
        color: ${({ borderColor }) => `${borderColor}`} !important;
    }
`
export const ContainerTitle = styled.div`
    display: flow-root;
    b {
        cursor: pointer;
        font-size: 14px;
        margin-left: 5px;
        color: ${({ color }) => color};
        font-weight: 600;
    }
`

