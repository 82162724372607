import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const Container = styled.div`
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }
`


export const ContainerForm = styled.form`
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    @media only screen and (min-width: 400px, max-width:700px) {
        margin-left: -5px;
    }
`

export const RadiusBox = styled.div`
    padding-top: 1px;
    margin-bottom: 1px;
    width: 10%;
    @media only screen and (min-width: 400px) {
        padding-top: 2px;
        margin-bottom: 2px;
        width: 5%;
    }
    .RadioGroup {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .MuiFormControlLabel-root {
            align-items: center;
            display: flex;
            height: 100% !important;
            justify-content: center;
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
        }
        .MuiRadio-colorSecondary.Mui-checked {
            height: 100% !important;
            width: 100% !important;
        }

        .WithStyles\(PointsRequestNew\)-radioRoot-317 {
            padding: 0 !important;
        }
        .MuiIconButton-label {
            justify-content: center !important;
        }
    }

    .rdtMonths,
    .rdtYears {
        .rdtMonth,
        .rdtYear {
            &.rdtActive {
                background-color: ${GetThemeColorFromStorage()?.titles
                    ?.secondary?.textColor} !important;
            }
        }
    }
`

export const RadiusOptions = styled.div`
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 100%;
`

export const InputsBox = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 90%;
    @media only screen and (min-width: 400px) {
        width: 95%;
    }
`
export const InvoiceNumber = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 17px;
    .MuiFormControl-marginDense {
        margin: 0 !important;
    }
    .number {
        width: 100%;
        @media only screen and (min-width: 700px) {
            width: 50%;
        }
    }

    .date {
        margin-top: 10px;
        width: 100%;
        @media only screen and (min-width: 400px) {
            width: 50%;
        }
        @media only screen and (min-width: 700px) {
            margin-top: 0;
            padding-left: 10px;
            width: 19%;
        }

        .icon-data {
            font-size: 20px;
            margin-right: -8px;
            color: ${GetThemeColorFromStorage()?.visual?.icons?.color};
        }
        .rdtPicker {
            .dow,
            th.rdtSwitch,
            th.rdtNext,
            th.rdtPrev,
            .rdtTimeToggle {
                color: ${(props) =>
                    props?.theme?.titles?.highlight?.textColor} !important;
            }
        }
        .rdtDay {
            &.rdtToday.rdtActive,
            &.rdtActive,
            &.rdtActive:hover {
                background-color: ${(props) =>
                    props?.theme?.titles?.highlight?.textColor} !important;
            }
        }
        .rdtMonths,
        .rdtYears {
            .rdtMonth,
            .rdtYear {
                &.rdtActive {
                    background-color: ${(props) =>
                        props?.theme?.titles?.highlight?.textColor} !important;
                }
            }
        }
    }
    .purchaseValue {
        width: 100%;
        margin-top: 10px;

        @media only screen and (min-width: 400px) {
            padding-left: 10px;
            width: 50%;
        }
        @media only screen and (min-width: 700px) {
            margin-top: 0;
            width: 27%;
        }
    }
`
export const InvoiceKey = styled.div`
    width: 100%;
    .MuiFormHelperText-contained {
        position: absolute !important;
        top: 37px !important;
    }

	.MuiFormControl-marginDense {

	}
`

export const InvoiceGrid = styled(Grid)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .REWARD_REQUEST {
        transform: translateY(8px);
        @media (min-width: 600px) {
            max-width: 84%;
            flex-basis: 84%;
        }
		@media (min-width: 960px) {
            max-width: 86.888888%;
            flex-basis: 86.888888%;
        }
    }

    .REQUEST_BUTTON {
        float: right;
        @media (min-width: 960px) {
            max-width: 12% !important;
            flex-basis: 12% !important;
        }
        @media (min-width: 600px){
            max-width: 12% !important;
            flex-basis: 12% !important;
        }
    }
`
