import React from 'react'
import {
    MuiBox,
    MuiDefaultModal,
    MuiTypography,
    Button,
    MuiStack,
    MuiDivider,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
export function Modal({ isModalOpen, handleCloseModal, negotiationDetails }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 580,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    }
    return (
        <MuiDefaultModal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={isModalOpen}
            onClose={handleCloseModal}
        >
            <MuiBox sx={{ ...style, maxHeight: '90vh', overflowY: 'auto' }}>
                <MuiStack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                >
                    <MuiTypography fontWeight={600}>
                        Unidades de faturamento
                    </MuiTypography>
                    <Button
                        hoverColor="#F05B4F"
                        hoverTextColor="#F05B4F"
                        text="Fechar"
                        textColor="#FFFFFF"
                        typeVariation="CustomTextButton"
                        onClick={handleCloseModal}
                        icon
                        colorIcon="#FFFFFF"
                        sx={{
                            borderRadius: '5px',
                            backgroundColor: '#F05B4F',
                            height: '35px',
                            fontWeight: 400,
                            border: 'none',
                            textTransform: 'inherit',
                            color: '#FFFFFF',
                            ':hover': {
                                backgroundColor: '#F05B4F',
                                color: '#FFFFFF',
                                border: 'none',
                            },
                            maxWidth: '20%',
                        }}
                    />
                </MuiStack>
                <MuiBox
                    sx={{
                        width: '100%',
                        border: '1px solid #D2D2D2',
                        padding: '10px',
                        borderRadius: '5px',
                    }}
                    mt={2}
                >
                    {negotiationDetails?.divisions?.length > 0 &&
                        negotiationDetails?.divisions?.map((partner, index) => (
                            <div key={index}>
                                <MuiTypography
                                    color={
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor
                                    }
                                    fontWeight={500}
                                    fontSize="12px"
                                    sx={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    - {partner?.name ?? '-'}
                                    {index ===
                                    negotiationDetails?.divisions?.length - 1
                                        ? '.'
                                        : ';'}
                                </MuiTypography>
                                {index <
                                    negotiationDetails?.divisions?.length -
                                        1 && (
                                    <MuiDivider
                                        sx={{
                                            marginBottom: 1,
                                            marginTop: 1,
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                </MuiBox>
            </MuiBox>
        </MuiDefaultModal>
    )
}
