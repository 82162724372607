import React, { memo, useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { envs } from '../../../../redux/api/windowServerData'
import RedirectButton from './RedirectButton'

function Header() {
    const { t } = useTranslation()
    const history = useHistory()

    const [theme, setTheme] = useState({
        images: {
            internalPages: {
                menuBackground: { url: '' },
                menuLogo: { url: '', filename: '' },
            },
        },
    })

    useEffect(() => {
        setTheme(GetThemeColorFromStorage())
    }, [])

    const images = theme.images?.internalPages
    const isCvaleIdentity = envs.REACT_APP_CVALE_IDENTITY === 'true'

    const handleLogoClick = useCallback(() => {
        history.push('/')
    }, [history])

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
        >
            <Box
                sx={{
                    backgroundImage: `url(${images?.menuBackground?.url})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    justifyContent: 'center',
                    minHeight: '130px',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        maxWidth: {
                            md: '100%',
                            lg: '90%',
                            xl: '75%',
                        },
                        width: '100%',
                    }}
                >
                    <Box
                        component="img"
                        onClick={handleLogoClick}
                        sx={{ cursor: 'pointer' }}
                        src={images?.menuLogo?.url}
                        alt={images?.menuLogo?.filename}
                    />
                    <Box display={{ xs: 'flex', md: 'none' }}>
                        <RedirectButton
                            push={history.push}
                            theme={theme}
                            t={t}
                        />
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    height: '90px',
                    width: '100%',
                    transform: 'translateY(-16px)',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        padding: {
                            xs: '14px',
                            sm: '20px',
                            md: '38px',
                            lg: '42px',
                        },
                        background: isCvaleIdentity
                            ? 'linear-gradient(0deg, #74CCD4 0%, #2FAEBB 100%)'
                            : 'linear-gradient(rgb(159, 159, 159), rgb(203, 201, 201))',
                        maxWidth: {
                            md: '100%',
                            lg: '90%',
                            xl: '75%',
                        },
                        width: '100%',
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        height: '90px',
                        borderRadius: '10px',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            gap: { xs: '0px', md: '10px' },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            '& p': {
                                lineHeight: '25px',
                                textAlign: 'center',
                                margin: 0,
                                color: 'white',
                                fontSize: {
                                    xs: '20px',
                                    sm: '24px',
                                },
                            },
                        }}
                    >
                        <p>
                            <b>{t('REGISTER')}</b> {t('AND_HAVE_MANY')}{' '}
                            <b>{t('BENEFITS')}</b>
                        </p>
                        {isCvaleIdentity && (
                            <p>
                                {t('AT_THE')} <b>{t('WORLD')}</b>{' '}
                                {t('OF_AGRIBUSINESS')}
                            </p>
                        )}
                    </Box>
                    <Box display={{ xs: 'none', md: 'flex' }}>
                        <RedirectButton
                            push={history.push}
                            theme={theme}
                            t={t}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default memo(Header)
