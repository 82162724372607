import moment from 'moment'

export const spliceMessage = (message, t) => {
    const words = message.split(' ')

    const [first, second, third, ...rest] = words
    const truncatedMessage = t(`${first} ${second}`) + ' ' + third
    const remainingWords = rest.join(' ')
    return `${truncatedMessage} ${remainingWords}`
}

export const getLogs = (query, logs) => {
    const userId = JSON.parse(localStorage.getItem('user'))?.id

    const dateFrom = query?.dateFrom
        ? moment(query?.dateFrom?._d).startOf('day')
        : null
    const dateTo = query?.dateTo ? moment(query?.dateTo?._d).endOf('day') : null

    const description = query?.history !== undefined ? query?.history : ''
    const userName = []

    if (logs) {
        logs.map((log) => {
            if (query?.history?.includes(log.userName)) {
                userName.push(log.userName)
            }
        })
    }

    return {
        //userId: userId,
        partnerId: '',
        userName: '',
        action: '',
        success: null,
        amount: 0,
        status: [query?.status?.label] || [] ,
        description: description,
        transactionId: '',
        paymentMethod: '',
        purchaserName: '',
        initialCreated: dateFrom,
        finalCreated: dateTo,
        orderByDescending: query.orderDirection === 'desc' ? false : true,
        pageSize: query?.pageSize ?? 10,
        page: query?.page + 1 || 1,
    }
}

export const IconStatus = () => [
    {
        color: '#F55A4E',
        icon: 'icon-chave_aleatoria',
    },
    {
        color: '#00B812',
        icon: 'icon-pix_cpf',
        value: 'CPF',
    },

    {
        color: '#F55A4E',
        icon: 'icon-pix_celular',
        value: 'Celular',
    },

    {
        color: '#F55A4E',
        icon: 'icon-pix_email',
        value: 'Email',
    },

    {
        color: '#1BBED3',
        icon: 'icon-conta_bancaria',
    },
]
