import React from 'react'
import Alert from 'react-bootstrap-sweetalert'

export const validationTreat = ({
    errors,
    data,
    state,
    setState,
    success,
    t,
    classes,
    push,
    preDisabled,
}) => {
    if (success) {
        return setState((prevState) => ({
            ...prevState,
            alert: (
                <Alert
                    success
                    style={{
                        display: 'block',
                        marginTop: '-100px',
                    }}
                    title={null}
                    onConfirm={() => {
                        push('/authenticateAccess', {
                            cpfcnpj: data.cpf,
                        })
                    }}
                    confirmBtnCssClass={
                        classes.button + ' ' + classes.greenButtonColor
                    }
                    confirmBtnText={'Continuar'}
                >
                    <div
                        style={{
                            fontWeight: 500,
                            fontFamily: 'helvetica',
                        }}
                    >
                        {'E-mail enviado com sucesso!'}
                    </div>
                </Alert>
            ),
        }))
    }

    const userDuplicate =
        errors.length &&
        errors.find((item) => item.message === 'user - DUPLICATED')

    if (userDuplicate) {
        return setState((prevState) => ({
            ...prevState,
            alert: (
                <Alert
                    warning
                    style={{
                        display: 'block',
                        marginTop: '-100px',
                    }}
                    title={''}
                    onConfirm={() => {
                        setState((prevState) => ({
                            ...prevState,
                            alert: null,
                        }))
                    }}
                    confirmBtnCssClass={
                        classes.button + ' ' + classes.greenButtonColor
                    }
                    confirmBtnText={'OK'}
                >
                    <div
                        style={{
                            fontWeight: 500,
                            fontFamily: 'helvetica',
                        }}
                    >
                        {t(userDuplicate?.message)}
                    </div>
                </Alert>
            ),
        }))
    }

    const associatedDataError =
        errors.length &&
        errors.find((item) => item.message === 'Associated - INVALID_VALUE')

    const cellphonenumberError =
        errors.length &&
        errors.find((item) => item.message === 'cellphonenumber - NOT_FOUND')

    const cellphoneDuplicate =
        errors.length &&
        errors.find((item) => item.message === 'cellphonenumber - DUPLICATED')

    const invalidPassword =
        errors.length &&
        errors.find((item) => {
            if (item.message === 'Password - INVALID_VALUE') {
                return (item.message = 'Password - INVALID_VALUE_RULE')
            }
        })

    if (invalidPassword && state.associatedData) {
        return setState((prevState) => ({
            ...prevState,
            alert: null,
            modalError: {
                ...state.modalError,
                one: true,
                textError: <p>{`Senha inválida.`}</p>,
            },
        }))
    }

    const addressAssociatedError =
        errors.length &&
        errors.find((item) => item.message === 'address - NOT_FOUND')

    if (addressAssociatedError && state.associatedData) {
        return setState((prevState) => ({
            ...prevState,
            alert: null,
            modalError: {
                ...state.modalError,
                one: true,
                textError: (
                    <p>{`Identificamos que seu endereço está incompleto.`}</p>
                ),
            },
        }))
    }

    if (associatedDataError) {
        return setState((prevState) => ({
            ...prevState,
            alert: null,
            modalError: {
                ...state.modalError,
                one: true,
                textError: (
                    <p>
                        {
                            'Encontramos divergência nos seus dados. Por gentileza entrar em contato com o suporte CVale nos canais abaixo.'
                        }
                    </p>
                ),
            },
        }))
    }

    if (cellphoneDuplicate && state.associatedData) {
        return setState((prevState) => ({
            ...prevState,
            cellphoneDuplicateError: { error: true, text: '' },
            phoneNumber: data.phoneNumber,
            phoneNumber2: data.phoneNumber2,
            alert: null,
            modalError: { ...state.modalError, two: true },
        }))
    }

    if (cellphoneDuplicate && !state.associatedData) {
        return setState((prevState) => ({
            ...prevState,
            cellphoneDuplicateError: { error: true, text: '' },
            alert: null,
            modalError: { ...state.modalError, two: true },
        }))
    }

    if (cellphonenumberError && state.associatedData) {
        return setState((prevState) => ({
            ...prevState,
            alert: null,
            modalError: { ...state.modalError, one: true, textError: '' },
        }))
    }

    const DateOfBirthError = errors.find(
        (error) => error.message === 'DateOfBirth - CANNOT_BE_UNDER_AGE',
    )

    if (DateOfBirthError) {
        return setState((prevState) => ({
            ...prevState,
            alert: null,
            modalDateOfBirth: {
                ...state.modalDateOfBirth,
                open: true,
                text: preDisabled
                    ? t('CANNOT_BE_UNDER_AGE')
                    : t('DateOfBirth - CANNOT_BE_UNDER_AGE'),
            },
        }))
    }

    const sweetAlert = (
        <Alert
            warning
            style={{
                display: 'block',
            }}
            title={'Atenção!'}
            onConfirm={() => setState({ alert: null })}
            confirmBtnCssClass={classes.button + ' ' + classes.greenButtonColor}
            confirmBtnText={'OK'}
        >
            {errors?.map((error) => [
                error.field.indexOf(
                    'The Password must be at least 6 and at max 100 characters long.',
                ) > -1 ? (
                    t('PasswordSize')
                ) : error.field.indexOf(
                      'The Name must be at least 2 and at max 50 characters long.',
                  ) > -1 ? (
                    t('NameSize')
                ) : (
                    <div>{t(error.message)}</div>
                ),
            ])}
        </Alert>
    )

    return setState((prevState) => ({ ...prevState, alert: sweetAlert }))
}

export const initialTreatment = ({
    errors,
    data,
    state,
    setState,
    success,
    t,
    classes,
    push,
}) => {
    if (success) {
        return setState((prevState) => ({
            ...prevState,
            alert: (
                <Alert
                    success
                    style={{
                        display: 'block',
                        marginTop: '-100px',
                    }}
                    title={null}
                    onConfirm={() => {
                        window.location.reload()
                    }}
                    confirmBtnCssClass={
                        classes.button + ' ' + classes.greenButtonColor
                    }
                    confirmBtnText={'Continuar'}
                >
                    <div
                        style={{
                            fontWeight: 500,
                            fontFamily: 'helvetica',
                        }}
                    >
                        {'E-mail enviado com sucesso!'}
                    </div>
                </Alert>
            ),
        }))
    }

    return setState((prevState) => ({
        ...prevState,
        alert: (
            <Alert
                warning
                style={{
                    display: 'block',
                    marginTop: '-100px',
                }}
                title={null}
                onConfirm={() => {
                    window.location.reload()
                }}
                confirmBtnCssClass={
                    classes.button + ' ' + classes.greenButtonColor
                }
                confirmBtnText={'Continuar'}
            >
                {errors?.length ? (
                    errors.map((error) => [
                        <div
                            style={{
                                fontWeight: 500,
                                fontFamily: 'helvetica',
                            }}
                        >
                            {t(error.message)}
                        </div>,
                    ])
                ) : (
                    <div
                        style={{
                            fontWeight: 500,
                            fontFamily: 'helvetica',
                        }}
                    >
                        {'Ocorreu um erro ao realizar a operação!'}
                    </div>
                )}
            </Alert>
        ),
    }))
}
