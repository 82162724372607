/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'
import Countdown from 'react-countdown'
import _ from 'lodash'

import { Grid, useMediaQuery, withStyles } from '@material-ui/core'

import Section from 'components/Section/Section'
import * as Yup from 'yup'

import Button from 'components/CustomButtons/Button.jsx'
import registerStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'
import { MuiBox } from 'components/storybook'
// Utils
import compose from 'utils/compose'

import { Controller, useForm } from 'react-hook-form'
import RegisterIndicationInput from '../../../../components/RegisterIndicationInput'
import Header from '../../../CreateUser/components/Header'
import * as Style from './styles'
import { yupResolver } from '@hookform/resolvers'
import {
    GetThemeColorFromStorage,
    GetThemeFromAPILogin,
    SetThemeIntoStorage,
} from '../../../../redux/api/themeColor'
import { getProgramTitle } from '../../../../utils/utils'

const validationSchema = Yup.object({})

// MOMENT JS
import * as moment from 'moment'
import { GetGeneralRulesData } from '../../../../redux/api/generalRules'
import Footer from '../../../../components/Footer/Footer'
import { GetContact } from '../../../../redux/api/footer'
import { handleAuthLogout } from '../../../../utils'

const langg = localStorage.getItem('i18nextLng')
moment.globalLocale = langg !== null ? langg.toLowerCase() : 'pt-BR'

const renderer = ({
    hours,
    minutes,
    seconds,
    completed,
    isCompleted,
    setIsCompleted,
}) => {
    if (completed) {
        setIsCompleted(true)
        return null
    } else {
        return (
            !isCompleted && (
                <span>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                </span>
            )
        )
    }
}

const Confirm = ({
    onSubmit,
    requestSmsCode,
    props,
    isLoading,
    handlSent,
    consumer,
}) => {
    let [date, setDate] = useState()
    const errorsToken = props?.emailToken?.errors
    const userData = props?.emailToken?.data
    const [hideCounter, setHideCounter] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
    const [themeColor, setThemeColor] = useState(GetThemeColorFromStorage())
    const [programName, setProgramName] = useState('')
    const mdMediaQuery = useMediaQuery('(min-width:600px)')
    const [contact, setContat] = useState()
    const [loading, setLoading] = useState(true)

    const [timer, setTimer] = useState({
        time: 1000 * 120,
        isActive: false,
    })

    const { t } = useTranslation()

    const {
        handleSubmit,
        control,
        watch,
        errors,
        setValue,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            numberToken: '',
        },
        resolver: yupResolver(validationSchema),
    })

    const value = watch('numberToken')

    const GetContactInfo = async () => {
        const res = await GetContact()
        res && setContat(res)
    }

    const handleTimer = async () => {
        handlSent()

        await requestSmsCode()

        setIsCompleted(false)
        setTimer((prevState) => ({
            time: 1000 * 120,
            isActive: !prevState.isActive,
        }))
    }

    const GetGeneralRulesBasic = () => {
        GetGeneralRulesData()
            .then((res) => {
                if (!_.isEmpty(res)) {
                    setProgramName(res?.name)
                    localStorage.setItem(
                        'GeneralRulesBasic',
                        JSON.stringify(res),
                    )
                }
            })
            .catch((err) => console.log(err))
    }

    const getTheme = async () => {
        setLoading(true)
        try {
            const theme = await GetThemeFromAPILogin()

            SetThemeIntoStorage(theme)
        } catch (error) {
            console.log(error)
            handleAuthLogout()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setDate(Date.now() + timer.time)
        setThemeColor(GetThemeColorFromStorage())
    }, [])

    useEffect(() => {
        if (
            errorsToken?.length > 1 &&
            errorsToken[0]?.message !== 'consumer - EMAIL_ALREADY_VALIDATED'
        ) {
            setHideCounter(true)
        }
        GetGeneralRulesBasic()
        GetContactInfo()
        getTheme()
    }, [])

    useMemo(() => {
        if (isCompleted) setValue('numberToken', '')
    }, [isCompleted])

    useMemo(() => {
        if (props?.putSms?.errors?.length > 0) setValue('numberToken', '')
    }, [props.putSms])

    return loading ? (
        <div>...</div>
    ) : (
        <form
            style={{
                width: '100%',
                height: '100%',
                display: isLoading ? 'none' : 'block',
            }}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div style={{ position: 'relative', minHeight: '100vh' }}>
                <DocumentTitle
                    title={getProgramTitle(t('TITLE_REGISTER_CONFIRMATION'))}
                />

                <Style.Container container justify="center">
                    <Header />
                    <Grid
                        item
                        xs={12}
                        container
                        className="main-box"
                        justify="space-between"
                    >
                        {/* #1 DADOS PESSOAIS */}
                        <Grid container>
                            <Section title={t('TITLE_ACCESS_AUTHENTICATION')} />
                            <Style.SectionInfo>
                                <p>
                                    Para ter acesso ao portal {programName}{' '}
                                    informe o código enviado para o celular
                                    cadastrado,{' '}
                                    <Style.CellHidden themeColor={themeColor}>
                                        {userData?.phoneNumber2 ||
                                            consumer?.phoneNumber2 ||
                                            ''}
                                    </Style.CellHidden>{' '}
                                </p>
                                <p>
                                    Caso não tenha recebido, aguarde o tempo
                                    informado e solicite um novo.
                                </p>
                                <p>
                                    É importante que seu número de telefone
                                    esteja correto, pois receberá um sms com o
                                    código de ativação, caso esteja errado entre
                                    em contato com o Suporte do Programa{' '}
                                    {programName}.
                                </p>
                            </Style.SectionInfo>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                className="numberTokenClass"
                                style={{ textAlign: 'center' }}
                            >
                                <Controller
                                    as={
                                        <RegisterIndicationInput
                                            title={
                                                'Utilize o código enviado para autenticação:'
                                            }
                                            hasBackground={false}
                                            validChars="0-9"
                                            amount={4}
                                            disabled={isCompleted}
                                            error={errors?.numberToken?.message}
                                        />
                                    }
                                    name="numberToken"
                                    control={control}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                justify="center"
                                alignItems="center"
                                direction="column"
                                style={{
                                    marginTop: '1.25rem',
                                }}
                            >
                                {!isCompleted && <p>Nova validação</p>}
                                <div
                                    style={{
                                        fontWeight: 800,
                                        fontSize: '20px',
                                        color:
                                            themeColor?.navigation?.buttons
                                                ?.backgroundColor || '',
                                    }}
                                >
                                    {!hideCounter ? (
                                        <Countdown
                                            key={timer.isActive}
                                            date={
                                                date
                                                    ? date
                                                    : Date.now() + timer.time
                                            }
                                            renderer={(props) =>
                                                renderer({
                                                    ...props,
                                                    isCompleted,
                                                    setIsCompleted,
                                                })
                                            }
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                justify="center"
                                spacing={mdMediaQuery ? 2 : null}
                                className="btn_contained"
                            >
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    sm={2}
                                    justify="flex-end"
                                >
                                    <Button
                                        size="sm"
                                        color="greenButtonColor"
                                        disabled={
                                            value.length < 4 || isSubmitting
                                        }
                                        type="submit"
                                    >
                                        {t('BUTTON_CONFIRM')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <MuiBox
                                        onClick={handleTimer}
                                        mt={0.6}
                                        sx={{
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            border: `1px solid ${
                                                isCompleted
                                                    ? themeColor?.navigation
                                                          ?.buttons
                                                          ?.backgroundColor
                                                    : '#B4B4B4'
                                            }`,
                                            borderRadius: '5px',
                                            padding: '5px',
                                            color: isCompleted
                                                ? themeColor?.navigation
                                                      ?.buttons?.backgroundColor
                                                : '#B4B4B4',
                                        }}
                                    >
                                        {t('BUTTON_REQUEST_NEW_TOKEN_CODE')}
                                    </MuiBox>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="footer-text">
                            <Style.CustomP themeColor={themeColor}>
                                Se tiver problemas com o código entre em contato
                                com
                                <a
                                    href={`mailto:${contact?.contactEmail}`}
                                    target="_blank"
                                >
                                    {contact?.contactEmail || ''}
                                </a>
                            </Style.CustomP>
                        </Grid>
                    </Grid>
                    <Footer />
                </Style.Container>
            </div>
        </form>
    )
}

export default compose(withStyles(registerStyle))(Confirm)
