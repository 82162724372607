import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { GetThemeColorFromStorage } from '../../../../../redux/api/themeColor';

function Section({
	title,
	subtitle,
}) {
	return (
		<Box
			display="flex"
			width="100%"
			alignItems="center"
			sx={{
				textAlign: 'left',
				font: 'normal normal bold 15px Roboto',
				letterSpacing: '0px',
				color: '#333333',
				opacity: 1,
				whiteSpace: 'nowrap',
			}}
		>
			<Box pr="8px">
				{title}
			</Box>
			<Box
				letterSpacing="0px"
				mr="8px"
				sx={{
					font: 'italic normal normal 12px/21px Roboto',
				}}
			>
				{subtitle}
			</Box>

			<Box
				width="100%"
				bgcolor={GetThemeColorFromStorage()?.titles?.main?.textColor}
				height="3px"
			/>
		</Box >
	)
}

Section.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
}


export default Section;
