import styled from 'styled-components/macro'
import { TextField } from '@material-ui/core'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const Container = styled.div`
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }
`


export const FormFilterBox = styled.form`
    border-radius: 8px;
    background: #f6f6f6;
    max-width: 840px;
    padding: 20px 15px;
    width: 100%;
    .MuiFormControl-marginDense {
        margin: 0;
    }
`
export const InputsFilterBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

export const InputRecommendation = styled.div`
    margin-bottom: 15px;
    width: 100%;
    @media screen and (min-width: 500px) {
        margin-bottom: 0;
        padding-right: 12.5px;
        width: 50%;
    }
    @media screen and (min-width: 700px) {
        width: 60%;
    }
`
export const InputStatus = styled.div`
    width: 100%;
    @media screen and (min-width: 500px) {
        padding-left: 12.5px;
        width: 50%;
    }
    @media screen and (min-width: 700px) {
        width: 40%;
    }

    .MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 3px;
    }

    .MuiAutocomplete-popperDisablePortal {
		li:hover {
			color: #ffffff !important;
			background-color: ${GetThemeColorFromStorage()?.navigation?.buttons
                ?.backgroundColor} 
		}
	}
`

export const BtnSearchContain = styled.div`
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 25px;
    width: 100%;
`

export const GridBox = styled.div`
    width: 100%;
    max-width: 840px;
    margin-top: 25px;

    tbody {
        border-left: none;
        border-right: none;
    }

    .MuiPaper-elevation2 {
        box-shadow: unset;
    }

    .MuiToolbar-regular {
        min-height: 0px;
    }

    .MuiTableHead-root {
        tr {
            th {
                background-color: #e9e9e9;
                color: ${GetThemeColorFromStorage()?.titles?.secondary
                    ?.textColor};
                font-size: 16px;
                font-weight: bold;
                height: 40px;

                padding: 0px 16px;

                :first-child {
                    border-top-left-radius: 6px;
                    width: 22% !important;
                }

                :last-child {
                    border-top-right-radius: 6px;
                    width: 15% !important;
                    span {
                        svg {
                            display: none;
                        }
                    }
                }
                :nth-child(2) {
                    text-align: left !important;
                }
            }
        }
    }

    .MuiTableBody-root {
        tr {
            color: #717171;
            font-size: 15px;
            height: 45px;

            p {
                font-size: 13px;
                font-style: italic;
                margin: 0px;
            }

            td {
                padding: 0px 15px !important;
            }
            :nth-child(2) {
                text-align: left !important;
            }
        }
    }
`

export const IndicationBox = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;

    .MuiFormControl-marginDense {
        margin: 0 !important;
    }
    @media screen and (min-width: 750px) {
        flex-wrap: nowrap;
    }
`

export const NameInput = styled.div`
    margin-bottom: 15px;
    width: 100%;
    @media screen and (min-width: 500px) {
        padding-right: 12.5px;
        width: 50%;
    }
`
export const EmailInput = styled.div`
    margin-bottom: 15px;

    width: 100%;
    @media screen and (min-width: 500px) {
        padding-left: 12.5px;
        width: 50%;
    }
`

export const BtnActionContain = styled.div`
    display: flex;

    width: 100%;
    @media screen and (min-width: 750px) {
        justify-content: flex-end;
        margin-bottom: 15px;
        margin-top: 5px;
        width: 100px;
    }
`
export const BtnSendContain = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: -45px;
    width: 100%;
    @media screen and (min-width: 750px) {
        margin-top: 0;
    }
`
