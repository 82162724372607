import { Box } from '@mui/material'
import React from 'react'
import CardProduct from '../CardProduct'
import { useTranslation } from 'react-i18next'
import SectionCatalog from '../SectionCatalog'
import { numberFormatText } from 'utils/utils'

function ExchangePoints(props) {
    const { t } = useTranslation()
    const { products, url } = props

    const formatPrice = (price) => {
        try {
            const symbol =
                localStorage.getItem('programCurrencySymbol') ||
                process.env.REACT_APP_PROGRAM_CURRENCY_SYMBOL

            return numberFormatText(price, symbol, 0, '.')
        } catch (error) {
            console.error('Erro ao formatar preço:', error)
        }
    }

    return (
        products?.length > 0 && (
            <Box mt="30px">
                <SectionCatalog
                    title={t('TROQUE SEUS PONTOS')}
                    button={t('Acessar catálogo completo')}
                />
                <Box
                    display="flex"
                    gap="20px"
                    flexWrap="wrap"
                    justifyContent={{
                        xs: 'center',
                        sm: 'space-around',
                        md: 'space-between',
                        lg: 'flex-start',
                    }}
                    mt="30px"
                >
                    {products?.slice(0, 4)?.map((product, index) => (
                        <CardProduct
                            url={
                                url ??
                                process.env.REACT_APP_STORE_CONNECTION_BASE_URL
                            }
                            key={index}
                            image={product?.image}
                            title={product?.name}
                            price={formatPrice(product?.priceInPoints)}
                            productStoryId={product?.id}
                        />
                    ))}
                </Box>
            </Box>
        )
    )
}

export default ExchangePoints
