import React, { useContext, useState, createContext } from 'react'
const StatementContext = createContext()

export const StatementProvider = ({ children }) => {
    const [valueTypeFilter, setValueTypeFilter] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [recordCount, setRecordCount] = useState(0)

    return (
        <StatementContext.Provider
            value={{
                currentPage,
                setCurrentPage,
                recordCount,
                setRecordCount,
                valueTypeFilter,
                setValueTypeFilter,
            }}
        >
            {children}
        </StatementContext.Provider>
    )
}
export const useStatement = () => useContext(StatementContext)
