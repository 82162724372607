import React, { memo, useState, useEffect } from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import * as Style from './styles'
import RegisterIndicationInput from '../../../../components/RegisterIndicationInput'

import { verifyIndicationCodeInput } from 'redux/api/user'
import { useTranslation } from 'react-i18next'

function IndicationData({ control, watch }) {
    const { t } = useTranslation()
    const watchIndication = watch('IndicatorCode')
    const [errors, setErrors] = useState({
        message: '',
        status: false,
    })
    const isMobile = useMediaQuery('(max-width:960px)')

    useEffect(() => {
        if (watchIndication?.length === 6) {
            verifyIndicationCodeInput(watchIndication).then((response) => {
                if (!response) {
                    setErrors({
                        status: true,
                        message: t('Invalid code'),
                    })
                } else {
                    setErrors({
                        status: false,
                    })
                }
            })
        }
    }, [t, watchIndication])

    return (
        <Style.Container container xs={12} md={6} spacing={2}>
            <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                style={{ marginTop: isMobile ? '0' : '-37px' }}
                md={12}
            >
                <b>{t('WAS_IT_REFERRED_BY')}</b>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '35px' }}>
                <Controller
                    as={
                        <RegisterIndicationInput
                            title={t('ENTER_THE_PARTICIPANT_CODE_AND_BENEFIT')}
                            amount={6}
                            error={errors.status ? errors.message : ''}
                        />
                    }
                    defaultValue=""
                    name="IndicatorCode"
                    control={control}
                    id="IndicatorCode"
                />
            </Grid>
        </Style.Container>
    )
}

export default memo(IndicationData)
