import React from 'react'
import TemplatePage from './TemplatePage'
import useScoringRules from './Hooks/ScoringRules'

export default function ScoringRulesChild() {
    const hookParams = useScoringRules()

    const sharedProps = {
        ...hookParams,
    }

    return (
        <>
            <TemplatePage {...sharedProps} />
        </>
    )
}
