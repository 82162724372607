import { ValidationError, object, string } from 'yup'
import * as yup from 'yup'

import { VerifyCPFCNPJ } from 'utils/utils'

import { VerifyPhone } from '../../../redux/api/location'
import { GetCurrentConsumer } from '../../../redux/api/user'

const VerifyPhoneNumber = async (n) => {
	let id
	try {
		const response = await GetCurrentConsumer()
		id = response?.id ?? ''

		if (id) {
			const res = await VerifyPhone(n, id)
			return res.success
		}
	} catch (error) {
		console.log(error)
	}
}

export const validationSchema = object()
	.shape({
		cpf: string(),
		// dateOfBirth: date().required(),
		email: string()
			.email('E-mail inválido')
			.required('obrigatório')
			.typeError('obrigatório'),
		phoneNumber2: string()
			.required('obrigatório')
			.typeError('obrigatório')
			.test(
				'valid',
				'Celular deve ter 11 dígitos',
				(x) => x && x.replace(/[^\d]+/g, '').length > 10,
			)
			.test('valid', 'Número de celular invalido', (x) => {
				if (x) {
					let rawValue = x.replace(/[^\d]+/g, '')
					return (
						rawValue &&
						rawValue.substring(2, 3) >= 6 &&
						rawValue.substring(2, 3) <= 9
					)
				}
			}),
			// .test('valid', 'Celular duplicado', (x) => {
			// 	if (x) {
			// 		if (x.replace(/[^\d]+/g, '').length > 10) {
			// 			return VerifyPhoneNumber(x)
			// 		}
			// 	}
			// }),
		zipCode: string()
			.matches(/^([0-9]{5}-[0-9]{3})|([0-9]{8})/)
			.required(),
		stateId: string().required(),
		location: string().required(),
		cityId: string().required(),
		// aditionalInfoForm: string().required(),
		repName: string().when('cpf', (cpf, repName) =>
			cpf && cpf.replace(/[^\d]+/g, '').length > 11
				? string().required('obrigatório')
				: repName,
		),
		repCpf: string().when('cpf', (cpf, repCpf) =>
			cpf && cpf.replace(/[^\d]+/g, '').length > 11
				? string().required('obrigatório')
				: repCpf,
		),
		repEmail: string().when('cpf', (cpf, repEmail) =>
			cpf && cpf.replace(/[^\d]+/g, '').length > 11
				? string().email('inválido').required(' obrigatório')
				: repEmail,
		),
		repPhoneNumber: string().when('cpf', (cpf, repPhoneNumber) =>
			cpf && cpf.replace(/[^\d]+/g, '').length > 11
				? string().required(' obrigatório')
				: repPhoneNumber,
		),
		district: string().required(),
		number: string().required(),
		isMEI: yup.boolean(),
	})
	.test('repCpfInvalido', null, (obj) => {
		if (
			(obj.cpf && obj.cpf.replace(/[^\d]+/g, '').length <= 11) ||
			(obj.repCpf && VerifyCPFCNPJ(obj.repCpf))
		) {
			return true
		} else {
			return new ValidationError('inválido', null, 'repCpf')
		}
	})

export const validationSchema_Account = object().shape({
	pixKeyType: string().when('agency', (agency, schema) => {
		if (agency) {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}

	}).when('bank', (bank, schema) => {
		if (bank) {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}
	}).when('account', (account, schema) => {
		if (account) {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}
	}),

	pixKey: string().when('agency', (agency, schema) => {
		if (agency) {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}

	}).when('bank', (bank, schema) => {
		if (bank) {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}
	}).when('account', (account, schema) => {
		if (account) {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}
	}),

	//se pix key for preenchido, os outros campos nao podem ser obrigatorios
	bankType: string().when('pixKey', (pixKey, schema) => {
		if (pixKey) {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}

	}),

	bank: string().when('bankType', (bankType, schema) => {
		if (bankType === '0') {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}

	}),
	numberDigit: string().when('bankType', (bankType, schema) => {
		if (bankType === '0') {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}

	}),

	agency: string().when('bankType', (pix, schema) => {
		if (pix === '0') {
			return schema.notRequired()
		}
		else {
			return schema.required('obrigatório')
		}

	}),

	account: string().when('bankType', (Key, schema, value) => {
		if (Key === '0') {
			return schema.required('Obrigatório.').min(6).max(10)
		}
		else {
			return schema.notRequired().min(6, "Mínimo de 5 caracteres.")
		}
	})
},
	[
		['pixKey', 'bank'], ['pixKey', 'agency'], ['pixKey', 'account'],
		['pixKeyType', 'bank'], ['pixKeyType', 'agency'], ['pixKeyType', 'account'],
	]
)
