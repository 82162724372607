import React, { useState } from 'react'

// @PropTypes
import _ from 'lodash'
import PropTypes from 'prop-types'

// @Components
import { Box } from '@mui/material'
import Button from 'components/CustomButtons/Button.jsx'
import sem_imagem from 'assets/img/sem_imagem_consumidor.png'
import Catalog_Redirect from 'assets/img/getCatalogRedirect.png'

// @Styles
import { CardProductContainer, CardProductTitle } from './styles'

// @Redux
import { GetUrlProduct } from '../../../../redux/api/store'

function CardProduct(props) {
    const [loading, setLoading] = useState(false)
    const { image, title, price, url } = props

    const imageUrl = !_.isEmpty(image) ? image : sem_imagem
    const imageHeight = !_.isEmpty(image) ? 'auto' : '70px'
    const imageStyle = {
        objectFit: !_.isEmpty(image) ? 'contain' : 'cover',
        borderRadius: !_.isEmpty(image) ? '0px' : '5px',
    }

    const getStoreId = async () => {
        setLoading(true)
        try {
            const response = await GetUrlProduct(props?.productStoryId)

            window.open(response?.data, '_blank')
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <CardProductContainer>
            <Box height="100px" display="flex" alignItems="flex-end">
                <Box
                    component="img"
                    src={imageUrl}
                    alt={title}
                    height={imageHeight}
                    width="70px"
                    sx={imageStyle}
                />
            </Box>
            <CardProductTitle>{title}</CardProductTitle>
            <Box
                color="#21409A"
                display="flex"
                gap="8px"
                opacity="1"
                letterSpacing="0px"
                textAlign="center"
                sx={{ font: 'normal normal bold 14px/18px Roboto' }}
            >
                <Box
                    component="img"
                    src={Catalog_Redirect}
                    alt="Ícone de moeda"
                />
                {price}
            </Box>
            <Button
                color="greenButtonColor"
                size="sm"
                className="btn-round"
                onClick={() => {
                    getStoreId()
                }}
            >
                Resgatar produto
            </Button>
        </CardProductContainer>
    )
}

CardProduct.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    url_key: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
}

CardProduct.defaultProps = {
    image: '',
}

export default CardProduct
