import React from 'react'
import * as _ from 'lodash'
import classNames from 'classnames'
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import withStyles from '@material-ui/core/styles/withStyles'
import { Badge } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import compose from 'utils/compose'
import { history } from 'utils'
import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx'
import { ReadNotificationHub } from 'redux/api/signalr'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'
import * as S from '../styles'
import { envs } from '../../../redux/api/windowServerData'

const MyTooltip = styled(Tooltip)``

class UserBox extends React.Component {
    constructor(props) {
        super(props)
        this._t = this.props.t
        this.state = {
            anchorEl: null,
            setAnchorEl: null,
            selectedIndex: 1,
            setSelectedIndex: 1,
            MenuOptions: [
                this._t('MENU_USER_MY_PROFILE'),
                this._t('MENU_USER_EDIT_PASSWORD'),
                this._t('BUTTON_LOGOUT'),
            ],
            alertCount: '',
            open: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.alerts !== this.props.alerts) {
            if (this.props?.alerts?.type?.value === 'ALERT') {
                this.setState({ alertCount: this.props.alerts.value })
            }
            if (this.props.alerts && this.props.alerts.identityId) {
                // const routeEnv = envs.REACT_APP_GERMINI_API_URL.replace(
                const routeEnv = envs.REACT_APP_GERMINI_API_URL.replace(
                    '/api',
                    '',
                )
            }
        }
    }

    getNotifications = (routeEnv) => {
        const connection = new HubConnectionBuilder()
            .withUrl(
                `${routeEnv}/NotificationHub?identityId=${this.props.alerts.identityId}`,
            )
            .configureLogging(LogLevel.Information)
            .build()

        async function start() {
            try {
                await connection
                    .start()
                    .catch((e) => console.log('err', e.toString()))
                    .then(async () => {
                        try {
                            const connectionId = await connection.invoke(
                                'GetConnectionId',
                            )
                            return connectionId
                        } catch (err) {
                            console.error('Invoke error: ', err)
                        }
                    })
                console.log('> SignalR Connected.')
            } catch (err) {
                console.log('ERRO: ', err)
                setTimeout(() => start(), 5000)
            }
        }

        // Start the connection.
        start()
        //
        connection.on('notificationMessage', (payload) => {
            //console.log("PAYLOAD: ", payload)
            if (payload?.type?.value === 'ALERT') {
                this.setState({ alertCount: payload.value })
                return payload
            }
        })
    }

    handleClick = () => {
        this.setState({ open: !this.state.open })
    }
    handleClose = () => {
        this.setState({ open: false })
    }

    handleProfile = (i) => {
        history.push(`/${i}`)
        this.handleClose()
    }

    goToAlerts = async () => {
        const response = await ReadNotificationHub()
        if (response) {
            this.setState({ alertCount: 0 })
        }
        history.push('/notification')
    }

    goToNotificationCenter = () => {
        history.push('/notificationcenter')
    }

    render() {
        const { classes } = this.props
        const { open } = this.state
        const dropdownItem = classNames(
            classes.dropdownItem,
            classes.primaryHover,
        )
        return (
            <S.NotificationContain className="userBox">
                <S.NotificationBox onClick={() => this.goToAlerts()}>
                    <Badge
                        badgeContent={this.state.alertCount}
                        style={{ margin: '0 10px' }}
                    >
                        <i
                            className="icon-ico_aviso"
                            style={{
                                paddingLeft: '10px',
                                color: GetThemeColorFromStorage()?.visual?.icons
                                    ?.color,
                            }}
                        />
                    </Badge>
                </S.NotificationBox>

                <Button
                    onClick={() => this.goToNotificationCenter()}
                    style={{
                        margin: '0 10px',
                        textAlign: 'left',
                        backgroundColor: 'transparent',
                        padding: '0px',
                    }}
                >
                    <MyTooltip
                        arrow
                        id="tooltip-top"
                        title="Central de notificações"
                        placement="top-start"
                    >
                        {
                            <i
                                className="icon-ico_notificacoes"
                                style={{
                                    paddingRight: '10px',
                                    color: GetThemeColorFromStorage()?.visual
                                        ?.icons?.color,
                                }}
                            />
                        }
                    </MyTooltip>
                </Button>

                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                    placement="bottom"
                    className={classNames({
                        [classes.popperClose]: !open,
                        [classes.pooperResponsive]: true,
                        [classes.pooperNav]: true,
                    })}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list"
                            style={{ transformOrigin: '0 0 0' }}
                        >
                            <Paper className={classes.dropdown}>
                                <ClickAwayListener
                                    onClickAway={this.handleClose}
                                >
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={() =>
                                                this.handleProfile('profile')
                                            }
                                            className={dropdownItem}
                                        >
                                            {this._t('MENU_USER_MY_PROFILE')}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.handleProfile(
                                                    'changepassword',
                                                )
                                            }
                                            className={dropdownItem}
                                        >
                                            {this._t('MENU_USER_EDIT_PASSWORD')}
                                        </MenuItem>
                                        <NavLink to="/auth/login">
                                            <MenuItem
                                                onClick={this.handleClose}
                                                className={dropdownItem}
                                            >
                                                {this._t('BUTTON_LOGOUT')}
                                            </MenuItem>
                                        </NavLink>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </S.NotificationContain>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        generalRulesData: state.generalRules.generalRulesData,
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation(),
    withStyles(adminNavbarLinksStyle),
)(UserBox)
