import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Fade } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

import { ModalContainer, PaperContainer } from './styles'

import { ButtonClose } from 'components/NewButton/styles'
import { GetContact } from '../../../../redux/api/footer'

function ModalResendEmail({
    children,
    className,
    closeByClick,
    handleSendEmail,
    handleClose,
    maxWidth,
    minWidth,
    open,
    padding,
    shadow,
    t,
    email,
}) {
    const [contact, setContat] = useState()
    const [themeColor, setThemeColor] = useState(GetThemeColorFromStorage())
    const GetContactInfo = async () => {
        const res = await GetContact()
        res && setContat(res)
    }

    useEffect(() => {
        GetContactInfo()
        setThemeColor(GetThemeColorFromStorage())
    }, [])

    const blankedEmail = (email) => {
        if (email) {
            let newEmail = `${email.split('', 2)}...@...${email
                .split('', -1)
                .slice(-4)}`
            return newEmail.replaceAll(',', '')
        }
    }

    return (
        <ModalContainer
            open={open}
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            maxwidth={maxWidth}
            minwidth={minWidth}
            disableEnforceFocus
        >
            <Fade in={open} unmountOnExit>
                <PaperContainer padding={'0px 30px'} maxwidth={maxWidth}>
                    <GridContainer
                        xs={12}
                        sm={12}
                        style={{ margin: '10px 30px 30px 2px' }}
                    >
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                cursor: 'pointer',
                            }}
                        >
                            <ButtonClose onClick={handleClose}>
                                <i
                                    className="icon-ico_cancelar"
                                    style={{
                                        fontSize: '20px',
                                        marginRight: '10px',
                                    }}
                                />
                                {t('BUTTON_CLOSE')}
                            </ButtonClose>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <i
                                className="icon-alerta_modal"
                                style={{
                                    color: germiniDefaultColors[11],
                                    fontSize: '80px',
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
                        <div
                            style={{
                                fontSize: '15px',
                                fontWeight: 'bold',
                            }}
                        >
                            {t('We have identified that your registration')}
                            <br />
                            {t('Resend the email to')} {blankedEmail(email)}{' '}
                            {t('and perform the validation')}
                        </div>
                        <div
                            style={{
                                fontSize: '13px',
                                color: '#9B9B9B',
                                marginTop: '20px',
                            }}
                        >
                            <p>
                                {t(
                                    'If you have not received it, please check your junk',
                                )}
                            </p>
                            <p
                                style={{
                                    margin: '10px 0',
                                    color: themeColor?.navigation?.buttons
                                        ?.backgroundColor,
                                }}
                            >
                                E-mail: {contact?.contactEmail || ''}
                            </p>
                            <p
                                style={{
                                    marginTop: '0px',
                                    color: themeColor?.navigation?.buttons
                                        ?.backgroundColor,
                                }}
                            >
                                {t('FIELD_REGISTER_PHONE')}:{' '}
                                {contact?.contactPhoneNumber || ''}
                            </p>
                        </div>
                    </GridItem>
                    <GridContainer
                        style={{
                            padding: '10px 0 0 10px',
                            marginBottom: '30px',
                        }}
                    >
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{ textAlign: 'center' }}
                        >
                            <Button
                                size="sm"
                                variant="contained"
                                color={'greenButtonColor'}
                                style={{
                                    marginLeft: '5px',
                                    padding: '8px 35px 8px 35px',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                }}
                                onClick={handleSendEmail}
                            >
                                {t('Resend email')}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </PaperContainer>
            </Fade>
        </ModalContainer>
    )
}

ModalResendEmail.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    closeByClick: PropTypes.func,
    handleClose: PropTypes.func,
    handleSendEmail: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
}

ModalResendEmail.defaultProps = {
    children: null,
    className: '',
    closeByClick: null,
    handleClose: null,
    handleSendEmail: null,
    maxWidth: '',
    minWidth: '',
    open: false,
    padding: '',
    shadow: '',
}

export default withTranslation()(ModalResendEmail)
