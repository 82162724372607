import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//components
import Button from 'components/CustomButtons/Button.jsx'
import { CustomCheckbox } from 'components/CustomCheckbox'
import DocumentTitle from 'react-document-title'
import Section from 'components/Section/Section'
import SnackDefault from 'components/SnackDefault'
import Loader from 'components/Loader/Loader.jsx'
import { InformationWhiteIcon } from 'components/storybook'

// @ Styles
import { Container } from './styles'

// @ Mui
import { Box, Typography } from '@mui/material'
import {
    AcceptancePrivacyPolicies,
    GetPrivacyPolicies,
} from '../../redux/api/policies'
import moment from 'moment'
import { useConsumer } from '../../Context/consumerContext'
import { getProgramTitle } from '../../utils/utils'

function PrivacyPolicy() {
    const { t } = useTranslation()
    const [policy, setPolicy] = useState(null)
    const [consumerAgreements, setConsumerAgreements] = useState({})
    const [consumer, setConsumer] = useState({})
    const [checked, setChecked] = useState(false)
    const { userData } = useConsumer()

    const [snackStatus, setSnackStatus] = useState({
        open: false,
        text: '',
        severity: 'success',
    })

    const [loading, setLoading] = useState(false)

    const getPolicy = () => {
        setLoading(true)
        GetPrivacyPolicies()
            .then((response) => {
                if (response.status === 200) {
                    setPolicy(response.data)
                    setConsumerAgreements(response.data.consumerAgreements)
                }
            })
            .finally(() => setLoading(false))
    }

    const GetConsumer = () => {
        if (userData) setConsumer(userData)
    }

    const AcceptancePrivacyPolicy = async () => {
        try {
            const response = await AcceptancePrivacyPolicies({
                consumerId: consumer?.id || '',
                privacyPoliciesId: policy.id,
            })

            if (response.status === 200 && response?.data) {
                const { data } = response
                if (data?.errors?.length > 0) {
                    setSnackStatus({
                        open: true,
                        text: t(data.errors[0].message),
                        severity: 'error',
                    })
                    return
                }

                setSnackStatus({
                    open: true,
                    text: 'Política de privacidade aceita com sucesso!',
                    severity: 'success',
                })
                getPolicy()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDateFormat = (user) => {
        const id = policy?.id || ''
        if (user?.privacyPoliciesRules?.length > 0) {
            const { privacyPoliciesRules } = user

            const policy = privacyPoliciesRules.filter(
                (item) => item?.privacyPoliciesRulesId === id,
            )

            return policy?.length > 0
                ? moment(policy[0].createdAt).format(
                      'DD/MM/YYYY HH:mm',
                      'pt-br',
                  )
                : '-- --'
        }
        return '-- --'
    }

    useEffect(() => {
        getPolicy()
        GetConsumer()
    }, [])

    return (
        <Container>
            {loading && <Loader />}
            <DocumentTitle title={getProgramTitle(t('TITLE_PRIVACY_POLICY'))} />
            <SnackDefault
                snackStatus={snackStatus}
                handleCloseSnack={() =>
                    setSnackStatus((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
            />

            <Section title={t('TITLE_PRIVACY_POLICY')} />
            <Typography
                component="div"
                dangerouslySetInnerHTML={{
                    __html: policy?.privacyPolicies || '',
                }}
            />
            {!policy ? (
                <>
                    <Box display="grid" justifyContent="center" mt={5}>
                        <InformationWhiteIcon color="#BEBEBE" size={45} />
                    </Box>
                    <Box display="grid" justifyContent="center" mt={2}>
                        <Typography
                            color="#BEBEBE"
                            fontWeight={500}
                            fontSize="16px"
                        >
                            Não há Política de privacidade a ser exibida
                        </Typography>
                    </Box>
                </>
            ) : (
                <Box
                    bgcolor="#F4F4F4"
                    borderRadius="6px"
                    padding="10px 20px"
                    mt="20px"
                >
                    {consumerAgreements?.acceptedCurrentPrivacyPolicies ? (
                        <Box
                            textAlign="left"
                            font="normal normal normal 14px/21px Roboto"
                            letterSpacing="0px"
                            color="#333333"
                        >
                            Política de privacidade aceita -{' '}
                            {getDateFormat(consumer)}
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box display="flex" alignItems="center" gap="15px">
                                <CustomCheckbox
                                    style={{ padding: '0px' }}
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                />
                                <Typography component="div">
                                    Declaro que li e estou ciente das condições
                                    da política de privacidade
                                </Typography>
                            </Box>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="greenButtonColor"
                                size="sm"
                                style={{
                                    boxShadow: 'none',
                                    margin: '0px',
                                }}
                                onClick={() => AcceptancePrivacyPolicy()}
                                disabled={!checked}
                            >
                                {t('BUTTON_CONFIRM')}
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </Container>
    )
}

export default PrivacyPolicy
