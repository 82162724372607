import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer.jsx'

export const Container = styled(Grid)`
    height: 100%;

    .numberTokenClass {
        text-align: center;
        margin-top: 2.8em;
    }

    .btn_contained {
        margin-top: 1.2rem;
    }

    .main-box {
        max-height: 100%;
        padding: 6rem 25px;
    }

    .email_text {
        //margin-top: 22.5rem;
    }

    .footer-text {
        display: flex;
        justify-content: center;
        font: normal normal normal 15px/30px Roboto;

        a {
            margin-left: 0.5rem;
            font: normal normal normal 15px/30px Roboto;
        }
    }

    button {
        min-width: 100%;
    }

    @media screen and (min-width: 600px) {
        button {
            min-width: 182px;
        }
    }

    @media screen and (min-width: 1200px) {
        .main-box {
            max-width: 90%;
            margin: 0 auto;
        }
    }

    @media screen and (min-width: 1460px) {
        .main-box {
            max-width: 75%;
        }
    }
`

export const GridContainerFooter = styled(GridContainer)`
    position: absolute;
    bottom: 0;
    background-color: ${({ themeColor }) =>
        themeColor?.footer?.backgroundColor};
    height: 60px;
`

export const GridFooter = styled(GridContainer)`
    // position: absolute;
    // bottom: 0;
    background-color: ${({ themeColor }) =>
        themeColor?.footer?.backgroundColor};
    min-height: 60px;
`

export const BoxFooter = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
export const Box = styled.div`
    color: #666;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1150px;
    z-index: 5;
    font-size: 0.8rem;
    opacity: 0.7;
`

export const GridText = styled(Grid)`
    border-radius: 9px;
    display: flex;
    opacity: 1;

    span {
        text-align: center;
        font: normal normal normal 0.938rem/30px Roboto;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }
`

export const CellHidden = styled.span`
    text-align: left;
    font: normal normal bold 0.938rem Roboto;
    letter-spacing: 0px;
    color: ${({ themeColor }) =>
        themeColor?.navigation?.buttons?.backgroundColor || 'white'};
`

export const SectionInfo = styled.div`
    font: normal normal normal 15px/30px Roboto;
    opacity: 1;
    letter-spacing: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    color: #333333;
    margin-bottom: 0.1rem;
`

export const CustomP = styled.p`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    text-align: center;

    a {
        color: ${({ themeColor }) =>
            themeColor?.navigation?.buttons?.backgroundColor || 'white'};
    }
`

export const ContactInformation = styled.p``
