import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Card from "../../Card";

const SliderComponent = ({ categories, setLoading }) => {

	var settings = {
		dots: true,
		infinite: false,
		speed: 500,
		className: "slider variable-width",
		slidesToShow: 6,
		slidesToScroll: 6,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: true,
					dots: true
				}
			}
		]
	};

	return (


		<Slider {...settings}>
			{
				categories.map((product, index) => product.enable === true && (
					<Card
						key={index}
						name={product.name}
						imageUrl={product.imageUrl}
						enable={product.enable}
						id={product.id}
						storeId={product.storeId}
						setLoading={setLoading}
					/>
				))
			}
		</Slider>
	);
}

SliderComponent.propTypes = {
	categories: PropTypes.array,
};

SliderComponent.defaultProps = {
	categories: [],
};

export default SliderComponent;
