import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Grid, Fade } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import { ModalContainer, PaperContainer } from './styles'
import { ButtonClose } from 'components/NewButton/styles'

function ModalResendSms({
    children,
    className,
    closeByClick,
    handleSendSms,
    handleClose,
    maxWidth,
    minWidth,
    open,
    padding,
    shadow,
    t,
}) {
    return (
        <ModalContainer
            open={open}
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            maxwidth={maxWidth}
            minwidth={minWidth}
            disableEnforceFocus
        >
            <Fade in={open} unmountOnExit>
                <PaperContainer padding={'0px 30px'} maxwidth={maxWidth}>
                    <GridContainer
                        xs={12}
                        sm={12}
                        style={{ margin: '10px 30px 30px 2px' }}
                    >
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                cursor: 'pointer',
                            }}
                        >
                            <ButtonClose onClick={handleClose}>
                                <i
                                    className="icon-ico_cancelar"
                                    style={{
                                        fontSize: '20px',
                                        marginRight: '10px',
                                    }}
                                />
                                {t('BUTTON_CLOSE')}
                            </ButtonClose>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <i
                                className="icon-alerta_modal"
                                style={{
                                    color: germiniDefaultColors[11],
                                    fontSize: '80px',
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
                        <div
                            style={{
                                fontSize: '15px',
                            }}
                        >
                            {t('You have not yet validated the cell phone registered')}. <br />
                            {t("A new SMS will be sent with the confirmation")}. <br />
                            {t('Click on validation')}.
                            <br />
                        </div>
                    </GridItem>
                    <GridContainer
                        style={{
                            padding: '10px 0 0 10px',
                            marginBottom: '30px',
                        }}
                    >
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{ textAlign: 'center' }}
                        >
                            <Button
                                size="sm"
                                variant="contained"
                                color={'greenButtonColor'}
                                style={{
                                    marginLeft: '5px',
                                    padding: '8px 35px 8px 35px',
                                    borderRadius: '5px',
                                    fontSize: '16px',
                                }}
                                onClick={handleSendSms}
                            >
                                {t('Continue')}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </PaperContainer>
            </Fade>
        </ModalContainer>
    )
}

ModalResendSms.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    closeByClick: PropTypes.func,
    handleClose: PropTypes.func,
    handleSendSms: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
}

ModalResendSms.defaultProps = {
    children: null,
    className: '',
    closeByClick: null,
    handleClose: null,
    handleSendSms: null,
    maxWidth: '',
    minWidth: '',
    open: false,
    padding: '',
    shadow: '',
}

export default withTranslation()(ModalResendSms)
