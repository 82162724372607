import React from 'react'
import { createSvgIcon } from '@material-ui/core'

export const Asterisk = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18.669 18.669">
		<g id="noun_Asterisk_757030_1_" data-name="noun_Asterisk_757030 (1)" transform="translate(-60.888 -47.113)">
			<g id="Grupo_746" data-name="Grupo 746" transform="translate(60.888 47.113)">
				<g id="Grupo_745" data-name="Grupo 745" transform="translate(0 0)">
					<path id="Caminho_964" data-name="Caminho 964" d="M11.165,2.1a.723.723,0,1,1,1.022,1.022L9.372,5.939c-.267.267-.177.483.2.483h3.98a.723.723,0,1,1,0,1.445H9.572c-.377,0-.467.216-.2.483l2.815,2.815a.723.723,0,1,1-1.022,1.022L8.35,9.372c-.267-.267-.483-.177-.483.2v3.98a.723.723,0,1,1-1.445,0V9.572c0-.377-.216-.467-.483-.2L3.124,12.186A.723.723,0,1,1,2.1,11.165L4.917,8.35c.267-.267.177-.483-.2-.483H.737A.739.739,0,0,1,0,7.128a.732.732,0,0,1,.737-.706h3.98c.377,0,.467-.216.2-.483L2.1,3.124A.723.723,0,1,1,3.124,2.1L5.939,4.917c.267.267.483.177.483-.2V.737A.739.739,0,0,1,7.161,0a.732.732,0,0,1,.706.737V4.945c0,.252.144.312.322.133Z" transform="translate(5.468 0) rotate(22.5)" fill-rule="evenodd" />
				</g>
			</g>
		</g>
	</svg>,
	'Asterisk',
)
