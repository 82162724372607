import React from 'react'
import { withTranslation } from 'react-i18next'
import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'

import { Fade } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { ModalContainer, PaperContainer, LabelCheck } from './styles'

function ModalMobileDuplicated({
    children,
    className,
    closeByClick,
    handleForgetPWD,
    handleLogin,
    handleClose,
    maxWidth,
    minWidth,
    open,
    showButtons,
    padding,
    shadow,
    t,
    text,
    smsReceiveNumber,
    setSmsReceiveNumber,
    phoneNumber,
    phoneNumber2,
}) {
    const handleRadioChange = (val) => {
        setSmsReceiveNumber(val)
    }

    const submit = () => {
        handleClose()
    }

    return (
        <ModalContainer
            open={open}
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            maxwidth={maxWidth}
            minwidth={minWidth}
            disableEnforceFocus
        >
            <Fade in={open} unmountOnExit>
                <PaperContainer padding={padding} maxwidth={maxWidth}>
                    <GridContainer
                        xs={12}
                        sm={12}
                        style={{ margin: '10px 20px 30px 2px' }}
                    >
                        <GridItem
                            GridItem
                            xs={2}
                            sm={2}
                            style={{ marginTop: '15px' }}
                        >
                            <b>{t("Confirm_Mobile")}</b>
                        </GridItem>
                        <GridItem
                            GridItem
                            xs={7}
                            sm={7}
                            style={{ marginTop: '20px' }}
                        >
                            <div className="line"></div>
                        </GridItem>
                        <GridItem GridItem xs={3} sm={3}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                size="sm"
                                onClick={() => submit()}
                            >
                                {t('BUTTON_CONFIRM')}
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                size="sm"
                                color="greenBorderButtonColor"
                                onClick={handleClose}
                            >
                                {t("BUTTON_CANCEL")}
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <GridItem
                        xs={12}
                        sm={12}
                        style={{ margin: '10px 20px 30px 2px' }}
                    >
						{t('To proceed with the authentication of your login')}
                    </GridItem>
                    <GridItem xs={12} sm={12} style={{ marginBottom: '30px' }}>
                        <RadioGroup
                            value={smsReceiveNumber}
                            onChange={(e) => handleRadioChange(e.target.value)}
                            className={''}
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
							<div>
								<LabelCheck>{t("FIELD_REGISTER_MOBILE_PHONE")}:</LabelCheck>
								<FormControlLabel
									value="2"
									control={
										<Radio checkedIcon={<CheckCircle />} />
									}
									label={phoneNumber2}
								/>
							</div>
							<div>
								<LabelCheck>{t("FIELD_REGISTER_PHONE")}:</LabelCheck>
								<FormControlLabel
									value="1"
									control={
										<Radio checkedIcon={<CheckCircle />} />
									}
									label={phoneNumber}
								/>
							</div>
                        </RadioGroup>
                    </GridItem>
                </PaperContainer>
            </Fade>
        </ModalContainer>
    )
}

ModalMobileDuplicated.defaultProps = {
    children: null,
    className: '',
    closeByClick: null,
    handleClose: null,
    handleForgetPWD: null,
    handleLogin: null,
    maxWidth: '',
    minWidth: '',
    open: false,
    showButtons: true,
    padding: '',
    shadow: '',
    text: '',
}

export default withTranslation()(ModalMobileDuplicated)
