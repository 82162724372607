import MaterialTable from 'material-table'
import moment from 'moment'
import React, { useEffect } from 'react'
import { CurrencyTableWrapper } from './styls'
import Section from './Section'
import { useTranslation } from 'react-i18next'
import { GetExpirationPoints } from '../../../../redux/api/user'
import { Box } from '@mui/material'
import {
    InformationWhiteIcon,
    MuiBox,
    MuiTypography,
} from '../../../../components/storybook'
import Skeleton from '@mui/material/Skeleton'

function CurrencyTable(props) {
    const { t } = useTranslation()
    const { loading } = props

    const [pageSize, setPageSize] = React.useState(3)
    const [data, setData] = React.useState([])
    const [hasValueToExpire, setHasValueToExpire] = React.useState([])

    const getCurrency = () => {
        GetExpirationPoints().then((result) => {
            const hasValueToExpire = result?.data
            const data = result &&
                result?.data?.expireMonths && [...result?.data?.expireMonths]
            setPageSize(data.length)
            result && result?.data?.expireMonths ? setData(data) : setData([])
            setHasValueToExpire(hasValueToExpire)
        })
    }

    useEffect(() => {
        getCurrency()
    }, [])

    return (
        <CurrencyTableWrapper>
            <Section
                title={
                    data?.length > 0
                        ? `${data[0]?.simpleRules?.programCurrencyName} a expirar`
                        : 'Seeds a expirar'
                }
                subtitle={t('(Próximos 90 dias)')}
            />
            <div className="table-wrapper">
                {hasValueToExpire?.hasValueToExpire ? (
                    <MaterialTable
                        title={false}
                        search={false}
                        noToolbar={true}
                        data={() =>
                            new Promise((resolve) => {
                                GetExpirationPoints().then((result) => {
                                    const data = result &&
                                        result?.data?.expireMonths && [
                                            ...result?.data?.expireMonths,
                                        ]
                                    setPageSize(data.length)
                                    result && result?.data?.expireMonths
                                        ? resolve({
                                              data: data,
                                              page: 1,
                                              totalCount: 10,
                                          })
                                        : resolve({
                                              data: [],
                                              page: 0,
                                              totalCount: 0,
                                          })
                                })
                            })
                        }
                        options={{
                            emptyRowsWhenPaging: false,
                            search: false,
                            toolBar: false,
                            pageSize: pageSize,
                            rowStyle: {
                                backgroundColor: '#fff',
                                fontSize: '15px',
                            },
                            headerStyle: {
                                fontSize: '15px',
                                lineHeight: '1.3',
                            },
                        }}
                        columns={[
                            {
                                title: 'Quantidade',
                                field: 'pointsAcumulated',
                                render: (props) => (
                                    <Box
                                        textAlign="left"
                                        display="flex"
                                        alignItems="center"
                                        gap="5px"
                                    >
                                        <Box
                                            sx={{
                                                font: 'normal normal normal 12px Roboto',
                                            }}
                                        >
                                            {
                                                props.simpleRules
                                                    ?.programCurrencySymbol
                                            }
                                        </Box>
                                        <Box
                                            sx={{
                                                font: 'normal normal normal 15px Roboto',
                                            }}
                                        >
                                            {props.pointsAcumulated}
                                        </Box>
                                    </Box>
                                ),
                            },
                            {
                                title: 'Data',
                                field: 'year',
                                render: (props) => (
                                    <div style={{ textAlign: 'left' }}>
                                        {props.month &&
                                            moment(
                                                new Date(
                                                    props.year,
                                                    props.month - 1,
                                                    1,
                                                ),
                                            ).format('MM/YYYY')}
                                    </div>
                                ),
                            },
                        ]}
                    />
                ) : loading ? (
                    <Skeleton variant="rectangular" width="100%" height={150} />
                ) : (
                    <>
                        <MuiBox display="grid" justifyContent="center" mt={5}>
                            <InformationWhiteIcon color="#BEBEBE" size={45} />
                        </MuiBox>
                        <MuiBox display="grid" justifyContent="center" mt={2}>
                            <MuiTypography
                                color="#BEBEBE"
                                fontWeight={500}
                                fontSize="16px"
                            >
                                {t('POINTS_EXPIRE')}
                            </MuiTypography>
                        </MuiBox>
                    </>
                )}
            </div>
        </CurrencyTableWrapper>
    )
}

export default CurrencyTable
