import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'
import { useDispatch } from 'react-redux'
import { ShowLoader, HideLoader } from 'redux/actions'

import {
    Switch,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@material-ui/core'

import Section from '../../../components/Section/Section'
import Loader from 'components/Loader/Loader.jsx'

import * as S from './styles'
import {
    CustomButtonsBackground,
    CustomButtons,
} from '../../../components/CustomButtons'

import {
    PutUserPreference,
    GetUserPreference,
} from '../../../redux/api/AccountPreferences'
import { getProgramTitle } from '../../../utils/utils'

const AccountPreferences = (props) => {
    const [disabled, setDisabled] = useState(true)
    const [reload, setReload] = useState(false)
    const [viewImportantAlerts, setViewImportantAlerts] = useState(true)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            dispatch(ShowLoader(<Loader />))
            try {
                const response = await GetUserPreference()
                const { viewImportantAlerts } = response.data
                setViewImportantAlerts(viewImportantAlerts)
                setDisabled(true)
            } catch (error) {
                console.error(error)
            } finally {
                setReload(false)
                dispatch(HideLoader())
            }
        }

        fetchData()
    }, [dispatch, reload])

    const handleEdit = () => {
        setDisabled(false)
    }

    const handleCancel = () => {
        setDisabled(true)
        setReload(true)
    }

    const handleSave = async () => {
        dispatch(ShowLoader(<Loader />))
        try {
            const response = await PutUserPreference(viewImportantAlerts)
            setViewImportantAlerts(response.data)
            setDisabled(true)
        } catch (error) {
            console.error(error)
        } finally {
            dispatch(HideLoader())
        }
    }

    return (
        <S.container>
            <DocumentTitle
                title={getProgramTitle(t('MENU_USER_ACCOUNT_PREFERENCES'))}
            />

            <Section title={t('MENU_USER_ACCOUNT_PREFERENCES')}>
                {disabled ? (
                    <CustomButtonsBackground onClick={handleEdit}>
                        {t('BUTTON_EDIT')}
                    </CustomButtonsBackground>
                ) : (
                    <CustomButtonsBackground onClick={handleSave}>
                        {t('BUTTON_SAVE')}
                    </CustomButtonsBackground>
                )}
                {!disabled && (
                    <CustomButtons onClick={handleCancel}>
                        {t('BUTTON_CANCEL')}
                    </CustomButtons>
                )}
            </Section>

            <S.bodyStyle>
                <FormControl>
                    <S.showMessage>{t('FIELD_SHOW_MESSAGE')}</S.showMessage>
                    <RadioGroup
                        aria-label="quiz"
                        name="viewImportantAlerts"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={viewImportantAlerts}
                                    onChange={() => {
                                        setViewImportantAlerts(
                                            !viewImportantAlerts,
                                        )
                                    }}
                                    name="checkedB"
                                    color="primary"
                                    disabled={disabled}
                                />
                            }
                            label={viewImportantAlerts ? 'Sim' : 'Não'}
                        />
                    </RadioGroup>
                </FormControl>
            </S.bodyStyle>
        </S.container>
    )
}

export default AccountPreferences
