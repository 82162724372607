import React from 'react'
import * as _ from 'lodash'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

//components
import Button from 'components/CustomButtons/Button.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section/Section'

// @material
import { withStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// UTILS
import compose from 'utils/compose'

// style
import styles from './regulation.module.scss'

// REDIX INIT
import {
	PostConsumerRegulation,
	GetRegulationData,
	GetCurrentConsumer,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from '../../redux/actions'

import checkStyle from '../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { getProgramTitle } from '../../utils/utils.js'

//import styles from './styles.module.scss';

class Regulation extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			regulation: '',
			accepted: false,
			disabled: false,
			userData: {},
		}
	}

	componentDidMount() {
		this.props.GetRegulationData()
		if (!this.props.userData) {
			this.props.GetCurrentConsumer()
		} else {
			this.setState({ userData: this.props.userData })
			this.setState({
				accepted:
					this.props.userData &&
					this.props.userData.agreements &&
					this.props.userData.agreements.acceptedCurrentRegulation &&
					this.props.userData.agreements.acceptedCurrentRegulation,
				disabled:
					this.props.userData &&
					this.props.userData.agreements &&
					this.props.userData.agreements.acceptedCurrentRegulation &&
					this.props.userData.agreements.acceptedCurrentRegulation,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.regulationData) {
			if (prevProps.regulationData !== this.props.regulationData) {
				this.setState({
					regulation: this.props.regulationData,
					accepted:
						this.props.regulationData.consumerAgreements &&
						this.props.regulationData.consumerAgreements
							.acceptedCurrentRegulation,
					disabled:
						this.props.regulationData.consumerAgreements &&
						this.props.regulationData.consumerAgreements
							.acceptedCurrentRegulation,
				})
			}
		}

		if (prevProps.userData !== this.props.userData) {
			this.setState({
				userData: this.props.userData,
				accepted:
					this.props.userData &&
					this.props.userData.agreements &&
					this.props.userData.agreements.acceptedCurrentRegulation &&
					this.props.userData.agreements.acceptedCurrentRegulation,
				disabled:
					this.props.userData &&
					this.props.userData.agreements &&
					this.props.userData.agreements.acceptedCurrentRegulation &&
					this.props.userData.agreements.acceptedCurrentRegulation,
			})
		}

		if (
			prevProps.consumerRegulationPost !==
			this.props.consumerRegulationPost
		) {
			this.props.HideLoader()
			this.props.GetCurrentConsumer()
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_SUCCESS')}
				</Alert>,
			)
			setTimeout(() => {
				this.props.HideAlert()
			}, 3000)
		}

		if (prevProps.userData !== this.props.userData) {
			this.setState({ userData: this.props.userData })
		}
		if (
			prevProps.regulationDataFailed !== this.props.regulationDataFailed
		) {
			this.props.HideLoader()
			// console.log('ERROR ON LOADING REGULATION DATA')
		}
	}

	handleChangeSwitch = () => {
		this.setState({ accepted: !this.state.accepted })
	}

	handleChangeIsActive = (event) => {
		this.setState({ checkRegulation: event.target.checked })
	}

	submit = (e) => {
		e.preventDefault()
		this.props.ShowLoader(<Loader />)
		if (this.state.accepted) {
			this.acceptRegulation()
		} else {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_REQUIRED_REGULATION')}
				</Alert>,
			)
		}
	}

	acceptRegulation = () => {
		const { regulation, userData } = this.state
		const obj = {
			consumerId: userData.id,
			regulationRulesId: regulation.id,
		}
		this.props.PostConsumerRegulation(obj)
	}

	isRegulationAccepted = (userRegulations, id) => {
		const obj = _.find(userRegulations, { regulationRulesId: id })
		if (obj) return true
		return false
	}

	render() {
		const { t, classes } = this.props
		const { regulation, userData, accepted, disabled } = this.state
		return (
			<Grid>
				<DocumentTitle title={getProgramTitle(t('NAVPILLS_REGULATION'))} />

				<Section title={t('PROGRAM_REGULATION')} />
				<Typography
					className={styles.regulationContent}
					dangerouslySetInnerHTML={{ __html: regulation.regulation }}
				></Typography>
				{userData && regulation && (
					<form
						name="form"
						onSubmit={this.submit}
						style={{
							backgroundColor: '#F4F4F4',
							borderRadius: '4px',
						}}
					>
						<GridItem
							className={styles.formContent}
						>
							<FormControlLabel
								control={
									<Checkbox
										onClick={this.handleChangeSwitch}
										disabled={disabled}
										checked={accepted}
										checkedIcon={
											<Check
												className={classes.checkedIcon}
											/>
										}
										icon={
											<Check
												className={
													classes.uncheckedIcon
												}
											/>
										}
										classes={{
											checked: classes.checked,
											root: classes.checkRoot,
										}}
										inputProps={{
											id: 'checkRegulation',
										}}
									/>
								}
								classes={{
									label: classes.label,
								}}
								label={t(
									'REGULATION_TERMS_ACCEPT_LABEL_MESSAGE',
								)}
							/>
							{!disabled && (
								<div>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										size="sm"
										className={styles.confirmButton}
										disabled={disabled}
									>
										{t('BUTTON_CONFIRM')}
									</Button>
								</div>
							)}
						</GridItem>
					</form>
				)}
			</Grid>
		)
	}
}

Regulation.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		regulationData: state.regulation.regulationData,
		regulationDataFailed: state.regulation.regulationDataFailed,

		consumerRegulationPost: state.users.consumerRegulationPost,

		userData: state.users.userData,
		userDataFailed: state.users.userDataFailed,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			PostConsumerRegulation,
			GetRegulationData,
			GetCurrentConsumer,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation(),
	withStyles(checkStyle),
	withStyles(extendedFormsStyle),
)(Regulation)
