import Axios from 'axios'

export default class ScoringRulesService {
    async getSimulatePointsConversion(value) {
        const response = await Axios.get(
            `/PointsConversion/SimulatePointsConversion?pointsValue=${value}`,
        )
        return response?.data
    }
    async updateRequestPointsConversion(data) {
        const response = await Axios.post(
            '/PointsConversion/RequestPointsConversion',
            data,
        )
        return response?.data
    }
}
