import React from 'react'
import {
    MuiBox,
    MuiDefaultModal,
    Button,
    GridItem,
    MuiTypography,
    MuiInput,
    GridContainer,
    AlertModalIcon,
} from '../../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { Controller } from 'react-hook-form'
import { styleModalConfirmPassword } from '../../utils'
export function ConfirmRefundModal({
    watchFields,
    isSmallScreen,
    setIsModalConfirmOpen,
    isModalConfirmOpen,
    handleSubmit,
    handlePasswordSubmit,
    control,
}) {
    return (
        <MuiDefaultModal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={isModalConfirmOpen}
            onClose={() => setIsModalConfirmOpen(false)}
        >
            <MuiBox
                sx={{
                    ...styleModalConfirmPassword,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                <form onSubmit={handleSubmit(handlePasswordSubmit)}>
                    <GridContainer spacing={2}>
                        <GridItem xs={12} sm={12} md={12}>
                            <AlertModalIcon size={70} color="#F8BB86" />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <MuiTypography
                                color={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                fontSize="14px"
                                fontWeight={600}
                            >
                                CONFIRMAÇÃO
                            </MuiTypography>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} mt={1}>
                            <MuiTypography color="#333333" fontSize="14px">
                                Para prosseguir com a transferência é necessário
                                informar a sua senha.
                            </MuiTypography>
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            mt={2}
                            textAlign="left"
                        >
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                render={({ ...field }) => (
                                    <MuiInput
                                        {...field}
                                        placeholder="Senha*"
                                        standard
                                        showLabel
                                        textLabel={
                                            watchFields?.password
                                                ? '*Senha'
                                                : ' '
                                        }
                                        type="password"
                                        defaultColor={
                                            GetThemeColorFromStorage()
                                                ?.navigation?.buttons
                                                ?.backgroundColor
                                        }
                                    />
                                )}
                            />
                        </GridItem>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{
                                paddingLeft: isSmallScreen
                                    ? 0
                                    : '105px!important',
                            }}
                        >
                            <Button
                                defaultColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                icon=""
                                outlined="outlined"
                                text="CONFIRMAR"
                                textColor="#FFFFFF"
                                typeVariation="CustomTextButton"
                                borderRadius="5px"
                                type="submit"
                            />
                        </GridItem>

                        <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            sx={{
                                paddingRight: isSmallScreen
                                    ? 0
                                    : '105px!important',
                            }}
                        >
                            <Button
                                borderColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                defaultColor="transparent"
                                icon=""
                                outlined="outlined"
                                text="CANCELAR"
                                textColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                typeVariation="CustomTextButton"
                                borderRadius="5px"
                                onClick={() => setIsModalConfirmOpen(false)}
                            />
                        </GridItem>
                    </GridContainer>
                </form>
            </MuiBox>
        </MuiDefaultModal>
    )
}
