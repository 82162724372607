import * as actionTypes from './actionsTypes';

export const sweetAlert = (data) => {
    return {
        type: actionTypes.SWEET_ALERT,
        alert: data
    };
};

export const hideAlert = () => {
    return {
        type: actionTypes.HIDE_ALERT,
        alert: null
    };
};

//----------------------------------------------------

export const SweetAlert = (data) => {
    return dispatch => (
        dispatch(sweetAlert(data))
    );
};

export const HideAlert = () => {
    return dispatch => (
        dispatch(hideAlert())
    );
}
