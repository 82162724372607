import { RequestConsumerTransfer } from '../../redux/api/user'

const transferBodySchema = (
    _password,
    _transferType,
    _value,
    _liquidValue,
    _anticipationFee,
    _transferFee,
) => {
    return {
        password: _password,
        transferType: _transferType,
        value: _value,
        anticipationFee: _anticipationFee,
        transferFee: _transferFee,
        liquidValue: _liquidValue,
    }
}

export const dealWithAPI = async (
    showModal,
    setTransferRequisitionResponse,
    userPasswordFromDataBase,
    setPasswordModal_open,
    _paymentOption,
    _value,
    _anticipationFee,
    _transferFee,
    _liquidValue,
) => {
    try {
        const res = await RequestConsumerTransfer({
            password: userPasswordFromDataBase,
            transferType: _paymentOption,
            value: _value,
            anticipationFee: _anticipationFee,
            transferFee: _transferFee,
            liquidValue: _liquidValue,
        })
        //se tem responsta...
        if (res) {
            if (_value > 0) {
                setTransferRequisitionResponse(res)
                setPasswordModal_open(true)
            }
        } else {
            showModal(
                `Não foi possível obter resposta do banco de dados. Por favor, tente novamente mais tarde.`,
                false,
            )
        }
        return res
    } catch (error) {
        console.log(error)
        showModal(`Verifique a opção e o valor de transferência.#`, false)
    }
}
