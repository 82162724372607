import React from 'react'
import { Icon, Tooltip } from '@material-ui/core'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import styles from './voucherCard.module.scss'
import { useTranslation } from 'react-i18next'

const VoucherCard = (props) => {
    const { data, value, destiny, anonymo, classes } = props

    const { t } = useTranslation()

    return (
        <div className={styles.card}>
            <div className={styles.image} />
            <div className={styles.content}>
                <div className={styles.title}>Data de envio</div>
                <div className={styles.description}>{data}</div>

                <div className={styles.title}>Valor</div>
                <div className={styles.description}>{value}</div>

                <div className={styles.title}>Destino</div>

                <div className={styles.descriptionWrapper}>
                    <div className={styles.description} title={destiny}>
                        {destiny}
                    </div>
                    {anonymo === true && (
                        <Tooltip
                            arrow
                            id="tooltip-top"
                            title={t('ANONYMOUS_GIFT_SENT')}
                            placement="top-start"
                            classes={{
                                tooltip: classes.tooltip,
                            }}
                        >
                            <Icon
                                className="icon-anonimo"
                                style={{
                                    width: '1.5em',
                                    fontSize: '16px',
                                    color: germiniDefaultColors[0],
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VoucherCard
