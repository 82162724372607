import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { Button, Container, IconAlert, TextAlert } from './styles';



const ShowIntegrationModal = ({ open, handleClose }) => {
	const { t } = useTranslation();
	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Container>
				<IconAlert className="icon-alerta_modal" />
				<TextAlert >
					ATENÇÃO
				</TextAlert>
				<Typography component="div" >
					A partir de agora, utilize o mesmo login e senha cadastrados para acessar a loja.
				</Typography>
				<Button
					borderColor="#B2B2B2"
					color="#F05B4F"
					bgHover="#F05B4F" onClick={handleClose}

				>
					{t('BUTTON_CLOSE')}
				</Button>
			</Container>
		</Modal>
	);
}

ShowIntegrationModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
};


export default ShowIntegrationModal;
