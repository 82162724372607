import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../utility';

const initialState = {
    tierData: [],
    tierDataFailed: false,
    activeTierData: [],
    activeTierDataFailed: false
}

const fetchData = (state, action) => {
    return updateObject(state, {
        tierData: action.tierData
    });
}

const fetchDataFailed = (state, action) => {
    return updateObject(state, {
        tierDataFailed: action.tierDataFailed
    });
}

// active
const fetchActiveData = (state, action) => {
    return updateObject(state, {
        activeTierData: action.activeTierData
    });
}

const fetchActiveDataFailed = (state, action) => {
    return updateObject(state, {
        activeTierDataFailed: action.activeTierDataFailed
    });
}

const tierReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TIER_DATA:
            return fetchData(state, action);
        case actionTypes.FETCH_TIER_FAILED:
            return fetchDataFailed(state, action);

        case actionTypes.FETCH_ACTIVE_TIER_DATA:
            return fetchActiveData(state, action);
        case actionTypes.FETCH_ACTIVE_TIER_FAILED:
            return fetchActiveDataFailed(state, action);

        default:
            return state;
    }
}

export default tierReducer;