import React, { useState } from "react";
import {
  PageContainer,
  LeftArrow,
  RightArrow,
  LeftArrowFirst,
  RightArrowEnd,
} from "./styles";

function PageModal({ total = 1, sendPage }) {
  const [pageNumber, setPageNumber] = useState(1);

  function backPage(page) {
    if (page < 1) {
      return;
    }
    sendPage(page);
    setPageNumber(page);
  }

  function forwardPage(page, readAllImportantAlerts) {
    if (page > total) {
      return;
    }
    sendPage(page, readAllImportantAlerts);
    setPageNumber(page);
  }

  function endClick(total) {
    return forwardPage(total, true);
  }

  return (
    <PageContainer container>
      <LeftArrowFirst
        onClick={() => backPage(pageNumber - pageNumber + 1)}
        disable={pageNumber <= 1}
      ></LeftArrowFirst>
      <LeftArrow
        onClick={() => backPage(pageNumber - 1)}
        disable={pageNumber <= 1}
      ></LeftArrow>
      <div>
        {pageNumber} de {total}
      </div>
      <RightArrow
        onClick={() => forwardPage(pageNumber + 1)}
        disable={pageNumber >= total}
      ></RightArrow>
      <RightArrowEnd
        onClick={() => endClick(total)}
        disable={pageNumber >= total}
      ></RightArrowEnd>
    </PageContainer>
  );
}

export default PageModal;
