import React from 'react'
import Alert from 'react-bootstrap-sweetalert'
import { Box, Typography } from '@material-ui/core'
import './styles.css'
import Cookies from 'js-cookie'

export function AlertPhoneNumber({ hideAlert, onConfirm }) {
    const theme =
        JSON.parse(Cookies.get('themeColor')) ||
        JSON.parse(localStorage.getItem('themeColor'))
    const themeColor = theme?.navigation?.buttons?.backgroundColor

    return (
        <div className="custom-alert">
            <Alert
                warning
                style={{
                    display: 'block',
                    marginTop: '-150px',
                    marginRight: '150px',
                    left: '45%',
                }}
                confirmBtnText="Reenviar e-mail"
                className="custom-alert"
            >
                <Box>
                    <button
                        style={{
                            backgroundColor: `#f25756`,
                            color: '#fff',
                            fontSize: '15px',
                            padding: '5px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            marginTop: '15px',
                            position: 'absolute',
                            float: 'right',
                            top: '1px',
                            left: '80%',
                        }}
                        onClick={hideAlert}
                    >
                        Fechar
                    </button>
                </Box>
                <Box textAlign="center">
                    <Typography className="title">
                        Você ainda não validou o celular cadastrado para o login
                        informado.
                    </Typography>
                    <Box className="description">
                        <Typography>
                            Será enviado um novo sms com o código de confirmação
                            para você.
                        </Typography>
                        <Typography>
                            Clique em 'continuar' para prosseguir com a
                            validação do seu cadastro.
                        </Typography>
                    </Box>

                    <button
                        style={{
                            backgroundColor: `${themeColor}`,
                            color: '#fff',
                            fontSize: '15px',
                            padding: '10px 25px',
                            borderRadius: '5px',
                            border: 'none',
                            marginTop: '15px',
                        }}
                        onClick={onConfirm}
                    >
                        Continuar
                    </button>
                </Box>
            </Alert>
        </div>
    )
}
