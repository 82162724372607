import React from 'react'
import {
    Button,
    GridContainer,
    GridItem,
    MuiBox,
    MuiSnack,
} from '../../components/storybook'
import DocumentTitle from 'react-document-title'
import Section from '../../components/Section/Section'
import { getProgramTitle } from '../../utils/utils.js'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor.js'
import { Modal } from './components/Modal/index.jsx'
import { ModalTransferValue } from './components/ModalTransferValue/index.jsx'
import { MessageSuccess } from './components/MessageSuccess/index.jsx'
import { Warning } from './components/Warning/index.jsx'
import { ConvertedPoints } from './components/ConvertedPoints/index.jsx'
import { AcceptTerms } from './components/AcceptTerms/index.jsx'

const TemplatePage = ({ ...sharedProps }) => {
    const {
        setIsModalTransferOpen,
        isSmallScreen,
        setErrorMessage,
        errorMessage,
        messageSuccess,
        isButtonDisabled,
        errorMessageValue,
    } = sharedProps

    return (
        <div>
            <DocumentTitle title={getProgramTitle('Converter meus pontos')} />
            <MuiBox mt={1}>
                <MuiSnack
                    open={errorMessage}
                    message={errorMessage}
                    type="error"
                    autoHideDuration={5000}
                    onClose={() => setErrorMessage('')}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                />
                <Section
                    title="Converter meus pontos"
                    children={
                        !messageSuccess && (
                            <Button
                                customSize="235px"
                                border={`1px solid ${
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }`}
                                defaultColor={
                                    GetThemeColorFromStorage()?.navigation
                                        ?.buttons?.backgroundColor
                                }
                                icon=""
                                outlined="outlined"
                                text="Transferir para carteira digital"
                                textColor="#FFFFFF"
                                typeVariation="CustomTextButton"
                                borderRadius="5px"
                                onClick={() => setIsModalTransferOpen(true)}
                                disabled={isButtonDisabled || errorMessageValue}
                            />
                        )
                    }
                />
                {messageSuccess ? (
                    <MessageSuccess {...sharedProps} />
                ) : (
                    <GridContainer
                        spacing={isSmallScreen ? 0 : 2}
                        mt={2}
                        pl={isSmallScreen && 2}
                        pr={isSmallScreen && 2}
                    >
                        {/************** WARNING **************/}
                        <Warning {...sharedProps} />

                        {/************** CONVERTED POINTS **************/}
                        <ConvertedPoints {...sharedProps} />

                        {/************** ACCEPT TERMS **************/}
                        <AcceptTerms {...sharedProps} />

                        {/************** MODAL USETERMS **************/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Modal {...sharedProps} />
                        </GridItem>

                        {/************** MODAL TRANSFER **************/}
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <ModalTransferValue {...sharedProps} />
                        </GridItem>
                    </GridContainer>
                )}
            </MuiBox>
        </div>
    )
}

export default TemplatePage
