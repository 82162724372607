import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

export const Container = styled(Grid)`
    &.MuiGrid-item {
        margin-top: 1rem;
    }

    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 22px 14px;

    @media screen and (min-width: 960px) {
        fieldset {
            .MuiFormGroup-root {
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-template-areas:
                    'selectAll input1'
                    'selectAll input2'
                    'selectAll input3'
                    'selectAll input4';

                .MuiFormControlLabel-root:first-child {
                    grid-area: selectAll;
                    border-right: 1px solid #b4b4b4;
                }
            }
        }
    }
`

export const CustomLink = styled.link`
    margin-left: 4px;
    display: contents;
`
