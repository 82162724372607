import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader.jsx'
import moment from 'moment'

// @ Components
import CustomDate from 'components/CustomDate'
import ConfirmModal from 'components/ConfirmModal'
import Button from 'components/CustomButtons/Button.jsx'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer.jsx'

// @ Material UI
import Autocomplete from '@mui/material/Autocomplete'

// @ Utils
import { CssTextField } from 'components/CssTextField/CssTextField'


// @ Utils and Styles
import * as S from '../styles'
import {
	periodOptions,
	defaultValues,
	handleChangePeriod,
	Open,
	IsDisabled,
	ordinationOptions,
	statusOptions,
} from './utils'

// PaymentGateway
import {
	ExportAllTransactionLogs,
} from '../../../../../../../redux/api/paymentGateway'

const SearchForm = ({ tableRef, getLogs }) => {
	const { t } = useTranslation()

	const [openModal, setOpenModal] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)

	const { control, handleSubmit, reset, watch, setValue } = useForm({
		defaultValues: { ...defaultValues, ordination: ordinationOptions[0] },
	})

	const { period } = watch()

	const handleSearch = e => {
		tableRef.current && tableRef.current.onQueryChange(e)
	}

	useEffect(() => {
		period && handleChangePeriod(period, setValue)
	}, [period, setValue])



	const handleClickExportModal = () => {
		setOpenModal(true)
	}

	const handleExportLog = async () => {
		const obj = getLogs(watch())
		setIsLoaded(true)
		try {
			await new Promise(resolve => setTimeout(resolve, 3000))
			const logs = await ExportAllTransactionLogs(obj)

			if (logs.status === 200) {
				const newData = logs?.data

				const url = window.URL.createObjectURL(new Blob([newData]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'arquivo.xlsx')

				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
			handleCloseExportModal()
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoaded(false)
		}
	}

	const handleCloseExportModal = () => setOpenModal(false)

	const isValid = current => {
		const startDate = watch('dateFrom')
		const today = moment()
		return current.isAfter(startDate) && current.isBefore(today)
	}

	const validation = currentDate => {
		const dateTo = watch('dateTo')
		const today = moment()
		if (moment(dateTo, 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(dateTo)) {
				return false
			}
		}
		return currentDate.isBefore(today)
	}

	const focousOut = () => {
		const dateTo = watch('dateTo')
		if (!moment(dateTo, 'DD/MM/YYYY').isValid()) {
			setValue('dateTo', '')
		}
		if (!isValid(moment(dateTo), 'DD/MM/YYYY')) {
			setValue('dateTo', '')
		}
	}


	return (
		<S.CustomCard>
			{isLoaded ? (
				<Loader />
			) : (
				<ConfirmModal
					open={openModal}
					onConfirm={handleExportLog}
					onCancel={handleCloseExportModal}
					btnCloseTxt={t('BUTTON_NO')}
					btnConfirmTxt={t('BUTTON_YES')}
					text={t('MODAL_EXPORT_LOGS')}
				/>
			)}
			<S.TabPanel>
				<GridContainer>
					<GridItem
						xs={12}
						style={{
							fontSize: '16px',
							color: '#717171',
							marginBottom: '20px',
							fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
						}}
					>
						{t('SEARCH_OPTIONS')}
					</GridItem>
					<GridItem
						className="grid-item-margin"
						xs={12}
						sm={12}
						md={3}
						lg={3}
					>
						<Controller
							name="period"
							control={control}
							defaultValue={[]}
							render={({ onChange, value }) => (
								<Autocomplete
									disablePortal
									getOptionLabel={option =>
										option.label ? option.label : ''
									}
									options={periodOptions}
									value={value}
									disableClearable
									onChange={(e, value) => onChange(value)}
									renderInput={(params) => (
										<div ref={params.InputProps.ref}>
											<CssTextField
												{...params}
												style={{
													width: '100%',
												}}
												label={t(
													'FIELD_PERIOD',
												)}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
								/>
							)}
						/>
					</GridItem>
					<GridItem
						className="grid-item-date"
						xs={12}
						sm={12}
						md={5}
						lg={5}

					>
						<Controller
							name="dateFrom"
							defaultValue=""
							control={control}
							as={
								<CustomDate
									size="small"
									variant="outlined"
									label={t('DATETIME_FROM')}
									maxDate={new Date().getTime()}
									margin="normal"
									timeFormat={false}
									open={Open(watch('period'))}
									disabled={IsDisabled(watch('period'))}
									enableDates={IsDisabled(watch('period'))}
									isValidDate={e => validation(e)}
								/>
							}
						/>
						<Controller
							name="dateTo"
							defaultValue=""
							control={control}
							as={
								<CustomDate
									size="small"
									variant="outlined"
									label={t('DATETIME_TO')}
									margin="normal"
									timeFormat={false}
									onBlur={focousOut}
									isValidDate={isValid}
									open={Open(watch('period'))}
									disabled={IsDisabled(watch('period'))}
									enableDates={IsDisabled(watch('period'))}
									maxDate={new Date().getTime()}
								/>
							}
						/>
					</GridItem>
					<GridItem
						className="grid-item-margin"
						xs={12}
						sm={12}
						md={4}
						lg={4}
					>
						<Controller
							name="status"
							control={control}
							defaultValue={[]}
							render={({ onChange, value }) => {
								return (
									<Autocomplete
										disablePortal
										id="combo-box-demo"
										size="small"
										options={statusOptions}
										getOptionLabel={option =>
											option.label ? option.label : ''
										}
										value={value}
										disableClearable
										onChange={(e, value) => onChange(value)}
										renderOption={(props, option) => (
											<div {...props}>
												<S.IconStatus
													style={{
														backgroundColor:
															option.value === 2
																? '#F05B4F'
																: '#5CB860',
													}}
												/>
												<p style={{ margin: '10px' }}>
													{option.label}
												</p>
											</div>
										)}
										renderInput={params => (
											<CssTextField
												{...params}
												size="small"
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												variant="outlined"
												label={t('FIELD_STATUS')}
											/>
										)}
									/>
								)
							}}
						/>
					</GridItem>
					<GridItem
						className="grid-item-margin"
						xs={12}
						sm={12}
					>
						<Controller
							name="history"
							control={control}
							defaultValue=""
							as={
								<CssTextField
									variant="outlined"
									style={{
										backgroundColor: '#ffffff',
										width: '100%',
									}}
									formControlProps={{
										fullWidth: true,
									}}
									size="small"
									label={t('FIELD_SEARCH_LOG')}
								/>
							}
						/>
					</GridItem>
				</GridContainer>
				<GridItem xs={12}>
					<S.ButtonsContainer>
						<Button
							size="sm"
							variant="contained"
							color="greenButtonColor"
							style={{ margin: '0px' }}
							className="button-wrapper"
							onClick={handleSubmit(handleSearch)}
						>
							{t('BUTTON_FIND')}
						</Button>
						<Button
							size="sm"
							onClick={() => {
								reset(defaultValues)
								tableRef.current &&
									tableRef.current.onQueryChange(
										defaultValues,
									)
							}}
							style={{ margin: '0px' }}
							className="button-wrapper"
							color="greenBorderButtonColor"
						>
							{t('BUTTON_CLEAN_FILTER')}
						</Button>
						<Button
							size="sm"
							variant="contained"
							color="greenButtonColor"
							style={{ margin: '0px' }}
							className="button-wrapper"
							onClick={event => handleClickExportModal(event)}
						>
							<i
								className="icon-exportar_relatorios"
								style={{ marginRight: '5px' }}
							></i>
							{t('BUTTON_EXPORT_LOG')}
						</Button>
					</S.ButtonsContainer>
				</GridItem>
			</S.TabPanel>
		</S.CustomCard>
	)
}

SearchForm.propTypes = {
	tableRef: PropTypes.func,
	getLogs: PropTypes.func,
}

export default SearchForm
