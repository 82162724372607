import React from 'react'
import { useAuth } from './authconfig'
import { Redirect } from 'react-router-dom'

export const RequireAuth = ({ children }) => {
    const auth = useAuth()

    if (!auth) {
        return <Redirect to="/home" />
    }
    return children
}
