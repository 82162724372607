import styled from 'styled-components'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import { grayColor } from 'assets/jss/material-dashboard-pro-react'

export const Container = styled.div`
    position: relative;
    min-height: 100vh;

    .text-justify {
        @media screen and (max-width: 500px) {
            text-align: justify;
        }
    }
`

export const GridItemText = styled(GridItem)`
    display: flex;

    a {
        margin-left: 5px;
        color: ${({ grayColor }) => grayColor};
        font-weight: 600;
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
`

export const GridContainerFooter = styled(GridContainer)`
    position: absolute;
    bottom: 0;
    background-color: ${({ themeColor }) =>
        themeColor?.footer?.backgroundColor};
    height: 60px;
`

export const ContainerButton = styled.div`
    float: left;
    color: ${({ themeColor }) => themeColor?.navigation?.buttons?.textColor};
    background-color: ${({ themeColor }) =>
        themeColor?.navigation?.buttons?.backgroundColor};
    width: auto;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;

    @media screen and (max-width: 700px) {
        position: absolute;
        right: 10px;
        margin-top: -140px;
    }
`

export const BoxFooter = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`
export const Box = styled.div`
    color: #666;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1150px;
    z-index: 5;
    margin-top: -20px;
    font-size: 0.8rem;
    opacity: 0.7;
`
export const BoxImage = styled.div`
    background-image: ${({ themeColor }) =>
        themeColor ? ` url(${themeColor})` : ''};
`

export const Snack = {
    open: false,
    severity: '',
    text: '',
}

export const SectionText = styled.div`
    display: flex;
    gap: 5px;
`

export const LinkText = styled.b`
    cursor: pointer;
    color: ${grayColor[2]};
    font-weight: bold;
`

export const LinkEmail = styled.b`
    cursor: pointer;
    color: ${({ themeColor }) =>
        themeColor?.navigation?.buttons?.backgroundColor || ''};
    font-weight: bold;
    padding-left: 5px;

    @media screen and (max-width: 500px) {
        padding-left: 0px;
    }
`
