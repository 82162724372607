import * as API from '../../../redux/api/user'

export default (setUserTotalMoney, showModal, setUserTransferLimit) => {
    API.GetConsumerTransferAccountInfo()
        .then((res) => {
            const transferInfo = JSON.parse(JSON.stringify(res))
            if (transferInfo.success) {
                setUserTotalMoney(transferInfo.data.balanceAvailable)

                if (transferInfo.data.hasTransferLimit === false) {
                    setUserTransferLimit(1)
                } else {
                    setUserTransferLimit(transferInfo.data.transferLimitLeft)
                }
            }
        })
        .catch((err) => {
            showModal(
                `Não foi possível resgatar os dados de sua carteira digital.#${err}#`,
                false,
            )
        })
}
