import styled from 'styled-components/macro'
import { TextField, Grid } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import { FormControlLabel } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

export const MyCurrencyTextField = styled(CurrencyTextField)`
    &.MuiFormControl-root {
        margin-top: 8px;
    }

    .MuiOutlinedInput-input {
        padding-top: 10px;
        padding-bottom: 10px;
    }
`

export const MyGridItem = styled(GridItem)`
    .GridItem-grid-315 {
        padding: 0px !important;
    }
`

export const MyTextField = styled(TextField)`
    .MuiInputBase-input {
        background: #f4f4f4;
        font-size: small;
        height: 19px;
    }

    .MuiInputBase-input.Mui-disabled {
        opacity: 1;
    }
`

export const MyTooltip = styled(Tooltip)``

export const MyFormControlLabel = styled(FormControlLabel)`
    .MuiTypography-body1 {
        color: ${GetThemeColorFromStorage()?.titles?.highlight?.textColor};
        text-decoration: underline;
        text-decoration-style: dotted;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.elementsColor};
    }
`

export const TextInput = styled(TextField)``

export const ValName = styled.div`
    color: red;
`

export const GridBox = styled(Grid)`
    width: 100%;
    margin-top: 30px;

    tbody {
        border-left: none;
        border-right: none;
    }

    .greyInfo {
        display: flex;
        align-items: center;
        font-weight: 400;
        background-color: #eee;
        color: ${GetThemeColorFromStorage()?.titles?.highlight?.textColor};
    }

    .MuiPaper-elevation2 {
        box-shadow: unset;
    }

    .MuiToolbar-regular {
        min-height: 0px;
    }

    .MuiTableHead-root {
        tr {
            th {
                background-color: #e9e9e9;
                color: ${GetThemeColorFromStorage()?.titles?.secondary
                    ?.textColor};
                font-size: 16px;
                font-weight: bold;
                height: 40px;

                padding: 0px 16px;

                :first-child {
                    border-top-left-radius: 6px;
                    width: 22% !important;
                }

                :last-child {
                    border-top-right-radius: 6px;
                    width: 15% !important;
                    span {
                        svg {
                            display: none;
                        }
                    }
                }
                :nth-child(2) {
                    text-align: left !important;
                }
            }
        }
    }

    .MuiTableBody-root {
        tr {
            color: #717171;
            font-size: 15px;
            height: 45px;

            p {
                font-size: 13px;
                font-style: italic;
                margin: 0px;
            }

            td {
                padding: 0px 15px !important;

                :last-child {
                    > div {
                        /* justify-content: flex-end; */
                        /* text-align: right !important; */
                    }
                }
                :nth-child(2) {
                    text-align: left !important;
                }
            }
        }
    }
`

export const GridContent = styled(Grid)``

export const IndicationBox = styled(GridContainer)`
    .button-box {
        display: flex;
        align-items: center;
        padding-left: 0px;
        padding-right: 15px;
    }

    @media screen and (max-width: 720px) {
        position: relative;
        left: -15px;
    }
`

export const IndicationItem = styled(Grid)`
    padding: 0px 15px 0px;

    &.send-button {
        justify-content: flex-end;
        display: flex;
        padding-right: 0px;
        padding-bottom: 30px;
    }

    @media screen and (max-width: 720px) {
        .send-button {
            position: relative;
            right: -15px;
        }
    }
`

export const ContentGrid = styled(Grid)`
    padding-left: 0.875rem;

    .gridItem {
        transform: translateY(15px);
    }

    .gdItem {
        display: flex;
        gap: 13px;
        padding-left: 15px;
    }
`
