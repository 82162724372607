import React from 'react'

// @ Context and Utils
import CardLog from './CardLog'

export const CardServices = () => {

	return (
		<CardLog />
	)
}
