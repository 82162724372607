import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
//const themeStorage = JSON.parse(localStorage.getItem('themeColor'))

export const LoginContainer = styled(Grid)`
    padding: 0px !important;
`
export const FormContainer = styled(Grid)`
    display: flex;
    justify-content: center;
`
export const WelcomeContainer = styled(Grid)`
    padding: 0px !important;
    .welcomeBox {
        background-image: url(${({ themeColor }) =>
            themeColor?.images?.loginPage?.background?.url || ' '});
        min-height: 99.9vh;
        max-width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;

        padding: 20px 60px;
        font-family: Roboto;
        margin-top: -1px;
    }
    .welcomeBox img {
        padding: 10px 0;
        text-align: end;
    }

    .text-body > span {
        color: #fff !important;
    }
`

export const ButtonContainer = styled.div`
    padding-left: 5px;
    width: 40%;
    align-items: flex-start;
    display: flex;
`

export const recaptchaBox = styled.div``
