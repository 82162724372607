import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    partnersData: [],
    partnersDataFailed: false
};

const fetchPartnersData = (state, action) => {
    return updateObject(state, {
        partnersData: action.partnersData
    });
};

// DATA FAIL
const fetchPartnersFail = (state, action) => {
    return updateObject(state, {
        partnersDataFailed: action.partnersDataFailed
    });
};

const partnersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PARTNERS_DATA:
            return fetchPartnersData(state, action);

        case actionTypes.FETCH_PARTNERS_FAILED:
            return fetchPartnersFail(state, action);

        default:
            return state;
    }
};

export default partnersReducer;