/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { MuiBox } from "../../../Box";
import { MuiStack } from "../../../Stack";
import { MuiTypography } from "../../../Typography";
export function TabButtons(_a) {
    var isEditData = _a.isEditData, defaultColor = _a.defaultColor, tabLabels = _a.tabLabels, selectedItem = _a.selectedItem, selectedTab = _a.selectedTab, setSelectedTab = _a.setSelectedTab, onOpenModal = _a.onOpenModal, onclickEdit = _a.onclickEdit, buttonActions = _a.buttonActions, tabPadding = _a.tabPadding, isFixedTab = _a.isFixedTab, tabFontSize = _a.tabFontSize;
    useMemo(function () {
        if (selectedItem) {
            setSelectedTab(selectedItem);
        }
    }, [selectedItem, setSelectedTab]);
    return (_jsx(_Fragment, { children: _jsxs(MuiStack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", spacing: 2, width: "100%", sx: {
                borderBottom: "3px solid ".concat(defaultColor),
                width: "100%",
                fontFamily: "Roboto, sans-serif",
            } }, { children: [_jsx(MuiStack, __assign({ direction: "row", justifyContent: "flex-start", alignItems: "center" }, { children: tabLabels === null || tabLabels === void 0 ? void 0 : tabLabels.map(function (item, index) { return (_jsx(MuiBox, __assign({ onClick: function () {
                            if (isEditData && onOpenModal) {
                                setSelectedTab(index);
                                onOpenModal();
                            }
                            else {
                                setSelectedTab(index);
                            }
                        }, sx: {
                            borderRadius: "5px 5px 0px 0px",
                            padding: "5px 15px",
                            backgroundColor: selectedTab === index ? defaultColor : "#E9E9E9",
                            borderTop: isFixedTab && index < 1 && "4px solid ".concat(selectedTab === index ? defaultColor : "#989898"),
                            minWidth: "180px",
                            textAlign: "center",
                            "&:hover": {
                                cursor: "pointer",
                            },
                        } }, { children: _jsx(MuiTypography, __assign({ sx: { fontSize: tabFontSize !== null && tabFontSize !== void 0 ? tabFontSize : 15, fontWeight: 500, lineHeight: "2.5rem", color: selectedTab === index ? "#fff" : "#636363" } }, { children: item })) }), index)); }) })), buttonActions && _jsx(MuiBox, { children: buttonActions })] })) }));
}
