import React, { useEffect, useState } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import * as API from '../../redux/api/user'
import { dashRoutes } from 'pages/Admin/routes.js'
import { GetThemeColorFromStorage } from 'redux/api/themeColor'

import {
    ListItemIconMenu,
    ListItemMenu,
    ListItemTextMenu,
    ListSubItem,
    CustomList,
} from './styles'
import { removeRoutes, useStyles } from './utils'
import { GetGeneralRules } from '../../redux/api/generalRules'
import { useConsumer } from '../../Context/consumerContext'
import { MuiBox } from '../storybook'
import { GetNegotiation } from '../../redux/actions/negotiation'

function SideMenu({ t, handleDrawerToggle }) {
    const classes = useStyles()
    const generalRulesData = useSelector(
        (state) => state.generalRules.generalRulesData,
    )
    const [open, setOpen] = useState(false)
    const [id, setId] = useState()
    const location = useLocation()
    const [newRoutes, setNewRoutes] = useState([])
    const [focuses, setFocuses] = useState('')
    const { userData } = useConsumer()
    const [showNegotiation, setShowNegotiation] = useState(false)
    const show = localStorage.getItem('showNegotiations')

    useEffect(() => {
        GetNegotiation()
    }, [])

    useEffect(() => {
        setFocuses('')
        verifyFocus()
    }, [location])

    useEffect(() => {
        getGeralBasicData()
    }, [])

    const getGeralBasicData = async () => {
        const response = await GetGeneralRules()
        setNewRoutes(removeRoutes(response, dashRoutes))
    }

    const handleClick = (routeId) => {
        if (id === routeId) {
            setId('')
            return setOpen(false)
        }
        setOpen(routeId)
        setId(routeId)
    }

    function focus(path) {
        var currentUrl = window.location.pathname
        if (path === currentUrl) {
            return true
        }
    }

    function verifyFocus() {
        var firstPath = window.location.pathname.split('/')[1]
        var currentUrl = window.location.pathname
        newRoutes.forEach((item) => {
            if (item.collapse === true) {
                item.views.map((view) => {
                    if (view.path === currentUrl) {
                        if (view.focus) {
                            setFocuses(view.focus)
                            return setOpen(view.id)
                        } else {
                            focus(view.path)
                            return setOpen(view.id)
                        }
                    } else {
                        if (currentUrl.includes(firstPath)) {
                            if (firstPath === view.path.split('/')[1]) {
                                if (view.focus) {
                                    setFocuses(view.focus)
                                    return setOpen(view.id)
                                }
                            }
                        }
                    }
                })
            } else {
                if (currentUrl.includes(firstPath)) {
                    if (firstPath === item.path.split('/')[1]) {
                        if (item.focus) {
                            setFocuses(item.id)
                            return setOpen(item.id)
                        }
                    }
                }
            }
        })
    }

    const dinamicMenuItemStyle = (_isDisabled) => {
        if (_isDisabled === true) {
            return {
                cursor: 'default',
                pointerEvents: 'none',
            }
        }

        return {
            cursor: 'pointer',
            pointerEvents: 'auto',
        }
    }

    const MenuItemWithHover = ({ route, key }) => {
        const [iconColor, setIconColor] = useState('#878787')

        return (
            <div style={dinamicMenuItemStyle(false)} key={key}>
                <NavLink to={route.path} onClick={handleDrawerToggle}>
                    <ListItemMenu
                        theme={
                            focuses === route.path ? 'true' : focus(route.path)
                        }
                        _themeColor={
                            GetThemeColorFromStorage() &&
                            GetThemeColorFromStorage()?.navigation &&
                            GetThemeColorFromStorage()?.navigation.menu
                        }
                        first={key === 0 ? true : false}
                        onMouseOver={() => {
                            if (route.svgIcon) {
                                setIconColor('#fff')
                            }
                        }}
                        onMouseOut={() => {
                            if (route.svgIcon) {
                                setIconColor('#878787')
                            }
                        }}
                    >
                        <ListItemIconMenu
                            theme={
                                focuses === route.path
                                    ? 'true'
                                    : focus(route.path)
                            }
                        >
                            <MuiBox
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    '.iconMenu:hover  svg .iconMenu.true svg': {
                                        fill: '#fff',
                                    },
                                }}
                            >
                                <div
                                    id="listMenu"
                                    className={`iconMenu ${
                                        focuses === route.path ? 'true' : ''
                                    }`}
                                >
                                    {(route.svgIcon &&
                                        route.svgIcon(
                                            focus(route.path)
                                                ? '#fff'
                                                : iconColor,
                                        )) || <i className={route.icon} />}
                                </div>
                            </MuiBox>
                        </ListItemIconMenu>

                        <ListItemTextMenu primary={t(route.name)} />
                    </ListItemMenu>
                </NavLink>
            </div>
        )
    }

    return (
        <CustomList
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
            key={showNegotiation}
        >
            {newRoutes
                .filter(
                    (x) =>
                        generalRulesData.allowIndications || x.id !== 'friends',
                )
                .map((route, key) =>
                    route.sidebar && !route.collapse ? (
                        <MenuItemWithHover route={route} key={key} />
                    ) : route.sidebar && route.collapse ? (
                        <div style={dinamicMenuItemStyle(false)} key={route.id}>
                            <ListItemMenu
                                button
                                onClick={() => handleClick(route.id)}
                                _themeColor={
                                    GetThemeColorFromStorage()?.navigation?.menu
                                }
                            >
                                <ListItemIconMenu
                                    theme={focuses === route.path}
                                >
                                    <i className={route.icon} />
                                </ListItemIconMenu>
                                <ListItemTextMenu primary={t(route.name)} />
                                {open === route.id ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItemMenu>
                            {route.views.map((subItem) =>
                                subItem.sidebar ? (
                                    <Collapse
                                        in={open === subItem.id}
                                        key={subItem.id}
                                        timeout="auto"
                                        unmountOnExit
                                        style={{ width: '284px' }}
                                    >
                                        <List component="div" disablePadding>
                                            <NavLink
                                                to={subItem.path}
                                                onClick={handleDrawerToggle}
                                            >
                                                <ListSubItem
                                                    theme={
                                                        focuses === subItem.path
                                                    }
                                                    button
                                                    className={classes.nested}
                                                    _themeColor={
                                                        GetThemeColorFromStorage()
                                                            ?.navigation?.menu
                                                    }
                                                >
                                                    <ListItemIconMenu
                                                        theme={
                                                            focuses ===
                                                            subItem.path
                                                        }
                                                    >
                                                        <i
                                                            className={
                                                                subItem.icon
                                                            }
                                                        />
                                                    </ListItemIconMenu>
                                                    <ListItemTextMenu
                                                        primary={t(
                                                            subItem.name,
                                                        )}
                                                    />
                                                </ListSubItem>
                                            </NavLink>
                                        </List>
                                    </Collapse>
                                ) : (
                                    ''
                                ),
                            )}
                        </div>
                    ) : (
                        ''
                    ),
                )}
            <div
                style={{
                    borderBottomStyle: 'solid',
                    borderWidth: '15px',
                    color: '#eee',
                    borderBottomLeftRadius: '10px',
                    WebkitBorderBottomRightRadius: '10px',
                    width: '284px',
                }}
            ></div>
        </CustomList>
    )
}

export default withTranslation()(SideMenu)
