import React, { useEffect, useMemo, useState } from 'react'
import { history } from 'utils'
import useNegotiationDetails from './Hooks/useNegotiationDetails'
import { useParams } from 'react-router-dom'
import useNegotiation from '../Negotiation/Hooks/useNegotiation'

import { Button, GridContainer, GridItem } from '../../components/storybook'
import Section from '../../components/Section/Section'
import { Modal } from './components/Modal'
import { Table } from './components/Table'
import { Details } from './components/Details'
import { Values } from './components/Values'

export function NegotiationDetails() {
    const { id } = useParams()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { negotiationData } = useNegotiation()

    const {
        getNegotiationDetailsById,
        getNegotiationTableById,
        negotiationDetails,
        negotiationDetailsTable,
        tablePagination,
        getPaginatedNegotiations,
        isLoadingDetailsTable,
    } = useNegotiationDetails()

    const pointsMask = negotiationDetails?.simpleRules?.programCurrencySymbol
    const cashbackMask = negotiationDetails?.simpleRules?.currencySymbol

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (id) {
            getNegotiationDetailsById(id)
            getNegotiationTableById(id)
        }
    }, [id])

    return (
        <>
            <GridContainer spacing={2}>
                <GridItem xs={12} mt={3}>
                    <Section
                        title="Detalhamento das negociações"
                        children={
                            <Button
                                text="Voltar"
                                typeVariation="CleanButton"
                                onClick={() =>
                                    history.push({
                                        pathname: '/negotiation',
                                    })
                                }
                                sx={{
                                    border: '1px solid #C8C8C8',
                                    width: '100%',
                                    height: '40px',
                                    color: '#C81010!important',
                                    textTransform: 'unset',
                                }}
                            />
                        }
                    />
                </GridItem>
                <Details
                    negotiationDetails={negotiationDetails}
                    handleOpenModal={handleOpenModal}
                    setIsModalOpen={setIsModalOpen}
                />
                <GridItem xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Values negotiationDetails={negotiationDetails} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Table
                        negotiationDetailsTable={negotiationDetailsTable}
                        pointsMask={pointsMask}
                        tablePagination={tablePagination}
                        getPaginatedNegotiations={getPaginatedNegotiations}
                        isLoadingDetailsTable={isLoadingDetailsTable}
                        negotiationData={negotiationData}
                        cashbackMask={cashbackMask}
                        id={id}
                    />
                </GridItem>
            </GridContainer>
            <Modal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                negotiationDetails={negotiationDetails}
            />
        </>
    )
}
