/* eslint-disable react-hooks/exhaustive-deps */
import { Fade } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import * as S from './styles'
import { withTranslation } from 'react-i18next'
import Section from '../Section/Section'

function ViewModal({
    children,
    open,
    closeByClick,
    maxWidth,
    className,
    padding,
    shadow,
    t,
    headerIcon = 'icon-ico_regra_plataforma',
    headerTitle = 'TITLE_PLATFORM_RULES_USE_TERMS_AND_POLICY',
    buttonTitle,
    days,
}) {
    const phrase = useMemo(() => {
        return days >= 1
            ? `Faltam ${days} dia(s) para expirar o
              aceite do novo ${t(headerTitle)}. Caso não confirme, você perderá o
              acesso ao portal.`
            : `Para prosseguir, leia e aceite ${headerTitle === "TITLE_PRIVACY_POLICY" ? `a nova` : 'o novo'} ${t(headerTitle)}.`
    }, [days, headerTitle])

    const warning = useMemo(() => days >= 1, [days])

    return (
        <S.ModalContainer
            open={open}
            className={className}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            disableEnforceFocus
        >
            <Fade in={open} unmountOnExit style={{ width: '100%' }}>
                <S.PaperContainer padding={padding} maxwidth={maxWidth}>
                    <Section title={t(headerTitle)}>
                        <button
                            widthText={buttonTitle}
                            type="button"
                            className="close-button"
                            onClick={closeByClick}
                        >
                            {t(buttonTitle)}
                        </button>
                    </Section>

                    <S.SubHeader warning={warning}>
                        <div>{phrase}</div>
                    </S.SubHeader>
                    <main>{children}</main>
                </S.PaperContainer>
            </Fade>
        </S.ModalContainer>
    )
}

ViewModal.propTypes = {
    children: PropTypes.element,
    open: PropTypes.bool,
    closeByClick: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
    headTitle: PropTypes.string,
}

ViewModal.defaultProps = {
    className: '',
    padding: '',
    shadow: '',
    headTitle: '',
    children: null,
    open: false,
    closeByClick: null,
    maxWidth: '630px',
}

export default withTranslation()(ViewModal)
