/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'

// Resolvers
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'

// @material
import { Checkbox, Grid, Icon } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import Loader from 'components/Loader/Loader.jsx'

import Button from 'components/CustomButtons/Button.jsx'
import { CssTextField } from 'components/CssTextField/CssTextField'
import ConfirmModal from 'components/ConfirmModal'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Section from 'components/Section/Section'
import SnackDefault from 'components/SnackDefault'
import TextMaskCustom from '../../../components/CustomCpfInput'

// utils
import axios from 'axios'
import * as _ from 'lodash'
import * as Styled from './styles'
import ValidateModal from '../ValidateModal'
import { numberFormatText } from 'utils/utils'

// Requests
import {
    ValidateConsumerByCode,
    ValidateConsumerByCpf,
    ValidateConsumer,
    SendGiftCard,
    GetBalanceResume,
} from 'redux/api/user'
import { useSelector, useDispatch } from 'react-redux'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'
import { currencyFormatter, defaultValues, validateIndication } from './utils'
import { InputPtBRCurrency } from '../../../components/storybook/Components/InputMasks'
import { giftValueFormat, giftValueValidate } from './functions'
import { getProgramTitle } from '../../../utils/utils'

function GirftFriend() {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const [clear, setClear] = useState(false)
    const [loading, setLoading] = useState(false)

    const { generalRulesData } = useSelector((state) => state.generalRules)
    const { userData } = useSelector((state) => state.users)

    const [codeValidated, setCodeValidated] = useState(null)
    const [cpfValidated, setCpfValidated] = useState(null)

    const [errorGiftValue, setErrorGiftValue] = useState(false)
    const [giftCardData, setGiftCardData] = useState(null)
    const [maximumGiftCard, setMaximumGiftCard] = useState(0)
    const [minimumGiftCard, setMinimumGiftCard] = useState(0)
    const [valueInsufficient, setValueInsufficient] = useState(false)
    const [sendAnnonymous, setSendAnnonymous] = useState(false)
    const [validateModalProps, setValidateModalProps] = useState({
        open: false,
        text: 'Ao confirmar a ação, seu amigo será presenteado com R$ 0,00 que serão transferidos do saldo da sua carteira digital como forma de Vale Presente.\n\nDeseja confirmar a transferência?',
    })
    const [confirmModalProps, setConfirmModalProps] = useState({
        open: false,
        text: 'As alterações realizadas serão perdidas. Deseja continuar?',
    })

    const [snackStatus, setSnackStatus] = useState({
        open: false,
        severity: 'success',
        text: t('FIELD_ALERT_SUCCESS'),
    })
    const [currentTotalCash, setCurrentTotalCash] = useState([])
    const [validatedName, setValidatedName] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    const colorModal = GetThemeColorFromStorage()?.titles?.secondary?.textColor
    const [giftValue, setGiftValue] = useState(0)

    useEffect(() => {
        if (clear === true) {
            setClear(false)
        }
    }, [clear])

    const { control, errors, handleSubmit, reset, setValue, watch, formState } =
        useForm({
            defaultValues: defaultValues,
            resolver: yupResolver(validateIndication),
        })
    const { isDirty } = formState
    const watchGiftValue = watch('giftValue')

    useEffect(() => {
        if (watchGiftValue > 0) {
            const value = watchGiftValue
            let result = 0

            if (value <= currentTotalCash) {
                result = currentTotalCash - value
                setErrorGiftValue(false)
            } else {
                setValueInsufficient(true)
                setValue('balanceUsed', '')
            }
            setValue('balanceUsed', `${currencyFormatter.format(result)}`)
        } else {
            setValue('balanceUsed', '')
        }
    }, [currentTotalCash, watchGiftValue])

    useEffect(() => {
        if (currentTotalCash) {
            setTimeout(() => {
                setValue(
                    'balance',
                    `${currencyFormatter.format(currentTotalCash)}`,
                )
            }, 500)
        }
    }, [currentTotalCash, generalRulesData])

    const requestApi = useCallback(async () => {
        try {
            const currentTotalCashData = await GetBalanceResume()
            setCurrentTotalCash(currentTotalCashData.currentTotalCash)
        } catch (error) {
            console.log('ERROR: ', error)
        }

        reset({
            giftValue: '',
        })
    }, [dispatch, reset])

    useEffect(() => {
        axios.get(`/ProgramRules/General`).then((response) => {
            setMinimumGiftCard(response.data.minimumGiftCard)
            const { maximumGiftCard } = response.data
            setMaximumGiftCard(maximumGiftCard)
        })
        requestApi()
    }, [])

    const handleOpenConfirmation = (data) => {
        // CHECK IF CODE OR CPF WAS VALIDATED
        if (_.isEmpty(cpfValidated)) {
            setValidatedName('')
            setErrorMsg('O usuário não foi validado.')
            return false
        }
        //
        if (errorGiftValue) {
            setLoading(false)
            setSnackStatus({
                open: true,
                severity: 'warning',
                text: 'Você não tem saldo suficiente',
            })
            return false
        }
        if (watchGiftValue === 0) {
            setLoading(false)
            setSnackStatus({
                open: true,
                severity: 'warning',
                text: `O valor deve ser maior que ${numberFormatText(
                    minimumGiftCard,
                    generalRulesData.currencySymbol
                        ? generalRulesData.currencySymbol + ' '
                        : ' ',
                    2,
                )}`,
            })
            return false
        }

        let obj = {
            ...data,
            annonymous: sendAnnonymous,
        }

        let destName = validatedName.fontcolor(colorModal)

        const numberFormat = (value) =>
            new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value)

        let GiftValueColored =
            numberFormat(watchGiftValue).fontcolor(colorModal)

        setGiftCardData(obj)
        setValidateModalProps({
            open: true,
            text: `<html><body>Ao confirmar a ação, o Destinatário/Origem <span style="font-weight: bold;">${destName}</span> será presenteado com <span style="font-weight: bold;">${GiftValueColored}</span> que serão transferidos do saldo da sua carteira digital na forma de Vale Presente.<br/><br/><span style="font-weight: bold;">Deseja confirmar a transferência?</span></body></html>`,
        })
        setClear(true)
    }

    const handleSend = async (password) => {
        const giftValue = Number(parseFloat(giftCardData.giftValue).toFixed(0))

        if (giftValue < minimumGiftCard) {
            setLoading(false)
            setSnackStatus({
                open: true,
                severity: 'warning',
                text: t('VALUE_BELOW_ALLOWED'),
            })
            return false
        }

        if (giftValue > maximumGiftCard) {
            setLoading(false)
            setSnackStatus({
                open: true,
                severity: 'warning',
                text: t('VALUE_ABOVE_ALLOWED'),
            })
            return false
        }

        const destinyConsumerId = codeValidated
            ? codeValidated?.id
            : cpfValidated?.id
        //
        let obj = {
            originConsumerId: userData.id,
            destinyConsumerId: destinyConsumerId,
            value: Number(giftCardData.giftValue),
            sendAnnonymous,
            password,
        }
        //

        const response = await SendGiftCard(obj)
        if (response.success) {
            setLoading(false)
            setSnackStatus({
                open: true,
                severity: 'success',
                text: t('FIELD_ALERT_SUCCESS'),
            })
            setGiftValue(giftCardData.giftValue)
            reset({
                cpf: '',
                balance: '',
                balanceUsed: '',
                giftValue: 0,
            })
            setValue('giftValue', 0)
            setClear(true)
            setValidatedName('')
            setSendAnnonymous(false)
            setCodeValidated(null)
            requestApi()
        } else {
            setLoading(false)
            const snakeUpper = _.toUpper(
                _.snakeCase(response.errors[0].message),
            )
            setLoading(false)
            setSnackStatus({
                open: true,
                severity: 'warning',
                text: t(snakeUpper),
            })
        }
    }

    const validateConsumerPwd = async ({ password }) => {
        setLoading(true)
        const response = await ValidateConsumer(password)
        if (response) {
            hideModal()
            handleSend(password)
        } else {
            setLoading(false)
            setSnackStatus({
                open: true,
                severity: 'warning',
                text: 'Senha inválida, tente novamente.',
            })
        }
    }

    const validateConsumerByCodeOrCpf = async (e) => {
        const cpf = e

        if (cpf.length === 14) {
            let response = ''
            if (cpf !== '') {
                response = await ValidateConsumerByCpf(cpf.replace(/\D/g, ''))
            }
            if (response.success) {
                setCpfValidated(response?.data)
                let name = response?.data?.name

                var first4 = name.substring(0, 4)
                var last5 = name.substring(name.length - 5)

                let mask = '*'.repeat(name.length - 5)

                let valName = first4 + mask + last5

                setValidatedName(valName)
                setErrorMsg('')
                setGiftValue(cpf)
            } else {
                setValidatedName('')
                setErrorMsg('Usuário não encontrado')
            }
        }
        if (cpf.length === 6) {
            let indicationcode = cpf
            let response = ''
            response = await ValidateConsumerByCode(indicationcode)
            if (response.success) {
                setCpfValidated(response?.data)
                let name = response?.data?.name

                var first4 = name.substring(0, 4)
                var last5 = name.substring(name.length - 5)

                let mask = '*'.repeat(name.length - 5)

                let valName = first4 + mask + last5

                setValidatedName(valName)
                setErrorMsg('')
            } else {
                setValidatedName('')
                setErrorMsg('Usuário não encontrado')
            }
        }
    }

    const hideModal = () => {
        setValidateModalProps({ open: false, text: '' })
        setConfirmModalProps({
            ...confirmModalProps,
            open: false,
        })
    }

    const handleChangeAnonnymous = (event) => {
        if (event.target.name === 'sendAnnonymous') {
            setSendAnnonymous(event.target.checked)
        }
    }

    const handleCancel = () => {
        if (isDirty) {
            setConfirmModalProps({
                ...confirmModalProps,
                open: true,
            })
        } else {
            handleGoBack()
        }
    }

    const handleGoBack = () => {
        history.goBack()
    }

    const clearValidation = () => {
        setValue('giftValue', 0)
        setClear(true)
        setValidatedName('')
        setErrorMsg('')
        setCpfValidated(null)
    }

    return (
        <>
            <DocumentTitle title={getProgramTitle(t('MENU_GIFT_FRIEND'))} />
            {loading && <Loader />}
            <SnackDefault
                snackStatus={snackStatus}
                handleCloseSnack={() =>
                    setSnackStatus((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
            />
            <form onSubmit={handleSubmit(handleOpenConfirmation)}>
                <GridContainer style={{ marginBottom: '20px' }}>
                    <Styled.ContentGrid container xs={12}>
                        <Grid xs={12} sm={12} md={9} className="gridItem">
                            <Section title={t('MENU_GIFT_FRIEND')} />{' '}
                        </Grid>

                        <Grid xs={12} sm={12} md={3} className="gdItem">
                            <Button
                                color={giftValueValidate(
                                    'color',
                                    watchGiftValue,
                                    currentTotalCash,
                                    minimumGiftCard,
                                    maximumGiftCard,
                                )}
                                disabled={giftValueValidate(
                                    'disabled',
                                    watchGiftValue,
                                    currentTotalCash,
                                    minimumGiftCard,
                                    maximumGiftCard,
                                )}
                                size="sm"
                                type="submit"
                            >
                                {t('BUTTON_SEND')}
                            </Button>
                            <Button
                                color="greenBorderButtonColor"
                                size="sm"
                                onClick={() => handleCancel()}
                                style={{
                                    width: '6.125rem',
                                }}
                            >
                                {t('BUTTON_CANCEL')}
                            </Button>
                        </Grid>
                    </Styled.ContentGrid>
                </GridContainer>

                <Styled.GridBox container>
                    <Styled.GridContent
                        xs={12}
                        style={{
                            paddingLeft: '0px !important',
                        }}
                    >
                        <div
                            className="greyInfo"
                            style={{
                                marginBottom: '20px',
                            }}
                        >
                            <i
                                className="icon-detalhe_campo"
                                style={{
                                    fontSize: '30px',
                                    marginRight: '10px',
                                }}
                            />
                            Você pode enviar um Vale presente para um amigo do
                            programa de Fidelidade, utilizando o saldo
                            disponível na sua carteira digital.
                        </div>
                    </Styled.GridContent>
                </Styled.GridBox>

                <GridContainer>
                    <GridItem xs={12} md={5}>
                        <Controller
                            render={({ onChange, value, ...props }) => (
                                <CssTextField
                                    error={!!errors?.cpf}
                                    fullWidth
                                    helperText={errors?.cpf?.message}
                                    label="CPF ou Código do participante *"
                                    margin="dense"
                                    style={{ backgroundColor: '#fff' }}
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: TextMaskCustom,
                                        value,
                                        onChange,
                                    }}
                                    {...props}
                                    onBlur={(e) => {
                                        validateConsumerByCodeOrCpf(
                                            e.target.value,
                                        )
                                    }}
                                    onFocus={clearValidation}
                                />
                            )}
                            name="cpf"
                            control={control}
                            disabled={false}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6} style={{ marginTop: 5 }}>
                        <Styled.MyTooltip
                            arrow
                            id="tooltip-top"
                            title={t(
                                'Ao selecionar o envio anônimo, o Destinatário/Origem não saberá que foi você que enviou o vale presente.',
                            )}
                            placement="top-start"
                        >
                            <Styled.MyFormControlLabel
                                control={
                                    <Checkbox
                                        checked={sendAnnonymous}
                                        onChange={handleChangeAnonnymous}
                                        name="sendAnnonymous"
                                        color="primary"
                                    />
                                }
                                label={t('BUTTON_SEND_ANONYMOUSLY')}
                            />
                        </Styled.MyTooltip>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem>
                        <div
                            style={{
                                color: GetThemeColorFromStorage()?.titles
                                    ?.secondary?.textColor,
                                fontWeight: 'bold',
                            }}
                        >
                            {validatedName}
                        </div>
                        <div style={{ color: 'red', fontWeight: 'bold' }}>
                            {errorMsg}
                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer style={{ marginTop: '25px' }} spacing={0}>
                    <GridItem xs={12} md={5} key={giftValue}>
                        <Controller
                            render={({ onChange, value }) => (
                                <InputPtBRCurrency
                                    placeholder="R$ 00"
                                    label={t(
                                        'Valor que deseja presentear (R$) *',
                                    )}
                                    onChange={(e) => {
                                        giftValueFormat(e, onChange)
                                    }}
                                    defaultColor={
                                        GetThemeColorFromStorage()?.navigation
                                            ?.buttons?.backgroundColor
                                    }
                                    sx={{ marginTop: '24.5px' }}
                                    value={value}
                                    error={errors?.giftValue}
                                    helperText={errors?.giftValue?.message}
                                />
                            )}
                            control={control}
                            name="giftValue"
                            defaultValue=""
                        />

                        {valueInsufficient &&
                        watchGiftValue > currentTotalCash ? (
                            <p
                                style={{
                                    color: '#FF0000',
                                    fontSize: '13px',
                                }}
                            >
                                {t('VALUE_INSUFFICIENT')}
                            </p>
                        ) : (
                            ''
                        )}

                        {watchGiftValue && watchGiftValue > maximumGiftCard ? (
                            <p style={{ color: '#FF0000', fontSize: '13px' }}>
                                {t(
                                    'VALUE_IS_NOT_BETWEEN_MINIMUM_AND_MAXIMUM_ALLOWED_BY_THE_PROGRAM_RULES',
                                )}
                            </p>
                        ) : (
                            ''
                        )}

                        {watchGiftValue > 0 &&
                        watchGiftValue < minimumGiftCard ? (
                            <p
                                style={{
                                    color: '#FF0000',
                                    fontSize: '13px',
                                }}
                            >
                                O valor deve ser maior que{' '}
                                {numberFormatText(
                                    minimumGiftCard,
                                    generalRulesData.currencySymbol
                                        ? generalRulesData.currencySymbol + ' '
                                        : ' ',
                                    2,
                                )}
                                ,
                            </p>
                        ) : (
                            ''
                        )}
                    </GridItem>

                    <Styled.GridContent xs={12} md={3}>
                        <InputLabel>Meu saldo</InputLabel>
                        <Controller
                            as={
                                <CssTextField
                                    fullWidth
                                    id="balance"
                                    variant="outlined"
                                    margin="dense"
                                    disabled={true}
                                    inputProps={{
                                        style: { fontSize: 14, height: 19 },
                                    }}
                                />
                            }
                            name="balance"
                            control={control}
                        />
                    </Styled.GridContent>

                    <Styled.GridContent
                        xs={11}
                        sm={11}
                        md={1}
                        style={{ textAlign: 'center' }}
                    >
                        <div
                            style={{
                                color: GetThemeColorFromStorage()?.visual?.icons
                                    ?.color,
                                marginTop: 15,
                            }}
                        >
                            <Icon
                                className="icon-ico_conversao"
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '28px',
                                    margin: '10px 0',
                                    overflow: 'visible',
                                    position: 'relative',
                                    top: '3px',
                                    width: '45px',
                                }}
                            />
                        </div>
                    </Styled.GridContent>

                    <Styled.GridContent xs={12} md={3}>
                        <InputLabel>Saldo atualizado</InputLabel>
                        <Controller
                            as={
                                <CssTextField
                                    fullWidth
                                    id="balanceUsed"
                                    variant="outlined"
                                    margin="dense"
                                    inputProps={{
                                        style: { fontSize: 14, height: 19 },
                                    }}
                                    disabled={true}
                                />
                            }
                            name="balanceUsed"
                            control={control}
                        />
                    </Styled.GridContent>
                </GridContainer>
            </form>
            {/*  */}
            <ConfirmModal
                open={confirmModalProps.open}
                text={confirmModalProps.text}
                onConfirm={() => handleGoBack()}
                onCancel={() => hideModal()}
            />
            {/*  */}
            <ValidateModal
                open={validateModalProps.open}
                text={validateModalProps.text}
                error={validateModalProps.error}
                onConfirm={(value) => validateConsumerPwd(value)}
                onCancel={() => hideModal()}
                title={t('BUTTON_CONFIRM')}
            />
        </>
    )
}
export default GirftFriend
