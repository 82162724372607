import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'

import { Dialog, DialogContent, DialogContentText } from '@material-ui/core'
import { CssTextField } from 'components/CssTextField/CssTextField'
import {
    CustomButtonsBackground,
    CustomButtons,
} from 'components/CustomButtons'

export const validationSchema = Yup.object().shape({
    password: Yup.string().required('Senha obrigatória.'),
})

function ConfirmModal({
    error,
    onCancel,
    onConfirm,
    open,
    t,
    text,
    title = 'Confirmar',
}) {
    const { handleSubmit, control, errors } = useForm({
        defaultValues: {
            password: '',
        },
        resolver: yupResolver(validationSchema),
    })
    const [displayText, setDisplayText] = useState({})
    const [displayError, setDisplayError] = useState()

    useEffect(() => {
        setDisplayText(text)
    }, [text, error])

    async function onSave(value) {
        onConfirm(value)
    }

    return (
        <Dialog
            aria-labelledby="form-dialog-title"
            onClose={() => onCancel()}
            open={open}
        >
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSave)}>
                <DialogContent>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'orange',
                        }}
                    >
                        <i
                            className="icon-vale_presente"
                            style={{
                                fontSize: '80px',
                            }}
                        />
                    </div>
                    <DialogContentText
                        style={{
                            textAlign: 'center',
                            padding: '30px 0',
                            fontSize: '15px',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: displayText,
                            }}
                        ></div>
                    </DialogContentText>
                    <div style={{ textAlign: 'center' }}>
                        <Controller
                            as={
                                <CssTextField
                                    variant="outlined"
                                    type="password"
                                    placeholder={'Digite a sua senha *'}
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                    style={{ width: 300 }}
                                />
                            }
                            defaultValue=""
                            name="password"
                            control={control}
                        ></Controller>
                    </div>
                </DialogContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '30px 0',
                    }}
                >
                    <CustomButtons
                        style={{ marginRight: '10px' }}
                        color="primary"
                        startIcon={
                            <div
                                style={{
                                    fontSize: '14px',
                                }}
                            ></div>
                        }
                        onClick={() => {
                            onCancel()
                            setDisplayError('')
                        }}
                    >
                        Cancelar
                    </CustomButtons>
                    <CustomButtonsBackground type="submit">
                        {title}
                    </CustomButtonsBackground>
                </div>
            </form>
        </Dialog>
    )
}

ConfirmModal.propTypes = {
    children: PropTypes.element,
    open: PropTypes.bool,
    closeByClick: PropTypes.func,
    className: PropTypes.string,
    text: PropTypes.string,
    password: PropTypes.string,
    error: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

ConfirmModal.defaultProps = {
    className: '',
    children: null,
    open: false,
    closeByClick: null,
    text: '',
    password: '',
    error: '',
    onConfirm: null,
    onCancel: null,
}

export default withTranslation()(ConfirmModal)
