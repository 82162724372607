import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import axios from 'axios'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'
import * as S from '../styles'
import { CssTextField } from 'components/CssTextField/CssTextField'
import SnackDefault from 'components/SnackDefault'
import { CustomButtonsBackground } from '../../../components/CustomButtons'
import { ActionButton } from 'components/NewButton/styles'
import { CreateIndications } from 'redux/api/friends'
import Loader from 'components/Loader/Loader.jsx'

const validationSchema = Yup.object({
    friendList: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('obrigatório'),
            email: Yup.string()
                .email('E-mail inválido')
                .required('obrigatório')
                .typeError(' obrigatório'),
        }),
    ),
})

function InviteFriend() {
    const { t } = useTranslation()
    const {
        clearErrors,
        control,
        errors,
        handleSubmit,
        reset,
        setError,
        formState,
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: '',
            email: '',
        },
    })

    const [snackStatus, setSnackStatus] = useState({
        open: false,
        severity: 'success',
        text: t('FIELD_ALERT_SUCCESS'),
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'friendList',
    })

    useEffect(() => {
        reset({
            friendList: [
                {
                    name: '',
                    email: '',
                },
            ],
        })
    }, [reset])

    async function addFriends(data) {
		await new Promise(resolve => setTimeout(resolve, 1000))
        try {
            const response = await CreateIndications(data.friendList)
            if (response[0].success !== true) {
                console.log('error', response[0].errors[0])
                setSnackStatus({
                    open: true,
                    severity: 'error',
                    text: t(response[0].errors[0].message),
                })
            } else {
                setSnackStatus({
                    open: true,
                    severity: 'success',
                    text: t('FIELD_ALERT_SUCCESS'),
                })
            }
        } catch {
        } finally {
        }
    }

    async function verifyEmail(e, name) {
        try {
            const response = await axios.get(
                `/Consumer/VerifyReferralEmailValid/${e}`,
            )
            if (!response.data.success) {
                setError(name, { message: response.data.errors[0].message })
            } else {
                clearErrors(name)
            }
        } catch (err) {
            //
        } finally {
            //
        }
    }

    return (
        <form
            autoComplete="off"
            onSubmit={handleSubmit(addFriends)}
            style={{
                width: '100%',
                marginBottom: '25px',
            }}
        >
			{formState.isSubmitting && <Loader />}
            {fields.map((item, index) => {
                const { friendList } = errors

                const message =
                    !!friendList && !!friendList[index] && friendList[index]
                return (
                    <S.IndicationBox key={item.id}>
                        <SnackDefault
                            snackStatus={snackStatus}
                            handleCloseSnack={() =>
                                setSnackStatus((prevState) => ({
                                    ...prevState,
                                    open: false,
                                }))
                            }
                        />

                        <S.NameInput>
                            <Controller
                                as={
                                    <CssTextField
                                        error={message?.name}
                                        fullWidth
                                        helperText={t(message?.name?.message)}
                                        inputProps={{ maxLength: 255 }}
                                        label="Qual o nome do seu amigo?"
                                        margin="dense"
                                        variant="outlined"
                                    />
                                }
                                name={`friendList[${index}].name`}
                                defaultValue={item.name}
                                control={control}
                            />
                        </S.NameInput>
                        <S.EmailInput>
                            <Controller
                                control={control}
                                defaultValue={item.email}
                                name={`friendList[${index}].email`}
                                render={({ onChange, onBlur, value, name }) => (
                                    <CssTextField
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        onBlur={(e) => {
                                            verifyEmail(e.target.value, name)
                                            onBlur()
                                        }}
                                        name={name}
                                        onChange={(e) => {
                                            // verifyEmail(e.target.value, name)
                                            return onChange(e.target.value)
                                        }}
                                        error={message?.email}
                                        helperText={t(message?.email?.message)}
                                        inputProps={{ maxLength: 255 }}
                                        label="Informe o e-mail da pessoa indicada"
                                        value={value}
                                    />
                                )}
                            />
                        </S.EmailInput>
                        <S.BtnActionContain>
                            <ActionButton
                                color="primary"
                                size="sm"
                                onClick={() => {
                                    append()
                                }}
                                disabled={fields.length > 4}
                            >
                                +
                            </ActionButton>
                            <ActionButton
                                color="primary"
                                size="sm"
                                onClick={() => {
                                    remove(index)
                                }}
                                disabled={index === 0}
                            >
                                -
                            </ActionButton>
                        </S.BtnActionContain>
                    </S.IndicationBox>
                )
            })}
            <S.BtnSendContain>
                <CustomButtonsBackground type="submit">
                    {t('BUTTON_SEND')}
                </CustomButtonsBackground>
            </S.BtnSendContain>
        </form>
    )
}

export default InviteFriend
