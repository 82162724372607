import React from 'react'
import propTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import compose from 'utils/compose'

const CustomField = ({ t, disabled, message, value, autoComplete, autocomplete }) => (
    <>
        {t(value)}&nbsp;
        {!disabled && (
            <label
                autoComplete={autoComplete || autocomplete}
                style={{ color: '#f44336' }}
            >
                {message}
            </label>
        )}
    </>
)

CustomField.propTypes = {
    value: propTypes.string.isRequired,
}

export default compose(withTranslation())(CustomField)
