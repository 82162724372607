import * as actionTypes from './actionsTypes';
import * as API from '../api/partner';

export const fetchPartnersData = (data) => {
    return {
        type: actionTypes.FETCH_PARTNERS_DATA,
        partnersData: data
    };
};


// FAIL FETCH -------------------------------------
export const fetchPartnersFailed = (error) => {
    return {
        type: actionTypes.FETCH_PARTNERS_FAILED,
        partnersDataFailed: error
    };
}
// -----------------------------------------

// GET
export const GetPartnersData = (name,cnpj,page) => {
    return dispatch => (
        API.GetPartners(name,cnpj,page).then(response => {
            dispatch(fetchPartnersData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchPartnersFailed(error));
        })
    );
};