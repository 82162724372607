import React from 'react'
import { CustomButtonsBackground } from '../../components/CustomButtons'

export default ({ goToProfile }) => {
    return (
        <div
            style={{
                width: '100%',
                display: 'grid',
                placeItems: 'center',
                padding: '45px 0',
            }}
        >
            <i
                className="icon-alerta_modal"
                style={{
                    fontSize: '5rem',
                    display: 'block',
                    marginBottom: '30px',
                    color: 'rgb(240, 173, 78)',
                }}
            />

            <div>
                <b>
                    Para realizar transferências é necessário configurar a conta
                    de destino.
                </b>
            </div>

            <CustomButtonsBackground onClick={goToProfile}>
                {'Configurar'}
            </CustomButtonsBackground>
        </div>
    )
}
