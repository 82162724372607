import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'

import { Grid, Fade, TextField } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import { ModalContainer, PaperContainer } from './styles'
import * as S from './styles'
import Button from 'components/CustomButtons/Button.jsx'
import TextInput from 'components/TextInput'
import { ButtonBack } from 'components/NewButton/styles'
import { ButtonConfirm } from 'components/NewButton/styles'

const textType = [
    {
        text: 'Para prosseguir com o estorno é necessário informar a senha.',
        type: 'refund',
    },
]

export const validationSchema = Yup.object().shape({
    password: Yup.string().required('Senha obrigatória.'),
})

function ConfirmRefundModal({
    className,
    closeByClick,
    error,
    maxWidth,
    minWidth,
    onCancel,
    // onChange,
    onConfirm,
    onDelete,
    open,
    parameter,
    shadow,
    t,
    text,
    title = 'Confirmar',
    type,
    value,
    incorrectPassword_text = 'Senha incorreta',
    incorrectPassword_show = 'false',
    loading,
    setUserPasswordFromModal,
}) {
    const { handleSubmit, errors } = useForm({ validationSchema })
    const [displayText, setDisplayText] = useState({})

    useEffect(() => {
        ModalType(type)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, text, parameter, error])

    async function removeForm(value) {
        onDelete(value)
    }

    function ModalType(type) {
        if (type) {
            if (!text) {
                return textType.forEach((item) => {
                    if (item.type == type) {
                        setDisplayText(item.text)
                    }
                })
            }
            return setDisplayText(text)
        }
        if (text) {
            return setDisplayText(text)
        }
        setDisplayText(
            'Para prosseguir com a transferência é necessário informar a sua senha.',
        )
    }

    const Change = (_value) => {
        setUserPasswordFromModal(_value)
    }

    return (
        <ModalContainer
            open={open}
            className={className}
            onClose={closeByClick}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            shadow={shadow}
            maxwidth={maxWidth}
            minwidth={minWidth}
            disableEnforceFocus
        >
            {open && (
                <Fade in={open} unmountOnExit>
                    <PaperContainer padding={'0px 60px'} maxwidth={maxWidth}>
                        <GridContainer
                            xs={12}
                            sm={12}
                            style={{ margin: '50px 20px 30px 2px' }}
                        >
                            <GridItem
                                xs={12}
                                sm={12}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <i
                                    className="icon-alerta_modal"
                                    style={{
                                        color: germiniDefaultColors[11],
                                        fontSize: '80px',
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridItem
                            xs={12}
                            sm={12}
                            style={{ textAlign: 'center' }}
                        >
                            <S.Text_Focused>{displayText}</S.Text_Focused>
                        </GridItem>
                        <form
                            style={{ width: '100%' }}
                            onSubmit={handleSubmit(removeForm)}
                        >
                            <GridContainer style={{ marginTop: '30px' }}>
                                <Grid
                                    item
                                    xs={8}
                                    style={{
                                        margin: '0 auto',
                                        paddingLeft: '10px',
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        {incorrectPassword_show && (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    color: 'red',
                                                    fontWeight: '500',
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    bottom: '-27px',
                                                }}
                                            >
                                                {incorrectPassword_text}
                                            </span>
                                        )}

                                        <TextField
                                            id="passwordField"
                                            name="passwordField"
                                            value={value ?? ''}
                                            fullWidth
                                            //style={{ marginRight: '50px' }}
                                            label="Senha *"
                                            type="password"
                                            // error={!!errors.password}
                                            helperText={
                                                errors?.password?.message
                                            }
                                            onChange={(e) => {
                                                Change(e.target.value)
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </GridContainer>
                            <div
                                style={{
                                    position: 'relative',
                                    left: '45px',
                                    color: '#f44336',
                                    fontSize: '0.80rem',
                                    fontWeight: '400',
                                    fontFamily:
                                        'Roboto, Helvetica, Arial, sans-serif',
                                }}
                            >
                                {t(error ? error : '')}
                            </div>
                            <GridContainer
                                style={{
                                    padding: '10px 0 0 20px',
                                    marginBottom: '50px',
                                    marginTop: '35px',
                                }}
                            >
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    style={{ textAlign: 'center' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="greenButtonColor"
                                        style={{
                                            marginRight: 22,
                                            width: '112px',
                                            height: '42px',
                                            backgroundColor: loading && '#ccc',
                                        }}
                                        type="submit"
                                        onClick={onConfirm}
                                        disabled={loading}
                                    >
                                        {title}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="greenBorderButtonColor"
                                        style={{
                                            width: '112px',
                                            height: '42px',
                                        }}
                                        onClick={onCancel}
                                    >
                                        Cancelar
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </PaperContainer>
                </Fade>
            )}
        </ModalContainer>
    )
}

ConfirmRefundModal.propTypes = {
    children: PropTypes.element,
    open: PropTypes.bool,
    closeByClick: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shadow: PropTypes.string,
    standardFocus: PropTypes.string,
    text: PropTypes.string,
    password: PropTypes.string,
    parameter: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    onDelete: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
}

ConfirmRefundModal.defaultProps = {
    className: '',
    padding: '',
    shadow: '',
    standardFocus: 'no',
    children: null,
    open: false,
    closeByClick: null,
    onDelete: null,
    maxWidth: '',
    minWidth: '',
    text: '',
    parameter: '',
    password: '',
    error: '',
    type: '',
    onConfirm: null,
    onCancel: null,
    loading: false,
}

export default withTranslation()(ConfirmRefundModal)
