import { Modal, Paper } from '@material-ui/core'
import styled from 'styled-components/macro'

export const ModalContainer = styled(Modal)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
`

export const PaperContainer = styled(Paper)`
    margin-bottom: 40px;
    position: relative;
    max-width: ${({ maxwidth }) => maxwidth};
    min-width: 50vw;
    &.MuiPaper-elevation1 {
        box-shadow: ${({ shadow }) => shadow};
    }
    &.MuiPaper-rounded {
        border-radius: 17px;
    }

    .sub-info {
        color: #48484c;
        font-size: 0.9rem;
        font-weight: bold;
    }

    .info-geral {
        color: #31394d;
        font-weight: bold;
        font-size: 1.3rem;
        > div {
            margin-right: 5px;
        }
    }

    .blue {
        color: #3f51b5;
    }

    .close-button {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        color: #f44336;
        &:hover {
            opacity: 0.7;
        }
        svg {
            margin-right: 3px;
        }
    }

    .sub-total {
        color: #31394d;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
    }

    .sub-price {
        color: #49d489;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
    .button-box {
        position: absolute;
        margin-top: -28px;
        top: 0;
        margin-right: -28px;
        right: 0;
        .MuiIconButton-label {
            background-color: #3f51b5;
            border-radius: 50%;
            .MuiIcon-colorPrimary {
                color: #fff;
                font-size: 2rem;
            }
        }
    }

    .line {
        height: 3px;
        background-color: #21409a;
        margin-left: 15px;
        width: 100%;
        @media (max-width: 768px) {
            margin-left: -15px;
        }
    }
`

export const LabelCheck = styled.p`
	color: #AAAAAA;
	font-size: 15px;
	position: relative;
	bottom: -5px;
`
