import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Section from 'components/Section/Section'
import SnackDefault from 'components/SnackDefault'
import {
    CustomButtonsBackground,
    CustomButtons,
} from '../../components/CustomButtons'

import { CssTextField } from 'components/CssTextField/CssTextField'
import * as S from './styles'

import MaterialTable from 'material-table'
import { Icon } from '@material-ui/core'

import { HideLoader } from 'redux/actions'
import {
    GetIndications,
    GetConsumerIndicationStatusTypes,
} from '../../redux/api/friends'

import { TablePagination, MenuItem } from '@material-ui/core'
import InviteFriend from './InviteFriend'

import Autocomplete from '@mui/material/Autocomplete'
import { getProgramTitle } from '../../utils/utils'

const initialPagination = {
    currentPage: 0,
    pageCount: 1,
    pageSize: 10,
    recordCount: 1,
    filter: {
        name: '',
        status: '',
    },
}

const validationSchema = Yup.object({
    name: Yup.string().nullable(),
    status: Yup.string().nullable(),
})

function Friends() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { control, reset, handleSubmit, errors, watch } = useForm({
        validationSchema,
    })

    const [snackStatus, setSnackStatus] = useState({
        open: false,
        severity: 'success',
        text: t('FIELD_ALERT_SUCCESS'),
    })

    const [pagination, setPagination] = useState(initialPagination)
    const [gridList, setGridList] = useState([])
    const [statusList, setStatusList] = useState([])

    useEffect(() => {
        dispatch(HideLoader())
        requestApi()
    }, [
        pagination.currentPage,
        pagination.pageSize,
        pagination.filter,
        dispatch,
        requestApi,
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function requestApi() {
        try {
            const filter = {
                ...pagination.filter,
            }

            const { results, ...rest } = await GetIndications(
                `page=${pagination.currentPage + 1}&pageSize=${
                    pagination.pageSize
                }&${new URLSearchParams(filter).toString()}`,
            )

            setPagination((prevState) => ({
                ...prevState,
                recordCount: rest.recordCount,
                pageSize: rest.pageSize,
            }))
            setGridList(results)

            const responseStatusList = await GetConsumerIndicationStatusTypes()
            setStatusList(responseStatusList)
        } catch (error) {
        } finally {
        }
    }

    function handleFilter(value) {
        setPagination((prevState) => ({
            ...prevState,
            filter: {
                ...value,
            },
        }))
    }

    function handleCleanFilter() {
        reset({
            name: '',
            status: '',
        })
        setPagination((prevState) => ({ ...prevState, ...initialPagination }))
    }

    return (
        <S.Container>
            <DocumentTitle title={getProgramTitle(t('MENU_FRIENDS'))} />
            <Section title={t('MENU_FRIENDS')} />
            <SnackDefault
                snackStatus={snackStatus}
                handleCloseSnack={() =>
                    setSnackStatus((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
            />

            <InviteFriend />
            <S.FormFilterBox
                onSubmit={handleSubmit(handleFilter)}
                autoComplete="off"
            >
                <S.InputsFilterBox>
                    <S.InputRecommendation>
                        <Controller
                            as={
                                <CssTextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    label="Indicação"
                                />
                            }
                            defaultValue=""
                            name="name"
                            control={control}
                        />
                    </S.InputRecommendation>
                    <S.InputStatus>
                        <Controller
                            defaultValue=""
                            name="status"
                            control={control}
                            render={({ onChange, value }) => (
                                <Autocomplete
                                    id="combo-box-demo"
                                    size="small"
                                    options={statusList}
                                    getOptionLabel={(option) =>
                                        option.value ? t(option.value) : ''
                                    }
                                    onChange={(event, item) => {
                                        onChange(item.key)
                                    }}
                                    value={value}
                                    disablePortal
                                    renderInput={(params) => (
                                        <CssTextField
                                            {...params}
                                            style={{
                                                width: '100%',
                                            }}
                                            label={t('FIELD_STATUS')}
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            )}
                        />
                    </S.InputStatus>
                </S.InputsFilterBox>
                <S.BtnSearchContain>
                    <CustomButtonsBackground type="submit">
                        {t('BUTTON_FIND')}
                    </CustomButtonsBackground>
                    <CustomButtons onClick={() => handleCleanFilter()}>
                        {t('BUTTON_CLEAN_FILTER')}
                    </CustomButtons>
                </S.BtnSearchContain>
            </S.FormFilterBox>

            <S.GridBox>
                <MaterialTable
                    title={false}
                    search={false}
                    noToolbar={true}
                    components={{
                        Pagination: (props) => {
                            return (
                                <TablePagination
                                    {...props}
                                    rowsPerPageOptions={[10, 20, 30, 50, 100]}
                                    count={pagination.recordCount}
                                    page={pagination.currentPage}
                                    rowsPerPage={pagination.pageSize}
                                    onChangePage={(e, page) =>
                                        setPagination((prevState) => ({
                                            ...prevState,
                                            currentPage: page,
                                        }))
                                    }
                                />
                            )
                        },
                    }}
                    options={{
                        emptyRowsWhenPaging: false,
                        search: false,
                        toolBar: false,
                        pageSize: pagination.pageSize,
                        loadingType: 'none',
                        rowStyle: { backgroundColor: '#fff', fontSize: '15px' },
                        headerStyle: { fontSize: '15px', lineHeight: '1.3' },
                    }}
                    onChangeRowsPerPage={(e) =>
                        e !== pagination.pageSize
                            ? setPagination((prevState) => ({
                                  ...prevState,
                                  pageSize: e,
                              }))
                            : ''
                    }
                    localization={{
                        header: {
                            actions: 'Ações',
                        },
                        pagination: {
                            labelRowsSelect: 'linhas',
                            firstTooltip: '',
                            lastTooltip: '',
                            nextTooltip: '',
                            previousTooltip: '',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Nenhum registro encontrado.',
                        },
                    }}
                    data={gridList}
                    columns={[
                        {
                            title: 'Data de indicação',
                            field: 'indicationDate',
                        },
                        {
                            title: 'Indicação/Email',
                            field: 'name',
                            render: (props) => (
                                <div style={{ textAlign: 'left' }}>
                                    {props.name}
                                    <br />
                                    <p>{props.email}</p>
                                </div>
                            ),
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            render: (props) => (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            lineHeight: 0.8,
                                            marginTop: '4px',
                                        }}
                                    >
                                        {props.status.key === '1' ? (
                                            <Icon
                                                className="icon-grid_pendente"
                                                style={{
                                                    fontSize: '16px',
                                                    color: '#FFA21A',
                                                    size: '11px',
                                                    overflow: 'unset',
                                                    position: 'relative',
                                                    top: '-1px',
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {props.status.key === '2' ? (
                                            <Icon
                                                className="icon-grid_reprovado"
                                                style={{
                                                    fontSize: '16px',
                                                    color: '#F55A4E',
                                                    size: '11px',
                                                    overflow: 'unset',
                                                    position: 'relative',
                                                    top: '-1px',
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {props.status.key === '3' ? (
                                            <Icon
                                                className="icon-grid_ativo"
                                                style={{
                                                    fontSize: '16px',
                                                    color: '#5CB860',
                                                    size: '11px',
                                                    overflow: 'unset',
                                                    position: 'relative',
                                                    top: '-1px',
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {props.status.key === '4' ? (
                                            <Icon
                                                className="icon-cancelado"
                                                style={{
                                                    fontSize: '16px',
                                                    color: '#944E4E',
                                                    size: '11px',
                                                    overflow: 'unset',
                                                    position: 'relative',
                                                    top: '-1px',
                                                }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        <div
                                            style={{
                                                marginTop: '1px',
                                                marginLeft: '7px',
                                            }}
                                        >
                                            {t(props.status.value)}
                                        </div>
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </S.GridBox>
        </S.Container>
    )
}

export default Friends
