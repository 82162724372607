import React, { Fragment } from 'react'
import GridItem from 'components/Grid/GridItem.jsx'
import { useEffect } from 'react'

const TransferGridItemField = ({
	gridSize,
	gridItemSize = `auto`,
	InnerComponent = <Fragment></Fragment>,
	isVisible = true,
	error,
}) => {
	const invisibleStyle = {
		display: 'none',
		pointerEvents: 'none',
		userSelect: 'none'
	}

	return InnerComponent?.props?.spanLabel !== 'Taxa de antecipação (0%)' && (
		<GridItem
			xs={gridSize}
			sm={gridSize}
			//	md //auto
			md={gridItemSize}
			style={{
				margin: '7px 0',
				marginBottom: error ? '29px' : '7px',
				...(!isVisible && invisibleStyle)
			}
			}
		>
			{InnerComponent}
		</GridItem>
	)
}

export default TransferGridItemField;
