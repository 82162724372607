import React from 'react'
import Axios from 'axios'
import MaskedInput from 'react-text-mask'
import { envs } from '../../../../../redux/api/windowServerData'

export const onBlurStateRegistration = async (
    values,
    setFieldError,
    t,
    setState,
) => {
    try {
        const stateRegistration = await RegistrationValid(values)
        if (stateRegistration?.status === 200) {
            const { data } = stateRegistration
            if (data?.errors?.length === 0) {
                setFieldError('stateRegistration', '')
            } else {
                setFieldError('stateRegistration', 'INVALID_STATE_REGISTRATION')
            }
        }
    } catch (error) {
        console.log('error', error)
    } finally {
        setState({
            timerStarted: false,
        })
    }
}

export const RegistrationValid = async (values) => {
    const { stateRegistrationWaiver, cpf, stateRegistration, UF } = values
    if (
        stateRegistrationWaiver === false &&
        cpf?.replace(/[^\d]+/g, '')?.length > 11 &&
        stateRegistration &&
        UF
    ) {
        try {
            return await Axios.get(
                `/Consumer/IsStateRegistrationValid/${stateRegistration}/${UF}`,
                {
                    // baseURL: envs.REACT_APP_GERMINI_API_URL,
                    baseURL: envs.REACT_APP_GERMINI_API_URL,
                },
            )
        } catch (e) {
            console.log(e)
        }
    }
    return true
}

export const CPFOrCNPJ = (value) => {
    if (value && value.replace(/[^\d]+/g, '').length > 11) {
        return 'CNPJ'
    } else {
        return 'CPF'
    }
}

export const CEPMaskCustom = (props) => {
    const { inputRef, ...other } = props
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    )
}

export const PhoneMaskCustom = (props) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            guide={false}
            mask={(rawValue) =>
                rawValue.replace(/[^\d]+/g, '').length <= 10
                    ? [
                          '(',
                          /[1-9]/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                      ]
                    : [
                          '(',
                          /[1-9]/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                      ]
            }
            placeholderChar={'\u2000'}
            // showMask
        />
    )
}

///******* */

export const CPFMaskCustom = (props) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            mask={[
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '.',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
            ]}
            placeholderChar={'\u2000'}
        />
    )
}

export const CPFCNPJMaskCustom = (props) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            mask={(rawValue) =>
                rawValue.replace(/[^\d]+/g, '').length <= 11
                    ? [
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                      ]
                    : [
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                      ]
            }
            placeholderChar={'\u2000'}
        />
    )
}

export const DateMaskCustom = (props) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null)
            }}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            placeholder="mm/dd/yyyy"
            placeholderChar={'\u2000'}
        />
    )
}
