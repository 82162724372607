import Axios from 'axios'

class SearchService {
    async getSurveyDetails(id) {
        const response = await Axios.get(`/Survey/GetSurvey/${id}`)
        return response?.data
    }
    async postSurveyDetails(data) {
        const response = await Axios.post(`/Survey/PostResponse`, data)
        return response?.data
    }
}

export default new SearchService()
