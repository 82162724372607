import styled from 'styled-components'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

export const ContextDate = styled.div`
    .calendario {
        .icon-data {
            font-size: 20px;
            margin-right: -8px;
            color: ${({ theme }) => theme?.visual?.icons?.color};
        }
    }
    .CalendarioDesabilitado {
        .icon-data {
            font-size: 20px;
            margin-right: -8px;
        }
    }
    .rdtPicker {
        .dow,
        th.rdtSwitch,
        th.rdtNext,
        th.rdtPrev,
        .rdtTimeToggle {
            color: ${(props) =>
                props?.theme?.titles?.highlight?.textColor} !important;
        }
    }
    .rdtDay {
        &.rdtToday.rdtActive,
        &.rdtActive,
        &.rdtActive:hover {
            background-color: ${(props) =>
                props?.theme?.titles?.highlight?.textColor} !important;
        }
    }
    .rdtMonths,
    .rdtYears {
        .rdtMonth,
        .rdtYear {
            &.rdtActive {
                background-color: ${(props) =>
                    props?.theme?.titles?.highlight?.textColor} !important;
            }
        }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${(props) =>
            props?.theme?.navigation?.buttons?.backgroundColor} !important;
    }
`
