import axios from 'axios'

export const getAllCategories = async () => {
    try {
        return await axios.get(`/StoreApp/GetAllCategories`)
    } catch (error) {
        console.log(error)
    }
}

export const GetUrlCategory = async (categoryStoryId) => {
    try {
        let url = '/StoreApp/GetUrl'

        if (categoryStoryId) {
            url += `?categoryStoryId=${categoryStoryId}`
        }

        const response = await axios.get(url)

        return response?.data
    } catch (error) {
        console.log(error)
    }
}
export const GetUrlProduct = async (productStoryId) => {
    try {
        const response = await axios.get(
            `/StoreApp/GetProductUrl?productStoryId=${productStoryId}`,
        )

        return response?.data
    } catch (error) {
        console.log(error)
    }
}

export const GetUrlNegotiation = async () => {
    try {
        return await axios.get(`/Negotiation/GetBalanceExtract`)
    } catch (error) {
        console.log(error)
    }
}

export const GetUrlCatalog = async () => {
    try {
        const response = await axios.get(`/StoreApp/GetProductUrl`)
        return response?.data
    } catch (error) {
        console.log(error)
    }
}
