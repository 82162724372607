import styled from 'styled-components/macro'
import ListItem from '@material-ui/core/ListItem'
import { ListItemIcon, ListItemText } from '@material-ui/core'

const _t = (_themeColor) => {
    return {
        backgroundColor: _themeColor?.backgroundColor,
        highlightColor: _themeColor?.highlightColor,
        activeColor: _themeColor?.activeColor,
    }
}

export const ListItemMenu = styled(ListItem)`
    &:hover {
        i {
            color: white;
        }
        span {
            color: white;
        }
    }

    &.MuiListItem-button:hover {
        text-decoration: none;
        background-color: ${({ _themeColor }) =>
            _t(_themeColor)?.highlightColor};
        color: #ffffff;
    }

    &.MuiListItem-root {
        /* background: #eee; */
        background-color: ${(props) =>
            props.theme == true ? _t(props?._themeColor)?.activeColor : '#eee'};
        margin-bottom: 3px;
        border-top-left-radius: ${(props) =>
            props.first == true ? '10px' : '0px'};
        border-top-right-radius: ${(props) =>
            props.first == true ? '10px' : '0px'};
        color: ${(props) => (props.theme == true ? '#FFFFFF' : '#878787')};
        :hover {
            background-color: ${({ _themeColor }) =>
                _t(_themeColor)?.highlightColor};
        }
        &.MuiListItemText-root {
            color: ${(props) => (props.theme == true ? '#FFFFFF' : '#878787')};
        }
        //width: 284px;
    }

    .ListItemTextMenu {
        > span {
            font-size: '15px';
            color: ${(props) => (props.theme == true ? '#FFFFFF' : '#878787')};
            :hover {
                color: #ffffff;
            }
        }
    }
`

export const ListSubItem = styled(ListItem)`
    &.MuiListItem-root {
        /* background: #eee; */
        background-color: ${(props) =>
            props.theme == true ? _t(props?._themeColor)?.activeColor : '#eee'};
        margin-bottom: 3px;
        color: ${(props) => (props.theme == true ? '#FFFFFF' : '#878787')};
        border-left-style: solid;
        border-width: 5px;
        border-color: ${(props) =>
            props.theme == true ? '#FFFFFF' : '#878787'};
    }

    &.MuiListItem-button:hover {
        text-decoration: none;
        background-color: ${({ _themeColor }) =>
            _t(_themeColor)?.highlightColor};
        color: #ffffff;
        border-color: #ffffff;
    }

    &.makeStyles-nested-213 {
        padding-left: 11px;
    }
    :hover {
        i {
            color: #fff;
        }
    }
`

export const ListItemIconMenu = styled(ListItemIcon)`
    &.MuiListItemIcon-root {
        margin-left: -5px;
        font-size: 20px;
        color: ${(props) => (props.theme == true ? '#FFFFFF' : '#878787')};
        display: inline-flex;
        min-width: 42px;
        flex-shrink: 0;
    }

    .iconMenu:hover,
    .iconMenu.active {
        color: #fff;
    }

    &.MuiListItemIcon-root:hover {
        font-size: 20px;
        color: #ffffff;
        display: inline-flex;
        min-width: 42px;
        flex-shrink: 0;
    }
    &.svgIcon {
        :hover {
            fill: white; // ou a cor desejada
        }
    }

    > span {
        :hover {
            color: #ffffff;
        }
    }
`

export const ListItemTextMenu = styled(ListItemText)`
    > span {
        :hover {
            color: #ffffff;
        }
    }
`

export const CustomList = styled.div`
    //
`
