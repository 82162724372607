import styled from "styled-components";
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";

export const PageContainer = styled(Grid)`
  padding: 20px;
  padding-right: 15px;
  justify-content: flex-end;
  align-items: center;

  div {
    margin-right: 6px;
  }
`;

export const LeftArrowFirst = styled(FirstPageIcon)`
  color: ${({ disable }) => (disable ? "#e6e6e6" : "#848484")};
  cursor: ${({ disable }) => (disable ? "initial" : "pointer")};
  margin-right: 8px;
  font-size: 38px !important;
`;

export const LeftArrow = styled(ArrowBackIosIcon)`
  color: ${({ disable }) => (disable ? "#e6e6e6" : "#848484")};
  cursor: ${({ disable }) => (disable ? "initial" : "pointer")};
`;

export const RightArrow = styled(ArrowForwardIosIcon)`
  color: ${({ disable }) => (disable ? "#e6e6e6" : "#848484")};
  cursor: ${({ disable }) => (disable ? "initial" : "pointer")};
`;

export const RightArrowEnd = styled(LastPageIcon)`
  color: ${({ disable }) => (disable ? "#e6e6e6" : "#848484")};
  cursor: ${({ disable }) => (disable ? "initial" : "pointer")};
  font-size: 38px !important;
`;
