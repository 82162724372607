/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { CustomTextButton } from "./styles";
export function TextButton(_a) {
    var text = _a.text, themeColor = _a.themeColor, themeHoverColor = _a.themeHoverColor, props = __rest(_a, ["text", "themeColor", "themeHoverColor"]);
    var _b = useState("#329EF1"), hoverColor = _b[0], setHoverColor = _b[1];
    return (_jsx(CustomTextButton, __assign({ style: {
            backgroundColor: hoverColor,
        }, onMouseOver: function () {
            return setHoverColor(themeHoverColor ? themeHoverColor : "#2886CE");
        }, onMouseOut: function () { return setHoverColor(themeColor ? themeColor : "#329EF1"); } }, props, { children: text })));
}
