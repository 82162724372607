import { Grid } from '@material-ui/core'
import React, { forwardRef, useState } from 'react'
import RICIBs from 'react-individual-character-input-boxes'
import * as Style from './styles'
import './index.css'

import VerificationInput from 'react-verification-input'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor'

const RegisterIndicationInput = forwardRef(
    (
        {
            title = 'Digite o código do participante e beneficie quem te indicou',
            amount,
            onChange,
            regExp = /^[0-9]$/,
            error,
            value,
            disabled = false,
            hasBackground = true,
            validChars,
        },
        ref,
    ) => {
        const [themeColor, setThemeColor] = useState(GetThemeColorFromStorage())
        return (
            <>
                <Style.Container
                    item
                    className="input-_-container"
                    container
                    themeColor={themeColor}
                    title={hasBackground}
                    error={error ? 1 : 0}
                    xs={12}
                >
                    {hasBackground && (
                        <Grid item xs={12} md={6}>
                            <span>{title}</span>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        md={hasBackground ? 6 : 12}
                        className="input-box"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {!hasBackground && (
                            <p className="title_kVVVRq">{title}</p>
                        )}

                        <VerificationInput
                            validChars={validChars}
                            removeDefaultStyles
                            classNames={{
                                container: 'container',
                                character: 'character',
                                characterInactive: 'character--inactive',
                                characterSelected: 'character--selected',
                            }}
                            value={value}
                            inputProps={{
                                disabled: disabled,
                            }}
                            ref={ref}
                            length={amount}
                            onChange={onChange}
                        />

                        {/* <RICIBs
                            ref={ref}
                            amount={amount}
                            handleOutputString={onChange}
                            inputRegExp={regExp}
                        /> */}
                    </Grid>
                </Style.Container>
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: hasBackground ? 'end' : 'center' }}
                >
                    {error && <span style={{ color: '#DE2E2E' }}>{error}</span>}
                </Grid>
            </>
        )
    },
)

export default RegisterIndicationInput
