import * as actionTypes from './actionsTypes';
import * as API from '../api/images';

export const setFileData = (data) => {
    return {
        type: actionTypes.SET_FILE_DATA,
        imageFile: data
    };
};


export const postImageData = (data) => {
    return {
        type: actionTypes.POST_IMAGE_DATA,
        imageUrl: data
    };
};

export const fetchThumbnails = (data) => {
    return {
        type: actionTypes.FETCH_THUMBNAILS,
        thumbnails: data
    };
};

// FAIL ACTIONS -------------------------------------
export const fetchImagesFailed = (error) => {
    return {
        type: actionTypes.FETCH_IMAGE_FAILED,
        imageFailed: error
    };
};
//----------------------------------------------------

export const SetFileData = (data) => {
    return dispatch => (
        dispatch(setFileData(data))
    );
};


export const ImageUpload = (data) => {
    return dispatch => (
        API.ImageUpload(data).then(response => {
            dispatch(postImageData(response));
            dispatch(setFileData(null));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchImagesFailed());
        })
    );
};

export const GetThumbnails = () => {
    return dispatch => (
        API.GetThumbnails().then(response => {
            dispatch(fetchThumbnails(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchImagesFailed());
        })
    );
}
