import { updateObject } from '../utility'

const initialState = {
    regulationData: null,
    regulationDataFailed: false,
}

const fetchData = (state, action) => {
    return updateObject(state, {
        regulationData: action.regulationData,
    })
}

const fetchDataFailed = (state, action) => {
    return updateObject(state, {
        regulationDataFailed: action.regulationDataFailed,
    })
}

const regulationReducer = (state = initialState, action) => {
    switch (action.types) {
        case action.FETCH_REGULATION_DATA:
            return fetchData(state, action)
        case action.FETCH_REGULATION_FAILED:
            return fetchDataFailed(state, action)
        default:
            return state
    }
}

export default regulationReducer
