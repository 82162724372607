import React from 'react'
import { useEffect } from 'react';

const TransferRequest_LimitsWarnings = ({
	userTransferLimit,
	daysToProcessTransfer,
	generalRules,
}) => {
	const [startTransfer, setStartTransfer] = React.useState('');
	const [endTransfer, setEndTransfer] = React.useState('');
	useEffect(() => {
		if (generalRules) {
			setStartTransfer(generalRules?.startTransferPeriod?.slice(0, -3));
			setEndTransfer(generalRules?.endTransferPeriod?.slice(0, -3));
		}
	}, [generalRules])
	return (
		<div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-evenly', flexDirection: 'column', marginTop: '20px' }}>
			<p style={{ margin: '0' }} >{`As solicitações de transferência estão permitidas entre ${startTransfer || "-"} e ${endTransfer || "-"} `}
				{
					generalRules.allowDaysToProcessTransfer &&
					daysToProcessTransfer > 0 &&
					`e serão processadas em ${daysToProcessTransfer} ${daysToProcessTransfer > 1 ? 'dias úteis' : 'dia útil'}`
				}
			</p>
			{generalRules?.allowDailyTransferLimit &&
				<p style={{ margin: '0' }} >{`Seu limite de transferências hoje é de ${userTransferLimit}.`}</p>
			}
		</div>
	)
}
export default TransferRequest_LimitsWarnings;
