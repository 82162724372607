import * as API from '../../../redux/api/user'

const userPixSchema = (_pixKeyType = 0, _pixKey = null) => {
    return {
        pixKeyType: _pixKeyType,
        pixKey: _pixKey,
    }
}

const userBankSchema = (
    _name = null,
    _agency = null,
    _account = null,
    _digit = null,
) => {
    return {
        name: _name,
        agency: _agency,
        account: _account, //pegar todos menos o ultimo caracter
        digit: _digit, // ultimo caracter
    }
}

const maskedPixValue = (_value) => {
    switch (_value) {
        case '0':
            return 'Chave PIX' //0
        case 'AleatoryKey':
            return 'Chave aleatória'
        // case 'CPF': return "CPF"
        case 'Cellphone':
            return 'Celular'
        case 'Email':
            return 'E-mail'
        default:
            return _value
    }
}

export default (
    setUserPix,
    setUserBank,
    userBank,
    userPix,
    setUserHasAnyPaymentOption,
    showModal,
    set_userHasBank,
    setLoading,
) => {
    // setLoading(true)
    API.GetCurrentConsumer()
        .then((res) => {
            const userAccountInfo = res?.consumerAccount

            const bankDigit = userAccountInfo?.number
                ? userAccountInfo?.number?.slice(-1)
                : null // ultimo caracter

            const bankNumber = userAccountInfo?.number
                ? userAccountInfo?.number?.substring(
                      0,
                      userAccountInfo?.number?.length - 1,
                  )
                : null //pegar todos menos o ultimo caracter

            if (userAccountInfo) {
                setUserBank(
                    userBankSchema(
                        userAccountInfo.bank
                            ? userAccountInfo.bank.value
                            : null,
                        userAccountInfo.branch ? userAccountInfo.branch : null,
                        bankNumber,
                        bankDigit,
                    ),
                )

                setUserPix(
                    userPixSchema(
                        maskedPixValue(userAccountInfo.keyType.value),
                        userAccountInfo.key,
                    ),
                )
            } else {
                setUserBank(userBankSchema())
                setUserPix(userPixSchema())
            }
            //CHECANDO SE USER TEM OU NAO MEIO DE PAGAMENTO PRA MOSTRAR NA TELA
            const hasBank =
                userAccountInfo?.bank?.value &&
                userAccountInfo?.branch &&
                bankNumber &&
                bankDigit !== null &&
                bankDigit !== undefined
                    ? true
                    : false

            const hasPix =
                userAccountInfo?.keyType?.value && userAccountInfo?.key
                    ? true
                    : false

            setUserHasAnyPaymentOption(hasPix || hasBank)

            set_userHasBank(!hasPix ? hasBank : false)
        })
        .catch((_err) => {
            showModal(`Não foi resgatar seus dados de transferência.#`, false)
        })
        .finally(() => {
            setLoading(false)
        })
}
