import styled from 'styled-components'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

export const Container = styled.div`
    .GridContainer-grid-170 {
        width: 100% !important;
    }
    .GridItem-grid-399,
    .ecHOuH {
        padding: 0 !important;
    }
    .GridContainer-grid-170 {
        margin: 0 !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
    .MuiInput-underline:after,
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: ${GetThemeColorFromStorage()?.navigation?.buttons
            ?.backgroundColor} !important;
    }
`

export const DivDate = styled.div`
    width: 50%;
    padding-right: 10px;
    .icon-data {
        font-size: 20px;
        margin-right: -8px;
        color: ${GetThemeColorFromStorage()?.visual?.icons?.color};
    }
    .rdtPicker {
        .dow,
        th.rdtSwitch,
        th.rdtNext,
        th.rdtPrev,
        .rdtTimeToggle {
            color: ${GetThemeColorFromStorage()?.titles?.secondary?.textColor};
        }
    }
    .rdtDay {
        &.rdtToday.rdtActive,
        &.rdtActive,
        &.rdtActive:hover {
            background-color: ${GetThemeColorFromStorage()?.titles?.secondary
                ?.textColor} !important;
        }
    }
    .MuiFormControl-marginDense {
        margin: 0 !important;
        width: 100% !important;
    }
    .styles_dateInputBlock__1y1ap {
        max-width: 100% !important;
    }
`
export const SectionBox = styled.div`
    width: 100%;
`

export const FilterBox = styled.div`
    padding: 20px !important;
    width: 100%;
`
export const InputBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media only screen and (min-width: 800px) {
        flex-wrap: nowrap;
    }
`

export const InputPeriod = styled.div`
    width: 100%;
    @media only screen and (min-width: 600px) {
        margin-bottom: 15px;
    }
    @media only screen and (min-width: 800px) {
        margin-bottom: 0;
        padding-right: 25px;
        width: 27%;
    }

    .MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 3px;
    }

    .MuiAutocomplete-popperDisablePortal {
		li:hover {
			color: #ffffff !important;
			background-color: ${GetThemeColorFromStorage()?.navigation?.buttons
                ?.backgroundColor}
		}
	}
`
export const dateBox = styled.div`
    display: flex;
    gap: 15px;
    margin: 15px 0;
    width: 100%;
    @media only screen and (min-width: 600px) {
        margin: 0;
        padding-right: 7px;
        width: 50%;
    }
    @media only screen and (min-width: 800px) {
        width: 37.9%;
    }
`
export const inputDestiny = styled.div`
    width: 100%;
    .MuiFormControl-marginDense {
        margin: 0 !important;
    }
    @media only screen and (min-width: 600px) {
        padding-left: 7.5px;
        width: 50%;
    }
    @media only screen and (min-width: 800px) {
        width: 35%;
    }
`

export const BtnFilterContain = styled.div`
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 20px;
    width: 100%;
`
export const LabelInput = styled.label`
    font-weight: 400;
    position: absolute;
    top: -7px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    font-size: 1rem;
    z-index: 3;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    line-height: 1;
    letter-spacing: 0.00938em;
    transform: translateX(-10px) scale(0.75);
    pointer-events: none;
    padding: 0px 9px 0px 9px;
    background-color: #fff;
    left: -7px;
`
